import { Dispatch } from "redux";

import { getOwnVenuesRequest } from "../../common/api";
import dictionary from "../../i18n/en_US/dictionary";
import { store } from "../index";
import {
  CURRENT_VENUE_GET_OWN_VENUES_FULFILLED,
  CURRENT_VENUE_GET_OWN_VENUES_PENDING,
  CURRENT_VENUE_GET_OWN_VENUES_REJECTED,
  CURRENT_VENUE_SET_CURRENT_VENUE,
  CurrentVenueActionTypes,
  GetOwnVenuesFailAction,
  GetOwnVenuesPendingAction,
  GetOwnVenuesSuccessAction,
  OwnVenue,
  SetCurrentVenueAction,
} from "./types";

export const setCurrentVenue = (venue?: OwnVenue): SetCurrentVenueAction => ({
  type: CURRENT_VENUE_SET_CURRENT_VENUE,
  venue,
});

const getOwnVenuesPendingAction = (): GetOwnVenuesPendingAction => ({
  type: CURRENT_VENUE_GET_OWN_VENUES_PENDING,
});

const getOwnVenuesSuccessAction = (
  venues: OwnVenue[]
): GetOwnVenuesSuccessAction => ({
  type: CURRENT_VENUE_GET_OWN_VENUES_FULFILLED,
  venues,
});

const getOwnVenuesFailAction = (error: string): GetOwnVenuesFailAction => ({
  type: CURRENT_VENUE_GET_OWN_VENUES_REJECTED,
  error,
});

export const getOwnVenues =
  () => async (dispatch: Dispatch<CurrentVenueActionTypes>) => {
    dispatch(getOwnVenuesPendingAction());

    try {
      const { venues } = await getOwnVenuesRequest();

      dispatch(getOwnVenuesSuccessAction(venues));
    } catch (e) {
      dispatch(
        getOwnVenuesFailAction(
          dictionary.venues.ownVenues.errorGettingOwnVenues
        )
      );
    }
  };

export const updateCurrentVenue =
  (venueId: number) => (dispatch: Dispatch<CurrentVenueActionTypes>) => {
    const { ownVenues } = store.getState().currentVenue;

    if (!ownVenues) {
      return;
    }

    for (const venue of ownVenues) {
      if (venue.id === venueId) {
        dispatch(setCurrentVenue(venue));
        break;
      }
    }
  };
