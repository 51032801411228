import { DialogActions, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AlertContext } from "../../../../../../../../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../../../../../../../../common/components/ButtonBlack";
import ButtonCancel from "../../../../../../../../../../../common/components/ButtonCancel";
import dictionary from "../../../../../../../../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../../../../../../../../store/rootReducer";
import { genRanHex } from "../../../../../../../../../../../utils/numberUtils";
import { WorkingDaysForm } from "../../../../../../../../../../VenueSettings/components/VenueCommonData/form/WorkingDaysForm";
import api from "../../../../../../../../../api";
import DtoHappyHour from "../../../../../../../../../dtos/HappyHour.dto";
import DtoPartialDiscount from "../../../../../../../../../dtos/PartialDiscount.dto";
import { PromotionsContext } from "../../../../../../../../../providers";
import { DealCardState } from "../../../../../../../../../types/enums/DealCardState.enum";
import { CreateHappyHour } from "../../../../../../../../../types/interfaces/CreateHappyHour.interface";
import { CreatePartialDiscount } from "../../../../../../../../../types/interfaces/CreatePartialDiscount.interface";
import { DiscountDaysHours } from "../../../../../../../../../types/interfaces/DiscountDaysHours.interface";
import { HappyHour } from "../../../../../../../../../types/interfaces/HappyHour.inteface";
import { DialogContext } from "../../DialogContextProvider";
import Props from "./Props";
import { useStyles } from "./styles";

const Actions: FC<Props> = (props: Props) => {
  const { closeDialog, happyHourId } = props;
  const { showAlert } = useContext(AlertContext);
  const workingDaysForm = useSelector<AppState, WorkingDaysForm>(
    (state) => state.forms.workingDays
  );
  const [loading, setLoading] = useState<boolean>(false);

  const { setHappyHoursList, setHappyHoursTabCount } =
    useContext(PromotionsContext);

  const classes = useStyles();

  const {
    currentStep,
    setCurrentStep,
    mainForm,
    mainForm: {
      form: { isValid: mainFormIsValid },
    },
    advancedForm,
    daysHours,
    setDaysHours,
    setHappyHourId,
    resetForm,
    setDeals,
    deals,
    activeCardState,
    filesQueue,
    updateDealItem,
    resetInActiveCards,
    increaseCustomFormLengthProperty,
    decreaseCustomFormLengthProperty,
    isSaveDisabled,
    setIsSaveDisabled,
    setUpdateDealItem,
  } = useContext(DialogContext);

  useEffect(() => {
    if (mainFormIsValid) {
      setIsSaveDisabled(false);
    }
  }, [mainFormIsValid, setIsSaveDisabled]);

  const updateHappyHourList = async (): Promise<any> => {
    api.happyHours
      .listAndCount()
      .then((data: { list: HappyHour[]; count: number }) => {
        if (data) {
          setHappyHoursList(data.list);
          setHappyHoursTabCount(data.count);
        }
      })
      .catch(() => {
        showAlert(dictionary.errors.undefinedError, "error");
      });
  };

  useEffect(() => {
    if (workingDaysForm.values.workingDays) {
      const reformattedWorkingDays: DiscountDaysHours[] =
        workingDaysForm.values.workingDays.map((day) => {
          return {
            id: day.id,
            day: day.day,
            isActive: day.isOpen,
            start: day.hours[0].from,
            end: day.hours[0].to,
          };
        });

      setDaysHours(reformattedWorkingDays);
    }
  }, [setDaysHours, workingDaysForm.values.workingDays]);

  const getSecondaryButtonLabel = () =>
    currentStep === 1 ? dictionary.general.cancel : dictionary.general.back;

  const getAddDealButtonLabel = () => {
    let label = dictionary.general.addItem;
    if (updateDealItem) {
      label = dictionary.general.editItem;
    }
    return label;
  };

  const closeAndReset = () => {
    closeDialog();
    resetForm();
  };

  const handlePressSecondaryButton = () => {
    currentStep === 1 ? closeAndReset() : setCurrentStep(currentStep - 1);
    setUpdateDealItem(undefined);
  };

  const sendUpdateRequest = (happyHourId: number): void => {
    const builtDeals: CreatePartialDiscount[] = [];

    deals.map((deal: any) => {
      builtDeals.push(
        DtoPartialDiscount.buildUpdate({
          ...deal,
          venueId: Number(mainForm.form.values.venueId),
          daysHours,
        })
      );
    });

    const { menuUrl } = mainForm.form.values;
    const happyHour: CreateHappyHour = DtoHappyHour.buildUpdate({
      id: happyHourId,
      daysHours,
      menuUrl,
      venueId: Number(mainForm.form.values.venueId),
      deals: builtDeals,
      files: filesQueue.filter((fileQueue) => fileQueue?.generatedName),
    });

    setLoading(true);

    api.happyHours
      .update(happyHour)
      .then(async (data) => {
        await updateHappyHourList();
        showAlert("Successfully Updated!", "success");
        closeAndReset();
      })
      .catch((error) => {
        switch (error.response.data) {
          case dictionary.deals.happyHours.errors.venueDoesntExist: {
            showAlert(
              dictionary.deals.happyHours.errors.venueDoesntExist,
              "error"
            );
            break;
          }
          case dictionary.deals.happyHours.errors.atLeastOneDeal: {
            showAlert(
              dictionary.deals.happyHours.errors.atLeastOneDeal,
              "error"
            );
            break;
          }
          case dictionary.deals.happyHours.errors.atLeastOneFile: {
            showAlert(
              dictionary.deals.happyHours.errors.atLeastOneFile,
              "error"
            );
            break;
          }
          case dictionary.deals.happyHours.errors.venueAlreadyUsed: {
            showAlert(
              dictionary.deals.happyHours.errors.venueAlreadyUsed,
              "error"
            );
            break;
          }
          case dictionary.deals.happyHours.errors.venueIsNotClaimed: {
            showAlert(
              dictionary.deals.happyHours.errors.venueIsNotClaimed,
              "error"
            );
            break;
          }
          default: {
            showAlert(
              dictionary.deals.createDealForm.msgErrorCreation,
              "error"
            );
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const sendCreateRequest = (): void => {
    const builtDeals: CreatePartialDiscount[] = [];

    deals.map((deal) => {
      builtDeals.push(
        DtoPartialDiscount.build({
          ...deal,
          venueId: Number(mainForm.form.values.venueId),
          daysHours,
        })
      );
    });

    const { menuUrl } = mainForm.form.values;
    const happyHour: CreateHappyHour = DtoHappyHour.build({
      daysHours,
      menuUrl,
      venueId: Number(mainForm.form.values.venueId),
      deals: builtDeals,
      files: filesQueue.filter((fileQueue) => fileQueue?.generatedName),
    });

    setLoading(true);

    api.happyHours
      .add(happyHour)
      .then(async (data) => {
        setHappyHourId(data.id);
        await updateHappyHourList();
        showAlert("Successfully Created!", "success");
        closeAndReset();
      })
      .catch((error) => {
        if (
          error.response.data ===
          dictionary.deals.happyHours.errors.venueDoesntExist
        ) {
          showAlert(
            dictionary.deals.happyHours.errors.venueDoesntExist,
            "error"
          );
        } else if (
          error.response.data ===
          dictionary.deals.happyHours.errors.atLeastOneDeal
        ) {
          showAlert(dictionary.deals.happyHours.errors.atLeastOneDeal, "error");
        } else if (
          error.response.data ===
          dictionary.deals.happyHours.errors.atLeastOneFile
        ) {
          showAlert(dictionary.deals.happyHours.errors.atLeastOneFile, "error");
        } else if (
          error.response.data ===
          dictionary.deals.happyHours.errors.venueAlreadyUsed
        ) {
          showAlert(
            dictionary.deals.happyHours.errors.venueAlreadyUsed,
            "error"
          );
        } else
          showAlert(dictionary.deals.createDealForm.msgErrorCreation, "error");
      })
      .finally(() => setLoading(false));
  };

  const validateAdvancedForm = () => {
    let schemaRule = {};
    switch (activeCardState) {
      case DealCardState.percent: {
        schemaRule = {
          percent: { type: "percentNumber", required: true },
          rebatedPrice: {
            required: false,
          },
          sellingPrice: {
            required: false,
          },
          customTitle: {
            required: false,
          },
        };
        break;
      }
      case DealCardState.rebatedPrice: {
        schemaRule = {
          rebatedPrice: {
            type: "number",
            required: true,
          },
          percent: { required: false },
          sellingPrice: {
            required: false,
          },
          customTitle: {
            required: false,
          },
        };
        break;
      }
      case DealCardState.sellingPrice: {
        schemaRule = {
          sellingPrice: {
            type: "number",
            required: true,
          },
          percent: { required: false },
          customTitle: {
            required: false,
          },
          rebatedPrice: {
            required: false,
          },
        };
        break;
      }
      case DealCardState.customTitle: {
        schemaRule = {
          customTitle: {
            required: true,
          },
          percent: { required: false },
          rebatedPrice: {
            required: false,
          },
          sellingPrice: {
            required: false,
          },
        };
        break;
      }
    }

    advancedForm.setSchemaRules(schemaRule);
    return advancedForm.validateForm();
  };

  const CreatePartialDiscountTitle = ({
    rebatedPrice,
    percent,
    sellingPrice,
    customTitle,
    subCategoryName,
    itemsName,
  }: {
    rebatedPrice: number;
    percent: number;
    sellingPrice: number;
    customTitle: string;
    subCategoryName: string;
    itemsName: string;
  }) => {
    let title = "";
    if (Number(percent) > 0) {
      title = `${percent}% off ${itemsName}`;
    } else if (Number(rebatedPrice) > 0) {
      title = `$${Number(rebatedPrice)?.toFixed(2)} off ${itemsName}`;
    } else if (Number(sellingPrice) > 0) {
      title = `$${Number(sellingPrice)?.toFixed(2)} ${itemsName}`;
    } else if (customTitle.length > 0) {
      title = customTitle;
    }
    return title;
  };

  const handleSaveAction = () => {
    if (mainForm.validateForm()) {
      if (happyHourId) sendUpdateRequest(happyHourId);
      else sendCreateRequest();
    } else {
      showAlert(
        <div>
          {Object.values(mainForm.form.errors).map((error, key) => (
            <Typography key={key} style={{ color: "red" }}>
              {error}
            </Typography>
          ))}
        </div>,
        "error"
      );

      setIsSaveDisabled(true);
    }
  };

  const handleAddDealAction = () => {
    if (validateAdvancedForm()) {
      const { values } = advancedForm.form;
      const formData = resetInActiveCards(values);
      formData.name = CreatePartialDiscountTitle(formData);
      if (updateDealItem) {
        setDeals((deals) =>
          deals.map((deal) => (deal.id === values.id ? formData : deal))
        );
        setUpdateDealItem(undefined);
      } else {
        increaseCustomFormLengthProperty(1, "lengthDeals");
        setDeals([...deals, { ...formData, id: genRanHex(30) }]);
      }

      handlePressSecondaryButton();
    }
  };

  return (
    <DialogActions
      classes={{
        root: clsx(classes.dialogActions),
      }}>
      <div className={classes.actionFlexItem}>
        <ButtonCancel onClick={handlePressSecondaryButton}>
          {getSecondaryButtonLabel()}
        </ButtonCancel>
        {currentStep === 1 ? (
          <ButtonBlack
            onClick={handleSaveAction}
            disabled={loading || isSaveDisabled}
            className={classes.dialogPrimaryButtonAction}>
            {dictionary.general.save}
          </ButtonBlack>
        ) : (
          <ButtonBlack
            onClick={handleAddDealAction}
            className={classes.dialogPrimaryButtonAction}>
            {getAddDealButtonLabel()}
          </ButtonBlack>
        )}
      </div>
    </DialogActions>
  );
};

export default Actions;
