import { Typography } from "@material-ui/core";
import React, { FC, useState } from "react";

import OcietySelect from "../../../../../../../../common/components/forms/OcietySelect";
import OptionItem from "../../../../../../../../common/components/forms/OcietySelect/OptionItem";
import PhoneNumberInput from "../../../../../../../../common/components/PhoneNumberInput";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { useStyles } from "../../styles";
import Props from "./Props";

const PhoneNumberStep: FC<Props> = (props: Props) => {
  const { onSetPhoneNumber, phoneNumberInputError } = props;
  const classes = useStyles();

  const [countryCode, setCountryCode] = useState("1");
  const [smsMFAPhone, setSmsMFAPhone] = useState("");

  let isPhoneNumberValid = false;

  if (smsMFAPhone) {
    const match = smsMFAPhone.match(/\d/g);

    if (match) {
      isPhoneNumberValid = match.length === 11;
    }
  }

  if (isPhoneNumberValid) {
    onSetPhoneNumber(+smsMFAPhone);
  } else {
    onSetPhoneNumber(0);
  }

  const onChangePhoneNumber = (phoneNumber: string) => {
    phoneNumber = phoneNumber.replace(/\D/g, "");
    setSmsMFAPhone(phoneNumber);
  };
  return (
    <div>
      <Typography variant="h1" className={classes.headerTitle}>
        {dictionary.profile.mfa.letsSetupYouPhone}
      </Typography>
      <Typography variant="body1" className={classes.helperTxt}>
        {dictionary.profile.mfa.enterPhoneNumber}
      </Typography>

      <div className={classes.phoneNumberWrapper}>
        <OcietySelect
          required={true}
          placeholder={dictionary.profile.mfa.country}
          options={[new OptionItem("1", "United State (+1)")]}
          onChangeSelect={(value: string) => setCountryCode(value)}
          value={countryCode}
        />
        <PhoneNumberInput
          value={smsMFAPhone}
          onChange={(ev) => {
            onChangePhoneNumber(ev.target.value);
          }}
          className={classes.phoneNumberField}
          error={phoneNumberInputError}
          label={dictionary.profile.mfa.phoneNumber}
          name="currentPass"
          maskCode={countryCode}
        />
      </div>

      <Typography variant="body1" className={classes.helperTxt}>
        {dictionary.profile.mfa.sequrityTxt}
      </Typography>
    </div>
  );
};
export default PhoneNumberStep;
