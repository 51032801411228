import { Tooltip } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { ReactComponent as QuestionIcon } from "../../svg/icons/question-circle.svg";
import Props from "./Props";
import { useStyles } from "./styles";

const OcietyTooltip: FC<Props> = (props: Props) => {
  const { children, iconClasses, ...rest } = props;

  const classes = useStyles();

  return (
    <Tooltip {...rest} classes={{ tooltip: classes.tooltip }}>
      {children || (
        <div className={clsx(classes.root, iconClasses?.iconContainer)}>
          <QuestionIcon />
        </div>
      )}
    </Tooltip>
  );
};

export default OcietyTooltip;
