import { Typography } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

import { ReactComponent as IconCircleCheckboxFilled } from "../../../../common/svg/icons/circle-checkbox-filled.svg";
import { ReactComponent as IconTrashBucketTransparent } from "../../../../common/svg/icons/trash-bucket-transparent.svg";
import { utilsFile } from "../../../../utils/file.util";
import { HappyHourFile } from "../../types/interfaces/HappyHourFile.interface";
import LinearProgressWithLabel from "./components/LinearProgressWithLabel";
import Props from "./Props";
import { useStyles } from "./styles";

const FileUploadProgress: FC<Props> = ({
  file,
  onFileUpload,
  onRemove,
}: Props) => {
  const classes = useStyles();

  const [progressUpload, setProgressUpload] = useState(0);

  const progressUpdate = (progressValue: number) => {
    if (progressValue > 0) {
      setProgressUpload(progressValue);
    }
  };

  useEffect(() => {
    if (file && "type" in file && file?.type)
      onFileUpload(file, progressUpdate).catch((e: string) => console.error(e));
  }, [file, onFileUpload]);

  const RenderFileProgress = ({ file: { name } }: { file: any }) => (
    <div className={classes.renderFileProgressContainer}>
      <div className={classes.renderFileProgressContent}>
        <Typography
          className={classes.renderFileProgressName}
          variant="subtitle2">
          {name}
        </Typography>
        <LinearProgressWithLabel value={progressUpload} />
      </div>
    </div>
  );
  const RenderFileUploaded = ({
    happyHourFile: { originalName, sizeBytes, generatedName },
  }: {
    happyHourFile: HappyHourFile;
  }) => (
    <div className={classes.renderFileUploadedContainer}>
      <IconTrashBucketTransparent
        onClick={() => onRemove(generatedName)}
        className={classes.iconTrashBucketTransparent}
      />
      <div className={classes.renderFileUploadedContent}>
        <Typography
          variant="body1"
          className={classes.renderFileUploadedOriginalName}>
          {originalName}
          <IconCircleCheckboxFilled
            className={classes.iconCircleCheckboxFilled}
          />
        </Typography>
        <Typography variant="body1" className={classes.textSizeBytes}>
          {utilsFile.convertBytesToMegabytes(sizeBytes)} MB
        </Typography>
        <LinearProgressWithLabel value={100} />
      </div>
    </div>
  );

  return file && "type" in file && file?.type ? (
    <RenderFileProgress file={file} />
  ) : file ? (
    <RenderFileUploaded happyHourFile={file} />
  ) : (
    <></>
  );
};
export default FileUploadProgress;
