import { SelectClassKey } from "@material-ui/core";
import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "wrapper"
  | "filterSelect"
  | "flexRowBetweenCenter"
  | "lockedMask";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(40 / 8),
      },
      wrapper: {
        height: "100%",
        maxWidth: "100%",
        padding: 30,
      },
      filterSelect: {
        textAlign: "left",
        marginLeft: "auto",
        marginRight: theme.spacing(3),
      },
      flexRowBetweenCenter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
      lockedMask: {
        transform: "translate(100%, 0px)",
      },
    })
);
export const useSelectStyles: () => Partial<ClassNameMap<SelectClassKey>> =
  makeStyles(() =>
    createStyles({
      root: {
        padding: 14,
        width: 139,
      },
    })
  );
