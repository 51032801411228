import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames =
  | "root"
  | "listItemTitle"
  | "listItemDescription"
  | "listItem"
  | "listItemActions"
  | "deleteButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
      },
      listItemTitle: {
        fontWeight: 600,
        fontSize: 14,
        color: "#747982",
      },
      listItemDescription: {
        fontWeight: 600,
        fontSize: 16,
        color: "#37393F",
      },
      listItem: {
        padding: "8px 0",
        borderBottom: "solid 1px #E5E7EF",
        alignItems: "flex-start",
        "& > div:last-child": {
          textAlign: "right",
        },
      },
      listItemActions: {
        marginTop: 18,
        padding: "6px 0",
        justifyContent: "end",
      },
      deleteButton: {
        minHeight: 42,
        marginRight: 17,
      },
    })
);
