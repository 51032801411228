import React, { FC } from "react";

import StatisticsBlockShort from "../StatisticsBlockShort";

const TotalUsersReviews: FC<any> = ({ value, difference, description }) => {
  return (
    <StatisticsBlockShort
      loading={false}
      score={value}
      difference={difference}
      description={description}
    />
  );
};

export default TotalUsersReviews;
