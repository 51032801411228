import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import ButtonBlack from "../ButtonBlack";
import Props from "./Props";
import { useStyles } from "./styles";

const SuccessDialog: FC<Props> = (props: Props) => {
  const { open, onClose, onConfirm, title, message } = props;
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.titleWrapper} disableTypography>
        <Typography className={classes.title}>
          {title || dictionary.successDialog.title}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.message}>{message}</Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container>
          <Grid item xs={12} className={classes.actionButtonContainer}>
            <ButtonBlack onClick={onConfirm || onClose}>
              {dictionary.successDialog.okayButton}
            </ButtonBlack>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
