import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FC, useEffect, useState } from "react";

import { usePrevious } from "../../../hooks";
import { useRestApi } from "../../../hooks/useRestApi";
import { VENUE_LIST_BASIC } from "./api";
import IVenueBasic from "./models/IVenueBasic";
import Props from "./Props";

const VenueAutocompleteSearch: FC<Props> = (props: Props) => {
  const {
    venueId,
    onChange,
    error,
    label,
    fullWidth,
    requried,
    style,
    name,
    inputRef = null,
  } = props;
  const prevVenueId = usePrevious(venueId);
  const [value, setValue] = useState<IVenueBasic | null>(null);
  const [{ data: venues, loading }, getVenues] = useRestApi<IVenueBasic[]>(
    VENUE_LIST_BASIC,
    "GET"
  );

  const handleAutocompleteChange = (_: any, option: IVenueBasic | null) => {
    onChange(option?.id);
    setValue(option);
  };

  useEffect(() => {
    getVenues().catch((e) => console.error(e));
  }, [getVenues]);

  useEffect(() => {
    const shouldSetDefault = venueId !== prevVenueId;

    if (shouldSetDefault && venues && venueId) {
      setValue(venues.filter((venue) => venue.id === venueId)[0]);
    }
  }, [venueId, prevVenueId, venues]);

  return (
    <Autocomplete
      style={style}
      value={value}
      getOptionSelected={(option) => option.id === venueId}
      id="venue-select-autocomplete"
      loading={loading}
      fullWidth={fullWidth}
      options={venues || []}
      getOptionLabel={(venue: IVenueBasic) =>
        `${venue?.name} ${venue?.address}`
      }
      onChange={handleAutocompleteChange}
      ref={inputRef}
      renderInput={(params) => (
        <TextField
          {...params}
          required={!!requried}
          fullWidth
          error={!!error}
          helperText={error}
          label={label}
          value={venueId}
          name={name || "venue"}
          type="text"
          variant="outlined"
        />
      )}
    />
  );
};

export default VenueAutocompleteSearch;
