import { Dialog, Grid } from "@material-ui/core";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AlertContext } from "../../../common/components/Alert/AlertContextProvider";
import ConfirmDialog from "../../../common/components/dialogs/ConfirmDialog";
import { ConfirmModalType } from "../../../common/components/dialogs/ConfirmDialog/Props";
import { useRestApi } from "../../../common/hooks/useRestApi";
import dictionary from "../../../i18n/en_US/dictionary";
import { getOwnVenues } from "../../../store/currentVenue/actions";
import { CurrentVenueState } from "../../../store/currentVenue/reducer";
import { AppState } from "../../../store/rootReducer";
import { GET_VENUE_PACKAGES } from "../../Plan/api";
import { Package } from "../../Plan/models";
import PromotionFormDialog from "../../Promotions/AdminVenue/dialogs/PromotionFormDialog";
import { PromotionFormDialogContext } from "../../Promotions/AdminVenue/dialogs/PromotionFormDialog/PromotionFormDialogContextProvider";
import { DELETE_MANAGER_PROMOTION, DUPLICATE_MANAGER_PROMOTION } from "./api";
import PromotionsList from "./components/PromotionsList";
import { useStyles } from "./styles";

const ManagerPromotions: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showAlert } = useContext(AlertContext);
  const { setPromotionId } = useContext(PromotionFormDialogContext);
  const [refreshToken, setRefreshToken] = useState(0);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [confirmDialogType, setConfirmDialogType] = useState<
    ConfirmModalType | undefined
  >(undefined);
  const [editPromotionId, setEditPromotionId] = useState<number | null>(null);
  const [selectedPromotionId, setSelectedPromotionId] = useState<number | null>(
    null
  );
  const { currentVenue } = useSelector<AppState, CurrentVenueState>(
    (appState) => appState.currentVenue
  );
  const venueId: number = currentVenue ? currentVenue.id : 0;
  const [, getVenuePackages] = useRestApi<Package[]>(
    GET_VENUE_PACKAGES(venueId),
    "GET"
  );
  const [, deletePromotion] = useRestApi(
    DELETE_MANAGER_PROMOTION(
      venueId,
      selectedPromotionId ? selectedPromotionId : 0
    ),
    "DELETE"
  );
  const [, duplicatePromotion] = useRestApi<any, { promotionId: number }>(
    DUPLICATE_MANAGER_PROMOTION(venueId),
    "POST"
  );

  const openFormDialog = useCallback(() => {
    setPromotionId(0);
    setFormDialogOpen(true);
  }, [setPromotionId]);

  useEffect(() => {
    dispatch(getOwnVenues());
    if (!!venueId) {
      getVenuePackages().catch((e) => console.error(e));
    }
  }, [getVenuePackages, dispatch, venueId]);

  const openEditFormDialog = useCallback(
    (promotionId: number) => {
      setEditPromotionId(promotionId);
      setSelectedPromotionId(null);
      setPromotionId(0);
      setFormDialogOpen(true);
    },
    [setPromotionId]
  );

  const openDeleteDialog = useCallback((promotionId: number) => {
    setSelectedPromotionId(promotionId);
    setConfirmDialogType(ConfirmModalType.YES_DELETE);
  }, []);

  const openDuplicateDialog = useCallback((promotionId: number) => {
    setSelectedPromotionId(promotionId);
    setConfirmDialogType(ConfirmModalType.CONFIRM);
  }, []);

  const closeConfirmDialog = useCallback(() => {
    setSelectedPromotionId(null);
    setConfirmDialogType(undefined);
  }, []);

  const closeFormDialog = useCallback(() => {
    setFormDialogOpen(false);
    setEditPromotionId(null);
    setSelectedPromotionId(null);
    setPromotionId(0);
  }, [setPromotionId]);

  const refreshGrid = useCallback(() => {
    setRefreshToken(+new Date());
  }, []);

  const confirmDeletePromotion = async () => {
    if (!selectedPromotionId) {
      return;
    }

    try {
      const result = await deletePromotion();

      if (result) {
        showAlert(dictionary.deals.deleteDealSuccess, "success");
        refreshGrid();
      } else {
        showAlert(dictionary.deals.deletePromotonError, "error");
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(dictionary.deals.deletePromotonError, "error");
    }
  };

  const confirmDuplicatePromotion = async () => {
    if (!selectedPromotionId) {
      return;
    }

    try {
      const result = await duplicatePromotion({
        promotionId: selectedPromotionId,
      });

      if (result) {
        showAlert(
          dictionary.deals.duplicateDeal.createdDealDuplicate,
          "success"
        );

        if (result.id) {
          openEditFormDialog(result.id);
        }
        refreshGrid();
      } else {
        showAlert(dictionary.deals.duplicateDeal.errorDealDuplicate, "error");
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(dictionary.deals.duplicateDeal.errorDealDuplicate, "error");
    }
  };
  return (
    <div>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} lg={12} md={12} xl={12}>
          <PromotionsList
            refreshToken={refreshToken}
            openEditDialog={openEditFormDialog}
            openDeleteDialog={openDeleteDialog}
            openCreateDialog={openFormDialog}
            openDuplicateDialog={openDuplicateDialog}
            venueId={venueId}
          />
        </Grid>
      </Grid>
      <PromotionFormDialog
        refresh={refreshGrid}
        promotionId={editPromotionId}
        closeDialog={closeFormDialog}
        open={formDialogOpen}
      />
      <ConfirmDialog
        open={!!selectedPromotionId && !!confirmDialogType}
        onClose={closeConfirmDialog}
        onConfirm={
          confirmDialogType === ConfirmModalType.YES_DELETE
            ? confirmDeletePromotion
            : confirmDuplicatePromotion
        }
        subtitle={
          confirmDialogType === ConfirmModalType.YES_DELETE
            ? dictionary.deals.onDeleteMessage
            : dictionary.deals.onDuplicateMessage
        }
        type={confirmDialogType}
        message=""
      />
    </div>
  );
};

export default ManagerPromotions;
