import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";
import { colors } from "../../theme/colors";

export type VenuesClassNames =
  | "root"
  | "list"
  | "addressContainer"
  | "paper"
  | "cardContainer"
  | "title"
  | "tableOfContent"
  | "wrapLongText";

export const useStyles: () => ClassNameMap<VenuesClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(0),
          width: 200,
        },
      },
      list: {
        marginLeft: 15,
      },
      addressContainer: {
        border: "1px solid",
        padding: 15,
        paddingLeft: 30,
        backgroundColor: colors.grey.lighten,
      },
      paper: {
        padding: 25,
        margin: 20,
        minWidth: 300,
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          margin: 0,
        },
      },
      cardContainer: {
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(1, 0),
        },
      },
      title: {
        textAlign: "center",
      },
      tableOfContent: {
        marginLeft: 24,
      },
      wrapLongText: {
        overflowWrap: "break-word",
        wordWrap: "break-word",
      },
    })
);
