import React from "react";
import ReactDOM from "react-dom";
import { install } from "resize-observer";
import WebFont from "webfontloader";

import App from "./App";
import * as serviceWorker from "./sw/serviceWorker";

// @ts-ignore init polyfill
if (!window.ResizeObserver) {
  install();
}

WebFont.load({
  google: {
    families: ["Nunito Sans:100,200,300,400,500,600,700,800,900"],
  },
});

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.register();
