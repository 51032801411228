import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "inputField"
  | "outlinedInput"
  | "inputBase"
  | "tagsContainer"
  | "removeAllButton"
  | "removeAllText"
  | "tag"
  | "addTagButton"
  | "removeAllButtonWrapper"
  | "errorMsg"
  | "tooltipContainer";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      inputBase: {
        border: `none`,
        padding: 0,
      },
      inputField: {
        height: theme.spacing(5),
      },
      tagsContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        marginTop: theme.spacing(4),
        minHeight: 62,
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        height: "auto",
        border: `solid 1px ${colors.grey.medium}`,
        borderRadius: 3,
        paddingLeft: 11,
      },
      removeAllButton: {
        textTransform: "none",
        color: colors.red.primary,
      },
      removeAllText: {
        fontWeight: "bold",
        fontSize: 16,
      },
      removeAllButtonWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      tag: {
        height: theme.spacing(5),
        color: colors.grey.additional,
        backgroundColor: colors.grey.stroke,
      },
      addTagButton: {
        textTransform: "none",
        color: colors.blue.primary2,
        fontSize: 16,
        fontWeight: "bold",
        "&:disabled": {
          color: colors.grey.disabled,
          pointerEvents: "none",
        },
      },
      outlinedInput: {
        padding: "10px 0px 10px 5px",
      },
      errorMsg: {
        color: colors.red.primary,
        marginTop: 7,
      },
      tooltipContainer: {
        display: "inline-block",
      },
    })
);
