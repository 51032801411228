import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "contactItem"
  | "contactIcon"
  | "label"
  | "contact";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        height: "100%",
        [theme.breakpoints.up("lg")]: {
          padding: theme.spacing(5, 30 / 8),
        },
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(3, 2),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(3, 2),
        },
      },
      contactItem: {
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(60 / 8, 30 / 8),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(3, 2),
        },
        "&:not(:last-of-type)": {
          borderBottom: "1px solid rgba(227, 231, 236, 0.44)",
        },
      },
      contactIcon: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("lg")]: {
          marginRight: theme.spacing(40 / 8),
        },
      },
      label: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
      },
      contact: {
        color: colors.red.primary,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(18),
        marginTop: theme.spacing(2),
      },
    })
);
