import { MediaContentViewsData } from "./api";

export const chartDataMock: MediaContentViewsData[] = [
  {
    month: "Jan",
    videos: 64,
    images: 77,
  },
  {
    month: "Feb",
    videos: 43,
    images: 23,
  },
  {
    month: "Mar",
    videos: 12,
    images: 65,
  },
  {
    month: "Apr",
    videos: 55,
    images: 55,
  },
  {
    month: "May",
    videos: 34,
    images: 88,
  },
  {
    month: "Jun",
    videos: 23,
    images: 44,
  },
];
