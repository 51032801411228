import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent, FC, useContext, useState } from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import TextMessage from "../../../../common/components/TextMessage";
import { useForm } from "../../../../common/hooks";
import dictionary from "../../../../i18n/en_US/dictionary";
import { changePassword } from "./api";
import { ChangePasswordFormField, createPromotionForm, schema } from "./form";
import ChangePasswordRequest from "./models/ChangePasswordRequest";
import IChangePasswordRequest from "./models/IChangePasswordRequest";
import Props from "./Props";
import { useStyles } from "./styles";

const ChangePasswordForm: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { form, setAndValidate, validateForm } = useForm(
    schema,
    createPromotionForm
  );
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const { showAlert } = useContext(AlertContext);

  const handleChangeFormTextField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;

    if (errorMsg) {
      setErrorMsg("");
    }

    setAndValidate(key as ChangePasswordFormField, value);
  };

  const handleChangePassword = async () => {
    if (!validateForm()) {
      return;
    }

    if (form.values.currentPassword === form.values.newPassword) {
      showAlert(
        dictionary.venueAdmin.profile.changePassword.similarPasswordsWarning,
        "warning"
      );
      return;
    }

    try {
      setLoading(true);

      const changePasswordRequest: IChangePasswordRequest =
        new ChangePasswordRequest(
          form.values.currentPassword,
          form.values.newPassword
        );

      await changePassword(changePasswordRequest);
      setSuccessMsg(
        dictionary.venueAdmin.profile.changePassword.successMessage
      );

      setErrorMsg("");
    } catch (e) {
      if (!e) {
        return;
      }

      if (e.data) {
        setErrorMsg(e.data);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Typography variant="h4">
            {dictionary.venueAdmin.profile.changePassword.title}
          </Typography>
          <TextMessage className={classes.errorMessage} type="error">
            {errorMsg}
          </TextMessage>
          <TextMessage className={classes.successMessage} type="success">
            {successMsg}
          </TextMessage>
          {loading && <LoadingIndicator withMask />}
          <TextField
            fullWidth
            required
            label={dictionary.venueAdmin.profile.changePassword.currentPassword}
            name="currentPassword"
            type="password"
            error={!!form.errors.currentPassword}
            helperText={form.errors.currentPassword}
            onChange={handleChangeFormTextField}
            style={{ marginTop: "1rem" }}
            value={form.values.currentPassword}
            variant="outlined"
          />
          <TextField
            fullWidth
            required
            label={dictionary.venueAdmin.profile.changePassword.newPassword}
            name="newPassword"
            error={!!form.errors.newPassword}
            helperText={form.errors.newPassword}
            onChange={handleChangeFormTextField}
            style={{ marginTop: "1rem" }}
            type="password"
            value={form.values.newPassword}
            variant="outlined"
          />
          <TextField
            fullWidth
            required
            label={dictionary.venueAdmin.profile.changePassword.newPassword}
            name="confirmPassword"
            error={!!form.errors.confirmPassword}
            helperText={form.errors.confirmPassword}
            onChange={handleChangeFormTextField}
            style={{ marginTop: "1rem" }}
            type="password"
            value={form.values.confirmPassword}
            variant="outlined"
          />

          <Button
            style={{ marginTop: 30 }}
            color="primary"
            variant="outlined"
            disabled={!form.isValid || loading}
            onClick={handleChangePassword}>
            {dictionary.venueAdmin.profile.changePassword.btnText}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChangePasswordForm;
