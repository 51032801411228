import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { usePagination } from "../../common/hooks/usePagination";
import { setForm } from "../../store/forms/actions";
import { AppState } from "../../store/rootReducer";
import { AddNewVenueForm } from "./components/AddNewVenueDialog/form";
import { getAllUnclaimedVenues } from "./ducks/actions";

export function useDataEntryBusinesses() {
  const dispatch = useDispatch();
  const {
    search,
    page,
    rowsPerPage,
    searchPage,
    skip,
    setPage,
    setSearchPage,
    handleSearch,
    handleRowsPerPageChange,
  } = usePagination();

  const [isOpenedVenueMode, setIsOpenedVenueMode] = useState(false);
  const [editVenueId, setEditVenueId] = useState(0);

  const {
    unclaimedVenues,
    unclaimedVenuesCount,
    loading: unclaimedVenueLoading,
  } = useSelector((state: AppState) => state.unclaimedVenue);

  const fetchUnclaimedData = useCallback(() => {
    dispatch(getAllUnclaimedVenues(skip, rowsPerPage, search));
  }, [dispatch, rowsPerPage, search, skip]);

  const handleOpenVenueMode = () => {
    setIsOpenedVenueMode(true);
  };

  const handleCloseVenueMode = () => {
    setIsOpenedVenueMode(false);
    setEditVenueId(0);
    dispatch(setForm("addNewVenue", AddNewVenueForm));
  };

  return {
    search,
    page,
    rowsPerPage,
    searchPage,
    editVenueId,
    isOpenedVenueMode,
    unclaimedVenues,
    unclaimedVenuesCount,
    unclaimedVenueLoading,
    setEditVenueId,
    setPage,
    setSearchPage,
    handleSearch,
    handleRowsPerPageChange,
    handleOpenVenueMode,
    handleCloseVenueMode,
    fetchUnclaimedData,
  };
}

export default useDataEntryBusinesses;
