import { http } from "../../../../../../config/api";

const MFA_SMS_DISABLE_REQUEST = "profile/mfa/sms-disable";

export const disableSmsMFAMethod = async (): Promise<any> =>
  http
    .post(MFA_SMS_DISABLE_REQUEST)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
