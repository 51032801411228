import { Typography } from "@material-ui/core";
import Holidays from "date-holidays";
import React, { FC, useContext, useState } from "react";
import { useSelector } from "react-redux";

// eslint-disable-next-line max-len
import { AlertContext } from "../../../../../../../../common/components/Alert/AlertContextProvider";
import OcietyIcon from "../../../../../../../../common/components/OcietyIcon";
// eslint-disable-next-line max-len
import OcietyTooltip from "../../../../../../../../common/components/OcietyTooltip";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../../../../../store/rootReducer";
import { AustriaStatesMapping } from "./config";
import Props from "./Props";
import { useStyles } from "./styles";

const HolidaysLoad: FC<Props> = (props: Props) => {
  const { handleImportHolidays } = props;
  const [hd] = useState(new Holidays());
  const { showAlert } = useContext(AlertContext);
  const { forms } = useSelector((state: AppState) => state);
  const form = forms.venue;

  const classes = useStyles();

  const getCountryCode = () => {
    const countryName = form.values.country;

    if (!countryName) {
      return null;
    }

    return countryName === "Austria" ? "AT" : "US";
  };

  const getStateCode = (countryCode: string) => {
    const states = hd.getStates(countryCode);
    const stateFromForm = form.values
      .state as keyof typeof AustriaStatesMapping;
    const isAustria = countryCode === "AT";

    if (!stateFromForm) {
      return null;
    }

    for (const [stateCode, stateName] of Object.entries(states)) {
      if (
        stateName ===
        (isAustria ? AustriaStatesMapping[stateFromForm] : stateName)
      ) {
        return stateCode;
      }
    }

    return null;
  };

  const handleLoadHolidays = () => {
    const country = getCountryCode();

    if (!country) {
      return showAlert(
        "Please select country in your venue settings form first",
        "warning"
      );
    }

    const state = getStateCode(country);

    if (!state) {
      return showAlert(
        "Please select state in your venue settings form first",
        "warning"
      );
    }

    hd.init(country, state);
    handleImportHolidays(hd.getHolidays(new Date().getFullYear()));
  };

  return (
    <div className={classes.root} onClick={handleLoadHolidays}>
      <OcietyIcon
        name={"plusRed"}
        viewBox={"0 0 27 27"}
        width={"27"}
        height={"27"}
      />
      <Typography className={classes.label}>
        {dictionary.venues.venueForm.holidaysLoadButton}
      </Typography>
      <OcietyTooltip title={dictionary.venues.venueForm.holidaysLoadTooltip} />
    </div>
  );
};

export default HolidaysLoad;
