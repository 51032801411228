import { FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import React, { ChangeEvent, FC, useEffect } from "react";

import { PackageUID } from "../../../store/currentVenue/types";
import { useRestApi } from "../../hooks/useRestApi";
import { useVenueHasPackage } from "../../hooks/useVenueHasPacakage";
import SelectIcon from "../SelectIcon";
import { AgeGroup, GET_AGE_GROUP_OPTIONS } from "./api";
import Props from "./Props";
import { useStyles } from "./styles";

const AgeGroupSelect: FC<Props> = (props: Props) => {
  const { ageGroupId, onChangeAgeGroupId, className } = props;
  const classes = useStyles();

  const [{ data }, getAgeGroups] = useRestApi<Array<AgeGroup>>(
    GET_AGE_GROUP_OPTIONS,
    "GET"
  );

  const hasPackage = useVenueHasPackage(PackageUID.CUSTOMER);

  useEffect(() => {
    getAgeGroups().catch((e) => console.error(e));
  }, [getAgeGroups]);

  const ageGroups = data || [];

  const handleChangeAge = (e: ChangeEvent<any>) => {
    const { value } = e.target;

    onChangeAgeGroupId(value);
  };

  return (
    <FormControl
      variant="filled"
      className={clsx(classes.root, "select-no-legend", className)}>
      <Select
        disabled={!hasPackage}
        value={ageGroupId || 0}
        onChange={handleChangeAge}
        disableUnderline
        classes={{ select: classes.select }}
        className={classes.selectWrapper}
        IconComponent={(iconProps) => (
          <SelectIcon
            {...iconProps}
            viewBox={false}
            iconName={"triangleDown"}
            style={{ top: 16, width: 8, height: 6, right: 16 }}
          />
        )}>
        <MenuItem key={"none"} value={0} selected>
          {"Age Group"}
        </MenuItem>
        {ageGroups.map((ageGroup) => (
          <MenuItem key={ageGroup.id} value={ageGroup.id}>
            {ageGroup.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AgeGroupSelect;
