import { Paper } from "@material-ui/core";
import React, { FC } from "react";

import PageTitle from "../../common/components/PageTitle";
import dictionary from "../../i18n/en_US/dictionary";
import TabsClaimRequests from "./components/Tabs";
import VenueClaimRequestsTable from "./components/VenueClaimRequestsTable";
import { useStyles } from "./styles";

const VenueClaimRequests: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PageTitle>{dictionary.venueClaim.listTitle}</PageTitle>
      <Paper className={classes.content}>{<TabsClaimRequests />}</Paper>
    </div>
  );
};

export default VenueClaimRequests;
