import { RadioGroup, Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import OcietyBlockWrapperForRadioButton from "../../../../../../../../../common/components/OcietyBlockWrapperForRadioButton";
import { useCurrentVenueId } from "../../../../../../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../../../../../../common/hooks/useRestApi";
import dictionary from "../../../../../../../../../i18n/en_US/dictionary";
import { PromotionFormDialogContext } from "../../../../PromotionFormDialogContextProvider";
import { PromotionKind } from "../../../PromotionFormDialogActions/PromotionKind.enum";
import { useStyles } from "../../styles";

const wrapperStyle = {
  height: "100%",
};

export const SelectDealKindStep = () => {
  const classes = useStyles();
  const { mainForm } = useContext(PromotionFormDialogContext);
  const { setFormValue } = mainForm;

  const [selectedDealKind, setSelectedDealKind] = useState<PromotionKind>(
    PromotionKind.DEAL
  );

  useEffect(() => {
    setSelectedDealKind(mainForm.form.values.kind as PromotionKind);
  }, [mainForm.form.values.kind]);

  const handleChangeDealKind = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setFormValue("kind", value as PromotionKind);
  };

  const [
    { data: isFullOrderPromotionAllowed },
    getIsFullOrderPromotionAllowed,
  ] = useRestApi<{ allowed: false }>(
    `venue/${useCurrentVenueId()}/full-order-promotion-allowed`,
    "GET",
    undefined,
    true
  );

  useEffect(() => {
    if (isFullOrderPromotionAllowed === null) {
      getIsFullOrderPromotionAllowed();
    }
  }, [getIsFullOrderPromotionAllowed, isFullOrderPromotionAllowed]);

  const isNotVoucherAllowed =
    isFullOrderPromotionAllowed !== null &&
    isFullOrderPromotionAllowed.allowed === false;

  return (
    <RadioGroup
      aria-label="priceRange"
      name="price"
      value={selectedDealKind}
      onChange={handleChangeDealKind}>
      <div className={classes.radioWrapper}>
        <OcietyBlockWrapperForRadioButton
          isActive={selectedDealKind === PromotionKind.DEAL}
          label={
            <div className={classes.textAlignLeft}>
              <Typography variant="h2">
                {dictionary.deals.createDealForm.ocietyDeals}
              </Typography>
              <Typography>
                {dictionary.deals.createDealForm.ocietyDealsDescr}
              </Typography>
            </div>
          }
          value={PromotionKind.DEAL}
          wrapperStyle={wrapperStyle}
          rootClassName={classes.radioRoot}
        />
      </div>
      <div className={classes.radioWrapper}>
        <OcietyBlockWrapperForRadioButton
          isActive={selectedDealKind === PromotionKind.SPECIAL}
          label={
            <div className={classes.textAlignLeft}>
              <Typography variant="h2">
                {dictionary.deals.createDealForm.specials}
              </Typography>
              <Typography>
                {dictionary.deals.createDealForm.specialsDesc}
              </Typography>
            </div>
          }
          value={PromotionKind.SPECIAL}
          wrapperStyle={wrapperStyle}
          rootClassName={classes.radioRoot}
        />
      </div>
      <div className={classes.radioWrapper}>
        <OcietyBlockWrapperForRadioButton
          isActive={selectedDealKind === PromotionKind.VOUCHER}
          disabled={isNotVoucherAllowed}
          label={
            <div className={classes.textAlignLeft}>
              <Typography
                variant="h2"
                className={
                  isNotVoucherAllowed ? classes.disabledRadioText : ""
                }>
                {dictionary.deals.createDealForm.ocietyVoucher}
              </Typography>
              <Typography
                className={
                  isNotVoucherAllowed ? classes.disabledRadioText : ""
                }>
                {dictionary.deals.createDealForm.ocietyVoucherDesc}
              </Typography>
              {isNotVoucherAllowed ? (
                <div className={classes.disabledRadioHintWrap}>
                  <ErrorOutlineIcon className={classes.disabledRadioInfoIcon} />
                  <Link
                    to="/venue-settings"
                    className={classes.disabledRadioInfoMainMenuLink}>
                    <Typography
                      className={classes.disabledRadioInfoMainMenuText}>
                      {
                        dictionary.deals.createDealForm
                          .ocietyVoucherDisabledMenuUpload
                      }
                    </Typography>
                  </Link>
                  <Typography className={classes.inlineBlock}>
                    {
                      dictionary.deals.createDealForm
                        .ocietyVoucherDisabledMenuUploadReq
                    }
                  </Typography>
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          value={PromotionKind.VOUCHER}
          wrapperStyle={wrapperStyle}
          rootClassName={classes.radioRoot}
        />
      </div>
      <div className={classes.radioWrapper}>
        <OcietyBlockWrapperForRadioButton
          isActive={selectedDealKind === PromotionKind.GIVEAWAY}
          label={
            <div className={classes.textAlignLeft}>
              <Typography variant="h2">
                {dictionary.deals.createDealForm.giveawaysKindSelectTitle}
              </Typography>
              <Typography>
                {dictionary.deals.createDealForm.giveawaysKindSelectDescription}
              </Typography>
            </div>
          }
          value={PromotionKind.GIVEAWAY}
          wrapperStyle={wrapperStyle}
          rootClassName={classes.radioRoot}
        />
      </div>
    </RadioGroup>
  );
};
