import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { ReactComponent as ArrowBottomRightIcon } from "../../../../../../common/svg/icons/arrow-bottom-left.svg";
import { ReactComponent as ArrowTopLeftIcon } from "../../../../../../common/svg/icons/arrow-top-left.svg";
import { ColorsRed } from "../../../../../../theme/colors";
import Props from "./Props";
import { useStyles } from "./styles";

const StatisticsBlock: FC<Props> = (props: Props) => {
  const { description, score, difference } = props;
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title}>{description}</Typography>
      <div className={classes.description}>
        <Typography variant={"h1"} style={{ marginRight: 8 }}>
          {score}
        </Typography>
        {score == difference || difference === 0 ? (
          <></>
        ) : (
          <div
            style={{
              background:
                difference > 0
                  ? "rgba(121, 193, 119, 0.1)"
                  : "rgba(254, 84, 84, 0.1)",
            }}
            className={clsx(classes.icon)}>
            <div>
              {difference > 0 ? (
                <ArrowTopLeftIcon className={classes.arrowTopLeftIcon} />
              ) : (
                <ArrowBottomRightIcon
                  className={classes.arrowBottomRightIcon}
                />
              )}
            </div>
            <div style={{ marginLeft: 10 }}>
              <span
                className={classes.difference}
                style={{
                  color: difference > 0 ? "#63BF61" : ColorsRed._FE5454,
                }}>
                {Math.abs(difference)}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StatisticsBlock;
