import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames = "root" | "forgotPasswordText" | "buttonWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        flexGrow: 1,
        marginBottom: 20,
        marginTop: 20,
      },
      forgotPasswordText: {
        color: "#FE5454",
        fontSize: 16,
        fontWeight: "bold",
        paddingTop: 10,
      },
      buttonWrapper: {
        display: "flex",
        gap: 30,
        alignItems: "center",
        flexWrap: "wrap",
      },
    })
);
