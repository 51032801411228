import { http } from "../../../../config/api";
import { NotificationEnum } from "./enums";

const PROFILE_NOTIFICATIONS = "profile/notifications";
export const getNotifications = async (): Promise<any> =>
  http
    .get(PROFILE_NOTIFICATIONS)
    .then((response) => [...response.data])
    .catch((error) => {
      throw error.response;
    });

export const saveNotifications = async (
  notifications: NotificationEnum[]
): Promise<any> =>
  http
    .post(PROFILE_NOTIFICATIONS, {
      notifications,
    })
    .then((response) => [...response.data])
    .catch((error) => {
      throw error.response;
    });
