import IVenueAdminProfile from "../models/IVenueAdminProfile";
import {
  GET_PROFILE_FULFILLED,
  GET_PROFILE_PENDING,
  GET_PROFILE_REJECTED,
  ProfileActionTypes,
  SAVE_PROFILE_FULFILLED,
  SAVE_PROFILE_PENDING,
  SAVE_PROFILE_REJECTED,
} from "./types";

interface IVenueAdminProfileState {
  profile?: IVenueAdminProfile;
  error?: string;
  loading: boolean;
  saved: boolean;
}

const defaultState: IVenueAdminProfileState = {
  profile: undefined,
  error: undefined,
  loading: false,
  saved: false,
};

export default (
  state: IVenueAdminProfileState = defaultState,
  action: ProfileActionTypes
): IVenueAdminProfileState => {
  switch (action.type) {
    case GET_PROFILE_PENDING:
      return {
        ...state,
        loading: true,
        saved: false,
      };
    case GET_PROFILE_FULFILLED:
      return {
        ...state,
        profile: action.profile,
        loading: false,
        saved: false,
      };
    case GET_PROFILE_REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
        saved: false,
      };
    case SAVE_PROFILE_PENDING:
      return {
        ...state,
        loading: true,
        saved: false,
      };
    case SAVE_PROFILE_FULFILLED:
      return {
        ...state,
        loading: false,
        saved: true,
      };
    case SAVE_PROFILE_REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
        saved: false,
      };
    default:
      return state;
  }
};
