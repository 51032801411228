import { Dispatch } from "redux";

import { forgotPasswordRequest } from "../../common/api";
import HttpStatusCodes from "../../common/httpStatusCodes";
import { ForgotPassword } from "../../common/models";
import dictionary from "../../i18n/en_US/dictionary";
import {
  FORGOT_PASSWORD_CLEAR_ERROR,
  FORGOT_PASSWORD_FULFILLED,
  FORGOT_PASSWORD_PENDING,
  FORGOT_PASSWORD_REJECTED,
  FORGOT_PASSWORD_RESET,
  ForgotPasswordActionTypes,
  ForgotPasswordClearErrorAction,
  ForgotPasswordFailAction,
  ForgotPasswordPendingAction,
  ForgotPasswordResetAction,
  ForgotPasswordResponse,
  ForgotPasswordSuccessAction,
} from "./types";

export const forgotPasswordClearError = (): ForgotPasswordClearErrorAction => ({
  type: FORGOT_PASSWORD_CLEAR_ERROR,
});

export const forgotPasswordPendingAction = (): ForgotPasswordPendingAction => ({
  type: FORGOT_PASSWORD_PENDING,
});

export const forgotPasswordReset = (): ForgotPasswordResetAction => ({
  type: FORGOT_PASSWORD_RESET,
});

const forgotPasswordFailAction = (error: string): ForgotPasswordFailAction => ({
  type: FORGOT_PASSWORD_REJECTED,
  error,
});

const forgotPasswordSuccessAction = (
  { success, method, methods, token }: ForgotPasswordResponse,
  email: string
): ForgotPasswordSuccessAction => ({
  type: FORGOT_PASSWORD_FULFILLED,
  success,
  method,
  methods,
  email,
  token,
});

export const forgotPasswordRequst =
  (forgotPassword: ForgotPassword) =>
  async (dispatch: Dispatch<ForgotPasswordActionTypes>) => {
    dispatch(forgotPasswordPendingAction());

    try {
      const response: ForgotPasswordResponse = await forgotPasswordRequest(
        forgotPassword
      );

      dispatch(forgotPasswordSuccessAction(response, forgotPassword.username));
    } catch (e) {
      let error: string = dictionary.forgotPassword.forgotPasswordError;

      if (e && "status" in e) {
        if (
          e.status === HttpStatusCodes.SERVER_ERROR ||
          e.status === HttpStatusCodes.NOT_FOUND
        ) {
          error = e.data;
        }
      }

      dispatch(forgotPasswordFailAction(error));
    }
  };
