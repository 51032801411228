export const GET_MANAGERS = (venueId: number): string =>
  `venue-staff/${venueId}/managers`;

export const UPDATE_MANAGER = (managerId: number): string =>
  `venue-staff/${managerId}/update`;

export const CREATE_MANAGER = (venueId: number): string =>
  `venue-staff/${venueId}/add`;

export const DELETE_MANAGER = (venueId: number): string =>
  `venue-staff/${venueId}/delete`;
