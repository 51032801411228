import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../common/models";

export type ClassNames =
  | "root"
  | "dialogContent"
  | "radioWrapper"
  | "textAlignLeft"
  | "nextButtonRightAlignWrapper"
  | "marginRight16"
  | "disabledRadioText"
  | "disabledRadioHintWrap"
  | "disabledRadioInfoIcon"
  | "disabledRadioInfoMainMenuLink"
  | "disabledRadioInfoMainMenuText"
  | "inlineBlock"
  | "padding16"
  | "radioRoot";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      dialogContent: {
        padding: theme.spacing(2),
        minHeight: 0,
        [theme.breakpoints.up("lg")]: {
          minWidth: 900,
        },
        [theme.breakpoints.up("md")]: {
          minWidth: 648,
        },
        [theme.breakpoints.down("sm")]: {
          minWidth: 500,
        },
        [theme.breakpoints.down("xs")]: {
          minWidth: "auto",
          width: "100%",
          height: "100%",
        },
      },
      radioWrapper: {
        textAlign: "left",
        margin: 8,
      },
      textAlignLeft: {
        textAlign: "left",
      },
      nextButtonRightAlignWrapper: {
        display: "flex",
        flexDirection: "row-reverse",
        width: "100%",
      },
      marginRight16: {
        marginRight: 16,
      },
      disabledRadioText: {
        color: "#9BA2AE",
      },
      disabledRadioHintWrap: {
        display: "flex",
        alignItems: "center",
        marginTop: 12,
      },
      disabledRadioInfoIcon: {
        width: 16,
        height: 16,
        color: "#000",
        marginRight: 8,
      },
      disabledRadioInfoMainMenuLink: {
        marginRight: 4,
      },
      disabledRadioInfoMainMenuText: {
        display: "inline-block",
        color: "#000",
        textDecoration: "underline",
      },
      inlineBlock: {
        display: "inline-block",
      },
      padding16: {
        padding: 16,
      },
      radioRoot: {
        alignItems: "flex-start",
        "& > span.MuiRadio-root": {
          transform: "translateY(-5px)",
        },
      },
    })
);
