import axios from "axios";

import { REFRESH_TOKENS } from "../common/api";
import HttpStatusCodes from "../common/httpStatusCodes";
import dictionary from "../i18n/en_US/dictionary";
import { store } from "../store";
import { logout, refreshTokenAction } from "../store/auth/actions";
import { isUnauthorized } from "../utils/userUtils";

export const http = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/admin/`,
  timeout: 1000 * 60, // TODO change to lower value in the future
  withCredentials: true,
});

http.interceptors.request.use((config) => {
  return { ...config };
});

export const subscribeResponse = (
  showErrorAlert: (message: string) => void
) => {
  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { status, config } = error.response;

        if (config.url === REFRESH_TOKENS) {
          store.dispatch(logout());
          showErrorAlert(dictionary.app.unauthorizedError);
        }

        if (isUnauthorized(status) && store.getState().auth.hasToken) {
          return http
            .post(REFRESH_TOKENS)
            .then((response) => {
              const { data, status } = response;
              if (status === HttpStatusCodes.OK) {
                store.dispatch(refreshTokenAction(data));

                return axios(error.config);
              }

              return Promise.reject();
            })
            .catch(() => {
              store.dispatch(logout());
              showErrorAlert(dictionary.app.unauthorizedError);
            });
        } else if (status === HttpStatusCodes.FORBIDDEN) {
          showErrorAlert(dictionary.app.permissionsError);
        }
      } else {
        // showErrorAlert(dictionary.app.networkError);
      }

      return Promise.reject(error);
    }
  );
};
