import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "inputMessage"
  | "nameTitle"
  | "messageFormWrapper"
  | "chatWindowWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        flex: 2,
        position: "relative",
      },
      inputMessage: {
        width: "100%",
      },
      nameTitle: {
        padding: theme.spacing(3),
        width: "100%",
        borderBottom: `2px solid ${colors.grey.divider}`,
        borderColor: colors.grey.divider,
      },
      messageFormWrapper: {
        display: "flex",
        width: "100%",
      },
      chatWindowWrapper: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      },
    })
);
