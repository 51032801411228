import { MFAFormSchema } from "../../../../store/forms/types";

export const schema: MFAFormSchema = {
  code: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 6,
    },
  },
};
