import { FormControl } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";

import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import ButtonSecondary from "../../../../common/components/ButtonSecondary";
import OcietyRadioGroup from "../../../../common/components/OcietyRadioGroup";
import TextMessage from "../../../../common/components/TextMessage";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  forgotPasswordRequst,
  forgotPasswordReset,
} from "../../../../store/forgotPassword/actions";
import Props from "./Props";

const ForgotMethodForm: FC<Props> = (props: Props) => {
  const { classes, methods, email, error } = props;
  const dispatch = useDispatch();
  const [value, setValue] = useState(methods[0].method);
  const handleRequest = async () => {
    dispatch(forgotPasswordRequst({ username: email, method: value }));
  };

  const handleCancel = () => {
    dispatch(forgotPasswordReset());
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    // tslint:disable-next-line:no-shadowed-variable
    value: string
  ) => {
    setValue(value);
  };

  return (
    <>
      {error && (
        <TextMessage className={classes.errorMessage} type="error">
          {error}
        </TextMessage>
      )}
      <FormControl component="fieldset">
        <FormLabel component="legend" focused className={classes.formLabel}>
          {dictionary.forgotPassword.useMethod}
        </FormLabel>
        <OcietyRadioGroup
          value={value}
          onChange={handleChange}
          options={methods.map(({ method, target }) => ({
            value: method,
            label: target,
          }))}
        />
      </FormControl>
      <div className={classes.actionsWrapper}>
        <ButtonPrimary
          className={classes.primaryBtn}
          fullWidth
          onClick={handleRequest}>
          {dictionary.forgotPassword.continue}
        </ButtonPrimary>
        <ButtonSecondary
          className={classes.btn}
          fullWidth
          onClick={handleCancel}>
          {dictionary.forgotPassword.cancel}
        </ButtonSecondary>
      </div>
    </>
  );
};

export default ForgotMethodForm;
