import { ChangeEvent, FocusEvent, useCallback, useContext } from "react";

import { PromotionMainFormField } from "../../../../../forms/main";
import { PromotionFormDialogContext } from "../../../../../PromotionFormDialogContextProvider";

export const useFormHandlers = () => {
  const { mainForm } = useContext(PromotionFormDialogContext);

  const handleChangeFormField = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const pattern = new RegExp(/^[0-9]*\.?(\d{1,2})?$/i);
      if (pattern.test(event.target.value.toString())) {
        mainForm.setAndValidate(
          event.target.name as PromotionMainFormField,
          event.target.value
        );
      }
    },
    [mainForm]
  );

  const clearValueFieldOnClickIfZero = useCallback((e: any) => {
    if (e.target.value === "0") {
      e.target.value = "";
    }
  }, []);

  const handleChangeFormNameField = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      mainForm.setAndValidate(
        event.target.name as PromotionMainFormField,
        event.target.value
      );
    },
    [mainForm]
  );

  const onBlurPriceField = useCallback(
    (priceFieldName: "limitPrice" | "rebatedPrice") =>
      (e: FocusEvent<HTMLInputElement>) => {
        if (e.target.value === "0" || e.target.value === "") {
          mainForm.setAndValidate(priceFieldName, "0");
          return;
        }
        mainForm.setFormValue(
          priceFieldName,
          String(parseFloat(e.target.value))
        );
      },
    [mainForm]
  );

  return {
    handleChangeFormField,
    clearValueFieldOnClickIfZero,
    handleChangeFormNameField,
    onBlurPriceField,
  };
};
