import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "title"
  | "notion"
  | "buttonCreate"
  | "buttonCreateIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        background:
          "url('/images/backgrounds/venues-list-empty-search-bg.png') no-repeat center",
        backgroundSize: "contain",
        minHeight: 500,
      },
      title: {
        marginBottom: theme.spacing(1),
      },
      notion: {
        maxWidth: 815,
        color: colors.grey.additional,
        marginBottom: theme.spacing(6),
      },
      buttonCreate: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(5),
      },
      buttonCreateIcon: {
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(1),
      },
    })
);
