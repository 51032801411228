import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../../../../../common/models";

export type ClassNames =
  | "root"
  | "text1"
  | "colorRed"
  | "text2"
  | "textMenuUrl"
  | "inputMenuUrl"
  | "addNewItemBlockContainer"
  | "addNewItemBlock"
  | "addNewItemBlockImage";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        width: "auto",
        margin: theme.spacing(1, 0),
      },
      text1: { fontWeight: 700, fontSize: 16, color: "#37393F" },
      colorRed: { color: "red" },
      text2: { fontWeight: 400, fontSize: 12, color: "#747982" },
      textMenuUrl: { fontWeight: 600, fontSize: 12, color: "#747982" },
      inputMenuUrl: { marginTop: 16 },
      addNewItemBlockContainer: {
        marginTop: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "34px 32px",
        background: "#F6F7F8",
        borderRadius: 7,
      },
      addNewItemBlock: { display: "flex", alignItems: "center" },
      addNewItemBlockImage: { marginRight: 25, width: 82, height: 57 },
    })
);
