import { Button, DialogContent, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import React, { FC, useContext, useState } from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import ButtonSuccess from "../../../../../../common/components/ButtonSuccess";
import OcietyDialogTitle from "../../../../../../common/components/dialogs/OcietyDIalogTitle";
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import StepsIndicator from "../../../../../../common/components/StepsIndicator";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { checkCodeIsValid } from "../../../../../../utils/phoneNumberUtils";
import Props from "../SMSMethodDialog/Props";
import { requestTotp, saveTotp } from "./api";
import PasswordStep from "./components/PasswordStep";
import QRStep from "./components/QRStep";
import ITotpData from "./models/ITotpData";
import { Steps } from "./Steps";
import { useStyles } from "./styles";

const OTPMethodDialog: FC<Props> = (props: Props) => {
  const { isOpen, setOpen, onSuccessEnabled } = props;
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const [step, setStep] = useState(Steps.PASSWORD_STEP);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [descriptionOtp, setDescriptionOtp] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [totp, setTotp] = useState<ITotpData | null>(null);
  const [descriptionError, setDescriptionError] = useState("");
  const [codeError, setCodeError] = useState("");

  const handleClose = () => {
    setOpen(false);
    setStep(Steps.PASSWORD_STEP);
  };

  const handleBack = () => {
    setStep(Steps.PASSWORD_STEP);
  };

  const handleRequestTotp = async () => {
    if (!password) {
      return;
    }

    try {
      setLoading(true);

      const totpdata = await requestTotp(password);

      if (!totpdata) {
        showAlert(dictionary.profile.mfa.errorResponseMsg, "error");
      }
      setTotp(totpdata);
      setStep(Steps.QR_STEP);
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(e.data, "error");
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTotp = async () => {
    if (!descriptionOtp || !otpCode || !totp) {
      return;
    }

    try {
      setLoading(true);

      const result = await saveTotp(totp.secret, descriptionOtp, otpCode);

      if (!result) {
        showAlert(
          dictionary.venueAdmin.profile.mfa.dialogs.totp.errorResponseMsg,
          "error"
        );
      }

      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.totp.mfaSuccessEnable,
        "success"
      );
      if (onSuccessEnabled) {
        onSuccessEnabled();
        setStep(Steps.PASSWORD_STEP);
      }
    } catch (e) {
      if (!e) {
        return;
      }

      if (e.data) {
        showAlert(e.data, "error");
      }
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const validate = () => {
    setDescriptionError("");
    setCodeError("");

    if (!descriptionOtp) {
      setDescriptionError(dictionary.profile.mfa.descripionEmptyError);
    }
    if (!checkCodeIsValid(otpCode)) {
      setCodeError(dictionary.profile.mfa.codeError);
    }
  };

  const handleBtnClick = async () => {
    if (step === Steps.PASSWORD_STEP) {
      await handleRequestTotp();
    }
    if (step === Steps.QR_STEP) {
      validate();
      await handleSaveTotp();
    }
  };

  const dialogTitle =
    step === Steps.PASSWORD_STEP
      ? dictionary.profile.mfa.enterPassowrd
      : dictionary.profile.mfa.scanQRCode;

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title">
      <OcietyDialogTitle id="mfa-otp-dialog-title" onClose={handleClose}>
        {dialogTitle}
      </OcietyDialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center">
          <Grid item xs={12}>
            {loading && <LoadingIndicator withMask />}
            <StepsIndicator
              stepsNames={[
                dictionary.profile.mfa.otpYourPassword,
                dictionary.profile.mfa.otpQRCode,
              ]}
              currentStep={step === Steps.QR_STEP ? 2 : 1}
            />
            <div className={classes.stepsWrapper}>
              {step === Steps.PASSWORD_STEP && (
                <PasswordStep
                  onSetPassword={setPassword}
                  setTotpData={setTotp}
                />
              )}
              {step === Steps.QR_STEP && (
                <QRStep
                  onSetDescription={setDescriptionOtp}
                  onSetOPTCode={setOtpCode}
                  secretKey={totp?.secret || ""}
                  imgSrc={totp?.imageUrl || ""}
                  errorDescription={descriptionError}
                  errorCode={codeError}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {step === Steps.PASSWORD_STEP && (
          <>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{ textTransform: "none" }}>
              {dictionary.profile.mfa.cancelBtn}
            </Button>
            <ButtonSuccess onClick={handleBtnClick}>
              {dictionary.profile.mfa.enableMFABtn}
            </ButtonSuccess>
          </>
        )}
        {step === Steps.QR_STEP && (
          <>
            <Button variant="outlined" onClick={handleBack}>
              {dictionary.profile.mfa.backBtn}
            </Button>
            <ButtonSuccess onClick={handleBtnClick}>
              {dictionary.profile.mfa.addBtn}
            </ButtonSuccess>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default OTPMethodDialog;
