import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type ClassNames = "root";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {
      boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)",
    },
  })
);
