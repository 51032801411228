import {
  Avatar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import clsx from "clsx";
import React, { FC, useCallback, useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import OcietyIcon from "../../../../common/components/OcietyIcon";
import { useIsManager } from "../../../../common/hooks/useIsManager";
import { useIsSuperAdmin } from "../../../../common/hooks/useIsSuperAdmin";
import { Venue, VenueAdmin, VenuePhoto } from "../../../../common/models";
import { ReactComponent as AssignToUserIcon } from "../../../../common/svg/icons/assign-venue-to-user.svg";
import { ReactComponent as AssignIcon } from "../../../../common/svg/icons/assign-venue.svg";
import { ReactComponent as VenuePhotoPlaceholder } from "../../../../common/svg/icons/venue-photo-placeholder.svg";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { getPrefilledVenueData, getVenueAdmins } from "../../api";
import AssignVenueDialog from "../AssignVenueDialog";
import AssignVenueToUserDialog from "../AssignVenueToUserDialog";
import ClaimVenueDialog from "../ClaimVenueDialog";
import { Props } from "./Props";
import { useStyles } from "./styles";

const VenuesTable: FC<Props> = (props: Props) => {
  const { className, venues } = props;
  const classes = useStyles();
  const history = useHistory();
  const [selectedVenue, setSelectedVenue] = useState<Venue | undefined>();
  const [open, setOpen] = React.useState(false);
  const [openAssign, setOpenAssign] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [userOptions, setUserOptions] = useState<VenueAdmin[]>([]);
  const [prefilledData, setPrefilledData] = useState(undefined);
  const location = useLocation();

  const superAdmin = useIsSuperAdmin();
  const manager = useIsManager();

  const getPrefilledData = useCallback(() => {
    const pathComponents = location.pathname.split("/");
    if (pathComponents.length > 2) {
      getPrefilledVenueData(pathComponents[2]).then((result) => {
        if (Object.keys(result).length === 0) {
          history.push({
            pathname: "/not-found",
          });
        } else {
          setPrefilledData(result);
          setSelectedVenue(result);
          setOpen(true);
        }
      });
    } else {
      toggleForm(false);
    }
  }, [history, location.pathname]);

  useEffect(() => {
    getPrefilledData();
  }, [getPrefilledData]);

  const claimVenue = (venue: Venue) => {
    setSelectedVenue(venue);
    setOpen(true);
  };

  const assignVenue = (venue: Venue) => {
    setSelectedVenue(venue);
    setOpenAssign(true);
  };

  const toggleForm = (value: boolean) => {
    if (value) {
      setOpen(value);
    } else {
      setPrefilledData(undefined);
      setSelectedVenue(undefined);
      setOpen(false);
    }
  };

  const ExteriorPhoto = ({
    photos,
    name,
  }: {
    photos: VenuePhoto[];
    name: string;
  }) => {
    const photo = photos.find(({ tag }) => tag === "exterior");
    if (photo === undefined) {
      return (
        <Avatar className={classes.avatar}>
          <VenuePhotoPlaceholder />
        </Avatar>
      );
    }
    return (
      <Avatar className={classes.avatar} src={photo.url}>
        {name}
      </Avatar>
    );
  };

  const assignVenueToUser = (venue: Venue) => {
    getVenueAdmins().then((resp) => {
      setSelectedVenue(venue);
      setUserOptions(resp.venueAdmins || []);
      setLoading(false);
      setOpenUser(true);
    });
  };

  return (
    <>
      {(superAdmin || manager) && selectedVenue && (
        <AssignVenueDialog
          setOpen={setOpenAssign}
          venueId={selectedVenue && selectedVenue.id}
          open={openAssign}
        />
      )}
      {(superAdmin || manager) && selectedVenue && !loading && (
        <AssignVenueToUserDialog
          userOptions={userOptions}
          setOpen={setOpenUser}
          venueId={selectedVenue && selectedVenue.id}
          open={openUser}
        />
      )}
      {selectedVenue && open && (
        <ClaimVenueDialog
          setOpen={toggleForm}
          venueId={selectedVenue.id}
          open={!!prefilledData || !!selectedVenue}
          prefilledData={prefilledData}
        />
      )}
      <div className={clsx(classes.root, className)}>
        <div className={classes.content}>
          <Table>
            <TableHead className={classes.thead}>
              <TableRow>
                <TableCell className={classes.th} align={"center"}>
                  <Typography variant={"body2"} className={classes.heading}>
                    {dictionary.venues.list.headingPhoto}
                  </Typography>
                </TableCell>
                <TableCell className={classes.th}>
                  <Typography variant={"body2"} className={classes.heading}>
                    {dictionary.venues.list.headingName}
                  </Typography>
                </TableCell>
                <TableCell className={classes.th}>
                  <Typography variant={"body2"} className={classes.heading}>
                    {dictionary.venues.list.headingAddress}
                  </Typography>
                </TableCell>
                <TableCell className={classes.th}>
                  <Typography variant={"body2"} className={classes.heading}>
                    {dictionary.venues.list.headingZipCode}
                  </Typography>
                </TableCell>
                {!superAdmin && !manager && (
                  <TableCell className={classes.th} align={"center"}>
                    <Typography variant={"body2"} className={classes.heading}>
                      {dictionary.venues.list.headingClaim}
                    </Typography>
                  </TableCell>
                )}
                {(superAdmin || manager) && (
                  <TableCell className={classes.th} align={"center"}>
                    <Typography variant={"body2"} className={classes.heading}>
                      {dictionary.venues.list.headingAssign}
                    </Typography>
                  </TableCell>
                )}
                {(superAdmin || manager) && (
                  <TableCell className={classes.th} align={"center"}>
                    <Typography variant={"body2"} className={classes.heading}>
                      {dictionary.venues.list.headingAssignToUser}
                    </Typography>
                  </TableCell>
                )}
                {(superAdmin || manager) && (
                  <TableCell className={classes.th} align={"center"}>
                    <Typography variant={"body2"} className={classes.heading}>
                      {dictionary.venues.list.headingActions}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tbody}>
              {venues.map((venue) => (
                <TableRow className={classes.tableRow} hover key={venue.id}>
                  <TableCell className={classes.td} style={{ maxWidth: 60 }}>
                    <div className={classes.photoContainer}>
                      <ExteriorPhoto photos={venue.photos} name={venue.name} />
                    </div>
                  </TableCell>
                  <TableCell className={clsx(classes.td, classes.td33)}>
                    <div className={classes.nameContainer}>
                      <Typography className={classes.venueName} variant="body1">
                        {venue.name}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell className={clsx(classes.td, classes.td33)}>
                    <Typography variant={"body2"} className={classes.address}>
                      {venue.address}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.td}>
                    <Typography variant={"body2"} className={classes.address}>
                      {venue.postcode}
                    </Typography>
                  </TableCell>
                  {!superAdmin && !manager && (
                    <TableCell className={classes.td} align={"center"}>
                      <IconButton
                        aria-label="claim"
                        className={classes.icon}
                        onClick={() => claimVenue(venue)}>
                        <OcietyIcon name={"claim"} viewBox={"0 0 32 32"} />
                      </IconButton>
                    </TableCell>
                  )}
                  {(superAdmin || manager) && (
                    <TableCell className={classes.td} align={"center"}>
                      <IconButton
                        aria-label="add"
                        className={classes.icon}
                        onClick={() => assignVenue(venue)}>
                        <AssignIcon />
                      </IconButton>
                    </TableCell>
                  )}
                  {(superAdmin || manager) && (
                    <TableCell className={classes.td} align={"center"}>
                      <IconButton
                        aria-label="add"
                        className={classes.icon}
                        onClick={() => assignVenueToUser(venue)}>
                        <AssignToUserIcon />
                      </IconButton>
                    </TableCell>
                  )}
                  {(superAdmin || manager) && (
                    <TableCell className={classes.td} align={"center"}>
                      <NavLink to={`/${Route.VenueSettings}/${venue.id}`}>
                        <IconButton aria-label="edit" className={classes.icon}>
                          <Edit style={{ color: "#7C8BC2" }} />
                        </IconButton>
                      </NavLink>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default VenuesTable;
