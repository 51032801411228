import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React, { FC, useContext } from "react";

import ButtonBlack from "../../../../../../common/components/ButtonBlack";
import ButtonPrimary from "../../../../../../common/components/ButtonPrimary";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../../../utils/dateTimeUtils";
import { transformDineroAmountToDecimalString } from "../../../../../Promotions/AdminVenue/dialogs/PromotionFormDialog/components/PromotionFormDialogContent/utils";
import { PromoCodeResponseItem } from "../../../../models";
import { PromoCodesContext } from "../../../../providers";
import Props from "./Props";
import { useStyles } from "./styles";

function Row({
  row,
  handleRemoveCouponCode,
  handleEditPromoCode,
}: {
  row: PromoCodeResponseItem;
  handleRemoveCouponCode: (id: number) => void;
  handleEditPromoCode: (id: number) => void;
}) {
  const classes = useStyles();
  const value = transformDineroAmountToDecimalString(row.value);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={
            <Typography variant="body1" className={classes.listItemTitle}>
              {dictionary.couponManagement.tableTitles.couponCode}
            </Typography>
          }
        />
        <div>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                className={classes.listItemDescription}>
                {row.couponCode}
              </Typography>
            }
          />
        </div>
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={
            <Typography variant="body1" className={classes.listItemTitle}>
              {dictionary.couponManagement.tableTitles.value}
            </Typography>
          }
        />
        <div>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                className={classes.listItemDescription}>
                {"$" + value}
              </Typography>
            }
          />
        </div>
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={
            <Typography variant="body1" className={classes.listItemTitle}>
              {dictionary.couponManagement.tableTitles.startDate}
            </Typography>
          }
        />
        <ListItemText primary={getFormattedDate(row.start)} />
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={
            <Typography variant="body1" className={classes.listItemTitle}>
              {dictionary.couponManagement.tableTitles.endDate}
            </Typography>
          }
        />
        <ListItemText primary={getFormattedDate(row.end)} />
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={
            <Typography variant="body1" className={classes.listItemTitle}>
              {dictionary.couponManagement.tableTitles.usages}
            </Typography>
          }
        />
        <div>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                className={classes.listItemDescription}>
                {row.usages}
              </Typography>
            }
          />
        </div>
      </ListItem>
      <ListItem className={classes.listItemActions}>
        <ButtonPrimary
          className={classes.deleteButton}
          onClick={() => handleRemoveCouponCode(row.id)}>
          {dictionary.general.delete}
        </ButtonPrimary>
        <ButtonBlack onClick={() => handleEditPromoCode(row.id)}>
          {dictionary.couponManagement.tableTitles.edit}
        </ButtonBlack>
      </ListItem>
    </List>
  );
}

const AdaptiveList: FC<Props> = (props: Props) => {
  const { removeCouponCode } = props;
  const { promoCodesList, setPromoCodeId, setFormDialogOpen } =
    useContext(PromoCodesContext);

  const handleEditPromoCode = (id: number) => {
    setPromoCodeId(id);
    setFormDialogOpen(true);
  };

  return (
    <>
      {promoCodesList?.map((item: PromoCodeResponseItem) => (
        <Row
          key={item.id}
          row={item}
          handleRemoveCouponCode={removeCouponCode}
          handleEditPromoCode={handleEditPromoCode}
        />
      ))}
    </>
  );
};

export default AdaptiveList;
