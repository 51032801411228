import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AlertContext } from "../../../../../../../common/components/Alert/AlertContextProvider";
import ButtonPrimary from "../../../../../../../common/components/ButtonPrimary";
import ButtonSecondary from "../../../../../../../common/components/ButtonSecondary";
import ConfirmDialog from "../../../../../../../common/components/dialogs/ConfirmDialog";
import { ConfirmModalType } from "../../../../../../../common/components/dialogs/ConfirmDialog/Props";
import LoadingIndicator from "../../../../../../../common/components/LoadingIndicator";
import OcietyNavLink from "../../../../../../../common/components/OcietyNavLink";
import { useRestApi } from "../../../../../../../common/hooks/useRestApi";
import HttpStatusCodes from "../../../../../../../common/httpStatusCodes";
import { Route } from "../../../../../../../config/router";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { CurrentVenueState } from "../../../../../../../store/currentVenue/reducer";
import { AppState } from "../../../../../../../store/rootReducer";
import VenuesSearchEmpty from "../../../../../../Venues/components/VenuesSearchEmpty";
import {
  changePromotionStatus,
  getPromotionsAll,
} from "../../../../../components/api";
import PromotionsGrid from "../../../../../components/PromotionsGrid";
import SearchPromotion from "../../../../../components/SearchPromotion";
import { DiscountStatus } from "../../../../../types/DiscountStatus";
import { DiscountKind } from "../../../../../types/enums/DiscountKind.enum";
import { IPromotion } from "../../../../../types/IPromotion";
import PromotionFormDialog from "../../../../dialogs/PromotionFormDialog";
import PromotionCategoriesSelect from "./components/PromotionCategoriesSelect";
import IPromotionsAllResponse from "./models/IPromotionsAllResponse";
import { useStyles } from "./styles";
import { useTabPromotionsHook } from "./useTabPromotions.hook";

const TabPromotions: FC = () => {
  const ROWS_PER_PAGE = 5;
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const [promotions, setPromotions] = useState<IPromotion[]>([]);
  const [promotionsCount, setPromotionsCount] = useState<number>(0);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
  const [selectedDealKind, setSelectedDealKind] = useState<DiscountKind>();
  const [page, setPage] = useState(1);
  const [isAllActivePromotionChecked, setIsAllActivePromotionChecked] =
    useState(false);
  const { currentVenue, ownVenues } = useSelector<AppState, CurrentVenueState>(
    (state) => state.currentVenue
  );
  const [
    { loading: paymentStatusLoading, data: status },
    getPaymentAccountStatus,
  ] = useRestApi("/venue/paymentstatus/" + currentVenue?.id, "GET", {}, true);

  const activeStatus = DiscountStatus.ENABLED;
  const skip = (page - 1) * 5;

  const {
    refreshToken: refreshToken,
    openEditFormDialog: openEditDialog,
    openDeleteDialog: openDeleteDialog,
    openFormDialog: openCreateDialog,
    openDuplicateDialog: openDuplicateDialog,
    refreshGrid: refresh,
    venueId: venueId,
    confirmDuplicatePromotion,
    closeDuplicateDialog,
    duplicatePromotionId,
    confirmDeletePromotion,
    closeDeleteDialog,
    deletePromotionId,
    formDialogOpen,
    closeFormDialog,
    editPromotionId,
  } = useTabPromotionsHook();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      await getPaymentAccountStatus();
      getPromotionsAll(
        venueId,
        ROWS_PER_PAGE,
        skip,
        searchValue,
        selectedCategoryId,
        isAllActivePromotionChecked ? activeStatus : undefined,
        selectedDealKind
      )
        .then((data: IPromotionsAllResponse) => {
          if (data) {
            setPromotions(data.promotions);
            setPromotionsCount(data.count);
          }
        })
        .catch((e) => {
          if (e.status !== HttpStatusCodes.SERVER_ERROR) {
            showAlert(dictionary.deals.createDealForm.errorFetchData, "error");
          }
        });
    })();
  }, [
    showAlert,
    venueId,
    searchValue,
    skip,
    activeStatus,
    selectedCategoryId,
    refreshToken,
    isAllActivePromotionChecked,
    getPaymentAccountStatus,
    selectedDealKind,
  ]);

  const onChangeSearchValue = (search: string) => {
    setPage(1);
    setSearchValue(search);
  };

  const onlyActivePromotions = () => {
    setIsAllActivePromotionChecked((prevState) => !prevState);
    setPage(1);
  };

  const onChangePromotionStatus = async (
    promotionId: number,
    status: DiscountStatus
  ) => {
    try {
      setLoading(true);
      const result = await changePromotionStatus(venueId, promotionId, status);

      if (!result) {
        showAlert(dictionary.deals.createDealForm.incorrectResponse, "error");
      }
      showAlert(dictionary.deals.successfullySaved, "success");
    } catch (e) {
      if (e?.data === "purchasedPromotion") {
        showAlert(
          dictionary.deals.createDealForm.msgErrorPurchasedDeal,
          "error"
        );
      } else showAlert(dictionary.deals.createDealForm.errorStatus, "error");
    } finally {
      refresh();
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.wrapper}>
        <Grid container spacing={1}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Typography variant="h3" component="h3" className={classes.title}>
              {dictionary.deals.titlePage}
            </Typography>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={6}
            className={classes.checkboxWrapper}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isAllActivePromotionChecked}
                  onChange={onlyActivePromotions}
                  name="onlyActivePromotions"
                />
              }
              className={classes.checkbox}
              label={
                <Typography variant="body2">
                  {dictionary.deals.activeDeal}
                </Typography>
              }
            />
          </Grid>
          <Grid item lg={3} xl={2} xs={12} md={6} className={classes.search}>
            <SearchPromotion onChange={onChangeSearchValue} />
          </Grid>
          <Grid item lg={2} xl={4} xs={12} md={6} />
          <Grid item lg={1} xl={2} xs={12} md={6} justify="space-between" />
          <Grid
            item
            xl={4}
            lg={6}
            xs={12}
            md={6}
            direction="row"
            justify="space-between"
            style={{ textAlign: "right" }}>
            <PromotionCategoriesSelect
              selectedCategoryId={selectedCategoryId}
              setSelectedCategoryId={setSelectedCategoryId}
              selectedDealKind={selectedDealKind}
              setSelectedDealKind={setSelectedDealKind}
              setPage={setPage}
            />
            <ButtonPrimary
              disabled={!currentVenue?.hasStripeAccount || !status}
              style={{ padding: 17, marginTop: -7 }}
              onClick={openCreateDialog}>
              {dictionary.deals.addBtnTxt}
            </ButtonPrimary>
            {!status && (
              <OcietyNavLink to={Route.VenueSettings}>
                <ButtonSecondary style={{ padding: 15, marginTop: 10 }}>
                  {dictionary.deals.addPaymentMethod}
                </ButtonSecondary>
              </OcietyNavLink>
            )}
          </Grid>
          {searchValue && !promotions.length ? (
            <VenuesSearchEmpty />
          ) : (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {loading && <LoadingIndicator withMask />}
              <PromotionsGrid
                rowsPerPage={ROWS_PER_PAGE}
                page={page}
                setPage={setPage}
                promotions={promotions}
                count={promotionsCount}
                onEditPromotion={openEditDialog}
                onChangePromotionStatus={onChangePromotionStatus}
                onDeletePromotion={openDeleteDialog}
                onDuplicatePromotion={openDuplicateDialog}
                refresh={refresh}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      <PromotionFormDialog
        refresh={refresh}
        promotionId={editPromotionId}
        closeDialog={closeFormDialog}
        open={formDialogOpen}
      />
      <ConfirmDialog
        open={!!deletePromotionId}
        onClose={closeDeleteDialog}
        onConfirm={confirmDeletePromotion}
        subtitle={"Are you sure you would like to delete deal?"}
        yesTypeAdditionalText={"delete"}
        type={ConfirmModalType.YES_DELETE}
        message={" \n This cannot be undone."}
      />

      <ConfirmDialog
        open={!!duplicatePromotionId}
        onClose={closeDuplicateDialog}
        onConfirm={confirmDuplicatePromotion}
        subtitle={"Are you sure you would like to duplicate this deal? "}
        type={ConfirmModalType.CONFIRM}
        message={" \n  "}
      />
    </div>
  );
};

export default TabPromotions;
