import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "formControl"
  | "formControlWithTooltip"
  | "title"
  | "notion"
  | "actionsWrapper"
  | "buttonBlackIcon"
  | "smallArrowIcon"
  | "buttonBlack"
  | "warningIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        position: "relative",
      },
      formControl: {
        width: "100%",
      },
      formControlWithTooltip: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      title: {
        marginBottom: theme.spacing(4),
      },
      actionsWrapper: {
        marginTop: theme.spacing(6),
      },
      smallArrowIcon: {
        marginLeft: "auto",
        marginRight: 0,
        transition: theme.transitions.create("margin-right", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      notion: {
        marginTop: theme.spacing(2),
        fontSize: theme.typography.pxToRem(12),
        fontWeight: "bold",
      },
      buttonBlack: {
        textAlign: "left",
        borderRadius: 2,
        padding: theme.spacing(1.75),
        backgroundColor: colors.grey.primary,
        color: colors.white.primary,
        textTransform: "none",
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
        "&:hover, &:active, &:focus": {
          backgroundColor: colors.grey.primary,
          boxShadow: "0 0 25px 0 rgba(0,0,0,0.25)",
        },

        "&:hover $smallArrowIcon": {
          [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(1),
          },
        },
      },
      buttonBlackIcon: {
        marginRight: 10,
      },
      warningIcon: { width: 24, height: 24, marginLeft: 16, minWidth: 24 },
    })
);
