import React, { FC, useEffect } from "react";

import { useAsyncState } from "../../../../common/hooks/useAsyncState";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { GET_VENUE_REVIEWS_TOTAL, ScoreTotal } from "../../models";
import DashboardWidgetSmall from "../DashboardWidgetSmall";
import Props from "./Props";

const TotalReviews: FC<Props> = (props) => {
  const { venueId, period } = props;
  const [state, setAsyncState] = useAsyncState<ScoreTotal>(
    GET_VENUE_REVIEWS_TOTAL(venueId, period)
  );

  useEffect(() => {
    if (!!venueId) {
      setAsyncState().then();
    }
  }, [setAsyncState, venueId]);

  return (
    <DashboardWidgetSmall
      loading={state.loading}
      icon={"widgetReviews"}
      description={dictionary.dashboard.widgetReviewsDescription}
      link={Route.Reviews}
      score={state.response ? state.response.score : 0}
    />
  );
};

export default TotalReviews;
