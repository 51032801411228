import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../../../theme/colors";
import { OcietyTheme } from "../../../../models";

export type ClassNames =
  | "root"
  | "rootNew"
  | "notificationIconContainer"
  | "PROMOTION_ICON"
  | "RECOMMENDATION_ICON"
  | "imageContainer"
  | "leftContainer"
  | "rightContainer"
  | "image"
  | "title"
  | "message"
  | "dateTime";

const NOTIFICATION_ICON_SIZE = 23;
const NOTIFICATION_IMAGE_SIZE = 42;

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        width: "100%",
        borderRadius: 2,
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1.5),
        marginTop: theme.spacing(1) / 2,
        marginBottom: theme.spacing(1) / 2,
        cursor: "pointer",

        "&:hover": {
          backgroundColor: colors.grey.medium,
        },
      },
      rootNew: {
        backgroundColor: "#F7F8FC",
      },
      notificationIconContainer: {
        height: NOTIFICATION_ICON_SIZE,
        position: "absolute",
        bottom: -NOTIFICATION_ICON_SIZE / 5,
        right: -NOTIFICATION_ICON_SIZE / 5,
        width: NOTIFICATION_ICON_SIZE,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        border: "2px solid #fff",
      },
      imageContainer: {
        height: NOTIFICATION_IMAGE_SIZE,
        width: NOTIFICATION_IMAGE_SIZE,
        borderRadius: "50%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      image: {
        height: "auto",
        minWidth: "100%",
        minHeight: "100%",
      },
      PROMOTION_ICON: {
        backgroundColor: "#B7C0D6",
      },
      VENUE_POS_CONNECT_ICON: {
        backgroundColor: "#B7C0D6",
      },
      VENUE_POS_DISCONNECT_ICON: {
        backgroundColor: "#B7C0D6",
      },
      VENUE_MENU_DELETE_ICON: {
        backgroundColor: "#B7C0D6",
      },
      RECOMMENDATION_ICON: {
        backgroundColor: "#6979F8",
      },
      VENUE_REVIEW_ICON: {
        backgroundColor: "#FFCF5C",
      },
      leftContainer: {
        position: "relative",
        height: NOTIFICATION_IMAGE_SIZE,
        width: NOTIFICATION_IMAGE_SIZE,
      },
      rightContainer: {
        overflow: "hidden",
        marginLeft: theme.spacing(2),
        flex: 1,
      },
      title: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontWeight: 700,
        maxWidth: "auto",
        fontSize: theme.typography.pxToRem(16),
      },
      message: {
        color: "rgba(107, 112, 133, 0.65)",
        fontSize: theme.typography.pxToRem(13),
        fontWeight: 600,
      },
      dateTime: {
        color: "#B7BFD5",
        fontSize: theme.typography.pxToRem(12),
        fontWeight: "bold",
      },
    })
);
