import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { FC, useContext } from "react";

import { DiscountKind } from "../../../../../Promotions/types/enums/DiscountKind.enum";
import {
  AutocompleteSearchValues,
  FiltersWithoutDateRange,
  TransactionHistoryType,
} from "../../../../models";
import { TransactionsContext } from "../../../../providers";
import Props from "./Props";

const AutoCompleteTransactionsSearch: FC<Props> = ({
  options,
  column,
  handleClose,
}: Props) => {
  const { setSearchFilters, setTableLoading } = useContext(TransactionsContext);

  const handleChange = (
    event?: TransactionHistoryType[] & string[] & DiscountKind[]
  ) => {
    setTableLoading(true);
    setSearchFilters((prevFilters) => {
      const current = { ...prevFilters };
      current[column.id as keyof FiltersWithoutDateRange] = event;

      return current;
    });
    setTableLoading(false);
  };

  return (
    <Autocomplete
      multiple
      limitTags={1}
      id="transactions-search-limit-tags"
      options={options}
      getOptionLabel={(option) => option}
      onChange={(event, newValue, reason) => {
        handleChange(newValue as AutocompleteSearchValues);
        if (reason === "clear") {
          handleClose();
        }
      }}
      renderInput={(params) => (
        // @ts-ignore
        <TextField
          {...params}
          onReset={handleClose}
          label={`Search ${column.label}`}
          placeholder={`Search ${column.label}`}
        />
      )}
      sx={{ width: "300px" }}
    />
  );
};

export default AutoCompleteTransactionsSearch;
