import { ChartDataItem } from "./models";

export const mockVenuesNames = {
  firstVenueName: "First nearby",
  secondVenueName: "Second nearby",
  thirdVenueName: "Third nearby",
};

export const chartDataMock: ChartDataItem[] = [
  {
    month: "Jan",
    first: 340,
    second: 277,
    third: 670,
  },
  {
    month: "Feb",
    first: 440,
    second: 577,
    third: 260,
  },
  {
    month: "Mar",
    first: 540,
    second: 177,
    third: 460,
  },
  {
    month: "Apr",
    first: 404,
    second: 777,
    third: 600,
  },
  {
    month: "May",
    first: 403,
    second: 774,
    third: 609,
  },
  {
    month: "Jun",
    first: 440,
    second: 377,
    third: 460,
  },
];
