import { Role } from "../../models";
import { Permission } from "../../permissions/permission";
import {
  AuthActionTypes,
  CLEAR_MFA_TOKEN,
  LOGIN_CLEAR_ERROR,
  LOGIN_FULFILLED,
  LOGIN_PENDING,
  LOGIN_REJECTED,
  LOGOUT,
  REFRESH_TOKEN,
  SET_MFA_TOKEN,
  TOGGLE_REMEMBER_ME,
} from "./types";

export interface AuthState {
  rememberMe: boolean;
  mfaToken?: string;
  mfaType?: string;
  hasToken?: boolean;
  roles: Role[];
  permissions: Permission[];
  loading: boolean;
  error?: string;
}

const defaultState: AuthState = {
  rememberMe: false,
  hasToken: false,
  roles: [],
  loading: false,
  permissions: [],
};

export default (
  state: AuthState = defaultState,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    case REFRESH_TOKEN:
      return {
        ...state,
        hasToken: action.hasToken,
      };
    case CLEAR_MFA_TOKEN:
      return {
        ...state,
        mfaToken: undefined,
        mfaType: undefined,
      };
    case LOGIN_PENDING:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SET_MFA_TOKEN:
      return {
        ...state,
        mfaToken: action.mfaToken,
        mfaType: action.mfaType,
        loading: false,
      };
    case LOGIN_FULFILLED:
      const { roles, permissions } = action;

      return {
        ...state,
        hasToken: true,
        roles,
        permissions,
        loading: false,
      };
    case LOGIN_REJECTED:
      return {
        ...state,
        error: action.error,
      };
    case LOGIN_CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case LOGOUT:
      return { ...defaultState };
    case TOGGLE_REMEMBER_ME:
      return {
        ...state,
        rememberMe: !state.rememberMe,
      };

    default:
      return state;
  }
};
