import {
  Link,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";

import OcietyIcon from "../../../../../../common/components/OcietyIcon";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import AtLocationStatusLabel from "./components/AtLocationStatusLabel";
import UserActivityTableCell from "./components/UserActivityTableCell";
import UserActivityTablePaginationActions from "./components/UserActivityTablePaginationActions";
import Props from "./Props";
import { userScreen, userType } from "./rowHelpers";
import { useStyles } from "./styles";

const ActiveUsersTable: FC<Props> = (props: Props) => {
  const { liveUsersData, rowsPerPage } = props;
  const [page, setPage] = React.useState(0);
  const classes = useStyles();

  return (
    <TableContainer style={{ overflowX: "inherit" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <UserActivityTableCell></UserActivityTableCell>
            <UserActivityTableCell align={"center"}>
              {dictionary.reportingLiveStats.headerUserType}
            </UserActivityTableCell>
            <UserActivityTableCell align={"center"}>
              {dictionary.reportingLiveStats.headerActions}
            </UserActivityTableCell>
            <UserActivityTableCell align={"center"}></UserActivityTableCell>
            <UserActivityTableCell align={"center"}>
              {dictionary.reportingLiveStats.views}
            </UserActivityTableCell>
            <UserActivityTableCell align={"center"}>
              {dictionary.reportingLiveStats.dealUsed}
            </UserActivityTableCell>
            <UserActivityTableCell>
              {dictionary.reportingLiveStats.comingSoon}
            </UserActivityTableCell>
            <UserActivityTableCell></UserActivityTableCell>
          </TableRow>
        </TableHead>

        <TableBody
          style={{
            boxShadow: "0px 0px 15px rgba(59, 72, 118, 0.08)",
          }}>
          {(rowsPerPage > 0
            ? liveUsersData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : liveUsersData
          ).map((row) => (
            <TableRow style={{}} key={row.userId} className={classes.row}>
              <UserActivityTableCell>
                <OcietyIcon
                  onClick={() => alert("Not implemented")}
                  name={"circleAvatarGray"}
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                />
              </UserActivityTableCell>
              <UserActivityTableCell align={"center"}>
                {userType(row)}
              </UserActivityTableCell>
              <UserActivityTableCell align={"center"}>
                {userScreen(row)}
              </UserActivityTableCell>
              <UserActivityTableCell align={"center"}>
                <AtLocationStatusLabel status={row.atLocation} />
              </UserActivityTableCell>
              <UserActivityTableCell align={"center"}>
                {row.views}
              </UserActivityTableCell>
              <UserActivityTableCell align={"center"}>
                {row.promotionsUsed}
              </UserActivityTableCell>
              <UserActivityTableCell>
                <Typography variant="body1">
                  <Link
                    href="#"
                    className={classes.pushPromotions}
                    onClick={() => alert("Not implemented")}>
                    {dictionary.reportingLiveStats.pushDeal}
                  </Link>
                </Typography>
              </UserActivityTableCell>
              <UserActivityTableCell>
                <OcietyIcon
                  onClick={() => alert("Not implemented")}
                  name={"mailBlue"}
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                />
              </UserActivityTableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              style={{
                borderBottom: 0,
                paddingTop: 15,
              }}
              className={classes.paginationWrapper}
              rowsPerPageOptions={[]}
              colSpan={9}
              count={liveUsersData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={(
                event: React.MouseEvent<HTMLButtonElement> | null,
                page: number
              ) => setPage(page)}
              ActionsComponent={UserActivityTablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
export default ActiveUsersTable;
