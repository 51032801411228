import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";
import { FOOTER_HEIGHT, FOOTER_HEIGHT_MOBILE } from "./index";

export type FooterClassNames = "root" | "copyright" | "links" | "linkText";

export const useStyles: () => ClassNameMap<FooterClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        [theme.breakpoints.up("sm")]: {
          height: FOOTER_HEIGHT,
        },
        [theme.breakpoints.down("sm")]: {
          height: FOOTER_HEIGHT_MOBILE,
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: colors.grey.primary,
        padding: theme.spacing(0, 3),
      },
      copyright: {
        marginRight: theme.spacing(9),
        color: "#FFF",
      },
      links: {
        [theme.breakpoints.down("sm")]: {
          flexWrap: "wrap",
        },
      },
      linkText: {
        color: colors.white.primary,
      },
    })
);
