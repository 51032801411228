import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import clsx from "clsx";
import React, { FC, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import dictionary from "../../../../../i18n/en_US/dictionary";
import { setFormValue } from "../../../../../store/forms/actions";
import { AppState } from "../../../../../store/rootReducer";
import { getMomentFromTimeString } from "../../../../../utils/dateTimeUtils";
import { WorkingDaysForm } from "../../../../../views/VenueSettings/components/VenueCommonData/form/WorkingDaysForm";
import { AlertContext } from "../../../Alert/AlertContextProvider";
import OcietyIcon from "../../../OcietyIcon";
import OcietySwitch from "../../../OcietySwitch";
import { WorkingDays } from "../../models";
import { defaultTimeProps } from "../../utils";
import Props from "./Props";
import { useStyles } from "./styles";

const WorkingDayRow: FC<Props> = (props: Props) => {
  const {
    id: itemId,
    day,
    hours,
    isOpen,
    setApplyAllDays,
    applyAllDays,
    disableAddNewHoursPair,
  } = props;
  const form = useSelector<AppState, WorkingDaysForm>(
    (state) => state.forms.workingDays
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);

  const toggleOpen = () => () => {
    const newState = form.values.workingDays?.map((workday) => {
      if (isCurrentWorkday(workday.id)) {
        if (workday.hours.length === 0 && !workday.isOpen) {
          return {
            ...workday,
            isOpen: true,
            hours: [...workday.hours, defaultTimeProps()],
          };
        }

        return { ...workday, isOpen: !workday.isOpen };
      } else if (isAllDayRow() && setApplyAllDays && applyAllDays) {
        setApplyAllDays({ ...applyAllDays, enable: !applyAllDays.enable });

        return {
          ...workday,
          hours: applyAllDays.hours,
          isOpen: !applyAllDays.enable,
        };
      }

      if (!validateDaysHoursEnable(form.values.workingDays)) {
        showAlert("Must be included for at least 1 day", "error");
      }

      return workday;
    });

    dispatchWorkingDaysState(newState);
  };

  const validateDaysHoursEnable = (daysHours?: WorkingDays[]): boolean => {
    if (daysHours) {
      for (const dh of daysHours) {
        if (dh.isOpen) {
          return dh.isOpen;
        }
      }
    }

    return false;
  };

  const editHours = (
    timeIndex: string,
    prop: string,
    newTime: MaterialUiPickersDate
  ) => {
    const newState = form.values.workingDays?.map((workday) => {
      if (isCurrentWorkday(workday.id)) {
        const newHours = workday.hours.map((time) => {
          if (isCurrentTimeRow(time.id, timeIndex)) {
            return {
              ...time,
              [prop]: newTime?.toDate().toLocaleTimeString("en-GB"),
            };
          }

          return time;
        });

        return {
          ...workday,
          hours: newHours,
        };
      } else if (isAllDayRow() && applyAllDays && setApplyAllDays) {
        const newHours = applyAllDays.hours.map((time) => {
          if (isCurrentTimeRow(time.id, timeIndex)) {
            return {
              ...time,
              [prop]: newTime?.toDate().toLocaleTimeString("en-GB"),
            };
          }

          return time;
        });

        setApplyAllDays({
          ...applyAllDays,
          enable: false,
          hours: newHours,
        });
      }

      return workday;
    });

    dispatchWorkingDaysState(newState);
  };

  const addHours = () => {
    const newState = form.values.workingDays?.map((workday) => {
      if (isCurrentWorkday(workday.id)) {
        return {
          ...workday,
          hours: [...workday.hours, defaultTimeProps()],
          isOpen: true,
        };
      } else if (isAllDayRow() && applyAllDays && setApplyAllDays) {
        setApplyAllDays({
          ...applyAllDays,
          enable: false,
          hours: [...applyAllDays.hours, defaultTimeProps()],
        });
      }

      return workday;
    });

    dispatchWorkingDaysState(newState);
  };

  const removeHours = (timeIndex: string) => () => {
    const newState = form.values.workingDays?.map((workday) => {
      if (isCurrentWorkday(workday.id)) {
        const newHours = workday.hours.filter(
          (time) => !isCurrentTimeRow(time.id, timeIndex)
        );

        if (newHours.length) {
          return {
            ...workday,
            hours: newHours,
          };
        }
      } else if (isAllDayRow() && applyAllDays && setApplyAllDays) {
        const newHours = applyAllDays.hours.filter(
          (time) => !isCurrentTimeRow(time.id, timeIndex)
        );

        if (newHours.length) {
          setApplyAllDays({
            ...applyAllDays,
            hours: newHours,
            enable: false,
          });
        }
      }

      return workday;
    });

    dispatchWorkingDaysState(newState);
  };

  const dispatchWorkingDaysState = (state: WorkingDays[] | undefined): void => {
    dispatch(setFormValue("workingDays", "workingDays", state));
  };

  const isCurrentWorkday = (id: string): boolean => {
    return itemId === id;
  };

  const isCurrentTimeRow = (currentId: string, timeIndex: string): boolean => {
    return currentId === timeIndex;
  };

  const isAllDayRow = (): boolean => {
    return itemId === "apply-all-days";
  };

  return (
    <Grid item direction={"row"} xs={12} className={classes.root}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          direction={"row"}
          className={classes.daySwitchWrapper}>
          <Typography variant={"subtitle2"} className={classes.dayName}>
            {day}
          </Typography>
          <OcietySwitch
            value={isOpen}
            checked={isOpen}
            onChange={toggleOpen()}
          />
        </Grid>
        <Grid item xs={8} direction={"row"}>
          {hours.map((pair) => (
            <Grid
              container
              direction={"row"}
              spacing={1}
              key={pair.id}
              className={classes.timePickerContainer}>
              <Grid item xs={12} sm={disableAddNewHoursPair ? 6 : 5}>
                <Paper
                  className={clsx(
                    classes.timePickerWrapper,
                    !isOpen && classes.timePickerWrapperDisabled
                  )}>
                  <OcietyIcon
                    name={isOpen ? "clockActive" : "clock"}
                    height={"26px"}
                    width={"26px"}
                    viewBox={"0 0 26 26"}
                  />
                  <TimePicker
                    className={classes.timePicker}
                    disabled={!isOpen}
                    label={dictionary.venues.venueForm.openLabel}
                    inputProps={{
                      className: classes.timePickerInput,
                      shrink: true,
                    }}
                    value={getMomentFromTimeString(pair.from)}
                    onChange={(newTime) => editHours(pair.id, "from", newTime)}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={disableAddNewHoursPair ? 6 : 5}>
                <Paper
                  className={clsx(
                    classes.timePickerWrapper,
                    !isOpen && classes.timePickerWrapperDisabled
                  )}>
                  <OcietyIcon
                    name={isOpen ? "clockActive" : "clock"}
                    height={"26px"}
                    width={"26px"}
                    viewBox={"0 0 26 26"}
                  />
                  <TimePicker
                    className={classes.timePicker}
                    disabled={!isOpen}
                    label={dictionary.venues.venueForm.closeLabel}
                    inputProps={{
                      className: classes.timePickerInput,
                      shrink: true,
                    }}
                    value={getMomentFromTimeString(pair.to)}
                    onChange={(newTime) => editHours(pair.id, "to", newTime)}
                  />
                </Paper>
              </Grid>
              {!disableAddNewHoursPair && (
                <Grid item xs={12} sm={2}>
                  {isOpen && (
                    <Paper className={classes.addMinusIcon}>
                      <OcietyIcon
                        name={
                          hours.indexOf(pair) === 0
                            ? "addTimePlus"
                            : "removeTimeMinus"
                        }
                        onClick={
                          hours.indexOf(pair) === 0
                            ? addHours
                            : removeHours(pair.id)
                        }
                      />
                    </Paper>
                  )}
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WorkingDayRow;
