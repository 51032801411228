import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type ClassNames = "root" | "gridWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 30,
      marginBottom: 50,
      width: "80%",
    },
    gridWrapper: {
      marginTop: 20,
    },
  })
);
