import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import MessageForm from "./components/MessageForm";
import MessagesList from "./components/MessagesList";
import Props from "./Props";
import { useStyles } from "./styles";

const ChatWindow: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { children, value, index, data, addNewMessage, ...other } = props;
  const { name, messages, id } = data;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className={classes.root}>
      {value === index && (
        <div className={classes.chatWindowWrapper}>
          <Typography variant={"h3"} className={classes.nameTitle}>
            {name}
          </Typography>
          <MessagesList messages={messages} />
          <MessageForm chatId={id} addNewMessage={addNewMessage} />
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
