export const GET_VENUE_PROMOTION_VIEWS = "reporting/promotion-views-by-month";

export interface PromotionViewsData {
  month: string;
  food: number;
  drinks: number;
  bill: number;
}

export interface GetVenuePromotionViewsResponse {
  stats: PromotionViewsData[];
}
