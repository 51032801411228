export const GET_PROMOTION_RECOMMENDATIONS_IMPRESSIONS =
  "reporting/promotion-recommendations-impressions-by-month";

export interface ImpressionsData {
  month: string;
  impressionsCount: number;
}

export interface GetPromotionRecommendationsImpressionsResponse {
  stats: ImpressionsData[];
}
