import { ChartDataItem } from "./models";

export const chartDataMock: ChartDataItem[] = [
  {
    hour: "12 pm",
    usages: 4,
  },
  {
    hour: "1 am",
    usages: 2,
  },
  {
    hour: "2 am",
    usages: 2,
  },
  {
    hour: "3 am",
    usages: 3,
  },
  {
    hour: "4 am",
    usages: 2,
  },
  {
    hour: "5 am",
    usages: 1,
  },
  {
    hour: "6 am",
    usages: 3,
  },
  {
    hour: "7 am",
    usages: 1,
  },
  {
    hour: "7 am",
    usages: 2,
  },
  {
    hour: "9 am",
    usages: 3,
  },
  {
    hour: "10 am",
    usages: 4,
  },
  {
    hour: "11 am",
    usages: 5,
  },
  {
    hour: "12 am",
    usages: 7,
  },
  {
    hour: "1 pm",
    usages: 10,
  },
  {
    hour: "2 pm",
    usages: 14,
  },
  {
    hour: "3 pm",
    usages: 15,
  },
  {
    hour: "4 pm",
    usages: 18,
  },
  {
    hour: "5 pm",
    usages: 20,
  },
  {
    hour: "6 pm",
    usages: 20,
  },
  {
    hour: "7 pm",
    usages: 17,
  },
  {
    hour: "7 pm",
    usages: 14,
  },
  {
    hour: "9 pm",
    usages: 8,
  },
  {
    hour: "10 pm",
    usages: 9,
  },
  {
    hour: "11 pm",
    usages: 6,
  },
];
