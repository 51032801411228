import { http } from "../../../../../config/api";
import IPromotionDietaryRestriction from "../../../types/interfaces/IPromotionDietaryRestriction.interface";
import CreatePromotionFormRequest from "./models/CreatePromotionFormRequest";
import UpdatePromotionFormRequest from "./models/UpdatePromotionFormRequest";

const PROMOTION_CREATE = (venueId: number) => `promotions/venue/${venueId}`;
const PROMOTION_UPDATE = (promotionId: number, venueId: number) =>
  `promotions/${promotionId}/${venueId}`;
const PROMOTION_VENUE_CONFIG = (venueId: number) =>
  `promotions/venue/${venueId}/validate-config`;
const PROMOTION_DELETE = (promotionId: number, venueId: number) =>
  `promotions/${venueId}/discounts/${promotionId}`;

export const getPromotionVanueConfig = async (
  venueId: number
): Promise<any> => {
  http
    .get(PROMOTION_VENUE_CONFIG(venueId))
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });
};

export const createPromotion = async (
  createPromotionFormRequest: CreatePromotionFormRequest,
  venueId: number,
  dietaryRestrictions?: IPromotionDietaryRestriction[],
  ageGroupId?: number[]
): Promise<any> =>
  http
    .post(
      PROMOTION_CREATE(venueId),
      Object.assign(createPromotionFormRequest, {
        dietaryRestrictions,
        ageGroupId,
      })
    )
    .then((response) => ({
      ...response,
    }))
    .catch((error) => {
      throw error.response;
    });

export const updatePromotion = async (
  updatePromotionFormRequest: UpdatePromotionFormRequest,
  promotionId: number,
  venueId: number,
  dietaryRestrictions?: IPromotionDietaryRestriction[],
  ageGroupId?: number[]
): Promise<any> =>
  http
    .put(
      PROMOTION_UPDATE(promotionId, venueId),
      Object.assign(updatePromotionFormRequest, {
        dietaryRestrictions,
        ageGroupId,
      })
    )
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const deletePromotion = async (
  promotionId: number,
  venueId: number
): Promise<any> =>
  http
    .delete(PROMOTION_DELETE(promotionId, venueId))
    .then((response) => ({
      ...response,
    }))
    .catch((error) => {
      throw error.response;
    });
