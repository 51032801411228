import { ChartDataItem } from "./models";

export const chartDataMock: ChartDataItem[] = [
  {
    ageRange: "18-24",
    value: 35,
  },
  {
    ageRange: "25-34",
    value: 25,
  },
  {
    ageRange: "35-44",
    value: 15,
  },
  {
    ageRange: "45-54",
    value: 10,
  },
  {
    ageRange: "55-64",
    value: 7,
  },
  {
    ageRange: "65+",
    value: 3,
  },
];
