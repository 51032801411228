import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import ButtonCancel from "../../../../../../common/components/ButtonCancel";
import ButtonPrimary from "../../../../../../common/components/ButtonPrimary";
import OcietyDialogTitle from "../../../../../../common/components/dialogs/OcietyDIalogTitle";
import { OcietyTheme } from "../../../../../../common/models";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { CurrentVenueState } from "../../../../../../store/currentVenue/reducer";
import { AppState } from "../../../../../../store/rootReducer";
import useBottomScrollListener from "../../hooks/useBottomScrollListener";
import Props from "./Props";
import { useStyles } from "./styles";

const ConfirmDeleteProfileDialog: FC<Props> = (props: Props) => {
  const {
    open,
    onClose,
    onConfirm,
    yesTypeAdditionalText,
    title,
    subtitle,
    message,
  } = props;

  const isMobile = useMediaQuery((theme: OcietyTheme) =>
    theme.breakpoints.down("xs")
  );

  const classes = useStyles();
  const additionalText = yesTypeAdditionalText ? yesTypeAdditionalText : "";
  const [isActiveDeleteButton, setIsActiveDeleteButton] =
    useState<boolean>(false);

  const renderConfirmButton = (active: boolean) => {
    return (
      <ButtonPrimary
        disabled={!active}
        className={classes.mobileButton}
        onClick={() => {
          onConfirm();
          onClose();
        }}>
        {dictionary.confirmDialog.yesDeleteButton(additionalText)}
      </ButtonPrimary>
    );
  };

  const { forms } = useSelector((state: AppState) => state);
  const form = forms.profile;

  const { currentVenue } = useSelector<AppState, CurrentVenueState>(
    (state) => state.currentVenue
  );

  const handleBottomReached = useCallback(() => {
    if (!isActiveDeleteButton) {
      setIsActiveDeleteButton(true);
    }
  }, [isActiveDeleteButton]);

  const scrollRef = useBottomScrollListener(handleBottomReached);

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      classes={{
        paperWidthSm: classes.root,
      }}>
      <OcietyDialogTitle id="confirmation-dialog-title" onClose={onClose}>
        {title || dictionary.confirmDialog.title}
      </OcietyDialogTitle>
      <Divider />
      <DialogContent className={classes.content} ref={scrollRef}>
        <Typography variant={"subtitle1"} className={classes.subtitle}>
          {subtitle || null}
        </Typography>
        <Typography className={classes.message}>{message}</Typography>

        {form.values.email && currentVenue && (
          <iframe
            id="deleteProfileIframeGoogleForm"
            src={`https://docs.google.com/forms/d/e/1FAIpQLSd5JYirdiC7qRoAtR9z_YHVGNCXIaJPEjJQSjlJUphZY6rVPQ/viewform?embedded=true&entry.1780289729=${currentVenue.name
              .split(" ")
              .join("+")}&entry.1228356849=${form.values.email}`}
            width="640"
            height="1753">
            Loading…
          </iframe>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <ButtonCancel onClick={onClose} className={classes.mobileButton}>
          {dictionary.confirmDialog.cancelButton}
        </ButtonCancel>
        {renderConfirmButton(isActiveDeleteButton)}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteProfileDialog;
