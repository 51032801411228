import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames =
  | "swipeableContainer"
  | "tabsWrapper"
  | "tabRoot"
  | "removeMenuButton"
  | "saveButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      swipeableContainer: {
        padding: theme.spacing(3),
        margin: theme.spacing(-3),
        "& > .react-swipeable-view-container > div": {
          overflow: "visible !important",
        },
      },
      tabsWrapper: {
        [theme.breakpoints.up("lg")]: {
          marginTop: theme.spacing(2),
        },
        [theme.breakpoints.down("lg")]: {
          marginTop: theme.spacing(1),
        },
        [theme.breakpoints.down("sm")]: {
          marginTop: 0,
        },
        "&:after": {
          marginTop: -3,
          content: "''",
          display: "block",
          width: "100%",
          height: 2,
          backgroundColor: "rgba(204, 208, 222, 0.35)",
        },
      },
      tabRoot: {
        padding: theme.spacing(1.5, 0),
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: 400,
        [theme.breakpoints.up("md")]: {
          minWidth: "auto",
        },
        "&:not(:first-of-type)": {
          [theme.breakpoints.up("md")]: {
            marginLeft: theme.spacing(70 / 8),
          },
          [theme.breakpoints.down("md")]: {
            marginLeft: theme.spacing(4),
          },
          [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(2),
          },
        },
      },
      removeMenuButton: {
        fontSize: 16,
        fontWeight: 700,
        textTransform: "none",
        padding: "12px 16px",
      },
      saveButton: {
        padding: "12px 16px",
        marginRight: 16,
      },
    })
);
