import React, { FC } from "react";

import { useStyles } from "./styles";

const BreakLine: FC = () => {
  const classes = useStyles();

  return <div className={classes.breakLine}></div>;
};

export default BreakLine;
