import { DialogContent, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { getRouteWithSlash, Route } from "../../../config/router";
import dictionary from "../../../i18n/en_US/dictionary";
import ButtonSuccess from "../ButtonSuccess";
import OcietyDialogTitle from "../dialogs/OcietyDIalogTitle";
import Props from "./Props";
import { useStyles } from "./styles";

const PackageInformationPopup: FC<Props> = (props) => {
  const { open, onClose, content, title, ...rest } = props;
  const [visible, setVisible] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const popupStatus = localStorage.getItem("popup_status");

    if (!popupStatus) {
      setVisible(true);
      localStorage.setItem("popup_status", "1");
    }
  }, []);

  if (!visible) return null;

  const handleClosePopup = () => {
    if (onClose) {
      // @ts-ignore
      onClose();
      setVisible(false);
    }
  };

  const handleGoToPackages = () => {
    handleClosePopup();
    history.push(getRouteWithSlash(Route.Plan));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      {...rest}>
      <OcietyDialogTitle
        id="mfa-call-dialog-title"
        onClose={handleClosePopup}
      />
      <DialogContent>
        <div className={classes.dialogContent}>
          <img
            src="/images/backgrounds/no-package-information-popup-bg.png"
            alt="no-package"
          />
          <Typography className={classes.title} variant={"h2"}>
            {title}
          </Typography>
          <Typography className={classes.message} variant={"body2"}>
            {content}
          </Typography>
          <ButtonSuccess
            className={classes.button}
            onClick={handleGoToPackages}>
            {dictionary.plan.takeToPackages}
          </ButtonSuccess>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PackageInformationPopup;
