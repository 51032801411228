import { capitalize, Typography } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { Cell, Legend, Pie, PieChart, Tooltip, TooltipPayload } from "recharts";

import ChartContainer from "../../../../../../common/components/ChartContainer";
import ChartTooltip from "../../../../../../common/components/ChartTooltip";
// eslint-disable-next-line max-len
import { useStyles as useTooltipStyles } from "../../../../../../common/components/ChartTooltip/styles";
import { VenueReportingContext } from "../../../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../../../common/hooks/useRequestByPeriod";
import { useVenueHasPackage } from "../../../../../../common/hooks/useVenueHasPacakage";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { PackageUID } from "../../../../../../store/currentVenue/types";
import {
  chartAdditionalColor,
  chartPrimaryColor,
  chartSecondAdditionalColor,
  chartSecondaryColor,
  colors,
} from "../../../../../../theme/colors";
import { LegendDefaultProps } from "../../../../defaultProps";
import { GET_VENUE_VIEWS_GENDER, GetVenueViewsGenderResponse } from "./api";
import { chartDataMock } from "./mock";

const Gender: FC = () => {
  const [chartData, setChartData] = useState(chartDataMock);
  const { handlePackageName } = useContext(VenueReportingContext);

  const hasPackage = useVenueHasPackage(PackageUID.CUSTOMER);
  const { response, loading } = useRequestByPeriod<GetVenueViewsGenderResponse>(
    GET_VENUE_VIEWS_GENDER,
    undefined,
    hasPackage
  );
  const tooltipClasses = useTooltipStyles();

  const getBarFillColor = (index: number) => {
    const valuesArray = chartData.map(({ gender }) => gender);
    const currentGender = valuesArray[index];

    return currentGender === "male"
      ? chartPrimaryColor
      : currentGender === "female"
      ? chartAdditionalColor
      : currentGender === "non-binary"
      ? chartSecondAdditionalColor
      : currentGender === "other"
      ? chartSecondaryColor
      : colors.grey.secondary;
  };

  useEffect(() => {
    if (response) {
      setChartData(response.stats);
    }
    if (!hasPackage) {
      setChartData(chartDataMock);
    }
  }, [response, hasPackage]);

  const renderTooltipContent = (payload: ReadonlyArray<TooltipPayload>) => {
    const data = payload[0].payload;

    return (
      <>
        <Typography className={tooltipClasses.label}>{`${
          data.value
        } % ${capitalize(data.gender)}`}</Typography>
      </>
    );
  };

  const isChartEmpty = !chartData.some((data) => data.value);

  return (
    <ChartContainer
      locked={!hasPackage}
      loading={loading}
      isChartEmpty={isChartEmpty}
      isChartSmall
      title={dictionary.reporting.venueGenderTitle}
      chartType={"pie-with-legend-bottom"}
      packageName={handlePackageName(PackageUID.CUSTOMER)}>
      <PieChart>
        <Pie
          data={chartData}
          dataKey="value"
          nameKey="gender"
          cx="50%"
          cy="50%"
          fill="#8884d8">
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getBarFillColor(index)} />
          ))}
        </Pie>
        <Tooltip
          content={<ChartTooltip renderCustomContent={renderTooltipContent} />}
        />
        <Legend
          {...LegendDefaultProps}
          wrapperStyle={{ ...LegendDefaultProps.wrapperStyle, left: 0 }}
        />
      </PieChart>
    </ChartContainer>
  );
};

export default Gender;
