import { AxiosRequestConfig } from "axios";
import { useCallback, useState } from "react";

import { http } from "../../config/api";

export type RequestMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type RestApiRequestData = Record<string, any> | undefined;
type RestApiResponseData = Record<string, any>;
type RestApiConfig = AxiosRequestConfig | undefined;

interface PutState {
  loading: boolean;
  error?: string;
  data: RestApiResponseData | null;
}

export function useRestApi<
  T extends RestApiResponseData,
  R extends RestApiRequestData = undefined,
  C extends RestApiConfig = undefined
>(
  apiPath: string,
  method: RequestMethod,
  config?: RestApiConfig,
  disableCache = false
) {
  interface State extends PutState {
    data: T | null;
  }

  const cachedResponse = disableCache ? null : localStorage.getItem(apiPath);

  const [state, setState] = useState<State>({
    loading: false,
    data: cachedResponse ? JSON.parse(cachedResponse) : null,
    error: undefined,
  });

  const fetchData = useCallback(
    async (data?: R): Promise<T> => {
      switch (method) {
        case "DELETE": {
          return await http.delete(apiPath, config);
        }
        case "GET": {
          return await http.get(apiPath, config);
        }
        case "POST": {
          return await http.post(apiPath, data, config);
        }
        case "PUT": {
          return await http.put(apiPath, data, config);
        }
        case "PATCH": {
          return await http.patch(apiPath, data, config);
        }
      }
    },
    //TODO fix ddos here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiPath, method]
  );

  const sendRequest = useCallback(
    async (requestData?: R): Promise<T | undefined> => {
      setState((prevState) => ({ ...prevState, loading: true }));

      try {
        const { data } = await fetchData(requestData);

        const newState = {
          error: undefined,
          loading: false,
          data,
        };

        setState(newState);

        if (!disableCache) {
          localStorage.setItem(apiPath, JSON.stringify(data));
        }

        return newState.data;
      } catch (e: any) {
        const error = e?.response?.data || "Something went wrong...";

        setState((prevState) => ({
          ...prevState,
          loading: false,
          error,
        }));

        throw e;
      }
    },
    [apiPath, fetchData, disableCache]
  );

  /**
   * Update state without request to the server
   */
  const updateData = useCallback((data: Record<keyof T, any>) => {
    setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        ...data,
      },
    }));
  }, []);

  return [state, sendRequest, updateData] as const;
}
