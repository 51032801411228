import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../common/models";

export type ClassNames = "root" | "title" | "rowItemRight" | "rowItemLeft";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        borderRadius: "0 !important",
        display: "flex",
        justifyContent: "center",
        height: 64,
        width: "auto",
        boxShadow: "30px 0px 30px rgba(59, 72, 118, 0.08) !important",
        padding: theme.spacing(0),
        backgroundImage: theme.palette.primary.main,
      },
      title: {
        fontWeight: 700,
        [theme.breakpoints.up("sm")]: {
          fontSize: 16,
        },
      },
      rowItemLeft: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
      },
      rowItemRight: {
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: theme.spacing(1),
      },
    })
);
