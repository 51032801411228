import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames =
  | "root"
  | "blockLabel"
  | "blockValue"
  | "permissionsContainer";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(1),
      },
      blockLabel: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
      },
      blockValue: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 700,
        wordBreak: "break-word",
      },
      permissionsContainer: {
        paddingTop: theme.spacing(3),
      },
    })
);
