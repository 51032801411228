import { Input } from "@material-ui/core";
import { InputProps } from "@material-ui/core/Input/Input";
import SearchIcon from "@material-ui/icons/Search";
import React, { FC } from "react";

import { useStyles } from "./styles";

const SearchInput: FC<InputProps> = (props: InputProps) => {
  const { style, ...rest } = props;

  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      <SearchIcon className={classes.icon} />
      <Input {...rest} className={classes.input} disableUnderline />
    </div>
  );
};

export default SearchInput;
