import { IconButton, Typography } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { ReactComponent as CartIcon } from "../../../../common/svg/icons/cart.svg";
import { isPackagesDisabled } from "../../../../config";
import { CartState } from "../../../../store/cart/reducer";
import { AppState } from "../../../../store/rootReducer";
import Props from "./Props";
import { useStyles } from "./styles";

const MiniCartButton: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const venueId = useCurrentVenueId();
  const { toggleCart } = props;
  const cartItems = useSelector<AppState, CartState>(
    (appState) => appState.cart
  );
  const currentCartItems = useMemo(
    () => cartItems[venueId] || [],
    [cartItems, venueId]
  );

  if (isPackagesDisabled) {
    return null;
  }

  return (
    <div>
      <IconButton className={classes.iconButton} onClick={toggleCart}>
        <CartIcon />
        {currentCartItems.length && currentCartItems.length > 0 ? (
          <div className={classes.counter}>
            <Typography className={classes.counterText} component={"span"}>
              {currentCartItems.length}
            </Typography>
          </div>
        ) : null}
      </IconButton>
    </div>
  );
};

export default MiniCartButton;
