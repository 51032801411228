import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import clsx from "clsx";
import React, { FC } from "react";

import { useStyles, useTabelStyles } from "./styles";

const UserActivityTableCell: FC<TableCellProps> = (props: TableCellProps) => {
  const { children, className, ...rest } = props;
  const tableClasses = useTabelStyles();

  const classes = useStyles();
  return (
    <TableCell
      classes={tableClasses}
      className={clsx(classes.cell, tableClasses)}
      {...rest}>
      {children}
    </TableCell>
  );
};
export default UserActivityTableCell;
