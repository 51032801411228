import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames = "root" | "title";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        flex: 1,
        flexDirection: "column",
        padding: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      title: {
        marginBottom: theme.spacing(1),
        fontWeight: "bold",
      },
    })
);
