import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../models";

export type VenueClassNames = "formControl" | "selectEmpty" | "errorTxt";

export const useStyles: () => ClassNameMap<VenueClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
        width: "100%",
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      errorTxt: {
        color: "red",
      },
    })
);
