import React, { FC, useContext } from "react";

import { useForm, UseFormTypes } from "../../../../common/hooks";
import { BasicForm } from "../../../../common/models";
import { PromoCodesContext } from "../../providers";
import {
  PromoCodesForm,
  PromoCodesFormErrorsField,
  PromoCodesFormSchema,
  PromoCodesFormSchemaType,
} from "./form";

interface Props {
  children?: React.ReactElement;
}

interface PromoCodeFormDialogContextInterface {
  mainForm: UseFormTypes<
    PromoCodesFormSchemaType,
    BasicForm<PromoCodesFormErrorsField>
  >;
  resetAndCloseForm: () => void;
}
export const DialogContext =
  React.createContext<PromoCodeFormDialogContextInterface>({
    mainForm: {
      form: PromoCodesForm,
      setAndValidate: () => undefined,
      setFormValue: () => undefined,
      setFormValues: () => undefined,
      setSchemaRules: () => undefined,
      clearErrors: () => undefined,
      validateForm: () => true,
    },
    resetAndCloseForm: () => undefined,
  });

const DialogContextProvider: FC<Props> = ({ children }) => {
  const mainForm = useForm(PromoCodesFormSchema, PromoCodesForm);
  const { setPromoCodeId, setFormDialogOpen, setIsPreview } =
    useContext(PromoCodesContext);
  const resetAndCloseForm = () => {
    setIsPreview(false);
    setPromoCodeId(0);
    setFormDialogOpen(false);
    mainForm.setFormValues(PromoCodesForm.values);
  };

  return (
    <DialogContext.Provider
      value={{
        mainForm,
        resetAndCloseForm,
      }}>
      {children}
    </DialogContext.Provider>
  );
};
export default DialogContextProvider;
