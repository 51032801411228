import { Dispatch } from "redux";

import HttpStatusCodes from "../../../common/httpStatusCodes";
import dictionary from "../../../i18n/en_US/dictionary";
import { getProfileData, saveProfileData } from "../api";
import IVenueAdminProfile from "../models/IVenueAdminProfile";
import {
  GET_PROFILE_FULFILLED,
  GET_PROFILE_PENDING,
  GET_PROFILE_REJECTED,
  GetProfileFailAction,
  GetProfilePendingAction,
  GetProfileSuccessAction,
  ProfileActionTypes,
  SAVE_PROFILE_FULFILLED,
  SAVE_PROFILE_PENDING,
  SAVE_PROFILE_REJECTED,
  SaveProfileFailAction,
  SaveProfilePendingAction,
  SaveProfileSuccessAction,
} from "./types";

const getProfilePendingAction = (): GetProfilePendingAction => ({
  type: GET_PROFILE_PENDING,
});

const getProfileSuccessAction = (
  profile: IVenueAdminProfile
): GetProfileSuccessAction => ({
  type: GET_PROFILE_FULFILLED,
  profile,
});

const getProfileFailAction = (error: string): GetProfileFailAction => ({
  type: GET_PROFILE_REJECTED,
  error,
});

export const getProfile =
  () => async (dispatch: Dispatch<ProfileActionTypes>) => {
    dispatch(getProfilePendingAction());

    try {
      const profile: IVenueAdminProfile = await getProfileData();
      dispatch(getProfileSuccessAction(profile));
    } catch (e) {
      dispatch(getProfileFailAction(e));
    }
  };

const saveProfilePendingAction = (): SaveProfilePendingAction => ({
  type: SAVE_PROFILE_PENDING,
});

const saveProfileSuccessAction = (): SaveProfileSuccessAction => ({
  type: SAVE_PROFILE_FULFILLED,
});

const saveProfileFailAction = (error: string): SaveProfileFailAction => ({
  type: SAVE_PROFILE_REJECTED,
  error,
});

export const saveProfile =
  (
    profile: IVenueAdminProfile,
    errCallback?: (msg?: string) => void,
    successCallback?: () => void
  ) =>
  async (dispatch: Dispatch<ProfileActionTypes>) => {
    dispatch(saveProfilePendingAction());

    try {
      await saveProfileData(profile);
      dispatch(saveProfileSuccessAction());

      if (successCallback) {
        successCallback();
      }
    } catch (error) {
      const msg = error.data.details
        ? error.data.details[0].message
        : error.data;

      dispatch(saveProfileFailAction(msg));
      if (errCallback) {
        if (error.data === "Insult word") {
          errCallback(dictionary.notifications.insultingMessageError);
        } else errCallback();
      }
    }
  };
