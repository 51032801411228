import { Dialog, useMediaQuery } from "@material-ui/core";
import React, { FC } from "react";

import { OcietyTheme } from "../../../../../../../../../common/models";
import Actions from "./components/Actions";
import Content from "./components/Content";
import Title from "./components/Title";
import DialogContextProvider from "./DialogContextProvider";
import Props from "./Props";

const DialogCreateHappyHours: FC<Props> = (props: Props) => {
  const { open, closeDialog, happyHourId } = props;

  const isMobile = useMediaQuery((theme: OcietyTheme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <DialogContextProvider>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        onClose={closeDialog}
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen={isMobile}
        maxWidth={isMobile ? false : "sm"}>
        <Title closeDialog={closeDialog} />
        <Content happyHourId={happyHourId} />
        <Actions closeDialog={closeDialog} happyHourId={happyHourId} />
      </Dialog>
    </DialogContextProvider>
  );
};

export default DialogCreateHappyHours;
