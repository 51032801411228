import { FormControl, InputLabel } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { ChangeEvent, FC } from "react";

import dictionary from "../../../../i18n/en_US/dictionary";
import SelectIcon from "../../SelectIcon";
import IOptionItem from "./IOptionItem";
import Props from "./Props";
import { useStyles } from "./styles";

const OcietySelect: FC<Props> = (props: Props) => {
  const {
    className,
    placeholder,
    onChangeSelect,
    value,
    options,
    helperText,
    error,
    allowEmptyValue,
    required,
  } = props;
  const classes = useStyles();

  const onChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (onChangeSelect) {
      onChangeSelect(event.target.value as string);
    }
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={className}
      error={!!error}>
      <InputLabel
        required={!!required}
        id="ociety-select"
        className={classes.selectLabel}>
        {placeholder ? placeholder : "Select..."}
      </InputLabel>
      <Select
        IconComponent={(iconProps) => <SelectIcon {...iconProps} />}
        classes={{ select: classes.select }}
        className={classes.selectWrapper}
        id="ociety-select-1"
        value={value || ""}
        defaultValue={""}
        label={placeholder ? placeholder : "Select..."}
        onChange={onChange}>
        {allowEmptyValue && (
          <MenuItem key="_" value="" disabled>
            {"   "}
          </MenuItem>
        )}
        {options.map((option: IOptionItem) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default OcietySelect;
