import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "actionsDialogButton"
  | "formField"
  | "errorMessage"
  | "successMessage"
  | "verificationSubtitle"
  | "radioChecked"
  | "radioLabel"
  | "checkedIcon"
  | "radioGroupWrapper"
  | "helperResendTxt"
  | "permissionsContainer"
  | "timer"
  | "resendWrapper"
  | "cursor"
  | "linkButtonGroup"
  | "linkButton"
  | "linkButtonIcon"
  | "disabledButton"
  | "linkDisabledButton"
  | "tooltip"
  | "claimButton"
  | "confirmOwnershipLabel"
  | "attachmentListCriteria"
  | "attachmentCriteriaText"
  | "dropzoneLabelText"
  | "dropzoneRoot"
  | "attachmentsList";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        padding: theme.spacing(4),
      },
      actionsDialogButton: {
        padding: "0px 32px 32px",
      },
      formField: {
        marginTop: theme.spacing(2),
      },
      errorMessage: {
        marginTop: theme.spacing(1),
        color: theme.palette.error.main,
      },
      successMessage: {
        marginTop: theme.spacing(1),
        color: theme.palette.success.main,
      },
      verificationSubtitle: {
        marginBottom: theme.spacing(4),
        fontSize: 16,
      },
      radioGroupWrapper: {
        flexDirection: "row",
        marginTop: 36,
        width: 650,
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          alignItems: "center",
          width: 400,
        },
      },
      radioLabel: {
        justifyContent: "center",
        border: "solid 2px #E5E7EF",
        borderRadius: 5,
        width: "49%",
        height: 132,
      },
      radioChecked: {
        border: "solid 2px #FE5454",
        backgroundColor: "#FE545410",
      },
      checkedIcon: {
        borderRadius: "50%",
        width: 20,
        height: 20,
        backgroundColor: colors.red.primary,
        "&:before": {
          display: "block",
          width: 20,
          height: 20,
          backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
          content: '""',
        },
      },
      helperResendTxt: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
      },
      resendWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
      },
      permissionsContainer: {
        paddingTop: theme.spacing(2),
      },
      timer: {
        marginLeft: theme.spacing(1),
        color: colors.red.primary,
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
      },
      cursor: {
        cursor: "pointer",
      },
      linkButtonGroup: {
        marginRight: "5%",
      },
      linkButtonIcon: {
        margin: "7px -3px",
      },
      linkButton: {
        backgroundColor: colors.grey.medium,
        color: `${colors.grey.stroke} !important`,
      },
      disabledButton: {
        backgroundColor: `${colors.grey.medium} !important`,
        color: `white !important`,
      },
      claimButton: {
        padding: "8px 25px",
        borderRadius: 4,
        borderColor: "transparent",
        textTransform: "none",
        "&:$disabled": {
          backgroundColor: "#C8C8C8",
        },
        fontWeight: "bold",
      },
      linkDisabledButton: {
        backgroundColor: `${colors.grey.stroke} !important`,
        color: `${colors.grey.additional} !important`,
        textTransform: "none",
        fontSize: "0.9em",
        marginRight: "-3px",
        height: "43px",
      },
      tooltip: {
        backgroundColor: colors.grey.primary,
        borderRadius: 4,
        boxShadow: "0px 6px 10px rgba(70, 72, 84, 0.15)",
        padding: theme.spacing(1, 2),
        fontSize: "0.9em",
      },
      confirmOwnershipLabel: {
        color: colors.grey.additional,
        fontWeight: 700,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
      },
      attachmentListCriteria: {
        paddingLeft: theme.spacing(2),
      },
      attachmentCriteriaText: {
        color: colors.grey.additional,
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(25),
        fontWeight: 600,
      },
      dropzoneLabelText: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 700,
        marginLeft: 14,
        marginTop: theme.spacing(0.5),
      },
      dropzoneRoot: {
        maxWidth: "100%",
        backgroundImage: "none",
        backgroundColor: colors.white.primary,
        marginTop: theme.spacing(2),
        flexDirection: "column",
      },
      attachmentsList: {
        fontWeight: 700,
        marginTop: theme.spacing(1),
      },
    })
);
