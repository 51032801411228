import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useContext, useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { SocialLinks } from "social-links";

import { AlertContext } from "../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../common/components/ButtonBlack";
import ButtonCancel from "../../common/components/ButtonCancel";
import LoadingIndicator from "../../common/components/LoadingIndicator";
import PageTitle from "../../common/components/PageTitle";
import { useRestApi } from "../../common/hooks/useRestApi";
import { useGlobalStyles } from "../../common/styles";
import { getRouteWithSlash, Route } from "../../config/router";
import dictionary from "../../i18n/en_US/dictionary";
import { UserManagementCriticRequest } from "../UserManagement/models";
import { APPROVE_CRITIC_REQUEST, DENY_CRITIC_REQUEST } from "./api";
import Props from "./Props";
import { useStyles } from "./styles";

const socialLinks = new SocialLinks();
const CriticRequest: FC<Props> = (props: Props) => {
  const requestId = parseInt(props.match.params.id);
  const { showAlert } = useContext(AlertContext);
  const history = useHistory();

  const [{ data, loading }, getVenueAddRequest] =
    useRestApi<UserManagementCriticRequest>(
      `user-management/critic-requests/${requestId}`,
      "GET"
    );

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const [{ loading: loadingApprove }, approveCriticRequest] = useRestApi<
    any,
    { phone: string }
  >(APPROVE_CRITIC_REQUEST(requestId), "PATCH");

  const [, denyCriticRequest] = useRestApi(
    DENY_CRITIC_REQUEST(requestId),
    "DELETE"
  );

  useEffect(() => {
    getVenueAddRequest().catch((e) => console.error(e));
  }, [getVenueAddRequest]);

  const redirectToTheList = () => {
    history.push(getRouteWithSlash(Route.UserManagement));
  };

  const handleApproveCriticRequest = (phone: string) => {
    approveCriticRequest({ phone })
      .then(() => {
        showAlert(dictionary.criticRequest.approveSuccess, "success");
        redirectToTheList();
      })
      .catch(() => {
        showAlert(dictionary.criticRequest.approveError, "error");
      });
  };

  const handleDenyCriticRequest = () => {
    denyCriticRequest()
      .then(() => {
        redirectToTheList();
      })
      .catch(() => {
        showAlert(dictionary.criticRequest.approveError, "error");
      });
  };

  return (
    <div className={classes.root}>
      <PageTitle backURL={Route.UserManagement}>
        {dictionary.criticRequest.pageTitle}
      </PageTitle>
      <Paper className={classes.content}>
        {loading && <LoadingIndicator withMask />}
        {data && (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h3">{data.fullName}</Typography>
              <div
                className={clsx(globalClasses.flexRow)}
                style={{ marginTop: 16 }}>
                <Typography variant="body2">Email:</Typography>
                <Typography variant="body1" style={{ marginLeft: 8 }}>
                  {data.email}
                </Typography>
              </div>
              <div
                className={clsx(globalClasses.flexRow)}
                style={{ marginTop: 16 }}>
                <Typography variant="body2">Phone Number:</Typography>
                <Typography variant="body1" style={{ marginLeft: 8 }}>
                  {data.phone}
                </Typography>
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6">User Account</Typography>

              <Typography
                variant="body2"
                style={{
                  marginTop: 8,
                  color: data.userStatusActive ? "#79C177" : "#FE5454",
                }}>
                {data.userStatusActive ? "Active" : "Not Active"}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div>
                <Typography variant="h6">Type of Critic</Typography>
                <Typography variant="body2" style={{ marginTop: 8 }}>
                  {data.criticSpeciality}
                </Typography>
              </div>
              <div style={{ marginTop: 32 }}>
                <Typography variant="h6">Reason to become critic</Typography>
                <Typography variant="body2" style={{ marginTop: 8 }}>
                  {data.description}
                </Typography>
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6">Links</Typography>
              {data.socialLinks.map((item, key) => (
                <div style={{ marginTop: 32 }} key={key}>
                  <Typography variant="body2">
                    {socialLinks.detectProfile(item)}
                  </Typography>
                  <Typography variant="body2" className={classes.socialUrl}>
                    {item}
                  </Typography>
                </div>
              ))}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className={classes.divider} />
            </Grid>

            <ButtonBlack
              onClick={() => handleApproveCriticRequest(data?.phone)}>
              {dictionary.criticRequest.approve}
            </ButtonBlack>
            <ButtonCancel
              style={{ marginLeft: 8 }}
              onClick={handleDenyCriticRequest}>
              {dictionary.criticRequest.deny}
            </ButtonCancel>
          </Grid>
        )}
      </Paper>
    </div>
  );
};

export default withRouter(CriticRequest);
