import { Switch } from "@material-ui/core";
import { SwitchProps } from "@material-ui/core/Switch";
import React, { FC } from "react";

import { useStyles } from "./styles";

const OcietySwitch: FC<SwitchProps> = (props) => {
  const { ...rest } = props;
  const classes = useStyles();

  return <Switch {...rest} className={classes.root} />;
};

export default OcietySwitch;
