import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "container"
  | "tabs"
  | "tabsDivider"
  | "tabsIndicator";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        flex: 1,
        display: "flex",
        position: "absolute",
        bottom: 0,
        top: 0,
        right: 0,
        left: 0,
      },
      container: {
        flex: 2,
        position: "relative",
        display: "flex",
        width: "100%",
        "& .MuiTabs-scroller": {
          width: "100%",
        },
        "& .MuiTab-root": {
          maxWidth: "none",
        },
      },
      tabs: {
        borderRight: `1px solid ${colors.grey.divider}`,
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          flex: 2,
        },
      },
      tabsDivider: {
        borderBottom: `1px solid ${colors.grey.divider}`,
        "&.Mui-selected": {
          backgroundColor: colors.grey.lighten,
        },
      },
      tabsIndicator: {
        left: 0,
      },
    })
);
