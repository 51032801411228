export const GET_PROMOTION_USAGES_BY_DEVICE =
  "reporting/promotion-usages-by-device-os";

export interface PromotionUsagesDeviceData {
  month: string;
  iphone: number;
  iphonePercent: number;
  android: number;
  androidPercent: number;
}

export interface GetPromotionUsagesByDeviceResponse {
  deviceStats: PromotionUsagesDeviceData[];
}
