import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type ClassNames = "root" | "label";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      },
      label: {
        paddingLeft: 10,
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(18),
      },
    })
);
