import { Paper, Typography } from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../i18n/en_US/dictionary";
import imageUtils from "../../../../utils/imageUtils";
import { useStyles as useBaseStyles } from "../../styles";
import MenuTabs from "./components/MenuTabs";
import { useStyles } from "./styles";

const VenuePdfMenu: FC = () => {
  const baseClasses = useBaseStyles();
  const classes = useStyles();
  return (
    <Paper className={baseClasses.section}>
      <Typography variant={"h2"} className={baseClasses.sectionTitle}>
        {dictionary.venues.venueForm.menuTitle}
      </Typography>
      <Typography variant={"body1"} className={classes.infoText}>
        {dictionary.venues.venueForm.menuInfoText}
      </Typography>
      <MenuTabs />
    </Paper>
  );
};

export default VenuePdfMenu;
