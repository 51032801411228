import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";

import Avatar from "../../../../../../../common/components/Avatar";
import BreakLine from "../../../../../../../common/components/BreakLine";
import ButtonBlack from "../../../../../../../common/components/ButtonBlack";
import { ReactComponent as EnvelopeIcon } from "../../../../../../../common/svg/icons/envelope.svg";
import { ReactComponent as SpeakerIcon } from "../../../../../../../common/svg/icons/speaker.svg";
import { ReactComponent as StarSharpFilledIcon } from "../../../../../../../common/svg/icons/start-sharp-filled.svg";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const ReviewDetailsDialog: FC<Props> = (props: Props) => {
  const { open, onClose, title } = props;
  const classes = useStyles();
  const UpdatedBy = () => (
    <div className={classes.updatedBy}>
      <Typography>Updated by 10:25 AM, 05/24/21</Typography>
    </div>
  );

  const UserDetails = () => (
    <div className={classes.userDetailsWrapper}>
      <Avatar size={48} />
      <Typography style={{ marginLeft: -8 }}>John</Typography>
      <ButtonBlack style={{ marginLeft: "auto" }}>
        <EnvelopeIcon className={classes.envelopeIcon} />
        {dictionary.reviews.allReviewsReply}
      </ButtonBlack>
    </div>
  );

  const StarLine = () => (
    <div className={classes.line}>
      <Typography>{dictionary.reviews.foodRating}</Typography>
      <div>
        <span style={{ marginRight: 10 }}>{dictionary.reviews.score(0)}</span>
        <StarSharpFilledIcon />
      </div>
    </div>
  );

  const NoiseLine = () => (
    <div className={classes.line}>
      <Typography>{dictionary.reviews.noiseLevel}</Typography>
      <div
        style={{ display: "flex", alignItems: "center" }}
        className={classes.noiseLineTextWrapper}>
        <span style={{ marginRight: 10 }}>{dictionary.reviews.score(0)}</span>
        <SpeakerIcon />
      </div>
    </div>
  );

  const TextLine = () => (
    <div className={classes.line}>
      <Typography>Atmosphere</Typography>
      <Typography>Romantic, Upscale </Typography>
    </div>
  );

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <DialogTitle className={classes.titleWrapper} disableTypography>
        <p className={classes.title}>{title}</p>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <UserDetails />
        <StarLine />
        <BreakLine />
        <StarLine />
        <BreakLine />
        <NoiseLine />
        <BreakLine />
        <TextLine />
        <BreakLine />
        <TextLine />
        <BreakLine />
        <TextLine />
      </DialogContent>
    </Dialog>
  );
};

export default ReviewDetailsDialog;
