import { Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { ReactComponent as ArrowIcon } from "../../../common/svg/icons/arrow-right-short-black.svg";
import { ReactComponent as CCIcon } from "../../../common/svg/icons/credit-card-black.svg";
import dictionary from "../../../i18n/en_US/dictionary";
import { CreditCartPaymentMethod, PayPalPaymentMethod } from "../../models";
import LoadingIndicator from "../LoadingIndicator";
import Props from "./Props";
import { useStyles } from "./styles";

const DefaultPaymentMethod: FC<Props> = (props: Props) => {
  const { paymentMethod, onClickEdit, loading } = props;
  const classes = useStyles();

  const renderPayPal = (paymentMethod: PayPalPaymentMethod) => (
    <div className={classes.flexColumn}>
      <Typography className={classes.cardNumber}>
        {paymentMethod.payPalAccount}
      </Typography>
      <div className={classes.cardAdditionalInfo}>
        <Typography className={classes.paymentVendor} component={"span"}>
          PayPal
        </Typography>
      </div>
    </div>
  );

  const renderCreditCard = (paymentMethod: CreditCartPaymentMethod) => (
    <div>
      <div>
        <Typography className={classes.cardNumber}>
          {paymentMethod.maskedNumber}
        </Typography>
        <div className={classes.cardAdditionalInfo}>
          <Typography className={classes.paymentVendor} component={"span"}>
            {paymentMethod.cardType}
          </Typography>
          <Typography className={classes.paymentVendor} component={"span"}>
            {" - "}
          </Typography>
          <Typography className={classes.paymentVendor} component={"span"}>
            {dictionary.plan.cardExpires(paymentMethod.expirationDate)}
          </Typography>
        </div>
      </div>
    </div>
  );

  const renderDefault = () => <div>Unrecognized</div>;

  const renderContent = () => {
    switch (paymentMethod.type) {
      case "cc": {
        return renderCreditCard(paymentMethod as CreditCartPaymentMethod);
      }
      case "paypal": {
        return renderPayPal(paymentMethod as PayPalPaymentMethod);
      }
      default: {
        return renderDefault();
      }
    }
  };

  return (
    <div
      className={clsx(
        classes.root,
        paymentMethod.type === "cc" ? classes.rootCC : classes.rootPayPal
      )}>
      <div className={classes.flexRow}>
        <Typography className={classes.label}>
          {dictionary.plan.defaultPaymentMethod}
        </Typography>
        <Typography className={classes.activeLabel} component={"span"}>
          {dictionary.plan.activeLabel}
        </Typography>
      </div>
      <div className={classes.content}>{renderContent()}</div>
      <Button fullWidth className={classes.buttonChange} onClick={onClickEdit}>
        <CCIcon className={classes.buttonChangeIcon} />
        {dictionary.plan.changePaymentMethod}
        <ArrowIcon className={classes.smallArrowIcon} />
      </Button>
      {loading && <LoadingIndicator withMask />}
    </div>
  );
};

export default DefaultPaymentMethod;
