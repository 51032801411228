import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../common/models";

export type ClassNames = "root" | "gridContainer";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      gridContainer: {
        display: "flex",
        justifyContent: "center",
      },
      root: {
        marginTop: theme.spacing(0.5),
        backgroundColor: "white",
        padding: 2,
        paddingBottom: 30,
        maxWidth: "100%",
        boxSizing: "border-box",
        overflowX: "auto",
        [theme.breakpoints.down("sm")]: {
          maxWidth: 500,
        },
        [theme.breakpoints.up("sm")]: {
          maxWidth: "100%",
        },
      },
    })
);
