import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "tableRow"
  | "heading"
  | "th"
  | "td"
  | "td35"
  | "td15"
  | "thead"
  | "tbody"
  | "venueName"
  | "editButton"
  | "deleteButton"
  | "actionsButtonWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      tbody: {
        boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)",
      },
      thead: {
        background: "transparent",
      },
      th: {
        border: "none",
      },
      td: {
        position: "relative",
        border: "none",
        "&:after": {
          content: "''",
          display: "block",
          position: "absolute",
          left: 0,
          bottom: 0,
          width: "100%",
          height: theme.spacing(1),
          background: theme.palette.background.default,
          boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)",
        },
        "&:first-of-type, &:last-of-type": {
          borderRadius: 4,
        },
      },
      td15: {
        width: "15%",
      },
      td35: {
        width: "35%",
      },
      tableRow: {
        padding: theme.spacing(3 + 4, 1, 3 + 4),
      },
      venueName: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
      },
      heading: {
        fontWeight: 600,
      },
      actionsButtonWrapper: {
        display: "flex",
        gap: 22,
      },
      editButton: {
        minWidth: 52,
      },
      deleteButton: {
        minWidth: 71,
      },
    })
);
