import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AuthState } from "../../store/auth/reducer";
import { AppState } from "../../store/rootReducer";

export function useIsSuperAdmin() {
  const { roles } = useSelector<AppState, AuthState>((state) => state.auth);
  const [isSuper, setIsSuper] = useState(roles.includes("ADMIN"));

  useEffect(() => {
    setIsSuper(roles.includes("ADMIN"));
  }, [roles]);

  return isSuper;
}
