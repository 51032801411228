import React, { Dispatch, FC, SetStateAction, useState } from "react";

import { HappyHour } from "../types/interfaces/HappyHour.inteface";

interface Props {
  children?: React.ReactElement;
}

interface PromotionsContext {
  happyHoursTabCount: number;
  setHappyHoursTabCount: Dispatch<SetStateAction<number>>;
  onClickItemId: number;
  setOnClickItemId: Dispatch<SetStateAction<number>>;
  isActiveDaysDialogOpen: boolean;
  setActiveDaysDialogOpen: Dispatch<SetStateAction<boolean>>;
  happyHoursList: HappyHour[];
  setHappyHoursList: Dispatch<SetStateAction<HappyHour[]>>;
}

export const PromotionsContext = React.createContext<PromotionsContext>({
  happyHoursTabCount: 0,
  setHappyHoursTabCount: () => undefined,
  onClickItemId: 0,
  setOnClickItemId: () => undefined,
  isActiveDaysDialogOpen: false,
  setActiveDaysDialogOpen: () => undefined,
  happyHoursList: [],
  setHappyHoursList: () => undefined,
});

const PromotionsContextProvider: FC<Props> = ({ children }) => {
  const [happyHoursTabCount, setHappyHoursTabCount] = useState<number>(0);
  const [onClickItemId, setOnClickItemId] = useState<number>(0);
  const [isActiveDaysDialogOpen, setActiveDaysDialogOpen] =
    useState<boolean>(false);

  const [happyHoursList, setHappyHoursList] = useState<HappyHour[]>([]);

  return (
    <PromotionsContext.Provider
      value={{
        happyHoursTabCount,
        setHappyHoursTabCount,
        onClickItemId,
        setOnClickItemId,
        isActiveDaysDialogOpen,
        setActiveDaysDialogOpen,
        happyHoursList,
        setHappyHoursList,
      }}>
      {children}
    </PromotionsContext.Provider>
  );
};

export default PromotionsContextProvider;
