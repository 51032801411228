import React, { FC } from "react";
import { Route } from "react-router-dom";

import Props from "./Props";

const RouteWithLayout: FC<Props> = (props: Props) => {
  const { layout: Layout, component, className, ...rest } = props;
  const Component: any = component;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout className={className}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;
