import { Checkbox, FormControlLabel } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import React, { FC, useState } from "react";

import {
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
  TERMS_OF_SALE,
} from "../../../../config";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import Props from "./Props";

const PermissionsCheckboxes: FC<Props> = (props) => {
  const { setIsValidateDisabled } = props;
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
  const [isContactPermittedChecked, setIsContactPermittedChecked] =
    useState(false);
  const [isBusinessOwnerChecked, setIsBusinessOwnerChecked] = useState(false);

  const openDocument = (e: any, component: string) => {
    e.preventDefault();
    const win = window.open(component, "_blank");
    win?.focus();
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={isPrivacyPolicyChecked}
            onChange={(event) => {
              setIsValidateDisabled(
                !event.target.checked ||
                  !isBusinessOwnerChecked ||
                  !isContactPermittedChecked
              );
              setIsPrivacyPolicyChecked(event.target.checked);
            }}
            name="isPrivacyPolicyChecked"
            color="primary"
          />
        }
        label={
          <>
            {dictionary.venues.claimVenueDialog.privatePolicyAgreement1}
            <Link
              onClick={(event: any) =>
                openDocument(event, TERMS_AND_CONDITIONS_URL)
              }>
              {dictionary.venues.claimVenueDialog.tou}
            </Link>
            ,{" "}
            <Link
              onClick={(event: any) => openDocument(event, PRIVACY_POLICY_URL)}>
              {dictionary.venues.claimVenueDialog.privacyPolicy}
            </Link>
            {dictionary.venues.claimVenueDialog.privatePolicyAgreement2}
            <Link onClick={(event: any) => openDocument(event, TERMS_OF_SALE)}>
              {dictionary.venues.claimVenueDialog.tos}
            </Link>
          </>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isContactPermittedChecked}
            onChange={(event) => {
              setIsValidateDisabled(
                !isPrivacyPolicyChecked ||
                  !isBusinessOwnerChecked ||
                  !event.target.checked
              );
              setIsContactPermittedChecked(event.target.checked);
            }}
            name="isContactPermittedChecked"
            color="primary"
          />
        }
        label={dictionary.venues.claimVenueDialog.contactPermission}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isBusinessOwnerChecked}
            onChange={(event) => {
              setIsValidateDisabled(
                !isPrivacyPolicyChecked ||
                  !event.target.checked ||
                  !isContactPermittedChecked
              );
              setIsBusinessOwnerChecked(event.target.checked);
            }}
            name="isBusinessOwnerChecked"
            color="primary"
          />
        }
        label={dictionary.venues.claimVenueDialog.isBusinessOwner}
      />
    </div>
  );
};

export default PermissionsCheckboxes;
