import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";

import { useStyles } from "../../styles";
import Props from "./Props";

const InformationBlockItem: FC<Props> = (props: Props) => {
  const { label, value } = props;

  const classes = useStyles();

  return (
    <Grid item lg={6} md={6} sm={6} xs={12} className={classes.blockItemRoot}>
      <Grid container spacing={1} className={classes.row}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Typography variant={"body2"} className={classes.blockLabel}>
            {label}
          </Typography>
        </Grid>
        <Grid item lg={9} md={9} sm={9} xs={9}>
          <Typography className={classes.blockValue}>{value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InformationBlockItem;
