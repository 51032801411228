import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "attachmentTitle"
  | "attachmentRoot"
  | "attachmentWrapper"
  | "attachmentWrapperEmpty"
  | "attachmentImage"
  | "slider"
  | "pdfLinkToNewPage";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(7),
      },
      attachmentRoot: {
        overflow: "hidden",
      },
      attachmentTitle: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
      },
      attachmentWrapperEmpty: {
        height: 247,
        border: "1px solid #BCBCBC",
        backgroundColor: "#F9F9FC",
        margin: theme.spacing(1),
      },
      attachmentWrapper: {
        border: "1px solid #BCBCBC",
        borderRadius: 4,
        cursor: "zoom-in",
        overflow: "hidden",
        position: "relative",
        height: 247,
        width: "fit-content",
        display: "flex !important",
        justifyContent: "center",
        margin: theme.spacing(1),
        "& > img": {
          transition: theme.transitions.create(["max-width", "height"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
        "&:hover": {
          " & > img": {
            maxWidth: "110%",
            height: "110%",
          },
          "&:before": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pointerEvents: "none",
            content: "url(/images/icons/eye.svg)",
            position: "absolute",
            boxSizing: "border-box",
            height: "100%",
            textAlign: "center",
            padding: theme.spacing(2),
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.46)",
            zIndex: 1,
          },
        },
      },
      attachmentImage: {
        maxWidth: "100%",
        height: "100%",
      },
      slider: {
        marginTop: theme.spacing(3),
      },
      pdfLinkToNewPage: {
        position: "absolute",
        width: "100%",
        height: "100%",
      },
    })
);
