import {
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../i18n/en_US/dictionary";
import Props from "./Props";

const VenuePendingInformationForAdmin: FC<Props> = (props) => {
  const { classes, form, onChange } = props;

  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
        <Typography variant={"h3"}>
          {dictionary.venuePending.informationForAdmin.title}
        </Typography>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <FormControl className={classes.formControl}>
          <TextField
            fullWidth
            multiline={true}
            rows={4}
            label={dictionary.venuePending.informationForAdmin.commentLabel}
            placeholder={
              dictionary.venuePending.informationForAdmin.commentPlaceholder
            }
            name="comment"
            error={!!form.errors.comment}
            helperText={
              form.errors.comment ||
              dictionary.venuePending.informationForAdmin.commentHelper
            }
            onChange={onChange}
            type="text"
            value={form.values.comment || ""}
            variant="outlined"
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default VenuePendingInformationForAdmin;
