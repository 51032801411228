import { Paper } from "@material-ui/core";
import React, { FC } from "react";

import PageTitle from "../../common/components/PageTitle";
import dictionary from "../../i18n/en_US/dictionary";
import VenuesPendingTable from "./components/VenuesPendingTable";
import { useStyles } from "./styles";

const VenuesPending: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PageTitle>{dictionary.venuesPending.pageTitle}</PageTitle>
      <Paper className={classes.content}>
        <div>
          <VenuesPendingTable />
        </div>
      </Paper>
    </div>
  );
};

export default VenuesPending;
