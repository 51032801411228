export const GET_ALL_BENEFITS = `venue/benefits`;

export const GET_VENUE_BENEFITS = (venueId: number) =>
  `venue/benefits/${venueId}`;

export const SAVE_VENUE_BENEFITS = (venueId: number) =>
  `venue/benefits/${venueId}/save`;

export interface BenefitsItem {
  id: number;
  name: string;
  group: string;
  createdAt: string;
  updatedAt: string;
}

export interface VenueBenefitItem {
  id: number;
  name: string;
  group: string;
}

export type GetBenefitsResponse = BenefitsItem;

export type VenueId = number;

export type GetVenueBenefitsResponse = VenueBenefitItem[];

export interface SaveVenueBenefitsResponse {
  success: boolean;
}

export type SaveVenueBenefitsRequest = number[];
