import { TabsClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const MuiTabs: Partial<Record<TabsClassKey, CSSProperties>> = {
  indicator: {
    height: 4,
  },
};

export default MuiTabs;
