import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type LinksClassNames =
  | "root"
  | "image"
  | "title"
  | "description"
  | "button";

export const useStyles: () => ClassNameMap<LinksClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      image: {
        width: 162,
        height: 112,
      },
      title: { marginTop: 15, fontWeight: 700, fontSize: 24, color: "#37393F" },
      description: {
        fontWeight: 600,
        fontSize: 16,
        color: "#747982",
        marginTop: 8,
        maxWidth: 460,
        textAlign: "center",
      },
      button: { marginTop: 24, color: "#FFFFFF" },
    })
);
