import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../common/models";
import { colors } from "../../../../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "promotionDaysWrapper"
  | "formControl"
  | "applyAllDaysRoot"
  | "helperText"
  | "dietaryRestrictionsSection"
  | "dietaryRestrictionsContainer"
  | "dietaryRestrictionItem"
  | "iconOctagramGreenWrapper"
  | "dietaryRestrictionsHeader"
  | "dietaryRestrictionsDetails"
  | "accordion"
  | "accordionDetails"
  | "accordionHeader"
  | "dietaryLabel"
  | "promotionAmountWrapper"
  | "promotionAmountLabel"
  | "promotionAmountMarginTop"
  | "inputLabelWithTooltip"
  | "inputWrapperWithTooltip"
  | "tooltipInfoIconInInputLabel"
  | "usdSign"
  | "infoTooltip"
  | "textOptionalLabel"
  | "marginTop10"
  | "infoIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        width: "auto",
        margin: theme.spacing(1, 0),
      },
      promotionDaysWrapper: {
        padding: 0,
      },
      accordion: {
        padding: "16px 24px",
      },
      accordionDetails: {
        padding: 0,
      },
      accordionHeader: {
        padding: 0,
      },
      formControl: {
        width: "100%",
      },
      applyAllDaysRoot: {
        backgroundColor: colors.grey.bgLight,
      },
      helperText: {
        color: "red",
      },
      dietaryRestrictionsSection: {
        paddingTop: "0 !important",
      },
      dietaryRestrictionsContainer: {
        padding: "0px 24px 24px",
      },
      dietaryRestrictionsDetails: {
        padding: 0,
      },
      dietaryRestrictionsHeader: {
        padding: 0,
      },
      dietaryRestrictionItem: {
        paddingLeft: 12,
        paddingTop: 12,
      },
      dietaryLabel: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
      },
      promotionAmountWrapper: {
        marginTop: 14,
      },
      promotionAmountLabel: {
        width: "100%",
        margin: 10,
      },
      promotionAmountMarginTop: {
        marginTop: 16,
      },
      inputLabelWithTooltip: {
        color: colors.grey.additional,
        fontWeight: 400,
        fontSize: 12,
        lineHeight: theme.typography.pxToRem(16),
      },
      inputWrapperWithTooltip: {
        display: "inline-flex",
        alignItems: "center",
      },
      tooltipInfoIconInInputLabel: {
        marginLeft: 6,
      },
      usdSign: {
        color: "#1F1F1F",
        fontWeight: 600,
      },
      infoTooltip: { marginTop: 10, marginLeft: 24 },
      textOptionalLabel: { color: colors.grey.secondary },
      marginTop10: { marginTop: 10 },
      infoIcon: {
        width: 20,
        height: 20,
        color: "#9BA2AE",
      },
      iconOctagramGreenWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 16,
      },
    })
);
