import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const MenuURLTab: FC<Props> = (props: Props) => {
  const { menuLink, error, handleChangeMenuLink, isUploadedLink } = props;
  const classes = useStyles();

  return (
    <div>
      <FormControl fullWidth className={classes.urlFieldWrapper}>
        <TextField
          fullWidth
          label={dictionary.venues.venueForm.menuTabs.url}
          name="locationId"
          error={!!error}
          helperText={error}
          onChange={handleChangeMenuLink}
          type="text"
          value={menuLink}
          variant="outlined"
        />
        {isUploadedLink && (
          <FormHelperText className={classes.helperText}>
            {dictionary.venues.venueForm.successUploadedHint}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default MenuURLTab;
