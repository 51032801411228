import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "label"
  | "priceFirst"
  | "priceSecond"
  | "button"
  | "priceContainer"
  | "nextPaymentDate";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        justifyContent: "space-between",
        border: `2px solid ${colors.grey.medium}`,
        borderRadius: 18,
        padding: theme.spacing(5),
      },
      label: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(20),
        marginBottom: theme.spacing(3),
      },
      priceFirst: {
        fontWeight: 700,
        lineHeight: 1,
        fontSize: theme.typography.pxToRem(42),
      },
      priceSecond: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(24),
        marginBottom: 5,
      },
      nextPaymentDate: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 700,
        color: colors.grey.additional,
        marginTop: theme.spacing(2),
      },
      priceContainer: {
        display: "flex",
        alignItems: "flex-end",
      },
      button: {
        minWidth: 186,
        minHeight: 52,
        marginTop: theme.spacing(3),
      },
    })
);
