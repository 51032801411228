import { capitalize, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { TooltipPayload } from "recharts";

import Props from "./Props";
import { useStyles } from "./styles";

const ChartTooltip: FC<Props> = (props: Props) => {
  const { active, payload, label, renderCustomContent } = props;
  const classes = useStyles();

  if (!active || !payload || !payload[0]) {
    return null;
  }

  const renderContent = () => (
    <>
      <Typography className={classes.label}>{label}</Typography>
      {payload.map(renderLabel)}
    </>
  );

  const renderLabel = ({ value, dataKey, unit }: TooltipPayload) => (
    <Typography key={dataKey as string} className={classes.label}>
      {value + " " + (unit ? " " + unit : capitalize(dataKey as string))}
    </Typography>
  );

  return (
    <div className={classes.root}>
      {renderCustomContent ? renderCustomContent(payload) : renderContent()}
    </div>
  );
};

export default ChartTooltip;
