import React, { FC } from "react";

import { Route } from "../../../config/router";
import dictionary from "../../../i18n/en_US/dictionary";
import IconDropdown from "../IconDropdown";
import OcietyIcon from "../OcietyIcon";
import OcietyNavLink from "../OcietyNavLink";
import { useStyles } from "./styles";

const HelpDropdown: FC = () => {
  const classes = useStyles();

  return (
    <IconDropdown icon={"question"}>
      <>
        <OcietyNavLink
          className={classes.link}
          to={Route.ContactUs}
          linkTextClassName={classes.linkText}
          icon={<OcietyIcon name={"contactUs"} />}>
          {dictionary.topBar.contactLink}
        </OcietyNavLink>
        <OcietyNavLink
          className={classes.link}
          linkTextClassName={classes.linkText}
          to={Route.Help}
          icon={<OcietyIcon name={"list"} />}>
          {dictionary.topBar.helpLink}
        </OcietyNavLink>
      </>
    </IconDropdown>
  );
};

export default HelpDropdown;
