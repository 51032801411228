import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../common/models";

export type ClassNames =
  | "root"
  | "listItemTitle"
  | "listItemDescription"
  | "listItemContent"
  | "list"
  | "listItem"
  | "nestedListItem"
  | "nestedListItemTitle"
  | "nestedListItemDescription"
  | "listItemUnderline"
  | "listItemBold"
  | "select"
  | "selectWrapper"
  | "selectIcon"
  | "listItemActions"
  | "listItemDropdownActions"
  | "listItemDropdownActionsText"
  | "chevronIconOpened"
  | "chevronIconNotOpened"
  | "colorRed"
  | "activeDaysAndHoursContainer";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
      },
      nestedListItem: {
        padding: "16px 0",
        alignItems: "flex-start",
        borderBottom: "solid 1px #E5E7EF",

        "& > div:last-child": {
          textAlign: "right",
        },

        "&:last-child": {
          border: "none",
        },
      },
      listItemTitle: {
        fontWeight: 600,
        fontSize: 14,
        color: "#747982",
      },
      listItemDescription: {
        fontWeight: 600,
        fontSize: 16,
        color: "#37393F",
      },
      listItemContent: {
        fontWeight: 600,
        fontSize: 14,
        color: "#595D64",
      },
      listItemUnderline: {
        textDecorationLine: "underline",
        fontWeight: 700,
        fontSize: 14,
        color: "#37393F",
      },
      listItemBold: {
        fontWeight: 700,
        fontSize: 14,
        color: "#45484E",
      },
      list: {},
      listItem: {
        padding: "8px 0",
        borderBottom: "solid 1px #E5E7EF",
        alignItems: "flex-start",
        "& > div:last-child": {
          textAlign: "right",
        },
      },
      nestedListItemTitle: {
        fontWeight: 600,
        fontSize: 14,
        color: "#595D64",
      },
      nestedListItemDescription: {
        fontWeight: 600,
        fontSize: 14,
        color: "#37393F",
      },
      selectWrapper: {
        boxSizing: "border-box",
        backgroundColor: "transparent",
        border: "1px solid rgba(121, 193, 119, 0.3)",
        borderRadius: 3,
        fontWeight: 700,
        fontSize: 15,
        color: "#79C177",
        minWidth: 148,
        textAlign: "left",
      },
      select: {
        padding: "9px 36px 9px 12px !important",
      },
      selectIcon: {
        top: 15.75,
        right: 13.5,
        position: "absolute",
      },
      listItemActions: {
        marginTop: 18,
        padding: "6px 0",
        justifyContent: "end",
      },
      listItemDropdownActions: { padding: 0, marginTop: 24 },
      listItemDropdownActionsText: { textAlign: "right", marginRight: 12 },
      chevronIconOpened: {
        width: 16,
        height: 9,
        color: "#FF6464",
        transform: "rotate(180deg)",
      },
      chevronIconNotOpened: { width: 16, height: 9, color: "#45484E" },
      colorRed: { color: "red" },
      activeDaysAndHoursContainer: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    })
);
