import { TextFieldClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const MuiTextField: Partial<Record<TextFieldClassKey, CSSProperties>> = {
  root: {
    borderRadius: 4,
    borderColor: "#EBECF0",
  },
};

export default MuiTextField;
