import { SET_USER, SET_USER_AVATAR, UserActionTypes } from "./types";
export const AVATAR_PLACEHOLDER_PATH = "/default-avatar-profile-icon-grey.jpg";

export interface UserState {
  avatar: string;
  firstName: string;
  lastName: string;
}

const defaultState: UserState = {
  avatar: AVATAR_PLACEHOLDER_PATH,
  firstName: "",
  lastName: "",
};

export default (
  state: UserState = defaultState,
  action: UserActionTypes
): UserState => {
  switch (action.type) {
    case SET_USER:
      return {
        avatar:
          action.avatar === undefined
            ? state.avatar
            : action.avatar || AVATAR_PLACEHOLDER_PATH,
        firstName: action.firstName || state.firstName,
        lastName: action.lastName || state.lastName,
      };
    case SET_USER_AVATAR:
      return {
        ...state,
        avatar: action.avatar,
      };
    default:
      return state;
  }
};
