import {
  CardActions,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import React, { ChangeEvent, FC, useEffect, useState } from "react";

import LoadingIndicator from "../../../../../../../common/components/LoadingIndicator";
import TBody from "../../../../../../../common/components/TBody";
import { useCurrentVenueId } from "../../../../../../../common/hooks/useCurrentVenueId";
import { ReactComponent as SortIcon } from "../../../../../../../common/svg/icons/sort.svg";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import {
  tableGetComparator,
  TableSortOrder,
  tableStableSort,
} from "../../../../../../../utils/tableUtils";
import ReviewDetailsDialog from "../../dialogs/ReviewDetailsDialog";
import UserReviewsTableRow from "../UserReviewsTableRow";
import VenueReviewsResponse, { getVenueReviews } from "./api";
import Props from "./Props";
import { useStyles } from "./styles";

interface HeadCell {
  id: keyof Data | string;
  label: string;
  sort?: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "username",
    label: dictionary.reviews.table.username,
  },
  {
    id: "createdAt",
    sort: true,
    label: dictionary.reviews.table.date,
  },
  {
    id: "food",
    sort: true,
    label: dictionary.reviews.table.foodRating,
  },
  {
    id: "action",
    label: dictionary.reviews.table.action,
  },
];

interface Data {
  createdAt: number;
  food: number;
  service: string;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: TableSortOrder;
  orderBy: string;
}

const UserReviewsTable: FC<Props> = (props: Props) => {
  const { period } = props;
  const [order, setOrder] = React.useState<TableSortOrder>("desc");
  const [orderBy, setOrderBy] = React.useState<any>("createdAt");
  const currentVenueId = useCurrentVenueId();
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const defaultRowsPerPage = [10, 20, 30];
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage[0]);

  const [reviewsResponse, setReviewsResponse] = useState<VenueReviewsResponse>({
    reviews: [],
    count: 0,
  });

  useEffect(() => {
    setLoading(true);
    getVenueReviews(
      currentVenueId,
      period,
      rowsPerPage,
      page * rowsPerPage
    ).then((data: VenueReviewsResponse) => {
      setLoading(false);
      setReviewsResponse(data);
    });
  }, [page, rowsPerPage, currentVenueId, period]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    currentPage: number
  ) => {
    setPage(currentPage);
  };

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => {
            return headCell?.sort ? (
              <TableCell
                key={headCell.id}
                className={classes.tableCell}
                sortDirection={orderBy === headCell.id ? order : false}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id as keyof Data)}
                  // @ts-ignore
                  IconComponent={SortIcon}>
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell className={classes.tableCell}>
                {headCell.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <div className={classes.root}>
        {loading && <LoadingIndicator withMask />}
        <Table style={{ tableLayout: "fixed" }}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TBody>
            {tableStableSort(
              reviewsResponse.reviews as any,
              tableGetComparator(order, orderBy)
            ).map((review) => (
              <UserReviewsTableRow
                review={review as any}
                key={review.id}
                setDialogOpen={setDialogOpen}
              />
            ))}
          </TBody>
        </Table>

        <CardActions>
          <TablePagination
            component="div"
            count={reviewsResponse?.count || 0}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={defaultRowsPerPage}
          />
        </CardActions>
      </div>
      <ReviewDetailsDialog
        title={"Details"}
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    </>
  );
};

export default UserReviewsTable;
