import React, { FC, useEffect } from "react";

import Props from "./Props";

const AutoHideMessage: FC<Props> = (props: Props) => {
  const [isHide, setHide] = React.useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setHide(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (isHide) {
    return null;
  }
  return <div>{props.children}</div>;
};

export default AutoHideMessage;
