import { AccordionSummaryClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const MuiAccordionSummary: Partial<
  Record<AccordionSummaryClassKey, CSSProperties>
> = {
  content: {
    margin: "22px 0",
  },
};

export default MuiAccordionSummary;
