export const GET_VENUE_VIEWS = "reporting/views-by-month";

export interface ViewsData {
  month: string;
  viewsCount: number;
}

export interface GetVenueViewsResponse {
  stats: ViewsData[];
}
