import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "titleWrapper"
  | "main"
  | "photo"
  | "uploadBtn"
  | "uploadPhotoTxt"
  | "deleteBtn";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        flexGrow: 1,
        marginBottom: 10,
      },
      titleWrapper: {
        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(3.8),
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: theme.spacing(1.8),
        },
      },
      main: {
        backgroundColor: "#fff",
      },
      photo: {
        position: "relative",
      },
      uploadPhotoTxt: {
        marginTop: 20,
      },
      uploadBtn: {
        textTransform: "none",
        width: 154,
        height: 44,
      },
      deleteBtn: {
        position: "absolute",
        zIndex: 30,
        padding: 0,
        display: "block",
        marginLeft: 120,
      },
    })
);
