import { Grid, TextField, Typography } from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { AlertContext } from "../../../../../../../../../../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../../../../../../../../../../common/components/ButtonBlack";
import VenueAutocompleteSearch from "../../../../../../../../../../../../../common/components/forms/VenueAutocompleteSearch";
import LoadingIndicator from "../../../../../../../../../../../../../common/components/LoadingIndicator";
import WorkingDaysPicker from "../../../../../../../../../../../../../common/components/WorkingDaysPicker";
import { WorkingDays } from "../../../../../../../../../../../../../common/components/WorkingDaysPicker/models";
import HttpStatusCodes from "../../../../../../../../../../../../../common/httpStatusCodes";
import { CroppedImageSize } from "../../../../../../../../../../../../../common/types/enums.type";
import dictionary from "../../../../../../../../../../../../../i18n/en_US/dictionary";
import imageUtils from "../../../../../../../../../../../../../utils/imageUtils";
import { daysHoursToWorkingDays } from "../../../../../../../../../../../AdminVenue/dialogs/PromotionFormDialog/components/PromotionFormDialogContent/utils";
import api from "../../../../../../../../../../../api";
import CardHappyHours from "../../../../../../../../../../../components/CardHappyHours";
import FileUpload from "../../../../../../../../../../../components/FileUpload";
import FileUploadProgress from "../../../../../../../../../../../components/FileUploadProgress";
import { HappyHour } from "../../../../../../../../../../../types/interfaces/HappyHour.inteface";
import { HappyHourFile } from "../../../../../../../../../../../types/interfaces/HappyHourFile.interface";
import { DialogContext } from "../../../../DialogContextProvider";
import {
  HappyHourMainForm,
  HappyHourMainFormField,
} from "../../../../forms/main";
import ImageDiscountStickerRed from "./../../../../../../../../../../../../../common/images/components/DialogCreateHappyHours/discount-sticker-red.png";
import Props from "./Props";
import { useStyles } from "./styles";

const Main: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const focusOnError = useRef<HTMLDivElement>(null);
  const errorHasFocus = useRef<boolean>(false);
  const { showAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    mainForm: { form, setAndValidate },
    mainForm,
    advancedForm,
    setCurrentStep,
    deals,
    setDeals,
    setUpdateDealItem,
    filesQueue,
    setFilesQueue,
    setHappyHourId,
    happyHourId,
    setDaysHours,
    daysHours,
    increaseCustomFormLengthProperty,
    decreaseCustomFormLengthProperty,
    isSaveDisabled,
    setIsSaveDisabled,
  } = useContext(DialogContext);

  const [workingDays, setWorkingDays] = useState<WorkingDays[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (props.happyHourId && !happyHourId) {
      setLoading(true);
      api.happyHours
        .itemById(props.happyHourId)
        .then((data: HappyHour) => {
          if (data) {
            mainForm.setFormValues({
              menuUrl: data.menuUrl,
              venueId: data.venue.id,
              lengthMenuFiles: data.files.length,
              lengthDeals: data.deals.length,
              filesPaths: "",
            });

            setHappyHourId(data.id);
            setFilesQueue(data.files);
            setDeals(data.deals);
            mainForm.setFormValue("venueId", String(data.venue.id));

            setDaysHours(data.daysHours);

            setWorkingDays(daysHoursToWorkingDays(data.daysHours));
          }
        })
        .catch((e) => {
          if (e?.status !== HttpStatusCodes.SERVER_ERROR) {
            showAlert(dictionary.deals.createDealForm.errorFetchData, "error");
          }
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.happyHourId]);

  useEffect(() => {
    if (!mainForm.form.isValid) {
      focusOnError?.current?.focus();
      errorHasFocus.current = true;
    }
  }, [mainForm.form.isValid]);

  const handleChangeFormNameField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAndValidate(
      event.target.name as HappyHourMainFormField,
      event.target.value
    );
  };

  useEffect(() => {
    let schemaRule: any;
    if (mainForm.form.values.menuUrl) {
      schemaRule = {
        lengthMenuFiles: {
          required: false,
        },
      };
      if (mainForm.form.errors.lengthMenuFiles) {
        mainForm.clearErrors(["lengthMenuFiles"]);
        mainForm.validateForm();
      }
    } else {
      schemaRule = {
        lengthMenuFiles: {
          fieldName: "Menu Files",
          type: "atLeastOne",
          required: true,
        },
      };

      if (mainForm.form.errors.menuUrl) {
        mainForm.clearErrors(["menuUrl"]);
        mainForm.validateForm();
      }
    }
    if (schemaRule) mainForm.setSchemaRules(schemaRule);
  }, [mainForm]);

  const CustomErrorText = ({ textError }: { textError: string }) => (
    <Typography component="span" variant="body2" color="error">
      {textError}
    </Typography>
  );

  const setAndValidateVenue = (id?: number) => {
    if (id) setAndValidate("venueId", String(id));
  };

  const removeDeal = (index: number) => {
    deals.splice(index, 1);
    setDeals([...deals]);
    decreaseCustomFormLengthProperty(1, "lengthDeals");
  };

  const editDeal = (deal: any) => {
    setUpdateDealItem(deal);
    advancedForm.setFormValues(deal);
    setCurrentStep(2);
  };

  const handleFileChange = (files: any | any[]) => {
    files = Array.isArray(files) ? files : [files];
    setFilesQueue((currentFiles) => [...currentFiles, ...files]);
  };

  const onFileUpload = useCallback(
    async (file: any, progressUpdate: (value: number) => void) => {
      let uploadedFile: any;

      if (file.type.match(/image/g)) {
        const croppedImage = await imageUtils.cropImage(
          file,
          CroppedImageSize.s1280x1280
        );
        setIsSaveDisabled(true);

        uploadedFile = await api.happyHours
          .uploadFile(croppedImage, progressUpdate)
          .catch((error) => {
            if (error.response.data === "File too large")
              showAlert(dictionary.notifications.fileIsTooLargeError, "error");
            else showAlert(error.response.data, "error");
          })
          .finally(() => {
            setIsSaveDisabled(false);
          });
      } else {
        setIsSaveDisabled(true);
        uploadedFile = await api.happyHours
          .uploadFile(file, progressUpdate)
          .catch((error) => {
            if (error.response.data === "File too large")
              showAlert(dictionary.notifications.fileIsTooLargeError, "error");
            else showAlert(error.response.data, "error");
          })
          .finally(() => setIsSaveDisabled(false));
      }

      setFilesQueue((filesQueue) =>
        filesQueue.map((fileQueue) =>
          fileQueue && file && file.name === fileQueue.name
            ? uploadedFile
            : fileQueue
        )
      );
    },
    [setFilesQueue, setIsSaveDisabled, showAlert]
  );

  useEffect(() => {
    const uploadedFiles = filesQueue.filter(
      (fileQueue) => fileQueue?.generatedName
    );
    if (uploadedFiles.length && uploadedFiles.length > 0)
      mainForm.setAndValidate("lengthMenuFiles", String(uploadedFiles.length));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesQueue]);

  const handleLinkFiles = () => {
    const { filesPaths } = mainForm.form.values;
    const filesPathsList = [
      ...Array.from(new Set(filesPaths.replaceAll(" ", "").split("\n"))),
    ];

    const filesNames = filesPathsList.map((filePath: string) =>
      filePath.substring(filePath.lastIndexOf("/") + 1)
    );

    api.happyHours
      .getFilesByFilesNames(filesNames)
      .then((files: HappyHourFile[]) => {
        if (files?.length > 0)
          setFilesQueue((filesQueue) => [...filesQueue, ...files]);
      })
      .catch((error) => showAlert(error.response.data, "error"));
  };

  const onFileRemove = (generatedName: string) =>
    generatedName
      ? setFilesQueue((filesQueue) => {
          decreaseCustomFormLengthProperty(1, "lengthMenuFiles");
          return filesQueue.filter(
            (fileQueue) => fileQueue?.generatedName !== generatedName
          );
        })
      : undefined;

  return (
    <>
      <Grid container spacing={4} className={classes.root}>
        {loading && <LoadingIndicator withMask />}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <VenueAutocompleteSearch
            error={form.errors.venueId}
            name="venueId"
            fullWidth
            style={{ marginTop: 4, marginRight: 20 }}
            venueId={Number(form.values.venueId)}
            onChange={setAndValidateVenue}
            label={"Select Venue"}
            inputRef={
              errorHasFocus.current && form.errors.venueId ? focusOnError : null
            }
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h3">
            {dictionary.deals.happyHours.dialog.useableOnlyDuring}
          </Typography>

          <div style={{ marginTop: 48 }}>
            <WorkingDaysPicker
              accordionSummary={
                dictionary.deals.createDealForm.usableOnSpecifiedDays
              }
              disableAddNewHoursPair={true}
              propsDay={workingDays}
              isRenderAccordion={false}
            />
          </div>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h3" className={classes.text1}>
              {dictionary.deals.happyHours.dialog.menu}
            </Typography>
            <Typography variant="body1" className={classes.text2}>
              {dictionary.deals.happyHours.dialog.menuDescription}
            </Typography>
            <FileUpload
              error={form.errors.lengthMenuFiles}
              onFileChange={handleFileChange}
              accept=".jpg,.jpeg,.png,.pdf"
            />
            {!!form.errors.lengthMenuFiles && (
              <CustomErrorText textError={form.errors.lengthMenuFiles} />
            )}
            {filesQueue.map((file: any, index: number) => (
              <FileUploadProgress
                key={index}
                file={file}
                onFileUpload={onFileUpload}
                onRemove={onFileRemove}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="body2" className={classes.textMenuUrl}>
              {dictionary.deals.happyHours.dialog.menuUrl}
            </Typography>
            <TextField
              className={classes.inputMenuUrl}
              fullWidth
              label={"Menu URL"}
              name={"menuUrl"}
              error={!!form.errors.menuUrl}
              helperText={form.errors.menuUrl}
              onChange={handleChangeFormNameField}
              type="text"
              value={form.values.menuUrl}
              variant="outlined"
              inputRef={
                errorHasFocus.current && form.errors.menuUrl
                  ? focusOnError
                  : null
              }
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2} justify="flex-end" alignItems="center">
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography variant="h3" className={classes.text1}>
              Linked Files
            </Typography>
            <Typography variant="body1" className={classes.text2}>
              Put here files you would like to link
            </Typography>
            <TextField
              className={classes.inputMenuUrl}
              fullWidth
              label={"Linked Files"}
              name={"filesPaths"}
              error={!!form.errors.filesPaths}
              helperText={form.errors.filesPaths}
              onChange={handleChangeFormNameField}
              type="text"
              value={form.values.filesPaths}
              variant="outlined"
              inputRef={
                errorHasFocus.current && form.errors.filesPaths
                  ? focusOnError
                  : null
              }
              multiline={true}
              rows={3}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <ButtonBlack onClick={handleLinkFiles}>Link Files</ButtonBlack>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h3" className={classes.text1}>
              Happy Hour Items
            </Typography>

            {deals.map((deal: any, index: number) => (
              <CardHappyHours
                deal={deal}
                key={`card-happy-hours-${index}`}
                index={index}
                onDelete={removeDeal}
                onEdit={() => editDeal(deal)}
              />
            ))}
            <div
              className={classes.addNewItemBlockContainer}
              style={{
                border: form.errors.lengthDeals && "1px solid red",
              }}>
              <div className={classes.addNewItemBlock}>
                <div>
                  <img
                    src={ImageDiscountStickerRed}
                    className={classes.addNewItemBlockImage}
                  />
                </div>

                <div>
                  <Typography variant="h3" className={classes.text1}>
                    {dictionary.deals.happyHours.addNewItem}
                  </Typography>
                  <Typography variant="body1" className={classes.text2}>
                    {dictionary.deals.happyHours.dialog.addItemDescription}
                  </Typography>
                </div>
              </div>
              <ButtonBlack onClick={() => setCurrentStep(2)}>
                {dictionary.general.add}
              </ButtonBlack>
            </div>
            {!!form.errors.lengthDeals && (
              <CustomErrorText textError={form.errors.lengthDeals} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Main;
