import React, { FC, useContext } from "react";

import { AlertContext } from "./AlertContextProvider";
import { useStyles } from "./styles";

const AlertsContainer: FC = () => {
  const { alerts } = useContext(AlertContext);

  const classes = useStyles();

  return <div className={classes.alertsContainer}>{alerts}</div>;
};

export default AlertsContainer;
