import { Form } from "../../../../../common/models";
import { ValidationRules } from "../../../../../utils/validation";

export type UpdateRoleFormField = "name" | "description";
export type UpdateRoleFormValues = {
  [key in UpdateRoleFormField]: string;
};
export type UpdateRoleFormErrors = {
  [key in UpdateRoleFormField]: string;
};

export interface UpdateRoleForm extends Form {
  values: UpdateRoleFormValues;
  errors: UpdateRoleFormErrors;
  isValid: boolean;
  touched: UpdateRoleFormField[];
}

export type CreateRoleFormSchema = Record<UpdateRoleFormField, ValidationRules>;

export const createRoleForm: UpdateRoleForm = {
  values: {
    name: "",
    description: "",
  },
  errors: {
    name: "",
    description: "",
  },
  touched: [],
  isValid: true,
};

export const schema: CreateRoleFormSchema = {
  name: {
    required: true,
    length: {
      max: 256,
    },
  },
  description: {
    required: true,
    length: {
      max: 256,
    },
  },
};
