import { InputAdornment, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent, FC } from "react";

import OcietyIcon from "../../../../../../../../common/components/OcietyIcon";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const EmailStep: FC<Props> = (props: Props) => {
  const { onSetEmail, isEmailValid, emailError, email, onSetEmailError } =
    props;
  const classes = useStyles();

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    onSetEmailError("");
    onSetEmail(e.target.value);
  };

  return (
    <div>
      <Typography variant="h1" className={classes.headerTitle}>
        {dictionary.profile.mfa.letsSetupYouEmail}
      </Typography>
      <Typography variant="body1" className={classes.helperTxt}>
        {dictionary.profile.mfa.enterEmail}
      </Typography>

      <div className={classes.emailWrapper}>
        <TextField
          required
          fullWidth
          id="filled-email"
          label={dictionary.profile.mfa.email}
          name="currentPass"
          error={!!emailError}
          helperText={emailError}
          onChange={handleChangeEmail}
          style={{ marginTop: "1rem" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isEmailValid && (
                  <OcietyIcon
                    name={"greenCheckMark"}
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  />
                )}
              </InputAdornment>
            ),
          }}
          value={email}
          type="email"
          variant="outlined"
        />
      </div>
    </div>
  );
};

export default EmailStep;
