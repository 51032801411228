import { Form } from "../../../../../common/models";
import { ValidationRules } from "../../../../../utils/validation";

export type CreateRoleFormField = "name" | "description";
export type CreateRoleFormValues = {
  [key in CreateRoleFormField]: string;
};
export type CreateRoleFormErrors = {
  [key in CreateRoleFormField]: string;
};

export interface CreateRoleForm extends Form {
  values: CreateRoleFormValues;
  errors: CreateRoleFormErrors;
  isValid: boolean;
  touched: CreateRoleFormField[];
}

export type CreateRoleFormSchema = Record<CreateRoleFormField, ValidationRules>;

export const createRoleForm: CreateRoleForm = {
  values: {
    name: "",
    description: "",
  },
  errors: {
    name: "",
    description: "",
  },
  touched: [],
  isValid: true,
};

export const schema: CreateRoleFormSchema = {
  name: {
    required: true,
    length: {
      max: 256,
    },
  },
  description: {
    required: true,
    length: {
      max: 256,
    },
  },
};
