import { Dispatch } from "react";

import { Venue } from "../../../common/models";
import { getAllVenues as gav } from "../api";
import {
  GET_VENUES_FULFILLED,
  GET_VENUES_PENDING,
  GET_VENUES_REJECTED,
  GetAllVenuesFailAction,
  GetAllVenuesPendingAction,
  GetAllVenuesSuccessAction,
  VenuesActionTypes,
} from "./types";

const getAllVenuesPendingAction = (): GetAllVenuesPendingAction => ({
  type: GET_VENUES_PENDING,
});

const getAllVenuesSuccessAction = (
  venues: Venue[],
  venuesCount: number
): GetAllVenuesSuccessAction => ({
  type: GET_VENUES_FULFILLED,
  venues,
  venuesCount,
});

const getAllVenuesFailAction = (error: string): GetAllVenuesFailAction => ({
  type: GET_VENUES_REJECTED,
  error,
});

export const getAllVenues =
  (skip?: number, limit?: number, search?: string) =>
  async (dispatch: Dispatch<VenuesActionTypes>) => {
    dispatch(getAllVenuesPendingAction());

    try {
      const { venues, venuesCount } = await gav(skip, limit, search);

      dispatch(getAllVenuesSuccessAction(venues, venuesCount));
    } catch (e) {
      dispatch(getAllVenuesFailAction(e));
    }
  };
