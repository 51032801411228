import { Grid } from "@material-ui/core";
import React, { FC } from "react";

import { Package } from "../../models";
import PackageCardGroup from "../PackageCardGroup";
import PackageCardSingle from "../PackageCardSingle";
import Props from "./Props";
import { useStyles } from "./styles";

const PlanTab: FC<Props> = (props: Props) => {
  const { packages, userPackages, enablePackage, disablePackage, toggleCart } =
    props;
  const classes = useStyles();
  const singlePackages: Package[] = [];
  const groupedPackages: Array<Package[]> = [];
  const currentUsagesLimit =
    userPackages?.find((pack) => pack.currentUsagesLimit)?.currentUsagesLimit ||
    null;

  const isActive = (currentPackage: Package) => {
    if (!userPackages || !userPackages.length) {
      return false;
    }

    const activePackages = userPackages.filter((up) => {
      return up?.id === currentPackage.id;
    });

    return activePackages.length > 0;
  };

  const sortPackages = () => {
    for (const pack of packages) {
      if (pack.group) {
        const existentGroup = groupedPackages.find((packsGroup) =>
          packsGroup.find((packFromGroup) => packFromGroup.group === pack.group)
        );

        if (existentGroup) {
          existentGroup.push(pack);
        } else {
          groupedPackages.push([pack]);
        }
      } else {
        singlePackages.push(pack);
      }
    }
  };

  sortPackages();

  return (
    <Grid container spacing={2} className={classes.root}>
      {singlePackages.map((packageItem: Package) => (
        <Grid item lg={4} md={4} sm={6} xs={12} key={packageItem.id}>
          <PackageCardSingle
            package={packageItem}
            active={isActive(packageItem)}
            enablePackage={enablePackage}
            disablePackage={disablePackage}
            toggleCart={toggleCart}
          />
        </Grid>
      ))}
      {groupedPackages.map((packagesGroup: Package[]) => (
        <Grid item lg={4} md={4} sm={6} xs={12} key={packagesGroup[0].group}>
          <PackageCardGroup
            currentUsagesLimit={currentUsagesLimit}
            packagesGroup={packagesGroup}
            activePackageId={packagesGroup.find(isActive)?.id}
            enablePackage={enablePackage}
            disablePackage={disablePackage}
            toggleCart={toggleCart}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PlanTab;
