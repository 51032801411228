import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { FC } from "react";

import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import VenueBenefitsAccordionContent from "../VenueBenefitsAccordionContent";
import Props from "./Props";
import { useStyles } from "./styles";

const VenueBenefitsAccordion: FC<Props> = (props: Props) => {
  const { sections, loading, onChangeBenefit, benefitsState } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {loading && <LoadingIndicator withMask />}
      {sections.map((section) => (
        <Accordion key={section.title}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content">
            <Typography variant={"body2"} className={classes.accordionTitle}>
              {section.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <VenueBenefitsAccordionContent
              content={section.content}
              onChangeBenefit={onChangeBenefit}
              benefitsState={benefitsState}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default VenueBenefitsAccordion;
