import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "disabled" | "leftBtn" | "rightBtn";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      disabled: {
        backgroundColor: "#F9F9FA",
      },
      leftBtn: {},
      rightBtn: {},
    })
);
