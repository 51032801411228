import React, { FC, useContext } from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import MFAItem from "../MFAItem";
import { disableCallMFAMethod } from "./api";
import Props from "./Props";

const MFACallControl: FC<Props> = (props: Props) => {
  const {
    isActive,
    isSelected,
    phone,
    setLoading,
    refreshData,
    onClickEnable,
    onSelectClick,
  } = props;
  const { showAlert } = useContext(AlertContext);
  let helpInfoTxt = "";
  if (isActive && phone) {
    helpInfoTxt = `+${phone}`;
  }

  const disableOTPMethod = async () => {
    try {
      setLoading(true);

      const result = await disableCallMFAMethod();

      if (!result) {
        showAlert(dictionary.profile.mfa.disableMFAError, "error");
        return;
      }

      showAlert(dictionary.profile.mfa.disableCallMFASuccess, "success");

      if (refreshData) {
        refreshData();
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(dictionary.profile.mfa.disableMFAError, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <MFAItem
      title={dictionary.profile.mfa.byCallCode}
      description={dictionary.profile.mfa.descriptionTextCall}
      info={helpInfoTxt}
      isActive={isActive}
      selected={isSelected}
      onEnableClick={() => {
        onClickEnable(true);
      }}
      onDisableClick={disableOTPMethod}
      onSelectClick={onSelectClick}
    />
  );
};
export default MFACallControl;
