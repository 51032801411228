import { AccordionSummaryProps } from "@material-ui/core/AccordionSummary";
import React from "react";

import SelectIcon from "../../common/components/SelectIcon";

const MuiAccordionSummary: AccordionSummaryProps = {
  expandIcon: <SelectIcon />,
};

export default MuiAccordionSummary;
