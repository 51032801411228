import { http } from "../../../../../../config/api";

const MFA_CALL_CODE_REQUEST = "profile/mfa/call-code-request";
const MFA_CALL_SET_REQUEST = "profile/mfa/call-set";
export const requestCodeCall = async (phoneNumber: number): Promise<any> =>
  http
    .post(MFA_CALL_CODE_REQUEST, { phoneNumber })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
export const sendCallCode = async (
  phoneNumber: number,
  code: string
): Promise<any> =>
  http
    .post(MFA_CALL_SET_REQUEST, { phoneNumber, code })
    .then((response) => {
      return { ...response.data };
    })
    .catch((error) => {
      throw error.response;
    });
