export interface VenueChangesPending {
  id: number;
  venueName: string;
  changedFields: string[];
  venueId: number;
}

export interface SaveVenuePendingMediaRequest {
  mediaType: string;
}

export const GET_VENUES_CHANGES_PENDING = "/venue/venues-changes-pending";
export const SAVE_VENUE_PENDING_MEDIA = (venueId: number): string =>
  `venue/${venueId}/save-pending-media`;
