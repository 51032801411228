import { FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { FC, useEffect, useState } from "react";

import OptionItem from "./OptionItem";
import Props from "./Props";
import { useStyles } from "./styles";

const SimpleSelect: FC<Props> = (props: Props) => {
  const { options, value, placeholder, onChange, error } = props;
  const selectedOption: OptionItem | undefined = options
    .filter((option: OptionItem) => option.value === value)
    .pop();

  const classes = useStyles();
  const [selectValue, setValue] = useState("");

  useEffect(() => {
    selectedOption && setValue(selectedOption.value);
  }, [selectedOption]);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);
    onChange && onChange(event.target.value as string);
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">
          {placeholder}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectValue || (selectedOption && selectedOption.value) || ""}
          onChange={handleChange}
          label={placeholder}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((option: OptionItem) => (
            <MenuItem key={option.value} value={option.value}>
              {option.display}
            </MenuItem>
          ))}
        </Select>
        {error ? (
          <FormHelperText className={classes.errorTxt}>{error}</FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
};

export default SimpleSelect;
