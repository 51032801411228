import { Form } from "../../../../common/models";
import { ValidationRules } from "../../../../utils/validation";

export type ContactUsFormField =
  | "yourName"
  | "email"
  | "companyName"
  | "phoneNumber"
  | "subject"
  | "message";
export type ContactUsFormValues = {
  [key in ContactUsFormField]: string;
};
export type ContactUsFormErrors = {
  [key in ContactUsFormField]: string;
};

export interface ContactUsForm extends Form {
  values: ContactUsFormValues;
  errors: ContactUsFormErrors;
  isValid: boolean;
  touched: ContactUsFormField[];
}

export type ContactUsFormSchema = Record<ContactUsFormField, ValidationRules>;

export const contactUsForm: ContactUsForm = {
  values: {
    yourName: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    subject: "",
    message: "",
  },
  errors: {
    yourName: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    subject: "",
    message: "",
  },
  touched: [],
  isValid: true,
};

export const schema: ContactUsFormSchema = {
  yourName: {
    required: true,
    length: {
      max: 256,
    },
  },
  email: {
    required: true,
    type: "email",
  },
  companyName: {
    length: {
      max: 256,
    },
  },
  phoneNumber: {
    required: true,
    type: "phone",
  },
  subject: {
    required: true,
    length: {
      max: 256,
    },
  },
  message: {
    length: {
      max: 2000,
    },
  },
};
