import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../common/models";
import { colors } from "../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "rowItem"
  | "headerRowItemText"
  | "gridContainer"
  | "rowItemText"
  | "rowItemName"
  | "rowItemCenter"
  | "rowItemSmallWidth"
  | "headers";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      gridContainer: {
        [theme.breakpoints.down("lg")]: {
          minWidth: 1200,
        },
      },
      root: {
        padding: "0px 30px 30px 30px",
        overflowX: "auto",
      },
      rowItem: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 1),
      },
      headerRowItemText: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
      },
      rowItemText: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
      },
      rowItemName: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2, 1),
      },
      rowItemCenter: {
        display: "flex",
        justifyContent: "center",
      },
      rowItemSmallWidth: {
        maxWidth: "8%",
      },
      headers: {
        width: "100%",
        display: "flex",
        padding: theme.spacing(1, 2),
      },
    })
);
