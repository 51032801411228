import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PageTitle from "../../common/components/PageTitle";
import { useCurrentVenueId } from "../../common/hooks/useCurrentVenueId";
import { useIsManager } from "../../common/hooks/useIsManager";
import { useIsSuperAdmin } from "../../common/hooks/useIsSuperAdmin";
import { Holiday } from "../../common/models";
import dictionary from "../../i18n/en_US/dictionary";
import { setForm } from "../../store/forms/actions";
import { AppState } from "../../store/rootReducer";
import Payment from "./components/Payment";
import POSAuthorization from "./components/POSAuthorization";
import VenueBenefits from "./components/VenueBenefits";
import VenueCommonData from "./components/VenueCommonData";
import VenueFormHelper from "./components/VenueCommonData/form/helpers/VenueFormHelper";
import WorkingDaysFormHelper from "./components/VenueCommonData/form/helpers/WorkingDaysFormHelper";
import VenueMediaContent from "./components/VenueMediaContent";
import VenuePdfMenu from "./components/VenueMenu";
import VenueTags from "./components/VenueTags";
import { getVenue } from "./ducks/actions";

const VenueSettings: FC = () => {
  const dispatch = useDispatch();
  const { venue } = useSelector<AppState, AppState>((state) => state);
  const venueId = useCurrentVenueId();
  const superAdmin = useIsSuperAdmin();
  const manager = useIsManager();
  const [holidays, setHolidays] = useState<Holiday[]>([]);

  useEffect(() => {
    dispatch(getVenue(venueId));
  }, [dispatch, venueId]);

  useEffect(() => {
    if (venue.venue) {
      dispatch(
        setForm("venue", VenueFormHelper.createVenueFormFromValue(venue.venue))
      );
      dispatch(
        setForm(
          "workingDays",
          WorkingDaysFormHelper.createWorkingDaysFormFromValue(venue.venue)
        )
      );
      setHolidays(venue.venue.holidays);
    }
  }, [dispatch, venue.venue]);

  return (
    <div>
      <PageTitle backURL={superAdmin || manager ? "go-back" : undefined}>
        {dictionary.venues.venueSettingsPageTitle}
      </PageTitle>
      <VenueCommonData holidays={holidays} setHolidays={setHolidays} />
      {process.env.REACT_APP_POS_ENABLED === "true" && <POSAuthorization />}
      <Payment />
      <VenueMediaContent />
      <VenueTags />
      <VenuePdfMenu />
      <VenueBenefits />
    </div>
  );
};

export default VenueSettings;
