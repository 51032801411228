import React, { Dispatch, FC, SetStateAction, useState } from "react";

interface Props {
  children?: React.ReactElement;
}

interface UserManagementContext {
  criticRequestsTabCount: number;
  setCriticRequestsTabCount: Dispatch<SetStateAction<number>>;
}

export const UserManagementContext = React.createContext<UserManagementContext>(
  {
    criticRequestsTabCount: 0,
    setCriticRequestsTabCount: () => undefined,
  }
);

const UserManagementContextProvider: FC<Props> = ({ children }) => {
  const [criticRequestsTabCount, setCriticRequestsTabCount] =
    useState<number>(0);

  return (
    <UserManagementContext.Provider
      value={{
        criticRequestsTabCount,
        setCriticRequestsTabCount,
      }}>
      {children}
    </UserManagementContext.Provider>
  );
};

export default UserManagementContextProvider;
