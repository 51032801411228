import moment from "moment";
import { useContext, useEffect } from "react";

import { VenueReportingContext } from "../components/VenueReportingContextProvider";
import { GetChartDataByPeriodRequest } from "../models";
import { useCurrentVenueId } from "./useCurrentVenueId";
import { useRestApi } from "./useRestApi";

export function useRequestByPeriod<
  TResponse,
  TRequest = GetChartDataByPeriodRequest
>(apiPath: string, additionalRequestData?: Record<string, any>, fetch = true) {
  const { period } = useContext(VenueReportingContext);
  const venueId = useCurrentVenueId();

  const [{ data: response, loading }, fetchData] = useRestApi<
    TResponse,
    TRequest | GetChartDataByPeriodRequest
  >(apiPath, "POST");

  useEffect(() => {
    const today = new Date();

    if (fetch) {
      fetchData({
        dateFrom: moment(today)
          .add(-period, "months")
          .endOf("month")
          .add(1, "day")
          .toString(),
        dateTo: moment(today).toString(),
        venueId,
        ...additionalRequestData,
      }).catch((e) => console.error(e));
    }
  }, [venueId, fetchData, period, additionalRequestData, fetch]);

  return { response, loading };
}
