import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";

export type ClassNames = "root" | "content" | "divider" | "waitlistCheckbox";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      content: {
        padding: "16px 24px 24px",
        marginTop: theme.spacing(2),
      },
      divider: {
        margin: theme.spacing(5, 0),
      },
      waitlistCheckbox: {
        marginTop: theme.spacing(2),
      },
    })
);
