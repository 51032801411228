import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { AppState } from "../../store/rootReducer";
import { useIsManager } from "./useIsManager";
import { useIsSuperAdmin } from "./useIsSuperAdmin";

export function useCurrentVenueId() {
  const superAdmin = useIsSuperAdmin();
  const manager = useIsManager();
  const { id } = useParams<{ id: string }>();
  const { currentVenue } = useSelector<AppState, AppState>((state) => state);

  const [venueId, setVenueId] = useState(
    id ? Number(id) : currentVenue.currentVenue?.id || 0
  );

  useEffect(() => {
    if ((superAdmin || manager) && id) {
      setVenueId(Number(id));
    } else {
      setVenueId(currentVenue.currentVenue?.id || 0);
    }
  }, [id, currentVenue.currentVenue, superAdmin, manager]);

  return venueId;
}
