import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "blockItemRoot"
  | "blockLabel"
  | "blockValue"
  | "row"
  | "comment"
  | "commentWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      blockLabel: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
      },
      blockValue: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 700,
      },
      row: {
        padding: theme.spacing(1, 0),
        height: "100%",
      },
      commentWrapper: {
        marginTop: theme.spacing(3),
      },
      comment: {
        marginTop: 5,
        padding: theme.spacing(3),
        backgroundColor: "rgba(237, 239, 246, 0.35)",
      },
      blockItemRoot: {
        borderBottom: "1px solid #F3F5F7",
      },
    })
);
