import { Button, DialogContent, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import React, { FC, useContext, useState } from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import ButtonSuccess from "../../../../../../common/components/ButtonSuccess";
import OcietyDialogTitle from "../../../../../../common/components/dialogs/OcietyDIalogTitle";
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import StepsIndicator from "../../../../../../common/components/StepsIndicator";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { checkCodeIsValid } from "../../../../../../utils/phoneNumberUtils";
import { requestCodeCall, saveCall } from "./api";
import CodeStep from "./components/CodeStep";
import PhoneNumberStep from "./components/PhoneNumberStep";
import Props from "./Props";
import { Steps } from "./Steps";
import { useStyles } from "./styles";

const CallMethodDialog: FC<Props> = (props: Props) => {
  const { isOpen, setOpen, onSuccessEnabled } = props;
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);

  const handleClose = () => {
    setOpen(false);
  };

  const [step, setStep] = useState(Steps.PHONE_STEP);
  const [loading, setLoading] = useState(false);
  const [CallPhoneNumber, setCallPhoneNumber] = useState(0);
  const [SMSSequrityCode, setSMSSequrityCode] = useState("");
  const [SMSSequrityCodeError, setSMSSequrityCodeError] = useState("");
  const [phoneNumberInputError, setPhoneNumberInputError] = useState("");

  const handleBack = () => {
    setStep(Steps.PHONE_STEP);
  };
  const handleRequestCode = async () => {
    if (!CallPhoneNumber) {
      return;
    }

    try {
      setLoading(true);

      const result = await requestCodeCall(CallPhoneNumber);

      if (!result) {
        showAlert(dictionary.profile.mfa.incorrectResponse, "error");
      }

      showAlert(dictionary.profile.mfa.successSendCallCodeMessage, "success");
      setStep(Steps.CODE_STEP);
    } catch (e) {
      if (!e) {
        return;
      }
      setPhoneNumberInputError(e.data);
      showAlert(e.data, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCallMethodSave = async () => {
    if (!CallPhoneNumber) {
      return;
    }

    if (!checkCodeIsValid(SMSSequrityCode)) {
      setSMSSequrityCodeError(dictionary.profile.mfa.codeError);
      return;
    }

    try {
      setLoading(true);

      const result = await saveCall(CallPhoneNumber, SMSSequrityCode);

      if (!result) {
        showAlert(dictionary.profile.mfa.incorrectResponse, "error");
      }

      showAlert(dictionary.profile.mfa.successCallMFAMethodEnabled, "success");
      if (onSuccessEnabled) {
        onSuccessEnabled();
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(dictionary.profile.mfa.errorSaveMFAMethodMessage, "error");
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const handleBtnClick = async () => {
    if (step === Steps.PHONE_STEP) {
      await handleRequestCode();
    }
    if (step === Steps.CODE_STEP) {
      await handleCallMethodSave();
    }
  };

  const setPhoneNumber = (phoneNumber: number) => {
    if (phoneNumber.toString().length < 11) {
      setPhoneNumberInputError("");
    }
    setCallPhoneNumber(phoneNumber);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title">
      <OcietyDialogTitle id="mfa-call-dialog-title" onClose={handleClose}>
        {dictionary.profile.mfa.verifyPhoneNumber}
      </OcietyDialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center">
          <Grid item xs={12}>
            {loading && <LoadingIndicator withMask />}
            <StepsIndicator
              stepsNames={[
                dictionary.profile.mfa.phoneNumberStep,
                dictionary.profile.mfa.confirmStep,
              ]}
              currentStep={step === Steps.CODE_STEP ? 2 : 1}
            />
            {step === Steps.PHONE_STEP && (
              <PhoneNumberStep
                onSetPhoneNumber={setPhoneNumber}
                phoneNumberInputError={phoneNumberInputError}
              />
            )}
            {step === Steps.CODE_STEP && (
              <CodeStep
                onSetCode={setSMSSequrityCode}
                phonenumber={CallPhoneNumber}
                resendCode={handleRequestCode}
                error={SMSSequrityCodeError}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {step === Steps.PHONE_STEP && (
          <>
            <Button
              variant="outlined"
              onClick={handleClose}
              className={classes.btn}>
              {dictionary.profile.mfa.cancelBtn}
            </Button>
            <ButtonSuccess onClick={handleBtnClick}>
              {dictionary.profile.mfa.continuBtn}
            </ButtonSuccess>
          </>
        )}
        {step === Steps.CODE_STEP && (
          <>
            <Button
              variant="outlined"
              onClick={handleBack}
              className={classes.btn}>
              {dictionary.profile.mfa.backBtn}
            </Button>
            <ButtonSuccess onClick={handleBtnClick}>
              {dictionary.profile.mfa.submitBtn}
            </ButtonSuccess>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default CallMethodDialog;
