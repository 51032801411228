import { useContext, useEffect } from "react";

import { PromotionFormDialogContext } from "../../../../../PromotionFormDialogContextProvider";
import { PromotionKind } from "../../../../PromotionFormDialogActions/PromotionKind.enum";
import { DB_ID_ENTIRE_MENU_VOUCHER_APPLIES_TO_CATEGORY } from "../constants";

export const useDefaultCategoryForVoucher = () => {
  const { mainForm } = useContext(PromotionFormDialogContext);
  const { setAndValidate } = mainForm;

  useEffect(() => {
    if (
      mainForm.form.values.kind === PromotionKind.VOUCHER &&
      mainForm.form.values.categoryId === ""
    ) {
      setAndValidate(
        "categoryId",
        DB_ID_ENTIRE_MENU_VOUCHER_APPLIES_TO_CATEGORY
      );
    }
  }, [
    mainForm.form.values.kind,
    mainForm.form.values.categoryId,
    setAndValidate,
  ]);
};
