import { Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import React, { FC, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";

// eslint-disable-next-line max-len
import { AlertContext } from "../../common/components/Alert/AlertContextProvider";
import Logo from "../../common/components/Logo";
import { Route } from "../../config/router";
import dictionary from "../../i18n/en_US/dictionary";
import { forgotPasswordReset } from "../../store/forgotPassword/actions";
import { ForgotPasswordMethodEnum } from "../../store/forgotPassword/types";
import { clearForm } from "../../store/forms/actions";
import { AppState } from "../../store/rootReducer";
import ChangePasswordForm from "./components/ChangePasswordForm";
import ForgotForm from "./components/ForgotForm";
import ForgotMethodForm from "./components/ForgotMethodForm";
import Props from "./Props";
import { ForgotPasswordClassNames, useStyles } from "./styles";

const ForgotPassword: FC<Props> = (props: Props) => {
  const step = useRef(1);
  const { location } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { showAlert } = useContext(AlertContext);
  // Get data from email
  const tokenGetParam = new URLSearchParams(location.search).get("token");
  const codeGetParam = new URLSearchParams(location.search).get("code");

  const classes: ClassNameMap<ForgotPasswordClassNames> = useStyles();
  const { forgotPassword }: AppState = useSelector((state: AppState) => state);

  useEffect(() => {
    return () => {
      dispatch(clearForm("forgotPassword"));
      dispatch(clearForm("changePassword"));
    };
  }, [dispatch]);

  const getCurrentComponent = () => {
    if (
      !forgotPassword.success &&
      forgotPassword.methods &&
      forgotPassword.methods.length
    ) {
      step.current = 2;

      return (
        <ForgotMethodForm
          error={forgotPassword.error}
          methods={forgotPassword.methods}
          email={forgotPassword.email}
          classes={classes}
        />
      );
    }

    if (
      forgotPassword.success &&
      forgotPassword.method?.method === ForgotPasswordMethodEnum.EMAIL
    ) {
      history.push(Route.Login);
      showAlert(
        dictionary.forgotPassword.msgEmailSend(forgotPassword.method.target),
        "success"
      );
      dispatch(forgotPasswordReset());
    }

    if (
      (forgotPassword.success &&
        forgotPassword.token &&
        forgotPassword.token.length) ||
      (codeGetParam && tokenGetParam)
    ) {
      const token = tokenGetParam || forgotPassword.token || "";
      step.current = 3;

      return (
        <ChangePasswordForm
          classes={classes}
          method={forgotPassword.method}
          token={token}
          codeProp={codeGetParam}
        />
      );
    }

    step.current = 1;
    return <ForgotForm classes={classes} />;
  };

  const component = getCurrentComponent();

  return (
    <div className={classes.content}>
      <div className={classes.contentBody}>
        <div className={classes.form}>
          <div className={classes.contentHeader}>
            <Logo className={classes.logo} width={84} height={84} />
          </div>
          <div className={classes.contentHeader}>
            <Typography className={classes.title} variant="h1">
              {dictionary.forgotPassword.title}
            </Typography>
            <Typography className={classes.stepTitle}>
              {dictionary.forgotPassword.stepTitle(step.current)}
            </Typography>
          </div>
          {component}
        </div>
      </div>
      <div className={classes.aside} />
    </div>
  );
};

export default withRouter(ForgotPassword);
