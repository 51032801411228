import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { Holiday as HD } from "date-holidays";
import moment from "moment";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import { AlertContext } from "../../../../../../../../common/components/Alert/AlertContextProvider";
import ButtonCancel from "../../../../../../../../common/components/ButtonCancel";
import ButtonPrimary from "../../../../../../../../common/components/ButtonPrimary";
import ButtonSuccess from "../../../../../../../../common/components/ButtonSuccess";
import OcietyDialogTitle from "../../../../../../../../common/components/dialogs/OcietyDIalogTitle";
import { useRestApi } from "../../../../../../../../common/hooks/useRestApi";
import { Holiday } from "../../../../../../../../common/models";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../../../../../store/rootReducer";
import { getFormattedDate } from "../../../../../../../../utils/dateTimeUtils";
import { UPDATE_HOLIDAYS } from "../../../../../../api";
import HolidaysEdit from "../HolidaysEdit";
import HolidaysList from "../HolidaysList";
import HolidaysLoad from "../HolidaysLoad";
import Props from "./Props";
import { useStyles } from "./styles";

const Holidays: FC<Props> = (props: Props) => {
  const { open, onClose, holidays, setHolidays } = props;
  const [title, setTitle] = useState(
    dictionary.venues.venueForm.holidaysListModeTitle
  );
  const [currentHolidays, setCurrentHolidays] = useState<Holiday[]>([]);
  const [currentHoliday, setCurrentHoliday] = useState<Holiday | null>(null);
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const { venue } = useSelector((state: AppState) => state.venue);
  const [, updateHolidays] = useRestApi<
    any,
    { id: number; holidays?: Holiday[] }
  >(UPDATE_HOLIDAYS, "PUT");

  useEffect(() => {
    setCurrentHolidays(holidays);
  }, [holidays]);

  const cancelHolidaysDialog = () => {
    onClose();
    changeMode(null);
    setCurrentHolidays(holidays);
  };

  const changeMode = (holiday: Holiday | null, isNewItem?: boolean) => {
    setCurrentHoliday(holiday);
    setTitle(
      !holiday
        ? dictionary.venues.venueForm.holidaysListModeTitle
        : isNewItem
        ? dictionary.venues.venueForm.holidaysAddModeTitle
        : dictionary.venues.venueForm.holidaysEditModeTitle
    );
  };

  const deleteHoliday = (id: string) => {
    const holidaysList = currentHolidays.filter((item) => item.id !== id);
    setCurrentHolidays(holidaysList);
  };

  const doneHolidaysDialog = () => {
    handleHolidaysDialogExit()
      .then(() => {
        onClose();
        showAlert(
          dictionary.venues.venueForm.holidaysSaveSuccessMessage,
          "success"
        );
      })
      .catch((error) => {
        if (error.response.data === "Insult word") {
          showAlert(dictionary.notifications.insultingMessageError, "error");
        }
      });
    changeMode(null);
  };

  const saveEditedHoliday = (editedHoliday: Holiday) => {
    const newHolidaysList: Holiday[] = currentHolidays
      ? [...currentHolidays]
      : [];

    if (currentHolidays?.some((item) => item.id === editedHoliday.id)) {
      // @ts-ignore
      for (const [index, holiday] of newHolidaysList.entries()) {
        if (holiday.id === editedHoliday.id) {
          newHolidaysList[index] = { ...editedHoliday };
          break;
        }
      }
    } else {
      newHolidaysList.push(editedHoliday);
    }

    setCurrentHolidays(newHolidaysList);
  };

  const handleHolidaysDialogExit = async () => {
    if (venue) {
      await updateHolidays({
        id: venue?.id,
        holidays: currentHolidays,
      });
      setHolidays(currentHolidays);
      setCurrentHolidays(holidays);
    }
  };

  const handlePressSave = () => {
    if (currentHoliday) {
      if (!currentHoliday.name) {
        return showAlert("Holiday name is required", "error");
      }

      saveEditedHoliday(currentHoliday);
      changeMode(null);
    } else {
      doneHolidaysDialog();
    }
  };

  const handlePressCancel = () => {
    if (currentHoliday) {
      changeMode(null);
    } else {
      cancelHolidaysDialog();
    }
  };

  const createNewHoliday = (): Holiday => {
    const today = getFormattedDate(new Date());

    return {
      id: uuid(),
      name: "",
      startTime: today,
      endTime: today,
    };
  };

  const handlePressAddHoliday = () => {
    const newHoliday = createNewHoliday();
    changeMode(newHoliday, true);
  };

  const handleImportHolidays = useCallback(
    (holidaysList: HD[]) => {
      const importedHolidays = holidaysList.map((holiday) => ({
        id: uuid(),
        name: holiday.name,
        startTime: getFormattedDate(holiday.start),
        // correct way is ->> getFormattedDate(holiday.end)
        // but we need to use only 1 date for now
        endTime: getFormattedDate(holiday.start),
      }));

      const prevHolidays = currentHolidays || [];

      setCurrentHolidays([
        ...prevHolidays,
        // filter to exclude duplicates
        ...importedHolidays.filter(
          (holiday) =>
            !prevHolidays.some(
              (prevHoliday) => holiday.startTime === prevHoliday.startTime
            ) && moment(holiday.startTime).isAfter(moment(new Date()))
        ),
      ]);
    },
    [currentHolidays]
  );

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <OcietyDialogTitle
        id="holidays_dialog_title"
        onClose={cancelHolidaysDialog}>
        {title}
      </OcietyDialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        {currentHoliday ? (
          <HolidaysEdit
            currentHoliday={currentHoliday}
            setCurrentHoliday={setCurrentHoliday}
          />
        ) : (
          <>
            <div className={classes.actionsWrapper}>
              <ButtonPrimary
                onClick={handlePressAddHoliday}
                className={classes.addButton}>
                {dictionary.venues.venueForm.holidaysAddButton}
              </ButtonPrimary>
              <HolidaysLoad handleImportHolidays={handleImportHolidays} />
            </div>
            <HolidaysList
              holidays={currentHolidays}
              setCurrentHoliday={(holiday) => changeMode(holiday, false)}
              deleteHoliday={(id) => deleteHoliday(id)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonCancel onClick={handlePressCancel}>
          {dictionary.venues.venueForm.holidaysCancelDialog}
        </ButtonCancel>
        <ButtonSuccess
          onClick={handlePressSave}
          disabled={
            !currentHoliday &&
            JSON.stringify(currentHolidays) === JSON.stringify(holidays)
          }>
          {currentHoliday
            ? dictionary.venues.venueForm.holidaysAddDialog
            : dictionary.venues.venueForm.holidaysSaveDialog}
        </ButtonSuccess>
      </DialogActions>
    </Dialog>
  );
};

export default Holidays;
