import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";

export type ClassNames =
  | "root"
  | "titleWrapper"
  | "dashboardIcon"
  | "title"
  | "periodSelect";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      dashboardIcon: {
        width: 70,
        height: 70,
      },
      title: {
        marginTop: -6,
        marginRight: theme.spacing(2),
      },
      periodSelect: {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          margin: theme.spacing(2, 0, 1),
        },
      },
      titleWrapper: {
        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(3.8),
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: theme.spacing(1.8),
        },
        [theme.breakpoints.down("sm")]: {
          flexWrap: "wrap",
        },
      },
    })
);
