import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import HttpStatusCodes from "../../../../common/httpStatusCodes";
import dictionary from "../../../../i18n/en_US/dictionary";
import api from "../../api";
import { PromoCodeResponseItem } from "../../models";
import { PromoCodesContext } from "../../providers";

export const useCouponTable = () => {
  const {
    searchValue,
    setPromoCodesList,
    setPromoCodesTabCount,
    setSearchValue,
  } = useContext(PromoCodesContext);
  const { showAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const skip = page * rowsPerPage;

  const fetchCoupons = useCallback(() => {
    setLoading(true);
    api.coupons
      .listAndCount(rowsPerPage, skip, searchValue)
      .then((data: { list: PromoCodeResponseItem[]; count: number }) => {
        if (data) {
          setPromoCodesList(data.list);
          setPromoCodesTabCount(data.count);
        }
      })
      .catch((e) => {
        if (e.status !== HttpStatusCodes.SERVER_ERROR) {
          showAlert(dictionary.deals.createDealForm.errorFetchData, "error");
        }
      })
      .finally(() => setLoading(false));
  }, [
    showAlert,
    searchValue,
    skip,
    rowsPerPage,
    setPromoCodesList,
    setPromoCodesTabCount,
  ]);

  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);

  const onChangeSearchValue = (search: string) => {
    setSearchValue(search);
    setPage(0);
  };

  const removeCouponCode = async (id: number) => {
    await api.coupons
      .remove(id)
      .then(() => {
        setPromoCodesList((happyHoursList) =>
          happyHoursList.filter((itemHappyHour) => itemHappyHour.id !== id)
        );
      })
      .catch((error) => {
        if (error.response.data === "Coupon code does not exist") {
          return showAlert(error.response.data, "error");
        }

        if (error.response.data === "Coupon code is already purchased") {
          return showAlert(
            dictionary.couponManagement.couponsDeletionErrorMsg,
            "error"
          );
        }

        return showAlert(dictionary.auth.authError, "error");
      });
  };

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return {
    page,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    loading,
    handleRowsPerPageChange,
    onChangeSearchValue,
    removeCouponCode,
    fetchCoupons,
  };
};
