import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../common/models";

export type ClassNames =
  | "root"
  | "wrapper"
  | "title"
  | "search"
  | "checkboxWrapper"
  | "checkbox";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        // marginTop: theme.spacing(40 / 8),
      },
      wrapper: {
        height: "100%",
        maxWidth: "100%",
        padding: 30,
      },
      title: {
        paddingBottom: 30,
        paddingLeft: 10,
        paddingTop: 8,
      },
      search: {
        paddingLeft: 10,
      },
      checkboxWrapper: {
        textAlign: "end",
      },
      checkbox: {
        marginRight: 0,
      },
    })
);
