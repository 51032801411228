import { http } from "../../../../../../config/api";
import ITotpData from "./models/ITotpData";

const MFA_PROFILE_REQUEST_TOTP = "profile/mfa/totp-request";
const MFA_PROFILE_SET_TOTP = "profile/mfa/totp-set";

export const requestTotp = async (password: string): Promise<ITotpData> =>
  http
    .post(MFA_PROFILE_REQUEST_TOTP, { password })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });

export const saveTotp = async (
  secret: string,
  description: string,
  otpCode: string
): Promise<ITotpData> =>
  http
    .post(MFA_PROFILE_SET_TOTP, { description, otpCode, secret })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
