import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames = "counter" | "counterText";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      counter: {
        position: "absolute",
        bottom: 20,
        right: 20,
        borderRadius: "50%",
        height: 22,
        width: 22,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.black.primary,
      },
      counterText: {
        color: colors.white.primary,
        fontSize: theme.typography.pxToRem(10),
        fontWeight: 800,
      },
    })
);
