import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";

import { useStyles } from "../../styles";
import Props from "./Props";

const InformationBlockItem: FC<Props> = (props: Props) => {
  const { label, value } = props;

  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.blockItemRoot}>
      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={4}>
          <Typography variant={"body2"} className={classes.blockLabel}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.blockValue}>{value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InformationBlockItem;
