import { ChatDataItem } from "../views/Messages/models";

export const getNewMessagesQty = (chatData: ChatDataItem[]): number => {
  let newMessagesQty = 0;

  if (chatData.length) {
    chatData.forEach((chatItem) => {
      chatItem.messages.forEach((chatMessage) => {
        if (!chatMessage.isYours && !chatMessage.isReadByYou) {
          newMessagesQty++;
        }
      });
    });
  }

  return newMessagesQty;
};
