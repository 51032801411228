import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { ColorsGray } from "../../../../../../../theme/colors";

export type ClassNames =
  | "paper"
  | "titleWrapper"
  | "title"
  | "content"
  | "input"
  | "actions"
  | "cancel"
  | "buttonBlack";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    paper: { maxWidth: "375px", width: "100%", height: "100%" },
    titleWrapper: {
      padding: `10px 10px 10px 16px`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #e1e2e3",
    },
    title: {
      fontWeight: 600,
      fontSize: 20,
      color: ColorsGray._1F1F1F,
    },
    content: {
      padding: 16,
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    input: { display: "flex", flex: 2, height: "100%" },
    actions: {
      display: "flex",
      marginTop: 10,
    },
    cancel: {
      width: "100%",
    },
    buttonBlack: {
      width: "100%",
      marginLeft: 15,
    },
  })
);
