import { Dialog, DialogContent, Divider } from "@material-ui/core";
import React, { FC } from "react";

import OcietyDialogTitle from "../../../../../../../../../common/components/dialogs/OcietyDIalogTitle";
import dictionary from "../../../../../../../../../i18n/en_US/dictionary";
import ListDaysAndHours from "../../../../../../../components/ListDaysAndHours";
import Props from "./Props";
import { useStyles } from "./styles";

const DialogActiveDaysAndHours: FC<Props> = ({
  onClose,
  isOpen,
  activeDaysAndHours,
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={"md"}
      disableBackdropClick
      aria-labelledby="add-new-venue-dialog"
      fullWidth>
      <OcietyDialogTitle
        id={"add-new-venue-dialog-title"}
        data-testid={"add-new-venue-dialog-title"}
        onClose={onClose}>
        {dictionary.deals.activeHours}
      </OcietyDialogTitle>
      <Divider />
      <DialogContent classes={{ root: classes.dialogContent }}>
        <ListDaysAndHours daysAndHours={activeDaysAndHours} />
      </DialogContent>
    </Dialog>
  );
};

export default DialogActiveDaysAndHours;
