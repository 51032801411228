import { MenuClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const MuiMenu: Partial<Record<MenuClassKey, CSSProperties>> = {
  paper: {
    margin: "5px 0",
    border: "1px solid #EBECF0",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
  },
  list: {
    padding: 0,
  },
};

export default MuiMenu;
