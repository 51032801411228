import { Hidden, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as LogoutIcon } from "../../../common/svg/icons/logout.svg";
import { RBACK_ENABLED } from "../../../config";
import { Route } from "../../../config/router";
import dictionary from "../../../i18n/en_US/dictionary";
import { purgePersistor } from "../../../store";
import { logout } from "../../../store/auth/actions";
import { setCurrentVenue } from "../../../store/currentVenue/actions";
import { AppState } from "../../../store/rootReducer";
import { setUser } from "../../../store/user/actions";
import { UserState } from "../../../store/user/reducer";
import { useIsManager } from "../../hooks/useIsManager";
import { useIsSuperAdmin } from "../../hooks/useIsSuperAdmin";
import { useRestApi } from "../../hooks/useRestApi";
import { useGlobalStyles } from "../../styles";
import { ReactComponent as GroupIcon } from "../../svg/icons/group.svg";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import { ConfirmModalType } from "../dialogs/ConfirmDialog/Props";
import IconDropdown from "../IconDropdown";
import OcietyIcon from "../OcietyIcon";
import OcietyNavLink from "../OcietyNavLink";
import SelectIcon from "../SelectIcon";
import { GET_USER_DATA_FOR_HEADER, LOGOUT_USER, UserHeaderData } from "./api";
import { useStyles } from "./styles";

const AccountDropdown: FC = () => {
  const classes = useStyles();
  const { avatar, firstName, lastName } = useSelector<AppState, UserState>(
    (appState) => appState.user
  );
  const globalStyles = useGlobalStyles();
  const dispatch = useDispatch();
  const superAdmin = useIsSuperAdmin();
  const manager = useIsManager();

  const [isUserWantsToLogout, setIsUserWantsToLogout] = useState(false);
  const [, getUserData] = useRestApi<any, UserHeaderData>(
    GET_USER_DATA_FOR_HEADER,
    "GET"
  );
  const [, userLogout] = useRestApi(LOGOUT_USER, "POST");
  useEffect(() => {
    getUserData()
      .then((data) => {
        if (data) {
          dispatch(setUser(data));
        }
      })
      .catch((e) => console.error(e));
  }, [dispatch, getUserData]);

  const handleLogout = () => {
    userLogout()
      .then(() => {
        purgePersistor();
        dispatch(setCurrentVenue(undefined));
        dispatch(logout());
      })
      .catch((e) => console.log(e));
  };

  const openConfirmationOnLogout = () => {
    manager ? setIsUserWantsToLogout(true) : handleLogout();
  };

  const getAccountIconComponent = (onClick: () => void, className?: string) => (
    <div
      className={clsx(globalStyles.flexRow_Center, className, classes.toggler)}
      onClick={onClick}>
      <div className={classes.avatarWrapper}>
        <img
          className={classes.avatar}
          src={avatar}
          alt={"some user profile icon"}
        />
      </div>
      <Hidden smDown>
        <Typography className={classes.userName}>
          {firstName + " " + lastName}
        </Typography>
        <SelectIcon className={classes.caretIcon} />
      </Hidden>
      <ConfirmDialog
        open={isUserWantsToLogout}
        onClose={() => setIsUserWantsToLogout(false)}
        onConfirm={handleLogout}
        type={ConfirmModalType.CONFIRM}
        message={""}
        subtitle={dictionary.topBar.confirmationLogoutMessage}
      />
    </div>
  );

  return (
    <IconDropdown button={getAccountIconComponent}>
      <>
        <OcietyNavLink
          className={classes.link}
          to={Route.Profile}
          linkTextClassName={classes.linkText}
          icon={<OcietyIcon name={"userProfile"} />}>
          {dictionary.topBar.profileLink}
        </OcietyNavLink>
        {RBACK_ENABLED && superAdmin && !manager && (
          <OcietyNavLink
            className={classes.link}
            to={Route.Rbac}
            linkTextClassName={classes.linkText}
            icon={<OcietyIcon name={"roles"} />}>
            {dictionary.topBar.rbacLink}
          </OcietyNavLink>
        )}

        {!superAdmin && !manager && (
          <OcietyNavLink
            className={classes.link}
            to={Route.VenueStaff}
            linkTextClassName={classes.linkText}
            icon={<GroupIcon />}>
            {dictionary.topBar.venueStaffLink}
          </OcietyNavLink>
        )}
        {superAdmin && (
          <OcietyNavLink
            className={classes.link}
            to={Route.Settings}
            linkTextClassName={classes.linkText}
            icon={<OcietyIcon name={"gear"} />}>
            {dictionary.topBar.settingsLink}
          </OcietyNavLink>
        )}
        <div
          onClick={openConfirmationOnLogout}
          className={classes.logoutButton}>
          <LogoutIcon />
          <Typography className={classes.logoutText}>
            {dictionary.topBar.logoutLink}
          </Typography>
        </div>
      </>
    </IconDropdown>
  );
};

export default AccountDropdown;
