export enum DiscountStatus {
  ENABLED = "ENABLED",
  PAUSE = "PAUSE",
  EXPIRED = "EXPIRED",
  CANCELLED = "CANCELLED",
  PENDING = "PENDING",
}

export enum HappyHourDealStatus {
  ENABLED = "ENABLED",
  PAUSE = "PAUSE",
  PENDING = "PENDING",
}

export interface IDiscountStatusItem {
  id: DiscountStatus;
  label: string;
}

export const DiscountStatusLabel = {
  [DiscountStatus.ENABLED]: "Enabled",
  [DiscountStatus.PAUSE]: "Pause",
  [DiscountStatus.EXPIRED]: "Expired",
  [DiscountStatus.CANCELLED]: "Cancelled",
  [DiscountStatus.PENDING]: "Pending",
};

export const discountStatusLabelItems: IDiscountStatusItem[] = [
  {
    id: DiscountStatus.ENABLED,
    label: DiscountStatusLabel[DiscountStatus.ENABLED],
  },
  {
    id: DiscountStatus.PAUSE,
    label: DiscountStatusLabel[DiscountStatus.PAUSE],
  },
  {
    id: DiscountStatus.EXPIRED,
    label: DiscountStatusLabel[DiscountStatus.EXPIRED],
  },
  {
    id: DiscountStatus.CANCELLED,
    label: DiscountStatusLabel[DiscountStatus.CANCELLED],
  },
];
