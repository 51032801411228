import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

type ClassNames =
  | "root"
  | "row"
  | "spacer"
  | "importButton"
  | "exportButton"
  | "searchInput";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginBottom: theme.spacing(1),
      },
      row: {
        height: "42px",
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(1),
        width: "100%",
      },
      spacer: {
        flexGrow: 1,
      },
      importButton: {
        marginRight: theme.spacing(1),
      },
      exportButton: {
        marginRight: theme.spacing(1),
      },
      searchInput: {
        width: "100%",
      },
    })
);
