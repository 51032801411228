import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "./models";

export type ClassNames =
  | "chart"
  | "chartWithYAxis"
  | "chartWrapper"
  | "flexRow"
  | "flexRowBetweenEnd"
  | "flexRowBetweenCenter"
  | "flexRowStartCenter"
  | "flexColumn"
  | "flexColumnCenterCenter"
  | "flexColumnCenterCenterReverse"
  | "flexRow_Center"
  | "flexRowEndCenter";

export const useGlobalStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      flexRow: {
        display: "flex",
        flexDirection: "row",
      },
      flexRowBetweenEnd: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
      },
      flexRowBetweenCenter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
      flexRowStartCenter: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
      },
      flexColumn: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      flexRow_Center: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      flexColumnCenterCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      flexColumnCenterCenterReverse: {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center",
      },
      flexRowEndCenter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
      },
      chartWrapper: {
        height: "100%",
        maxWidth: "100%",
      },
      chart: {
        height: "auto !important",
        transition: theme.transitions.create(["width", "height"], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.complex,
        }),
        maxWidth: "99% !important", // Do not change it!
        display: "flex",
        margin: theme.spacing(3, "auto", 0),
      },
      chartWithYAxis: {
        left: -10,
        height: "auto !important",
        transition: theme.transitions.create(["width", "height"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        maxWidth: "99% !important", // Do not change it!
        display: "flex",
        margin: theme.spacing(3, "auto", 0),
      },
    })
);
