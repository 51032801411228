import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "companionMessageItemWrapper"
  | "companionTextMessage"
  | "yourMessageItemWrapper"
  | "yourTextMessage"
  | "yourMessageAndDateWrapper"
  | "companionMessageAndDateWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        flex: 1,
        width: "100%",
        overflow: "auto",
      },
      companionMessageItemWrapper: {
        margin: theme.spacing(0, 0, 0, 2),
        alignItems: "flex-end",
        width: "96%",
      },
      companionTextMessage: {
        display: "block",
        marginBottom: theme.spacing(1),
        padding: theme.spacing(3),
        overflowWrap: "anywhere",
        backgroundColor: colors.grey.lighten,
        maxWidth: 500,
        float: "left",
      },
      companionMessageAndDateWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginRight: theme.spacing(2),
      },
      yourMessageItemWrapper: {
        margin: theme.spacing(0, 0, 0, 2),
        alignItems: "flex-end",
        width: "96%",
        flexDirection: "row-reverse",
      },
      yourTextMessage: {
        display: "block",
        float: "right",
        overflowWrap: "anywhere",
        marginBottom: theme.spacing(1),
        padding: theme.spacing(3),
        backgroundColor: colors.red.primary,
        color: colors.white.primary,
        maxWidth: 500,
      },
      yourMessageAndDateWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginRight: theme.spacing(2),
      },
    })
);
