import { TextField } from "@material-ui/core";
import React, { FC, useState } from "react";

import Props from "./Props";

const EmailStep: FC<Props> = (props: Props) => {
  const { onSetEmail } = props;

  const [email, setEmail] = useState("");

  const isEmailValid = (email: string): boolean => {
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (pattern.test(email)) {
      return true;
    }
    return false;
  };

  if (isEmailValid(email)) {
    onSetEmail(email);
  }

  return (
    <div>
      <form noValidate autoComplete="off">
        <TextField
          id="phone-number"
          type="email"
          label="Email"
          error={!isEmailValid(email)}
          helperText={!isEmailValid(email) && "Invalid email"}
          value={email}
          onChange={(event: any) => {
            // @ts-ignore
            setEmail(event.target.value);
          }}
        />
      </form>
    </div>
  );
};
export default EmailStep;
