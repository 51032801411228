import { TextField } from "@material-ui/core";
import React, { FC, useState } from "react";

import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";

const CodeStep: FC<Props> = (props: Props) => {
  const { onSetCode } = props;
  const [code, setCode] = useState<string | null>(null);

  const codeIsValid = (code: string): boolean => {
    if (code) {
      const match = code.match(/\d/g);
      if (!match) {
        return false;
      }
      return match.length === 6;
    }

    return false;
  };

  let isError = false;

  if (code || typeof code === "string") {
    isError = !codeIsValid(code);
  }

  if (code && !isError) {
    onSetCode(code);
  }

  return (
    <div>
      <form noValidate autoComplete="off">
        <TextField
          id="phone-number"
          label={dictionary.venueAdmin.profile.mfa.dialogs.call.codeLabel}
          type="number"
          error={isError}
          value={code}
          helperText={
            isError &&
            dictionary.venueAdmin.profile.mfa.dialogs.call.invalidCode
          }
          onChange={(event) => {
            setCode(event.target.value);
          }}
        />
      </form>
    </div>
  );
};

export default CodeStep;
