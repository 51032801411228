export enum AtLocationStatus {
  UNKNOWN = "UNKNOWN",
  YES = "YES",
  NO = "NO",
}
export enum ScreenView {
  VIEW_VENUE = "VIEW_VENUE",
  VIEW_PROMOTION = "VIEW_PROMOTION",
}
export enum UserType {
  USER = "USER",
  CRITIC = "CRITIC",
}
