import { Venue } from "../../../common/models";
import {
  GET_VENUES_FULFILLED,
  GET_VENUES_PENDING,
  GET_VENUES_REJECTED,
  VenuesActionTypes,
} from "./types";

interface VenuesState {
  list: Venue[];
  venuesCount: number;
  current?: Venue;
  error?: string;
  loading: boolean;
}

const defaultState: VenuesState = {
  list: [],
  venuesCount: 0,
  current: undefined,
  error: undefined,
  loading: false,
};

export default (
  state: VenuesState = defaultState,
  action: VenuesActionTypes
): VenuesState => {
  switch (action.type) {
    case GET_VENUES_PENDING:
      return {
        ...state,
        loading: true,
      };
    case GET_VENUES_FULFILLED:
      return {
        ...state,
        list: action.venues,
        loading: false,
        venuesCount: action.venuesCount,
      };
    case GET_VENUES_REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
