import { toSnapshot } from "dinero.js";

import { PromotionLimitType, PromotionType } from "../../../enums";
import { PromotionKind } from "../components/PromotionFormDialogActions/PromotionKind.enum";
import { toDineroObjFormatSimple } from "../components/PromotionFormDialogContent/utils";
import { ICreatePromotionFormRequest } from "./ICreatePromotionFormRequest";
import { IDayHours } from "./IDayHours";

export default class CreatePromotionFormRequest
  implements ICreatePromotionFormRequest
{
  public name = "";
  public description = "";
  public restrictions = "";
  public start = "";
  public end = "";
  public promotionPhotosIds: number[] = [];
  public promotionVideosIds: number[] = [];
  public promotionCategoryId = "";
  public promotionSubCategoryId = "";
  public otherSubcategoryName = "";
  public type = PromotionType.FOOD;
  public daysHours: IDayHours[] = [];
  public limitType = PromotionLimitType.SINGLE;
  /** @deprecated limitPercent should not be used */
  public limitPercent = 0;
  /** @deprecated limitMaxAmount should not be used */
  public limitMaxAmount = 0;
  public limitPrice = toSnapshot(toDineroObjFormatSimple(0));
  public rebatedPrice = toSnapshot(toDineroObjFormatSimple(0));
  public limitItemsName = "";
  public quantity = 0;
  public notifyHowManyLeft = false;
  public notifyHowManyLeftCount = 0;
  public maximumUsagesPerCustomer = 0;
  public maximumUsagesPerDay = 0;
  public restrictMinimumAge = "0";
  public promotionCode = "";
  public isQuantityUnlimited = true;
  public isPlanetPromo = true;
  public ageGroupId = "";
  public mainPhotoId = 0;
  public dietaryRestrictions = "";
  public kind = PromotionKind.DEAL;

  set photosIds(ids: number[]) {
    this.promotionPhotosIds = ids;
  }

  set videosIds(ids: number[]) {
    this.promotionVideosIds = ids;
  }
}
