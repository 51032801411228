export const GET_VENUE_VIEWS_BY_DEVICE = "reporting/views-by-device-os";

export default interface ViewsDeviceData {
  month: string;
  iphone: number;
  iphonePercent: number;
  android: number;
  androidPercent: number;
}

export interface GetVenueViewsByDeviceResponse {
  deviceStats: ViewsDeviceData[];
}
