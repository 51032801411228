import clsx from "clsx";
import React, { FC } from "react";

import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { AtLocationStatus } from "../../../../models/enums";
import Props from "./Props";
import { useStyles } from "./styles";

const AtLocationStatusLabel: FC<Props> = (props: Props) => {
  const { status } = props;
  const classes = useStyles();

  if (status === AtLocationStatus.YES) {
    return (
      <span className={clsx(classes.root, classes.green)}>
        {dictionary.reportingLiveStats.yes}
      </span>
    );
  }

  if (status === AtLocationStatus.NO) {
    return (
      <span className={clsx(classes.root, classes.red)}>
        {dictionary.reportingLiveStats.no}
      </span>
    );
  }
  return (
    <span className={clsx(classes.root, classes.default)}>
      {dictionary.reportingLiveStats.unknown}
    </span>
  );
};
export default AtLocationStatusLabel;
