import { toDecimal } from "dinero.js";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { PromotionFormDialogContext } from "../../../../../PromotionFormDialogContextProvider";
import { userWillPayCalculationFromPercentage } from "../../../utils";

function removeNonNumeric(input: string): string {
  return input.replace(/[^\d.]/g, "");
}

function leaveOnly2DecimalNumbersAferPoint(input: string): string {
  const [intPart, decimalPart] = input.split(".");
  if (decimalPart) {
    return `${intPart}.${decimalPart.slice(0, 2)}`;
  }
  return input;
}

export const usePriceUserWillPay = () => {
  const [priceUserWillPay, setPriceUserWillPay] = useState("0");
  const [isEditing, setIsEditing] = useState(false);
  const { mainForm } = useContext(PromotionFormDialogContext);
  const { setAndValidate } = mainForm;

  useEffect(() => {
    if (
      mainForm.form.values.limitPrice === "0" &&
      mainForm.form.values.limitPercent === "0"
    ) {
      return;
    }
    if (
      mainForm.form.values.limitPrice === "0" ||
      mainForm.form.values.limitPrice === ""
    ) {
      setPriceUserWillPay("0");
      return;
    }
    if (
      mainForm.form.values.limitPercent === "0" ||
      mainForm.form.values.limitPercent === ""
    ) {
      setPriceUserWillPay(mainForm.form.values.limitPrice);
      setAndValidate("rebatedPrice", mainForm.form.values.limitPrice);
      return;
    }

    if (isEditing) {
      return;
    }

    const newPrice = userWillPayCalculationFromPercentage(
      mainForm.form.values.limitPrice,
      Number(mainForm.form.values.limitPercent)
    );
    const formattedPrice = toDecimal(newPrice);

    if (priceUserWillPay === formattedPrice) {
      return;
    }

    setPriceUserWillPay(formattedPrice);
    setAndValidate("rebatedPrice", formattedPrice);
  }, [
    mainForm.form.values.limitPrice,
    mainForm.form.values.limitPercent,
    priceUserWillPay,
    isEditing,
    setAndValidate,
  ]);

  const onChangePriceUserWillPay = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = removeNonNumeric(event.target.value);
      value = leaveOnly2DecimalNumbersAferPoint(value);
      setPriceUserWillPay(value);
      if (
        mainForm.form.values.limitPrice === "0" &&
        mainForm.form.values.limitPercent === "0"
      ) {
        return;
      }
      if (value === "") {
        mainForm.setFormValue("limitPercent", "100");
        return;
      }
      if (mainForm.form.values.limitPrice === "0") {
        mainForm.setFormValue("limitPercent", "0");
        return;
      }

      const limitPercent = String(
        Number(
          100 -
            (Number(value) * 100) / parseFloat(mainForm.form.values.limitPrice)
        ) || "0"
      );
      mainForm.setAndValidate("limitPercent", limitPercent);
    },
    [mainForm]
  );

  const onFocusPriceUserWillPay = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onBlurPriceUserWillPay = useCallback(() => {
    setIsEditing(false);
    if (priceUserWillPay === "") {
      setPriceUserWillPay("0");
      return;
    }
  }, [priceUserWillPay]);

  return {
    priceUserWillPay,
    onChangePriceUserWillPay,
    onFocusPriceUserWillPay,
    onBlurPriceUserWillPay,
  };
};
