import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AlertContext } from "../../../../../../../../common/components/Alert/AlertContextProvider";
// eslint-disable-next-line max-len
import ButtonCancel from "../../../../../../../../common/components/ButtonCancel";
// eslint-disable-next-line max-len
import ButtonSuccess from "../../../../../../../../common/components/ButtonSuccess";
// eslint-disable-next-line max-len
import OcietyDialogTitle from "../../../../../../../../common/components/dialogs/OcietyDIalogTitle";
import { usePrevious } from "../../../../../../../../common/hooks";
import { useRestApi } from "../../../../../../../../common/hooks/useRestApi";
import { WorkingDay } from "../../../../../../../../common/models";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../../../../../store/rootReducer";
import { UPDATE_WORK_DAYS } from "../../../../../../api";
// eslint-disable-next-line max-len
import { WorkingDaysForm } from "../../../../form/WorkingDaysForm";
import WorkingHours from "../../index";
import { useStyles } from "../../styles";
import Props from "./Props";

const WorkingHoursDialog: FC<Props> = (props: Props) => {
  const { open, onClose } = props;
  const [workingHoursDialogDone, setWorkingHoursDialogDone] = useState(false);
  const [currentForm, setCurrentForm] = useState<WorkingDaysForm | null>(null);
  const { forms } = useSelector((state: AppState) => state);
  const form = forms.workingDays;
  const prevFormValues = usePrevious(form.values);
  const classes = useStyles();
  const { venue } = useSelector((state: AppState) => state.venue);
  const { showAlert } = useContext(AlertContext);

  const [_, updateWorkDays] = useRestApi<
    any,
    { id: number; workingDays: WorkingDay[] }
  >(UPDATE_WORK_DAYS, "PUT");

  const cancelWorkingHoursDialog = () => {
    setWorkingHoursDialogDone(false);
    onClose();
  };

  const doneWorkingHoursDialog = () => {
    setWorkingHoursDialogDone(true);
    onClose();
  };

  const validateWorkdays = () => Boolean(form.values.workingDays?.length);

  const updateWorkingDays = (workDays?: WorkingDay[]) => {
    if (!validateWorkdays()) {
      return showAlert(
        dictionary.venues.venueForm.workdaysValidationError,
        "warning"
      );
    }

    if (venue?.id && workDays) {
      return updateWorkDays({ workingDays: workDays, id: venue?.id })
        .then(() => {
          showAlert(
            dictionary.venues.venueForm.workDaysSavedSuccess,
            "success"
          );
        })
        .catch(() => {
          showAlert(dictionary.venues.venueForm.saveError, "error");
        });
    }
  };

  /**
   * Update working days field data when dialog was closed.
   * It is fixed dialog content animation bug.
   * But exist little validation message showing/hiding
   * delay in this case, because dialog has transition effect
   */
  const handleWorkingDaysDialogExit = () => {
    if (workingHoursDialogDone) {
      updateWorkingDays(form.values.workingDays);
      setCurrentForm(form);
    }
  };

  /**
   * Save prev form before making changes
   */
  useEffect(() => {
    if (
      (!prevFormValues || !prevFormValues.workingDays) &&
      form.values.workingDays
    ) {
      setCurrentForm(form);
    }
  }, [prevFormValues, form]);

  return (
    <Dialog
      open={open}
      onExited={handleWorkingDaysDialogExit}
      aria-labelledby="form-dialog-title">
      <OcietyDialogTitle
        id="working_hours_dialog_title"
        onClose={cancelWorkingHoursDialog}>
        {dictionary.venues.venueForm.workingHoursDialogTitle}
      </OcietyDialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <WorkingHours />
      </DialogContent>
      <DialogActions>
        <ButtonCancel onClick={cancelWorkingHoursDialog}>
          {dictionary.venues.venueForm.workingHoursCancelDialog}
        </ButtonCancel>
        <ButtonSuccess onClick={doneWorkingHoursDialog}>
          {dictionary.venues.venueForm.workingHoursSaveDialog}
        </ButtonSuccess>
      </DialogActions>
    </Dialog>
  );
};

export default WorkingHoursDialog;
