import React, { FC, ReactElement, useCallback } from "react";

import ImgMediaCard from "../../../../common/components/ImgMediaCard";
import { ReactComponent as African } from "../../../../common/svg/dealsDefaultIcon/African.svg";
import { ReactComponent as Alcoholic } from "../../../../common/svg/dealsDefaultIcon/Alcohol.svg";
import { ReactComponent as Asian } from "../../../../common/svg/dealsDefaultIcon/Asian.svg";
import { ReactComponent as Bakery } from "../../../../common/svg/dealsDefaultIcon/Bakery.svg";
import { ReactComponent as BBQ } from "../../../../common/svg/dealsDefaultIcon/BBQ.svg";
import { ReactComponent as BiscuitsCoffee } from "../../../../common/svg/dealsDefaultIcon/BiscuitsCoffee.svg";
import { ReactComponent as Breakfast } from "../../../../common/svg/dealsDefaultIcon/Breakfast.svg";
import { ReactComponent as Burgers } from "../../../../common/svg/dealsDefaultIcon/Burger.svg";
import { ReactComponent as Caribbean } from "../../../../common/svg/dealsDefaultIcon/Caribbean.svg";
import { ReactComponent as Chinese } from "../../../../common/svg/dealsDefaultIcon/Chinese.svg";
import { ReactComponent as CajunCreole } from "../../../../common/svg/dealsDefaultIcon/Creole.svg";
import { ReactComponent as Dessert } from "../../../../common/svg/dealsDefaultIcon/Dessert.svg";
import { ReactComponent as EasternEuropean } from "../../../../common/svg/dealsDefaultIcon/EasternEuropean.svg";
import { ReactComponent as European } from "../../../../common/svg/dealsDefaultIcon/European.svg";
import { ReactComponent as FastCasual } from "../../../../common/svg/dealsDefaultIcon/FastCasual.svg";
import { ReactComponent as French } from "../../../../common/svg/dealsDefaultIcon/French.svg";
import { ReactComponent as Indian } from "../../../../common/svg/dealsDefaultIcon/Indian.svg";
import { ReactComponent as Italian } from "../../../../common/svg/dealsDefaultIcon/Italian.svg";
import { ReactComponent as Japanese } from "../../../../common/svg/dealsDefaultIcon/Japanese.svg";
import { ReactComponent as Korean } from "../../../../common/svg/dealsDefaultIcon/Korean.svg";
import { ReactComponent as Mediterranean } from "../../../../common/svg/dealsDefaultIcon/Mediterranean.svg";
import { ReactComponent as Mexican } from "../../../../common/svg/dealsDefaultIcon/Mexican.svg";
import { ReactComponent as MiddleEastern } from "../../../../common/svg/dealsDefaultIcon/MiddleEastern.svg";
import { ReactComponent as NewAmerican } from "../../../../common/svg/dealsDefaultIcon/NewAmerican.svg";
import { ReactComponent as NonAlcoholic } from "../../../../common/svg/dealsDefaultIcon/NoAlcohol.svg";
import { ReactComponent as Other } from "../../../../common/svg/dealsDefaultIcon/Other.svg";
import { ReactComponent as Pakistani } from "../../../../common/svg/dealsDefaultIcon/Pakistani.svg";
import { ReactComponent as Pizza } from "../../../../common/svg/dealsDefaultIcon/Pizza.svg";
import { ReactComponent as Salad } from "../../../../common/svg/dealsDefaultIcon/Salad.svg";
import { ReactComponent as Sandwich } from "../../../../common/svg/dealsDefaultIcon/Sandwich.svg";
import { ReactComponent as Seafood } from "../../../../common/svg/dealsDefaultIcon/Seafood.svg";
import { ReactComponent as Snacks } from "../../../../common/svg/dealsDefaultIcon/Snacks.svg";
import { ReactComponent as Soup } from "../../../../common/svg/dealsDefaultIcon/Soup.svg";
import { ReactComponent as LatinSouthAmerica } from "../../../../common/svg/dealsDefaultIcon/SouthAmerica.svg";
import { ReactComponent as Spanish } from "../../../../common/svg/dealsDefaultIcon/Spanish.svg";
import { ReactComponent as Steakhouse } from "../../../../common/svg/dealsDefaultIcon/Steakhouse.svg";
import { ReactComponent as Thai } from "../../../../common/svg/dealsDefaultIcon/Thai.svg";
import { ReactComponent as Turkish } from "../../../../common/svg/dealsDefaultIcon/Turkish.svg";
import { ReactComponent as Voucher } from "../../../../common/svg/dealsDefaultIcon/Voucher.svg";
import { PromotionKind } from "../../AdminVenue/dialogs/PromotionFormDialog/components/PromotionFormDialogActions/PromotionKind.enum";
import Props from "./Props";

export const PROMOTION_IMAGES = {
  African: <African />,
  Alcoholic: <Alcoholic />,
  Asian: <Asian />,
  Bakery: <Bakery />,
  BBQ: <BBQ />,
  "Biscuits + Coffee": <BiscuitsCoffee />,
  Breakfast: <Breakfast />,
  Burgers: <Burgers />,
  "Cajun/Creole": <CajunCreole />,
  Caribbean: <Caribbean />,
  Chinese: <Chinese />,
  Dessert: <Dessert />,
  "Eastern European": <EasternEuropean />,
  European: <European />,
  French: <French />,
  "Fast Casual": <FastCasual />,
  Indian: <Indian />,
  Italian: <Italian />,
  Japanese: <Japanese />,
  Korean: <Korean />,
  "Latin/South America": <LatinSouthAmerica />,
  Mediterranean: <Mediterranean />,
  Mexican: <Mexican />,
  "Middle Eastern": <MiddleEastern />,
  "Non-Alcoholic": <NonAlcoholic />,
  Other: <Other />,
  Pakistani: <Pakistani />,
  Pizza: <Pizza />,
  Salad: <Salad />,
  Sandwich: <Sandwich />,
  Seafood: <Seafood />,
  Snacks: <Snacks />,
  Soup: <Soup />,
  Spanish: <Spanish />,
  Thai: <Thai />,
  "New American": <NewAmerican />,
  "Steakhouse/Grill": <Steakhouse />,
  Turkish: <Turkish />,
  Voucher: <Voucher />,
};

const PromotionImage: FC<Props> = (props: Props) => {
  const {
    image,
    kind,
    categoryName,
    width = 75,
    height = 75,
    promoImages = PROMOTION_IMAGES,
  } = props;

  const PromotionDefaultIcon = useCallback(() => {
    const defaultCategories = Object.keys(promoImages);
    let promotionImage: ReactElement = promoImages["Other"];

    if (kind === PromotionKind.VOUCHER) promotionImage = promoImages["Voucher"];
    else if (categoryName && defaultCategories.includes(categoryName)) {
      promotionImage = promoImages[categoryName];
    }

    promotionImage = React.cloneElement(promotionImage, {
      width,
      height,
    });

    return promotionImage;
  }, [promoImages, kind, categoryName, width, height]);

  return (
    <>
      {image ? (
        <ImgMediaCard height={height} width={width} image={image} />
      ) : (
        <PromotionDefaultIcon />
      )}
    </>
  );
};
export default PromotionImage;
