import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { FC, useContext, useState } from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { requestCodeSms, sendCodeSms } from "./api";
import CodeStep from "./components/CodeStep";
import PhoneNumberStep from "./components/PhonNumberStep";
import SuccessStep from "./components/SuccessStep";
import Props from "./Props";
import { Steps } from "./Steps";
import { useStyles } from "./styles";

const AddSmsMethodDialog: FC<Props> = (props: Props) => {
  const { isOpen, setOpen, onSuccessEnabled } = props;
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const [step, setStep] = useState(Steps.PHONE_STEP);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [code, setCode] = useState("");

  const handleBtnClick = async () => {
    if (step === Steps.PHONE_STEP) {
      await handleRequestCode();
    }

    if (step === Steps.CODE_STEP) {
      await handleSMSMethodSave();
    }
  };

  const handleClose = () => {
    setOpen(false);

    if (step === Steps.SUCCESS_STEP && onSuccessEnabled) {
      onSuccessEnabled();
    }
  };

  const codeIsValid = (code: string): boolean => {
    if (code) {
      const match = code.match(/\d/g);
      if (!match) {
        return false;
      }
      return match.length === 6;
    }

    return false;
  };

  const handleSMSMethodSave = async () => {
    if (!phoneNumber) {
      return;
    }

    if (!codeIsValid(code)) {
      return;
    }

    try {
      setLoading(true);

      const result = await sendCodeSms(phoneNumber, code);

      if (!result) {
        showAlert("Some error", "error");
      }

      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.sms
          .successSaveMFAMethodMessage,
        "success"
      );

      setStep(Steps.SUCCESS_STEP);
    } catch (e) {
      if (!e) {
        return;
      }

      handleClose();
      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.sms.errorSaveMFAMethodMessage,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRequestCode = async () => {
    if (!phoneNumber) {
      return;
    }

    try {
      setLoading(true);

      const result = await requestCodeSms(phoneNumber);

      if (!result) {
        showAlert("Some error", "error");
      }

      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.sms.successSendCodeMessage,
        "success"
      );
      setStep(Steps.CODE_STEP);
    } catch (e) {
      if (!e) {
        return;
      }

      handleClose();
      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.sms.errorSendCodeMessage,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const isAllowedContinueBtn = () => {
    if (step === Steps.PHONE_STEP) {
      if (phoneNumber) {
        return true;
      }
    }

    if (step === Steps.CODE_STEP) {
      if (phoneNumber && code) {
        return true;
      }
    }

    return false;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="alert-dialog-title">
        {dictionary.venueAdmin.profile.mfa.dialogs.sms.title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {step === Steps.PHONE_STEP && (
          <PhoneNumberStep onSetPhoneNumber={setPhoneNumber} />
        )}
        {step === Steps.CODE_STEP && (
          <CodeStep error={!codeIsValid(code)} onChangeCode={setCode} />
        )}
        {step === Steps.SUCCESS_STEP && <SuccessStep />}
        {loading && <LoadingIndicator withMask />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={() => handleClose()}>
          {dictionary.venueAdmin.profile.mfa.dialogs.sms.closeButton}
        </Button>
        {step !== Steps.SUCCESS_STEP && (
          <Button
            color="primary"
            variant="outlined"
            disabled={!isAllowedContinueBtn()}
            onClick={handleBtnClick}>
            {dictionary.venueAdmin.profile.mfa.dialogs.sms.continueButton}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default AddSmsMethodDialog;
