import { formatUserTypeToString } from "../utils/userUtils";
import { MobileAppUserType } from "./models";

export const priceDisplayValues = [
  { display: "$", value: 1 },
  { display: "$$", value: 2 },
  { display: "$$$", value: 3 },
  { display: "$$$$", value: 4 },
];

export const userTypeDisplayValues = [
  {
    display: formatUserTypeToString(MobileAppUserType.USER),
    value: MobileAppUserType.USER,
  },
  {
    display: formatUserTypeToString(MobileAppUserType.CRITIC),
    value: MobileAppUserType.CRITIC,
  },
];

export const ratingDisplayValues = [
  {
    display: "★",
    value: 1,
  },
  {
    display: "★★",
    value: 2,
  },
  {
    display: "★★★",
    value: 3,
  },
  {
    display: "★★★★",
    value: 4,
  },
  {
    display: "★★★★★",
    value: 5,
  },
];
