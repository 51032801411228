import { Form } from "../../../../../../common/models";
import { ValidationRules } from "../../../../../../utils/validation";

export type PromotionAdvancedFormField =
  | "quantity"
  | "notifyHowManyLeft"
  | "notifyHowManyLeftCount"
  | "maximumUsagesPerCustomer"
  | "restrictMinimumAge"
  | "promotionCode"
  | "isQuantityUnlimited"
  | "ageGroupId"
  | "maximumUsagesPerDay";

export type PromotionAdvancedFormValues = {
  [key in PromotionAdvancedFormField]: string;
};
export type PromotionAdvancedFormErrors = {
  [key in PromotionAdvancedFormField]: string;
};

export interface IPromotionAdvancedForm extends Form {
  values: PromotionAdvancedFormValues;
  errors: PromotionAdvancedFormErrors;
  touched: PromotionAdvancedFormField[];
}

export const PromotionAdvancedForm: IPromotionAdvancedForm = {
  values: {
    quantity: "1",
    notifyHowManyLeft: "0",
    notifyHowManyLeftCount: "0",
    maximumUsagesPerCustomer: "1",
    maximumUsagesPerDay: "",
    restrictMinimumAge: "0",
    promotionCode: "",
    isQuantityUnlimited: "0",
    ageGroupId: "",
  },
  errors: {
    quantity: "",
    notifyHowManyLeft: "",
    notifyHowManyLeftCount: "",
    maximumUsagesPerCustomer: "",
    maximumUsagesPerDay: "",
    restrictMinimumAge: "",
    promotionCode: "",
    isQuantityUnlimited: "",
    ageGroupId: "",
  },
  touched: [],
  isValid: true,
};

export type PromotionAdvancedFormSchemaType = Record<
  PromotionAdvancedFormField,
  ValidationRules
>;
export const PromotionAdvancedFormSchema: PromotionAdvancedFormSchemaType = {
  quantity: {
    required: true,
    type: "number+",
  },
  notifyHowManyLeft: {
    required: true,
    type: "number",
  },
  notifyHowManyLeftCount: {},
  maximumUsagesPerCustomer: {
    required: true,
    type: "number+",
  },
  maximumUsagesPerDay: {
    type: "number",
  },
  restrictMinimumAge: {},
  ageGroupId: {},
  promotionCode: {},
  isQuantityUnlimited: {
    required: true,
    type: "number",
  },
};
