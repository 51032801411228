import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const ActiveUsersOnline: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { count } = props;
  let activeUsers = dictionary.reportingLiveStats.activeUsers;
  if (count === 1) {
    activeUsers = dictionary.reportingLiveStats.activeUser;
  }
  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.activeLabel}>
        <span className={classes.count}>{count ? count : 0}</span> {activeUsers}
      </Typography>
    </div>
  );
};
export default ActiveUsersOnline;
