export const CART_ITEM_ADD = "CART_ITEM_ADD";
export const CART_ITEM_REMOVE = "CART_ITEM_REMOVE";
export const CART_CLEAR = "CART_CLEAR";

export interface CartItemAddActionType {
  type: typeof CART_ITEM_ADD;
  venueId: number;
  packageId: number;
}

export interface CartItemRemoveActionType {
  type: typeof CART_ITEM_REMOVE;
  venueId: number;
  packageId: number;
}

export interface CartClearActionType {
  type: typeof CART_CLEAR;
}

export type CartActionTypes =
  | CartItemAddActionType
  | CartItemRemoveActionType
  | CartClearActionType;
