import { Button, DialogContent, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import React, { FC, useContext, useState } from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import ButtonSuccess from "../../../../../../common/components/ButtonSuccess";
import OcietyDialogTitle from "../../../../../../common/components/dialogs/OcietyDIalogTitle";
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import StepsIndicator from "../../../../../../common/components/StepsIndicator";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { isEmailValid } from "../../../../../../utils/emailUtils";
import { checkCodeIsValid } from "../../../../../../utils/phoneNumberUtils";
import Props from "../CallMethodDialog/Props";
import { useStyles } from "../CallMethodDialog/styles";
import { requestCodeEmail, sendEmailCode } from "./api";
import CodeStep from "./components/CodeStep";
import EmailStep from "./components/EmailStep";
import { Steps } from "./Steps";

const EmailMethodDialog: FC<Props> = (props: Props) => {
  const { isOpen, setOpen, onSuccessEnabled } = props;
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);

  const handleClose = () => {
    setOpen(false);
  };

  const handleBack = () => {
    setStep(Steps.EMAIL_STEP);
  };

  const [step, setStep] = useState(Steps.EMAIL_STEP);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [emailSecurityCode, setEmailSecurityCode] = useState("");
  const [emailSecurityCodeError, setEmailSecurityCodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const emailValid = isEmailValid(email);

  const handleRequestCode = async () => {
    if (!email) {
      return;
    }

    try {
      setLoading(true);

      const result = await requestCodeEmail(email);

      if (!result) {
        showAlert(dictionary.profile.mfa.incorrectResponse, "error");
      }

      showAlert(dictionary.profile.mfa.successSendCodeMessage, "success");
      setStep(Steps.CODE_STEP);
    } catch (e) {
      if (!e) {
        return;
      }
      handleClose();
      showAlert(e.data, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCallMethodSave = async () => {
    if (!email) {
      return;
    }

    if (!checkCodeIsValid(emailSecurityCode)) {
      setEmailSecurityCodeError(dictionary.profile.mfa.codeError);
      return;
    }

    try {
      setLoading(true);

      const result = await sendEmailCode(email, emailSecurityCode);

      if (!result) {
        showAlert(dictionary.profile.mfa.incorrectResponse, "error");
      }

      showAlert(dictionary.profile.mfa.successEmailMFAMethodEnabled, "success");

      if (onSuccessEnabled) {
        onSuccessEnabled();
        setStep(Steps.EMAIL_STEP);
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(e.data, "error");
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const handleBtnClick = async () => {
    if (step === Steps.EMAIL_STEP) {
      if (emailValid) {
        await handleRequestCode();
        setEmailError("");
      } else {
        setEmailError(dictionary.profile.mfa.invalidEmailErrorMessage);
        showAlert(dictionary.profile.mfa.invalidEmailErrorMessage, "error");
      }
    }
    if (step === Steps.CODE_STEP) {
      await handleCallMethodSave();
    }
  };
  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title">
      <OcietyDialogTitle id="mfa-call-dialog-title" onClose={handleClose}>
        {dictionary.profile.mfa.verifyEmail}
      </OcietyDialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center">
          <Grid item xs={12}>
            {loading && <LoadingIndicator withMask />}
            <StepsIndicator
              stepsNames={[
                dictionary.profile.mfa.emailStep,
                dictionary.profile.mfa.confirmStep,
              ]}
              currentStep={step === Steps.CODE_STEP ? 2 : 1}
            />
            {step === Steps.EMAIL_STEP && (
              <EmailStep
                onSetEmail={setEmail}
                emailError={emailError}
                onSetEmailError={setEmailError}
                email={email}
                isEmailValid={emailValid}
              />
            )}
            {step === Steps.CODE_STEP && (
              <CodeStep
                onSetCode={setEmailSecurityCode}
                email={email}
                resendCode={handleRequestCode}
                error={emailSecurityCodeError}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {step === Steps.EMAIL_STEP && (
          <>
            <Button
              variant="outlined"
              className={classes.btn}
              onClick={handleClose}>
              {dictionary.profile.mfa.cancelBtn}
            </Button>
            <ButtonSuccess onClick={handleBtnClick}>
              {dictionary.profile.mfa.continuBtn}
            </ButtonSuccess>
          </>
        )}
        {step === Steps.CODE_STEP && (
          <>
            <Button
              variant="outlined"
              className={classes.btn}
              onClick={handleBack}>
              {dictionary.profile.mfa.backBtn}
            </Button>
            <ButtonSuccess onClick={handleBtnClick}>
              {dictionary.profile.mfa.submitBtn}
            </ButtonSuccess>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default EmailMethodDialog;
