export enum VenueClaimRequestStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface VenueClaim {
  id: number;
  venueName: string;
  venueAddress: string;
  firstName: string;
  lastName: string;
  date: Date;
  venueId: number;
  status: VenueClaimRequestStatus;
  statusUpdatedAt: Date;
}

export const VENUE_CLAIMS_URL = "/venue/claims";
