import React, { FC, useEffect } from "react";

import { useRestApi } from "../../../../common/hooks/useRestApi";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { GET_VENUE_PROMOTIONS_USED_TOTAL, ScoreTotal } from "../../models";
import DashboardWidgetSmall from "../DashboardWidgetSmall";
import Props from "./Props";

const TotalPromotionsUsed: FC<Props> = (props) => {
  const { venueId, period } = props;
  const [{ data, loading }, setAsyncState] = useRestApi<ScoreTotal>(
    GET_VENUE_PROMOTIONS_USED_TOTAL(venueId, period),
    "GET"
  );

  useEffect(() => {
    if (!!venueId) {
      setAsyncState().catch((e) => console.error(e));
    }
  }, [setAsyncState, venueId]);

  return (
    <DashboardWidgetSmall
      loading={loading}
      icon={"widgetPromotionsUsed"}
      description={dictionary.dashboard.widgetDealsUsedDescription}
      link={Route.Deals}
      score={data ? data.score : 0}
    />
  );
};

export default TotalPromotionsUsed;
