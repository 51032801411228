import { ChartDataItem } from "./models";

export const chartDataMock: ChartDataItem[] = [
  {
    month: "Jan",
    food: 1200,
    drinks: 668,
    bill: 888,
  },
  {
    month: "Feb",
    food: 1200,
    drinks: 668,
    bill: 888,
  },
  {
    month: "Mar",
    food: 777,
    drinks: 668,
    bill: 888,
  },
  {
    month: "Apr",
    food: 999,
    drinks: 500,
    bill: 888,
  },
  {
    month: "May",
    food: 567,
    drinks: 900,
    bill: 390,
  },
  {
    month: "Jun",
    food: 1111,
    drinks: 668,
    bill: 4444,
  },
];
