import { Form } from "../../../../store/forms/types";

export interface AddNewVenueForm extends Form {
  errors: {
    name: string;
    address: string;
    city: string;
    state: string;
    country: string;
    price: string;
    postcode: string;
    subtypes: string;
    longitude: string;
    latitude: string;
    website: string;
    email: string;
    phone: string;
  };
  values: {
    name: string;
    address: string;
    city: string;
    state: string;
    country: string;
    price: number;
    postcode: string;
    subtypes: number[];
    longitude: string;
    latitude: string;
    website?: string;
    email?: string;
    phone?: string;
  };
}

export interface AddNewVenueFormSchema {
  name: {
    presence: { allowEmpty: boolean; message: string };
    length: {
      maximum: number;
      minimum: number;
    };
  };
  address: { presence: { allowEmpty: boolean; message: string } };
  city: { presence: { allowEmpty: boolean; message: string } };
  state: { presence: { allowEmpty: boolean; message: string } };
  country: any;
  price: { presence: { allowEmpty: boolean; message: string } };
  postcode: {
    presence: { allowEmpty: boolean; message: string };
    length: {
      maximum: number;
    };
    format: { pattern: RegExp; message: string };
  };
  subtypes: { presence: { allowEmpty: boolean; message: string } };
  longitude: { presence: { allowEmpty: boolean; message: string } };
  latitude: { presence: { allowEmpty: boolean; message: string } };
  website: any;
  email: any;
  phone: any;
}

export const addNewVenueFormSchema: AddNewVenueFormSchema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 100,
      minimum: 2,
    },
  },
  address: { presence: { allowEmpty: false, message: "is required" } },
  city: { presence: { allowEmpty: false, message: "is required" } },
  state: { presence: { allowEmpty: false, message: "is required" } },
  country: {},
  price: {
    presence: { allowEmpty: false, message: "is required" },
  },
  postcode: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 10,
    },
    format: {
      pattern: new RegExp("(^\\d{5}$)|(^\\d{5}-\\d{4}$)"),
      message: "don't match pattern xxxxx-xxxx or xxxxx",
    },
  },
  subtypes: { presence: { allowEmpty: false, message: "is required" } },
  longitude: { presence: { allowEmpty: false, message: "is required" } },
  latitude: { presence: { allowEmpty: false, message: "is required" } },
  website: {},
  email: {},
  phone: {},
};

export const AddNewVenueForm: AddNewVenueForm = {
  errors: {
    name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    price: "",
    postcode: "",
    subtypes: "",
    longitude: "",
    latitude: "",
    website: "",
    email: "",
    phone: "",
  },
  values: {
    name: "",
    address: "",
    city: "",
    state: "",
    country: "USA",
    price: 1,
    postcode: "",
    subtypes: [],
    longitude: "",
    latitude: "",
    website: "",
    email: "",
    phone: "",
  },
  touched: [],
  isValid: true,
};
