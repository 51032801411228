import { Form } from "../../../../../common/models";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { ValidationRules } from "../../../../../utils/validation";

export type CreateManagerFormField =
  | "email"
  | "firstName"
  | "isWaitlistAdmin"
  | "isDiscountAdmin";
export type CreateManagerFormValues = {
  [key in CreateManagerFormField]: any;
};
export type CreateManagerFormErrors = {
  [key in CreateManagerFormField]: string;
};

export interface CreateManagerForm extends Form {
  values: CreateManagerFormValues;
  errors: CreateManagerFormErrors;
  isValid: boolean;
  touched: CreateManagerFormField[];
}

export type CreateManagerFormSchema = Record<
  CreateManagerFormField,
  ValidationRules
>;

export const createManagerForm: CreateManagerForm = {
  values: {
    email: "",
    firstName: "",
    isWaitlistAdmin: false,
    isDiscountAdmin: false,
  },
  errors: {
    email: "",
    firstName: "",
    isWaitlistAdmin: "",
    isDiscountAdmin: "",
  },
  touched: [],
  isValid: true,
};

export const createManagerFormSchema: CreateManagerFormSchema = {
  email: {
    type: "email",
    required: true,
    length: {
      max: 256,
    },
  },
  firstName: {
    required: true,
    length: {
      max: 256,
    },
    fieldName: dictionary.venueStaff.managers.table.name,
  },
  isWaitlistAdmin: {
    required: false,
  },
  isDiscountAdmin: {
    required: false,
  },
};
