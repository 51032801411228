import { Paper } from "@material-ui/core";
import React, { FC } from "react";

import PageTitle from "../../common/components/PageTitle";
import dictionary from "../../i18n/en_US/dictionary";
import VenuesChangesPendingTable from "./components/VenuesChangesPendingTable";
import { useStyles } from "./styles";

const VenuesChangesPending: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PageTitle>{dictionary.venuesChangesPending.pageTitle}</PageTitle>
      <Paper className={classes.content}>
        <div>
          <VenuesChangesPendingTable />
        </div>
      </Paper>
    </div>
  );
};

export default VenuesChangesPending;
