import React, { FC } from "react";

import CriticRequestsTable from "./components/CriticRequestsTable";

const CriticRequestsTab: FC = () => {
  return (
    <div>
      <CriticRequestsTable />
    </div>
  );
};

export default CriticRequestsTab;
