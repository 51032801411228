import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type VenuesClassNames = "ulTxt";

export const useLocalStyles: () => ClassNameMap<VenuesClassNames> = makeStyles(
  () =>
    createStyles({
      ulTxt: {
        paddingLeft: 20,

        "& li": {
          textDecoration: "none",
          "list-style-type": "square",
          "list-style-position": "inside",
        },
      },
    })
);
