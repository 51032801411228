import { FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import React, { FC } from "react";

import {
  discountStatusLabelItems,
  IDiscountStatusItem,
} from "../../../../../../../types/DiscountStatus";
import Props from "./Props";
import { useSelectStyles, useStyles } from "./styles";

const PromotionStatusSelect: FC<Props> = (props: Props) => {
  const { status, setStatus } = props;
  const classes = useStyles();
  const selectClasses = useSelectStyles();
  const handleChangeCategoryFilter = (e: any) => {
    setStatus(e.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.filterSelect, "select-no-legend")}>
      <Select
        classes={selectClasses}
        id="promotions-status-labels-select"
        value={status}
        label={"Status"}
        onChange={handleChangeCategoryFilter}>
        <MenuItem value="" disabled>
          <em>{"Status"}</em>
        </MenuItem>
        <MenuItem key={0} value={0}>
          All
        </MenuItem>
        {discountStatusLabelItems.map((label: IDiscountStatusItem) => (
          <MenuItem key={label.id} value={label.id}>
            {label.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default PromotionStatusSelect;
