import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "icon"
  | "select"
  | "selectWrapper"
  | "selectIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      icon: {},
      selectWrapper: {
        boxSizing: "border-box",
        backgroundColor: "transparent",
        border: "1px solid rgba(121, 193, 119, 0.3)",
        borderRadius: 3,
        fontWeight: 700,
        fontSize: 15,
        color: "#79C177",
        minWidth: 148,
      },
      select: {
        padding: "9px 36px 9px 12px !important",
      },
      selectIcon: {
        top: 15.75,
        right: 13.5,
        position: "absolute",
      },
    })
);
