import React, { FC, useEffect } from "react";

import { useRestApi } from "../../../../common/hooks/useRestApi";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import DashboardWidgetSmall from "../../../Dashboard/components/DashboardWidgetSmall";
import { ScoreTotal } from "../../../Dashboard/models";
import { GET_USERS_TOTAL } from "./api";

const TotalUsers: FC = () => {
  const [{ data, loading }, getUsersQty] = useRestApi<ScoreTotal>(
    GET_USERS_TOTAL,
    "GET"
  );

  useEffect(() => {
    getUsersQty().catch((e) => console.error(e));
  }, [getUsersQty]);

  return (
    <DashboardWidgetSmall
      orientation={"vertical"}
      loading={loading}
      icon={"users"}
      description={dictionary.dashboard.totalUsers}
      link={Route.Users}
      score={data?.score || 0}
    />
  );
};

export default TotalUsers;
