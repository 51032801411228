import { InputAdornment, TextField } from "@material-ui/core";
import React, { ChangeEvent, FC } from "react";

import OcietyIcon from "../../../../../../common/components/OcietyIcon";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const SearchUser: FC<Props> = (props: Props) => {
  const { value, onChange } = props;
  const classes = useStyles();
  return (
    <TextField
      required
      fullWidth
      id="filled-phone-number"
      name="currentPass"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <OcietyIcon
              name={"greySearchButton"}
              width={16}
              height={16}
              className={classes.icon}
              viewBox="0 0 16 16"
            />
          </InputAdornment>
        ),
      }}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      }}
      placeholder={dictionary.reportingLiveStats.searchUser}
      value={value}
      type="text"
      variant="outlined"
    />
  );
};
export default SearchUser;
