import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../common/models";
import { colors } from "../../../../../theme/colors";

export type ClassNames =
  | "rowItem"
  | "gridContainer"
  | "text"
  | "rowItemText"
  | "rowItemName"
  | "rowItemCenter"
  | "manager"
  | "rowItemSmallWidth";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      gridContainer: {
        [theme.breakpoints.down("lg")]: {
          minWidth: 1200,
        },
      },
      rowItem: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 1),
      },
      text: {
        color: colors.dark.main,
      },
      rowItemText: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
      },
      rowItemName: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2, 1),
      },
      rowItemCenter: {
        display: "flex",
        justifyContent: "center",
      },
      manager: {
        marginBottom: 10,
        padding: 20,
      },
      rowItemSmallWidth: {
        maxWidth: "8%",
      },
    })
);
