import { useCallback, useState } from "react";

import { http } from "../../config/api";

type UseAsyncStateRequest = Record<string, any> | undefined;
type UseAsyncStateResponse = Record<string, any>;

interface UseAsyncState {
  loading: boolean;
  error?: string;
  response: UseAsyncStateResponse | null;
}

/**
 * Use 'useRestApi' hook instead (in the same directory)
 *
 * @deprecated
 *
 * @param apiPath
 */
export function useAsyncState<
  T extends UseAsyncStateResponse,
  R extends UseAsyncStateRequest = undefined
>(apiPath: string) {
  interface State extends UseAsyncState {
    response: T | null;
  }

  const cachedResponse = localStorage.getItem(apiPath);

  const [state, setState] = useState<State>({
    loading: false,
    response: cachedResponse ? JSON.parse(cachedResponse) : null,
    error: undefined,
  });

  const setAsyncState = useCallback(
    async (data?: R): Promise<T | undefined> => {
      setState((prevState) => ({ ...prevState, loading: true }));

      try {
        const response: T = data
          ? await http.post(apiPath, data)
          : await http.get(apiPath);

        const newState = {
          error: undefined,
          loading: false,
          response: response.data,
        };

        setState(newState);
        localStorage.setItem(apiPath, JSON.stringify(response.data));

        return newState.response;
      } catch (e) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          error: "Something went wrong...",
        }));
      }
    },
    [apiPath]
  );

  return [state, setAsyncState] as const;
}
