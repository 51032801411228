import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import theme from "../../../theme";
import { colors } from "../../../theme/colors";

export type ClassNames = "root" | "tooltip";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 50,
      minWidth: 50,
      height: 50,
      marginRight: -8, // to replase padding of the field when grid spacing equals 2 (padding 16px)
    },
    tooltip: {
      backgroundColor: colors.grey.primary,
      padding: "7px 12px",
      lineHeight: theme.typography.pxToRem(20),
      fontSize: 14,
      fontWeight: 600,
    },
  })
);
