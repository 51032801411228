import { http } from "../../../../config/api";

const UPLOAD_PROFILE_AVATAR = `profile/avatar`;
export const uploadAvatar = (avatar: any) => {
  const formData = new FormData();
  formData.append("avatar", avatar, avatar.name);

  return http.post(UPLOAD_PROFILE_AVATAR, formData);
};

export const deleteAvatar = async (): Promise<any> => {
  return http.delete(UPLOAD_PROFILE_AVATAR);
};
