export default class UrlUtils {
  /**
   *
   * @param query
   */
  public static buildQuery(query: { [key: string]: string | number }) {
    const result = [];

    for (const key in query) {
      if (query.hasOwnProperty(key)) {
        result.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(query[key])
        );
      }
    }

    return result.join("&");
  }
}
