import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../common/models";
import { colors, ColorsGray } from "../../../../../../../theme/colors";

export type ClassNames =
  | "line"
  | "paper"
  | "titleWrapper"
  | "title"
  | "content"
  | "updatedBy"
  | "userDetailsWrapper"
  | "envelopeIcon"
  | "noiseLineTextWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      paper: { maxWidth: "544px", width: "100%" },
      titleWrapper: {
        padding: `10px 10px 10px 32px`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `1px solid #e1e2e3`,
        [theme.breakpoints.down("sm")]: {
          padding: 20,
        },
      },
      title: {
        fontWeight: 600,
        fontSize: 20,
        color: ColorsGray._1F1F1F,
      },
      content: {
        padding: `24px 32px 32px`,
        maxWidth: 544,
        width: "100%",
      },
      line: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px 0",
      },
      updatedBy: {
        backgroundColor: colors.grey.bgUltraLight,
        textAlign: "center",
        padding: "8px 0",
        width: "100%",
      },
      userDetailsWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 24,
      },
      envelopeIcon: { marginRight: 8, width: 16, height: 16 },
      noiseLineTextWrapper: {},
    })
);
