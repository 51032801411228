import { AccordionClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const MuiAccordion: Partial<Record<AccordionClassKey, CSSProperties>> = {
  root: {
    maxWidth: 1044,
    marginBottom: 16,
    padding: "0 16px",
    "&:before": {
      display: "none",
    },
  },
};

export default MuiAccordion;
