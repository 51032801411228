import { BasicForm } from "../../../../common/models";
import { ValidationRules } from "../../../../utils/validation";

export type VenueAddRequestFormField =
  | "bar"
  | "restaurant"
  | "name"
  | "city"
  | "state"
  | "country"
  | "email"
  | "phoneNumber"
  | "address"
  | "postcode"
  | "latitude"
  | "longitude"
  | "website"
  | "comment";

export type VenueAddRequestForm = BasicForm<VenueAddRequestFormField>;

export type VenueAddRequestFormSchema = Record<
  VenueAddRequestFormField,
  ValidationRules
>;

export const venueAddRequestForm: BasicForm<VenueAddRequestFormField> = {
  values: {
    name: "",
    city: "",
    email: "",
    state: "",
    country: "",
    phoneNumber: "",
    address: "",
    postcode: "",
    bar: "",
    restaurant: "",
    longitude: "",
    latitude: "",
    comment: "",
    website: "",
  },
  errors: {
    name: "",
    city: "",
    email: "",
    state: "",
    country: "",
    phoneNumber: "",
    address: "",
    postcode: "",
    bar: "",
    restaurant: "",
    longitude: "",
    latitude: "",
    comment: "",
    website: "",
  },
  touched: [],
  isValid: true,
};

export const venueAddRequestFormSchema: VenueAddRequestFormSchema = {
  city: {
    required: true,
    length: {
      max: 256,
    },
  },
  name: {
    required: true,
    length: {
      max: 1000,
    },
  },
  email: {
    required: true,
    type: "email",
    length: {
      max: 256,
    },
  },
  state: {
    required: true,
    length: {
      max: 256,
    },
  },
  country: {
    required: true,
    length: {
      max: 256,
    },
  },
  phoneNumber: {
    required: true,
    type: "phone",
    length: {
      max: 256,
    },
  },
  address: {
    required: true,
    length: {
      max: 256,
    },
  },
  postcode: {
    required: true,
    type: "notLetters",
  },
  bar: {
    length: {
      max: 1000,
    },
  },
  restaurant: {
    length: {
      max: 1000,
    },
  },
  latitude: {
    required: true,
    type: "latitude",
  },
  longitude: {
    required: true,
    type: "longitude",
  },
  website: {
    required: false,
    length: {
      max: 1000,
    },
  },
  comment: {
    length: {
      max: 1000,
    },
  },
};
