import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "rootEmpty"
  | "rootRow"
  | "rowItem"
  | "rowItem5"
  | "rowItem8"
  | "rowItem10"
  | "rowItem19"
  | "rowItem20"
  | "gridContainer"
  | "gridContainerHeader"
  | "headerRowItemText"
  | "iconWrapper"
  | "emptyStateTitle"
  | "emptyStateMsg"
  | "text"
  | "textAddress"
  | "actionsWrapperRow"
  | "actionsWrapper"
  | "dateTextColor"
  | "actionButtons"
  | "approveButton"
  | "declineButton"
  | "activePhotosContainer"
  | "activeRequestsLabel"
  | "selectedContainer"
  | "photoRequestLabel"
  | "selectCategoryLabel"
  | "selectedCategoryBackground"
  | "selectedCategoryIconColor"
  | "indeterminateCheckColor"
  | "commentTxt"
  | "reportTextWrapper"
  | "paginationLabel";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      gridContainer: {
        [theme.breakpoints.down("lg")]: {
          minWidth: 900,
        },
      },
      gridContainerHeader: {
        padding: 0,
      },
      root: {
        marginBottom: theme.spacing(3),
        backgroundColor: "white",
        padding: "12px 24px 12px 24px",
        maxWidth: "100%",
        boxSizing: "border-box",
        overflowX: "auto",
        [theme.breakpoints.down("sm")]: {
          maxWidth: 500,
        },
        [theme.breakpoints.up("sm")]: {
          maxWidth: "100%",
        },
      },
      rootRow: {
        padding: "8px 0px 8px 0px",
      },
      rootEmpty: {
        flex: 1,
        flexDirection: "column",
        padding: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      emptyStateTitle: {
        marginBottom: theme.spacing(1),
        fontWeight: "bold",
        textAlign: "center",
      },
      emptyStateMsg: {
        textAlign: "center",
      },
      headerRowItemText: {
        color: colors.grey.additional,
      },
      iconWrapper: {
        display: "flex",
        alignItems: "center",
        marginLeft: 6,
      },
      rowItem: {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        flexWrap: "wrap",
        padding: theme.spacing(1, 1),
      },
      rowItem5: {
        flexGrow: 0,
        maxWidth: "5%",
        flexBasis: "5%",
      },
      rowItem8: {
        flexGrow: 0,
        maxWidth: "8%",
        flexBasis: "8%",
      },
      rowItem10: {
        flexGrow: 0,
        maxWidth: "10%",
        flexBasis: "10%",
      },
      rowItem19: {
        flexGrow: 0,
        maxWidth: "19%",
        flexBasis: "19%",
      },
      rowItem20: {
        flexGrow: 0,
        maxWidth: "20%",
        flexBasis: "20%",
      },
      text: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
      },
      textAddress: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 400,
        color: colors.grey.additional,
        marginTop: 5,
      },
      actionsWrapperRow: {
        width: "100%",
      },
      actionsWrapper: {
        display: "flex",
        flexDirection: "row",
      },
      actionButtons: {
        fontSize: 15,
        minHeight: 38,
        maxHeight: 38,
        borderRadius: 3,
        padding: "6px 10px",
        width: "100%",
        "&.Mui-disabled": {
          opacity: 0.2,
          color: colors.white.primary,
          backgroundColor: colors.red.primary,
        },
      },
      declineButton: {
        minWidth: 77,
      },
      approveButton: {
        marginRight: 10,
        minWidth: 72,
        "&.Mui-disabled": {
          opacity: 0.2,
          color: colors.white.primary,
          backgroundColor: colors.black.primary,
        },
      },
      activePhotosContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(3),
      },
      selectedContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: theme.spacing(3),
      },
      activeRequestsLabel: {
        fontWeight: 600,
        color: colors.grey.secondary,
      },
      dateTextColor: {
        color: colors.grey.additional,
      },
      photoRequestLabel: {
        fontWeight: "bold",
      },
      selectCategoryLabel: {
        fontSize: 15,
        fontWeight: "bold",
        lineHeight: "20px",
        paddingTop: 9,
        paddingBottom: 9,
      },
      selectedCategoryBackground: {
        backgroundColor: `${colors.grey.primary} !important`,
        color: colors.white.primary,
      },
      selectedCategoryIconColor: {
        color: colors.white.primary,
      },
      indeterminateCheckColor: {
        color: colors.red.primary,
      },
      commentTxt: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      reportTextWrapper: {
        maxWidth: "100%",
      },
      paginationLabel: {
        color: colors.blue.additional,
        opacity: 0.67,
      },
    })
);
