import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";
import { colors } from "../../theme/colors";

export type ClassNames =
  | "root"
  | "contentWrapper"
  | "searchAndAddNewButtonWrapper"
  | "searchWrapper"
  | "paginationLabel"
  | "paginationRowPerPage"
  | "paginationWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        flex: 1,
      },
      contentWrapper: {
        padding: theme.spacing(3),
        marginTop: 22,
        position: "relative",
        flex: 1,
        display: "flex",
        flexDirection: "column",
      },
      searchAndAddNewButtonWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(4),
      },
      searchWrapper: {
        flex: 1,
      },
      paginationLabel: {
        color: colors.blue.additional,
      },
      paginationRowPerPage: {
        color: colors.grey.secondary,
        fontWeight: 600,
      },
      paginationWrapper: {
        marginTop: 54,
      },
    })
);
