import { TextField, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import React, { FC } from "react";

import { formatMobileNumberWithCountry } from "../../../../../../../../common/helpers";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const CodeStep: FC<Props> = (props: Props) => {
  const { phonenumber, error, resendCode, onSetCode } = props;
  const classes = useStyles();

  const confirmHelpTxt =
    dictionary.profile.mfa.confirmCallHelpText +
    "+" +
    formatMobileNumberWithCountry(String(phonenumber));

  return (
    <div>
      <Typography variant="h1" className={classes.headerTitle}>
        {dictionary.profile.mfa.confirmCodeTitle}
      </Typography>
      <Typography variant="body1" className={classes.helperTxt}>
        {confirmHelpTxt}
      </Typography>

      <TextField
        fullWidth
        id="filled-phone-code"
        label={dictionary.profile.mfa.code}
        name="code"
        onChange={(ev) => {
          onSetCode(ev.target.value);
        }}
        style={{ marginTop: "1rem" }}
        helperText={error}
        error={!!error}
        type="text"
        variant="outlined"
      />
      <Typography variant="body1" className={classes.helperTxt}>
        {dictionary.profile.mfa.didnotget}{" "}
        <Link
          href="#"
          className={classes.resendLink}
          onClick={() => resendCode()}>
          {dictionary.profile.mfa.resend}
        </Link>
      </Typography>
    </div>
  );
};
export default CodeStep;
