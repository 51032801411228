import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type ClassNames = "searchIcon" | "menuItem";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    searchIcon: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    menuItem: { paddingLeft: 8 },
  })
);
