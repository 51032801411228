import { Dispatch } from "react";

import { store } from "../../../store";
import { setForm } from "../../../store/forms/actions";
import { SetFormActionType } from "../../../store/forms/types";
import { getAllConfigs as gac, saveConfig as sc } from "../api";
import { CoreSettingsForm } from "../form";
import { Config, ConfigSection, CoreConfigData } from "../models";
import {
  ConfigActionTypes,
  GET_CONFIG_FULFILLED,
  GET_CONFIG_PENDING,
  GET_CONFIG_REJECTED,
  GetAllConfigFailAction,
  GetAllConfigPendingAction,
  GetAllConfigSuccessAction,
  SAVE_CONFIG_FULFILLED,
  SAVE_CONFIG_PENDING,
  SAVE_CONFIG_REJECTED,
  SaveConfigFailAction,
  SaveConfigPendingAction,
  SaveConfigSuccessAction,
} from "./types";

const getAllConfigPendingAction = (): GetAllConfigPendingAction => ({
  type: GET_CONFIG_PENDING,
});

const getAllConfigSuccessAction = (
  configSections: ConfigSection[],
  configsArray: CoreConfigData[]
): GetAllConfigSuccessAction => ({
  type: GET_CONFIG_FULFILLED,
  configSections,
  configsArray,
});

const getAllConfigFailAction = (error: string): GetAllConfigFailAction => ({
  type: GET_CONFIG_REJECTED,
  error,
});

export const createFormValues = (
  configs: CoreConfigData[],
  setDefaults = false
): CoreSettingsForm => {
  const form: CoreSettingsForm = {
    isValid: true,
    touched: [],
    values: {},
    errors: {},
  };

  configs.forEach((config: CoreConfigData) => {
    form.values[config.path] = setDefaults ? config.defaultValue : config.value;
  });

  return form;
};

export const getAllConfigs =
  () => async (dispatch: Dispatch<ConfigActionTypes | SetFormActionType>) => {
    dispatch(getAllConfigPendingAction());

    try {
      const { config } = await gac();
      dispatch(
        getAllConfigSuccessAction(splitConfigIntoSections(config), config)
      );
      dispatch(setForm("coreSettings", createFormValues(config)));
    } catch (error) {
      if (!error) {
        return;
      }

      const msg = error.data.details
        ? error.data.details[0].message
        : error.data;

      dispatch(getAllConfigFailAction(msg));
    }
  };

export const resetToDefaults =
  () => (dispatch: Dispatch<ConfigActionTypes | SetFormActionType>) => {
    dispatch(
      setForm(
        "coreSettings",
        createFormValues(store.getState().config.configsArray, true)
      )
    );
  };

const saveFormConfigPendingAction = (): SaveConfigPendingAction => ({
  type: SAVE_CONFIG_PENDING,
});

const saveFormConfigSuccessAction = (): SaveConfigSuccessAction => ({
  type: SAVE_CONFIG_FULFILLED,
});

const saveFormConfigFailAction = (error: string): SaveConfigFailAction => ({
  type: SAVE_CONFIG_REJECTED,
  error,
});

export const saveConfig =
  () => async (dispatch: Dispatch<ConfigActionTypes>) => {
    dispatch(saveFormConfigPendingAction());

    try {
      const { forms } = store.getState();

      const config = forms.coreSettings.values;

      await sc(config);

      dispatch(saveFormConfigSuccessAction());
    } catch (error) {
      if (!error) {
        return;
      }

      const msg = error.data.details
        ? error.data.details[0].message
        : error.data;
      dispatch(saveFormConfigFailAction(msg));
    }
  };

const splitConfigIntoSections = (config: CoreConfigData[]): ConfigSection[] => {
  const sections: Record<string, CoreConfigData[]> = {};

  config.forEach((coreConfigData: CoreConfigData) => {
    const { path } = coreConfigData;

    const sectionName = path.substr(0, path.indexOf("/"));

    sections[sectionName] = sections[sectionName]
      ? [...sections[sectionName], coreConfigData]
      : [coreConfigData];
  });

  return (
    Object.keys(sections)
      .map((sectionKey: string) => {
        return {
          title: getLabelFromPath(sectionKey),
          content: sections[sectionKey]
            .map((configData: CoreConfigData) => ({
              label: getLabelFromPath(
                configData.path.substr(configData.path.indexOf("/") + 1)
              ),
              value: configData.value,
              description: configData.description,
              defaultValue: configData.defaultValue,
              path: configData.path,
              validation: configData.validation,
              inputType: configData.inputType,
            }))
            // sort alphabetically by labels
            .sort((a: Config, b: Config) => (a.label > b.label ? 1 : -1)),
        };
      })
      // sort alphabetically by titles
      .sort((a: ConfigSection, b: ConfigSection) =>
        a.title > b.title ? 1 : -1
      )
  );
};

const getLabelFromPath = (path: string): string => {
  return path[0].toUpperCase() + path.slice(1, path.length).replace(/_/gi, " ");
};
