import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import React, { FC } from "react";

import { ReactComponent as IconTriangle } from "../../../../common/svg/icons/triangle.svg";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  DiscountStatus,
  HappyHourDealStatus,
} from "../../types/DiscountStatus";
import Props from "./Props";
import { useStyles } from "./styles";

const SelectHappyHour: FC<Props> = (props: Props) => {
  const { id, status, onChange } = props;
  const classes = useStyles();

  return (
    <Select
      disableUnderline
      IconComponent={(iconProps) => <IconTriangle {...iconProps} />}
      classes={{
        select: classes.select,
        icon: classes.selectIcon,
      }}
      className={classes.selectWrapper}
      style={{
        borderColor:
          status === DiscountStatus.ENABLED
            ? "rgba(121, 193, 119, 0.3)"
            : "rgba(255, 167, 35, 0.3)",
        color:
          status === DiscountStatus.ENABLED
            ? "rgba(121, 193, 119)"
            : "rgba(255, 167, 35)",
      }}
      value={status}
      variant={"filled"}
      label={dictionary.venues.ownVenues.venueSelectPlaceholder}
      onChange={(event: any) => onChange(id, event.target.value)}>
      {Object.keys(HappyHourDealStatus).map((key) => (
        <MenuItem key={key} value={key}>
          {key === "ENABLED" ? "ACTIVE" : key}
        </MenuItem>
      ))}
    </Select>
  );
};
export default SelectHappyHour;
