import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";

export type VenueClassNames = "root";

export const useStyles: () => ClassNameMap<VenueClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        padding: 30,
      },
      successMsg: {
        color: "green",
        fontSize: theme.font.size.xxl,
      },
      error: {
        color: "red",
      },
    })
);
