import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AuthState } from "../../store/auth/reducer";
import { AppState } from "../../store/rootReducer";

export function useIsVenueAdmin() {
  const { roles } = useSelector<AppState, AuthState>((state) => state.auth);
  const [isVenueAdmin, setIsVenueAdmin] = useState(
    roles.includes("VENUE_ADMIN")
  );

  useEffect(() => {
    setIsVenueAdmin(roles.includes("VENUE_ADMIN"));
  }, [roles]);

  return isVenueAdmin;
}
