import React, { FC, useContext, useEffect, useState } from "react";

import { AlertContext } from "../../../../../common/components/Alert/AlertContextProvider";
import { useCurrentVenueId } from "../../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../../common/hooks/useRestApi";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { RoleTypeEnum } from "../../../main/RoleTypes";
import { CREATE_MANAGER } from "../../api";
import { ICreateManagerRequest } from "../../models/ManagerRequest";
import ManagerDialog from "../ManagerDialog";
import { CreateManagerForm } from "../ManagerDialog/form";
import Props from "./Props";

const CreateManagerDialog: FC<Props> = (props: Props) => {
  const { isOpen, toggleOpen, onSuccess } = props;

  const venueId = useCurrentVenueId();

  const [role, setRole] = useState<any>([]);
  const [_, createManager] = useRestApi<any, ICreateManagerRequest>(
    CREATE_MANAGER(venueId),
    "POST"
  );
  const { showAlert } = useContext(AlertContext);

  const handleClose = () => {
    toggleOpen(false);
  };

  useEffect(() => {
    setRole([]);
  }, [isOpen]);

  const handleCreateManager = async (form: CreateManagerForm) => {
    try {
      createManager({
        ...form.values,
        isWaitlistAdmin: role.includes(RoleTypeEnum.waitlist),
        isDiscountAdmin: role.includes(RoleTypeEnum.discount),
      } as ICreateManagerRequest)
        .then(() => {
          showAlert(dictionary.venueStaff.messages.createdSuccess, "success");
          handleClose();
          onSuccess && onSuccess();
        })
        .catch((error) => {
          if (!error) {
            return;
          }

          if (error.response.data === "Insult word") {
            showAlert(dictionary.notifications.insultingMessageError, "error");
          } else
            showAlert(dictionary.venueStaff.messages.updatedError, "error");
        });
    } catch (e) {
      if (!e) {
        return;
      }
    }
  };

  return (
    <>
      {isOpen && (
        <ManagerDialog
          handleClose={() => toggleOpen(false)}
          handleSave={handleCreateManager}
          role={role}
          setRole={setRole}
        />
      )}
    </>
  );
};

export default CreateManagerDialog;
