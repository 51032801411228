import { ChatDataItem } from "../../views/Messages/models";
import { MessagingActionType, SET_CHAT_DATA, SET_LIKED_MESSAGE } from "./types";

export const AVATAR_PLACEHOLDER_PATH = "/default-avatar-profile-icon-grey.jpg";

export interface MessagingState {
  chatData: ChatDataItem[];
  activeChat: number;
}

const defaultState: MessagingState = {
  chatData: [],
  activeChat: 0,
};

export default (
  state: MessagingState = defaultState,
  action: MessagingActionType
): MessagingState => {
  const prevChats = [...state.chatData];
  let newChatData: ChatDataItem[];

  switch (action.type) {
    case SET_CHAT_DATA:
      const { chatData } = action;

      return {
        ...state,
        chatData,
      };
    case "ADD_NEW_MESSAGE":
      const { newMessage, activeChatWindow } = action;
      const { userName, avatar } = newMessage;
      const currentChatWithUser = prevChats.find(
        (prevChat) => prevChat.id === newMessage.userId
      );

      if (currentChatWithUser) {
        newChatData = [...prevChats].map((chat) => {
          const newChatItem = { ...chat };

          if (chat.id === newMessage.userId) {
            newChatItem.messages = [
              ...chat.messages,
              {
                ...newMessage,
                isReadByYou:
                  activeChatWindow ===
                  prevChats.findIndex((chat) => chat.id === newMessage.userId),
              },
            ];
          }

          return newChatItem;
        });
      } else {
        newChatData = [
          ...prevChats,
          {
            id: newMessage.userId,
            avatar,
            name: userName,
            messages: [newMessage],
          },
        ];
      }

      return {
        ...state,
        chatData: newChatData,
      };
    case "SET_LIKED_MESSAGE":
      const { message } = action;

      newChatData = [...prevChats].map((chat) => {
        const chatItem = { ...chat };

        if (chat.id === message.userId) {
          chatItem.messages = [...chat.messages].map((chatMsg) => {
            if (chatMsg.id === message.id) {
              chatMsg.liked = !chatMsg.liked;
            }
            return chatMsg;
          });
        }

        return chatItem;
      });

      return {
        ...state,
        chatData: newChatData,
      };
    case "SET_ACTIVE_CHAT":
      const newChatWindow = action.activeChat;

      return {
        ...state,
        activeChat: newChatWindow,
      };
    default:
      return state;
  }
};
