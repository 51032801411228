import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import HttpStatusCodes from "../../../../common/httpStatusCodes";
import dictionary from "../../../../i18n/en_US/dictionary";
import api from "../../api";
import { TransactionItem } from "../../models";
import { TransactionsContext } from "../../providers";

export const useMoneyForm = () => {
  const {
    setFilterOptions,
    transactionsList,
    setTransactionsList,
    transactionsCount,
    setTransactionsCount,
    searchFilters,
    setTableLoading,
    order,
    orderBy,
  } = useContext(TransactionsContext);
  const { showAlert } = useContext(AlertContext);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const skip = page * rowsPerPage;

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchTransactions = useCallback(() => {
    setTableLoading(true);
    api.transactions
      .listAndCountAll({
        limit: rowsPerPage,
        skip,
        sortedColumn: orderBy,
        order,
        ...searchFilters,
      })
      .then((data: { list: TransactionItem[]; count: number }) => {
        if (data) {
          setTransactionsList(data.list);
          setTransactionsCount(data.count);
        }
      })
      .catch((e) => {
        if (e.status !== HttpStatusCodes.SERVER_ERROR) {
          showAlert(dictionary.deals.createDealForm.errorFetchData, "error");
        }
      })
      .finally(() => setTableLoading(false));
  }, [
    setTableLoading,
    rowsPerPage,
    skip,
    searchFilters,
    order,
    orderBy,
    setTransactionsList,
    setTransactionsCount,
    showAlert,
  ]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const fetchTransactionsFiltersOptions = useCallback(() => {
    setTableLoading(true);
    api.transactions
      .mapAllFilters()
      .then((data) => {
        if (Object.keys(data).length) {
          setFilterOptions(data);
        }
      })
      .catch((e) => {
        if (e.status !== HttpStatusCodes.SERVER_ERROR) {
          showAlert(dictionary.deals.createDealForm.errorFetchData, "error");
        }
      })
      .finally(() => setTableLoading(false));
  }, [setFilterOptions, setTableLoading, showAlert]);

  useEffect(() => {
    fetchTransactionsFiltersOptions();
  }, [fetchTransactionsFiltersOptions]);

  return {
    rowsPerPage,
    page,
    transactionsList,
    transactionsCount,
    setPage,
    setRowsPerPage,
    handleRowsPerPageChange,
  };
};
