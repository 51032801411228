import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../common/models";
import { ColorsGray, ColorsRed } from "../../../../../../../theme/colors";

export type ClassNames =
  | "cardWrapper"
  | "cardWrapperInner"
  | "cardTitle"
  | "cardTitleUsername"
  | "cardStarsWrapper"
  | "starHeptagonFilledIcon"
  | "starHeptagonFilledText"
  | "heartFilledIcon"
  | "heartFilledText"
  | "cardTitleDate"
  | "cardReviewText"
  | "cardReviewReplyWrapper"
  | "reviewReplyInput"
  | "button"
  | "cardTextResponseWrapper"
  | "cardTextResponseTitleWrapper"
  | "cardTextResponseUsername"
  | "cardTextResponseDate"
  | "cardTextResponseTextWrapper"
  | "cardTextResponseText"
  | "trashIcon"
  | "trashIconWrapper"
  | "cardReviewForbidden"
  | "exclamationIcon"
  | "cardReviewForbiddenText"
  | "sticky";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      button: {
        minWidth: 60,
        maxWidth: 60,
        padding: theme.spacing(1, 1, 1, 1),
        transition: "max-width 0.7s linear",
        marginTop: 16,
        marginLeft: "auto",
        marginBottom: 16,
      },
      cardWrapper: {
        padding: 24,
        paddingBottom: 8,
        marginBottom: 16,
      },
      cardWrapperInner: {
        display: "flex",
        alignItems: "center",
        position: "relative",
      },
      cardTitle: {
        display: "flex",
        flexDirection: "column",
      },
      cardTitleUsername: {
        color: ColorsGray._37393F,
        fontSize: 20,
        fontWeight: 600,
      },
      cardStarsWrapper: {
        display: "flex",
        alignItems: "center",
        marginTop: 8,
      },
      starHeptagonFilledIcon: {
        width: 16,
        height: 16,
        color: "#6E8BF9",
        marginRight: 8,
      },
      starHeptagonFilledText: {
        color: ColorsGray._595D64,
        fontSize: 17,
        fontWeight: 700,
        marginRight: 16,
      },
      heartFilledIcon: {
        width: 16,
        height: 14,
        color: ColorsRed._FE5454,
        marginRight: 8,
      },
      heartFilledText: {
        color: ColorsRed._FE5454,
        fontSize: 17,
        fontWeight: 700,
      },
      cardTitleDate: {
        color: ColorsGray._595D64,
        fontSize: 12,
        fontWeight: 400,
        marginLeft: "auto",
        position: "absolute",
        top: 0,
        right: 0,
      },
      cardReviewText: {
        color: ColorsGray._37393F,
        fontSize: 14,
        fontWeight: 400,
        marginTop: 24,
        paddingBottom: 16,
        lineHeight: "24px",
        letterSpacing: "0.15px",
        wordBreak: "break-word",
      },
      cardReviewReplyWrapper: {
        display: "flex",
        flexDirection: "column",
      },
      reviewReplyInput: {
        marginTop: 24,
        borderRadius: 4,
      },
      cardTextResponseWrapper: {
        marginBottom: 16,
      },
      cardTextResponseTitleWrapper: {
        marginTop: 16,
        marginBottom: 4,
        display: "flex",
        justifyContent: "space-between",
      },
      cardTextResponseUsername: {
        color: ColorsGray._37393F,
        fontSize: 20,
        fontWeight: 600,
      },
      cardTextResponseDate: {
        color: ColorsGray._595D64,
        fontSize: 12,
        fontWeight: 400,
        marginLeft: "auto",
      },
      cardTextResponseTextWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
      },
      cardTextResponseText: {
        flex: 2,
        wordBreak: "break-word",
      },
      trashIcon: {
        width: 24,
        height: 24,
        color: ColorsRed._FE5454,

        cursor: "pointer",
      },
      trashIconWrapper: {
        marginLeft: 70,
        display: "flex",
      },
      cardReviewForbidden: {
        borderRadius: 4,
        backgroundColor: "rgba(116,121,130, .1)",
        display: "flex",
        alignItems: "center",
        padding: "10px 16px",
        marginBottom: 16,
      },
      exclamationIcon: { width: 16, height: 16, marginRight: 10 },
      cardReviewForbiddenText: {
        color: ColorsGray._37393F,
        fontWeight: 600,
        fontSize: 12,
      },
      sticky: {
        top: 20,
        position: "sticky",
      },
    })
);
