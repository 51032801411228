import { Grid, Hidden, TextField } from "@material-ui/core";
import * as _ from "lodash";
import React, { ChangeEvent, FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonSuccess from "../../../../common/components/ButtonSuccess";
import PhoneNumberInput from "../../../../common/components/PhoneNumberInput";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  setAndValidate,
  setForm,
  validateForm,
} from "../../../../store/forms/actions";
import { FormFieldKey } from "../../../../store/forms/types";
import { AppState } from "../../../../store/rootReducer";
import { IProfileForm } from "./form/IProfileForm";
import ProfileFormHelper from "./form/ProfileFormHelper";
import { ProfileFormSchema as schema } from "./form/ProfileFormSchema";
import Props from "./Props";
import { useStyles } from "./styles";

const ProfileForm: FC<Props> = (props: Props) => {
  const { profile, onSave } = props;
  const classes = useStyles();
  const { forms } = useSelector((state: AppState) => state);
  const form = forms.profile;

  const dispatch = useDispatch();
  const stateFormRef = useRef<IProfileForm>();
  stateFormRef.current = form;

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = event.target.value;

    if (event.target.name === "phone") {
      value = value.replace(/\D/g, "");
      value = `+${value}`;
    }
    dispatch(
      setAndValidate(
        "profile",
        event.target.name as FormFieldKey,
        value,
        schema
      )
    );
  };

  const handleSave = _.debounce(() => {
    // if (!havePermission(Profile.EDIT)) {
    //   showAlert("User profile data is prohibited", "error");
    // }

    if (!dispatch(validateForm("profile", schema))) {
      return;
    }

    const form: IProfileForm = stateFormRef.current as IProfileForm;

    onSave(ProfileFormHelper.mapProfileFromForm(form));
  }, 500);

  useEffect(() => {
    dispatch(
      setForm("profile", ProfileFormHelper.createProfileFormFromValue(profile))
    );
  }, [dispatch, profile]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            required
            id="filled-firstName"
            label={dictionary.venueAdmin.profile.mainForm.firstName}
            name="firstName"
            error={!!form.errors.firstName}
            helperText={form.errors.firstName}
            className={classes.textField}
            type="text"
            value={form.values.firstName}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            required
            id="filled-lastName"
            label={dictionary.venueAdmin.profile.mainForm.lastName}
            name="lastName"
            error={!!form.errors.lastName}
            helperText={form.errors.lastName}
            className={classes.textField}
            type="text"
            value={form.values.lastName}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Hidden smDown>
          <Grid item lg={4} md={4} />
        </Hidden>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            required
            id="filled-email"
            label={dictionary.venueAdmin.profile.mainForm.email}
            name="email"
            error={!!form.errors.email}
            helperText={form.errors.email}
            className={classes.textField}
            type="text"
            value={form.values.email}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <PhoneNumberInput
            value={form.values.phone && form.values.phone}
            onChange={handleChange}
            error={form.errors.phone}
            name="phone"
            label={dictionary.venueAdmin.profile.mainForm.phone}
          />
        </Grid>
        <Hidden smDown>
          <Grid item lg={4} md={4} />
        </Hidden>
      </Grid>
      <ButtonSuccess
        className={classes.saveButton}
        disabled={!form.isValid}
        onClick={handleSave}>
        Save Changes
      </ButtonSuccess>
    </div>
  );
};
export default ProfileForm;
