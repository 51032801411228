import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";

export type ClassNames = "root" | "titleWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(7, 4),
        },
      },
      titleWrapper: {
        marginBottom: theme.spacing(3),
      },
    })
);
