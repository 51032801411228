import { ConfigSection, CoreConfigData } from "../models";

export const GET_CONFIG_PENDING = "GET_CONFIG.PENDING";
export const GET_CONFIG_REJECTED = "GET_CONFIG.REJECTED";
export const GET_CONFIG_FULFILLED = "GET_CONFIG.FULFILLED";
export const SAVE_CONFIG_PENDING = "SAVE_CONFIG.PENDING";
export const SAVE_CONFIG_REJECTED = "SAVE_CONFIG.REJECTED";
export const SAVE_CONFIG_FULFILLED = "SAVE_CONFIG.FULFILLED";

export interface GetAllConfigPendingAction {
  type: typeof GET_CONFIG_PENDING;
}

export interface GetAllConfigSuccessAction {
  type: typeof GET_CONFIG_FULFILLED;
  configSections: ConfigSection[];
  configsArray: CoreConfigData[];
}

export interface GetAllConfigFailAction {
  type: typeof GET_CONFIG_REJECTED;
  error: string;
}

export interface SaveConfigPendingAction {
  type: typeof SAVE_CONFIG_PENDING;
}

export interface SaveConfigSuccessAction {
  type: typeof SAVE_CONFIG_FULFILLED;
}

export interface SaveConfigFailAction {
  type: typeof SAVE_CONFIG_REJECTED;
  error: string;
}

export type ConfigActionTypes =
  | GetAllConfigPendingAction
  | GetAllConfigSuccessAction
  | GetAllConfigFailAction
  | SaveConfigPendingAction
  | SaveConfigSuccessAction
  | SaveConfigFailAction;
