import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";
import { colors } from "../../../../../../../../theme/colors";

export type VenueClassNames =
  | "root"
  | "headerTitle"
  | "helperTxt"
  | "resendLink";

export const useStyles: () => ClassNameMap<VenueClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      headerTitle: {
        marginTop: theme.spacing(4),
        textAlign: "center",
      },
      helperTxt: {
        color: colors.grey.helpText,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        fontSize: theme.typography.pxToRem(15),
        textAlign: "center",
        maxWidth: 300,
      },
      resendLink: {
        fontWeight: "bold",
      },
    })
);
