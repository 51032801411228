import { Grid } from "@material-ui/core";
import React, { FC } from "react";

import PromotionAdditionalFilters from "../PromotionAdditionalFilters";
// eslint-disable-next-line max-len
import MostPopularPromotions from "./components/MostPopularPromotions";
// eslint-disable-next-line max-len
import PromotionRecommendationsImpressions from "./components/PromotionRecommendationsImpressions";
// eslint-disable-next-line max-len
import PromotionRecommendationsPosition from "./components/PromotionRecommendationsPosition";
import PromotionUniqueViews from "./components/PromotionUniqueViews";
// eslint-disable-next-line max-len
import PromotionUsagePopularHours from "./components/PromotionUsagePopularHours";
import PromotionUsagesByDevice from "./components/PromotionUsagesByDevice";
import PromotionViews from "./components/PromotionViews";
import { useStyles } from "./styles";

const ReportingPromotionTab: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PromotionAdditionalFilters />
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <PromotionRecommendationsPosition />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <PromotionRecommendationsImpressions />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <PromotionViews />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <PromotionUsagesByDevice />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <PromotionUniqueViews />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <PromotionUsagePopularHours />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <MostPopularPromotions />
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportingPromotionTab;
