import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AuthState } from "../../store/auth/reducer";
import { AppState } from "../../store/rootReducer";
import { RoleNameEnum } from "../../views/VenueStaff/main/RoleTypes";

export function useDataEntryAdmin() {
  const { roles } = useSelector<AppState, AuthState>((state) => state.auth);
  const [isDataEntryAdmin, setIsDataEntryAdmin] = useState(
    roles.includes(RoleNameEnum.dataEntryAdmin)
  );

  useEffect(() => {
    setIsDataEntryAdmin(roles.includes(RoleNameEnum.dataEntryAdmin));
  }, [roles]);

  return isDataEntryAdmin;
}
