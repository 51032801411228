import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "form"
  | "actionsToolbar"
  | "recaptchaContainer";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        height: "100%",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(4, 4),
        },
        [theme.breakpoints.up("lg")]: {
          padding: theme.spacing(59 / 8, 134 / 8),
        },
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(3, 2),
        },
      },
      form: {
        position: "relative",
        marginTop: theme.spacing(50 / 8),
        maxWidth: 800,
      },
      actionsToolbar: {
        marginTop: theme.spacing(5),
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
        },
      },
      recaptchaContainer: {
        [theme.breakpoints.up("sm")]: {
          transform: "scale(0.685)",
          marginTop: 1,
          marginRight: -20,
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: theme.spacing(2),
        },
      },
    })
);
