import { Dispatch } from "redux";
import validate from "validate.js";

import { AppState } from "../rootReducer";
import {
  CLEAR_FORM,
  CLEAR_FORM_ERROR,
  CLEAR_FORM_ERRORS,
  ClearFormActionType,
  ClearFormErrorActionType,
  ClearFormErrorsActionType,
  DELETE_FORM_ERROR,
  DELETE_FORM_VALUE,
  DeleteFormErrorActionType,
  DeleteFormValueActionType,
  FormActionTypes,
  FormErrors,
  FormFieldKey,
  FormKeys,
  FormSchemaTypes,
  FormTypes,
  REMOVE_TOUCHED_FIELD,
  RemoveTouchedFieldActionType,
  SET_FORM,
  SET_FORM_ERROR,
  SET_FORM_ERRORS,
  SET_FORM_VALID,
  SET_FORM_VALUE,
  SET_TOUCHED_FIELD,
  SetFormActionType,
  SetFormErrorActionType,
  SetFormErrorsActionType,
  SetFormValidActionType,
  SetFormValueActionType,
  SetTouchedFieldActionType,
} from "./types";

export const clearForm = (formKey: FormKeys): ClearFormActionType => ({
  type: CLEAR_FORM,
  formKey,
});

export const clearFormErrors = (
  formKey: FormKeys
): ClearFormErrorsActionType => ({
  type: CLEAR_FORM_ERRORS,
  formKey,
});

export const deleteFormError = (
  formKey: FormKeys,
  formFieldKey: FormFieldKey
): DeleteFormErrorActionType => ({
  type: DELETE_FORM_ERROR,
  formKey,
  formFieldKey,
});

export const setFormErrors = (
  formKey: FormKeys,
  errors: FormErrors
): SetFormErrorsActionType => ({
  type: SET_FORM_ERRORS,
  errors,
  formKey,
});

export const deleteFormValue = (
  formKey: FormKeys,
  formFieldKey: FormFieldKey
): DeleteFormValueActionType => ({
  type: DELETE_FORM_VALUE,
  formKey,
  formFieldKey,
});

export const removeTouchedField = (
  formKey: FormKeys,
  formFieldKey: FormFieldKey
): RemoveTouchedFieldActionType => ({
  type: REMOVE_TOUCHED_FIELD,
  formKey,
  formFieldKey,
});

export const setForm = (
  formKey: FormKeys,
  form: FormTypes
): SetFormActionType => ({
  type: SET_FORM,
  formKey,
  form,
});

export const setFormError = (
  formKey: FormKeys,
  formFieldKey: FormFieldKey,
  error: string,
  value?: any
): SetFormErrorActionType => ({
  type: SET_FORM_ERROR,
  formFieldKey,
  formKey,
  error,
  value,
});

export const clearFormError = (
  formKey: FormKeys,
  formFieldKey: FormFieldKey,
  value?: any
): ClearFormErrorActionType => ({
  type: CLEAR_FORM_ERROR,
  formFieldKey,
  formKey,
  value,
});

export const setFormValid = (formKey: FormKeys): SetFormValidActionType => ({
  type: SET_FORM_VALID,
  formKey,
});

export const setFormValue = (
  formKey: FormKeys,
  formFieldKey: FormFieldKey,
  value: any
): SetFormValueActionType => ({
  type: SET_FORM_VALUE,
  formKey,
  formFieldKey,
  value,
});

export const setTouchedField = (
  formKey: FormKeys,
  formFieldKey: FormFieldKey
): SetTouchedFieldActionType => ({
  type: SET_TOUCHED_FIELD,
  formFieldKey,
  formKey,
});

export const setAndValidate =
  (
    formKey: FormKeys,
    formFieldKey: FormFieldKey,
    value: any,
    schema?: FormSchemaTypes
  ) =>
  (dispatch: Dispatch<FormActionTypes>) => {
    if (!schema) {
      dispatch(setFormValue(formKey, formFieldKey, value));
      return;
    }

    const formValue = { [formFieldKey]: value };

    const errors = validate(formValue, {
      // @ts-ignore
      [formFieldKey]: schema[formFieldKey],
    });

    if (errors && errors[formFieldKey]) {
      dispatch(
        setFormError(formKey, formFieldKey, errors[formFieldKey][0], value)
      );
    } else {
      dispatch(deleteFormError(formKey, formFieldKey));
    }

    dispatch(setFormValue(formKey, formFieldKey, value));
    dispatch(setFormValid(formKey));
  };

export const validateForm =
  (formKey: FormKeys, schema: FormSchemaTypes) =>
  (dispatch: Dispatch<FormActionTypes>, getState: () => AppState) => {
    const { values } = getState().forms[formKey];

    const errors: undefined | FormErrors = validate(values, schema);

    if (!errors) {
      return true;
    }

    const reduced: FormErrors = Object.entries(errors).reduce(
      (accumulator, [errorField, messages]) => ({
        ...accumulator,
        // @ts-ignore
        [errorField]: messages ? messages[0] : "",
      }),
      {}
    );

    dispatch(setFormErrors(formKey, reduced));
    dispatch(setFormValid(formKey));

    return false;
  };

export default {
  clearForm,
  clearFormErrors,
  deleteFormError,
  deleteFormValue,
  removeTouchedField,
  setForm,
  setFormError,
  setFormValid,
  setFormValue,
  setAndValidate,
  setTouchedField,
};
