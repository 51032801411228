import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

type ClassNames = "link" | "linkWithIcon" | "noEffects";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    link: {
      color: "inherit",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    linkWithIcon: {
      display: "flex",
      alignItems: "center",
    },
    noEffects: {
      "&:hover, &:visited, &:active, &:focus": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  })
);
