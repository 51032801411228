import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import { ReactComponent as MenuFileLogo } from "../../../../../../common/svg/icons/menu-upload-logo.svg";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const MenuFileTab: FC<Props> = ({
  menuInputRef,
  handleMenuChange,
  menuFile,
}: Props) => {
  const classes = useStyles();

  const getSizeInMb = (bytes: number) =>
    Math.round((bytes / (1024 * 1024)) * 100) / 100;

  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        ref={menuInputRef}
        onChange={handleMenuChange}
        accept=".jpg,.jpeg,.png,.pdf"
      />
      {menuFile ? (
        <div className={classes.uploadedMenuWrapper}>
          <MenuFileLogo className={classes.pdfFileIcon} />
          <div className={classes.menuFileMetadata}>
            <Typography variant="h2" className={classes.textGap}>
              {menuFile.fileName}
            </Typography>
            <Typography variant="body1">
              {getSizeInMb(menuFile.size)} MB
            </Typography>
          </div>
        </div>
      ) : (
        <div className={classes.emptyMenuWrapper}>
          <Typography variant={"h2"} className={classes.textGap}>
            {dictionary.venues.venueForm.uploadMenuLabel}
          </Typography>
          <Typography variant={"body1"}>
            {dictionary.venues.venueForm.uploadMenuSubTitle}
          </Typography>
        </div>
      )}
    </>
  );
};

export default MenuFileTab;
