import { DialogContent } from "@material-ui/core";
import React, { FC, useContext } from "react";

import StepsIndicator from "../../../../../../common/components/StepsIndicator";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { CreateVenueDialogContext } from "../../CreateVenueDialogContextProvider";
import CreateVenueDialogAdminInfoStep from "../CreateVenueDialogAdminInfoStep";
import CreateVenueDialogValidationStep from "../CreateVenueDialogValidationStep";
import CreateVenueDialogVenueInfoStep from "../CreateVenueDialogVenueInfoStep";
import { useStyles } from "./styles";

const CreateVenueDialogContent: FC = () => {
  const classes = useStyles();
  const { currentStep } = useContext(CreateVenueDialogContext);

  return (
    <DialogContent className={classes.dialogContent}>
      <StepsIndicator
        currentStep={currentStep}
        stepsNames={[
          dictionary.venues.list.createVenueAdminStepTitle,
          dictionary.venues.list.createVenueVenueStepTitle,
          dictionary.venues.list.createVenueValidationStepTitle,
        ]}
      />
      {currentStep === 1 ? (
        <CreateVenueDialogAdminInfoStep />
      ) : currentStep === 2 ? (
        <CreateVenueDialogVenueInfoStep />
      ) : (
        <CreateVenueDialogValidationStep />
      )}
    </DialogContent>
  );
};

export default CreateVenueDialogContent;
