import { http } from "../../../../config/api";
import ProfileChangePasswordRequest from "./models/ProfileChangePasswordRequest";
const CHANGE_PASSWORD = "profile/change-password";

export const changePassword = async (request: ProfileChangePasswordRequest) =>
  http
    .post(CHANGE_PASSWORD, request)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });
