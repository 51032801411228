import { ChartDataItem } from "./models";

export const chartDataMock: ChartDataItem[] = [
  {
    name: "Promotion 1",
    usages: 100,
  },
  {
    name: "Promotion 2",
    usages: 668,
  },
  {
    name: "Promotion 3 ",
    usages: 201,
  },
  {
    name: "Promotion 4",
    usages: 1200,
  },
];
