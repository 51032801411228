export const GET_VENUE_IMPRESSIONS_IN_SEARCH =
  "reporting/venue-search-impressions-by-month";

export interface ImpressionsData {
  month: string;
  impressionsCount: number;
}

export interface GetVenueSearchImpressionsResponse {
  stats: ImpressionsData[];
}
