import { Grid, Paper } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import IPromotionUsagesResponse, {
  getUsedPromotionsByPromotionId,
} from "./api";
import PromotionsUsagesTable from "./components/PromotionUsagesTable";
import IPromotionUsages from "./models/IPromotionUsages";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionsUsagesList: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const defaultRowsPerPage = [10, 20, 30];
  const { promotionId } = props;

  const [promotionUsages, setPromotionUsages] = useState<IPromotionUsages[]>(
    []
  );

  const { showAlert } = useContext(AlertContext);
  const [promotionUsagesCount, setPromotionUsagesCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage[0]);
  const skip = page * 3;

  useEffect(() => {
    getUsedPromotionsByPromotionId(promotionId, rowsPerPage, skip)
      .then((data: IPromotionUsagesResponse) => {
        if (data) {
          setPromotionUsages(data.usedDiscounts);
          setPromotionUsagesCount(data.totalCount);
        }
      })
      .catch(() => {
        showAlert("Error fetch data", "error");
      });
  }, [showAlert, skip, rowsPerPage, promotionId]);

  return (
    <div className={classes.root}>
      <Paper className={classes.wrapper}>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <PromotionsUsagesTable
              setPage={setPage}
              page={page}
              defaultRowsPerPage={defaultRowsPerPage}
              usedPromotions={promotionUsages}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              totalCount={promotionUsagesCount}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default PromotionsUsagesList;
