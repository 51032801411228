import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "nameContainer"
  | "lockIcon"
  | "packageName"
  | "contentContainer"
  | "actionsToolbar"
  | "unlockButton"
  | "description"
  | "currency"
  | "priceContainer"
  | "price"
  | "descriptionContainer"
  | "discountContainer"
  | "discount"
  | "period"
  | "mainContent";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: colors.red.primary,
        display: "flex",
        borderRadius: 6,
        overflow: "hidden",
        boxShadow: "0px 4px 25px rgba(235, 82, 82, 0.25)",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      nameContainer: {
        display: "flex",
        alignItems: "center",
      },
      lockIcon: {
        marginRight: theme.spacing(2),
      },
      packageName: {
        fontWeight: 700,
        color: colors.white.primary,
        fontSize: theme.typography.pxToRem(22),
      },
      contentContainer: {
        position: "relative",
        padding: theme.spacing(5, 5),
        background:
          "url('/images/backgrounds/full-access-pack-left-bg.png') no-repeat left top",
        height: 247,
        flex: 1,
        display: "flex",
        [theme.breakpoints.up("xl")]: {
          padding: theme.spacing(5, 10),
        },
        [theme.breakpoints.down("sm")]: {
          height: "auto",
          padding: theme.spacing(5, 5, 0),
        },
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(5, 2, 0),
        },
      },
      mainContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      description: {
        fontWeight: 600,
        color: colors.white.primary,
        fontSize: theme.typography.pxToRem(20),
      },
      actionsToolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(5),
        background:
          "url('/images/backgrounds/full-access-pack-right-bg.png') no-repeat right bottom",
        backgroundSize: "auto",
        backgroundColor: "rgba(208, 69, 69, 0.34)",
        minWidth: 450,
        [theme.breakpoints.down("md")]: {
          minWidth: 350,
        },
        [theme.breakpoints.down("sm")]: {
          minHeight: 200,
          backgroundColor: colors.red.primary,
        },
      },
      unlockButton: {
        backgroundColor: colors.white.primary,
        color: colors.grey.primary,
        "&:hover": {
          backgroundColor: colors.white.primary,
          boxShadow: "0px 2px 10px rgba(155, 199, 144, 0.54)",
        },
      },
      priceContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(6),
      },
      currency: {
        fontSize: theme.typography.pxToRem(34),
        fontWeight: 900,
        marginRight: 7,
        marginTop: -20,
        color: colors.white.primary,
      },
      price: {
        fontWeight: 800,
        fontSize: theme.typography.pxToRem(70),
        color: colors.white.primary,
      },
      period: {
        marginTop: 17,
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 600,
        marginLeft: 7,
        color: colors.white.primary,
      },
      descriptionContainer: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(4),
      },
      discountContainer: {
        top: -14,
        left: 110,
        zIndex: 1,
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
          left: 85,
        },
      },
      discount: {
        position: "relative",
        backgroundColor: colors.white.primary,
        display: "block",
        fontWeight: 700,
        padding: "11px 15px",
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        "&:before, &:after": {
          position: "absolute",
          content: "''",
          display: "block",
          top: 0,
          width: 0,
          height: 0,
          borderBottom: "14px solid #CECECE",
        },
        "&:before": {
          left: -10,
          borderLeft: "10px solid transparent",
        },
        "&:after": {
          right: -10,
          borderRight: "10px solid transparent",
        },
      },
    })
);
