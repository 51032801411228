export const colors = {
  black: {
    primary: "#282828",
  },
  blue: {
    additional: "#273950",
    primary: "#6979F8",
    dark: "#5967D3",
    secondary: "#2886E4",
    light: "#E7E9FE",
    primary2: "#6E8CF9",
  },
  purple: {
    additional: "#7C8BC2",
  },
  red: {
    primary: "#FE5454",
    light: "rgba(255, 129, 129, 0.2)",
    secondary: "#FF9696",
    dark: "#D74747",
    lightPink: "#FFF0F0",
    lightPinkDarker: "#ffcdcd",
  },
  green: {
    primary: "#6FCF97",
    secondary: "#66BD5F",
    additional: "#51B87E",
    dark: "#529a4c",
    veryDark: "#58A151",
    light: "#67C19F",
    lightSecondary: "#B4D8CA",
  },
  grey: {
    divider: "#E3E7EC",
    primary: "#464854",
    secondary: "#A5B2C5",
    stroke: "#F5F5F9",
    secondaryLight: "#9EA5B5",
    light: "#CFD9ED",
    lighten: "#F4F6F8",
    medium: "#E5E7EF",
    additional: "#596880",
    helpText: "#909EAF",
    text: "#73859B",
    bgLight: "#EDEFF6",
    bgUltraLight: "#F9FAFC",
    disabled: "#CCD0DE",
    "#848EAF": "#848EAF",
  },
  dark: {
    main: "#273950",
    secondary: "#3D3E48",
    additional: "#37393F",
  },
  orange: {
    main: "#FFB546",
  },
  yellow: {
    primary: "#FFCF5C",
    dark: "#e5b91f",
    light: "#FFF8E7",
  },
  white: {
    primary: "#FFFFFF",
  },
};

export enum ColorsGray {
  _1F1F1F = "#1F1F1F",
  _282B30 = "#282B30",
  _37393F = "#37393F",
  _45484E = "#45484E",
  _595D64 = "#595D64",
  _747982 = "#747982",
  _9298A0 = "#9298A0",
  _B2B7BF = "#B2B7BF",
  _CBCFD5 = "#CBCFD5",
  _E5E7EA = "#E5E7EA",
  _F6F7F8 = "#F6F7F8",
}

export enum ColorsRed {
  _560505 = "#560505",
  _A32B2B = "#A32B2B",
  _E44040 = "#E44040",
  _FE5454 = "#FE5454",
  _FFDDDD = "#FFDDDD",
  _FFF4F4 = "#FFF4F4",
}

export enum ColorsLight {
  _F6F7F8 = "#F6F7F8",
  _FFFFFF = "#FFFFFF",
}

export enum ColorsAdditional {
  _FFCD1B = "#FFCD1B",
  _79C177 = "#79C177",
  _6E8CF9 = "#6E8CF9",
  _7C8BC2 = "#7C8BC2",
}

export const improvementsChartColors = [
  colors.green.light,
  colors.green.lightSecondary,
  "#549F52",
  "#79C177",
  "#48967A",
];

export const chartPrimaryColor = colors.grey["#848EAF"];
export const chartAdditionalColor = colors.green.lightSecondary;
export const chartSecondAdditionalColor = colors.green.secondary;
export const chartSecondaryColor = colors.grey.medium;
