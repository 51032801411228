import { Grid } from "@material-ui/core";
import React, { FC } from "react";

import VenueReportingContextProvider from "../../common/components/VenueReportingContextProvider";
import PageTitleWithToolbar from "./components/PageTitleWithToolbar";
import ReviewsStatistics from "./components/ReviewsStatistics";
import ReviewsTabs from "./components/ReviewsTabs";

const Reviews: FC = () => {
  return (
    <VenueReportingContextProvider>
      <div>
        <PageTitleWithToolbar />

        <div>
          <ReviewsStatistics />
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ReviewsTabs />
            </Grid>
          </Grid>
        </div>
      </div>
    </VenueReportingContextProvider>
  );
};

export default Reviews;
