import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { FC, useCallback, useContext, useEffect, useRef } from "react";

import OcietyCheckbox from "../../../../../../../../../common/components/OcietyCheckbox";
import OcietySwitch from "../../../../../../../../../common/components/OcietySwitch";
import OcietyTooltip from "../../../../../../../../../common/components/OcietyTooltip";
import WorkingDaysPicker from "../../../../../../../../../common/components/WorkingDaysPicker";
import { ReactComponent as InfoIcon } from "../../../../../../../../../common/svg/icons/info.svg";
import { ReactComponent as IconOctagramGreen } from "../../../../../../../../../common/svg/icons/octagram-green.svg";
import dictionary from "../../../../../../../../../i18n/en_US/dictionary";
import IPromotionDietaryRestriction from "../../../../../../../types/interfaces/IPromotionDietaryRestriction.interface";
import { PromotionLimitType, PromotionType } from "../../../../../../enums";
import {
  limitItemsNameBaseRules,
  percentBasesRules,
} from "../../../../forms/main";
import { PromotionFormDialogContext } from "../../../../PromotionFormDialogContextProvider";
import { PromotionKind } from "../../../PromotionFormDialogActions/PromotionKind.enum";
import { DealPriceInput } from "./components/DealPriceInput";
import { SpecialPriceInput } from "./components/SpecialPriceInput/index";
import { VoucherPriceInput } from "./components/VoucherPriceInput";
import { useFormHandlers } from "./hooks/useFormHandlers";
import { layouts, useRenderDealsPanels } from "./hooks/useRenderDealsPanels";
import Props from "./Props";
import { useStyles } from "./styles";

const MainInfoPromotionStep: FC<Props> = (props: Props) => {
  const {
    setDietaryRestrictions,
    dietaryRestrictions,
    promotionDietaryRestrictions,
  } = props;

  const classes = useStyles();
  const focusOnError = useRef<HTMLDivElement>(null);
  const errorHasFocus = useRef<boolean>(false);
  const stepInput = useRef<HTMLInputElement>(null);
  const { mainForm, advancedForm } = useContext(PromotionFormDialogContext);
  const { renderPanels } = useRenderDealsPanels(props);
  const { form, setAndValidate, setSchemaRules, setFormValue, clearErrors } =
    mainForm;
  const formPromotionLimitTypeRef = useRef<PromotionLimitType>(
    form.values.limitType as PromotionLimitType
  );
  const isDeal =
    form.values.kind === PromotionKind.DEAL ||
    form.values.kind === PromotionKind.GIVEAWAY;
  const currentPromotionType = form.values.type as PromotionType;
  const isSingleItem = form.values.limitType === PromotionLimitType.SINGLE;

  const handleChangeLimitType = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement>) => {
      setAndValidate(
        "limitType",
        event ? (event.target as HTMLInputElement).value : ""
      );
      setAndValidate("limitMaxAmount", "0");

      setAndValidate("limitItemsName", "");
    },
    [setAndValidate]
  );

  useEffect(() => {
    stepInput.current &&
      stepInput.current?.scrollIntoView({ block: "nearest" });

    setSchemaRules({
      name: {},
      otherSubcategoryName: {},
    });
  }, [setSchemaRules]);

  useEffect(() => {
    if (!mainForm.form.isValid) {
      focusOnError?.current?.focus();
      errorHasFocus.current = true;
    }
  }, [mainForm.form.isValid]);

  useEffect(() => {
    if (
      currentPromotionType === PromotionType.FOOD ||
      currentPromotionType === PromotionType.DRINK ||
      mainForm.form.values.kind === PromotionKind.VOUCHER
    ) {
      setSchemaRules({
        categoryId: {
          fieldName: dictionary.deals.createDealForm.category,
          type: "number",
          required: true,
        },
        subCategoryId: {
          fieldName:
            mainForm.form.values.kind === PromotionKind.VOUCHER
              ? dictionary.deals.createDealForm.category
              : dictionary.deals.createDealForm.subCategory,
          type: "number",
          required: true,
        },
      });
      setAndValidate("limitType", PromotionLimitType.SINGLE);
    } else {
      handleChangeLimitType();
      setAndValidate("limitPercent", "0");
      setSchemaRules({
        categoryId: {
          type: "number",
        },
        subCategoryId: {
          type: "number",
        },
        otherSubcategoryName: {
          required: false,
        },
      });
    }
  }, [
    handleChangeLimitType,
    currentPromotionType,
    setAndValidate,
    setSchemaRules,
    mainForm.form.values.kind,
  ]);

  useEffect(() => {
    if (form.values.kind === PromotionKind.SPECIAL) {
      setSchemaRules({
        name: {
          required: true,
        },
      });
    }
  }, [form.values.kind, setSchemaRules]);

  useEffect(() => {
    const limitType = form.values.limitType;

    if (limitType !== formPromotionLimitTypeRef.current) {
      formPromotionLimitTypeRef.current = limitType as PromotionLimitType;
      clearErrors(["limitMaxAmount", "limitItemsName", "limitPrice"]);
    }
  }, [clearErrors, form.values.limitType]);

  useEffect(() => {
    if (
      PromotionKind.DEAL === form.values.kind ||
      PromotionKind.GIVEAWAY === form.values.kind
    ) {
      setSchemaRules({
        limitItemsName: {
          ...limitItemsNameBaseRules,
          required: true,
        },
      });
    }

    if (PromotionKind.DEAL === form.values.kind) {
      setSchemaRules({
        limitPercent: { ...percentBasesRules },
      });
    }
    if (
      PromotionKind.VOUCHER === form.values.kind ||
      PromotionKind.SPECIAL === form.values.kind
    ) {
      setSchemaRules({
        limitPercent: {},
        limitItemsName: {},
      });
    }
  }, [form.values.limitType, setSchemaRules, form.values.kind]);

  useEffect(() => {
    if (PromotionKind.VOUCHER === form.values.kind) {
      setSchemaRules({
        limitPrice: {
          isIntegerValueBigger: {
            fieldNameInForm: "rebatedPrice",
            fieldInputLabel:
              dictionary.deals.createDealForm.amountPaidByTheCustomer,
          },
          fieldName:
            dictionary.deals.createDealForm.amountReceivedByTheCustomer,
        },
      });
    }
  }, [form.values.kind, setSchemaRules]);

  const {
    handleChangeFormField,
    clearValueFieldOnClickIfZero,
    handleChangeFormNameField,
  } = useFormHandlers();

  const isEntireOrder = form.values.type === PromotionType.ORDER;

  const handleSwitchChange = (changed: boolean) => {
    setAndValidate("isPlanetPromo", changed ? "1" : "0");
  };

  const renderItem = (item: IPromotionDietaryRestriction) => {
    const handleChange = () => {
      const current = dietaryRestrictions || [];
      const index = dietaryRestrictions?.map((i) => i.id).indexOf(item.id);
      if (current && index !== undefined && index !== -1) {
        current.splice(index, 1);
      } else {
        current.push(item);
      }

      setDietaryRestrictions(current);
      setFormValue(
        "dietaryRestrictions",
        current?.map((item) => item.id).join()
      );
    };

    return (
      <FormControlLabel
        control={
          <OcietyCheckbox
            color={"primary"}
            checked={Boolean(
              dietaryRestrictions?.find((i) => i.name === item.name)
            )}
            onChange={handleChange}
            name={item.name}
          />
        }
        classes={{ label: classes.dietaryLabel }}
        label={item.name}
      />
    );
  };

  return (
    <>
      <div ref={stepInput} />
      <Grid container spacing={4} className={classes.root}>
        {(mainForm.form.values.kind === PromotionKind.DEAL ||
          mainForm.form.values.kind === PromotionKind.GIVEAWAY) &&
          layouts.deal.map(renderPanels)}
        {mainForm.form.values.kind === PromotionKind.SPECIAL &&
          layouts.special.map(renderPanels)}
        {mainForm.form.values.kind === PromotionKind.VOUCHER &&
          layouts.voucher.map(renderPanels)}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <WorkingDaysPicker
            accordionSummary={
              dictionary.deals.createDealForm.usableOnSpecifiedDays
            }
            disableAddNewHoursPair={true}
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.dietaryRestrictionsSection}>
          <Accordion
            defaultExpanded={true}
            className={classes.dietaryRestrictionsContainer}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              className={classes.dietaryRestrictionsHeader}>
              <Typography variant="h3">
                {dictionary.deals.createDealForm.dietaryRestrictionsTitle}
              </Typography>
              <Typography variant="h3" className={classes.textOptionalLabel}>
                {dictionary.deals.createDealForm.optionalLabel}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.dietaryRestrictionsDetails}>
              <Grid
                container
                spacing={3}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.dietaryRestrictionItem}>
                {promotionDietaryRestrictions.map((item) => (
                  <Grid key={item.id} lg={4} md={4} sm={4} xs={6}>
                    {renderItem(item)}
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item container spacing={2}>
          {isDeal && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={classes.iconOctagramGreenWrapper}>
                <IconOctagramGreen
                  style={{ width: 24, height: 24, marginRight: 10 }}
                />
                <Typography
                  variant="h3"
                  style={{
                    marginRight: 16,
                  }}>
                  {dictionary.deals.planetPromoTitle}
                </Typography>
                <OcietyTooltip
                  style={{ whiteSpace: "pre-line" }}
                  title={`${dictionary.deals.planetPromoTooltip} \r\n ${dictionary.deals.planetPromoTooltip2}`}
                  placement="top-start">
                  <InfoIcon
                    className={classes.infoIcon}
                    style={{ marginRight: 16 }}
                  />
                </OcietyTooltip>

                <OcietySwitch
                  value={form.values.isPlanetPromo}
                  checked={form.values.isPlanetPromo === "1"}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => {
                    handleSwitchChange(checked);
                  }}
                />
              </div>
            </Grid>
          )}
          {!isEntireOrder &&
            (form.values.kind === PromotionKind.DEAL ||
              form.values.kind === PromotionKind.GIVEAWAY) && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {isSingleItem ? (
                  <TextField
                    fullWidth
                    label={dictionary.deals.createDealForm.itemsName}
                    name={"limitItemsName"}
                    error={!!form.errors.limitItemsName}
                    helperText={form.errors.limitItemsName}
                    onChange={handleChangeFormNameField}
                    type="text"
                    value={form.values.limitItemsName}
                    variant="outlined"
                    inputRef={
                      errorHasFocus.current && form.errors.limitItemsName
                        ? focusOnError
                        : null
                    }
                  />
                ) : (
                  <TextField
                    fullWidth
                    label={dictionary.deals.createDealForm.maxAmount}
                    name="limitMaxAmount"
                    error={!!form.errors.limitMaxAmount}
                    helperText={form.errors.limitMaxAmount}
                    onChange={handleChangeFormField}
                    onClick={clearValueFieldOnClickIfZero}
                    type="text"
                    value={form.values.limitMaxAmount}
                    variant="outlined"
                  />
                )}
              </Grid>
            )}
          {isEntireOrder && form.values.kind !== PromotionKind.VOUCHER && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                fullWidth
                label={dictionary.deals.createDealForm.rebatedPriceOfOrderField}
                name={"rebatedPrice"}
                error={!!form.errors.rebatedPrice}
                helperText={form.errors.rebatedPrice}
                onChange={handleChangeFormField}
                onClick={clearValueFieldOnClickIfZero}
                className={classes.promotionAmountMarginTop}
                type="text"
                value={form.values.rebatedPrice}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography className={classes.usdSign}>$</Typography>
                    </InputAdornment>
                  ),
                }}
                inputRef={
                  errorHasFocus.current && form.errors.rebatedPrice
                    ? focusOnError
                    : null
                }
              />
            </Grid>
          )}
          {(mainForm.form.values.kind === PromotionKind.DEAL ||
            mainForm.form.values.kind === PromotionKind.GIVEAWAY) && (
            <DealPriceInput
              isEntireOrder={isEntireOrder}
              isSingleItem={isSingleItem}
            />
          )}
          {mainForm.form.values.kind === PromotionKind.SPECIAL && (
            <SpecialPriceInput />
          )}
          {mainForm.form.values.kind === PromotionKind.VOUCHER && (
            <VoucherPriceInput />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MainInfoPromotionStep;
