import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { APP_NAME } from "../../../config";
import { ReactComponent as OcietyLogo } from "../../svg/logo.svg";
import Props from "./Props";
import { useStyles } from "./styles";

const LOGO_WIDTH_DEFAULT = 38;
const LOGO_HEIGHT_DEFAULT = 38;

const Logo: FC<Props> = (props: Props) => {
  const { withText, wrapperClassName, width, height, className } = props;

  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        withText && classes.rootWithText,
        wrapperClassName
      )}>
      <OcietyLogo
        className={clsx(classes.logo, className)}
        width={width || LOGO_WIDTH_DEFAULT}
        height={height || LOGO_HEIGHT_DEFAULT}
      />
      {withText && (
        <Typography className={classes.logoText} variant={"body1"}>
          {APP_NAME}
        </Typography>
      )}
    </div>
  );
};

export default Logo;
