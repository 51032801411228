import { Form } from "../../../../../../../../common/models";
import { ValidationRules } from "../../../../../../../../utils/validation";

export type EditHolidayField = "date" | "name";
export type EditHolidayFormValues = {
  [key in EditHolidayField]: string;
};
export type EditHolidayFormErrors = {
  [key in EditHolidayField]: string;
};

export interface EditHolidayForm extends Form {
  values: EditHolidayFormValues;
  errors: EditHolidayFormErrors;
  isValid: boolean;
  touched: EditHolidayField[];
}

export type ChangePasswordFormSchema = Record<
  EditHolidayField,
  ValidationRules
>;

export const editHolidayForm: EditHolidayForm = {
  values: {
    date: "",
    name: "",
  },
  errors: {
    date: "",
    name: "",
  },
  touched: [],
  isValid: true,
};

export const schema: ChangePasswordFormSchema = {
  date: {
    required: true,
  },
  name: {
    required: true,
    length: {
      min: 2,
      max: 256,
    },
  },
};
