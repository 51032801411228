import { SelectClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const MuiSelect: Partial<Record<SelectClassKey, CSSProperties>> = {
  select: {
    paddingLeft: 20,
  },
};

export default MuiSelect;
