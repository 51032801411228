import { GET_DATA_WITH_PAGINATION } from "../../common/api";
import { http } from "../../config/api";
import { GetAllUnclaimedVenuesResponse } from "./models";

export const ADD_NEW_VENUE = "venue/unclaimed/add";
export const UPDATE_NEW_VENUE = (venueId: number) =>
  `venue/unclaimed/${venueId}`;
export const GET_UNCLAIMED_VENUE = (venueId: number) =>
  `venue/unclaimed/${venueId}`;
export const DELETE_UNCLAIMED_VENUE = (venueId: number) =>
  `venue/unclaimed/${venueId}`;

const UNCLAIMED_VENUES = "venue/unclaimed";

export const getAllUnclaimedVenues = async (
  skip?: number,
  limit?: number,
  search?: string
): Promise<GetAllUnclaimedVenuesResponse> =>
  http
    .get(GET_DATA_WITH_PAGINATION(UNCLAIMED_VENUES, skip, limit, search))
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
