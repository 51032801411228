import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import React, { FC, useState } from "react";

import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const PhoneNumberStep: FC<Props> = (props: Props) => {
  const { onSetPhoneNumber } = props;
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");

  let isPhoneNumberValid = false;

  if (phoneNumber) {
    const match = phoneNumber.match(/\d/g);

    if (match) {
      isPhoneNumberValid = match.length === 10;
    }
  }

  if (isPhoneNumberValid) {
    onSetPhoneNumber(Number(countryCode + phoneNumber));
  }

  return (
    <div>
      <Typography variant="body2" component="span" style={{ marginBottom: 30 }}>
        {dictionary.venueAdmin.profile.mfa.dialogs.sms.text}
      </Typography>
      <form noValidate autoComplete="off">
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">
            {dictionary.venueAdmin.profile.mfa.dialogs.sms.countrySelect}
          </InputLabel>
          <Select
            labelId="country-select-label"
            id="country-select"
            value={countryCode}
            onChange={(ev) => {
              if (ev.target.value) {
                setCountryCode(Number(ev.target.value));
              }
            }}>
            <MenuItem value={1}>+1</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="phone-number"
          type="number"
          label={dictionary.venueAdmin.profile.mfa.dialogs.sms.phoneNumberInput}
          error={!isPhoneNumberValid}
          helperText={
            !isPhoneNumberValid &&
            dictionary.venueAdmin.profile.mfa.dialogs.sms.errorPhoneNumber
          }
          value={phoneNumber}
          onChange={(event) => {
            setPhoneNumber(event.target.value);
          }}
        />
      </form>
    </div>
  );
};

export default PhoneNumberStep;
