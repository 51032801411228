import {
  CART_CLEAR,
  CART_ITEM_ADD,
  CART_ITEM_REMOVE,
  CartClearActionType,
  CartItemAddActionType,
  CartItemRemoveActionType,
} from "./types";

export const cartItemAdd = (
  venueId: number,
  packageId: number
): CartItemAddActionType => ({
  type: CART_ITEM_ADD,
  venueId,
  packageId,
});

export const cartItemRemove = (
  venueId: number,
  packageId: number
): CartItemRemoveActionType => ({
  type: CART_ITEM_REMOVE,
  venueId,
  packageId,
});

export const cartClear = (): CartClearActionType => ({
  type: CART_CLEAR,
});
