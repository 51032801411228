import { Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useRef } from "react";

import dictionary from "../../../../../../../../../../../i18n/en_US/dictionary";
import { PromotionFormDialogContext } from "../../../../../../PromotionFormDialogContextProvider";
import { useFormHandlers } from "../../hooks/useFormHandlers";
import { useStyles } from "../../styles";

export const VoucherPriceInput = () => {
  const classes = useStyles();
  const { mainForm } = useContext(PromotionFormDialogContext);
  const { form, setFormValue, setSchemaRules } = mainForm;
  const errorHasFocus = useRef<boolean>(false);
  const focusOnError = useRef<HTMLDivElement>(null);
  const {
    clearValueFieldOnClickIfZero,
    handleChangeFormField,
    onBlurPriceField,
  } = useFormHandlers();

  useEffect(() => {
    setSchemaRules({
      limitPrice: {
        type: "number+",
        required: true,
        isIntegerValueBigger: {
          fieldNameInForm: "rebatedPrice",
          fieldInputLabel:
            dictionary.deals.createDealForm.amountPaidByTheCustomer,
        },
        fieldName: dictionary.deals.createDealForm.amountReceivedByTheCustomer,
      },
    });
  }, [setSchemaRules]);

  return (
    <>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          fullWidth
          label={dictionary.deals.createDealForm.amountPaidByTheCustomer}
          InputLabelProps={{ style: { pointerEvents: "auto" } }}
          name="rebatedPrice"
          error={!!form.errors.rebatedPrice}
          helperText={form.errors.rebatedPrice}
          className={classes.promotionAmountMarginTop}
          onChange={handleChangeFormField}
          onClick={clearValueFieldOnClickIfZero}
          onBlur={onBlurPriceField("rebatedPrice")}
          type="text"
          value={form.values.rebatedPrice}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography className={classes.usdSign}>$</Typography>
              </InputAdornment>
            ),
          }}
          inputRef={
            errorHasFocus.current && form.errors.rebatedPrice
              ? focusOnError
              : null
          }
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          fullWidth
          label={dictionary.deals.createDealForm.amountReceivedByTheCustomer}
          InputLabelProps={{ style: { pointerEvents: "auto" } }}
          name="limitPrice"
          error={!!form.errors.limitPrice}
          helperText={form.errors.limitPrice}
          className={classes.promotionAmountMarginTop}
          onChange={handleChangeFormField}
          onClick={clearValueFieldOnClickIfZero}
          onBlur={onBlurPriceField("limitPrice")}
          type="text"
          value={form.values.limitPrice}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography className={classes.usdSign}>$</Typography>
              </InputAdornment>
            ),
          }}
          inputRef={
            errorHasFocus.current && form.errors.limitPrice
              ? focusOnError
              : null
          }
        />
      </Grid>
    </>
  );
};
