import { http } from "../../../../config/api";
import IMfaData, { TwoFactoryAuthTypeEnum } from "./IMfaData";

const MFA_SMS_CODE_REQUEST = "profile/mfa";
const MFA_SET_DEFAULT = "profile/mfa/set-default";
const MFA_SMS_DISABLE_REQUEST = "profile/mfa/sms-disable";
const MFA_CALL_DISABLE_REQUEST = "profile/mfa/call-disable";
const MFA_EMAIL_DISABLE_REQUEST = "profile/mfa/email-disable";
const MFA_TOTP_DISABLE_REQUEST = "profile/mfa/totp-disable";

export const getMfa = async (): Promise<IMfaData> =>
  http
    .get(MFA_SMS_CODE_REQUEST)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });

export const disableSmsMFA = async (): Promise<any> =>
  http
    .post(MFA_SMS_DISABLE_REQUEST)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });

export const disableCallMFA = async (): Promise<any> =>
  http
    .post(MFA_CALL_DISABLE_REQUEST)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });

export const disableEmailMFA = async (): Promise<any> =>
  http
    .post(MFA_EMAIL_DISABLE_REQUEST)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });

export const disableTotpMFA = async (): Promise<any> =>
  http
    .post(MFA_TOTP_DISABLE_REQUEST)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });

export const setMfaDefault = async (
  type: TwoFactoryAuthTypeEnum
): Promise<any> =>
  http
    .post(MFA_SET_DEFAULT, { type })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
