import { Typography } from "@material-ui/core";
import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";
import React, { FC } from "react";

import { OcietyTheme } from "../../models";
import Props from "./Props";

const OptionalFieldLabel: FC<Props> = (props: Props) => {
  const { label } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.labelText}>{label}</Typography>
      <Typography className={classes.optionalLabel}> (Optional)</Typography>
    </div>
  );
};

export default OptionalFieldLabel;

type ClassNames = "root" | "labelText" | "optionalLabel";

const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "row",
        gap: 4,
      },
      labelText: {
        color: "#585D6E",
        fontWeight: 600,
      },
      optionalLabel: {
        opacity: 0.5,
      },
    })
);
