import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";
import { colors } from "../../../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "headerTitle"
  | "qrCodeWrapper"
  | "warnText"
  | "helpText"
  | "secretKey";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      headerTitle: {
        textAlign: "center",
        marginTop: 20,
      },
      qrCodeWrapper: {
        margin: "auto",
      },
      warnText: {
        color: "#EB5252",
        fontSize: theme.typography.pxToRem(15),
        margin: theme.spacing(1),
      },
      helpText: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
        color: colors.grey.helpText,
      },
      secretKey: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 600,
        color: colors.grey.primary,
        padding: theme.spacing(2),
      },
    })
);
