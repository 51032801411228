import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "textWrapper"
  | "avatar"
  | "latestMessageText"
  | "deliveredTime"
  | "nameWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        padding: theme.spacing(2, 3, 2, 0),
      },
      textWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        flex: 4,
        overflowWrap: "anywhere",
        marginTop: theme.spacing(1),
      },
      nameWrapper: {
        textAlign: "start",
        [theme.breakpoints.down("md")]: {
          width: "50%",
        },
      },
      avatar: {
        display: "flex",
        justifyContent: "center",
        flex: 1,
        marginRight: theme.spacing(1),
      },
      latestMessageText: {
        textAlign: "left",
        margin: theme.spacing(1, 0, 0, 0),
        maxHeight: 45,
        overflow: "hidden",
      },
      deliveredTime: {
        position: "absolute",
        right: 0,
        paddingRight: theme.spacing(3),
      },
    })
);
