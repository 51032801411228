import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import React, { FC } from "react";

import LoadingIndicator from "../../../../../common/components/LoadingIndicator";
import PhoneNumberInput from "../../../../../common/components/PhoneNumberInput";
import dictionary from "../../../../../i18n/en_US/dictionary";
import PermissionsCheckboxes from "../../PermissionsCheckboxes";
import { useStyles } from "../styles";
import Props from "./Props";

const ClaimVenueForm: FC<Props> = (props: Props) => {
  const {
    claimVenueForm,
    handleChangeClaimForm,
    isWaitlistAdmin,
    setIsWaitlistAdmin,
    loading,
    setIsValidateDisabled,
  } = props;
  const classes = useStyles();

  return (
    <>
      <DialogContent dividers className={classes.root}>
        <form>
          <TextField
            fullWidth
            label={dictionary.venues.claimVenueDialog.firstNameFieldLabel}
            name="firstName"
            error={!!claimVenueForm.errors.firstName}
            helperText={claimVenueForm.errors.firstName}
            onChange={handleChangeClaimForm}
            type="text"
            value={claimVenueForm.values.firstName}
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            label={dictionary.venues.claimVenueDialog.lastNameFieldLabel}
            name="lastName"
            error={!!claimVenueForm.errors.lastName}
            helperText={claimVenueForm.errors.lastName}
            onChange={handleChangeClaimForm}
            className={classes.formField}
            type="text"
            value={claimVenueForm.values.lastName}
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            label={dictionary.venues.claimVenueDialog.companyFieldLabel}
            name="company"
            error={!!claimVenueForm.errors.company}
            helperText={claimVenueForm.errors.company}
            onChange={handleChangeClaimForm}
            className={classes.formField}
            type="text"
            value={claimVenueForm.values.company}
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            label={dictionary.venues.claimVenueDialog.titleFieldLabel}
            name="title"
            error={!!claimVenueForm.errors.title}
            helperText={claimVenueForm.errors.title}
            onChange={handleChangeClaimForm}
            className={classes.formField}
            placeholder={dictionary.venues.claimVenueDialog.titlePlaceholder}
            type="text"
            value={claimVenueForm.values.title}
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            label={dictionary.venues.claimVenueDialog.emailFieldLabel}
            name="email"
            error={!!claimVenueForm.errors.email}
            helperText={claimVenueForm.errors.email}
            onChange={handleChangeClaimForm}
            className={classes.formField}
            type="text"
            value={claimVenueForm.values.email}
            variant="outlined"
            required
          />
          <PhoneNumberInput
            value={claimVenueForm.values.phoneNumber}
            onChange={handleChangeClaimForm}
            className={classes.formField}
            name="phoneNumber"
            error={claimVenueForm.errors.phoneNumber}
            label={
              dictionary.venues.claimVenueDialog.locationPhoneNumberFieldLabel
            }
          />
          <TextField
            fullWidth
            label={dictionary.venues.claimVenueDialog.commentFieldLabel}
            name="comment"
            multiline
            rows={3}
            rowsMax={5}
            error={!!claimVenueForm.errors.comment}
            helperText={claimVenueForm.errors.comment}
            onChange={handleChangeClaimForm}
            className={classes.formField}
            type="textarea"
            value={claimVenueForm.values.comment}
            variant="outlined"
          />
          <FormControlLabel
            style={{ display: "none" }} // TODO: make checkbox visible
            control={
              <Checkbox
                checked={isWaitlistAdmin}
                onChange={(event) => setIsWaitlistAdmin(event.target.checked)}
                name="isWaitlistAdmin"
                color="primary"
              />
            }
            label={dictionary.venues.claimVenueDialog.includeWaitlist}
          />
          <div className={classes.permissionsContainer}>
            <PermissionsCheckboxes
              setIsValidateDisabled={setIsValidateDisabled}
            />
          </div>
        </form>
        {loading && <LoadingIndicator withMask />}
      </DialogContent>
    </>
  );
};

export default ClaimVenueForm;
