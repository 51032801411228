import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartTooltip from "../../../../../../common/components/ChartTooltip";
import EmptyChart from "../../../../../../common/components/EmptyChart";
// eslint-disable-next-line max-len
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import LockedChartMask from "../../../../../../common/components/LockedChartMask";
import { VenueReportingContext } from "../../../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../../../common/hooks/useRequestByPeriod";
import { useVenueHasPackage } from "../../../../../../common/hooks/useVenueHasPacakage";
import { Gender, Gender as GenderType } from "../../../../../../common/models";
import { useGlobalStyles } from "../../../../../../common/styles";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { PackageUID } from "../../../../../../store/currentVenue/types";
import {
  chartAdditionalColor,
  chartSecondaryColor,
} from "../../../../../../theme/colors";
import { getBarFillColor } from "../../../../../../utils/chartUtils";
import { YAxisDefaultProps } from "../../../../defaultProps";
import { useStyles } from "../../../../styles";
import {
  GET_VENUE_VIEWS_AGE_GENDER,
  GetVenueViewsAgeGenderRequest,
  GetVenueViewsAgeGenderResponse,
} from "./api";
import { chartDataMock } from "./mock";
import { FilterType } from "./models";

const Age: FC = () => {
  const [chartData, setChartData] = useState(chartDataMock);
  const [filterValue, setFilter] = useState<FilterType>("all");
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { handlePackageName } = useContext(VenueReportingContext);

  const getGenderFromFilter = useCallback((): GenderType | undefined => {
    switch (filterValue) {
      case "all": {
        return undefined;
      }
      case "female": {
        return GenderType.FEMALE;
      }
      case "non-binary": {
        return GenderType.NON_BINARY;
      }
      case "male": {
        return Gender.MALE;
      }
      case "unknown": {
        return Gender.UNKNOWN;
      }
    }
  }, [filterValue]);

  const genderData = useMemo(
    () => ({ gender: getGenderFromFilter() }),
    [getGenderFromFilter]
  );
  const hasPackage = useVenueHasPackage(PackageUID.CUSTOMER);
  const { response, loading } = useRequestByPeriod<
    GetVenueViewsAgeGenderResponse,
    GetVenueViewsAgeGenderRequest
  >(GET_VENUE_VIEWS_AGE_GENDER, genderData, hasPackage);

  useEffect(() => {
    if (response) {
      setChartData(response.stats);
    }
    if (!hasPackage) {
      setChartData(chartDataMock);
    }
  }, [response, hasPackage]);

  const getLabelClassName = (value: FilterType) =>
    filterValue === value ? "checked" : "";

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value as FilterType);
  };

  const isChartEmpty = !chartData.some((data) => data.value);

  return (
    <Paper className={globalClasses.chartWrapper}>
      <div className={classes.root}>
        {loading && <LoadingIndicator withMask />}
        {!hasPackage && (
          <LockedChartMask
            withMask
            packageName={handlePackageName(PackageUID.CUSTOMER)}
          />
        )}
        <div className={globalClasses.flexRowBetweenCenter}>
          <Typography variant={"h3"} className={classes.title}>
            {dictionary.reporting.venueViewsAgeTitle}
          </Typography>
        </div>
        {isChartEmpty ? (
          <EmptyChart chartType={"bar-with-y-axis"} isChartSmall />
        ) : (
          <>
            <FormControl
              component="fieldset"
              className={classes.filterContainer}>
              <RadioGroup
                classes={{
                  root: clsx(
                    globalClasses.flexRowEndCenter,
                    classes.filterWrapper
                  ),
                }}
                aria-label="gender-filter"
                name="gender_filter"
                value={filterValue}
                onChange={handleChangeFilter}>
                <FormControlLabel
                  classes={{
                    label: clsx(classes.filterLabel, getLabelClassName("all")),
                  }}
                  value="all"
                  control={<Radio className={classes.filterRadio} />}
                  label={dictionary.reporting.ageFilterAll}
                />
                <FormControlLabel
                  classes={{
                    label: clsx(classes.filterLabel, getLabelClassName("male")),
                  }}
                  value="male"
                  control={<Radio className={classes.filterRadio} />}
                  label={dictionary.reporting.ageFilterMale}
                />
                <FormControlLabel
                  classes={{
                    label: clsx(
                      classes.filterLabel,
                      getLabelClassName("female")
                    ),
                  }}
                  value="female"
                  control={<Radio className={classes.filterRadio} />}
                  label={dictionary.reporting.ageFilterFemale}
                />
                <FormControlLabel
                  classes={{
                    label: clsx(
                      classes.filterLabel,
                      getLabelClassName("non-binary")
                    ),
                  }}
                  value="non-binary"
                  control={<Radio className={classes.filterRadio} />}
                  label={dictionary.reporting.ageFilterNonBinary}
                />
              </RadioGroup>
            </FormControl>
            <div
              style={{
                display: "flex",
                flex: 1,
                height: 225,
                flexDirection: "column",
                justifyContent: "center",
                margin: "20px 0",
              }}>
              <ResponsiveContainer>
                <BarChart
                  layout={"vertical"}
                  barCategoryGap={3}
                  data={chartData}>
                  <XAxis type="number" hide />
                  <YAxis
                    {...YAxisDefaultProps}
                    type="category"
                    dataKey="ageRange"
                    width={70}
                  />
                  <Bar
                    unit={"%"}
                    radius={[0, 2, 2, 0]}
                    dataKey={"value"}
                    fill={chartSecondaryColor}>
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={getBarFillColor(chartData, index, "value")}
                      />
                    ))}
                  </Bar>
                  <Tooltip
                    content={<ChartTooltip />}
                    cursor={{
                      stroke: chartAdditionalColor,
                      strokeWidth: 2,
                      opacity: 0.3,
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </>
        )}
      </div>
    </Paper>
  );
};

export default Age;
