import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { getRouteWithSlash } from "../../../config/router";
import { ReactComponent as BackIcon } from "../../svg/icons/back-icon-red.svg";
import Props from "./Props";
import { useStyles } from "./styles";

const BackButton: FC<Props> = (props: Props) => {
  const { to } = props;
  const classes = useStyles();
  const history = useHistory();

  const handlePressBack = () => {
    if (to === "go-back") {
      history.goBack();
    } else history.push(getRouteWithSlash(to));
  };

  return <BackIcon onClick={handlePressBack} className={classes.root} />;
};
export default BackButton;
