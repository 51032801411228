import { VenueType } from "../../../../common/models";
import { http } from "../../../../config/api";

const VENUE_TYPES = `venue/types`;

export const getVenueTypes = async (): Promise<{
  bar: VenueType[];
  restaurant: VenueType[];
} | null> =>
  http
    .get(VENUE_TYPES)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });
