import { Tab, Tabs } from "@material-ui/core";
import React, { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMessaging } from "../../../../common/hooks/useMessaging";
import { setActiveChat } from "../../../../store/messaging/actions";
import { AppState } from "../../../../store/rootReducer";
import { ChatDataItem } from "../../models";
import ChatWindow from "../ChatWindow";
import DialogItemTab from "./components/DialogItemTab";
import DialogListEmpty from "./components/DialogListEmpty";
import { useStyles } from "./styles";

const DialogListTabs: FC = () => {
  const isMounted = useRef(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeChatWindow = useSelector<AppState, number>(
    ({ messaging }) => messaging.activeChat
  );
  const { markAsRead, pushNewMessageToChats } = useMessaging(activeChatWindow);

  const chatDataWithReply = useSelector<AppState, Array<ChatDataItem>>(
    ({ messaging }) => messaging.chatData
  );

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    dispatch(setActiveChat(newValue));
  };

  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    if (!isMounted.current) {
      // Mark first chat messages as read
      markAsRead();
      // To avoid infinity effect call
      isMounted.current = true;
    }
  }, [markAsRead]);

  return (
    <div className={classes.root}>
      {chatDataWithReply.length > 0 ? (
        <div className={classes.container}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            indicatorColor="primary"
            value={activeChatWindow}
            onChange={handleChange}
            TabIndicatorProps={{
              className: classes.tabsIndicator,
            }}
            className={classes.tabs}>
            {chatDataWithReply.map((chat, index) => (
              <Tab
                key={chat.id}
                icon={<DialogItemTab data={chat} />}
                {...a11yProps(index)}
                onClick={() => {
                  markAsRead(chat.id, index);
                }}
                className={classes.tabsDivider}
              />
            ))}
          </Tabs>
          {chatDataWithReply.map((chat, idx) => (
            <ChatWindow
              value={activeChatWindow}
              index={idx}
              data={chat}
              key={chat.id}
              addNewMessage={pushNewMessageToChats}
            />
          ))}
        </div>
      ) : (
        <DialogListEmpty />
      )}
    </div>
  );
};

export default DialogListTabs;
