import "../../../../../../common/scss/slick.scss";

import clsx from "clsx";
import React, { FC } from "react";
import Slider, { Settings } from "react-slick";

import { useIsManager } from "../../../../../../common/hooks/useIsManager";
import { useIsSuperAdmin } from "../../../../../../common/hooks/useIsSuperAdmin";
import {
  FileUrlWithId,
  VenuePhoto,
  VenueVideo,
} from "../../../../../../common/models";
import { ReactComponent as ArrowRightIcon } from "../../../../../../common/svg/icons/carousel-arrow.svg";
import { MediaEntityType } from "./MediaEntityType";
import Props from "./Props";
import { useStyles } from "./styles";
import VenueMediaCarouselPhotoSlide from "./VenueMediaCarouselPhotoSlide";
import VenueMediaCarouselVideoSlide from "./VenueMediaCarouselVideoSlide";

const VenueMediaCarousel: FC<Props> = (props: Props) => {
  const {
    venuePhotos,
    venueVideos,
    promotionPhotos,
    promotionVideos,
    onDeletePhoto,
    onDeleteVideo,
    onMainPhotoChange,
  } = props;

  const classes = useStyles();
  const sliderRef = React.useRef<Slider>(null);

  const superAdmin = useIsSuperAdmin();
  const manager = useIsManager();
  const renderPhotoSlide = (
    photo: VenuePhoto | FileUrlWithId,
    type: MediaEntityType
  ) =>
    onDeletePhoto ? (
      <VenueMediaCarouselPhotoSlide
        {...photo}
        onDeletePhotoPromotion={onDeletePhoto}
        onChangeMainPhotoPromotion={onMainPhotoChange}
        type={type}
      />
    ) : (
      <VenueMediaCarouselPhotoSlide {...photo} type={type} />
    );

  const renderVideoSlide = (
    video: VenueVideo | FileUrlWithId,
    type: MediaEntityType
  ) =>
    onDeleteVideo ? (
      <VenueMediaCarouselVideoSlide
        {...video}
        onDeleteVideoPromotion={onDeleteVideo}
        type={type}
      />
    ) : (
      <VenueMediaCarouselVideoSlide {...video} type={type} />
    );

  const sortMediaByActiveState = (a: any, b: any) =>
    a.props.active === b.props.active ? 0 : a.props.active ? 1 : -1;

  const next = () => {
    sliderRef?.current?.slickNext();
  };

  const previous = () => {
    sliderRef?.current?.slickPrev();
  };

  const settings: Settings = {
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    // eslint-disable-next-line react/display-name
    appendDots: (dots) => (
      <div
        style={{
          position: "relative",
          bottom: "auto",
        }}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    speed: 500,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const shouldRenderArrows = () => {
    if (settings) {
      const screenWidth = window.innerWidth;
      const slidesToShow = settings?.responsive?.find((breakpoint) => {
        if (screenWidth < breakpoint.breakpoint) {
          // @ts-ignore
          return breakpoint.settings.slidesToShow;
        }
        // @ts-ignore
      })?.settings.slidesToShow;

      return (
        (promotionVideos &&
          promotionPhotos &&
          promotionPhotos.concat(promotionVideos).length > slidesToShow) ||
        (venueVideos &&
          venuePhotos &&
          venuePhotos.concat(venueVideos)?.length > slidesToShow)
      );
    }
  };

  const renderPromotionArrows = () => {
    return (
      <div
        className={classes.arrowWrapper}
        style={{ marginTop: isArrowsVisible ? -30 : 0 }}>
        {isArrowsVisible && (
          <>
            <div
              className={clsx(classes.arrowLeft, classes.arrow)}
              onClick={previous}>
              <ArrowRightIcon />
            </div>
            <div className={classes.arrow} onClick={next}>
              <ArrowRightIcon />
            </div>
          </>
        )}
      </div>
    );
  };

  const renderVenueArrows = () => {
    return (
      <div
        className={classes.arrowWrapper}
        style={{ marginTop: isArrowsVisible ? 0 : 40 }}>
        {isArrowsVisible && (
          <>
            <div
              className={clsx(classes.arrowLeft, classes.arrow)}
              onClick={previous}>
              <ArrowRightIcon />
            </div>
            <div className={classes.arrow} onClick={next}>
              <ArrowRightIcon />
            </div>
          </>
        )}
      </div>
    );
  };

  const isArrowsVisible = shouldRenderArrows();

  return (
    <div className={classes.root}>
      <div>
        {venuePhotos && venueVideos && (
          <>
            {renderVenueArrows()}
            <Slider ref={sliderRef} {...settings}>
              {superAdmin || manager
                ? [
                    ...venuePhotos.map((item) =>
                      renderPhotoSlide(item, "venue")
                    ),
                    ...venueVideos.map((item) =>
                      renderVideoSlide(item, "venue")
                    ),
                  ].sort(sortMediaByActiveState)
                : [
                    ...venuePhotos.map((item) =>
                      renderPhotoSlide(item, "venue")
                    ),
                    ...venueVideos.map((item) =>
                      renderVideoSlide(item, "venue")
                    ),
                  ]}
            </Slider>
          </>
        )}
        {promotionPhotos && promotionVideos && (
          <>
            {renderPromotionArrows()}
            <Slider ref={sliderRef} {...settings}>
              {[
                ...promotionPhotos.map((item) =>
                  renderPhotoSlide(item, "promotion")
                ),
                ...promotionVideos.map((item) =>
                  renderVideoSlide(item, "promotion")
                ),
              ]}
            </Slider>
          </>
        )}
      </div>
    </div>
  );
};

export default VenueMediaCarousel;
