import { Dialog, useMediaQuery } from "@material-ui/core";
import React, { FC, useContext } from "react";

import { OcietyTheme } from "../../../../common/models";
import { PromoCodesContext } from "../../providers";
import Actions from "./components/Actions";
import Content from "./components/Content";
import Title from "./components/Title";
import DialogContextProvider, { DialogContext } from "./DialogContextProvider";
import Props from "./Props";

const DialogCreateNewCoupon: FC<Props> = (props: Props) => {
  const { updateListCoupon } = props;
  const { resetAndCloseForm } = useContext(DialogContext);
  const { formDialogOpen } = useContext(PromoCodesContext);

  const isMobile = useMediaQuery((theme: OcietyTheme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <DialogContextProvider>
      <Dialog
        open={formDialogOpen}
        aria-labelledby="promo-code-form"
        onClose={resetAndCloseForm}
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen={isMobile}
        maxWidth={isMobile ? false : "sm"}>
        <Title />
        <Content />
        <Actions updateListCoupon={updateListCoupon} />
      </Dialog>
    </DialogContextProvider>
  );
};

export default DialogCreateNewCoupon;
