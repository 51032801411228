import { createStyles } from "@material-ui/core";
import { ClassNameMap, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type UploadPhotoClassNames =
  | "renderFileProgressContainer"
  | "renderFileProgressContent"
  | "renderFileProgressName"
  | "renderFileUploadedContainer"
  | "iconTrashBucketTransparent"
  | "renderFileUploadedContent"
  | "renderFileUploadedOriginalName"
  | "iconCircleCheckboxFilled"
  | "textSizeBytes";

export const useStyles: () => ClassNameMap<UploadPhotoClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      renderFileProgressContainer: {
        display: "flex",
        marginTop: 16,
        position: "relative",
        padding: 16,
        border: "1px solid #E5E7EA",
        borderRadius: 4,
      },
      renderFileProgressContent: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
      },
      renderFileProgressName: {
        fontWeight: 600,
        fontSize: 14,
        color: "#37393F",
        display: "flex",
        alignItems: "center",
      },
      renderFileUploadedContainer: {
        display: "flex",
        marginTop: 16,
        position: "relative",
        padding: 16,
        border: "1px solid #FE5454",
        borderRadius: 4,
      },
      iconTrashBucketTransparent: {
        width: 20,
        height: 20,
        position: "absolute",
        right: 15,
        top: 15,
        cursor: "pointer",
      },
      renderFileUploadedContent: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
      },
      renderFileUploadedOriginalName: {
        fontWeight: 600,
        fontSize: 14,
        color: "#37393F",
        display: "flex",
        alignItems: "center",
      },
      iconCircleCheckboxFilled: { width: 16, height: 16, marginLeft: 5 },
      textSizeBytes: { fontWeight: 400, fontSize: 14, color: "#747982" },
    })
);
