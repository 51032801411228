import { Paper } from "@material-ui/core";
import React, { FC } from "react";

import PageTitle from "../../common/components/PageTitle";
import dictionary from "../../i18n/en_US/dictionary";
import TransactionTable from "./components/MoneyFlowTable";
import TransactionsContextProvider from "./providers";
import { useStyles } from "./styles";

const MoneyFlow: FC = () => {
  const classes = useStyles();

  return (
    <TransactionsContextProvider>
      <div className={classes.root}>
        <PageTitle>{dictionary.moneyForm.pageTitle}</PageTitle>
        <Paper className={classes.content}>
          <TransactionTable />
        </Paper>
      </div>
    </TransactionsContextProvider>
  );
};

export default MoneyFlow;
