import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import clsx from "clsx";
import React, { FC } from "react";

import LoadingIndicator from "../../../../../common/components/LoadingIndicator";
import { TwoFactoryAuthTypeEnum } from "../../../../../store/auth/types";
import { useStyles } from "../styles";
import Props from "./Props";

const ConfirmationMethod: FC<Props> = (props: Props) => {
  const { loading, setSelectedValue, selectedValue } = props;

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <>
      <DialogContent dividers className={classes.root}>
        <form>
          <RadioGroup
            aria-label="confirmation-method"
            name="confirmation-method"
            value={selectedValue}
            className={classes.radioGroupWrapper}
            onChange={handleChange}>
            <FormControlLabel
              value={TwoFactoryAuthTypeEnum.EMAIL}
              control={
                <Radio checkedIcon={<span className={classes.checkedIcon} />} />
              }
              label="Email"
              className={clsx(
                classes.radioLabel,
                selectedValue === TwoFactoryAuthTypeEnum.EMAIL &&
                  classes.radioChecked
              )}
            />
            <FormControlLabel
              value={TwoFactoryAuthTypeEnum.CALL}
              control={
                <Radio checkedIcon={<span className={classes.checkedIcon} />} />
              }
              label="Phone Number"
              className={clsx(
                classes.radioLabel,
                selectedValue === TwoFactoryAuthTypeEnum.CALL &&
                  classes.radioChecked
              )}
            />
          </RadioGroup>
        </form>
        {loading && <LoadingIndicator withMask />}
      </DialogContent>
    </>
  );
};

export default ConfirmationMethod;
