export const GET_MOST_POPULAR_NEARBY_VENUES = "reporting/popular-nearby-venues";

export interface PopularNearbyVenuesData {
  month: string;
  first: number;
  second: number;
  third: number;
}

export interface GetPopularNearbyVenuesResponse {
  stats: PopularNearbyVenuesData[];
  firstVenueName: string;
  secondVenueName: string;
  thirdVenueName: string;
}
