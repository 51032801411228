import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import React, { FC, useState } from "react";

import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const PhoneStep: FC<Props> = (props: Props) => {
  const { onSetPhoneNumber } = props;
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");

  const isPhoneNumberValid = (phonenumber: string) => {
    const match = phonenumber.match(/\d/g);
    if (match) {
      return match.length === 10;
    }
    return false;
  };

  if (isPhoneNumberValid(phoneNumber)) {
    onSetPhoneNumber(Number(countryCode + phoneNumber));
  }

  return (
    <div>
      <Typography variant="body2" component="span" style={{ marginBottom: 30 }}>
        {dictionary.venueAdmin.profile.mfa.dialogs.call.text}
      </Typography>
      <form noValidate autoComplete="off">
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">
            {dictionary.venueAdmin.profile.mfa.dialogs.call.countrySelect}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={countryCode}
            onChange={(ev) => {
              if (ev.target.value) {
                setCountryCode(Number(ev.target.value));
              }
            }}>
            <MenuItem value={1}>+1</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="phone-number"
          type="number"
          label={
            dictionary.venueAdmin.profile.mfa.dialogs.call.phoneNumberLabel
          }
          error={!isPhoneNumberValid(phoneNumber)}
          helperText={
            !isPhoneNumberValid(phoneNumber) &&
            dictionary.venueAdmin.profile.mfa.dialogs.call.errorPhoneNumber
          }
          value={phoneNumber}
          onChange={(event: any) => {
            // @ts-ignore
            setPhoneNumber(event.target.value);
          }}
        />
      </form>
    </div>
  );
};

export default PhoneStep;
