import { Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

// eslint-disable-next-line max-len
import ActionsDropdown from "../../../../../../../../common/components/ActionsDropdown";
// eslint-disable-next-line max-len
import { ActionItemProps } from "../../../../../../../../common/components/ActionsDropdown/Props";
import { getFormattedDate } from "../../../../../../../../utils/dateTimeUtils";
import Props from "./Props";
import { useStyles } from "./styles";

const HolidaysListRow: FC<Props> = (props: Props) => {
  const { holiday, setCurrentHoliday, deleteHoliday } = props;
  const { endTime, name, id, startTime } = holiday;

  const classes = useStyles();

  const getDate = () => {
    return startTime === endTime
      ? getFormattedDate(startTime)
      : getFormattedDate(startTime) + "-" + getFormattedDate(endTime);
  };

  const actions: ActionItemProps[] = [
    {
      iconName: "editAction",
      label: "Edit",
      onClick: () => setCurrentHoliday(holiday),
    },
    {
      iconName: "removeAction",
      label: "Remove",
      onClick: () => deleteHoliday(id),
    },
  ];

  return (
    <Grid container xs={12} component={Paper} className={classes.root}>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={6}
        className={clsx(classes.rowItem, classes.rowItemFirst)}>
        <Typography className={classes.rowItemText}>{name}</Typography>
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={4} className={classes.rowItem}>
        <Typography variant={"body2"}>{getDate()}</Typography>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        sm={2}
        xs={2}
        className={clsx(classes.rowItem, classes.rowItemLast)}>
        <ActionsDropdown items={actions} />
      </Grid>
    </Grid>
  );
};

export default HolidaysListRow;
