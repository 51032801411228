import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import {
  FOOTER_HEIGHT,
  FOOTER_HEIGHT_MOBILE,
} from "../../common/components/Footer";
import { HEADER_HEIGHT } from "../../common/components/Topbar";
import { OcietyTheme } from "../../common/models";

type ClassNames =
  | "root"
  | "container"
  | "commonWrapper"
  | "footer"
  | "content"
  | "sidebar"
  | "mobile"
  | "noSidebar"
  | "managerPromotions";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        height: "auto",
        display: "flex",
        flex: 1,
        maxWidth: "100%",
      },
      container: {
        height: `calc(100vh - ${HEADER_HEIGHT})`,
        overflow: "auto",
      },
      content: {
        position: "relative",
        padding: "16px 24px 51px",
        [theme.breakpoints.down("sm")]: {
          minHeight: `calc(100% - ${FOOTER_HEIGHT_MOBILE})`,
        },
        [theme.breakpoints.up("sm")]: {
          minHeight: `calc(100% - ${FOOTER_HEIGHT})`,
        },
      },
      commonWrapper: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100vh",
        maxWidth: "100%",
      },
      footer: {
        width: "100%",
      },
      mobile: {
        maxWidth: "100%",
        width: "100%",
      },
      sidebar: {
        width: "calc(100% - 290px)",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      noSidebar: {
        width: "calc(100% - 82px)",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      managerPromotions: {
        width: "100%",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    })
);
