import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../common/models";

export type ClassNames =
  | "textField"
  | "manager"
  | "select"
  | "deleteIcon"
  | "actions"
  | "selectedItems"
  | "formControl"
  | "actionsFormControl";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginLeft: 30,
        marginRight: 30,
        paddingBottom: theme.spacing(2),
      },
      textField: {
        marginBottom: theme.spacing(2),
      },
      manager: {
        marginBottom: 10,
        padding: theme.spacing(1.5, 2),
        display: "flex",
        flexDirection: "row",
        height: 110,
        width: "100%",
      },
      select: {
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(2),
        width: "100%",
      },
      deleteIcon: {
        "& > path": {
          fill: "white",
        },
        width: 20,
      },
      actions: {
        maxWidth: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(2.5, 0.5, 3, 1),
      },
      selectedItems: {
        display: "flex",
        justifyContent: "center",
      },
      formControl: {
        width: "90%",
        padding: theme.spacing(2, 1),
      },
      actionsFormControl: {
        width: "90%",
      },
    })
);
