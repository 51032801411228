import { FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import React, { FC } from "react";

import SelectIcon from "../../../../common/components/SelectIcon";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  VenueRequestType,
  VenuesChangesSelectFilterOption,
} from "../../models";
import { useStyles } from "./classes";
import Props from "./Props";

const options: VenuesChangesSelectFilterOption[] = [
  {
    value: VenueRequestType.ALL,
    label: "All",
  },
  {
    value: VenueRequestType.ADD,
    label: "Add",
  },
  {
    value: VenueRequestType.CLAIM,
    label: "Claim",
  },
  {
    value: VenueRequestType.CHANGE,
    label: "Change",
  },
];

const VenuesRequestsTableFilter: FC<Props> = (props) => {
  const { value, onChangeFilter } = props;
  const classes = useStyles();

  const changeCurrentVenue = (e: any) => {
    if (!options) {
      return;
    }

    for (const currentPeriod of options) {
      if (currentPeriod.value === e.target.value) {
        onChangeFilter(e.target.value);
        break;
      }
    }
  };

  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.root, "select-no-legend")}>
      <Select
        IconComponent={(iconProps) => <SelectIcon {...iconProps} />}
        classes={{ select: classes.select }}
        className={classes.selectWrapper}
        value={value}
        label={dictionary.venues.ownVenues.venueSelectPlaceholder}
        onChange={changeCurrentVenue}>
        {options.map((periodOption) => (
          <MenuItem key={periodOption.value} value={periodOption.value}>
            {periodOption.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default VenuesRequestsTableFilter;
