import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import {
  HeadCell,
  Order,
  TableTitle,
  TransactionFiltersOptions,
  TransactionItem,
  TransactionsAllFilterParams,
} from "../models";

const filtersOptionsInitialState: TransactionFiltersOptions = {
  userEmail: [],
  venueName: [],
  discountName: [],
  referralCode: [],
  promoCodeName: [],
  fiatAmount: [],
  creditsAmount: [],
  couponAmount: [],
  fee: [],
  discountRebatedPrice: [],
};

const searchDefault: TransactionsAllFilterParams = {
  userEmail: [],
  referralCode: [],
  promoCodeName: [],
  discountName: [],
  venueName: [],
  type: [],
  kind: [],
  createdAt: { from: undefined, to: undefined },
  fiatAmount: [],
  creditsAmount: [],
  couponAmount: [],
  fee: [],
  discountRebatedPrice: [],
};

interface Props {
  children?: React.ReactElement;
}

interface PromoCodesContext {
  filterOptions: TransactionFiltersOptions;
  setFilterOptions: Dispatch<SetStateAction<TransactionFiltersOptions>>;
  transactionsList: Partial<TransactionItem>[];
  setTransactionsList: Dispatch<SetStateAction<Partial<TransactionItem>[]>>;
  transactionsCount: number;
  setTransactionsCount: Dispatch<SetStateAction<number>>;
  order: Order;
  setOrder: Dispatch<SetStateAction<Order>>;
  orderBy: keyof TransactionItem;
  setOrderBy: Dispatch<SetStateAction<keyof TransactionItem>>;
  headCellsState: HeadCell[];
  setHeadCellsState: Dispatch<SetStateAction<HeadCell[]>>;
  searchFilters: TransactionsAllFilterParams;
  setSearchFilters: Dispatch<SetStateAction<TransactionsAllFilterParams>>;
  tableLoading: boolean;
  setTableLoading: Dispatch<SetStateAction<boolean>>;
}

export const TransactionsContext = React.createContext<PromoCodesContext>({
  filterOptions: filtersOptionsInitialState,
  setFilterOptions: () => undefined,
  transactionsList: [],
  setTransactionsList: () => undefined,
  transactionsCount: 0,
  setTransactionsCount: () => undefined,
  order: "DESC",
  setOrder: () => undefined,
  orderBy: "createdAt",
  setOrderBy: () => undefined,
  headCellsState: [],
  setHeadCellsState: () => undefined,
  searchFilters: searchDefault,
  setSearchFilters: () => undefined,
  tableLoading: true,
  setTableLoading: () => undefined,
});

const defaultOpenedColumns: Partial<keyof TransactionItem>[] = [
  "createdAt",
  "userEmail",
  "type",
  "fiatAmount",
  "venueName",
];

const TransactionsContextProvider: FC<Props> = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState<TransactionFiltersOptions>(
    filtersOptionsInitialState
  );
  const [transactionsList, setTransactionsList] = useState<
    Partial<TransactionItem>[]
  >([]);
  const [transactionsCount, setTransactionsCount] = useState<number>(0);
  const [order, setOrder] = useState<Order>("DESC");
  const [orderBy, setOrderBy] = useState<keyof TransactionItem>("createdAt");
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [searchFilters, setSearchFilters] =
    useState<TransactionsAllFilterParams>(searchDefault);

  const titles: TableTitle = Object.assign({}, dictionary.moneyForm.tableTitle);
  const previouslyCheckedColumns = localStorage.getItem(
    "money-flow-selected-table-column"
  );
  //@ts-ignore
  const titleEntries: Array<[keyof TableTitle, string]> =
    Object.entries(titles);
  const headCellsDefault: HeadCell[] = titleEntries.map(([id, label]) => {
    return {
      id,
      label,
      isSelected: defaultOpenedColumns.includes(id),
    };
  });
  const headCellsSaved = previouslyCheckedColumns
    ? JSON.parse(previouslyCheckedColumns)
    : headCellsDefault;
  const [headCellsState, setHeadCellsState] =
    useState<HeadCell[]>(headCellsSaved);

  useEffect(() => {
    localStorage.removeItem("money-flow-selected-table-column");
    localStorage.setItem(
      "money-flow-selected-table-column",
      JSON.stringify(headCellsState)
    );
  }, [headCellsState]);

  return (
    <TransactionsContext.Provider
      value={{
        filterOptions,
        setFilterOptions,
        transactionsList,
        setTransactionsList,
        transactionsCount,
        setTransactionsCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        headCellsState,
        setHeadCellsState,
        searchFilters,
        setSearchFilters,
        tableLoading,
        setTableLoading,
      }}>
      {children}
    </TransactionsContext.Provider>
  );
};

export default TransactionsContextProvider;
