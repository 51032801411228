import { http } from "../../../../../config/api";
import ICreateRoleRequest from "./models/ICreateRoleRequest";

const CREATE_ROLE = "rbac/role";

export const createRole = async (createRoleRequest: ICreateRoleRequest) =>
  http
    .post(CREATE_ROLE, createRoleRequest)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });
