import { IconButton, Typography } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../store/rootReducer";
import { PackageUID } from "../../../../views/Plan/models";
import ButtonPrimary from "../../ButtonPrimary";
import OcietyIcon from "../../OcietyIcon";
import Props from "./Props";
import { useStyles } from "./styles";

const WIDGET_NAME = "upgradePlan";

const UpgradePlanWidget: FC<Props> = (props: Props) => {
  const { isSidebarOpen } = props;
  const { currentVenue } = useSelector<AppState, AppState>((state) => state);
  const classes = useStyles();
  const history = useHistory();
  const [{ hiddenWidgets }, setCookie] = useCookies([WIDGET_NAME]);
  const hasPurchasedPackages = !!currentVenue.currentVenue?.packagesUid.filter(
    (uid) => uid !== PackageUID.BASIC
  ).length;
  const [hidden, setHidden] = useState(
    hasPurchasedPackages ||
      (hiddenWidgets && hiddenWidgets.includes(WIDGET_NAME))
  );

  useEffect(() => {
    setHidden(hasPurchasedPackages);
  }, [hasPurchasedPackages]);

  const onButtonPress = () => {
    history.push(Route.Plan);
  };

  if (!isSidebarOpen || hidden) {
    return null;
  }

  const hideWidget = () => {
    setCookie(
      "hiddenWidgets",
      hiddenWidgets ? [...hiddenWidgets, WIDGET_NAME] : [WIDGET_NAME],
      {
        maxAge: 60 * 60 * 24, // 1 day
      }
    );
    setHidden(true);
  };

  return (
    <div className={classes.root}>
      <IconButton className={classes.closeButton} onClick={hideWidget}>
        <OcietyIcon
          className={classes.closeIcon}
          name={"close"}
          viewBox={"0 0 12 12"}
        />
      </IconButton>
      <Typography className={classes.title}>
        {dictionary.sidebar.upgradeTitle}
      </Typography>
      <Typography variant={"body2"} className={classes.description}>
        {dictionary.sidebar.upgradeDescription}
      </Typography>
      <ButtonPrimary onClick={onButtonPress}>
        {dictionary.sidebar.upgradeButton}
      </ButtonPrimary>
    </div>
  );
};

export default UpgradePlanWidget;
