import { Paper } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import StatisticsBlock from "../StatisticsBlock";
import Props from "./Props";
import { useStyles } from "./styles";

const StatisticsBlockLong: FC<Props> = (props: Props) => {
  const { statistics, loading } = props;
  const classes = useStyles();

  const RenderBlockContent = ({
    score,
    description,
    difference,
  }: {
    score: number;
    description: string;
    difference: number;
  }) => {
    return (
      <div className={classes.blockContentWrapper}>
        <StatisticsBlock
          description={description}
          score={score}
          difference={difference}
        />
      </div>
    );
  };

  return (
    <Paper className={clsx(classes.root)}>
      {loading && <LoadingIndicator withMask />}
      <RenderBlockContent
        score={statistics.userReviews.value}
        description={dictionary.reviews.statistics.userReviews}
        difference={statistics.userReviews.difference}
      />
      <RenderBlockContent
        score={statistics.avgFood.value}
        description={dictionary.reviews.statistics.avgFoodRating}
        difference={statistics.avgFood.difference}
      />
      <RenderBlockContent
        score={statistics.avgNoise.value}
        description={dictionary.reviews.statistics.avgNoiseLevel}
        difference={statistics.avgNoise.difference}
      />
    </Paper>
  );
};

export default StatisticsBlockLong;
