import IVenueAdminProfile from "../../../models/IVenueAdminProfile";
import { IProfileForm } from "./IProfileForm";

export default class ProfileFormHelper {
  static createProfileFormFromValue(profile: IVenueAdminProfile) {
    const form: IProfileForm = {
      isValid: true,
      touched: [],
      values: {},
      errors: {},
    };
    form.values.firstName = profile.firstName;
    form.values.lastName = profile.lastName;
    form.values.email = profile.email;
    form.values.phone = profile.phone;

    return form;
  }

  public static mapProfileFromForm(form: IProfileForm): IVenueAdminProfile {
    return {
      firstName: form.values.firstName ? form.values.firstName : "",
      lastName: form.values.lastName ? form.values.lastName : "",
      email: form.values.email ? form.values.email : "",
      phone: form.values.phone ? form.values.phone : "",
    };
  }
}
