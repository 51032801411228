import { TablePaginationClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

import { colors } from "../colors";

const MuiTablePagination: Partial<
  Record<TablePaginationClassKey, CSSProperties>
> = {
  root: {
    width: "100%",
  },
  spacer: {
    display: "none",
  },
  caption: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.grey.secondary,
  },
  selectRoot: {
    marginRight: "auto",
  },
  selectIcon: {
    right: 10,
  },
  actions: {
    borderRadius: 4,
    overflow: "hidden",
    "& > button": {
      borderRadius: 0,
      backgroundColor: "#F0F0F1",
      "&[disabled]": {
        backgroundColor: "#F9F9FA",
      },
    },
  },
  select: {
    backgroundColor: colors.grey.lighten,
    height: 45,
    width: 75,
    paddingRight: "34px !important",
    lineHeight: "33px",
    boxSizing: "border-box",
    textAlign: "center",
    fontSize: "16px",
    borderRadius: 4,
    fontWeight: 600,
  },
};

export default MuiTablePagination;
