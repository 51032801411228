import { http } from "../../../config/api";
import UrlUtils from "../../../utils/UrlUtils";
import IPromotionsAllResponse from "../AdminVenue/components/Tabs/components/TabPromotions/models/IPromotionsAllResponse";
import { DiscountStatus } from "../types/DiscountStatus";
import { DiscountKind } from "../types/enums/DiscountKind.enum";
import { IPromotion } from "../types/IPromotion";

const PROMOTION_STATUS_UPDATE = (venueId: number) =>
  `promotions/${venueId}/status`;
const PROMOTIONS_ALL = (
  venueId?: number,
  limit?: number,
  skip?: number,
  search?: string,
  categoryId?: number,
  status?: DiscountStatus,
  kind?: DiscountKind
) => {
  let url = `promotions/${venueId}/all`;

  if (!venueId) {
    url = `promotions/all`;
  }

  let query = "";
  const queryParams: {
    limit?: number;
    skip?: number;
    search?: string;
    categoryId?: number;
    status?: DiscountStatus;
    kind?: DiscountKind;
  } = {};

  if (limit) {
    queryParams.limit = limit;
  }

  if (skip) {
    queryParams.skip = skip;
  }

  if (search) {
    queryParams.search = search;
  }
  if (categoryId) {
    queryParams.categoryId = categoryId;
  }

  if (status) {
    queryParams.status = status;
  }

  if (kind) {
    queryParams.kind = kind;
  }
  // @ts-ignore
  query = "?" + UrlUtils.buildQuery(queryParams);

  return url + query;
};

export const getPromotionsAll = async (
  venueId?: number,
  limit?: number,
  skip?: number,
  search?: string,
  categoryId?: number,
  status?: DiscountStatus,
  kind?: DiscountKind
): Promise<IPromotionsAllResponse> =>
  http
    .get(PROMOTIONS_ALL(venueId, limit, skip, search, categoryId, status, kind))
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });

export const changePromotionStatus = async (
  venueId: number,
  promotionId: number,
  status: DiscountStatus
): Promise<IPromotion[]> =>
  http
    .put(PROMOTION_STATUS_UPDATE(venueId), {
      promotionId,
      status,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
