import { TextField, Typography } from "@material-ui/core";
import { ChangeEvent, FC, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import ButtonSecondary from "../../../../common/components/ButtonSecondary";
import TextMessage from "../../../../common/components/TextMessage";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  forgotPasswordClearError,
  forgotPasswordRequst,
} from "../../../../store/forgotPassword/actions";
import { setAndValidate, validateForm } from "../../../../store/forms/actions";
import { FormFieldKey } from "../../../../store/forms/types";
import { AppState } from "../../../../store/rootReducer";
import { schema } from "./form";
import Props from "./Props";

const ForgotForm: FC<Props> = (props: Props) => {
  const { classes } = props;
  const emailGetParam = new URLSearchParams(location.search).get("email");
  const dispatch = useDispatch();
  const history = useHistory();
  const { forms, forgotPassword }: AppState = useSelector(
    (state: AppState) => state
  );
  const form = forms.forgotPassword;

  useEffect(() => {
    // To clear errors on component mounting
    dispatch(forgotPasswordClearError());

    return () => {
      // To clear errors on component unmounting
      dispatch(forgotPasswordClearError());
    };
  }, [dispatch]);

  const handleRequest = async () => {
    const { email } = form.values;

    if (!email) {
      dispatch(validateForm("changePassword", schema));
      return;
    }

    dispatch(forgotPasswordRequst({ username: email }));
  };

  const validateAndSubmit = async () => {
    if (!dispatch(validateForm("forgotPassword", schema))) {
      return;
    }

    await handleRequest();
  };

  useEffect(() => {
    if (emailGetParam) {
      dispatch(
        forgotPasswordRequst({ username: emailGetParam.replaceAll(" ", "+") })
      );
    }
  }, [dispatch, emailGetParam]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;
    dispatch(forgotPasswordClearError());
    dispatch(
      setAndValidate("forgotPassword", key as FormFieldKey, value, schema)
    );
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <>
      <Typography className={classes.description}>
        {dictionary.forgotPassword.emailDescription}
      </Typography>
      {forgotPassword.error && (
        <TextMessage className={classes.errorMessage} type="error">
          {forgotPassword.error}
        </TextMessage>
      )}
      <div className={classes.inputField}>
        <TextField
          error={!!form.errors.email}
          fullWidth
          required
          helperText={form.errors.email}
          label={dictionary.forgotPassword.usernameFieldLabel}
          name="email"
          onChange={handleChange}
          type="text"
          value={form.values.email || ""}
          variant="outlined"
        />
      </div>
      <div className={classes.actionsWrapper}>
        <ButtonPrimary
          className={classes.primaryBtn}
          disabled={!form.isValid || !!forgotPassword.error}
          fullWidth
          onClick={validateAndSubmit}>
          {dictionary.forgotPassword.continue}
        </ButtonPrimary>
        <ButtonSecondary onClick={handleCancel} fullWidth>
          {dictionary.forgotPassword.cancel}
        </ButtonSecondary>
      </div>
    </>
  );
};
export default ForgotForm;
