import { v4 as uuid } from "uuid";

export const defaultTimeProps = (isCustom?: boolean) => ({
  id: uuid(),
  from: isCustom ? "08:00:00" : "00:00:00",
  to: isCustom ? "20:00:00" : "23:59:59",
});

export const defaultDayProps = (isOpen: boolean, isCustom?: boolean) => ({
  id: uuid(),
  day: null,
  isOpen,
  hours: [defaultTimeProps(isCustom)],
});
