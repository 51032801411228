import MomentUtils from "@date-io/moment";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import moment from "moment";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import WorkingDayRow from "../../../../../../common/components/WorkingDaysPicker/components/WorkingDayRow";
import { WorkingDay } from "../../../../../../common/models";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { setFormValue } from "../../../../../../store/forms/actions";
import { AppState } from "../../../../../../store/rootReducer";
// eslint-disable-next-line max-len
import { WorkingDaysForm } from "../../form/WorkingDaysForm";
import { ScheduleType } from "./models";
import { useStyles } from "./styles";
import { defaultDayProps, defaultTimeProps } from "./utils";

function WorkingHours() {
  const form = useSelector<AppState, WorkingDaysForm>(
    (state) => state.forms.workingDays
  );
  const dispatch = useDispatch();
  const prevWorkDays = useRef(form.values.workingDays);
  const classes = useStyles();
  const [scheduleType, setScheduleType] = useState<ScheduleType>(
    ScheduleType.CUSTOM
  );

  const getCurrentScheduleType = useCallback(
    (workDays?: WorkingDay[]): ScheduleType => {
      let type = ScheduleType.CUSTOM;

      if (!workDays) {
        return type;
      }

      const hasOpen = workDays.some((day) => day.isOpen);
      const hasClose = workDays.some((day) => !day.isOpen);

      if (hasClose && hasOpen) {
        type = ScheduleType.CUSTOM;
      } else if (hasClose) {
        type = ScheduleType.ALL_OFF;
      } else if (hasOpen) {
        type = workDays.some((day) =>
          day.hours.some(
            (hour) =>
              hour.from !== defaultTimeProps().from ||
              hour.to !== defaultTimeProps().to
          )
        )
          ? ScheduleType.CUSTOM
          : ScheduleType.ALL_ON;
      }

      return type;
    },
    []
  );

  const getDefaults = (isOpen: boolean, isCustom?: boolean) =>
    moment.weekdays(true).map((day) => ({
      ...defaultDayProps(isOpen, isCustom),
      day,
      id: uuid(),
    }));

  const setDefaults = useCallback(
    (isOpen: boolean, isCustom?: boolean) => {
      dispatch(
        setFormValue(
          "workingDays",
          "workingDays",
          getDefaults(isOpen, isCustom)
        )
      );
    },
    [dispatch]
  );

  const setAllDaysOff = useCallback(() => {
    setDefaults(false);
  }, [setDefaults]);

  const setAllDaysOn = () => {
    setDefaults(true);
  };

  const setCustom = () => {
    setDefaults(true, true);
  };

  const handleChangeScheduleType = (e: ChangeEvent<HTMLInputElement>) => {
    const newSchedule = e.target.value as unknown as ScheduleType;

    switch (newSchedule) {
      case ScheduleType.ALL_OFF: {
        setAllDaysOff();
        break;
      }
      case ScheduleType.ALL_ON: {
        setAllDaysOn();
        break;
      }
      case ScheduleType.CUSTOM: {
        setCustom();
        break;
      }
    }
  };

  useEffect(() => {
    // Set default empty values when component mounted and form  haven't
    // valid working days value
    if (!prevWorkDays.current) {
      // Used ref to avoid dependency form.values.workingDays in deps array
      setDefaults(true);
    }
  }, [setDefaults]);

  useEffect(() => {
    setScheduleType(getCurrentScheduleType(form.values.workingDays));
  }, [form.values.workingDays, getCurrentScheduleType]);

  const displayDays = () =>
    form.values.workingDays?.map((day) => (
      <WorkingDayRow key={day.id} {...day} />
    ));

  const getLabelClassName = (labelValue: ScheduleType) => {
    return clsx(
      classes.scheduleTypeLabel,
      scheduleType === labelValue && classes.scheduleTypeLabelActive
    );
  };

  useEffect(() => {
    // If venue has not workdays, need to generate them
    if (!form.values.workingDays?.length) {
      setAllDaysOff();
    }
  }, [form.values.workingDays?.length, setAllDaysOff]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <FormControl component="div" className={classes.scheduleTypesWrapper}>
        <RadioGroup
          aria-label="venue-schedule-types"
          name="venue_schedule_types"
          value={scheduleType}
          onChange={handleChangeScheduleType}>
          <FormControlLabel
            classes={{ label: getLabelClassName(ScheduleType.ALL_OFF) }}
            value={ScheduleType.ALL_OFF}
            control={<Radio />}
            label={dictionary.venues.venueForm.workingHoursAllOff}
          />
          <FormControlLabel
            classes={{ label: getLabelClassName(ScheduleType.ALL_ON) }}
            value={ScheduleType.ALL_ON}
            control={<Radio />}
            label={dictionary.venues.venueForm.workingHoursAllOn}
          />
          <FormControlLabel
            classes={{ label: getLabelClassName(ScheduleType.CUSTOM) }}
            value={ScheduleType.CUSTOM}
            control={<Radio />}
            label={dictionary.venues.venueForm.workingHoursCustom}
          />
        </RadioGroup>
      </FormControl>
      <Grid container className={classes.root}>
        {form.values.workingDays ? (
          displayDays()
        ) : (
          <span>No data for working hours</span>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default WorkingHours;
