import { Form } from "../../../../common/models";
import { ValidationRules } from "../../../../utils/validation";

export type ChangePasswordFormField =
  | "currentPassword"
  | "newPassword"
  | "confirmPassword";
export type ChangePasswordFormValues = {
  [key in ChangePasswordFormField]: string;
};
export type ChangePasswordFormErrors = {
  [key in ChangePasswordFormField]: string;
};

export interface ChangePasswordForm extends Form {
  values: ChangePasswordFormValues;
  errors: ChangePasswordFormErrors;
  isValid: boolean;
  touched: ChangePasswordFormField[];
}

export type ChangePasswordFormSchema = Record<
  ChangePasswordFormField,
  ValidationRules
>;

export const createPromotionForm: ChangePasswordForm = {
  values: {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  },
  errors: {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  },
  touched: [],
  isValid: true,
};

export const schema: ChangePasswordFormSchema = {
  currentPassword: {
    required: true,
  },
  newPassword: {
    required: true,
    type: "password",
    length: {
      min: 8,
    },
  },
  confirmPassword: {
    required: true,
    equal: "newPassword",
  },
};
