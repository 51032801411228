import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../common/models";

export type ClassNames =
  | "wrapper"
  | "title"
  | "tabsWrapper"
  | "tab"
  | "addPromotionBtn"
  | "button"
  | "buttonContainer"
  | "noPromotionsText"
  | "noPromotionsContainer"
  | "noPromotionsBtn";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      wrapper: {
        maxWidth: "100%",
      },
      title: {
        paddingBottom: 24,
        paddingLeft: 16,
        paddingTop: 24,
      },
      button: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      },
      buttonContainer: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        maxHeight: 80,
      },
      addPromotionBtn: {
        width: "100%",
        margin: theme.spacing(0, 2, 1, 2),
        [theme.breakpoints.up("md")]: {
          maxWidth: "20%",
        },
      },
      tabsWrapper: {
        marginLeft: 20,
        marginRight: 20,
        [theme.breakpoints.up("lg")]: {
          marginTop: theme.spacing(2),
        },
        [theme.breakpoints.down("lg")]: {
          marginTop: theme.spacing(1),
        },
        [theme.breakpoints.down("sm")]: {
          marginTop: 0,
        },
        "&:after": {
          marginTop: -3,
          content: "''",
          display: "block",
          width: "100%",
          height: 2,
          backgroundColor: "rgba(204, 208, 222, 0.35)",
        },
      },
      tab: {
        padding: theme.spacing(1.5, 6.1),
        [theme.breakpoints.up("md")]: {
          minWidth: "auto",
        },
      },
      noPromotionsText: {
        justifyContent: "center",
        margin: theme.spacing(0, 3, 7, 3),
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 600,
        textAlign: "center",
      },
      noPromotionsBtn: {
        margin: theme.spacing(0, 2, 13, 2),
        [theme.breakpoints.up("md")]: {
          maxWidth: "20%",
        },
      },
      noPromotionsContainer: {
        justifyContent: "center",
        display: "flex",
        [theme.breakpoints.down("md")]: {
          height: "76vh",
        },
        [theme.breakpoints.up("md")]: {
          height: "82vh",
        },
      },
    })
);
