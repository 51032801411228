import React, { FC, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartContainer from "../../../../../common/components/ChartContainer";
import ChartTooltip from "../../../../../common/components/ChartTooltip";
import { VenueReportingContext } from "../../../../../common/components/VenueReportingContextProvider";
import { useVenueHasPackage } from "../../../../../common/hooks/useVenueHasPacakage";
import { PackageUID } from "../../../../../store/currentVenue/types";
import { colors } from "../../../../../theme/colors";
import {
  LegendDefaultProps,
  LineDefaultProps,
  XAxisDefaultProps,
  YAxisDefaultProps,
} from "../../../../Reporting/defaultProps";
import { chartDataMock } from "./mock";
import { ChartDataItem } from "./models";

const PromotionUsagesViewsChart: FC<{ title: string }> = ({
  title,
}: {
  title: string;
}) => {
  const { filters, handlePackageName, stats } = useContext(
    VenueReportingContext
  );
  const [chartData, setChartData] = useState(chartDataMock);
  const hasPackage = useVenueHasPackage(PackageUID.PROMOTIONS);

  const prepareChartData = (stats: ChartDataItem[]) => {
    setChartData(
      stats.map((viewsData) => ({
        ...viewsData,
        month: viewsData.month.slice(0, 3),
      }))
    );
  };

  useEffect(() => {
    if (stats) {
      prepareChartData(stats);
    }
    if (!hasPackage) {
      setChartData(chartDataMock);
    }
  }, [hasPackage, stats]);

  const isChartEmpty = !chartData.some(
    (data) => (data.usages || data.views) !== 0
  );

  return (
    <ChartContainer
      loading={!stats}
      title={title}
      isChartEmpty={isChartEmpty}
      locked={!hasPackage}
      chartType={"line-with-y-axis-and-legend-bottom"}
      packageName={handlePackageName(PackageUID.PROMOTIONS)}>
      <LineChart data={chartData}>
        <CartesianGrid
          strokeDasharray="3 3"
          strokeOpacity={0.6}
          color={colors.grey.light}
          vertical={false}
        />
        <Line {...LineDefaultProps} dataKey={"views"} unit={"views"} />
        <XAxis {...XAxisDefaultProps} dataKey="month" />
        <YAxis {...YAxisDefaultProps} />
        <Tooltip content={<ChartTooltip />} />
        <Legend {...LegendDefaultProps} />
      </LineChart>
    </ChartContainer>
  );
};

export default PromotionUsagesViewsChart;
