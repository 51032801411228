import { Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import clsx from "clsx";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";

import DynamicMetaTags from "../../common/components/DynamicMetaTags";
import Logo from "../../common/components/Logo";
import { getRouteWithSlash, Route } from "../../config/router";
import dictionary from "../../i18n/en_US/dictionary";
import { AuthState } from "../../store/auth/reducer";
import { AppState } from "../../store/rootReducer";
import { RoleNameEnum } from "../VenueStaff/main/RoleTypes";
import LoginForm from "./components/LoginForm";
import MFA from "./components/MFA";
import Props from "./Props";
import { LoginClassNames, useStyles } from "./styles";

const Login: FC<Props> = (props: Props) => {
  const { location } = props;
  const { hasToken, mfaToken } = useSelector<AppState, AuthState>(
    (state) => state.auth
  );
  const history = useHistory<Record<string, any>>();

  const { roles } = useSelector<AppState, AuthState>((state) => state.auth);

  useEffect(() => {
    if (hasToken) {
      roles.includes(RoleNameEnum.discount)
        ? history.push(getRouteWithSlash(Route.ManagerDeals))
        : history.push("/", history.location.state);
    }
  }, [roles, hasToken, history, location]);

  const classes: ClassNameMap<LoginClassNames> = useStyles();

  const metaTags = [
    {
      property: "og:title",
      content: dictionary.auth.loginOGTitle,
    },
    {
      property: "og:description",
      content: dictionary.auth.loginDescriptionTag,
    },
  ];

  return (
    <div className={classes.content}>
      <div className={classes.contentBody}>
        <div className={classes.form}>
          <DynamicMetaTags title={dictionary.auth.loginTitle} meta={metaTags} />
          <div className={classes.contentHeader} style={{ userSelect: "none" }}>
            <Logo className={classes.logo} width={84} height={84} />
          </div>
          <div className={classes.contentHeader}>
            <Typography
              className={clsx(
                classes.title,
                mfaToken ? classes.mfaTitle : classes.loginTitle
              )}
              variant="h1">
              {mfaToken ? (
                <span>{dictionary.auth.mfaTitle}</span>
              ) : (
                <>
                  <span>{dictionary.auth.logIn}</span>
                  <span className={classes.titleRight}>
                    {dictionary.auth.toYourAccount}
                  </span>
                </>
              )}
            </Typography>
          </div>
          {mfaToken ? (
            <MFA classes={classes} />
          ) : (
            <LoginForm classes={classes} />
          )}
        </div>
      </div>
      <div className={classes.aside} />
    </div>
  );
};

export default withRouter(Login);
