import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const OcietyQRCode: FC<Props> = (props: Props) => {
  const { imgSrc } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={imgSrc} alt="" />
    </div>
  );
};
export default OcietyQRCode;
