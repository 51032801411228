import React, { FC, useContext } from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import MFAItem from "../MFAItem";
import { disableOTPMFA } from "./api";
import Props from "./Props";

const MFAOTPControl: FC<Props> = (props: Props) => {
  const {
    isActive,
    isSelected,
    description,
    setLoading,
    refreshData,
    onClickEnable,
    onSelectClick,
  } = props;

  const { showAlert } = useContext(AlertContext);
  let helpInfoTxt = "";
  if (isActive && description) {
    helpInfoTxt = description;
  }

  const disableOTPMethod = async () => {
    try {
      setLoading(true);

      const result = await disableOTPMFA();

      if (!result) {
        showAlert(dictionary.profile.mfa.disableMFAError, "error");
        return;
      }

      showAlert(dictionary.venueAdmin.profile.mfa.disableMFASuccess, "success");

      if (refreshData) {
        refreshData();
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(dictionary.profile.mfa.disableMFAError, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <MFAItem
      title={dictionary.profile.mfa.byApplication}
      description={dictionary.profile.mfa.descriptionTextOTP}
      info={helpInfoTxt}
      infoHelp={dictionary.profile.mfa.mobileDevice}
      isActive={isActive}
      selected={isSelected}
      onEnableClick={() => {
        onClickEnable(true);
      }}
      onDisableClick={disableOTPMethod}
      onSelectClick={onSelectClick}
    />
  );
};
export default MFAOTPControl;
