import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type VenueClassNames = "root" | "errorMessage" | "successMessage";

export const useStyles: () => ClassNameMap<VenueClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        padding: 30,
      },
      errorMessage: {
        marginTop: theme.spacing(1),
        color: theme.palette.error.main,
      },
      successMessage: {
        marginTop: theme.spacing(1),
        color: theme.palette.success.main,
      },
    })
);
