import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";

export type ClassNames = "wrapper" | "content";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      wrapper: {
        padding: theme.spacing(4, 5, 5),
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
      },
      content: {
        marginTop: theme.spacing(3),
        flex: 1,
        display: "flex",
        position: "relative",
      },
    })
);
