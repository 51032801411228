import { ChartDataItem } from "./models";

export const chartDataMock: ChartDataItem[] = [
  {
    month: "Jan",
    position: 44,
  },
  {
    month: "Feb",
    position: 33,
  },
  {
    month: "Mar",
    position: 66,
  },
  {
    month: "Apr",
    position: 34,
  },
  {
    month: "May",
    position: 12,
  },
  {
    month: "Jun",
    position: 11,
  },
];
