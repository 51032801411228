import React, { FC } from "react";
import { withRouter } from "react-router-dom";

import PageTitle from "../../../common/components/PageTitle";
import dictionary from "../../../i18n/en_US/dictionary";
import VenueStaffManagers from "../managers";
import Props from "./Props";

const VenueStaff: FC<Props> = () => {
  return (
    <>
      <PageTitle>{dictionary.venueStaff.pageTitle}</PageTitle>
      <VenueStaffManagers />
    </>
  );
};
export default withRouter(VenueStaff);
