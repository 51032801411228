import { Divider, Typography } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";

import AutoHideMessage from "../../common/components/Draft/AutoHide";
import LoadingIndicator from "../../common/components/LoadingIndicator";
import dictionary from "../../i18n/en_US/dictionary";
import { Profile } from "../../permissions/permission";
import { AppState } from "../../store/rootReducer";
import { havePermission, isAdmin } from "../../utils/userUtils";
import ChangePasswordForm from "./components/ChangePasswordForm";
import MFAManagement from "./components/MFA";
import VenueAdminMainForm from "./components/VenueAdminMainForm";
import { getProfile, saveProfile } from "./ducks/actions";
import IVenueAdminProfile from "./models/IVenueAdminProfile";
import Props from "./Props";
import { useStyles } from "./styles";

const VenueAdminProfile: FC<Props> = (props: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { venueAdminProfile, saved } = useSelector(
    (state: AppState) => state.venueAdminProfile
  );

  const handleSave = (profile: IVenueAdminProfile) => {
    dispatch(saveProfile(profile));
  };

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);
  if (!isAdmin()) {
    history.push("/login");
    return null;
  }
  return (
    <div className={classes.root}>
      <Typography variant="h1" component="h2">
        {dictionary.venueAdmin.profile.title}
      </Typography>

      {venueAdminProfile ? (
        <VenueAdminMainForm onSave={handleSave} profile={venueAdminProfile} />
      ) : (
        <LoadingIndicator />
      )}
      {saved && (
        <AutoHideMessage>
          <span style={{ color: "green" }}>Saved</span>
        </AutoHideMessage>
      )}
      <Divider />
      {havePermission(Profile.CHANGE_PASSWORD) && <ChangePasswordForm />}
      <Divider />
      <MFAManagement />
    </div>
  );
};
export default withRouter(VenueAdminProfile);
