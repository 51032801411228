import MomentUtils from "@date-io/moment";
import { Box, IconButton, InputAdornment } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { FC } from "react";

import OcietyIcon from "../../OcietyIcon";
import Props from "./Props";

const OcietyDatePicker: FC<Props> = (props: Props) => {
  const {
    onChange,
    value,
    error,
    helperText,
    label,
    maxDate,
    minDate,
    icon,
    disabled,
  } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box style={{ marginTop: 0 }}>
        <DatePicker
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <OcietyIcon name={icon || "datePicker"} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={label}
          format="MM/DD/yyyy"
          error={error}
          helperText={helperText}
          inputVariant="outlined"
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChange}
          value={value !== "" ? moment(value) : null}
          disabled={disabled}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export default OcietyDatePicker;
