import { useCallback, useEffect } from "react";

export function useSubmitFormOnEnter(onSubmit: () => void) {
  const checkForEnter = useCallback(
    (e) => {
      // if enter
      if (e.which === 13) {
        onSubmit();
      }
    },
    [onSubmit]
  );

  useEffect(() => {
    document.addEventListener("keypress", checkForEnter);

    return () => {
      document.removeEventListener("keypress", checkForEnter);
    };
  }, [checkForEnter]);
}
