import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import HolidaysListRow from "../HolidaysListRow";
import Props from "./Props";
import { useStyles } from "./styles";

const HolidaysList: FC<Props> = (props) => {
  const { holidays, setCurrentHoliday, deleteHoliday } = props;
  const classes = useStyles();

  const renderItems = () =>
    holidays?.map((holiday) => (
      <HolidaysListRow
        key={holiday.id}
        holiday={holiday}
        setCurrentHoliday={setCurrentHoliday}
        deleteHoliday={deleteHoliday}
      />
    ));

  return (
    <div className={classes.root}>
      <Grid container xs={12}>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={6}
          className={clsx(classes.rowItem, classes.rowItemFirst)}>
          <Typography variant={"body2"} className={classes.headerRowItemText}>
            {dictionary.venues.venueForm.holidaysNameColumn}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4} className={classes.rowItem}>
          <Typography variant={"body2"} className={classes.headerRowItemText}>
            {dictionary.venues.venueForm.holidaysDateColumn}
          </Typography>
        </Grid>
        <Grid
          item
          lg={2}
          md={2}
          sm={2}
          xs={2}
          className={clsx(classes.rowItem, classes.rowItemLast)}>
          <Typography variant={"body2"} className={classes.headerRowItemText}>
            {dictionary.venues.venueForm.holidaysActionsColumn}
          </Typography>
        </Grid>
      </Grid>
      {renderItems()}
    </div>
  );
};

export default HolidaysList;
