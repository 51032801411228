import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames = "root" | "counter" | "iconButton" | "counterText";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      iconButton: {
        position: "relative",
      },
      counter: {
        border: "2px solid white",
        borderRadius: "50%",
        height: 18,
        width: 18,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.red.primary,
        position: "absolute",
        top: 5,
        right: 5,
      },
      counterText: {
        color: colors.white.primary,
        fontSize: theme.typography.pxToRem(10),
        fontWeight: 800,
      },
    })
);
