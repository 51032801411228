import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "actionsContainer"
  | "priceContainer"
  | "currency"
  | "deleteButton"
  | "price"
  | "packItemName"
  | "whatIncludedContainer"
  | "whatIncludedLabel"
  | "period";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      actionsContainer: {
        marginLeft: theme.spacing(2),
      },
      priceContainer: {
        display: "flex",
        justifyContent: "center",
        marginLeft: "auto",
      },
      currency: {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 900,
        marginRight: 3,
        marginTop: -15,
      },
      deleteButton: {
        border: "2px solid #F3F3F3",
        backgroundColor: colors.grey.bgUltraLight,
        borderRadius: 4,
      },
      price: {
        fontWeight: 800,
        fontSize: theme.typography.pxToRem(34),
        color: colors.green.additional,
      },
      period: {
        marginTop: 6,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
        marginLeft: 3,
      },
      root: {
        padding: theme.spacing(5, 3, 3),
        alignItems: "center",
        display: "flex",
        [theme.breakpoints.up("md")]: {
          minWidth: 500,
        },
        marginBottom: theme.spacing(3),
      },
      packItemName: {
        fontWeight: 700,
        fontSize: 22,
      },
      whatIncludedContainer: {
        display: "flex",
        alignItems: "center",
      },
      whatIncludedLabel: {
        color: colors.grey.secondary,
        fontWeight: 600,
      },
    })
);
