import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../common/models";

export type ClassNames = "root" | "title" | "wrapper" | "btn";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        // marginLeft: 30,
        // marginRight: 30,
        width: "100%",
        //
        //  backgroundColor: theme.palette.background.paper,
      },
      title: {
        backgroundColor: theme.palette.background.paper,
      },
      wrapper: {
        width: "100%",
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
        marginBottom: 20,
      },
      btn: {
        margin: 30,
      },
    })
);
