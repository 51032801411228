import {
  Button,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import React, { FC, useContext, useEffect, useState } from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  disableCallMFA,
  disableEmailMFA,
  disableSmsMFA,
  disableTotpMFA,
  getMfa,
  setMfaDefault,
} from "./api";
import AddCallMethodDialog from "./components/AddCallMethodDialog";
import AddMailMethodDialog from "./components/AddMailMethodDialog";
import AddSmsMethodDialog from "./components/AddSmsMethodDialog";
import AddTotpMethodDialog from "./components/AddTotpMethodDialog";
import IMfaData, { TwoFactoryAuthTypeEnum } from "./IMfaData";
import Props from "./Props";
import { useStyles } from "./styles";

const MFAManagement: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const [isAddSmsMethodDialogOpen, setIsAddSmsMethodDialogOpen] =
    useState(false);
  const [isAddCallMethodDialogOpen, setIsAddCallMethodDialogOpen] =
    useState(false);

  const [isAddEmailMethodDialogOpen, setIsAddEmailMethodDialogOpen] =
    useState(false);
  const [isAddTotpMethodDialogOpen, setIsAddTotpMethodDialogOpen] =
    useState(false);
  const [mfaData, setMfaData] = useState<IMfaData | null>(null);
  const [checkedType, setCheckedType] = useState<TwoFactoryAuthTypeEnum | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const openSmsDialog = () => {
    setIsAddSmsMethodDialogOpen(true);
  };
  const openCallDialog = () => {
    setIsAddCallMethodDialogOpen(true);
  };
  const openEmailDialog = () => {
    setIsAddEmailMethodDialogOpen(true);
  };
  const disableSmsMethod = async () => {
    try {
      setLoading(true);

      const result = await disableSmsMFA();

      if (!result) {
        showAlert(dictionary.venueAdmin.profile.mfa.disableMFAError, "error");
      }

      showAlert(dictionary.venueAdmin.profile.mfa.disableMFASuccess, "success");

      refreshMfaData();
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(dictionary.venueAdmin.profile.mfa.disableMFAError, "error");
    } finally {
      setLoading(false);
    }
  };

  const disableCallMethod = async () => {
    try {
      setLoading(true);

      const result = await disableCallMFA();

      if (!result) {
        showAlert(dictionary.venueAdmin.profile.mfa.disableMFAError, "error");
      }

      showAlert(dictionary.venueAdmin.profile.mfa.disableMFASuccess, "success");
      refreshMfaData();
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(dictionary.venueAdmin.profile.mfa.disableMFAError, "error");
    } finally {
      setLoading(false);
    }
  };

  const disableEmailMethod = async () => {
    try {
      setLoading(true);

      const result = await disableEmailMFA();

      if (!result) {
        showAlert(dictionary.venueAdmin.profile.mfa.disableMFAError, "error");
      }

      showAlert(dictionary.venueAdmin.profile.mfa.disableMFASuccess, "success");
      refreshMfaData();
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(dictionary.venueAdmin.profile.mfa.disableMFAError, "error");
    } finally {
      setLoading(false);
    }
  };
  const disableTotpMethod = async () => {
    try {
      setLoading(true);

      const result = await disableTotpMFA();

      if (!result) {
        showAlert(dictionary.venueAdmin.profile.mfa.disableMFAError, "error");
      }

      showAlert(dictionary.venueAdmin.profile.mfa.disableMFASuccess, "success");
      refreshMfaData();
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(dictionary.venueAdmin.profile.mfa.disableMFAError, "error");
    } finally {
      setLoading(false);
    }
  };

  const refreshMfaData = () => {
    getMfa()
      .then((mfaData: IMfaData) => {
        if (mfaData) {
          setMfaData(mfaData);
          setCheckedType(mfaData.mfaTwoFactoryAuthTypeDefault);
        }
      })
      .catch(() => {
        showAlert("Error", "error");
      });
  };

  useEffect(() => {
    getMfa()
      .then((mfaData: IMfaData) => {
        if (mfaData) {
          setMfaData(mfaData);
          setCheckedType(mfaData.mfaTwoFactoryAuthTypeDefault);
        }
      })
      .catch(() => {
        showAlert("Error", "error");
      });
  }, [showAlert]);

  const onChangeChecked = async (type: TwoFactoryAuthTypeEnum) => {
    try {
      setLoading(true);
      setCheckedType(type);
      const result = await setMfaDefault(type);

      if (!result) {
        showAlert(
          dictionary.venueAdmin.profile.mfa.changeDefaultMethodError,
          "error"
        );
      }

      showAlert("Saved", "success");
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(
        dictionary.venueAdmin.profile.mfa.changeDefaultMethodError,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };
  const renderSmsItem = () => {
    const isEnableMfaSms = mfaData?.mfaSmsEnable;
    let label = "via SMS";

    if (isEnableMfaSms && mfaData) {
      label = "SMS via +" + mfaData.mfaSmsPhone;
    }

    return (
      <ListItem key={"SMS"} role={undefined} dense button>
        <ListItemIcon>
          <FormControlLabel
            value={"SMS"}
            control={
              <Radio
                disabled={!isEnableMfaSms}
                // checked={isChecked(TwoFactoryAuthTypeEnum.SMS)}
              />
            }
            label={""}
          />
        </ListItemIcon>

        <ListItemText id={"SMS"} primary={label} />
        <ListItemSecondaryAction>
          {isEnableMfaSms ? (
            <Button onClick={disableSmsMethod} variant="contained">
              {dictionary.venueAdmin.profile.mfa.disableBtn}
            </Button>
          ) : (
            <Button onClick={openSmsDialog} variant="contained" color="primary">
              {dictionary.venueAdmin.profile.mfa.enableBtn}
            </Button>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderCallItem = () => {
    const isEnableMfaCall = mfaData?.mfaCallEnable;
    let label = "Call";

    if (isEnableMfaCall && mfaData) {
      label = "Call via " + mfaData.mfaCallPhone;
    }
    return (
      <ListItem key={"CALL"} role={undefined} dense button>
        <ListItemIcon>
          <FormControlLabel
            value={"CALL"}
            control={
              <Radio
                disabled={!isEnableMfaCall}
                // checked={isChecked(TwoFactoryAuthTypeEnum.CALL)}
              />
            }
            label={""}
          />
        </ListItemIcon>

        <ListItemText id={"CALL"} primary={label} />
        <ListItemSecondaryAction>
          {isEnableMfaCall ? (
            <Button onClick={disableCallMethod} variant="contained">
              {dictionary.venueAdmin.profile.mfa.disableBtn}
            </Button>
          ) : (
            <Button
              onClick={openCallDialog}
              variant="contained"
              color="primary">
              {dictionary.venueAdmin.profile.mfa.enableBtn}
            </Button>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderEmailItem = () => {
    const isEnableMfaEmail = mfaData?.mfaMailEnable;
    let label = "Email";

    if (isEnableMfaEmail && mfaData) {
      label = "Email via " + mfaData.mfaMailEmail;
    }
    return (
      <ListItem key={"EMAIL"} role={undefined} dense button>
        <ListItemIcon>
          <FormControlLabel
            value={"EMAIL"}
            control={
              <Radio
                disabled={!isEnableMfaEmail}
                // checked={isChecked(TwoFactoryAuthTypeEnum.EMAIL)}
              />
            }
            label={""}
          />
        </ListItemIcon>

        <ListItemText id={"EMAIL"} primary={label} />
        <ListItemSecondaryAction>
          {isEnableMfaEmail ? (
            <Button onClick={disableEmailMethod} variant="contained">
              {dictionary.venueAdmin.profile.mfa.disableBtn}
            </Button>
          ) : (
            <Button
              onClick={openEmailDialog}
              variant="contained"
              color="primary">
              {dictionary.venueAdmin.profile.mfa.enableBtn}
            </Button>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderTotpItem = () => {
    const isEnableMfaTotp = mfaData?.mfaTotpEnable;
    let label = "via TOTP";

    if (isEnableMfaTotp && mfaData) {
      label = "TOTP via " + mfaData.mfaTotpNote;
    }

    return (
      <ListItem key={"TOTP"} role={undefined} dense button>
        <ListItemIcon>
          <FormControlLabel
            value={"TOTP"}
            control={
              <Radio
                disabled={!isEnableMfaTotp}
                // checked={isChecked(TwoFactoryAuthTypeEnum.TOTP)}
              />
            }
            label={""}
          />
        </ListItemIcon>

        <ListItemText id={"TOTP"} primary={label} />
        <ListItemSecondaryAction>
          {isEnableMfaTotp ? (
            <Button onClick={disableTotpMethod} variant="contained">
              {dictionary.venueAdmin.profile.mfa.disableBtn}
            </Button>
          ) : (
            <Button
              onClick={() => setIsAddTotpMethodDialogOpen(true)}
              variant="contained"
              color="primary">
              {dictionary.venueAdmin.profile.mfa.enableBtn}
            </Button>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <div>
      <Typography variant="h6" className={classes.title}>
        {dictionary.venueAdmin.profile.mfa.title}
      </Typography>
      {loading && <LoadingIndicator withMask />}
      <List className={classes.root}>
        <RadioGroup
          defaultValue={"DISABLE"}
          value={checkedType}
          onChange={(event: any) => {
            // @ts-ignore
            onChangeChecked(event.target.value);
          }}
          aria-label="gender"
          name="customized-radios">
          {renderSmsItem()}
          {renderCallItem()}
          {renderEmailItem()}
          {renderTotpItem()}
        </RadioGroup>
      </List>

      <AddSmsMethodDialog
        isOpen={isAddSmsMethodDialogOpen}
        setOpen={setIsAddSmsMethodDialogOpen}
        onSuccessEnabled={() => refreshMfaData()}
      />

      <AddCallMethodDialog
        isOpen={isAddCallMethodDialogOpen}
        setOpen={setIsAddCallMethodDialogOpen}
        onSuccessEnabled={() => refreshMfaData()}
      />

      <AddMailMethodDialog
        isOpen={isAddEmailMethodDialogOpen}
        setOpen={setIsAddEmailMethodDialogOpen}
        onSuccessEnabled={() => refreshMfaData()}
      />

      <AddTotpMethodDialog
        isOpen={isAddTotpMethodDialogOpen}
        setOpen={setIsAddTotpMethodDialogOpen}
        onSuccessEnabled={() => {
          refreshMfaData();
        }}
      />
    </div>
  );
};
export default MFAManagement;
