import { Grid, Paper } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";

import { AlertContext } from "../../../../../../../common/components/Alert/AlertContextProvider";
import VenueAutocompleteSearch from "../../../../../../../common/components/forms/VenueAutocompleteSearch";
import LoadingIndicator from "../../../../../../../common/components/LoadingIndicator";
import HttpStatusCodes from "../../../../../../../common/httpStatusCodes";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import VenuesSearchEmpty from "../../../../../../Venues/components/VenuesSearchEmpty";
import IPromotionsAllResponse from "../../../../../AdminVenue/components/Tabs/components/TabPromotions/models/IPromotionsAllResponse";
import {
  changePromotionStatus,
  getPromotionsAll,
} from "../../../../../components/api";
import PromotionsGrid from "../../../../../components/PromotionsGrid";
import SearchPromotion from "../../../../../components/SearchPromotion";
import { DiscountStatus } from "../../../../../types/DiscountStatus";
import { IPromotion } from "../../../../../types/IPromotion";
import PromotionStatusSelect from "./components/PromotionStatusSelect";
import Props from "./Props";
import { useStyles } from "./styles";

const TabPromotions: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const rowsPerPage = 10;
  const { hash } = window.location;
  const statusHashes = {
    "#active": DiscountStatus.ENABLED,
  };
  let initDiscountStatus: null | DiscountStatus = null;

  if (hash && hash in statusHashes) {
    // @ts-ignore
    initDiscountStatus = statusHashes[hash];
  }

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const [promotions, setPromotions] = useState<IPromotion[]>([]);
  const [promotionsCount, setPromotionsCount] = useState<number>(0);
  const [venueId, setVenueId] = useState<number | undefined>(undefined);

  const [searchValue, setSearchValue] = useState("");
  const [discountStatus, setDiscountStatus] = useState<null | DiscountStatus>(
    initDiscountStatus
  );

  const skip = (page - 1) * rowsPerPage;
  useEffect(() => {
    getPromotionsAll(
      venueId,
      rowsPerPage,
      skip,
      searchValue,
      undefined,
      discountStatus ? discountStatus : undefined
    )
      .then((data: IPromotionsAllResponse) => {
        if (data) {
          setPromotions(data.promotions);
          setPromotionsCount(data.count);
        }
      })
      .catch((e) => {
        if (e.status !== HttpStatusCodes.SERVER_ERROR) {
          showAlert(dictionary.deals.createDealForm.errorFetchData, "error");
        }
      });
  }, [showAlert, venueId, searchValue, skip, discountStatus]);

  const openEditDialog = () => {
    alert("Opent edit dialog");
  };
  const onChangePromotionStatus = async (
    happyHourId: number,
    status: DiscountStatus,
    venueId: number
  ) => {
    try {
      setLoading(true);
      const result = await changePromotionStatus(venueId, happyHourId, status);

      if (result)
        if (!result) {
          showAlert(dictionary.deals.createDealForm.incorrectResponse, "error");
        }
      showAlert(dictionary.deals.successfullySaved, "success");
    } catch (e) {
      if (e?.data === "purchasedPromotion") {
        return showAlert(
          dictionary.deals.createDealForm.msgErrorPurchasedDeal,
          "error"
        );
      }
      showAlert(dictionary.deals.createDealForm.errorStatus, "error");
    } finally {
      setLoading(false);
    }
  };
  const openDeleteDialog = () => {
    alert(dictionary.deals.openDeleteDialog);
  };
  const openDuplicateDialog = () => {
    alert(dictionary.deals.openDuplicateDialog);
  };
  const onChangeSearchValue = (search: string) => {
    setPage(1);
    setSearchValue(search);
  };
  const refresh = (): void => {
    alert(dictionary.deals.refresh);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.wrapper}>
        <Grid container spacing={1}>
          <Grid lg={2}>
            <VenueAutocompleteSearch
              style={{ marginTop: 4, marginRight: 20 }}
              requried={false}
              onChange={(id) => setVenueId(id)}
              label={"Venue"}
            />
          </Grid>
          <Grid item lg={2}>
            <SearchPromotion onChange={onChangeSearchValue} />
          </Grid>
          <Grid lg={5} />

          <Grid lg={3}>
            <PromotionStatusSelect
              status={discountStatus}
              setStatus={setDiscountStatus}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {loading && <LoadingIndicator withMask />}
            {searchValue && !promotions.length ? (
              <VenuesSearchEmpty />
            ) : (
              <PromotionsGrid
                rowsPerPage={rowsPerPage}
                isSuperAdminGrid={true}
                page={page}
                setPage={setPage}
                promotions={promotions}
                count={promotionsCount}
                onEditPromotion={openEditDialog}
                onChangePromotionStatus={onChangePromotionStatus}
                onDeletePromotion={openDeleteDialog}
                onDuplicatePromotion={openDuplicateDialog}
                refresh={refresh}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default TabPromotions;
