import { Tab, Tabs, useTheme } from "@material-ui/core";
import React, { FC, useCallback, useContext, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";

import TabPanel from "../../../../common/components/TabPanel";
import {
  getInitialTabIndex,
  getTabProps,
} from "../../../../common/components/TabPanel/utils";
import VenueClaimRequestsTable from "../VenueClaimRequestsTable";
import { claimStatuses } from "./common";
import { useStyles } from "./styles";
import { useTabsHeadings } from "./useTabsHeadings";

const tabHashes = ["#pending", "#approved", "#rejected"]; // indexes (hashes order) is important

const TabsClaimRequests: FC = () => {
  const theme = useTheme();
  const initialTabIndex = getInitialTabIndex(
    window.location.hash,
    tabHashes,
    0
  );

  const [currentTabIdx, setCurrentTabIdx] = React.useState(initialTabIndex);
  const classes = useStyles();
  const headings = useTabsHeadings();

  const changeTab = (index: number) => {
    setCurrentTabIdx(index);
    window.location.hash = tabHashes[index];
  };

  return (
    <div>
      <div className={classes.tabsWrapper}>
        <Tabs
          value={currentTabIdx}
          onChange={(
            event: React.ChangeEvent<Record<any, any>>,
            newValue: number
          ) => {
            changeTab(newValue);
          }}
          indicatorColor="primary"
          textColor="primary"
          aria-label="user management tab">
          {headings.map((heading, index) => (
            <Tab
              key={index}
              classes={{
                root: classes.tabRoot,
              }}
              label={heading}
              {...getTabProps(index)}
            />
          ))}
        </Tabs>
      </div>
      <SwipeableViews
        className={classes.swipeableContainer}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={currentTabIdx}
        onChangeIndex={(index: number) => {
          changeTab(index);
        }}>
        {claimStatuses.map((status, index) => (
          <TabPanel
            key={index}
            hideNotActive
            value={currentTabIdx.toString()}
            index={index.toString()}
            dir={theme.direction}>
            <VenueClaimRequestsTable status={status} />
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  );
};

export default TabsClaimRequests;
