import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";

import { useStyles } from "../../styles";
import Props from "./Props";

const InformationBlockItem: FC<Props> = (props: Props) => {
  const { label, value, fullWidth } = props;

  const classes = useStyles();

  return (
    <Grid
      item
      lg={fullWidth ? 12 : 6}
      md={fullWidth ? 12 : 6}
      sm={fullWidth ? 12 : 6}
      xs={12}>
      <Grid container spacing={1}>
        <Grid
          item
          lg={fullWidth ? 3 : 6}
          md={fullWidth ? 3 : 6}
          sm={fullWidth ? 3 : 6}
          xs={6}>
          <Typography variant={"body2"} className={classes.blockLabel}>
            {label}
          </Typography>
        </Grid>
        <Grid
          item
          lg={fullWidth ? 9 : 6}
          md={fullWidth ? 9 : 6}
          sm={fullWidth ? 9 : 6}
          xs={6}>
          <Typography className={classes.blockValue}>{value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InformationBlockItem;
