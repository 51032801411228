import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";

export type ClassNames =
  | "section"
  | "tabsWrapper"
  | "uploaderWrapper"
  | "sectionTitle"
  | "saveButton"
  | "sliderWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      section: {
        position: "relative",
        padding: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(2),
        },
        "&:not(:first-of-type)": {
          marginTop: theme.spacing(3),
        },
      },
      sectionTitle: {
        marginBottom: theme.spacing(1),
      },
      saveButton: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(3),
      },
      tabsWrapper: {
        position: "relative",
        margin: theme.spacing(0, 0, 3, 2),
        display: "flex",
        [theme.breakpoints.down("sm")]: {
          flexWrap: "wrap",
          marginLeft: 0,
        },
        justifyContent: "center",
      },
      uploaderWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: theme.spacing(1, 3, 3, 0),
        width: "auto",
        [theme.breakpoints.down("xs")]: {
          flexWrap: "wrap",
          margin: theme.spacing(0, 0, 0, 0),
        },
      },
      sliderWrapper: {
        width: "100%",
        margin: "auto",
        overflow: "hidden",
        marginTop: -40,
      },
    })
);
