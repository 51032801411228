import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../i18n/en_US/dictionary";
import { OcietyTheme } from "../../../models";
import ButtonBlack from "../../ButtonBlack";
import ButtonCancel from "../../ButtonCancel";
import ButtonPrimary from "../../ButtonPrimary";
import OcietyDialogTitle from "../OcietyDIalogTitle";
import Props from "./Props";
import { useStyles } from "./styles";

const DialogSimpleConfirm: FC<Props> = (props: Props) => {
  const { open, onClose, title, subtitle, message } = props;

  const isMobile = useMediaQuery((theme: OcietyTheme) =>
    theme.breakpoints.down("xs")
  );

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      classes={{
        paperWidthSm: classes.root,
      }}>
      <OcietyDialogTitle id="confirmation-dialog-title" onClose={onClose}>
        {title || dictionary.confirmDialog.title}
      </OcietyDialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <Typography variant={"subtitle1"} className={classes.subtitle}>
          {subtitle || null}
        </Typography>
        <Typography className={classes.message}>{message}</Typography>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <ButtonBlack onClick={onClose} className={classes.mobileButton}>
          {dictionary.confirmDialog.okayButton}
        </ButtonBlack>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSimpleConfirm;
