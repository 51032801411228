import { capitalize } from "@material-ui/core";
import React, { FC, useContext, useEffect } from "react";

import { GET_VENUE_TYPES } from "../../../../../../common/api";
import { useRestApi } from "../../../../../../common/hooks/useRestApi";
import { VenueTypes } from "../../../../../../common/models";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import PermissionsCheckboxes from "../../../PermissionsCheckboxes";
import { CreateVenueDialogContext } from "../../CreateVenueDialogContextProvider";
import AttachmentsBlock from "./components/AttachmentsBlock";
import InformationBlock from "./components/InformationBlock";
import { InformationBlockItem } from "./components/InformationBlock/Props";
import { useStyles } from "./styles";

const CreateVenueDialogValidationStep: FC = () => {
  const classes = useStyles();
  const { adminForm, venueForm, setIsValidateDisabled } = useContext(
    CreateVenueDialogContext
  );
  const { lastName, firstName, title, phoneNumber, email, company } =
    adminForm.form.values;
  const {
    email: venueEmail,
    phoneNumber: venuePhoneNumber,
    city,
    address,
    country,
    name,
    state,
    bar,
    restaurant,
  } = venueForm.form.values;
  const [{ data: venueTypes }, setVenueTypes] = useRestApi<VenueTypes>(
    GET_VENUE_TYPES,
    "GET"
  );

  useEffect(() => {
    setVenueTypes().catch((e) => console.error(e));
  }, [setVenueTypes]);

  const parseVenueTypes = (type: "bar" | "restaurant", typesIds: string) => {
    if (!venueTypes) {
      return typesIds;
    }

    const idsArray = typesIds.split(",").map((id) => Number(id));
    const labelsArray: string[] = [];

    venueTypes[type].forEach((typeItem) => {
      if (idsArray.includes(typeItem.id)) {
        labelsArray.push(capitalize(typeItem.subtype));
      }
    });

    return labelsArray.join(", ");
  };

  const yourInformationBlockItems: InformationBlockItem[] = [
    {
      label: dictionary.venues.list.createVenueValidationStepFirstNameLabel,
      value: firstName,
    },
    {
      label: dictionary.venues.list.createVenueValidationStepCompanyLabel,
      value: company,
    },
    {
      label: dictionary.venues.list.createVenueValidationStepLastNameLabel,
      value: lastName,
    },
    {
      label: dictionary.venues.list.createVenueValidationStepPhoneLabel,
      value: phoneNumber,
    },
    {
      label: dictionary.venues.list.createVenueValidationStepTitleLabel,
      value: title,
    },
    {
      label: dictionary.venues.list.createVenueValidationStepEmailLabel,
      value: email,
    },
  ];

  const venueInformationBlockItems: InformationBlockItem[] = [
    {
      label: dictionary.venues.list.createVenueValidationStepNameLabel,
      value: name,
    },
    {
      label: dictionary.venues.list.createVenueValidationStepCountryLabel,
      value: country + ", " + state,
    },
    {
      label: dictionary.venues.list.createVenueValidationStepCityLabel,
      value: city,
    },
    {
      label: dictionary.venues.list.createVenueValidationStepVenuePhoneLabel,
      value: venuePhoneNumber,
    },
    {
      label: dictionary.venues.list.createVenueValidationStepAddressLabel,
      value: address,
    },
    {
      label: dictionary.venues.list.createVenueValidationStepVenueEmailLabel,
      value: venueEmail,
    },
    {
      label: dictionary.venues.list.createVenueValidationStepBarTypesLabel,
      value: parseVenueTypes("bar", bar) || "-",
    },
    {
      label:
        dictionary.venues.list.createVenueValidationStepRestaurantTypesLabel,
      value: parseVenueTypes("restaurant", restaurant) || "-",
    },
  ];

  return (
    <div className={classes.root}>
      <InformationBlock
        items={yourInformationBlockItems}
        title={
          dictionary.venues.list.createVenueValidationStepYourInformationTitle
        }
      />
      <InformationBlock
        items={venueInformationBlockItems}
        title={
          dictionary.venues.list.createVenueValidationStepVenueInformationTitle
        }
      />
      <AttachmentsBlock />
      <div className={classes.permissionsContainer}>
        <PermissionsCheckboxes setIsValidateDisabled={setIsValidateDisabled} />
      </div>
    </div>
  );
};

export default CreateVenueDialogValidationStep;
