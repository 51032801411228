export const SET_USER = "SET_USER";
export const SET_USER_AVATAR = "SET_USER_AVATAR";

export interface SetUserActionType {
  type: typeof SET_USER;
  firstName?: string;
  lastName?: string;
  avatar?: string | null;
}

export interface SetUserAvatarActionType {
  type: typeof SET_USER_AVATAR;
  avatar: string;
}

export type UserActionTypes = SetUserActionType | SetUserAvatarActionType;
