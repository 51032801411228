import { TableCell, Typography } from "@material-ui/core";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const TD: FC<Props> = (props: Props) => {
  const { children, ...rest } = props;

  const classes = useStyles();

  return (
    <TableCell className={classes.root} {...rest}>
      {typeof children === "string" ? (
        <Typography variant={"body1"} className={classes.text}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </TableCell>
  );
};

export default TD;
