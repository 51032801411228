import { DiscountKind } from "../types/enums/DiscountKind.enum";
import { CreatePartialDiscount } from "../types/interfaces/CreatePartialDiscount.interface";
import { DiscountDaysHours } from "../types/interfaces/DiscountDaysHours.interface";

const obligatoryDiscountSpecificValues = {
  start: new Date("2000-01-27 00:00:00"),
  end: new Date("2999-01-27 00:00:00"),
};

export default class DtoPartialDiscount implements CreatePartialDiscount {
  public static build = ({
    name,
    description,
    itemsName,
    percent,
    rebatedPrice,
    sellingPrice,
    mainCategoryId,
    subCategoryId,
    daysHours,
    venueId,
    otherSubcategoryName = "",
  }: CreatePartialDiscount) => {
    const builderPartialDiscount = new DtoPartialDiscount();
    delete builderPartialDiscount.id;
    builderPartialDiscount.name = name;
    builderPartialDiscount.description = description;
    builderPartialDiscount.itemsName = itemsName;
    builderPartialDiscount.percent = Number(percent);
    builderPartialDiscount.rebatedPrice = Number(rebatedPrice);
    builderPartialDiscount.sellingPrice = Number(sellingPrice);
    builderPartialDiscount.mainCategoryId = mainCategoryId;
    builderPartialDiscount.subCategoryId = subCategoryId;
    builderPartialDiscount.daysHours = daysHours;
    builderPartialDiscount.venueId = venueId;
    builderPartialDiscount.otherSubcategoryName = otherSubcategoryName;
    builderPartialDiscount.kind = DiscountKind.HAPPY_HOUR;

    builderPartialDiscount.calculatedAmount = Math.round(
      sellingPrice * (percent / 100)
    );

    return { ...builderPartialDiscount, ...obligatoryDiscountSpecificValues };
  };

  public static buildUpdate = ({
    id,
    name,
    description,
    itemsName,
    percent,
    rebatedPrice,
    sellingPrice,
    mainCategoryId,
    subCategoryId,
    daysHours,
    venueId,
    otherSubcategoryName = "",
  }: CreatePartialDiscount & { id: number }) => {
    const builderPartialDiscount = new DtoPartialDiscount();
    if (Number.isInteger(id)) {
      builderPartialDiscount.id = id;
    } else delete builderPartialDiscount.id;

    builderPartialDiscount.name = name;
    builderPartialDiscount.description = description;
    builderPartialDiscount.itemsName = itemsName;
    builderPartialDiscount.percent = Number(percent);
    builderPartialDiscount.rebatedPrice = Number(rebatedPrice);
    builderPartialDiscount.sellingPrice = Number(sellingPrice);
    builderPartialDiscount.mainCategoryId = mainCategoryId;
    builderPartialDiscount.subCategoryId = subCategoryId;
    builderPartialDiscount.calculatedAmount = Math.round(
      sellingPrice * (percent / 100)
    );
    builderPartialDiscount.daysHours = daysHours;
    builderPartialDiscount.venueId = venueId;
    builderPartialDiscount.otherSubcategoryName = otherSubcategoryName;
    builderPartialDiscount.kind = DiscountKind.HAPPY_HOUR;

    return { ...builderPartialDiscount, ...obligatoryDiscountSpecificValues };
  };

  public id? = 0;
  public venueId = 0;
  public name = "";
  public description = "";
  public itemsName = "";
  /** @deprecated calculatedAmount should not be used anymore */
  public percent = 0;
  public rebatedPrice = 0;
  public sellingPrice = 0;
  public mainCategoryId = 0;
  public subCategoryId = 0;
  public start: Date = new Date("2000-01-27 00:00:00");
  public end: Date = new Date("2999-01-27 00:00:00");
  /** @deprecated calculatedAmount should not be used anymore */
  public calculatedAmount = 0;
  public daysHours: DiscountDaysHours[] = [];
  public otherSubcategoryName = "";
  public kind = DiscountKind.HAPPY_HOUR;
}
