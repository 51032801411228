import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getRouteWithSlash, Route } from "../../config/router";
import { AuthState } from "../../store/auth/reducer";
import {
  addNewMessage,
  setActiveChat,
  setChatData,
} from "../../store/messaging/actions";
import { AppState } from "../../store/rootReducer";
import {
  CHAT_MESSAGES,
  CHAT_MESSAGES_MARK_READ,
  MarkMessagesAsReadRequest,
} from "../../views/Messages/api";
import { ChatDataItem, Message } from "../../views/Messages/models";
import { RoleNameEnum } from "../../views/VenueStaff/main/RoleTypes";
import WebsocketClient from "../../websocket/WebsocketClient";
import { useCurrentVenueId } from "./useCurrentVenueId";
import { ReplyToUserReviewState } from "./useReplyToReview";
import { useRestApi } from "./useRestApi";

export function useMessaging(activeChatWindow?: number) {
  const venueId = useCurrentVenueId();
  const dispatch = useDispatch();
  const [, getData] = useRestApi<ChatDataItem[]>(CHAT_MESSAGES(venueId), "GET");
  const chatData = useSelector<AppState, Array<ChatDataItem>>(
    ({ messaging }) => messaging.chatData
  );

  const { roles } = useSelector<AppState, AuthState>((state) => state.auth);
  const { hasToken } = useSelector<AppState, AuthState>((state) => state.auth);
  const [, markMessagesAsRead] = useRestApi<any, MarkMessagesAsReadRequest>(
    CHAT_MESSAGES_MARK_READ(venueId),
    "PATCH"
  );

  const shouldMarkAsRead = useCallback(
    (currentMessageChatWindow: number, activeChatWindow?: number) =>
      location.pathname === getRouteWithSlash(Route.Messages) &&
      activeChatWindow === currentMessageChatWindow,
    []
  );

  const pushNewMessageToChats = useCallback(
    (newMessage: Message) => {
      dispatch(addNewMessage(newMessage, activeChatWindow));
    },
    [activeChatWindow, dispatch]
  );

  const markAsRead = useCallback(
    (userId = chatData[0]?.id, newActiveTabIndex?: number) => {
      if (!chatData.length) {
        return;
      }

      const currentMessageChatWindow = chatData.findIndex(
        (chatItem) => chatItem.id === userId
      );

      if (
        shouldMarkAsRead(
          currentMessageChatWindow,
          newActiveTabIndex || activeChatWindow
        )
      ) {
        markMessagesAsRead({ userId })
          .then(() => {
            const newData = [...chatData];

            if (newData[currentMessageChatWindow]) {
              newData[currentMessageChatWindow].messages = [
                ...newData[currentMessageChatWindow].messages.map((message) => {
                  message.isReadByYou = true;
                  return message;
                }),
              ];
            }

            dispatch(setChatData(newData));
          })
          .catch();
      }
    },
    [activeChatWindow, chatData, dispatch, markMessagesAsRead, shouldMarkAsRead]
  );

  const pushNewMessageFromUserToChats = useCallback(
    (newChatMessage: Message) => {
      const { userId } = newChatMessage;

      markAsRead(userId);
      pushNewMessageToChats(newChatMessage);
    },
    [markAsRead, pushNewMessageToChats]
  );

  const replyToReview = useCallback(
    (chatDataFromServer: ChatDataItem[]) => {
      const replyToReviewState: ReplyToUserReviewState | undefined =
        window.history.state?.state;
      const newChatData = [...chatDataFromServer];

      if (
        replyToReviewState?.userAvatar !== undefined &&
        replyToReviewState?.username &&
        replyToReviewState?.userId
      ) {
        const chatWithUserIndex = newChatData.findIndex(
          (chatItem) => chatItem.id === replyToReviewState.userId
        );

        dispatch(setActiveChat(chatWithUserIndex));

        window.history.replaceState(undefined, document.title);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      !roles.includes(RoleNameEnum.superAdmin) &&
      !roles.includes(RoleNameEnum.discount) &&
      hasToken &&
      !!venueId
    ) {
      getData()
        .then((data) => {
          if (!data) {
            return;
          }
          replyToReview([...data]);

          dispatch(setChatData(data));
        })
        .catch((e) => console.error(e));
    }
  }, [dispatch, getData, replyToReview, hasToken, roles, venueId]);

  useEffect(() => {
    WebsocketClient.unsubscribeFromChatMessages();
    WebsocketClient.subscribeForChatMessages(pushNewMessageFromUserToChats);

    return () => {
      WebsocketClient.unsubscribeFromChatMessages();
    };
  }, [pushNewMessageFromUserToChats, pushNewMessageToChats, venueId]);

  return {
    markAsRead,
    pushNewMessageToChats,
  };
}
