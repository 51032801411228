import { Form } from "../../../../../../../../../../common/models";
import { ValidationRules } from "../../../../../../../../../../utils/validation";

export type HappyHourMainFormField = "menuUrl" | "description";

export type HappyHourMainFormValues = {
  [key in HappyHourMainFormField]: string;
};

export type HappyHourMainFormErrors = {
  [key in HappyHourMainFormField]: string;
};

export interface HappyHourMainForm extends Form {
  values: HappyHourMainFormValues;
  errors: HappyHourMainFormErrors;
  touched: HappyHourMainFormField[];
}

export const HappyHourMainForm: HappyHourMainForm = {
  values: {
    menuUrl: "",
    description: "",
  },
  errors: {
    menuUrl: "",
    description: "",
  },
  touched: [],
  isValid: true,
};

export type HappyHourMainFormSchemaType = Record<
  HappyHourMainFormField,
  ValidationRules
>;

export const HappyHourMainFormSchema: HappyHourMainFormSchemaType = {
  description: {
    fieldName: "Description",
    required: true,
    length: {
      min: 2,
      max: 512,
    },
  },
  menuUrl: {
    fieldName: "Menu Url",
    required: false,
    type: "isUrl",
  },
};
