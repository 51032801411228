import { Tab, Tabs, Typography, useTheme } from "@material-ui/core";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import ConfirmDialog from "../../../../common/components/dialogs/ConfirmDialog";
import { ConfirmModalType } from "../../../../common/components/dialogs/ConfirmDialog/Props";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import TabPanel from "../../../../common/components/TabPanel";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { isPackagesDisabled } from "../../../../config";
import dictionary from "../../../../i18n/en_US/dictionary";
import { cartItemAdd, cartItemRemove } from "../../../../store/cart/actions";
import { CartState } from "../../../../store/cart/reducer";
import { getOwnVenues } from "../../../../store/currentVenue/actions";
import { AppState } from "../../../../store/rootReducer";
import MiniCart from "../../../Cart/components/MiniCart";
import {
  AddPackageRequest,
  PURCHASE_PACKAGES,
} from "../../../Cart/components/PaymentConfirmationPopup/api";
import {
  CANCEL_PLAN_PACKAGE,
  GET_ALL_PACKEGES,
  GET_VENUE_PACKAGES,
} from "../../api";
import {
  Package,
  PackageCategory,
  PackageGroup,
  PackageUID,
} from "../../models";
import FullAccessPackageCard from "../FullAccessPackageCard";
import PackageAlert from "../PackageAlert";
import PlanTab from "../PlanTab";
import Props from "./Props";
import { useStyles } from "./styles";

// indexes (hashes order) is important
const tabHashes = ["#analytics", "#profile-upgrades"];

const PlanTabs: FC<Props> = (props: Props) => {
  const { onChangeTab } = props;
  const dispatch = useDispatch();
  const { showAlert } = useContext(AlertContext);
  const venueId = useCurrentVenueId();
  const theme = useTheme();
  const [isOpenCart, setIsOpenCart] = useState(false);
  const [currentCancelPackage, setCurrentCancelPackage] = useState<
    Package | undefined
  >();
  const [packageAlert, setPackageAlert] = useState("");
  const [fullAccessPackage, setFullAccessPackage] = useState<
    Package | undefined
  >();
  const cartState = useSelector<AppState, CartState>((state) => state.cart);
  const { hash } = window.location;
  const initialTabIndex = hash
    ? tabHashes.indexOf(hash) !== -1
      ? tabHashes.indexOf(hash)
      : 0
    : 0;
  const [currentTab, setCurrentTab] = React.useState(initialTabIndex);
  const classes = useStyles();
  const [{ data, loading }, getPackages] = useRestApi<Package[]>(
    GET_ALL_PACKEGES,
    "GET"
  );
  const [{ loading: activatePackageLoading }, activatePackage] = useRestApi<
    any,
    AddPackageRequest
  >(PURCHASE_PACKAGES, "POST");
  const [{ data: venuePackages }, getVenuePackages] = useRestApi<Package[]>(
    GET_VENUE_PACKAGES(venueId),
    "GET"
  );

  const [, cancelPlanSubscription] = useRestApi(
    CANCEL_PLAN_PACKAGE(venueId, currentCancelPackage?.id || 0),
    "DELETE"
  );

  const toggleCart = () => {
    setIsOpenCart((prev) => !prev);
  };

  const handleCloseCart = () => {
    setIsOpenCart(false);
  };

  const showCancelPackageModal = (packageId: number) => {
    if (data) {
      setCurrentCancelPackage(data.find((pack) => pack.id === packageId));
    }
  };

  const validateCart = (packageId: number) => {
    if (data) {
      const currentPackage = data.find((pack) => pack.id === packageId);
      const cartItems = cartState[venueId];

      if (!currentPackage) {
        handleShowPackageAlert(dictionary.plan.packageNotExist);

        return false;
      }

      if (currentPackage.group === PackageGroup.VENUE_BOOST) {
        return true;
      }

      if (
        venuePackages?.some(
          (pack) => pack.packageUid === PackageUID.FULL_ACCESS
        )
      ) {
        handleShowPackageAlert(
          dictionary.plan.fullAccessPurchased(currentPackage.name)
        );

        return false;
      }

      if (cartItems?.length) {
        if (currentPackage.packageUid === PackageUID.FULL_ACCESS) {
          handleShowPackageAlert(dictionary.plan.noFullAccessWithOtherPackages);

          return false;
        } else {
          const packagesInCart = data.filter((pack) =>
            cartItems.includes(pack.id)
          );

          if (
            packagesInCart.some(
              (pack) => pack.packageUid === PackageUID.FULL_ACCESS
            )
          ) {
            handleShowPackageAlert(
              dictionary.plan.noOtherPackagesWithFullAccess(currentPackage.name)
            );

            return false;
          }
        }
      }

      if (
        venuePackages?.length &&
        currentPackage.packageUid === PackageUID.FULL_ACCESS
      ) {
        handleShowPackageAlert(
          dictionary.plan.fullAccessWithOtherPackagesConflictWarning(
            venuePackages.map((pack) => pack.name)
          )
        );
      }
    }

    return true;
  };

  const handleAddPackageToCart = (packageId: number) => {
    if (isPackagesDisabled) {
      return activatePackage({ packagesIds: [packageId], venueId })
        .then(fetchAdminPackages)
        .catch();
    }

    if (validateCart(packageId)) {
      dispatch(cartItemAdd(venueId, packageId));
    }
  };

  const packages = data
    ? data.filter((pack) => pack.packageUid !== PackageUID.FULL_ACCESS)
    : [];

  /**
   * If network error occurred when user added package and didn't receive
   * success response - then items still in a cart, but already activated
   * so need to refresh cart when current venue packages received
   */
  const refreshCart = useCallback(
    (venuePacks: Package[] | undefined) => {
      if (!venuePacks) {
        return;
      }

      cartState[venueId]?.forEach((packId) => {
        if (venuePacks.some((pack) => pack.id === packId)) {
          dispatch(cartItemRemove(venueId, packId));
        }
      });
    },
    [cartState, dispatch, venueId]
  );

  const fetchAdminPackages = useCallback(() => {
    getVenuePackages()
      .then((data) => {
        if (refreshCart) {
          refreshCart(data);
        }

        dispatch(getOwnVenues());
      })
      .catch((e) => console.error(e));
  }, [dispatch, getVenuePackages, refreshCart]);

  useEffect(() => {
    getPackages()
      .then((packs) => {
        if (!packs) {
          return;
        }

        const fullAccessPack = packs.find(
          (pack) => pack.packageUid === PackageUID.FULL_ACCESS
        );

        const venueBoostPackages = packs.filter(
          (pack) => pack.group === PackageGroup.VENUE_BOOST
        );

        console.log("venueBoostPackages -->>> ", venueBoostPackages);

        if (fullAccessPack) {
          setFullAccessPackage(fullAccessPack);
        }
      })
      .catch((e) => console.error(e));
  }, [getPackages]);

  useEffect(() => {
    fetchAdminPackages();
    // Need to refresh user packages on every cart update
  }, [fetchAdminPackages, cartState]);

  const changeTab = (index: number) => {
    setCurrentTab(index);
    window.location.hash = tabHashes[index];

    if (onChangeTab) {
      onChangeTab(tabHashes[index], index);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<Record<any, any>>,
    newValue: number
  ) => {
    changeTab(newValue);
  };

  const handleChangeIndex = (index: number) => {
    changeTab(index);
  };

  const handleShowPackageAlert = (message: string) => {
    setPackageAlert(message);
  };

  const handleHidePackageAlert = () => {
    setPackageAlert("");
  };

  const handleHideCancelPackageModal = () => {
    setCurrentCancelPackage(undefined);
  };

  const isActiveFullAccess = () => {
    if (!venuePackages || !fullAccessPackage) {
      return false;
    }

    return venuePackages.some((pack) => pack?.id === fullAccessPackage.id);
  };

  const handleCancelPackageSucceed = () => {
    fetchAdminPackages();
    handleHideCancelPackageModal();
  };

  const handleConfirmPackageCancellation = () => {
    cancelPlanSubscription()
      .then(() => {
        showAlert(dictionary.plan.cancelPackageSucceed, "success");

        handleCancelPackageSucceed();
      })
      .catch(() => {
        showAlert(dictionary.plan.cancelPackageError, "error");
      })
      .finally(handleHideCancelPackageModal);
  };

  return (
    <div className={classes.root}>
      <FullAccessPackageCard
        active={isActiveFullAccess()}
        package={fullAccessPackage}
        enablePackage={handleAddPackageToCart}
        disablePackage={showCancelPackageModal}
        toggleCart={toggleCart}
      />
      <div className={classes.tabsWrapper}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="plan tabs">
          <Tab
            classes={{
              root: classes.tabRoot,
            }}
            label={dictionary.plan.tabLabelAnalytics}
          />
          {/* temporarily hidden profile upgrades packages */}
          {/* <Tab
            classes={{
              root: classes.tabRoot,
            }}
            label={dictionary.plan.tabLabelProfileUpgrades}
          />*/}
        </Tabs>
      </div>
      <SwipeableViews
        className={classes.swipeableContainer}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={currentTab}
        onChangeIndex={handleChangeIndex}>
        <TabPanel
          hideNotActive
          value={currentTab.toString()}
          index={"0"}
          dir={theme.direction}>
          <PlanTab
            userPackages={venuePackages}
            enablePackage={handleAddPackageToCart}
            disablePackage={showCancelPackageModal}
            packages={packages.filter(
              (packageItem) =>
                packageItem.category === PackageCategory.ANALYTICS
            )}
            toggleCart={toggleCart}
          />
        </TabPanel>
        {/* temporarily hidden profile upgrades packages */}
        {/*<TabPanel
          hideNotActive
          value={currentTab.toString()}
          index={"1"}
          dir={theme.direction}>
          <PlanTab
            userPackages={venuePackages}
            enablePackage={handleAddPackageToCart}
            disablePackage={showCancelPackageModal}
            packages={packages.filter(
              (packageItem) =>
                packageItem.category === PackageCategory.PROFILE_UPGRADES
            )}
            toggleCart={toggleCart}
          />
          </TabPanel>*/}
      </SwipeableViews>
      {(loading || activatePackageLoading) && <LoadingIndicator withMask />}
      <PackageAlert open={!!packageAlert} onClose={handleHidePackageAlert}>
        <Typography align={"center"}>{packageAlert}</Typography>
      </PackageAlert>
      <ConfirmDialog
        open={!!currentCancelPackage}
        onClose={handleHideCancelPackageModal}
        onConfirm={handleConfirmPackageCancellation}
        type={ConfirmModalType.CONFIRM}
        subtitle={dictionary.plan.cancelPackageConfirmationMessage(
          currentCancelPackage?.name
        )}
        message={""}
      />
      <MiniCart isOpen={isOpenCart} onClose={handleCloseCart} />
    </div>
  );
};

export default PlanTabs;
