import { Drawer, Hidden, IconButton } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import Logo from "../../../../common/components/Logo";
import OcietyIcon from "../../../../common/components/OcietyIcon";
import VenueSelect from "../../../../common/components/VenueSelect";
// eslint-disable-next-line max-len
import UpgradePlanWidget from "../../../../common/components/widgets/UpgradePlanWidget";
import { useIsManager } from "../../../../common/hooks/useIsManager";
import { useIsSuperAdmin } from "../../../../common/hooks/useIsSuperAdmin";
import { useGlobalStyles } from "../../../../common/styles";
import SidebarNav from "./components/SidebarNav";
import pages from "./pages";
import Props from "./Props";
import { useStyles } from "./styles";

export const SIDEBAR_WIDTH = "290px";

const Sidebar: FC<Props> = (props: Props) => {
  const { open, variant, className, toggleSidebar, ...rest } = props;
  const isSuperAdmin = useIsSuperAdmin();
  const manager = useIsManager();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Drawer
      onBackdropClick={toggleSidebar}
      anchor="left"
      ModalProps={{
        className: classes.modal,
      }}
      classes={{
        paper: clsx({
          [classes.drawer]: true,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      className={classes.root}
      open={open}
      variant={variant}>
      <div {...rest} className={className}>
        <div className={clsx(globalClasses.flexRow_Center, classes.sidebarTop)}>
          <IconButton onClick={toggleSidebar}>
            <OcietyIcon name={"burgerMenu"} />
          </IconButton>
          <Logo withText wrapperClassName={classes.logoWrapper} />
        </div>
        {!isSuperAdmin && (
          <Hidden lgUp>
            <VenueSelect
              className={classes.venueSelect}
              classes={{
                root: classes.venueSelectRoot,
                selectWrapper: classes.venueSelectWrapper,
                notActiveMessageRoot: classes.venueSelectNotActiveMessageRoot,
              }}
            />
          </Hidden>
        )}
        <SidebarNav
          isSidebarOpen={open}
          className={classes.nav}
          pages={pages}
          toggleSidebar={toggleSidebar}
        />
        {!isSuperAdmin && !manager && (
          <div>
            <UpgradePlanWidget isSidebarOpen={open} />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default Sidebar;
