import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type ClassNames =
  | "root"
  | "calendarWrapper"
  | "todayButton"
  | "selectDateTitle";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        maxWidth: 372,
        margin: "0 auto",
      },
      calendarWrapper: {
        boxSizing: "border-box",
        flexDirection: "column",
        padding: theme.spacing(3, 1),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: theme.spacing(5),
      },
      todayButton: {
        margin: "-36px 41px 0 auto",
      },
      selectDateTitle: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 600,
        marginBottom: theme.spacing(2),
      },
    })
);
