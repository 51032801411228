import { ViewsGenderData } from "./api";

export const chartDataMock: ViewsGenderData[] = [
  {
    gender: "male",
    value: 34,
  },
  {
    gender: "female",
    value: 30,
  },
  {
    gender: "non-binary",
    value: 15,
  },
  {
    gender: "prefer not to say",
    value: 36,
  },
  {
    gender: "other",
    value: 12,
  },
];
