export const BRAINTREE_GET_CLIENT_ACCESS_TOKEN = "payment/client-token";
export const BRAINTREE_SAVE_DEFAULT_PAYMENT_METHOD =
  "payment/payment-method-save";

export interface GetClientTokenResponse {
  clientToken: string;
}

export interface SaveDefaultPaymentMethodResponse {
  paymentMethodNonce: string;
  venueId: number;
}
