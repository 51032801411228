import { Divider } from "@material-ui/core";
import React, { FC, useContext, useState } from "react";
import { useSelector } from "react-redux";

import { AlertContext } from "../../../../../../../common/components/Alert/AlertContextProvider";
import ConfirmDialog from "../../../../../../../common/components/dialogs/ConfirmDialog";
import { ConfirmModalType } from "../../../../../../../common/components/dialogs/ConfirmDialog/Props";
import OcietyDialogTitle from "../../../../../../../common/components/dialogs/OcietyDIalogTitle";
import { FileUrlWithId } from "../../../../../../../common/models";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { CurrentVenueState } from "../../../../../../../store/currentVenue/reducer";
import { AppState } from "../../../../../../../store/rootReducer";
import { deletePromotion } from "../../api";
import { PromotionFormDialogContext } from "../../PromotionFormDialogContextProvider";
import { PromotionKind } from "../PromotionFormDialogActions/PromotionKind.enum";
import {
  handleDeletePromotionPhoto,
  handleDeletePromotionVideo,
} from "../PromotionFormDialogContent/components/BasicInfoPromotionStep/components/FileUploaderPromotion/api";
import Props from "./Props";

function getDialogTitle(
  currentStep: number,
  kind: PromotionKind,
  editMode?: boolean
): string {
  if (currentStep === 1) {
    return dictionary.deals.createDealForm.addNewDeals;
  }

  let title = "";

  if (kind === PromotionKind.DEAL) {
    title = dictionary.deals.createDealForm.addOcietyDeal;
    if (editMode) {
      title = dictionary.deals.createDealForm.editOcietyDeal;
    }
  }

  if (kind === PromotionKind.SPECIAL) {
    title = dictionary.deals.createDealForm.addSpec;
    if (editMode) {
      title = dictionary.deals.createDealForm.editSpec;
    }
  }

  if (kind === PromotionKind.VOUCHER) {
    title = dictionary.deals.createDealForm.addOcietyVoucher;
    if (editMode) {
      title = dictionary.deals.createDealForm.editOcietyVoucher;
    }
  }

  return title;
}

const PromotionFormDialogTitle: FC<Props> = (props: Props) => {
  const {
    editMode,
    closeDialog,
    propsPromotionId,
    setDietaryRestrictions,
    setAgeGroupId,
  } = props;
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const { showAlert } = useContext(AlertContext);
  const {
    resetForm,
    setPhotosWithIds,
    setVideosWithIds,
    promotionId,
    numberOfMediaInEditMode,
    setNumberOfMediaInEditMode,
    currentStep,
  } = useContext(PromotionFormDialogContext);

  const { currentVenue } = useSelector<AppState, CurrentVenueState>(
    (appState) => appState.currentVenue
  );
  const venueId: number = currentVenue ? currentVenue.id : 0;

  const handleDeleteVideo = (media: FileUrlWithId) => {
    handleDeletePromotionVideo(venueId, media.id).catch((e) => console.log(e));
    setVideosWithIds([]);
  };
  const handleDeletePhoto = (media: FileUrlWithId) => {
    handleDeletePromotionPhoto(venueId, media.id).catch((e) => console.log(e));
    setPhotosWithIds([]);
  };

  const handleConfirmExitPromotionCreation = async () => {
    if (numberOfMediaInEditMode.length) {
      numberOfMediaInEditMode.map((media) => {
        media.url.includes("photo")
          ? handleDeletePhoto(media)
          : handleDeleteVideo(media);
      });
    }

    if (!propsPromotionId && promotionId) {
      await deletePromotion(promotionId, venueId)
        .then(() => {
          showAlert(
            dictionary.deals.createDealForm.msgSuccessDeleted,
            "success"
          );
        })
        .catch(() => {
          showAlert(dictionary.deals.createDealForm.msgErrorDeleting, "error");
        });
    }

    closeAndReset();
  };

  const closeAndReset = () => {
    setAgeGroupId([]);
    setDietaryRestrictions([]);
    setNumberOfMediaInEditMode([]);
    closeDialog();
    resetForm();
  };

  const { mainForm } = useContext(PromotionFormDialogContext);

  return (
    <div>
      <OcietyDialogTitle
        id="alert-dialog-title"
        onClose={() => setIsConfirmDialogOpen(true)}>
        {getDialogTitle(
          currentStep,
          mainForm.form.values.kind as PromotionKind,
          editMode
        )}
      </OcietyDialogTitle>
      <Divider />
      <ConfirmDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={handleConfirmExitPromotionCreation}
        subtitle={
          propsPromotionId
            ? dictionary.deals.createDealForm.popupWarningOnCloseEditModeMessage
            : dictionary.deals.createDealForm.popupWarningOnClose
        }
        title={
          propsPromotionId
            ? dictionary.deals.createDealForm.popupWarningOnCloseEditModeTitle
            : undefined
        }
        type={ConfirmModalType.YES_DELETE}
        message={" \n  "}
        yesTypeAdditionalText={propsPromotionId ? "Leave" : "Delete"}
      />
    </div>
  );
};
export default PromotionFormDialogTitle;
