import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import { colors } from "../../../../../../theme/colors";
import Props from "./Props";

const PaginationPage: FC<Props> = (props: Props) => {
  const { count, rowStart, rowEnd } = props;

  if (count === 0) {
    return null;
  }

  return (
    <div style={{ width: 150, paddingTop: 35 }}>
      <Typography variant={"body2"} style={{ color: colors.dark.main }}>
        {rowStart} - {rowEnd} of {count}
      </Typography>
    </div>
  );
};
export default PaginationPage;
