export const GET_VENUE_VIEWS_GENDER = "reporting/views-by-month-gender";

export interface ViewsGenderData {
  gender: string;
  value: number;
}

export interface GetVenueViewsGenderResponse {
  stats: ViewsGenderData[];
}
