import { FormControl, InputLabel } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { FC } from "react";

import dictionary from "../../../../i18n/en_US/dictionary";
import { POS_KEY } from "../../../models";
import Props from "./Props";

interface SelectOptionsPOS {
  value: POS_KEY;
  label: string;
}

const SelectOptionsPOS: SelectOptionsPOS[] = [
  {
    label: "SquareUp",
    value: POS_KEY.SQUARE_UP,
  },
  {
    label: "Clover",
    value: POS_KEY.CLOVER,
  },
  {
    label: "Vend",
    value: POS_KEY.VEND,
  },
];

const SelectPOS: FC<Props> = (props: Props) => {
  const { onChange, value } = props;
  const handleChange = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <FormControl variant={"outlined"} fullWidth>
      <InputLabel>{dictionary.pos.selectPosLabel}</InputLabel>
      <Select
        fullWidth
        label={dictionary.pos.selectPosLabel}
        value={value}
        onChange={handleChange}>
        {SelectOptionsPOS.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectPOS;
