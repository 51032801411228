export const AustriaStatesMapping = {
  Burgenland: "Burgenland",
  Carinthia: "Kärnten",
  "Lower Austria": "Niederösterreich",
  "Upper Austria": "Oberösterreich",
  Salzburg: "Land Salzburg",
  Styria: "Steiermark",
  Tirol: "Tirol",
  Vorarlberg: "Vorarlberg",
  Vienna: "Wien",
};
