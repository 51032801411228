import { DineroSnapshot } from "dinero.js";

export interface PromoCodeResponseItem {
  id: number;
  couponCode: string;
  value: number;
  start: string;
  end: string;
  usages: number;
}
export enum PromoCodesType {
  STANDARD = "STANDARD",
  FIRST_TIME = "FIRST_TIME",
}

export interface CreateCouponCode {
  type: PromoCodesType;
  couponCode: string;
  value: DineroSnapshot<number> | number;
  start: string;
  end: string;
  minimumAmount: DineroSnapshot<number> | number;
  usageLimit: number;
}

export interface CouponCodeResponse {
  type: PromoCodesType;
  couponCode: string;
  value: number;
  start: string;
  end: string;
  minimumAmount: number;
  usageLimit: number;
}
