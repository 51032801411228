// eslint-disable-next-line max-len
import { Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import OcietyIcon from "../../../../common/components/OcietyIcon";
// eslint-disable-next-line max-len
import { OcietyIconName } from "../../../../common/components/OcietyIcon/iconsMapping";
import { useGlobalStyles } from "../../../../common/styles";
import dictionary from "../../../../i18n/en_US/dictionary";
import { useStyles } from "./styles";

interface ContactData {
  label: string;
  contact: string;
  iconName: OcietyIconName;
}

const contacts: ContactData[] = [
  {
    label: dictionary.contactUs.phoneNumberLabel,
    contact: "+1-212-555-1212",
    iconName: "phone",
  },
  {
    label: dictionary.contactUs.emailAddressLabel,
    contact: "help@ociety.com",
    iconName: "email",
  },
];

const ContactUsSidebar: FC = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const renderContactItem = ({ label, contact, iconName }: ContactData) => (
    <div
      key={label}
      className={clsx(globalClasses.flexRow_Center, classes.contactItem)}>
      <OcietyIcon name={iconName} className={classes.contactIcon} />
      <div className={globalClasses.flexColumn}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.contact}>{contact}</Typography>
      </div>
    </div>
  );

  return (
    <Paper className={classes.root}>{contacts.map(renderContactItem)}</Paper>
  );
};

export default ContactUsSidebar;
