import { RadioGroup, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const OcietyRadioGroup: FC<Props> = (props: Props) => {
  const { options, value: selectedValue, onChange, disabled } = props;

  const classes = useStyles();

  const checkedIcon = () => <div className={classes.checkedIcon} />;
  const uncheckedIcon = () => <div className={classes.uncheckedIcon} />;

  return (
    <RadioGroup value={selectedValue} onChange={onChange}>
      {options.map(({ value, label }) => {
        const selected = selectedValue === value;

        return (
          <FormControlLabel
            className={clsx(
              classes.optionLabel,
              selected && classes.optionLabelSelected,
              !selected && disabled && classes.optionLabelDisabled
            )}
            key={value}
            value={value}
            disabled={disabled}
            control={
              <Radio checkedIcon={checkedIcon()} icon={uncheckedIcon()} />
            }
            label={
              <Typography className={classes.labelText}>{label}</Typography>
            }
          />
        );
      })}
    </RadioGroup>
  );
};

export default OcietyRadioGroup;
