import { http } from "../../config/api";
import UrlUtils from "../../utils/UrlUtils";

export const REPORTED_MEDIA_ACCEPT = "/venue/delete-photo/0";
export const REPORTED_MEDIA_DECLINE = "/venue/delete-photo-report";

const REPORTED_MEDIA_ALL = (limit: number, skip?: number) => {
  const url = `/venue/report-photos/0`;

  const queryParams: {
    limit?: number;
    skip?: number;
    search?: string;
  } = {};

  if (limit) {
    queryParams.limit = limit;
  }

  if (skip) {
    queryParams.skip = skip;
  }

  // @ts-ignore
  const query = "?" + UrlUtils.buildQuery(queryParams);

  return url + query;
};

export const getAllReportedMedia = async (
  limit: number,
  skip?: number
): Promise<any> =>
  http
    .get(REPORTED_MEDIA_ALL(limit, skip))
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
