import { Form } from "../../../../../../../../../../common/models";
import { ValidationRules } from "../../../../../../../../../../utils/validation";

export type HappyHourMainFormField =
  | "filesPaths"
  | "menuUrl"
  | "venueId"
  | "lengthMenuFiles"
  | "lengthDeals";

export type HappyHourMainFormValues = {
  [key in HappyHourMainFormField]: string;
};

export type HappyHourMainFormErrors = {
  [key in HappyHourMainFormField]: string;
};

export interface HappyHourMainForm extends Form {
  values: HappyHourMainFormValues;
  errors: HappyHourMainFormErrors;
  touched: HappyHourMainFormField[];
}

export const HappyHourMainForm: HappyHourMainForm = {
  values: {
    menuUrl: "",
    venueId: "",
    lengthMenuFiles: "0",
    lengthDeals: "0",
    filesPaths: "",
  },
  errors: {
    menuUrl: "",
    venueId: "",
    lengthMenuFiles: "",
    lengthDeals: "",
    filesPaths: "",
  },
  touched: [],
  isValid: true,
};

export type HappyHourMainFormSchemaType = Record<
  HappyHourMainFormField,
  ValidationRules
>;

export const HappyHourMainFormSchema: HappyHourMainFormSchemaType = {
  menuUrl: {
    fieldName: "Menu Url",
    required: false,
    type: "isUrl",
  },
  filesPaths: {
    fieldName: "Linked Files",
    required: false,
  },
  venueId: {
    fieldName: "Venue Name",
    type: "number+",
    required: true,
  },
  lengthDeals: {
    fieldName: "Happy Hours Deals",
    type: "atLeastOne",
    required: true,
  },
  lengthMenuFiles: {
    fieldName: "Menu Files",
    type: "atLeastOne",
    required: true,
  },
};
