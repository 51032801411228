import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "rootCC"
  | "rootPayPal"
  | "paymentVendor"
  | "label"
  | "editButton"
  | "cardNumber"
  | "cardAdditionalInfo"
  | "activeLabel"
  | "content"
  | "flexRow"
  | "flexColumn"
  | "buttonChangeIcon"
  | "smallArrowIcon"
  | "buttonChange";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      activeLabel: {
        backgroundColor: colors.green.primary,
        color: colors.white.primary,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 700,
        display: "block",
        padding: theme.spacing(0.1, 2),
        borderRadius: 4,
      },
      root: {
        position: "relative",
        backgroundColor: colors.black.primary,
        borderRadius: 18,
        padding: theme.spacing(5),
        [theme.breakpoints.up("sm")]: {
          width: 500,
        },
      },
      rootCC: {
        background: "url(/images/backgrounds/cc-bg.png) no-repeat center",
      },
      rootPayPal: {
        background: "url(/images/backgrounds/paypal-bg.png) no-repeat center",
      },
      editButton: {
        border: "2px solid #F3F3F3",
        backgroundColor: colors.grey.bgUltraLight,
        borderRadius: 4,
        marginLeft: theme.spacing(1),
      },
      label: {
        color: colors.white.primary,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(20),
      },
      content: {
        maxWidth: 500,
        marginTop: theme.spacing(5),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      flexRow: {
        display: "flex",
        flex: 1,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      },
      flexColumn: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "100%",
      },
      cardNumber: {
        fontWeight: 700,
        textOverflow: "ellipsis",
        lineHeight: "38px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "100%",
        fontSize: theme.typography.pxToRem(38),
        color: colors.white.primary,
      },
      cardAdditionalInfo: {
        marginTop: theme.spacing(2),
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(16),
      },
      paymentVendor: {
        color: "rgba(255,255,255,.7)",
      },
      buttonChange: {
        textAlign: "left",
        borderRadius: 2,
        padding: theme.spacing(1.75),
        backgroundColor: colors.white.primary,
        textTransform: "none",
        fontWeight: 700,
        maxWidth: 266,
        marginTop: theme.spacing(5),
        color: colors.black.primary,
        fontSize: theme.typography.pxToRem(14),
        "&:hover, &:active, &:focus": {
          backgroundColor: colors.white.primary,
          boxShadow: "0 0 25px 0 rgba(0,0,0,0.25)",
        },

        "&:hover $smallArrowIcon": {
          [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(1),
          },
        },
      },
      buttonChangeIcon: {
        marginRight: 10,
        color: "red",
        fill: "red",
      },
      smallArrowIcon: {
        marginLeft: "auto",
        marginRight: 0,
        transition: theme.transitions.create("margin-right", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    })
);
