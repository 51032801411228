import { http } from "../../config/api";
import { GetAllConfigsResponse, SaveConfigRequest } from "./models";

const CORE_CONFIG = "config/all";
const CORE_CONFIG_SAVE = "config/save";

export const getAllConfigs = async (): Promise<GetAllConfigsResponse> =>
  http
    .get(CORE_CONFIG)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const saveConfig = async (saveConfigRequest: SaveConfigRequest) =>
  http
    .post(CORE_CONFIG_SAVE, saveConfigRequest)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });
