import { Grid } from "@material-ui/core";
import React, { FC } from "react";

import PromotionAdditionalFilters from "../PromotionAdditionalFilters";
import Age from "./components/Age";
import Gender from "./components/Gender";
import VenueImpressionsInSearch from "./components/VenueImpressionsInSearch";
import VenueMediaContentViews from "./components/VenueMediaContentViews";
import VenueUniqueViews from "./components/VenueUniqueViews";
import VenueViews from "./components/VenueViews";
import VenueViewsByDevice from "./components/VenueViewsByDevice";
import { useStyles } from "./styles";

const ReportingGeneralTab: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PromotionAdditionalFilters />
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <VenueViewsByDevice />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <VenueImpressionsInSearch />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <VenueViews />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <VenueUniqueViews />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Age />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Gender />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <VenueMediaContentViews />
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportingGeneralTab;
