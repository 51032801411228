import { ImpressionsData } from "./api";

export const chartDataMock: ImpressionsData[] = [
  {
    month: "Jan",
    impressionsCount: 1200,
  },
  {
    month: "Feb",
    impressionsCount: 874,
  },
  {
    month: "Mar",
    impressionsCount: 993,
  },
  {
    month: "Apr",
    impressionsCount: 600,
  },
  {
    month: "May",
    impressionsCount: 567,
  },
  {
    month: "Jun",
    impressionsCount: 1045,
  },
];
