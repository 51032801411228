import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../i18n/en_US/dictionary";
import { useStyles } from "./styles";

const MiniCartEmptyContent: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src="/images/backgrounds/cart-empty-bg.png" alt="cart-empty" />
      <Typography className={classes.emptyMessage}>
        {dictionary.plan.emptyCartMessage}
      </Typography>
    </div>
  );
};

export default MiniCartEmptyContent;
