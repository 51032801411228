import { SelectProps } from "@material-ui/core/Select";
import React from "react";

import SelectIcon from "../../common/components/SelectIcon";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MuiSelect: SelectProps = {
  color: "secondary",
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    // Next code fixed select items pop-in jumping
    // when user select items from list
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  },
  // eslint-disable-next-line react/display-name
  IconComponent: (iconProps) => (
    <SelectIcon {...iconProps} iconName={"caretDown"} />
  ),
};

export default MuiSelect;
