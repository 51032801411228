import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";

export type ClassNames =
  | "root"
  | "buttonGrouped"
  | "buttonGreen"
  | "buttonBlack"
  | "buttonGreenArrow";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {},
    buttonGrouped: {
      // "&:not(:last-child)": {
      //   borderRight: "3px solid black",
      // },
    },
    buttonDefault: {},
    buttonDefaultArrow: {},
    buttonGreen: {
      minWidth: 120,
      backgroundColor: colors.green.secondary,

      border: `1.5px solid ${colors.green.secondary}`,
      color: colors.white.primary,
      textTransform: "none",
      "&:hover": {
        backgroundColor: colors.green.secondary,
      },
      "&:$disabled": {
        opacity: ".7",
        color: colors.white.primary,
      },
      fontWeight: "bold",
      borderRadius: 4,
    },
    buttonGreenArrow: {
      // minWidth: 132,
      backgroundColor: colors.green.veryDark,
      border: `1.5px solid ${colors.green.veryDark}`,
      color: colors.white.primary,
      textTransform: "none",
      "&:hover": {
        backgroundColor: colors.green.veryDark,
      },
      "&:$disabled": {
        opacity: ".7",
        color: colors.white.primary,
      },
      fontWeight: "bold",
      borderRadius: 4,
    },
    buttonBlack: {
      minWidth: 120,
      backgroundColor: colors.dark.main,

      border: `1.5px solid ${colors.dark.main}`,
      color: colors.white.primary,
      textTransform: "none",
      "&:hover": {
        backgroundColor: colors.dark.main,
      },
      "&:$disabled": {
        opacity: ".7",
        color: colors.dark.main,
      },
      fontWeight: "bold",
      borderRadius: 4,
    },
    buttonBlackArrow: {
      backgroundColor: colors.dark.secondary,
      border: `1.5px solid ${colors.dark.secondary}`,
      color: colors.white.primary,
      textTransform: "none",
      "&:hover": {
        backgroundColor: colors.dark.secondary,
      },
      "&:$disabled": {
        opacity: ".7",
        color: colors.dark.secondary,
      },
      fontWeight: "bold",
      borderRadius: 4,
    },
    buttonRed: {
      minWidth: 120,
      backgroundColor: colors.red.primary,

      border: `1.5px solid ${colors.red.primary}`,
      color: colors.white.primary,
      textTransform: "none",
      "&:hover": {
        backgroundColor: colors.red.primary,
      },
      "&:$disabled": {
        opacity: ".7",
        color: colors.red.primary,
      },
      fontWeight: "bold",
      borderRadius: 4,
    },
    buttonRedArrow: {
      backgroundColor: colors.red.dark,
      border: `1.5px solid ${colors.red.dark}`,
      color: colors.white.primary,
      textTransform: "none",
      "&:hover": {
        backgroundColor: colors.red.dark,
      },
      "&:$disabled": {
        opacity: ".7",
        color: colors.red.secondary,
      },
      fontWeight: "bold",
      borderRadius: 4,
    },
    buttonBlue: {
      minWidth: 120,
      backgroundColor: colors.blue.primary,

      border: `1.5px solid ${colors.blue.primary}`,
      color: colors.white.primary,
      textTransform: "none",
      "&:hover": {
        backgroundColor: colors.blue.primary,
      },
      "&:$disabled": {
        opacity: ".7",
        color: colors.blue.primary,
      },
      fontWeight: "bold",
      borderRadius: 4,
    },
    buttonBlueArrow: {
      backgroundColor: colors.blue.dark,
      border: `1.5px solid ${colors.blue.dark}`,
      color: colors.white.primary,
      textTransform: "none",
      "&:hover": {
        backgroundColor: colors.blue.dark,
      },
      "&:$disabled": {
        opacity: ".7",
        color: colors.blue.dark,
      },
      fontWeight: "bold",
      borderRadius: 4,
    },
    buttonYellow: {
      minWidth: 120,
      backgroundColor: colors.yellow.primary,

      border: `1.5px solid ${colors.yellow.primary}`,
      color: colors.white.primary,
      textTransform: "none",
      "&:hover": {
        backgroundColor: colors.yellow.primary,
      },
      "&:$disabled": {
        opacity: ".7",
        color: colors.yellow.primary,
      },
      fontWeight: "bold",
      borderRadius: 4,
    },
    buttonYellowArrow: {
      backgroundColor: colors.yellow.dark,
      border: `1.5px solid ${colors.yellow.dark}`,
      color: colors.white.primary,
      textTransform: "none",
      "&:hover": {
        backgroundColor: colors.yellow.dark,
      },
      "&:$disabled": {
        opacity: ".7",
        color: colors.yellow.dark,
      },
      fontWeight: "bold",
      borderRadius: 4,
    },
  })
);
