import MomentUtils from "@date-io/moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import dictionary from "../../../i18n/en_US/dictionary";
import { setFormValue } from "../../../store/forms/actions";
import { AppState } from "../../../store/rootReducer";
import { WorkingDaysForm } from "../../../views/VenueSettings/components/VenueCommonData/form/WorkingDaysForm";
import WorkingDayRow from "./components/WorkingDayRow";
import { ApplyToAllDays } from "./models";
import Props from "./Props";
import { defaultDayProps, defaultTimeProps } from "./utils";

const WorkingDaysPicker: FC<Props> = (props: Props) => {
  const {
    accordionSummary,
    disableAddNewHoursPair,
    disableApplyToAll,
    propsDay,
    isRenderAccordion = true,
  } = props;
  const form = useSelector<AppState, WorkingDaysForm>(
    (state) => state.forms.workingDays
  );
  const dispatch = useDispatch();

  const [applyAllDays, setApplyAllDays] = useState<ApplyToAllDays>({
    enable: true,
    hours: [defaultTimeProps()],
  });

  const getDefaults = (isOpen: boolean, isCustom?: boolean) =>
    moment.weekdays(true).map((day) => ({
      ...defaultDayProps(isOpen, isCustom),
      day,
      id: uuid(),
    }));

  const setDefaults = useCallback(
    (isOpen: boolean, isCustom?: boolean) => {
      dispatch(
        setFormValue(
          "workingDays",
          "workingDays",
          getDefaults(isOpen, isCustom)
        )
      );
    },
    [dispatch]
  );

  const setPropsDays = useCallback(
    () => dispatch(setFormValue("workingDays", "workingDays", propsDay)),
    [dispatch, propsDay]
  );

  const setAllDaysOn = useCallback(() => {
    setDefaults(true);
  }, [setDefaults]);

  const displayDays = () =>
    form.values.workingDays?.map((day) => (
      <WorkingDayRow
        key={day.id}
        {...day}
        disableAddNewHoursPair={disableAddNewHoursPair}
      />
    ));

  useEffect(() => {
    if (propsDay) {
      setPropsDays();
    } else if (!form.values.workingDays?.length) {
      // If venue has no workdays, need to generate them
      setAllDaysOn();
    }
  }, [form.values.workingDays?.length, propsDay, setAllDaysOn, setPropsDays]);

  const RenderAccordion = ({ children }: any) => (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content">
        <Typography variant="h3">
          {accordionSummary ||
            dictionary.dataEntryVenues.addNewVenueDialogFormFields.workingDays}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );

  const RenderPicker = () => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container>
        {!disableApplyToAll && (
          <WorkingDayRow
            key={"apply-all-days"}
            day={dictionary.deals.applyToAllLabel}
            id={"apply-all-days"}
            isOpen={applyAllDays.enable}
            hours={applyAllDays.hours}
            setApplyAllDays={setApplyAllDays}
            applyAllDays={applyAllDays}
            disableAddNewHoursPair={disableAddNewHoursPair}
          />
        )}
        {form.values.workingDays ? (
          displayDays()
        ) : (
          <span>No data for working hours</span>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );

  return isRenderAccordion ? (
    <RenderAccordion>
      <RenderPicker />
    </RenderAccordion>
  ) : (
    <RenderPicker />
  );
};

export default WorkingDaysPicker;
