export const GET_VENUE_MEDIA_CONTENT_VIEWS = "reporting/media-content-views";

export interface MediaContentViewsData {
  month: string;
  images: number;
  videos: number;
}

export interface GetVenueMediaContentViewsResponse {
  stats: MediaContentViewsData[];
}
