import { ChipProps } from "@material-ui/core/Chip";
import React from "react";

import OcietyIcon from "../../common/components/OcietyIcon";

const MuiChip: ChipProps = {
  deleteIcon: <OcietyIcon name={"xSmallWhite"} viewBox={"0 0 10 10"} />,
};

export default MuiChip;
