import { FormGroup, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { Menu } from "@mui/base/Menu";
import { MenuItem as BaseMenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { styled } from "@mui/system";
import * as React from "react";
import { useContext, useState } from "react";

import ButtonBlack from "../../../../../../common/components/ButtonBlack";
import FilterButton from "../../../../../../common/components/FilterButton";
import OcietySwitch from "../../../../../../common/components/OcietySwitch";
import { ReactComponent as CloseChipIcon } from "../../../../../../common/svg/icons/close-chip-icon.svg";
import { ReactComponent as HiddenFieldsIcon } from "../../../../../../common/svg/icons/hidden-fields-icon.svg";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { TransactionsContext } from "../../../../providers";
import { useStyles } from "./styles";

export default function TableColumnManager() {
  const classes = useStyles();
  const { headCellsState, setHeadCellsState } = useContext(TransactionsContext);
  const [isShowAll, setIsShowAll] = useState<boolean>(true);
  const [isOpenedHiddenFields, setIsOpenedHiddenFields] =
    useState<boolean>(false);

  const handleOpenModal = () => {
    setIsOpenedHiddenFields(true);
  };

  const handleCloseModal = () => {
    setIsOpenedHiddenFields(false);
  };

  const onCheckedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newHeadCells = headCellsState.map((cell) => {
      if (cell.id === event.target.value) {
        return {
          ...cell,
          isSelected: checked,
        };
      }
      return cell;
    });
    setHeadCellsState(newHeadCells);
  };

  const handleChangeAll = () => {
    const newHeadCells = headCellsState.map((cell) => {
      return {
        ...cell,
        isSelected: isShowAll,
      };
    });
    setHeadCellsState(newHeadCells);
    setIsShowAll(!isShowAll);
  };

  const numberOfHiddenFields = headCellsState.filter(
    (cell) => !cell.isSelected
  ).length;

  return (
    <>
      <FilterButton
        mainIcon={<HiddenFieldsIcon />}
        label={dictionary.moneyForm.hiddenFields(numberOfHiddenFields)}
        handleButtonClick={handleOpenModal}
      />
      <Dialog open={isOpenedHiddenFields} onClose={handleCloseModal}>
        <Menu slots={{ listbox: Listbox }}>
          <MenuItem
            onClick={handleCloseModal}
            className={classes.showAllButton}>
            <CloseChipIcon />
          </MenuItem>
          <FormGroup>
            {headCellsState.map((field) => (
              <MenuItem key={field.id} className={classes.menuItemWrapper}>
                <OcietySwitch
                  value={field.id}
                  checked={field.isSelected}
                  onChange={onCheckedChange}
                />
                <Typography>{field.label}</Typography>
              </MenuItem>
            ))}
          </FormGroup>
          <MenuItem className={classes.showAllButton}>
            <ButtonBlack onClick={handleChangeAll}>
              {isShowAll
                ? dictionary.moneyForm.showAll
                : dictionary.moneyForm.hideAll}
            </ButtonBlack>
          </MenuItem>
        </Menu>
      </Dialog>
    </>
  );
}

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E6",
  700: "#0059B3",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  z-index: 1;
  `
);

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${menuItemClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[50]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }
  `
);
