import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "wrapper"
  | "betaDialogRoot"
  | "betaIcon"
  | "betaMessage"
  | "betaText";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(40 / 8),
      },
      wrapper: {
        height: "100%",
        maxWidth: "100%",
        padding: 30,
      },
      betaDialogRoot: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 32px 32px",
        maxWidth: 544,
      },
      betaIcon: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(3),
      },
      betaMessage: {
        marginBottom: 21,
        fontSize: 22,
        lineHeight: theme.typography.pxToRem(30),
        fontWeight: 700,
      },
      betaText: {
        fontSize: 18,
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: 600,
        color: colors.grey.additional,
      },
    })
);
