import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, AutocompleteInputChangeReason } from "@material-ui/lab";
import clsx from "clsx";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import OcietySwitch from "../../../../../../../../../../../../../common/components/OcietySwitch";
import ImageLineGraySalad from "../../../../../../../../../../../../../common/images/components/TabHappyHours/line-gray-salad.png";
import { ReactComponent as IconRadioButtonWithHole } from "../../../../../../../../../../../../../common/svg/icons/radio-button-with-hole.svg";
import dictionary from "../../../../../../../../../../../../../i18n/en_US/dictionary";
import { colors } from "../../../../../../../../../../../../../theme/colors";
import { otherSubcategoryBasesRules } from "../../../../../../../../../../../AdminVenue/dialogs/PromotionFormDialog/forms/main";
import { DealCardState } from "../../../../../../../../../../../types/enums/DealCardState.enum";
import IPromotionCategory from "../../../../../../../../../../../types/interfaces/IPromotionCategory.interface";
import { DialogContext } from "../../../../DialogContextProvider";
import {
  HappyHourAdvancedFormField,
  itemsNameSubcategoryBasesRules,
} from "../../../../forms/advanced";
import Props from "./Props";
import { useStyles } from "./styles";

const Secondary: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { promotionCategories, promotionSubCategories } = props;
  const {
    advancedForm,
    clearAdvancedForm,
    activeCardState,
    setActiveCardState,
    updateDealItem,
  } = useContext(DialogContext);
  const { form, setAndValidate, setSchemaRules, setFormValue } = advancedForm;

  useEffect(() => {
    if (updateDealItem) {
      updateDealItem.percent
        ? setActiveCardState(DealCardState.percent)
        : updateDealItem.rebatedPrice
        ? setActiveCardState(DealCardState.rebatedPrice)
        : updateDealItem.sellingPrice
        ? setActiveCardState(DealCardState.sellingPrice)
        : setActiveCardState(DealCardState.customTitle);
    }
  }, [setActiveCardState, updateDealItem]);

  const isOtherSubCategory = useCallback(() => {
    const otherId = promotionSubCategories.find((sub) => sub.name === "Other");

    return String(otherId?.id) === form.values.subCategoryId;
  }, [form.values.subCategoryId, promotionSubCategories]);

  useEffect(() => {
    if (isOtherSubCategory()) {
      setSchemaRules({
        otherSubcategoryName: {
          ...otherSubcategoryBasesRules,
          required: true,
        },
      });
    }
  }, [isOtherSubCategory, setSchemaRules]);

  useEffect(() => {
    if (updateDealItem?.name && activeCardState === DealCardState.customTitle)
      setAndValidate("customTitle", updateDealItem.name);
  }, [activeCardState, setAndValidate, updateDealItem?.name]);

  const handleChangeSubCategory = (
    _: React.ChangeEvent<any>,
    option: IPromotionCategory | null
  ) => {
    if (option) {
      handleSetAndValidateSubCategory(String(option.id));
      setAndValidate("subCategoryName", option.name);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearAdvancedForm(), []);

  const currentCategoryId = useMemo(() => {
    if (form.values.mainCategoryId) {
      return form.values.mainCategoryId;
    }
  }, [form.values.mainCategoryId]);

  const handleChangeCategory = (
    _: React.ChangeEvent<any>,
    option: IPromotionCategory | null
  ) => {
    if (option) {
      handleSetAndValidateCategories(String(option.id));
      setAndValidate("mainCategoryName", option.name);
    }
  };

  const handleSetAndValidateCategories = (value = ""): void => {
    setAndValidate("mainCategoryId", value);
    setAndValidate("subCategoryId", "");
  };

  const categoryAutoCompleteValue = useMemo(
    () =>
      promotionCategories.find(
        (category) => category.id.toString() == form.values.mainCategoryId
      ) || null,
    [form.values.mainCategoryId, promotionCategories]
  );

  const onCategoryDelete = (
    event: React.ChangeEvent<any>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === "clear" && value === "") {
      handleSetAndValidateCategories();
    }
  };

  const subCategoryAutoCompleteValue = useMemo(
    () =>
      promotionSubCategories.find(
        (category) => category.id.toString() == form.values.subCategoryId
      ) || null,
    [form.values.subCategoryId, promotionSubCategories]
  );

  useEffect(() => {
    if (updateDealItem && subCategoryAutoCompleteValue) {
      if (!form.values.subCategoryName)
        setAndValidate("subCategoryName", subCategoryAutoCompleteValue.name);
      if (!form.values.mainCategoryName)
        setAndValidate("mainCategoryName", subCategoryAutoCompleteValue.name);
    }
  }, [
    form.values.mainCategoryName,
    form.values.subCategoryName,
    setAndValidate,
    subCategoryAutoCompleteValue,
    updateDealItem,
  ]);

  const handleSetAndValidateSubCategory = (value = ""): void => {
    setAndValidate("subCategoryId", value);
    setAndValidate("otherSubcategoryName", "");
    setSchemaRules({
      otherSubcategoryName: {
        ...otherSubcategoryBasesRules,
        required: false,
      },
    });
  };

  const onSubCategoryDelete = (
    _: React.ChangeEvent<any>,
    value: string,
    event: string
  ) => {
    if (event === "clear" && value === "") {
      handleSetAndValidateSubCategory();
    }
  };

  const handleChangeFormNameField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAndValidate(
      event.target.name as HappyHourAdvancedFormField,
      event.target.value
    );
  };

  const chooseDealInputLabel = () => {
    switch (activeCardState) {
      case DealCardState.percent: {
        return "Deal Percentage";
      }
      case DealCardState.rebatedPrice: {
        return "Deal $ off";
      }
      case DealCardState.sellingPrice: {
        return "Deal Price";
      }
    }
  };

  useEffect(() => {
    if (activeCardState === DealCardState.customTitle) {
      advancedForm.setSchemaRules({
        itemsName: { ...itemsNameSubcategoryBasesRules, required: false },
      });
    } else {
      advancedForm.setSchemaRules({
        itemsName: itemsNameSubcategoryBasesRules,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCardState]);

  const DealInput = () => {
    return (
      <FormControl variant="outlined" style={{ width: "100%" }}>
        <InputLabel htmlFor="deal-input">{chooseDealInputLabel()}</InputLabel>
        <OutlinedInput
          name={activeCardState}
          error={!!form.errors[activeCardState]}
          onChange={handleChangeFormNameField}
          value={form.values[activeCardState]}
          id="deal-input"
          endAdornment={
            <InputAdornment position="end">
              {activeCardState === DealCardState.percent ? "%" : "$"}
            </InputAdornment>
          }
          labelWidth={100}
        />
        {!!form.errors[activeCardState] && (
          <FormHelperText error id="deal-input-error">
            {form.errors[activeCardState]}
          </FormHelperText>
        )}
      </FormControl>
    );
  };

  const ItemGridCard = ({
    title,
    titleExample,
    state,
    description,
  }: {
    title: string;
    titleExample: string;
    state: DealCardState;
    description: string;
  }) => {
    return (
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <div
          onClick={() => setActiveCardState(state)}
          className={clsx(
            classes.cardItem,
            state === activeCardState && "active"
          )}>
          <IconRadioButtonWithHole
            className={classes.iconRadioButtonWithHole}
          />
          <div>
            <img
              src={ImageLineGraySalad}
              alt=""
              className={classes.imageLineGraySalad}
            />
          </div>
          <div className={classes.cardItemContent}>
            <Typography variant="body1" className={classes.text1}>
              {title}
              <Typography
                variant="caption"
                className={classes.cardItemTextTitleExample}>
                {titleExample}
              </Typography>
            </Typography>
            <Typography className={classes.cardItemTextItemsName}>
              {description}
            </Typography>
            <Typography className={classes.cardItemTextDescription}>
              {form.values.description
                ? form.values.description
                : "The description will go here"}
            </Typography>
          </div>
        </div>
      </Grid>
    );
  };

  const ContainerGridCard = () => {
    const { rebatedPrice, percent, sellingPrice, customTitle, itemsName } =
      advancedForm.form.values;

    const description =
        categoryAutoCompleteValue?.name && subCategoryAutoCompleteValue?.name
          ? `${categoryAutoCompleteValue?.name}, ${subCategoryAutoCompleteValue?.name}`
          : "Category, Sub-category",
      productName = itemsName || "Product Name";

    return (
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography variant="body1" className={classes.text1}>
          {dictionary.deals.happyHours.dialog.cardView}
        </Typography>
        <Typography
          variant="caption"
          className={classes.containerGridCardTextCardViewDescription}>
          {dictionary.deals.happyHours.dialog.cardViewDescription}
        </Typography>
        <Grid container spacing={4} className={classes.root}>
          <ItemGridCard
            title={`${percent || 25}% off ${productName}`}
            titleExample={"(Ex. 25% off Drinks)"}
            state={DealCardState.percent}
            description={description}
          />
          <ItemGridCard
            title={`$${
              Number(rebatedPrice)?.toFixed(2) || 10
            } off ${productName}`}
            titleExample={"(Ex. $10 off Drinks)"}
            state={DealCardState.rebatedPrice}
            description={description}
          />
          <ItemGridCard
            title={`$${Number(sellingPrice)?.toFixed(2) || 10} ${productName}`}
            titleExample={"(Ex. $10 Drinks)"}
            state={DealCardState.sellingPrice}
            description={description}
          />
          <ItemGridCard
            title={`${customTitle || "Custom title"}`}
            titleExample={"(Ex. $12 Bottomless mimosa)"}
            state={DealCardState.customTitle}
            description={description}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={4} className={classes.root}>
        <ContainerGridCard />
        <Grid item container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Autocomplete
              id="promotion-category"
              fullWidth
              onChange={handleChangeCategory}
              value={categoryAutoCompleteValue}
              onInputChange={onCategoryDelete}
              groupBy={(option) => option.type}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={classes.muiFormLabel}
                  error={!!form.errors.mainCategoryId}
                  helperText={form.errors.mainCategoryId}
                  label={"Select Category"}
                  value={form.values.mainCategoryId}
                  name="mainCategoryId"
                  type="text"
                  variant="outlined"
                />
              )}
              options={promotionCategories}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Autocomplete
              id="promotion-sub-category"
              fullWidth
              value={subCategoryAutoCompleteValue}
              onChange={handleChangeSubCategory}
              onInputChange={onSubCategoryDelete}
              disabled={!currentCategoryId}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={classes.muiFormLabel}
                  error={!!form.errors.subCategoryId}
                  helperText={form.errors.subCategoryId}
                  label={dictionary.deals.subCategoriesLabel}
                  value={form.values.subCategoryId}
                  name="subCategoryId"
                  type="text"
                  variant="outlined"
                />
              )}
              options={promotionSubCategories}
            />
          </Grid>

          {isOtherSubCategory() && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                label={
                  dictionary.deals.createDealForm.provideSubcategoryNameIfOther
                }
                name="otherSubcategoryName"
                error={!!form.errors.otherSubcategoryName}
                helperText={form.errors.otherSubcategoryName}
                onChange={handleChangeFormNameField}
                type="text"
                value={form.values.otherSubcategoryName}
                variant="outlined"
                rows={1}
              />
            </Grid>
          )}

          {activeCardState !== DealCardState.customTitle && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                fullWidth
                className={classes.muiFormLabel}
                label={"Product Name"}
                name="itemsName"
                error={!!form.errors.itemsName}
                helperText={form.errors.itemsName}
                onChange={handleChangeFormNameField}
                value={form.values.itemsName}
                type="text"
                variant="outlined"
                rows={1}
              />
            </Grid>
          )}

          {activeCardState === DealCardState.customTitle ? (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                className={classes.muiFormLabel}
                fullWidth
                label={"Custom Title"}
                name="customTitle"
                error={!!form.errors.customTitle}
                helperText={form.errors.customTitle}
                onChange={handleChangeFormNameField}
                value={form.values.customTitle}
                type="text"
                variant="outlined"
                rows={1}
              />
            </Grid>
          ) : (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {DealInput()}
            </Grid>
          )}

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              className={classes.muiFormLabel}
              fullWidth
              label={"Description"}
              InputLabelProps={{ style: { pointerEvents: "auto" } }}
              name="description"
              error={!!form.errors.description}
              helperText={form.errors.description}
              onChange={handleChangeFormNameField}
              type="text"
              value={form.values.description}
              variant="outlined"
              multiline={true}
              rows={3}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Secondary;
