import {
  BarProps,
  LegendProps,
  LineProps,
  XAxisProps,
  YAxisProps,
} from "recharts";

import { chartPrimaryColor, colors } from "../../theme/colors";

export const YAxisDefaultProps: YAxisProps = {
  tickMargin: 15,
  tick: {
    fontSize: 12,
    fill: "#BAC4D1",
    fontWeight: "bold",
    fontFamily: "Nunito Sans",
  },
  axisLine: false,
  tickLine: false,
};

export const LineDefaultProps: Partial<LineProps> = {
  strokeWidth: 2,
  stroke: chartPrimaryColor,
  dot: { strokeWidth: 3, r: 1 },
};

export const XAxisDefaultProps: XAxisProps = {
  tickLine: false,
  tickMargin: 10,
  tick: {
    fontSize: 12,
    fill: colors.grey.primary,
    fontWeight: 600,
    fontFamily: "Nunito Sans",
  },
  stroke: colors.grey.light,
};

export const LegendDefaultProps: LegendProps = {
  wrapperStyle: {
    textTransform: "capitalize",
    width: "100%",
    bottom: -5,
    left: 13,
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Nunito Sans",
  },
};

export const LegendImprovementsProps: LegendProps = {
  wrapperStyle: {
    textTransform: "capitalize",
    width: "60%",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Nunito Sans",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    lineHeight: 3,
    paddingLeft: 25,
  },
};

export const BarDefaultProps: Partial<BarProps> = {
  radius: [2, 2, 0, 0],
};
