import { UnclaimedVenues } from "../models";
import {
  GET_UNCLAIMED_VENUES_FULFILLED,
  GET_UNCLAIMED_VENUES_PENDING,
  GET_UNCLAIMED_VENUES_REJECTED,
  UnclaimedVenuesActionTypes,
} from "./types";

interface VenuesState {
  unclaimedVenues: UnclaimedVenues[];
  unclaimedVenuesCount: number;
  error?: string;
  loading: boolean;
}

const defaultState: VenuesState = {
  unclaimedVenues: [],
  unclaimedVenuesCount: 0,
  error: undefined,
  loading: false,
};

export default (
  state: VenuesState = defaultState,
  action: UnclaimedVenuesActionTypes
): VenuesState => {
  switch (action.type) {
    case GET_UNCLAIMED_VENUES_PENDING:
      return {
        ...state,
        loading: true,
      };
    case GET_UNCLAIMED_VENUES_FULFILLED:
      return {
        ...state,
        unclaimedVenues: action.unclaimedVenues,
        loading: false,
        unclaimedVenuesCount: action.unclaimedVenuesCount,
      };
    case GET_UNCLAIMED_VENUES_REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
