import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames = "button" | "icon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      button: {
        backgroundColor: colors.red.primary,
        color: colors.white.primary,
        borderRadius: 4,
        borderColor: "transparent",
        textTransform: "none",
        "&:hover": {
          backgroundColor: colors.red.primary,
          boxShadow: "0px 2px 10px rgba(255, 100, 100, 0.54)",
        },
        "&:$disabled": {
          backgroundColor: "#C8C8C8",
        },
        fontWeight: "bold",
      },
      icon: {
        marginRight: 10,
        marginTop: -3,
      },
    })
);
