import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "dialogContent"
  | "createButton"
  | "gridRightSm6"
  | "gridLeftSm6"
  | "rangePriceWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      dialogContent: {
        padding: theme.spacing(4),
      },
      createButton: {
        minWidth: 116,
        [theme.breakpoints.down("xs")]: {
          minWidth: 0,
        },
      },
      gridRightSm6: {
        paddingLeft: `${theme.spacing(1)}px !important`,
        [theme.breakpoints.down("xs")]: {
          paddingLeft: `${theme.spacing(20 / 8)}px !important`,
        },
      },
      gridLeftSm6: {
        paddingRight: `${theme.spacing(1)}px !important`,
        [theme.breakpoints.down("xs")]: {
          paddingRight: `${theme.spacing(20 / 8)}px !important`,
        },
      },
      rangePriceWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        gap: theme.spacing(7 / 8),
        marginTop: theme.spacing(14 / 8),
        [theme.breakpoints.down("sm")]: {
          flexWrap: "wrap",
        },
      },
    })
);
