import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { ColorsGray } from "../../../../../../../theme/colors";

export type ClassNames = "root" | "tableCell";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
    },
    tableCell: {
      color: ColorsGray._595D64,
      fontWeight: 600,
      fontSize: 14,
      paddingBottom: 8,
    },
  })
);
