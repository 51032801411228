import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../../../../../common/models";

export type ClassNames =
  | "root"
  | "cardItem"
  | "cardItemContent"
  | "cardItemTextDescription"
  | "cardItemTextItemsName"
  | "cardItemTextTitleExample"
  | "containerGridCardTextCardViewDescription"
  | "iconRadioButtonWithHole"
  | "imageLineGraySalad"
  | "text1"
  | "muiFormLabel";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        width: "auto",
        margin: theme.spacing(1, 0),
      },
      muiFormLabel: {
        "& .MuiFormLabel-root": {
          fontSize: 14,
          fontWeight: "600",

          color: "#595D64 !important",
        },
        "& .MuiInputBase-input": {
          fontSize: 14,
          fontWeight: "600",

          color: "#595D64 !important",
        },
        "& .MuiInputBase-input::-webkit-input-placeholder": {
          opacity: 1,
        },
      },

      cardItem: {
        background: "#F6F7F8",
        border: "1px solid #CBCFD5",
        borderRadius: 4,
        position: "relative",
        "& svg": {
          color: "white",
        },
        "&.active": {
          borderColor: "#FE5454",
          cursor: "pointer",
          "& svg": {
            color: "#FE5454",
          },
        },
        "&:hover": {
          borderColor: "#FE5454",
          cursor: "pointer",
          "& svg": {
            color: "#FE5454",
          },
        },
      },
      cardItemContent: { padding: 12 },
      cardItemTextDescription: {
        fontWeight: 400,
        fontSize: 10,
        color: "#37393F",
        marginTop: 9,
      },
      cardItemTextItemsName: {
        fontWeight: 600,
        fontSize: 10,
        color: "#9298A0",
      },
      text1: { fontWeight: 700, fontSize: 14, color: "#1F1F1F" },
      cardItemTextTitleExample: {
        fontSize: 10,
        lineHeight: 14,
        color: "#37393F",
        paddingLeft: 5,
      },
      text: {
        fontWeight: 700,
        fontSize: 14,
        color: "#1F1F1F",
      },
      containerGridCardTextCardViewDescription: {
        fontWeight: 400,
        fontSize: 12,
        color: "#595D64",
      },
      iconRadioButtonWithHole: {
        height: 20,
        width: 20,
        position: "absolute",
        right: -6,
        top: -6,
      },
      imageLineGraySalad: { height: 40 },
    })
);
