import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";

export type ClassNames = "button";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    button: {
      minWidth: 132,
      minHeight: 44,
      backgroundColor: colors.green.secondary,
      border: `1.5px solid ${colors.green.secondary}`,
      color: colors.white.primary,
      textTransform: "none",
      "&:hover": {
        backgroundColor: colors.green.secondary,
        boxShadow: "0px 2px 10px rgba(155, 199, 144, 0.54);",
      },
      "&:$disabled": {
        opacity: ".7",
        color: colors.white.primary,
        border: 0,
      },
      fontWeight: "bold",
      borderRadius: 2,
    },
  })
);
