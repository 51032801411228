import clsx from "clsx";
import React, { FC } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import { ReactComponent as PlusIcon } from "../../svg/icons/plus-sign-bold.svg";
import ButtonSuccess from "../ButtonSuccess";
import Props from "./Props";
import { useStyles } from "./styles";

const CreateNewVenueButton: FC<Props> = (props) => {
  const classes = useStyles();
  const { openCreateVenuePopup, className } = props;

  return (
    <ButtonSuccess
      className={clsx(classes.buttonCreate, className)}
      onClick={openCreateVenuePopup}>
      <PlusIcon height={19} width={19} className={classes.buttonCreateIcon} />
      {dictionary.venues.list.createVenueButton}
    </ButtonSuccess>
  );
};

export default CreateNewVenueButton;
