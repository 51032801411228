import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "withMask"
  | "btn"
  | "lockIcon"
  | "unlockIcon"
  | "unlockBtnText";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      },
      withMask: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 10,
        position: "absolute",
        backgroundColor: "rgba(255,255,255, .92)",
        "&:hover": {
          backgroundColor: "transparent",
          "& $unlockIcon": {
            display: "flex",
          },
          "& $btn, $unlockBtnText": {
            maxWidth: "100%",
          },
          "& $lockIcon": {
            display: "none",
          },
        },
      },
      btn: {
        minWidth: 43,
        maxWidth: 43,
        padding: theme.spacing(1, 1, 1, 1),
        transition: "max-width 0.7s linear",
      },
      lockIcon: {},
      unlockIcon: {
        display: "none",
      },
      unlockBtnText: {
        color: colors.white.primary,
        width: "auto",
        maxWidth: "0%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        transition: "max-width 0.7s linear",
      },
    })
);
