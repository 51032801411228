import { IconButton, Paper, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useDispatch } from "react-redux";

import OcietyTooltip from "../../../../common/components/OcietyTooltip";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { ReactComponent as DeleteIcon } from "../../../../common/svg/icons/delete-2.svg";
import { ReactComponent as InfoIcon } from "../../../../common/svg/icons/info.svg";
import { CURRENCY_SYMBOL } from "../../../../config";
import dictionary from "../../../../i18n/en_US/dictionary";
import { cartItemRemove } from "../../../../store/cart/actions";
import Props from "./Props";
import { useStyles } from "./styles";

const CartItem: FC<Props> = (props: Props) => {
  const { pack } = props;
  const venueId = useCurrentVenueId();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleRemoveCartItem = () => {
    dispatch(cartItemRemove(venueId, pack.id));
  };

  return (
    <Paper className={classes.root}>
      <div>
        <Typography className={classes.packItemName}>{pack.name}</Typography>
        <div className={classes.whatIncludedContainer}>
          <Typography className={classes.whatIncludedLabel}>
            {dictionary.plan.whatIncluded}
          </Typography>
          <OcietyTooltip title={pack.benefits.join(";\r\n")}>
            <InfoIcon />
          </OcietyTooltip>
        </div>
      </div>
      <div className={classes.priceContainer}>
        <Typography
          component={"span"}
          variant={"body2"}
          className={classes.currency}>
          {CURRENCY_SYMBOL}
        </Typography>
        <Typography component={"span"} className={classes.price}>
          {pack.price}
        </Typography>
        <Typography
          component={"span"}
          variant={"body2"}
          className={classes.period}>
          {dictionary.plan.periodLabel}
        </Typography>
      </div>
      <div className={classes.actionsContainer}>
        <IconButton
          className={classes.deleteButton}
          onClick={handleRemoveCartItem}>
          <DeleteIcon />
        </IconButton>
      </div>
    </Paper>
  );
};

export default CartItem;
