import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "text"
  | "nameText"
  | "rowItem"
  | "rowItem1"
  | "rowItem2"
  | "rowItemPrimary"
  | "rowItemStatus"
  | "rowItemName"
  | "rowItemFirst"
  | "rowItemLast"
  | "rowItemCenter"
  | "rowItemText"
  | "activeHoursWrapper"
  | "link"
  | "primaryText"
  | "actions"
  | "content"
  | "cancelModalTitle"
  | "mobileButton"
  | "rootModal"
  | "modalImg";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: 10,
        minWidth: 1400,
      },
      text: {
        color: colors.grey.text,
      },
      nameText: {
        color: colors.dark.main,
        overflowWrap: "anywhere",
      },
      rowItemText: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
      },
      rowItem: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(3, 1),
        [theme.breakpoints.down("md")]: {
          paddingLeft: theme.spacing(1),
        },
      },
      rowItem1: {
        flexGrow: 0,
        maxWidth: "6.666666%",
        flexBasis: "6.666666%",
      },
      rowItem2: {
        flexGrow: 0,
        maxWidth: "13.333333%",
        flexBasis: "13.333333%",
      },
      rowItemName: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2, 1),
      },
      rowItemStatus: {
        justifyContent: "flex-end",
        padding: theme.spacing(2, 1),
      },
      rowItemPrimary: {
        paddingLeft: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
          justifyContent: "flex-start",
          paddingLeft: theme.spacing(1),
        },
      },
      rowItemFirst: {
        paddingLeft: theme.spacing(3),
      },
      rowItemLast: {
        padding: theme.spacing(1, 5, 1, 1),
        display: "flex",
        justifyContent: "flex-end",
      },
      rowItemCenter: {
        display: "flex",
        justifyContent: "center",
      },
      activeHoursWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 10,
      },
      link: {
        color: colors.blue.dark,
      },
      primaryText: {
        margin: theme.spacing(0, 0, 0, 0),
      },
      actions: {
        padding: 32,
        maxWidth: 544,
        [theme.breakpoints.down("sm")]: {
          padding: "10px 20px",
        },
      },
      content: {
        padding: `24px 40px 4px 32px`,
        maxWidth: 544,
        display: "flex",
        flexDirection: "column",
      },
      cancelModalTitle: {
        fontSize: "22px !important",
        marginBottom: 12,
      },
      mobileButton: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          height: "100%",
        },
      },
      rootModal: {
        minWidth: 544,
        [theme.breakpoints.down("xs")]: {
          minWidth: 0,
        },
      },
      modalImg: {
        alignSelf: "center",
      },
    })
);
