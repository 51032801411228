import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../../../theme/colors";
import { OcietyTheme } from "../../../../models";

export type ClassNames =
  | "root"
  | "dayName"
  | "dayNameWrapper"
  | "addMinusIcon"
  | "timePicker"
  | "timePickerInput"
  | "timePickerWrapper"
  | "timePickerContainer"
  | "timePickerWrapperDisabled"
  | "daySwitchWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: theme.spacing(1, 0, 1.5, 0),
        "&:not(:last-of-type)": {
          borderBottom: "1px solid #E5E7EF",
        },
      },
      dayNameWrapper: {
        minHeight: "50px",
        paddingRight: theme.spacing(2),
        boxSizing: "border-box",
        paddingTop: "5px",
      },
      dayName: {
        color: colors.grey.primary,
      },
      addMinusIcon: {
        padding: 14,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      },
      timePicker: {
        marginLeft: 12,
        // TODO: find better solution
        "& .MuiInput-underline": {
          "&:before, &:after": {
            display: "none",
          },
        },
      },
      timePickerInput: {
        padding: 1,
        cursor: "pointer",
        fontWeight: 600,
      },
      timePickerWrapper: {
        padding: theme.spacing(1, 2),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      timePickerContainer: {
        display: "flex",
        flexDirection: "row",
      },
      timePickerWrapperDisabled: {
        boxShadow: "none !important",
      },
      daySwitchWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      },
    })
);
