import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "content"
  | "actions"
  | "titleWrapper"
  | "title"
  | "message"
  | "actionButtonContainer";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      titleWrapper: {
        padding: `10px 10px 10px 32px`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #e1e2e3",
        [theme.breakpoints.down("sm")]: {
          padding: 20,
        },
      },
      title: {
        fontWeight: 600,
        fontSize: 20,
        color: "#273950",
      },
      content: {
        padding: `24px 32px 32px`,
        maxWidth: 544,
      },
      actions: {
        padding: `0px 32px 32px 32px`,
        maxWidth: 544,
      },
      message: {
        fontSize: 18,
        fontWeight: 600,
        color: colors.grey.additional,
        textAlign: "center",
      },
      actionButtonContainer: {
        justifyContent: "flex-end",
        display: "flex",
      },
    })
);
