import { AvatarClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

import { colors } from "../colors";

const MuiAvatar: Partial<Record<AvatarClassKey, CSSProperties>> = {
  colorDefault: {
    backgroundColor: colors.grey.lighten,
  },
};

export default MuiAvatar;
