import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import {
  FOOTER_HEIGHT,
  FOOTER_HEIGHT_MOBILE,
} from "../../common/components/Footer";
import { OcietyTheme } from "../../common/models";
import { colors } from "../../theme/colors";

// TODO Generate auto type from object keys...
export type ForgotPasswordClassNames =
  | "actionsWrapper"
  | "aside"
  | "cancelButton"
  | "content"
  | "contentHeader"
  | "errorMessage"
  | "stepTitle"
  | "description"
  | "successMessage"
  | "contentBody"
  | "logo"
  | "link"
  | "form"
  | "title"
  | "inputField"
  | "primaryBtn"
  | "msg"
  | "formLabel"
  | "methodName"
  | "btn";

export const useStyles: () => ClassNameMap<ForgotPasswordClassNames> =
  makeStyles((theme: OcietyTheme) =>
    createStyles({
      actionsWrapper: {
        [theme.breakpoints.up("md")]: {},
      },
      aside: {
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
        [theme.breakpoints.up("md")]: {
          minHeight: `calc(100vh - ${FOOTER_HEIGHT})`,
          background: "url('/images/backgrounds/login-bg.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "56%",
        },
      },
      msg: {
        marginBottom: theme.spacing(1.5),
      },
      btn: {
        textTransform: "none",
        marginBottom: theme.spacing(1),
      },
      primaryBtn: {
        minHeight: 52,
        marginBottom: theme.spacing(2),
      },
      cancelButton: {
        color: theme.palette.text.primary,
      },
      content: {
        flex: 1,
        display: "flex",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          minHeight: `calc(100vh - ${FOOTER_HEIGHT})`,
        },
        [theme.breakpoints.down("sm")]: {
          minHeight: `calc(100vh - ${FOOTER_HEIGHT_MOBILE})`,
        },
        [theme.breakpoints.down("md")]: {
          alignItems: "center",
          justifyContent: "center",
        },
      },
      contentHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      contentBody: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
          justifyContent: "center",
        },
        [theme.breakpoints.up("md")]: {
          width: "44%",
          minHeight: `calc(100% - ${FOOTER_HEIGHT})`,
        },
      },
      errorMessage: {
        marginBottom: theme.spacing(2),
      },
      successMessage: {
        marginBottom: theme.spacing(6),
      },
      formLabel: {
        textAlign: "center",
        margin: theme.spacing(35 / 8, 0, 4),
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        "&.Mui-focused": {
          color: theme.palette.text.secondary,
        },
        [theme.breakpoints.up("md")]: {
          minWidth: 570,
          marginLeft: "-35%",
        },
      },
      link: {
        display: "inline-block",
        marginLeft: "auto",
        fontSize: 16,
        fontWeight: 300,
        color: "#1261be",
      },
      methodName: {
        fontWeight: "bold",
      },
      form: {
        display: "flex",
        flex: 1,
        justifyContent: "space-around",
        flexDirection: "column",
        margin: theme.spacing(2, "auto"),
        [theme.breakpoints.up("lg")]: {
          maxHeight: 800,
        },
        [theme.breakpoints.down("sm")]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.up("md")]: {
          width: 330,
        },
        [theme.breakpoints.down("md")]: {
          justifyContent: "center",
        },
      },
      logo: {
        [theme.breakpoints.down("md")]: {
          marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(5),
        },
        "& > g": {
          fill: colors.red.primary,
        },
      },
      stepTitle: {
        margin: theme.spacing(1, 0),
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.text.secondary,
      },
      title: {
        fontWeight: "bold",
      },
      inputField: {
        position: "relative",
        marginBottom: theme.spacing(3),
        width: "100%",
      },
      description: {
        marginTop: 24,
        marginBottom: 16,
        fontWeight: 600,
        color: colors.grey.secondary,
        fontSize: theme.typography.pxToRem(15),
        lineHeight: theme.typography.pxToRem(20),
        [theme.breakpoints.up("lg")]: {
          marginBottom: 0,
          marginTop: 0,
        },
      },
    })
  );
