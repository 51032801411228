export interface ContactUsLetterRequestData {
  name: string;
  email: string;
  subject: string;
  companyName?: string;
  phoneNumber: string;
  message?: string;
}

export interface ContactUsLetterResponse {
  success: boolean;
}

export const SEND_CONTACT_US_LETTER = "contact-us/send-email";
