import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type ClassNames =
  | "root"
  | "dialogContent"
  | "addButton"
  | "actionsWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      addButton: {
        minWidth: 180,
      },
      actionsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      dialogContent: {
        [theme.breakpoints.up("md")]: {
          paddingTop: theme.spacing(6),
          minWidth: 900,
          minHeight: 700,
        },
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(2),
          minWidth: 500,
        },
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(2),
          minWidth: "auto",
          width: "100%",
        },
      },
    })
);
