import { createStyles } from "@material-ui/core";
import { ClassNameMap, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type UploadVideoClassNames =
  | "root"
  | "title"
  | "btn"
  | "price"
  | "loadingIndicator"
  | "btnLabel";

export const useStyles: () => ClassNameMap<UploadVideoClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
      },
      title: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
      },
      btn: {
        position: "relative",
        alignItems: "center",
        width: "100%",
        minWidth: 164,
        minHeight: 48,
        [theme.breakpoints.down("xs")]: {
          maxWidth: 160,
          marginBottom: 15,
        },
      },
      btnLabel: {
        textAlign: "center",
        fontSize: theme.typography.pxToRem(12),
        marginTop: theme.spacing(1),
        fontWeight: 600,
      },
      price: { marginTop: theme.spacing(2) },
      loadingIndicator: {
        position: "absolute",
        left: 0,
        bottom: 0,
        right: 0,
      },
    })
);
