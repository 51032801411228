import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "button";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      button: {
        fontWeight: "bold",
        textTransform: "none",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(16),
        minHeight: 50,
        minWidth: 132,
      },
    })
);
