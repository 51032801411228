import { Checkbox, Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useState } from "react";

import ButtonBlack from "../../../../../../common/components/ButtonBlack";
import ButtonPrimary from "../../../../../../common/components/ButtonPrimary";
import ImageFullScreen from "../../../../../../common/components/ImageFullScreen";
import ImgMediaCard from "../../../../../../common/components/ImgMediaCard";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../../../utils/dateTimeUtils";
import { useStyles } from "../../styles";
import Props from "./Props";

const UserPhotosTableRow: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [isFullScreenImg, setIsFullScreenImg] = useState(false);
  {
    /* const [category, setCategory] =
    useState<ApprovedPhotosCategory | string>(" "); */
  }
  const {
    userPhotoItem,
    handleOpenDeclineConfirmDialog,
    isChecked,
    setCheckedAll,
    selected,
    handleApprovePhotos,
  } = props;
  const { fileUrlForUser, venue, id, createdAt } = userPhotoItem;

  const handleClickDeclineAction = () => {
    handleOpenDeclineConfirmDialog();
    setCheckedAll([userPhotoItem.id]);
  };

  const handleCheckPhoto = () => {
    isChecked
      ? setCheckedAll((prevState: number[]) =>
          prevState.filter((state) => state !== id)
        )
      : setCheckedAll((prevState: number[]) => [...prevState, id]);
  };

  {
    /*const handleChangeCategory = (event: any) => {
        setCategory(event.target.value);
      };
    */
  }
  return (
    <Grid
      container
      lg={12}
      xs={12}
      component={Paper}
      className={clsx(classes.root, classes.gridContainer, classes.rootRow)}>
      <Grid className={clsx(classes.rowItem, classes.rowItem10)}>
        <Checkbox
          color="primary"
          checked={isChecked}
          onChange={handleCheckPhoto}
        />
      </Grid>
      <Grid
        className={clsx(classes.rowItem, classes.rowItem15)}
        onClick={() => setIsFullScreenImg(true)}>
        <ImgMediaCard height={69} width={129} image={fileUrlForUser} />
      </Grid>
      <Grid item className={clsx(classes.rowItem, classes.rowItem25)}>
        <div>
          <Typography variant={"body1"} className={classes.text}>
            {venue.name}
          </Typography>
          <Typography variant={"body2"} className={classes.textAddress}>
            {venue.address}
          </Typography>
        </div>
      </Grid>
      {/* <Grid item className={clsx(classes.rowItem, classes.rowItem14)}>
        <Typography variant={"body1"} className={classes.text}>
          {username}
        </Typography>
      </Grid>
      */}
      <Grid item className={clsx(classes.rowItem, classes.rowItem25)}>
        <Typography
          variant={"body1"}
          className={clsx(classes.text, classes.dateTextColor)}>
          {getFormattedDate(createdAt)}
        </Typography>
      </Grid>
      {/*<Grid item className={clsx(classes.rowItem, classes.rowItem18)}>
        <Select
          id="select-category"
          value={category}
          placeholder={dictionary.mediaRequests.selectCategoryLabel}
          variant="outlined"
          classes={{
            select: classes.selectCategory,
            outlined: clsx(
              classes.selectCategoryLabel,
              category !== " " && classes.selectedCategoryBackground
            ),
            icon: classes.selectedCategoryIconColor,
          }}
          IconComponent={
            category !== " " ? DropdownIconWhite : DropdownIconBlack
          }
          onChange={handleChangeCategory}>
          <MenuItem value=" " disabled className={classes.selectCategoryLabel}>
            {dictionary.mediaRequests.selectCategoryLabel}
          </MenuItem>
          {approvedCategory.map((category: SelectCategory) => (
            <MenuItem key={category.value} value={category.value}>
              {category.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
        */}
      <Grid item className={clsx(classes.rowItem, classes.rowItem25)}>
        <div className={classes.actionsWrapper}>
          <ButtonBlack
            disabled={!!selected}
            onClick={() => handleApprovePhotos([id])}
            className={clsx(classes.acceptButton, classes.approveButton)}>
            {dictionary.mediaRequests.actions.accept}
          </ButtonBlack>
          <ButtonPrimary
            disabled={!!selected}
            onClick={handleClickDeclineAction}
            color="primary"
            className={classes.acceptButton}>
            {dictionary.mediaRequests.actions.decline}
          </ButtonPrimary>
        </div>
      </Grid>
      <ImageFullScreen
        src={fileUrlForUser}
        isOpen={isFullScreenImg}
        close={() => setIsFullScreenImg(false)}
      />
    </Grid>
  );
};
export default UserPhotosTableRow;
