import { TextField, Typography } from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";

const CodeStep: FC<Props> = (props: Props) => {
  const { onChangeCode, error } = props;
  return (
    <div>
      <Typography variant="body2" component="span" style={{ marginBottom: 30 }}>
        {dictionary.venueAdmin.profile.mfa.dialogs.sms.sentSmsSecurityCode("")}
      </Typography>
      <form noValidate autoComplete="off">
        <TextField
          id="phone-number"
          label={dictionary.venueAdmin.profile.mfa.dialogs.sms.codeInput}
          type="number"
          error={error}
          helperText={
            error && dictionary.venueAdmin.profile.mfa.dialogs.sms.invalidCode
          }
          onChange={(event) => {
            onChangeCode(event.target.value);
          }}
        />
      </form>
    </div>
  );
};
export default CodeStep;
