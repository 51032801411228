import {
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React, { FC, useState } from "react";

import OcietyIcon from "../OcietyIcon";
import Props from "./Props";
import { useStyles } from "./styles";

const ActionsDropdown: FC<Props> = (props: Props) => {
  const { items, iconName = "threeDotsHorizontal" } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (event: any) => {
    event.stopPropagation();
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const hideDropdown = () => {
    setIsOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={hideDropdown}>
      <div className={classes.root}>
        <IconButton onClick={toggleDropdown}>
          <OcietyIcon name={iconName} />
        </IconButton>
        {isOpen && (
          <div className={classes.dropdownContent}>
            <List>
              {items.map(({ iconName, onClick, label }) => (
                <ListItem
                  key={label}
                  button
                  onClick={onClick}
                  classes={{ root: classes.itemRoot }}>
                  <ListItemIcon classes={{ root: classes.iconRoot }}>
                    <OcietyIcon
                      name={iconName}
                      width="15px"
                      height="15px"
                      viewBox="0 0 15 15"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={label}
                    classes={{ primary: classes.itemLabel }}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default ActionsDropdown;
