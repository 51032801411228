import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type ClassNames = "root" | "label";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {
      border: "1px solid #E5E7EF",
      borderRadius: 3,
      display: "flex",
      flexDirection: "row",
      gap: 8,
      alignItems: "center",
      padding: "8px 12px",
      cursor: "pointer",
    },
    label: {
      fontSize: "15px !important",
    },
  })
);
