import { Box, LinearProgress, Typography } from "@material-ui/core";
import React, { FC } from "react";

import Props from "./Props";

const LinearProgressWithLabel: FC<Props> = (props: Props) => {
  const { className, hidePercents, ...restProps } = props;

  return (
    <Box display="flex" alignItems="center" className={className}>
      <Box width="100%" mr={hidePercents ? 0 : 1}>
        <LinearProgress variant="determinate" {...restProps} />
      </Box>
      {!hidePercents && (
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};
export default LinearProgressWithLabel;
