export const APPROVE_VENUE_CLAIM = (requestId: number) =>
  `/venue/claim/${requestId}/approve`;
export const REJECT_VENUE_CLAIM = (requestId: number) =>
  `/venue/claim/${requestId}/reject`;

export interface ApproveVenueClaimRequest {
  comment?: string;
  isWaitlistAdmin?: boolean;
}

export interface RejectVenueClaimRequest {
  comment?: string;
}
