import { Typography } from "@material-ui/core";
import React, { FC, useCallback, useContext } from "react";

// eslint-disable-next-line max-len
import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import Dropzone from "../../../../../../common/components/forms/Dropzone";
import OcietyTooltip from "../../../../../../common/components/OcietyTooltip";
import { useCurrentVenueId } from "../../../../../../common/hooks/useCurrentVenueId";
import { useVenueHasPackage } from "../../../../../../common/hooks/useVenueHasPacakage";
import HttpStatusCodes from "../../../../../../common/httpStatusCodes";
import { VenuePhoto } from "../../../../../../common/models";
import { CroppedImageSize } from "../../../../../../common/types/enums.type";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { PackageUID } from "../../../../../../store/currentVenue/types";
import imageUtils from "../../../../../../utils/imageUtils";
// eslint-disable-next-line max-len
import PhotoUpload from "./components/PhotoUpload";
// eslint-disable-next-line max-len
import { uploadPhoto } from "./components/PhotoUpload/api";
// eslint-disable-next-line max-len
import VideoUpload from "./components/VideoUpload";
// eslint-disable-next-line max-len
import { uploadVideo } from "./components/VideoUpload/api";
import Props from "./Props";
import { useStyles } from "./styles";

const VenueMediaUploader: FC<Props> = (props: Props) => {
  const {
    onUploadVenuePhoto,
    onUploadVenueVideo,
    onUploadPromotionPhoto,
    onUploadPromotionVideo,
    tag,
    isFileUploaded,
    label,
    uploaderTitle,
    isOptional,
  } = props;
  const venueId = useCurrentVenueId();
  const { showAlert } = useContext(AlertContext);
  const classes = useStyles();

  const hasPackage = useVenueHasPackage(PackageUID.PROFILE_UPD);

  const handleUploadPhoto = useCallback(
    (
      photo: any,
      progressUpdate: (value: number) => void,
      successCallback: () => void
    ) => {
      uploadPhoto(photo, tag, String(venueId), progressUpdate)
        .then((response) => response.data)
        .then(async (venuePhoto: VenuePhoto) => {
          if (onUploadVenuePhoto) {
            onUploadVenuePhoto(venuePhoto);
          }

          await imageUtils.uploadCroppedImages(photo, venuePhoto.url, [
            CroppedImageSize.s300x300,
          ]);

          showAlert(
            dictionary.venues.mediaContent.successPhotoUpload,
            "success"
          );
          successCallback();
        })
        .catch((e: any) => {
          const status = e?.response?.status;

          if (status === HttpStatusCodes.FORBIDDEN) {
            showAlert(
              dictionary.venues.mediaContent.errorPhotoUploadNoPackage,
              "error"
            );
          } else if (status === HttpStatusCodes.CONFLICT) {
            showAlert(
              dictionary.venues.mediaContent.errorPhotoUploadMaxLimit,
              "error"
            );
          } else {
            showAlert(dictionary.venues.mediaContent.errorPhotoUpload, "error");
          }
        });
    },
    [showAlert, tag, onUploadVenuePhoto, venueId]
  );

  const handleUploadVideo = useCallback(
    (
      video: any,
      progressUpdate: (value: number) => void,
      successCallback: () => void
    ) => {
      uploadVideo(video, tag, String(venueId), progressUpdate)
        .then((response) => response.data)
        .then((venueVideo: any) => {
          if (onUploadVenueVideo) {
            onUploadVenueVideo(venueVideo);
          }

          showAlert(
            dictionary.venues.mediaContent.successVideoUpload,
            "success"
          );
          successCallback();
        })
        .catch(() =>
          showAlert(dictionary.venues.mediaContent.errorVideoUpload, "error")
        );
    },
    [showAlert, tag, onUploadVenueVideo, venueId]
  );

  const onDrop = useCallback(
    async (
      files: any[],
      progressUpdate: (value: number) => void,
      successCallback: () => void
    ) => {
      if (onUploadPromotionPhoto && onUploadPromotionVideo) {
        if (files.length) {
          const file = files[0];
          if (file.type.match(/image/g)) {
            const croppedImage = await imageUtils.cropImage(
              file,
              CroppedImageSize.s1280x1280
            );
            onUploadPromotionPhoto(
              croppedImage,
              progressUpdate,
              successCallback
            );
          } else if (file.type.match(/video/g)) {
            onUploadPromotionVideo(file, progressUpdate, successCallback);
          } else {
            showAlert(dictionary.venues.mediaContent.errorFileType, "error");
          }
        }
      }
      if (files.length) {
        const file = files[0];
        if (file.type.match(/image/g)) {
          const croppedImage = await imageUtils.cropImage(
            file,
            CroppedImageSize.s1280x1280
          );
          handleUploadPhoto(croppedImage, progressUpdate, successCallback);
        } else if (file.type.match(/video/g)) {
          handleUploadVideo(file, progressUpdate, successCallback);
        } else {
          showAlert(dictionary.venues.mediaContent.errorFileType, "error");
        }
      }
    },
    [
      handleUploadPhoto,
      handleUploadVideo,
      onUploadPromotionPhoto,
      onUploadPromotionVideo,
      showAlert,
    ]
  );

  return (
    <div className={classes.root}>
      {isFileUploaded ? (
        <Dropzone isLock={!hasPackage} onDrop={onDrop} />
      ) : (
        <>
          <Dropzone
            isLock={!hasPackage}
            className={classes.dropZone}
            onDrop={onDrop}
            label={"  "}
            description={" "}
            dropZoneIcon={<></>}
          />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography variant={"h1"} className={classes.title}>
              {uploaderTitle}
            </Typography>
            {isOptional && (
              <Typography variant={"h1"} className={classes.optionalLabel}>
                {dictionary.deals.createDealForm.optionalLabel}
              </Typography>
            )}
          </div>
          <Typography className={classes.label} variant={"subtitle1"}>
            {label}
          </Typography>
        </>
      )}
      <div
        className={
          isFileUploaded ? classes.btnWrapperWithFile : classes.btnWrapper
        }>
        <span>
          <PhotoUpload
            onUploadPhoto={
              onUploadPromotionPhoto
                ? onUploadPromotionPhoto
                : handleUploadPhoto
            }
          />
        </span>
        <OcietyTooltip
          title={dictionary.venues.mediaContent.videoUploadHint}
          placement="bottom-start"
          disableHoverListener={hasPackage}>
          <span>
            <VideoUpload
              onUploadVideo={
                onUploadPromotionVideo
                  ? onUploadPromotionVideo
                  : handleUploadVideo
              }
              isLock={!hasPackage}
            />
          </span>
        </OcietyTooltip>
      </div>
    </div>
  );
};

export default VenueMediaUploader;
