import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, ReactElement, SyntheticEvent } from "react";
import { NavLink, NavLinkProps, useHistory } from "react-router-dom";

import { Route } from "../../../config/router";
import { useStyles } from "./styles";

// @ts-ignore
interface Props extends NavLinkProps {
  to?: Route | Array<Route | string>;
  linkTextClassName?: string;
  noEffects?: boolean;
  icon?: ReactElement;
  state?: Record<string, any>;
  onClick?: (e: SyntheticEvent) => void;
  href?: string;
}

const OcietyNavLink: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory<Record<string, string>>();
  const {
    className,
    children,
    icon,
    linkTextClassName,
    noEffects,
    state,
    to,
    href,
    onClick,
    ...rest
  } = props;

  let toHash = undefined;
  if (Array.isArray(to)) {
    to[0].replace(":id", to[1]);

    if (to[2]) {
      toHash = to[2];
    }
  }

  return (
    <>
      {href ? (
        <a
          className={clsx(
            classes.link,
            icon && classes.linkWithIcon,
            className,
            noEffects && classes.noEffects
          )}
          href={href}>
          {icon}
          {typeof children === "string" ? (
            <Typography
              component={"span"}
              className={linkTextClassName}
              variant="body1">
              {children}
            </Typography>
          ) : (
            <Typography component={"div"} className={linkTextClassName}>
              {children}
            </Typography>
          )}
        </a>
      ) : (
        <NavLink
          onClick={onClick}
          className={clsx(
            classes.link,
            icon && classes.linkWithIcon,
            className,
            noEffects && classes.noEffects
          )}
          to={{
            pathname:
              "/" + (Array.isArray(to) ? to[0].replace(":id", to[1]) : to),
            hash: toHash,
            state: {
              ...history.location.state,
              ...state,
            },
          }}
          {...rest}>
          {icon}
          {typeof children === "string" ? (
            <Typography
              component={"span"}
              className={linkTextClassName}
              variant="body1">
              {children}
            </Typography>
          ) : (
            <Typography component={"div"} className={linkTextClassName}>
              {children}
            </Typography>
          )}
        </NavLink>
      )}
    </>
  );
};

export default OcietyNavLink;
