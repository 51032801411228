import { IconButton } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";

import { colors } from "../../../../theme/colors";
import { OcietyDialogTitleProps } from "./OcietyDialogTitleProps";
import { useStyles } from "./styles";

const OcietyDialogTitle: FC<OcietyDialogTitleProps> = (
  props: OcietyDialogTitleProps
) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h2" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon style={{ color: colors.grey.primary }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};
export default OcietyDialogTitle;
