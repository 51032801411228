import { Grid, Typography } from "@material-ui/core";
import React, { FC, useCallback, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { useRestApi } from "../../../../../../../../../common/hooks/useRestApi";
import { FileUrlWithId } from "../../../../../../../../../common/models";
import dictionary from "../../../../../../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../../../../../../store/rootReducer";
import { PROMOTION_IMAGES } from "../../../../../../../components/PromotionImage/index";
import IPromotionCategory from "../../../../../../../types/interfaces/IPromotionCategory.interface";
import IPromotionDietaryRestriction from "../../../../../../../types/interfaces/IPromotionDietaryRestriction.interface";
import { PromotionFormDialogContext } from "../../../../PromotionFormDialogContextProvider";
import { PromotionKind } from "../../../PromotionFormDialogActions/PromotionKind.enum";
import FileUploaderPromotion from "./components/FileUploaderPromotion";
import { IPhoneMobilePreview } from "./components/IPhoneMobilePreview";
import { useStyles } from "./styles";

const getCategoryName = (
  categoryId: string,
  categories: IPromotionCategory[],
  kind?: PromotionKind
) => {
  if (categoryId !== "") {
    const category = categories.find(
      (category) => category.id === Number(categoryId)
    );
    if (category) {
      if (kind === PromotionKind.VOUCHER) {
        return category.name;
      }
      if (PROMOTION_IMAGES[category.name as keyof typeof PROMOTION_IMAGES]) {
        return category.name;
      }
    }
  }
  return "";
};

interface Props {
  promotionDietaryRestrictions: IPromotionDietaryRestriction[];
  promotionCategories: IPromotionCategory[];
  promotionSubCategories: IPromotionCategory[];
}

const BasicInfoPromotionStep: FC<Props> = (props) => {
  const {
    promotionDietaryRestrictions,
    promotionCategories,
    promotionSubCategories,
  } = props;
  const classes = useStyles();

  const focusOnError = useRef<HTMLDivElement>(null);
  const stepInput = useRef<HTMLInputElement>(null);

  const {
    basicForm,
    photosWithIds,
    setPhotosWithIds,
    videosWithIds,
    setVideosWithIds,
    setNumberOfMediaInEditMode,
    mainForm,
    daysHours,
  } = useContext(PromotionFormDialogContext);
  const { form, setAndValidate } = basicForm;

  const [
    { data: generalVoucherPromoCategories },
    getGeneralPromotionCategoryData,
  ] = useRestApi<{ categories: IPromotionCategory[] }>(
    "promotions/sub-categories/2",
    "GET"
  );

  const isVoucherKind = useCallback(
    () => mainForm.form.values.kind === PromotionKind.VOUCHER,
    [mainForm.form.values.kind]
  );

  useEffect(() => {
    if (isVoucherKind()) {
      getGeneralPromotionCategoryData();
    }
  }, [getGeneralPromotionCategoryData, isVoucherKind]);

  useEffect(() => {
    if (!basicForm.form.isValid) {
      focusOnError?.current?.focus();
    }
  }, [basicForm.form.isValid]);

  useEffect(() => {
    const photos = basicForm.form.values.promotionPhotos.length
      ? JSON.parse(basicForm.form.values.promotionPhotos)
      : ([] as FileUrlWithId[]);
    setPhotosWithIds(photos);
  }, [
    setPhotosWithIds,
    basicForm.form.values.promotionPhotos,
    basicForm.form.values.mainPhotoId,
  ]);

  useEffect(() => {
    setVideosWithIds(
      basicForm.form.values.promotionVideos.length
        ? JSON.parse(basicForm.form.values.promotionVideos)
        : ([] as FileUrlWithId[])
    );
  }, [setVideosWithIds, basicForm.form.values.promotionVideos]);

  useEffect(() => {
    stepInput.current &&
      stepInput.current?.scrollIntoView({ block: "nearest" });
  }, []);

  const pushVideoToString = (
    stringifiedArray: string,
    newItem: FileUrlWithId
  ) => {
    const itemsArray: FileUrlWithId[] = stringifiedArray.length
      ? JSON.parse(stringifiedArray)
      : [];
    itemsArray.push(newItem);
    return JSON.stringify(itemsArray);
  };

  const pushPhotoToString = (
    stringifiedArray: string,
    newItem: FileUrlWithId,
    mainPhotoId: number
  ) => {
    const itemsArray: FileUrlWithId[] = stringifiedArray.length
      ? JSON.parse(stringifiedArray)
      : [];
    if (!mainPhotoId) {
      itemsArray.push(Object.assign(newItem, { isMainPhoto: true }));
    } else {
      itemsArray.push(Object.assign(newItem, { isMainPhoto: false }));
    }
    return JSON.stringify(itemsArray);
  };

  const removePhotoFromString = (
    stringifiedArray: string,
    itemToRemoveID: number,
    newMainPhotoId: number | null
  ) => {
    let itemsArray: FileUrlWithId[] = stringifiedArray.length
      ? JSON.parse(stringifiedArray)
      : [];
    itemsArray = itemsArray.filter(({ id }) => id !== itemToRemoveID);

    if (newMainPhotoId) {
      itemsArray = itemsArray.map((item) => {
        item.isMainPhoto = item.id === newMainPhotoId;
        return item;
      });
    }
    return JSON.stringify(itemsArray);
  };

  const removeVideoFromString = (
    stringifiedArray: string,
    itemToRemoveID: number
  ) => {
    let itemsArray: FileUrlWithId[] = stringifiedArray.length
      ? JSON.parse(stringifiedArray)
      : [];
    itemsArray = itemsArray.filter(({ id }) => id !== itemToRemoveID);
    return JSON.stringify(itemsArray);
  };

  const updateMainPhotoIdInString = (
    stringifiedData: string,
    mainPhotoId: number
  ) => {
    return JSON.stringify(mainPhotoId);
  };

  const updateItemInString = (stringifiedArray: string, itemId: number) => {
    const itemsArray: FileUrlWithId[] = stringifiedArray.length
      ? JSON.parse(stringifiedArray)
      : [];

    return JSON.stringify(
      itemsArray.map((item) => {
        item.isMainPhoto = item.id === itemId;
        return item;
      })
    );
  };

  const handleOnPhotoDelete = (photoId: number) => {
    const photos = JSON.parse(basicForm.form.values.promotionPhotos);
    const currentMainPhotoId = Number(basicForm.form.values.mainPhotoId);
    if (photos.length > 1 && photoId === currentMainPhotoId) {
      const newMainPhoto = photos.find(
        (item: { id: number }) => item.id !== photoId
      );
      if (newMainPhoto) {
        setMainPhotoId(newMainPhoto.id);
      }
      removePhoto(photoId, newMainPhoto.id);
    } else {
      removePhoto(photoId, null);
    }
  };

  const removePhoto = useCallback(
    (id: number, newMainPhotoId: number | null) => {
      setAndValidate(
        "promotionPhotos",
        removePhotoFromString(
          basicForm.form.values.promotionPhotos,
          id,
          newMainPhotoId
        )
      );
    },
    [basicForm.form.values.promotionPhotos, setAndValidate]
  );

  const handleOnVideoDelete = useCallback(
    (id: number) => {
      return setAndValidate(
        "promotionVideos",
        removeVideoFromString(basicForm.form.values.promotionVideos, id)
      );
    },
    [basicForm.form.values.promotionVideos, setAndValidate]
  );

  const handleOnPhotoUpload = (photoWithId: FileUrlWithId) => {
    setNumberOfMediaInEditMode((prevState) => [...prevState, photoWithId]);
    const mainPhotoId = basicForm.form.values.mainPhotoId
      ? Number(basicForm.form.values.mainPhotoId)
      : basicForm.form.values.mainPhotoId;
    addPhoto(photoWithId, mainPhotoId);
    if (!mainPhotoId) {
      setMainPhotoId(photoWithId.id);
    }
  };

  const addPhoto = useCallback(
    (photoWithId: FileUrlWithId, mainPhotoId) => {
      setAndValidate(
        "promotionPhotos",
        pushPhotoToString(
          basicForm.form.values.promotionPhotos,
          photoWithId,
          mainPhotoId
        )
      );
    },
    [basicForm.form.values.promotionPhotos, setAndValidate]
  );

  const handleOnMainPhotoChange = (photoId: number) => {
    setMainPhotoId(photoId);
    setPhotoIsMain(photoId);
  };
  const setMainPhotoId = useCallback(
    (photoId: number) => {
      setAndValidate(
        "mainPhotoId",
        updateMainPhotoIdInString(basicForm.form.values.mainPhotoId, photoId)
      );
    },
    [basicForm.form.values.mainPhotoId, setAndValidate]
  );
  const setPhotoIsMain = useCallback(
    (photoId: number) => {
      setAndValidate(
        "promotionPhotos",
        updateItemInString(basicForm.form.values.promotionPhotos, photoId)
      );
    },
    [basicForm.form.values.promotionPhotos, setAndValidate]
  );

  const handleOnVideoUpload = useCallback(
    (videoWithId: FileUrlWithId) => {
      setNumberOfMediaInEditMode((prevState) => [...prevState, videoWithId]);
      setAndValidate(
        "promotionVideos",
        pushVideoToString(basicForm.form.values.promotionVideos, videoWithId)
      );
    },
    [
      setNumberOfMediaInEditMode,
      basicForm.form.values.promotionVideos,
      setAndValidate,
    ]
  );

  const { currentVenue } = useSelector<AppState, AppState>((state) => state);

  const getMainCategoryName = useCallback(() => {
    if (
      mainForm.form.values.kind === PromotionKind.VOUCHER &&
      mainForm.form.values.categoryId !== "" &&
      generalVoucherPromoCategories
    ) {
      const category = getCategoryName(
        mainForm.form.values.categoryId,
        generalVoucherPromoCategories.categories,
        mainForm.form.values.kind
      );
      if (category !== "") {
        return category;
      }
    }

    if (mainForm.form.values.subCategoryId !== "") {
      const category = getCategoryName(
        mainForm.form.values.subCategoryId,
        promotionSubCategories
      );
      if (category !== "" && category !== "Other") {
        return category;
      }
    }
    if (mainForm.form.values.categoryId !== "") {
      const category = getCategoryName(
        mainForm.form.values.categoryId,
        promotionCategories
      );
      if (category !== "") {
        return category;
      }
    }

    return "Other";
  }, [
    mainForm.form.values.categoryId,
    mainForm.form.values.subCategoryId,
    promotionCategories,
    promotionSubCategories,
    mainForm.form.values.kind,
    generalVoucherPromoCategories,
  ]);

  const getSubCategoryName = useCallback(() => {
    if (mainForm.form.values.otherSubcategoryName !== "") {
      return mainForm.form.values.otherSubcategoryName;
    }
    const categories = isVoucherKind()
      ? promotionCategories
      : promotionSubCategories;

    return (
      categories.find(
        (category) => category.id === Number(mainForm.form.values.subCategoryId)
      )?.name || ""
    );
  }, [
    isVoucherKind,
    mainForm.form.values.otherSubcategoryName,
    mainForm.form.values.subCategoryId,
    promotionCategories,
    promotionSubCategories,
  ]);

  const getDisplayCategoryName = useCallback(() => {
    const categoryDbName =
      (isVoucherKind() && generalVoucherPromoCategories
        ? generalVoucherPromoCategories.categories
        : promotionCategories
      ).find(
        (category) => category.id === Number(mainForm.form.values.categoryId)
      )?.name || "";

    return categoryDbName;
  }, [
    isVoucherKind,
    mainForm.form.values.categoryId,
    promotionCategories,
    generalVoucherPromoCategories,
  ]);

  const isDealKind =
    (mainForm.form.values.kind as PromotionKind) === PromotionKind.DEAL;

  return (
    <>
      <div ref={stepInput} />
      <Grid container spacing={4} className={classes.root}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <FileUploaderPromotion
            photosWithIds={photosWithIds}
            videosWithIds={videosWithIds}
            error={!!form.errors.promotionPhotos}
            onPhotoUpload={handleOnPhotoUpload}
            onPhotoDelete={handleOnPhotoDelete}
            onVideoUpload={handleOnVideoUpload}
            onVideoDelete={handleOnVideoDelete}
            onMainPhotoChange={handleOnMainPhotoChange}
          />
        </Grid>
        <Typography variant="h2" className={classes.previewTitle}>
          {dictionary.deals.createDealForm.preview}
        </Typography>
        <IPhoneMobilePreview
          promotionDietaryRestrictions={promotionDietaryRestrictions}
          endDate={mainForm.form.values.end}
          selectedDietaryRestrictions={mainForm.form.values.dietaryRestrictions}
          description={mainForm.form.values.description}
          restrictions={mainForm.form.values.restrictions}
          daysHours={daysHours}
          photosWithIds={photosWithIds}
          currentVenueName={currentVenue.currentVenue?.name || ""}
          category={getDisplayCategoryName()}
          subCategory={getSubCategoryName()}
          title={mainForm.form.values.name}
          kind={mainForm.form.values.kind as PromotionKind}
          sellingPrice={mainForm.form.values.rebatedPrice}
          originalPrice={mainForm.form.values.limitPrice}
          offPercent={parseFloat(mainForm.form.values.limitPercent).toFixed(0)}
          mainCategoryName={getMainCategoryName()}
          productName={mainForm.form.values.limitItemsName}
        />
      </Grid>
    </>
  );
};

export default BasicInfoPromotionStep;
