import { FormControl, Grid, Paper, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import React, { FC, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import OptionItem from "../../../../common/components/forms/OcietySelect/OptionItem";
import LockedChartMask from "../../../../common/components/LockedChartMask";
import { VenueReportingContext } from "../../../../common/components/VenueReportingContextProvider";
import { useVenueHasPackage } from "../../../../common/hooks/useVenueHasPacakage";
import dictionary from "../../../../i18n/en_US/dictionary";
import { CurrentVenueState } from "../../../../store/currentVenue/reducer";
import { PackageUID } from "../../../../store/currentVenue/types";
import { AppState } from "../../../../store/rootReducer";
import { getReportingLiveStats } from "./api";
import ActiveUsersOnline from "./components/ActiveUsersOnline";
import ActiveUsersTable from "./components/ActiveUsersTable";
import SearchUser from "./components/SearchUser";
import { UserType } from "./models/enums";
import ILiveUserData from "./models/ILiveUserData";
import { useSelectStyles, useStyles } from "./styles";

const ReportingLiveStatsTab: FC = () => {
  const UPD_INTERVAL = 60 * 1000;
  const classes = useStyles();
  const selectClasses = useSelectStyles();
  const { showAlert } = useContext(AlertContext);
  const { handlePackageName } = useContext(VenueReportingContext);
  const { currentVenue } = useSelector<AppState, CurrentVenueState>(
    (appState) => appState.currentVenue
  );
  const venueId: number = currentVenue ? currentVenue.id : 0;
  const hasPackage = useVenueHasPackage(PackageUID.CUSTOMER);
  const [data, setData] = useState<ILiveUserData[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterUserType, setFilterUserType] = useState<UserType | null>(null);

  const filterOptions: Array<{ label: string; value: string }> = [
    { value: "", label: dictionary.reportingLiveStats.filterUserTypeAll },
    {
      value: UserType.USER,
      label: dictionary.reportingLiveStats.filterUserTypeUser,
    },
    {
      value: UserType.CRITIC,
      label: dictionary.reportingLiveStats.filterUserTypeCritic,
    },
  ];

  useEffect(() => {
    const fetchData = (venueId: number) => {
      if (!hasPackage) {
        return;
      }
      getReportingLiveStats(venueId)
        .then((lifeUserData: ILiveUserData[]) => {
          if (lifeUserData) {
            setData(lifeUserData);
          }
        })
        .catch(() => {
          showAlert(dictionary.reportingLiveStats.errorFetchData, "error");
        });
    };

    const interval = setInterval(() => {
      fetchData(venueId);
    }, UPD_INTERVAL);
    fetchData(venueId);
    return () => {
      clearInterval(interval);
    };
  }, [venueId, showAlert, setData, UPD_INTERVAL, hasPackage]);
  let liveUsersData = data;

  if (filterUserType) {
    liveUsersData = data.filter((liveUserData: ILiveUserData) => {
      return liveUserData.userType === filterUserType;
    });
  }

  if (searchValue) {
    liveUsersData = data.filter((liveUserData: ILiveUserData) => {
      if (
        liveUserData.username.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        return true;
      }

      return !!(
        liveUserData.firstName
          ?.toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        liveUserData.lastName?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
  }

  const handleChangeCategoryFilter = (e: any) => {
    setFilterUserType(e.target.value);
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.wrapper}>
        <Grid container spacing={4}>
          <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
            <Typography variant="h2" component="h2">
              {dictionary.reportingLiveStats.title}
            </Typography>
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={12}
            className={classes.flexRowBetweenCenter}>
            <ActiveUsersOnline count={data.length} />
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={4} xs={12}>
            {!hasPackage && (
              <LockedChartMask
                withMask
                packageName={handlePackageName(PackageUID.CUSTOMER)}
                wrapperClassName={classes.lockedMask}
              />
            )}
            <SearchUser value={searchValue} onChange={setSearchValue} />
          </Grid>
          <Grid item xl={8} lg={7} md={4} sm={4} xs={12} />
          <Grid item xl={2} lg={3} md={4} sm={4} xs={12} justify={"flex-start"}>
            <FormControl
              variant="outlined"
              className={clsx(classes.filterSelect, "select-no-legend")}>
              <Select
                classes={selectClasses}
                id="reporting_live_stats"
                value={filterUserType ? filterUserType : " "}
                label={dictionary.reportingLiveStats.userTypeFilter}
                onChange={handleChangeCategoryFilter}>
                <MenuItem value=" " disabled>
                  <em>{dictionary.reportingLiveStats.userTypeFilter}</em>
                </MenuItem>
                {filterOptions.map((option: OptionItem) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ActiveUsersTable liveUsersData={liveUsersData} rowsPerPage={10} />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ position: "relative", paddingTop: 0, paddingBottom: 0 }}>
            <div style={{ position: "absolute", top: -40 }}>
              <Typography variant="body1" style={{ color: "#C2C4C7" }}>
                Automatically updates every minute
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default ReportingLiveStatsTab;
