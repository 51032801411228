import { Checkbox, CheckboxProps } from "@material-ui/core";
import React, { FC } from "react";

import { ReactComponent as CheckedIcon } from "../../svg/icons/checkbox-active.svg";
import { ReactComponent as UncheckedIcon } from "../../svg/icons/checkbox-inactive.svg";
import { ReactComponent as IndeterminateIcon } from "../../svg/icons/checkbox-indeterminate-icon.svg";

const OcietyCheckbox: FC<CheckboxProps> = (props) => {
  const { ...rest } = props;

  return (
    <Checkbox
      {...rest}
      checkedIcon={<CheckedIcon />}
      icon={<UncheckedIcon />}
      indeterminateIcon={<IndeterminateIcon />}
    />
  );
};

export default OcietyCheckbox;
