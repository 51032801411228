import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "logo" | "logoText" | "rootWithText";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        userSelect: "none",
      },
      rootWithText: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      logo: {
        "& > g": {
          fill: colors.red.primary,
        },
      },
      logoText: {
        color: theme.palette.primary.main,
        fontWeight: 400,
        marginLeft: 3,
        fontSize: "23.7px !important",
        lineHeight: "29.6px !important",
        fontFamily: "K2D",
      },
    })
);
