import clsx from "clsx";
import React, { FC } from "react";

import OcietyIcon from "../OcietyIcon";
import Props from "./Props";
import { useStyles } from "./styles";

const SelectIcon: FC<Props> = (props: Props) => {
  const { iconWrapperClassName, iconName, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, iconWrapperClassName)}>
      <OcietyIcon
        // @ts-ignore
        name={iconName || "caretDown"}
        viewBox={"-6 -6 24 24"}
        {...rest}
      />
    </div>
  );
};

export default SelectIcon;
