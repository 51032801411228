import React from "react";

import { ReactComponent as African } from "./icons/deal-preview-icons/African.svg";
import { ReactComponent as Alcoholic } from "./icons/deal-preview-icons/Alcoholic.svg";
import { ReactComponent as Asian } from "./icons/deal-preview-icons/Asian.svg";
import { ReactComponent as Bakery } from "./icons/deal-preview-icons/Bakery.svg";
import { ReactComponent as BBQ } from "./icons/deal-preview-icons/BBQ.svg";
import { ReactComponent as BiscuitsCoffee } from "./icons/deal-preview-icons/BiscuitsCoffee.svg";
import { ReactComponent as Breakfast } from "./icons/deal-preview-icons/Breakfast.svg";
import { ReactComponent as Burgers } from "./icons/deal-preview-icons/Burgers.svg";
import { ReactComponent as CajunCreole } from "./icons/deal-preview-icons/CajunCreole.svg";
import { ReactComponent as Caribbean } from "./icons/deal-preview-icons/Caribbean.svg";
import { ReactComponent as Chinese } from "./icons/deal-preview-icons/Chinese.svg";
import { ReactComponent as Dessert } from "./icons/deal-preview-icons/Dessert.svg";
import { ReactComponent as EasternEuropean } from "./icons/deal-preview-icons/EasternEuropean.svg";
import { ReactComponent as European } from "./icons/deal-preview-icons/European.svg";
import { ReactComponent as FastCasual } from "./icons/deal-preview-icons/FastCasual.svg";
import { ReactComponent as French } from "./icons/deal-preview-icons/French.svg";
import { ReactComponent as Indian } from "./icons/deal-preview-icons/Indian.svg";
import { ReactComponent as Italian } from "./icons/deal-preview-icons/Italian.svg";
import { ReactComponent as Japanese } from "./icons/deal-preview-icons/Japanese.svg";
import { ReactComponent as Korean } from "./icons/deal-preview-icons/Korean.svg";
import { ReactComponent as LatinSouthAmerica } from "./icons/deal-preview-icons/LatinSouthAmerica.svg";
import { ReactComponent as Mediterranean } from "./icons/deal-preview-icons/Mediterranean.svg";
import { ReactComponent as Mexican } from "./icons/deal-preview-icons/Mexican.svg";
import { ReactComponent as MiddleEastern } from "./icons/deal-preview-icons/MiddleEastern.svg";
import { ReactComponent as NewAmerican } from "./icons/deal-preview-icons/NewAmerican.svg";
import { ReactComponent as NonAlcoholic } from "./icons/deal-preview-icons/NonAlcoholic.svg";
import { ReactComponent as Other } from "./icons/deal-preview-icons/Other.svg";
import { ReactComponent as Pakistani } from "./icons/deal-preview-icons/Pakistani.svg";
import { ReactComponent as Pizza } from "./icons/deal-preview-icons/Pizza.svg";
import { ReactComponent as Salad } from "./icons/deal-preview-icons/Salad.svg";
import { ReactComponent as Sandwich } from "./icons/deal-preview-icons/Sandwich.svg";
import { ReactComponent as Seafood } from "./icons/deal-preview-icons/Seafood.svg";
import { ReactComponent as Snacks } from "./icons/deal-preview-icons/Snacks.svg";
import { ReactComponent as Soup } from "./icons/deal-preview-icons/Soup.svg";
import { ReactComponent as Spanish } from "./icons/deal-preview-icons/Spanish.svg";
import { ReactComponent as Steakhouse } from "./icons/deal-preview-icons/Steakhouse.svg";
import { ReactComponent as Thai } from "./icons/deal-preview-icons/Thai.svg";
import { ReactComponent as Turkish } from "./icons/deal-preview-icons/Turkish.svg";
import { ReactComponent as Voucher } from "./icons/deal-preview-icons/Voucher.svg";

export const DEAL_MOBILE_PREVIEW = {
  African: <African />,
  Alcoholic: <Alcoholic />,
  Asian: <Asian />,
  Bakery: <Bakery />,
  BBQ: <BBQ />,
  "Biscuits + Coffee": <BiscuitsCoffee />,
  Breakfast: <Breakfast />,
  Burgers: <Burgers />,
  "Cajun/Creole": <CajunCreole />,
  Caribbean: <Caribbean />,
  Chinese: <Chinese />,
  Dessert: <Dessert />,
  "Eastern European": <EasternEuropean />,
  European: <European />,
  French: <French />,
  "Fast Casual": <FastCasual />,
  Indian: <Indian />,
  Italian: <Italian />,
  Japanese: <Japanese />,
  Korean: <Korean />,
  "Latin/South America": <LatinSouthAmerica />,
  Mediterranean: <Mediterranean />,
  Mexican: <Mexican />,
  "Middle Eastern": <MiddleEastern />,
  "Non-Alcoholic": <NonAlcoholic />,
  Other: <Other />,
  Pakistani: <Pakistani />,
  Pizza: <Pizza />,
  Salad: <Salad />,
  Sandwich: <Sandwich />,
  Seafood: <Seafood />,
  Snacks: <Snacks />,
  Soup: <Soup />,
  Spanish: <Spanish />,
  Thai: <Thai />,
  "New American": <NewAmerican />,
  "Steakhouse/Grill": <Steakhouse />,
  Turkish: <Turkish />,
  Voucher: <Voucher />,
};
