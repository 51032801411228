import { Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React, {
  ChangeEvent,
  FocusEvent,
  useContext,
  useEffect,
  useRef,
} from "react";

import OcietyTooltip from "../../../../../../../../../../../common/components/OcietyTooltip";
import dictionary from "../../../../../../../../../../../i18n/en_US/dictionary";
import { rebatedPriceBasesRules } from "../../../../../../forms/main";
import { PromotionFormDialogContext } from "../../../../../../PromotionFormDialogContextProvider";
import { useFormHandlers } from "../../hooks/useFormHandlers";
import { useStyles } from "../../styles";

export const SpecialPriceInput = () => {
  const classes = useStyles();
  const { mainForm } = useContext(PromotionFormDialogContext);
  const { form, setSchemaRules } = mainForm;
  const errorHasFocus = useRef<boolean>(false);
  const focusOnError = useRef<HTMLDivElement>(null);
  const {
    clearValueFieldOnClickIfZero,
    handleChangeFormField,
    onBlurPriceField,
  } = useFormHandlers();

  useEffect(() => {
    setSchemaRules({
      rebatedPrice: {
        ...rebatedPriceBasesRules,
        fieldName: dictionary.deals.createDealForm.specPrice,
      },
    });
  }, [setSchemaRules]);

  useEffect(() => {
    if (form.values.limitPrice !== "0") {
      setSchemaRules({
        limitPrice: {
          isIntegerValueBigger: {
            fieldNameInForm: "rebatedPrice",
            fieldInputLabel: dictionary.deals.createDealForm.specPrice,
          },
          fieldName: dictionary.deals.createDealForm.specValue,
        },
      });
    }
    if (form.values.limitPrice === "0" || form.values.limitPrice === "") {
      setSchemaRules({
        limitPrice: {
          required: false,
        },
      });
      form.errors.limitPrice = "";
    }
  }, [
    form.values.kind,
    setSchemaRules,
    form.values.rebatedPrice,
    form.values.limitPrice,
    form.errors,
  ]);

  return (
    <>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          fullWidth
          label={dictionary.deals.createDealForm.specialValue}
          InputLabelProps={{ style: { pointerEvents: "auto" } }}
          name="limitPrice"
          error={!!form.errors.limitPrice}
          helperText={form.errors.limitPrice}
          className={classes.promotionAmountMarginTop}
          onBlur={onBlurPriceField("limitPrice")}
          onChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            handleChangeFormField(e);
          }}
          onClick={clearValueFieldOnClickIfZero}
          type="text"
          value={form.values.limitPrice}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography className={classes.usdSign}>$</Typography>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <OcietyTooltip
                  title={
                    dictionary.deals.createDealForm
                      .descriptionInfoTooltipSpecialValue
                  }>
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </OcietyTooltip>
              </InputAdornment>
            ),
          }}
          inputRef={
            errorHasFocus.current && form.errors.limitPrice
              ? focusOnError
              : null
          }
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          fullWidth
          label={dictionary.deals.createDealForm.specPrice}
          InputLabelProps={{ style: { pointerEvents: "auto" } }}
          name="rebatedPrice"
          error={!!form.errors.rebatedPrice}
          helperText={form.errors.rebatedPrice}
          className={classes.promotionAmountMarginTop}
          onChange={handleChangeFormField}
          onClick={clearValueFieldOnClickIfZero}
          onBlur={onBlurPriceField("rebatedPrice")}
          type="text"
          value={form.values.rebatedPrice}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography className={classes.usdSign}>$</Typography>
              </InputAdornment>
            ),
          }}
          inputRef={
            errorHasFocus.current && form.errors.rebatedPrice
              ? focusOnError
              : null
          }
        />
      </Grid>
    </>
  );
};
