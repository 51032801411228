import { Venue } from "../../../../../../common/models";
import { WorkingDaysForm } from "../WorkingDaysForm";

export default class WorkingDaysFormHelper {
  public static createWorkingDaysFormFromValue(venue: Venue) {
    const form: WorkingDaysForm = {
      isValid: true,
      touched: [],
      values: {},
      errors: {},
    };

    form.values.workingDays = venue.workingDays;

    return form;
  }

  public static saveMap(id: number, form: WorkingDaysForm): any {
    const venue = {
      id,
      workingDays: form.values.workingDays,
    };

    return venue;
  }
}
