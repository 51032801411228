export const getInitialTabIndex = (
  tab: string,
  tabs: string[],
  defaultIdx: number
) => {
  let index = defaultIdx;

  if (tab) {
    const tabIndex = tabs.indexOf(tab);
    if (tabIndex !== -1) {
      index = tabIndex;
    }
  }

  return index;
};

export const getTabProps = (index: number) => ({
  id: `full-width-tab-${index}`,
  "aria-controls": `full-width-tabpanel-${index}`,
});
