import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { ReactComponent as ArrowRightIcon } from "../../../../common/svg/icons/arrow-right.svg";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { formatDateToDayMonthYear } from "../../../../utils/dateTimeUtils";
import { VenueRequestType } from "../../models";
import VenuesRequestsTableFilter from "../VenuesRequestsTableFilter";
import { GET_VENUES_REQUESTS, VenueRequest } from "./api";
import { useStyles } from "./styles";

const VenuesRequestsTable: FC = () => {
  const [filter, setFilter] = useState(VenueRequestType.ALL);
  const [{ data, loading }, getPendingVenuesList] = useRestApi<VenueRequest[]>(
    GET_VENUES_REQUESTS(filter),
    "GET"
  );
  const classes = useStyles();
  const venueRequests = data || [];
  const history = useHistory();

  const handleChangeFilter = (value: VenueRequestType) => {
    setFilter(value);
  };

  useEffect(() => {
    getPendingVenuesList().catch((e) => console.error(e));
  }, [getPendingVenuesList]);

  const handlePressViewButton = (
    entityId: number,
    requestType: VenueRequestType
  ) => {
    let path = Route.VenueSettingsAdmin;

    if (requestType === VenueRequestType.ADD) {
      path = Route.VenuePending;
    } else if (requestType === VenueRequestType.CLAIM) {
      path = Route.VenueClaim;
    }

    history.push(path.replace(":id", entityId.toString()));
  };

  const formatType = (type: VenueRequestType) => {
    let label = "";

    switch (type) {
      case VenueRequestType.ADD: {
        label = "Add";
        break;
      }
      case VenueRequestType.CHANGE: {
        label = "Change";
        break;
      }
      case VenueRequestType.CLAIM: {
        label = "Claim";
        break;
      }
      default: {
        label = type;
      }
    }

    return label;
  };

  return (
    <Paper className={classes.root}>
      {loading && <LoadingIndicator withMask />}
      <div className={classes.sectionHeader}>
        <Typography variant={"h3"}>
          {dictionary.dashboard.venueRequestsTitle}
        </Typography>
        <VenuesRequestsTableFilter
          value={filter}
          onChangeFilter={handleChangeFilter}
        />
      </div>
      {venueRequests.length ? (
        <Table>
          <TableBody className={classes.tbody}>
            {venueRequests.map((item, index) => (
              <TableRow hover key={index}>
                <TableCell className={clsx(classes.td, classes.td40)}>
                  <Typography className={classes.venueName} variant="body1">
                    {item.venueName}
                  </Typography>
                </TableCell>
                <TableCell className={clsx(classes.td, classes.td40)}>
                  <div>
                    <Typography className={classes.address} variant="body1">
                      {formatDateToDayMonthYear(item.date)}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className={clsx(classes.td, classes.td20)}>
                  <div>
                    <Typography className={classes.address} variant="body1">
                      {formatType(item.type)}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell
                  className={clsx(classes.td, classes.td5)}
                  align={"center"}>
                  <ButtonPrimary
                    className={classes.arrowButton}
                    onClick={() =>
                      handlePressViewButton(
                        item.type === VenueRequestType.CHANGE
                          ? item.venueId
                          : item.id,
                        item.type
                      )
                    }>
                    <ArrowRightIcon />
                  </ButtonPrimary>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant={"body2"} className={classes.emptyText}>
          {dictionary.dashboard.emptyVenueRequests}
        </Typography>
      )}
    </Paper>
  );
};

export default VenuesRequestsTable;
