import IVenueAdminProfile from "../models/IVenueAdminProfile";

export const GET_VENUE_ADMIN_PROFILE_PENDING =
  "GET_VENUE_ADMIN_PROFILE_.PENDING";
export const GET_VENUE_ADMIN_PROFILE_REJECTED =
  "GET_VENUE_ADMIN_PROFILE_.REJECTED";
export const GET_VENUE_ADMIN_PROFILE_FULFILLED =
  "GET_VENUE_ADMIN_PROFILE_.FULFILLED";

export const SAVE_VENUE_ADMIN_PROFILE_PENDING =
  "SAVE_VENUE_ADMIN_PROFILE_.PENDING";
export const SAVE_VENUE_ADMIN_PROFILE_REJECTED =
  "SAVE_VENUE_ADMIN_PROFILE_.REJECTED";
export const SAVE_VENUE_ADMIN_PROFILE_FULFILLED =
  "SAVE_VENUE_ADMIN_PROFILE_.FULFILLED";

export interface GetVenueAdminProfilePendingAction {
  type: typeof GET_VENUE_ADMIN_PROFILE_PENDING;
}

export interface GetVenueAdminProfileSuccessAction {
  type: typeof GET_VENUE_ADMIN_PROFILE_FULFILLED;
  venueAdminProfile: IVenueAdminProfile;
}
export interface GetVenueAdminProfileFailAction {
  type: typeof GET_VENUE_ADMIN_PROFILE_REJECTED;
  error: string;
}

export interface SaveVenueAdminProfilePendingAction {
  type: typeof SAVE_VENUE_ADMIN_PROFILE_PENDING;
}

export interface SaveVenueAdminProfileSuccessAction {
  type: typeof SAVE_VENUE_ADMIN_PROFILE_FULFILLED;
}

export interface SaveVenueAdminProfileFailAction {
  type: typeof SAVE_VENUE_ADMIN_PROFILE_REJECTED;
  error: string;
}

export type VenueAdminProfileActionTypes =
  | GetVenueAdminProfilePendingAction
  | GetVenueAdminProfileSuccessAction
  | GetVenueAdminProfileFailAction
  | SaveVenueAdminProfilePendingAction
  | SaveVenueAdminProfileSuccessAction
  | SaveVenueAdminProfileFailAction;
