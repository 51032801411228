import { Form } from "../../../../../../../../../../common/models";
import dictionary from "../../../../../../../../../../i18n/en_US/dictionary";
import { ValidationRules } from "../../../../../../../../../../utils/validation";

export type HappyHourAdvancedFormField =
  | "id"
  | "mainCategoryId"
  | "subCategoryId"
  | "otherSubcategoryName"
  | "description"
  | "customTitle"
  | "itemsName"
  | "sellingPrice"
  | "rebatedPrice"
  | "percent"
  | "subCategoryName"
  | "mainCategoryName";

export type HappyHourAdvancedFormValues = {
  [key in HappyHourAdvancedFormField]: string;
};
export type HappyHourAdvancedFormErrors = {
  [key in HappyHourAdvancedFormField]: string;
};

export interface HappyHourAdvancedForm extends Form {
  values: HappyHourAdvancedFormValues;
  errors: HappyHourAdvancedFormErrors;
  touched: HappyHourAdvancedFormField[];
}

export const otherSubcategoryBasesRules: ValidationRules = {
  fieldName: dictionary.deals.createDealForm.provideSubcategoryNameIfOther,
  length: {
    min: 2,
    max: 20,
  },
  required: false,
};

export const HappyHourAdvancedForm: HappyHourAdvancedForm = {
  values: {
    id: "",
    mainCategoryId: "",
    subCategoryId: "",
    otherSubcategoryName: "",
    mainCategoryName: "",
    description: "",
    customTitle: "",
    itemsName: "",
    sellingPrice: "",
    rebatedPrice: "",
    percent: "",
    subCategoryName: "",
  },
  errors: {
    id: "",
    mainCategoryId: "",
    subCategoryId: "",
    otherSubcategoryName: "",
    mainCategoryName: "",
    description: "",
    customTitle: "",
    itemsName: "",
    sellingPrice: "",
    rebatedPrice: "",
    percent: "",
    subCategoryName: "",
  },
  touched: [],
  isValid: true,
};

export type HappyHourAdvancedFormSchemaType = Record<
  HappyHourAdvancedFormField,
  ValidationRules
>;

export const itemsNameSubcategoryBasesRules: ValidationRules = {
  fieldName: "Product Name",
  required: true,
};

export const HappyHourAdvancedFormSchema: HappyHourAdvancedFormSchemaType = {
  id: {},
  description: { required: true },
  customTitle: {},
  itemsName: itemsNameSubcategoryBasesRules,
  subCategoryName: {},
  mainCategoryName: {},

  mainCategoryId: {
    type: "number",
    required: true,
  },
  subCategoryId: {
    type: "number",
    required: true,
  },
  otherSubcategoryName: {
    ...otherSubcategoryBasesRules,
  },

  sellingPrice: { type: "number" },
  rebatedPrice: { type: "number" },
  percent: { type: "percentNumber" },
};
