import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import LockIcon from "@material-ui/icons/Lock";
import PeopleIcon from "@material-ui/icons/People";
import React, { FC } from "react";
import { useHistory, withRouter } from "react-router-dom";

import { RBAC as RBACPermission } from "../../../permissions/rbac";
import { havePermission } from "../../../utils/userUtils";
import Props from "./Props";
import { useStyles } from "./styles";
const RBAC: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <Typography variant="h3" gutterBottom>
        Role-based access control (RBAC)
      </Typography>

      <div className={classes.root}>
        <List component="nav" aria-label="main mailbox folders">
          {havePermission(RBACPermission.VIEW_ADMINS) && (
            <ListItem
              button
              onClick={() => {
                history.push("/rbac/admins");
              }}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Manage admins" />
            </ListItem>
          )}
          {havePermission(RBACPermission.VIEW_ROLES) && (
            <ListItem
              button
              onClick={() => {
                history.push("/rbac/roles");
              }}>
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText primary="Manage roles" />
            </ListItem>
          )}
        </List>
        <Divider />
      </div>
    </div>
  );
};
export default withRouter(RBAC);
