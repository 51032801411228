import React, { FC, useContext } from "react";

import AgeGroupSelect from "../../../../../common/components/AgeGroupSelect";
import GenderSelect from "../../../../../common/components/GenderSelect";
import PeriodSelect from "../../../../../common/components/PeriodSelect";
import { VenueReportingContext } from "../../../../../common/components/VenueReportingContextProvider";
import {
  AnalyticsPeriod,
  AnalyticsPeriodSelectOption,
} from "../../../../../common/models";
import Props from "./Props";
import { useStyles } from "./styles";

const VenueAdminFilters: FC<Props> = (props: Props) => {
  const { className } = props;
  const classes = useStyles();
  const periodOptions: AnalyticsPeriodSelectOption[] = [
    {
      value: AnalyticsPeriod.LastThreeMoths,
      label: "Last 3 months",
    },
    {
      value: AnalyticsPeriod.LastSixMoths,
      label: "Last 6 months",
    },
    {
      value: AnalyticsPeriod.LastYear,
      label: "Last year",
    },
  ];

  const { period, setPeriod, gender, setGender, ageGroupId, setAgeGroupId } =
    useContext(VenueReportingContext);

  return (
    <div className={classes.root}>
      <AgeGroupSelect
        ageGroupId={ageGroupId}
        onChangeAgeGroupId={setAgeGroupId}
        className={classes.filterSelect}
      />
      <GenderSelect
        gender={gender}
        onChangeGender={setGender}
        className={classes.filterSelect}
      />
      <PeriodSelect
        className={classes.filterSelect}
        setPeriod={setPeriod}
        period={period}
        periodOptions={periodOptions}
      />
    </div>
  );
};
export default VenueAdminFilters;
