import { DialogContent, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import clsx from "clsx";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import BraintreeDropin from "../../../../common/components/BraintreeDropin";
import ButtonCancel from "../../../../common/components/ButtonCancel";
import ButtonSuccess from "../../../../common/components/ButtonSuccess";
import DefaultPaymentMethod from "../../../../common/components/DefaultPaymentMethod";
import OcietyDialogTitle from "../../../../common/components/dialogs/OcietyDIalogTitle";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import HttpStatusCodes from "../../../../common/httpStatusCodes";
import { PaymentMethod } from "../../../../common/models";
import { CURRENCY_SYMBOL } from "../../../../config";
import { getRouteWithSlash, Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { cartClear } from "../../../../store/cart/actions";
import { CurrentVenueState } from "../../../../store/currentVenue/reducer";
import { AppState } from "../../../../store/rootReducer";
import CartItem from "../CartItem";
import MiniCartEmptyContent from "../MiniCartEmptyContent";
import MiniCartSuccessPurchase from "../MiniCartSuccessPurchase";
import {
  AddPackageRequest,
  GET_SUBSCRIPTION_PAYMENT_METHOD,
  PURCHASE_PACKAGES,
} from "./api";
import Props from "./Props";
import { useStyles } from "./styles";

const PaymentConfirmationPopup: FC<Props> = (props) => {
  const {
    open,
    onClose,
    children,
    onPurchaseSucceed,
    onPurchaseFailed,
    packages,
    ...rest
  } = props;
  const venueId = useCurrentVenueId();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentVenue } = useSelector<AppState, CurrentVenueState>(
    (state) => state.currentVenue
  );
  const [purchased, setPurchased] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const [{ loading: addPackageLoading }, subscribe] = useRestApi<
    any,
    AddPackageRequest
  >(PURCHASE_PACKAGES, "POST");
  const [
    { data: currentPaymentMethod, loading: currentPaymentMethodLoading },
    getCurrentPaymentMethod,
  ] = useRestApi<PaymentMethod>(
    GET_SUBSCRIPTION_PAYMENT_METHOD(venueId),
    "GET"
  );

  const fetchCurrentPaymentMethod = useCallback(() => {
    getCurrentPaymentMethod().catch((e) => console.error(e));
  }, [getCurrentPaymentMethod]);

  useEffect(() => {
    if (open) {
      fetchCurrentPaymentMethod();
    }
  }, [fetchCurrentPaymentMethod, open]);

  const handleClearCart = () => {
    dispatch(cartClear());
  };

  const getTotal = () => {
    const amount = packages
      .map((pack) => pack.price)
      .reduce((acc, cur) => acc + cur);

    return dictionary.plan.totalAmountWithSymbol(CURRENCY_SYMBOL, amount);
  };

  const handleClosePopup = useCallback(() => {
    setPurchased(false);

    if (onClose) {
      // @ts-ignore
      onClose();
    }
  }, [onClose]);

  const handleGoToPackages = () => {
    handleClosePopup();
    history.push(getRouteWithSlash(Route.Plan));
  };

  const handlePressSavePayment = () => {
    subscribe({
      packagesIds: packages.map(({ id }) => id),
      venueId,
    })
      .then(() => {
        if (onPurchaseSucceed) {
          onPurchaseSucceed();
          setPurchased(true);
        }

        showAlert(dictionary.plan.packagePurchaseSucceed, "success");
      })
      .catch((e: any) => {
        const status = e?.response?.status;

        if (
          packages &&
          status &&
          status === HttpStatusCodes.CONFLICT &&
          onPurchaseFailed
        ) {
          onPurchaseFailed();
        } else {
          showAlert(dictionary.plan.packagePurchaseError, "error");
        }
      });
  };

  const renderActions = () => {
    if (purchased) {
      return null;
    }

    return (
      <>
        {!!packages.length && (
          <ButtonCancel
            className={classes.clearCartButton}
            onClick={handleClearCart}>
            {dictionary.plan.clearCart}
          </ButtonCancel>
        )}
        {packages.length ? (
          <>
            <ButtonCancel onClick={handleClosePopup}>
              {dictionary.plan.packageCancelButton}
            </ButtonCancel>
            <ButtonSuccess
              disabled={currentPaymentMethodLoading || !currentPaymentMethod}
              onClick={handlePressSavePayment}>
              {dictionary.plan.packageBuyButton}
            </ButtonSuccess>
          </>
        ) : (
          <ButtonSuccess onClick={handleGoToPackages}>
            {dictionary.plan.reviewPackages}
          </ButtonSuccess>
        )}
      </>
    );
  };

  const handleClickEditDefaultPaymentMethod = () => {
    handleClosePopup();
    history.push(getRouteWithSlash(Route.BillingInformation), {
      editMode: true,
    });
  };

  const renderContent = () =>
    packages && packages.length ? (
      <div>
        <div className={classes.itemsTitleContainer}>
          <Typography
            variant={"body2"}
            component={"span"}
            className={classes.packageToPurchaseTitle}>
            {dictionary.plan.cartItemsTitle(packages.length)}
          </Typography>
          <Typography
            variant={"body1"}
            component={"b"}
            className={classes.packageToPurchaseTitle}>
            {currentVenue?.name}
          </Typography>
        </div>
        <div className={classes.packagesContainer}>
          {packages.map((pack) => (
            <CartItem pack={pack} key={pack.id} />
          ))}
        </div>
        <div className={classes.paymentContainer}>
          {currentPaymentMethodLoading ? (
            <LoadingIndicator withMask />
          ) : currentPaymentMethod ? (
            <DefaultPaymentMethod
              onClickEdit={handleClickEditDefaultPaymentMethod}
              paymentMethod={currentPaymentMethod}
            />
          ) : (
            <BraintreeDropin
              onPaymentSave={fetchCurrentPaymentMethod}
              onCancel={handleClosePopup}
            />
          )}
        </div>
        <div className={classes.totalContainer}>
          <Typography className={classes.totalLabel} component={"span"}>
            {dictionary.plan.total}
          </Typography>
          <Typography className={classes.totalAmount} component={"span"}>
            {getTotal()}
          </Typography>
        </div>
        {addPackageLoading && <LoadingIndicator withMask />}
      </div>
    ) : purchased ? (
      <MiniCartSuccessPurchase onClose={handleClosePopup} />
    ) : (
      <MiniCartEmptyContent />
    );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      {...rest}>
      <OcietyDialogTitle id="mfa-call-dialog-title" onClose={handleClosePopup}>
        {dictionary.plan.paymentConfirmationPopupTitle}
      </OcietyDialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        {renderContent()}
      </DialogContent>
      <DialogActions
        className={clsx({
          [classes.reviewPackageBtnEmptyCart]: !packages.length,
        })}>
        {renderActions()}
      </DialogActions>
    </Dialog>
  );
};

export default PaymentConfirmationPopup;
