import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import React, { FC } from "react";

import { BenefitsItem } from "../../api";
import Props from "./Props";
import { useStyles } from "./styles";

const VenueBenefitsAccordionContent: FC<Props> = (props: Props) => {
  const { content, onChangeBenefit, benefitsState } = props;

  const classes = useStyles();

  const renderItem = (item: BenefitsItem) => {
    const handleChange = () => {
      onChangeBenefit(item);
    };

    return (
      <FormControlLabel
        classes={{
          label: classes.checkboxLabel,
        }}
        control={
          <Checkbox
            color={"default"}
            checked={benefitsState.includes(item.id)}
            onChange={handleChange}
            name={item.name}
          />
        }
        label={item.name}
      />
    );
  };

  return (
    <Grid container spacing={3} className={classes.root}>
      {content.map((item) => (
        <Grid item key={item.id} lg={3} md={3} sm={4} xs={6}>
          {renderItem(item)}
        </Grid>
      ))}
    </Grid>
  );
};

export default VenueBenefitsAccordionContent;
