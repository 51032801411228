import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "clearButton"
  | "header"
  | "emptyText"
  | "emptyContainer"
  | "title"
  | "notificationsContainer";

const ITEM_MIN_HEIGHT = 87;

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        maxWidth: 320,
        minWidth: 320,
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          minWidth: "auto",
        },
      },
      clearButton: {
        color: colors.red.primary,
        textTransform: "none",
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(12),
      },
      title: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        color: colors.grey.secondary,
      },
      header: {
        minHeight: 23,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
      notificationsContainer: {
        minHeight: ITEM_MIN_HEIGHT,
        position: "relative",
        maxHeight: 360,
        overflow: "auto",
      },
      emptyContainer: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: ITEM_MIN_HEIGHT,
        justifyContent: "center",
        alignItems: "center",
      },
      emptyText: {
        textAlign: "center",
      },
    })
);
