import { TabClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

import { colors } from "../colors";

const MuiTab: Partial<Record<TabClassKey, CSSProperties>> = {
  root: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "40px",
    textTransform: "none",
    minWidth: "auto",
    "&$selected": {
      fontWeight: "bold",
    },
  },
  textColorPrimary: {
    color: colors.grey.primary,
    "&$selected": {
      fontWeight: "bold",
    },
  },
};

export default MuiTab;
