import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../common/models";
import { ColorsGray } from "../../../../../theme/colors";

export type ClassNames = "root" | "button" | "buttonWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      buttonWrapper: {
        display: "flex",
        marginTop: 24,
        alignItems: "center",
        justifyContent: "center",
      },
      button: {
        backgroundColor: ColorsGray._37393F,
        padding: "8px 12px",
      },
    })
);
