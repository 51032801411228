import { Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";
import { ResponsiveContainer } from "recharts";

import { useGlobalStyles } from "../../styles";
import EmptyChart from "../EmptyChart";
import LoadingIndicator from "../LoadingIndicator";
import LockedChartMask from "../LockedChartMask";
import Props from "./Props";
import { useStyles } from "./styles";

const ChartContainer: FC<Props> = (props: Props) => {
  const {
    children,
    loading,
    title,
    chartType,
    locked,
    packageName,
    isChartEmpty,
    isChartSmall,
  } = props;
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const getAdditionalClasses = () => {
    const additionalClasses: string[] = [];

    switch (chartType) {
      case "line-with-y-axis": {
        additionalClasses.push(classes.basic, classes.line, classes.withYAxis);
        break;
      }
      case "bar-with-y-axis": {
        additionalClasses.push(classes.basic, classes.bar, classes.withYAxis);
        break;
      }
      case "bar-with-y-axis-and-legend-bottom": {
        additionalClasses.push(
          classes.bar,
          classes.withYAxis,
          classes.withLegendBottom
        );
        break;
      }
      case "line-with-y-axis-and-legend-bottom": {
        additionalClasses.push(
          classes.line,
          classes.withYAxis,
          classes.withLegendBottom
        );
        break;
      }
      case "pie": {
        additionalClasses.push(classes.pie);
        break;
      }
      case "pie-with-legend-bottom": {
        additionalClasses.push(classes.pie, classes.withLegendBottom);
        break;
      }
    }

    return additionalClasses;
  };

  return (
    <Paper className={classes.root}>
      {loading && <LoadingIndicator withMask />}
      {locked && <LockedChartMask withMask packageName={packageName} />}
      <div className={globalClasses.flexRowBetweenCenter}>
        <Typography variant={"h3"}>{title}</Typography>
      </div>
      {isChartEmpty ? (
        <EmptyChart chartType={chartType} isChartSmall={isChartSmall} />
      ) : (
        <div className={classes.content}>
          <ResponsiveContainer className={clsx(getAdditionalClasses())}>
            {children}
          </ResponsiveContainer>
        </div>
      )}
    </Paper>
  );
};

export default ChartContainer;
