import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../common/models";

export type ClassNames = "dialogContent";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      dialogContent: {
        padding: theme.spacing(4),
        [theme.breakpoints.down("xs")]: {
          padding: "24px 16px",
        },
      },
    })
);
