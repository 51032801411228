import Input from "@material-ui/core/Input";
import Popover from "@material-ui/core/Popover";
import Slider from "@material-ui/core/Slider";
import React, { ChangeEvent, FC, useState } from "react";

import { ReactComponent as SortRangeIcon } from "../../../common/svg/icons/sort.svg";
import Props from "./Props";
import { useStyles } from "./styles";

const RangeAmountSlider: FC<Props> = ({
  minRange,
  maxRange,
  handleSetRange,
}: Props) => {
  const classes = useStyles();
  const [priceRange, setPriceRange] = useState([minRange, maxRange]);
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleSliderChange = (_: any, newValue: any) => {
    setPriceRange(newValue);
  };

  const handleSliderChangeCommit = (_: any, newValue: any) => {
    handleSetRangeExternal(newValue);
  };

  const handleInputChange =
    (index: number) =>
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newPriceRange = [...priceRange];
      //@ts-ignore
      newPriceRange[index] =
        event.target.value === "" ? "" : Number(event.target.value);
      setPriceRange(newPriceRange);
    };

  const handleSetRangeExternal = (range: number[]) => {
    if (handleSetRange) {
      handleSetRange(range);
    }
  };

  const handleBlur = (index: number) => () => {
    if (priceRange[index] < minRange) {
      setPriceRange([minRange, priceRange[1]]);
    }
    if (priceRange[index] > maxRange) {
      setPriceRange([priceRange[0], maxRange]);
    }

    handleSetRangeExternal(priceRange);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const open = Boolean(anchor);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.root}>
      <div onClick={handleClick} className={classes.searchIcon}>
        <SortRangeIcon />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div style={{ padding: "20px" }}>
          <Slider
            value={priceRange}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderChangeCommit}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            max={maxRange}
            min={minRange}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Input
              value={priceRange[0]}
              margin="dense"
              onChange={handleInputChange(0)}
              onBlur={handleBlur(0)}
              inputProps={{
                step: 1,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
            <Input
              value={priceRange[1]}
              margin="dense"
              onChange={handleInputChange(1)}
              onBlur={handleBlur(1)}
              inputProps={{
                step: 1,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default RangeAmountSlider;
