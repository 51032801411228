import { Box } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { sortByOrder } from "../../../utils/objectArrayUtils";
import OcietyNavLink from "../OcietyNavLink";
import Props from "./Props";
import { useStyles } from "./styles";

const LinksBox: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { links, className, linkClassName, linkTextClassName } = props;

  const mapLinks = () =>
    links.sort(sortByOrder).map((link) => (
      <OcietyNavLink
        href={link.href}
        key={link.path}
        to={link.path}
        onClick={link.onClick}
        linkTextClassName={linkTextClassName}
        className={clsx(classes.link, linkClassName)}>
        {link.label}
      </OcietyNavLink>
    ));

  return <Box className={clsx(classes.root, className)}>{mapLinks()}</Box>;
};

export default LinksBox;
