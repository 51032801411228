import { http } from "../../../../../config/api";
import IRole from "./models/IRole";

const RBAC_ROLES = `rbac/roles`;
const RBAC_ADMIN_ROLES = (adminId: number): string =>
  `rbac/admin/${adminId}/roles`;

export const getRolesList = async (): Promise<IRole[]> =>
  http
    .get(RBAC_ROLES)
    .then((response) => [...response.data])
    .catch((error) => {
      throw error.response;
    });

export const saveAdminRoles = async (
  adminId: number,
  roleIds: number[]
): Promise<any> =>
  http
    .put(RBAC_ADMIN_ROLES(adminId), { roleIds })
    .then((response) => {
      return { ...response.data };
    })
    .catch((error) => {
      throw error.response;
    });
