import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "actionsRow"
  | "messageIcon"
  | "icon"
  | "tableDataCellInner"
  | "link"
  | "marginRight10";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {},
    actionsRow: {
      display: "flex",
      alignItems: "center",
    },
    messageIcon: {
      marginRight: 8,
      width: 16,
      height: 16,
    },
    icon: {
      marginLeft: 10,
    },
    link: {
      color: colors.grey.primary,
      fontWeight: 700,
      fontSize: 14,
      textDecorationLine: "underline",
    },
    tableDataCellInner: {
      display: "flex",
      alignItems: "center",
      paddingLeft: 3,
    },
    marginRight10: {
      marginRight: 10,
    },
  })
);
