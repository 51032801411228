import {
  CardActions,
  Divider,
  Paper,
  TablePagination,
  Typography,
} from "@material-ui/core";
import React, { FC, useEffect } from "react";

import ButtonBlack from "../../common/components/ButtonBlack";
import LoadingIndicator from "../../common/components/LoadingIndicator";
import dictionary from "../../i18n/en_US/dictionary";
import VenuesSearchEmpty from "../Venues/components/VenuesSearchEmpty";
import VenuesToolbar from "../Venues/components/VenuesToolbar";
import AddNewVenueDialog from "./components/AddNewVenueDialog";
import VenuesTable from "./components/VenuesTable";
import { useStyles } from "./styles";
import { useDataEntryBusinesses } from "./useDataEntryBusinesses";

const DataEntryBusinesses: FC = () => {
  const classes = useStyles();
  const {
    unclaimedVenues,
    unclaimedVenuesCount,
    unclaimedVenueLoading,
    search,
    page,
    rowsPerPage,
    searchPage,
    isOpenedVenueMode,
    editVenueId,
    fetchUnclaimedData,
    setEditVenueId,
    setPage,
    setSearchPage,
    handleSearch,
    handleRowsPerPageChange,
    handleOpenVenueMode,
    handleCloseVenueMode,
  } = useDataEntryBusinesses();

  useEffect(() => {
    fetchUnclaimedData();
  }, [fetchUnclaimedData]);

  return (
    <div className={classes.root}>
      <Divider />
      <Paper className={classes.contentWrapper}>
        {unclaimedVenueLoading && <LoadingIndicator withMask />}
        <div className={classes.searchAndAddNewButtonWrapper}>
          <div className={classes.searchWrapper}>
            <VenuesToolbar
              handleSearch={handleSearch}
              placeholder={dictionary.dataEntryVenues.searchVenuePlaceholder}
            />
          </div>
          <div>
            <ButtonBlack onClick={handleOpenVenueMode}>
              {dictionary.dataEntryVenues.addNewVenueButtonLabel}
            </ButtonBlack>
          </div>
        </div>
        {search && unclaimedVenues.length === 0 ? (
          <VenuesSearchEmpty />
        ) : (
          <VenuesTable
            venueList={unclaimedVenues}
            setEditVenueId={setEditVenueId}
            handleOpenVenueMode={handleOpenVenueMode}
            editVenueId={editVenueId}
          />
        )}
        <CardActions className={classes.paginationWrapper}>
          <TablePagination
            component="div"
            count={unclaimedVenuesCount}
            onChangePage={(event, page) => {
              !search ? setPage(page) : setSearchPage(page);
            }}
            labelDisplayedRows={(type) => (
              <Typography
                variant={"body1"}
                className={
                  classes.paginationLabel
                }>{`${type.to} - ${type.count}`}</Typography>
            )}
            labelRowsPerPage={
              <Typography
                variant={"body1"}
                className={classes.paginationRowPerPage}>
                Rows per page
              </Typography>
            }
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={!search ? page : searchPage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[25, 50, 100]}
          />
        </CardActions>
      </Paper>
      <AddNewVenueDialog
        onCloseVenueDialog={handleCloseVenueMode}
        isVenueDialogOpen={isOpenedVenueMode}
        editVenueId={editVenueId}
      />
    </div>
  );
};

export default DataEntryBusinesses;
