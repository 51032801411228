import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import React, { FC } from "react";

import OcietySwitch from "../../../../../common/components/OcietySwitch";
import { IPermission } from "../../models/IPermission";
import { useStyles } from "../../styles";
import Props from "./Props";

const RolesGroupPermissionList: FC<Props> = (props: Props) => {
  const { permissionGroup, handleToggle, rolePermissions } = props;
  const classes = useStyles();

  const renderListItem = (permission: IPermission) => {
    return (
      <ListItem key={String(permission.key)}>
        <ListItemText
          id="switch-list-label-{{permission.key}}"
          primary={permission.name}
        />
        <ListItemSecondaryAction>
          <OcietySwitch
            edge="end"
            color="primary"
            onChange={() => {
              handleToggle(permission.key);
            }}
            checked={rolePermissions.indexOf(permission.key) !== -1}
            inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <List
      subheader={<ListSubheader>{permissionGroup.name}</ListSubheader>}
      className={classes.root}
      key={permissionGroup.key}>
      {permissionGroup.permissions &&
        permissionGroup.permissions.map((permission: IPermission) => {
          return renderListItem(permission);
        })}
    </List>
  );
};
export default RolesGroupPermissionList;
