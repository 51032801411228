import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import dictionary from "../../../../../i18n/en_US/dictionary";
import IManager from "../../models/IManager";
import CreateUserDialog from "../CreateUserDialog";
import VenueStaffManager from "../VenueStaffManager";
import Props from "./Props";
import { useStyles } from "./styles";

const VenueStaffManagersList: FC<Props> = (props: Props) => {
  const {
    managers,
    onSuccess,
    isOpenCreateUserModal,
    toggleUserCreateModal,
    currentManager,
    setCurrentManager,
  } = props;

  const classes = useStyles();

  const toggleEditUserModal = (manager: IManager | undefined) => {
    if (!!manager) {
      toggleUserCreateModal(false);
      setCurrentManager(manager);
    } else {
      setCurrentManager(undefined);
    }
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.gridContainer} container xs={12}>
        <Grid container xs={12} className={classes.headers}>
          <Grid item xs={3} className={classes.rowItem}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.venueStaff.managers.table.name}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.rowItem}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.venueStaff.managers.table.email}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            className={clsx(classes.rowItem, classes.rowItemCenter)}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.venueStaff.managers.table.roles}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            className={clsx(
              classes.rowItem,
              classes.rowItemCenter,
              classes.rowItemSmallWidth
            )}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.venueStaff.managers.table.actions}
            </Typography>
          </Grid>
        </Grid>

        {managers &&
          managers.map((row: IManager, index) => (
            <VenueStaffManager
              key={index}
              manager={row}
              currentManager={currentManager}
              toggleEditUserModal={(manager) => toggleEditUserModal(manager)}
              onSuccess={onSuccess}
            />
          ))}

        <CreateUserDialog
          onSuccess={() => onSuccess()}
          isOpen={isOpenCreateUserModal}
          toggleOpen={(status) => toggleUserCreateModal(status)}
        />
      </Grid>
    </div>
  );
};
export default VenueStaffManagersList;
