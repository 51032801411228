import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../../../common/models";
import { colors } from "../../../../../../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "error"
  | "uploadedFilesTitle"
  | "uploadedFilesTitleWrapper"
  | "infoIcon"
  | "uploaderContainer"
  | "label"
  | "optionalLabel"
  | "uploadBtns"
  | "title";
export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        margin: theme.spacing(0, 0),
      },
      uploaderContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      error: {
        backgroundColor: "rgba(255,218,218,0.35)",
      },
      uploadedFilesTitle: {
        marginBottom: 10,
      },
      infoIcon: { marginBottom: 10, marginLeft: 10 },
      uploadedFilesTitleWrapper: {
        display: "flex",
        alignItems: "center",
        zIndex: 1,
        position: "relative",
        marginTop: 32,
        width: "50%",
      },
      label: {
        color: colors.grey.additional,
        marginTop: 8,
      },
      optionalLabel: {
        color: colors.grey.secondary,
        marginLeft: 5,
      },
      title: {
        fontSize: 16,
        fontWeight: 700,
      },
      uploadBtns: {
        marginTop: 20,
        width: 350,
        display: "flex",
        justifyContent: "space-between",
      },
    })
);
