import dictionary from "../../../i18n/en_US/dictionary";
import { ConfigSection, CoreConfigData } from "../models";
import {
  ConfigActionTypes,
  GET_CONFIG_FULFILLED,
  GET_CONFIG_PENDING,
  GET_CONFIG_REJECTED,
  SAVE_CONFIG_FULFILLED,
  SAVE_CONFIG_PENDING,
  SAVE_CONFIG_REJECTED,
} from "./types";

interface VenuesState {
  configSections: ConfigSection[];
  configsArray: CoreConfigData[];
  error?: string;
  success?: string;
  loading: boolean;
}

const defaultState: VenuesState = {
  configSections: [],
  configsArray: [],
  error: undefined,
  success: undefined,
  loading: false,
};

export default (
  state: VenuesState = defaultState,
  action: ConfigActionTypes
): VenuesState => {
  switch (action.type) {
    case GET_CONFIG_PENDING:
      return {
        ...state,
        loading: true,
        error: undefined,
        success: undefined,
      };
    case GET_CONFIG_FULFILLED:
      return {
        ...state,
        configSections: action.configSections,
        configsArray: action.configsArray,
        loading: false,
      };
    case GET_CONFIG_REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SAVE_CONFIG_PENDING:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SAVE_CONFIG_FULFILLED:
      return {
        ...state,
        loading: false,
        success: dictionary.settings.successMessage,
      };
    case SAVE_CONFIG_REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
