import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PackageUID } from "../../store/currentVenue/types";
import { AppState } from "../../store/rootReducer";

export function useVenueHasPackage(packageUid: PackageUID) {
  const { currentVenue } = useSelector<AppState, AppState>((state) => state);
  const [venueHasPackage, setVenueHasPackage] = useState<boolean>(false);

  useEffect(() => {
    const hasPackage = currentVenue.currentVenue?.packagesUid.some(
      (puid) => puid === PackageUID.FULL_ACCESS || puid === packageUid
    );
    setVenueHasPackage(!!hasPackage);
  }, [currentVenue.currentVenue, packageUid]);

  return venueHasPackage;
}
