import { Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import PageTitle from "../../common/components/PageTitle";
import { useGlobalStyles } from "../../common/styles";
import dictionary from "../../i18n/en_US/dictionary";
import TotalActivePromotions from "./components/TotalActivePromotions";
import TotalActiveSubscriptions from "./components/TotalActiveSubscriptions";
import TotalUsers from "./components/TotalUsers";
import TotalVenues from "./components/TotalVenues";
import VenuesRequestsTable from "./components/VenuesRequestsTable";
import { useStyles } from "./styles";

const DashboardSuperAdmin: FC = () => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  return (
    <div>
      <div className={clsx(globalClasses.flexRow_Center, classes.titleWrapper)}>
        <PageTitle>{dictionary.dashboard.pageTitle}</PageTitle>
      </div>
      <Grid container spacing={3} style={{ maxWidth: "none" }}>
        <Grid item xs={12} lg={3} md={6} xl={3}>
          <TotalUsers />
        </Grid>
        <Grid item xs={12} lg={3} md={6} xl={3}>
          <TotalVenues />
        </Grid>
        <Grid item xs={12} lg={3} md={6} xl={3}>
          <TotalActivePromotions />
        </Grid>
        <Grid item xs={12} lg={3} md={6} xl={3}>
          <TotalActiveSubscriptions />
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <VenuesRequestsTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardSuperAdmin;
