import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "optionLabel"
  | "optionLabelSelected"
  | "labelText"
  | "optionLabelDisabled"
  | "uncheckedIcon"
  | "checkedIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      optionLabel: {
        borderRadius: 5,
        border: `2px solid ${colors.grey.light}`,
        margin: theme.spacing(0, 0, 2),
        padding: theme.spacing(1),
      },
      optionLabelSelected: {
        borderColor: colors.green.primary,
        background: "rgba(111, 207, 151, 0.08)",
      },
      optionLabelDisabled: {
        opacity: 0.4,
      },
      labelText: {
        fontWeight: "bold",
        fontSize: theme.typography.pxToRem(15),
      },
      uncheckedIcon: {
        width: 20,
        height: 20,
        borderRadius: "50%",
        border: `1px solid ${colors.grey.disabled}`,
      },
      checkedIcon: {
        width: 20,
        height: 20,
        borderRadius: "50%",
        boxShadow: "0px 1px 3px rgba(254, 84, 84, 0.3)",
        border: `5px solid ${colors.red.primary}`,
      },
    })
);
