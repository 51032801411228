import { TextField } from "@material-ui/core";
import TelegramIcon from "@material-ui/icons/Telegram";
import React, {
  ChangeEvent,
  FC,
  KeyboardEventHandler,
  useContext,
  useState,
} from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import ButtonPrimary from "../../../../../../common/components/ButtonPrimary";
import { useCurrentVenueId } from "../../../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../../../common/hooks/useRestApi";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { CHAT_MESSAGES } from "../../../../api";
import { Message, SendMessageRequest } from "../../../../models";
import Props from "./Props";
import { useStyles } from "./styles";

const MessageForm: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const venueId = useCurrentVenueId();
  const { chatId, addNewMessage } = props;
  const [textMessage, setTextMessage] = useState("");
  const { showAlert } = useContext(AlertContext);
  const [timeSent, setTimeSent] = useState("");
  const disableButton = textMessage.trim().length === 0;

  const [, sendMessage] = useRestApi<Message, SendMessageRequest>(
    CHAT_MESSAGES(venueId),
    "POST",
    undefined,
    true
  );

  const addMessage = () => {
    setTextMessage("");
    messageSentTime();
    sendMessage({
      userId: chatId,
      payload: textMessage,
    })
      .then((newMessage) => {
        if (newMessage) {
          addNewMessage(newMessage);
        }
      })
      .catch((e) => {
        if (e.response.data === "Insult word") {
          showAlert(dictionary.notifications.insultingMessageError, "error");
        }
      });
  };

  const messageSentTime = () => {
    const timeNow = new Date();
    return timeNow.toLocaleString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleClickEnter: KeyboardEventHandler<HTMLDivElement> = (e: any) => {
    if (e.key === "Enter" && textMessage === "") {
      e.preventDefault();
    }

    if (e.key === "Enter" && textMessage !== "") {
      e.preventDefault();
      addMessage();
      setTextMessage("");
    }
  };

  const handleChangeTextMessage = (e: ChangeEvent<any>) => {
    const { value } = e.target;
    const correctTimeFormat = messageSentTime();
    setTimeSent(correctTimeFormat);
    setTextMessage(value);
  };

  return (
    <div className={classes.root}>
      <TextField
        placeholder="Type a message..."
        inputProps={{ maxlength: 1000 }}
        value={textMessage}
        onChange={handleChangeTextMessage}
        onKeyPress={handleClickEnter}
        className={classes.inputMessage}
        InputProps={{ disableUnderline: true, className: classes.inputWrapper }}
        multiline
      />
      <div className={classes.sendBtnWrapper}>
        <ButtonPrimary
          className={classes.sendBtn}
          onClick={addMessage}
          disabled={disableButton}>
          <TelegramIcon />
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default MessageForm;
