import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

import OcietyDialogTitle from "../../../../common/components/dialogs/OcietyDIalogTitle";
import { ReactComponent as BetaIcon } from "../../../../common/svg/icons/beta-version-icon.svg";
import dictionary from "../../../../i18n/en_US/dictionary";
import PromotionAdditionalFilters from "../PromotionAdditionalFilters";
import MostPopularVenuesNearby from "./components/MostPopularVenuesNearby";
import { useStyles } from "./styles";

const ReportingRecommendationsTab: FC = () => {
  const classes = useStyles();
  const [isBetaPopupOpen, setIsBetaPopupOpen] = useState(false);

  useEffect(() => {
    const betaRecommendationPopup = localStorage.getItem(
      "beta_recommendation_popup"
    );

    if (!betaRecommendationPopup) {
      setIsBetaPopupOpen(true);
      localStorage.setItem("beta_recommendation_popup", "1");
    }
  }, []);

  return (
    <div className={classes.root}>
      <PromotionAdditionalFilters />
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <MostPopularVenuesNearby />
        </Grid>
      </Grid>
      <Dialog open={isBetaPopupOpen}>
        <OcietyDialogTitle
          id={"deta-popup"}
          onClose={() => setIsBetaPopupOpen(false)}>
          {dictionary.reporting.reportingRecommendationBetaDialogTitle}
        </OcietyDialogTitle>
        <Divider />
        <DialogContent className={classes.betaDialogRoot}>
          <BetaIcon className={classes.betaIcon} />
          <Typography variant={"h3"} className={classes.betaMessage}>
            {dictionary.reporting.reportingRecommendationBetaDialogMessage}
          </Typography>
          <Typography variant={"body1"} className={classes.betaText}>
            {dictionary.reporting.reportingRecommendationBetaDialogText}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReportingRecommendationsTab;
