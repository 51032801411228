import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { forwardRef, useEffect, useRef, useState } from "react";

import dictionary from "../../../../i18n/en_US/dictionary";
import { useAsyncState } from "../../../hooks/useAsyncState";
import { AddressLocation } from "../../../models";
import { GET_ALL_STATES } from "./api";
import Props from "./Props";

const StateAutocompleteSearch = forwardRef<HTMLDivElement, Props>(
  (props: Props, stateRef) => {
    const { state, country, label, error, onChange, fullWidth } = props;
    const didMount = useRef(false);
    /*const prevState = usePrevious(state);
    const shouldSetDefault = !didMount || state !== prevState;
    const prevCountry = usePrevious(country);*/
    const [value, setValue] = useState<AddressLocation | null>(null);
    const [{ response: locationStates, loading }, setLocationStates] =
      useAsyncState<AddressLocation[]>(GET_ALL_STATES(country));
    const handleAutocompleteChange = (
      _: any,
      option: AddressLocation | null
    ) => {
      setValue(option);
      onChange(option?.state);
    };

    useEffect(() => {
      setLocationStates().then();
    }, [setLocationStates]);

    useEffect(() => {
      /*if (shouldSetDefault && state) {
        setLocationStates().then((locations) => {
          didMount.current = true;

          if (locations) {
            setValue(
              locations.filter((location) => location.state === state)[0]
            );
          }
        });
      } else if (!shouldSetDefault && prevCountry !== country) {
        setLocationStates().then(() => {
          if (!shouldSetDefault && prevCountry && prevCountry !== country) {
            onChange("");
            setValue(null);
          }
        });
      }*/
      didMount.current = true;

      if (locationStates) {
        setValue(
          locationStates.filter((location) => location.state === state)[0]
        );
      }
    }, [
      state,
      locationStates,
      onChange,
      setLocationStates,
      /*country,
      shouldSetDefault,
      prevCountry,*/
    ]);

    return (
      <Autocomplete
        value={value}
        getOptionSelected={(option) => option.state === state}
        id="state_autocomplete"
        /*disabled={!country}*/
        fullWidth={Boolean(fullWidth)}
        loading={loading}
        options={locationStates || []}
        getOptionLabel={(option: AddressLocation) => option.state}
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            value={state}
            error={!!error}
            helperText={
              (!country && dictionary.venues.venueForm.stateHelper) || error
            }
            label={label}
            name="state"
            type="text"
            variant="outlined"
            inputRef={stateRef}
          />
        )}
      />
    );
  }
);

StateAutocompleteSearch.displayName = "StateAutocompleteSearch";
export default StateAutocompleteSearch;
