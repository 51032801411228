import { Dialog, DialogContent, Divider, Typography } from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import OcietyDialogTitle from "../dialogs/OcietyDIalogTitle";
import Props from "./Props";
import { useStyles } from "./styles";

const ImageFullScreen: FC<Props> = (props: Props) => {
  const { src, isOpen, close, title } = props;

  const classes = useStyles();

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} className={classes.root} maxWidth={"xl"}>
      <OcietyDialogTitle id={"full-screen-photo"} onClose={close}>
        <Typography variant={"h3"} className={classes.title}>
          {title || dictionary.mediaRequests.fullScreenPhotoTitle}
        </Typography>
      </OcietyDialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <div className={classes.imageWrapper}>
          <img src={src} alt={src} className={classes.image} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImageFullScreen;
