import { Button, ButtonProps } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { useStyles } from "./styles";

const ButtonSuccess: FC<ButtonProps> = (props) => {
  const { children, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.button, className)}
      size="large"
      type="submit"
      variant="outlined"
      {...rest}>
      {children}
    </Button>
  );
};

export default ButtonSuccess;
