import clsx from "clsx";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";

import PageTitle from "../../../../common/components/PageTitle";
import PeriodSelect from "../../../../common/components/PeriodSelect";
import { VenueReportingContext } from "../../../../common/components/VenueReportingContextProvider";
import {
  AnalyticsPeriod,
  AnalyticsPeriodSelectOption,
} from "../../../../common/models";
import { useGlobalStyles } from "../../../../common/styles";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const periodOptions: AnalyticsPeriodSelectOption[] = [
  {
    value: AnalyticsPeriod.LastThreeMoths,
    label: "Last 3 months",
  },
  {
    value: AnalyticsPeriod.LastSixMoths,
    label: "Last 6 months",
  },
  {
    value: AnalyticsPeriod.LastYear,
    label: "Last year",
  },
];

const ReportingTopBar: FC<Props> = (props: Props) => {
  const { currentTabIndex } = props;
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const { period, setPeriod } = useContext(VenueReportingContext);
  const redirectToPlanPage = () => {
    history.push(Route.Plan);
  };

  return (
    <div className={clsx(globalClasses.flexRowBetweenCenter, classes.root)}>
      <PageTitle>{dictionary.reporting.pageTitle}</PageTitle>
      <div className={classes.actionsWrapper}>
        <PeriodSelect
          disabled={currentTabIndex === 1}
          setPeriod={setPeriod}
          period={period}
          className={classes.periodSelect}
          periodOptions={periodOptions}
        />
      </div>
    </div>
  );
};

export default ReportingTopBar;
