import { Paper } from "@material-ui/core";
import React, { FC } from "react";

import PageTitle from "../../common/components/PageTitle";
import dictionary from "../../i18n/en_US/dictionary";
import UserManagementTabs from "./components/UserManagementTabs";
import UserManagementContextProvider, {
  UserManagementContext,
} from "./providers";
import { useStyles } from "./styles";

const UserManagement: FC = () => {
  const classes = useStyles();
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

  return (
    <UserManagementContextProvider>
      <div className={classes.root}>
        <PageTitle>{dictionary.userManagement.pageTitle}</PageTitle>
        <Paper className={classes.content}>
          <UserManagementTabs
            onChangeTab={(tabHash: string, index: number) => {
              setCurrentTabIndex(index);
            }}
          />
        </Paper>
      </div>
    </UserManagementContextProvider>
  );
};

export default UserManagement;
