import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../common/models";
import { colors } from "../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "formControl"
  | "connectedLabel"
  | "actionsToolbar"
  | "actionsToolbarConnected"
  | "siteUrl"
  | "appModeLabel"
  | "isSandboxWrapper"
  | "linkIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        paddingTop: theme.spacing(4),
      },
      formControl: {
        marginTop: 30,
      },
      appModeLabel: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        fontWeight: "bold",
      },
      actionsToolbar: {
        marginTop: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
      },
      actionsToolbarConnected: {
        display: "flex",
        alignItems: "center",
      },
      connectedLabel: {
        marginLeft: theme.spacing(3),
        fontWeight: "bold",
      },
      siteUrl: {
        display: "inline-flex",
        alignItems: "center",
        padding: "10px 18px",
        margin: "0 10px",
        borderRadius: 4,
        fontSize: theme.typography.pxToRem(12),
        cursor: "pointer",
        backgroundColor: colors.grey.medium,
      },
      linkIcon: {
        marginLeft: theme.spacing(2),
      },
      isSandboxWrapper: {
        marginBottom: theme.spacing(2),
      },
    })
);
