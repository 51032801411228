import { Card, CardActionArea, CardMedia } from "@material-ui/core";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const ImgMediaCard: FC<Props> = (props: Props) => {
  const { image, height, width } = props;
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      style={{
        maxWidth: width,
      }}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt={image}
          height={height}
          width={width}
          image={image}
        />
      </CardActionArea>
    </Card>
  );
};
export default ImgMediaCard;
