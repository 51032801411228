import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames = "root" | "blockContentWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        height: "100%",
        minHeight: 115,
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(3, 3, 3, 0),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(3, 0, 3, 0),
        },
      },
      blockContentWrapper: {
        paddingLeft: 24,
        display: "flex",
        flexDirection: "column",

        flex: "auto",

        "&:first-child": {
          borderRight: "1px solid #E5E7EF",
        },
        "&:last-child": {
          paddingRight: 0,
        },

        [theme.breakpoints.down("sm")]: {
          width: "50%",
          "&:nth-child(n+3)": {
            marginTop: 32,
          },
        },
      },
    })
);
