import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames =
  | "tableContainer"
  | "tableRow"
  | "cellText"
  | "rowDropdownWrapper"
  | "rowCellDropdownWrapper"
  | "tableCell"
  | "tableHeadCell"
  | "tableHeadBody"
  | "rowTableCell"
  | "alignCenter";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      tableCell: {
        border: "none",
      },
      tableRow: {
        cursor: "pointer",
        boxShadow: "0px 0px 30px 0px rgba(59, 72, 118, 0.08)",
        borderRadius: 4,
        borderTopLeftRadius: 4,
      },
      cellText: { fontWeight: 600, fontSize: 14, color: "#37393F" },
      rowDropdownWrapper: { backgroundColor: "#F8F8F8", borderRadius: 4 },
      rowCellDropdownWrapper: { padding: "0 0 16px 0" },
      alignCenter: {
        textAlign: "center",
      },
      tableContainer: { overflow: "visible" },
      tableHeadCell: { fontWeight: 600, fontSize: 14, color: "#595D64" },
      tableHeadBody: { boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)" },
      rowTableCell: { padding: "24px 16px" },
    })
);
