import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";

import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

// eslint-disable-next-line max-len
import AlertContextProvider from "./common/components/Alert/AlertContextProvider";
import AlertsContainer from "./common/components/Alert/AlertsContainer";
import Routes from "./Routes";
import { persistor, store } from "./store";
import theme from "./theme";

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AlertContextProvider>
              <>
                <Routes />
                <AlertsContainer />
              </>
            </AlertContextProvider>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}
