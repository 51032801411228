import React, { FC, useRef, useState } from "react";

import ButtonBlack from "../../../../../../../../common/components/ButtonBlack";
import LinearProgressWithLabel from "../../../../../../../../common/components/LinearProgressWithLabel";
import OcietyIcon from "../../../../../../../../common/components/OcietyIcon";
import { CroppedImageSize } from "../../../../../../../../common/types/enums.type";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import imageUtils from "../../../../../../../../utils/imageUtils";
import { useStyles } from "../VideoUpload/styles";
import Props from "./Props";

const PhotoUpload: FC<Props> = (props: Props) => {
  const { isLock } = props;
  const classes = useStyles();
  const photoInput = useRef<HTMLInputElement>(null);
  const [progressUpload, setProgressUpload] = useState(0);
  const progressUpdate = (progressValue: number) => {
    if (progressValue > 0) {
      setProgressUpload(progressValue);
    }
  };

  const openImageSelector = () => {
    if (photoInput.current) {
      photoInput.current.click();
    }
  };

  const handleFileChange = async (event: any) => {
    const [file] = event.target.files;
    const croppedImage = await imageUtils.cropImage(
      file,
      CroppedImageSize.s1280x1280
    );
    props.onUploadPhoto(croppedImage, progressUpdate, () => {
      if (photoInput.current) {
        photoInput.current.value = ""; // Clear input after uploading
      }

      setTimeout(() => {
        setProgressUpload(0);
      }, 1000);
    });
  };

  return (
    <div className={classes.root}>
      <input
        ref={photoInput}
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleFileChange}
        id="photo-selector-input"
        type="file"
      />
      <ButtonBlack
        disabled={isLock}
        className={classes.btn}
        variant="contained"
        fullWidth
        onClick={openImageSelector}>
        {isLock && <OcietyIcon name="lock" />}
        {dictionary.venueAdmin.profile.uploadPhoto}
        <LinearProgressWithLabel
          hidePercents
          value={progressUpload}
          className={classes.loadingIndicator}
        />
      </ButtonBlack>
    </div>
  );
};
export default PhotoUpload;
