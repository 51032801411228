import {
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";

import { AlertContext } from "../../../../../common/components/Alert/AlertContextProvider";
import ButtonPrimary from "../../../../../common/components/ButtonPrimary";
import LoadingIndicator from "../../../../../common/components/LoadingIndicator";
import { useRestApi } from "../../../../../common/hooks/useRestApi";
import { OcietyTheme } from "../../../../../common/models";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { DiscountStatus } from "../../../../Promotions/types/DiscountStatus";
import { GET_MANAGER_PROMOTIONS } from "../../api";
import PromotionsGrid from "../PromotionsGrid";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionsList: FC<Props> = (props: Props) => {
  const {
    venueId,
    openCreateDialog,
    openEditDialog,
    openDeleteDialog,
    refreshToken,
    openDuplicateDialog,
  } = props;
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const [promotions, setPromotions] = useState<any>([]);
  const [promotionsCount, setPromotionsCount] = useState<number>(0);
  const [currentCategoryId, setCurrentCategoryId] = useState<number>();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("active");
  const activeStatus = DiscountStatus.ENABLED;

  const [{ data: IPromotionsAllResponse }, getManagerPromotions] = useRestApi(
    GET_MANAGER_PROMOTIONS(venueId, filter),
    "GET"
  );

  const isMobile = useMediaQuery((theme: OcietyTheme) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    if (!!venueId) {
      setLoading(true);
      getManagerPromotions()
        .then((data) => {
          if (data) {
            setPromotions(data.promotions);
            setPromotionsCount(data.count);
          }
        })
        .catch(() => {
          showAlert(dictionary.deals.createDealForm.errorFetchData, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    getManagerPromotions,
    filter,
    showAlert,
    venueId,
    activeStatus,
    currentCategoryId,
    refreshToken,
  ]);

  function tabsProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
    if (newValue === 0) {
      setFilter("active");
    } else if (newValue === 1) {
      setFilter("history");
    }
  };

  return (
    <div>
      <Paper className={classes.wrapper}>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h3" component="h3" className={classes.title}>
              {dictionary.deals.titlePage}
            </Typography>
          </Grid>
          {loading && <LoadingIndicator withMask />}
          {promotionsCount > 0 && (
            <>
              <Grid
                item
                xl={12}
                lg={12}
                xs={12}
                md={12}
                direction="row"
                className={classes.button}>
                <ButtonPrimary
                  className={classes.addPromotionBtn}
                  onClick={openCreateDialog}>
                  {dictionary.deals.addBtnTxt}
                </ButtonPrimary>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.tabsWrapper}>
                  <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={isMobile ? "fullWidth" : "standard"}
                    onChange={handleChangeTab}>
                    <Tab
                      label={dictionary.managerDeals.tabs.active}
                      classes={{
                        root: classes.tab,
                      }}
                      {...tabsProps(0)}
                    />
                    <Tab
                      label={dictionary.managerDeals.tabs.history}
                      classes={{
                        root: classes.tab,
                      }}
                      {...tabsProps(1)}
                    />
                  </Tabs>
                </div>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <PromotionsGrid
                  promotions={promotions}
                  onEditPromotion={openEditDialog}
                  onDeletePromotion={openDeleteDialog}
                  onDuplicatePromotion={openDuplicateDialog}
                />
              </Grid>
            </>
          )}
          {promotionsCount === 0 && (
            <Grid
              item
              xl={12}
              lg={12}
              xs={12}
              md={12}
              className={classes.noPromotionsContainer}
              direction="column">
              <Typography variant="h3" className={classes.noPromotionsText}>
                {dictionary.deals.noDeals}
              </Typography>
              <Grid
                item
                xl={12}
                lg={12}
                xs={12}
                md={12}
                direction="row"
                className={classes.buttonContainer}>
                <ButtonPrimary
                  className={classes.noPromotionsBtn}
                  onClick={openCreateDialog}>
                  {dictionary.deals.addBtnTxt}
                </ButtonPrimary>
              </Grid>
              <Grid />
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
};

export default PromotionsList;
