import { LinearProgressClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const LinearProgress: Partial<Record<LinearProgressClassKey, CSSProperties>> = {
  colorPrimary: {
    backgroundColor: "transparent",
  },
  barColorPrimary: {
    backgroundColor: "green",
  },
};

export default LinearProgress;
