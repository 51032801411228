import IVenueAdminProfile from "../../../models/IVenueAdminProfile";
import { VenueAdminMainForm } from "./VenueAdminMainForm";

export default class VenueAdminProfileFormHelper {
  public static createVenueAdminProfileFormFromValue(
    profile: IVenueAdminProfile
  ) {
    const form: VenueAdminMainForm = {
      isValid: true,
      touched: [],
      values: {},
      errors: {},
    };

    // Map
    form.values.firstName = profile.firstName;
    form.values.email = profile.email;
    form.values.phone = profile.phone;
    form.values.birthday = profile.birthday || "";
    form.values.timezone = profile.timezone || undefined;
    form.values.city = String(profile.locationId) || undefined;

    return form;
  }

  public static mapProfileFromForm(
    form: VenueAdminMainForm
  ): IVenueAdminProfile {
    const profile: any = {
      firstName: form.values.firstName ? form.values.firstName : "",
      email: form.values.email ? form.values.email : "",
      phone: form.values.phone ? form.values.phone : "",
      birthday: form.values.birthday ? form.values.birthday : "",
      timezone: form.values.timezone ? form.values.timezone : "",
      locationId: form.values.city ? form.values.city.toString() : "",
    };

    return profile;
  }
}
