import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { FC, useContext, useState } from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import CodeStep from "../AddSmsMethodDialog/components/CodeStep";
import { useStyles } from "../AddSmsMethodDialog/styles";
import { requestCodeCall, sendCallCode } from "./api";
import { CallSteps } from "./CallSteps";
import PhoneStep from "./components/PhoneStep";
import SuccessStep from "./components/SuccessStep";
import Props from "./Props";

const AddCallMethodDialog: FC<Props> = (props: Props) => {
  const { isOpen, setOpen, onSuccessEnabled } = props;
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const [step, setStep] = useState(CallSteps.PHONE_STEP);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [code, setCode] = useState("");

  const handleClose = () => {
    setOpen(false);

    if (step === CallSteps.SUCCESS_STEP && onSuccessEnabled) {
      onSuccessEnabled();
    }
  };

  const handleRequestCodeCall = async () => {
    if (!phoneNumber) {
      return;
    }

    try {
      setLoading(true);

      const result = await requestCodeCall(phoneNumber);

      if (!result) {
        showAlert(
          dictionary.venueAdmin.profile.mfa.dialogs.call.errorResponseMsg,
          "error"
        );
      }

      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.call.successSendCodeMessage,
        "success"
      );
      setStep(CallSteps.CODE_STEP);
    } catch (e) {
      if (!e) {
        return;
      }

      handleClose();
      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.call.errorSendCodeMessage,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCallMethodSave = async () => {
    if (!phoneNumber || !code) {
      return;
    }

    try {
      setLoading(true);

      const result = await sendCallCode(phoneNumber, code);

      if (!result) {
        showAlert(
          dictionary.venueAdmin.profile.mfa.dialogs.call.errorResponseMsg,
          "error"
        );
      }

      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.call
          .successSaveMFAMethodMessage,
        "success"
      );

      setStep(CallSteps.SUCCESS_STEP);
    } catch (e) {
      if (!e) {
        return;
      }

      handleClose();
      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.call
          .errorSaveMFAMethodMessage,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };
  const handleBtnClick = async () => {
    if (step === CallSteps.PHONE_STEP) {
      await handleRequestCodeCall();
    }

    if (step === CallSteps.CODE_STEP) {
      await handleCallMethodSave();
    }
  };

  const isAllowedContinueBtn = () => {
    if (step === CallSteps.PHONE_STEP) {
      if (phoneNumber) {
        return true;
      }
    }

    if (step === CallSteps.CODE_STEP) {
      if (phoneNumber && code) {
        return true;
      }
    }

    return false;
  };
  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="alert-dialog-title">
        {dictionary.venueAdmin.profile.mfa.dialogs.call.title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {step === CallSteps.PHONE_STEP && (
          <PhoneStep onSetPhoneNumber={setPhoneNumber} />
        )}
        {step === CallSteps.CODE_STEP && <CodeStep onChangeCode={setCode} />}
        {step === CallSteps.SUCCESS_STEP && <SuccessStep />}
        {loading && <LoadingIndicator withMask />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={() => handleClose()}>
          {dictionary.venueAdmin.profile.mfa.dialogs.call.closeButton}
        </Button>
        {step !== CallSteps.SUCCESS_STEP && (
          <Button
            color="primary"
            variant="outlined"
            disabled={!isAllowedContinueBtn()}
            onClick={handleBtnClick}>
            {dictionary.venueAdmin.profile.mfa.dialogs.call.continueButton}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default AddCallMethodDialog;
