import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type ClassNames = "content" | "subtitle" | "textField" | "formControl";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      content: {
        position: "relative",
        width: "500px",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      subtitle: {
        marginBottom: theme.spacing(2),
      },
      textField: {
        marginBottom: theme.spacing(2),
      },
    })
);
