import { WorkingDays } from "../../common/components/WorkingDaysPicker/models";
import { VenueTypes } from "../../common/models";

export enum SubTypes {
  bar = "bar",
  restaurant = "restaurant",
}

export interface UnclaimedVenues {
  id: number;
  name: string;
  address: string;
}

export interface AddNewVenueRequest {
  name: string;
  address: string;
  city: string;
  state: string;
  country: string;
  price: number;
  postcode: string;
  subtypes: number[];
  longitude: number | null;
  latitude: number | null;
  website?: string;
  email?: string;
  phone?: string;
  workTime: WorkingTimeDataTeamForm[];
}

export interface GetUnclaimedVenueResponse {
  id: number;
  name: string;
  location: UnclaimedVenueLocationResponse;
  address: string;
  type: VenueTypes;
  price: number;
  postcode: string;
  longitude: number | null;
  latitude: number | null;
  website?: string;
  email?: string;
  phone?: string;
  history: UnclaimedVenueHistory;
  workingDays: WorkingDays[];
  rating: any;
}

export interface UnclaimedVenueLocationResponse {
  id: number;
  city: string;
  state: string;
  country: string;
}

export interface WorkingTimeDataTeamForm {
  day: string;
  isOpen: boolean;
  hours: { from: string; to: string }[];
}

export interface UnclaimedVenueHistory {
  adminAction: string;
  adminFirstName: string;
  adminLastName: string;
  adminId: number;
  adminEmail: string;
  venueDateOfCreation: string;
  venueUpdateDate: string;
}

export interface GetAllUnclaimedVenuesResponse {
  unclaimedVenues: UnclaimedVenues[];
  unclaimedVenuesCount: number;
}
