import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames = "root" | "title" | "score" | "icon" | "percent";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      score: {
        fontWeight: "bold",
        fontSize: theme.typography.pxToRem(32),
        lineHeight: theme.typography.pxToRem(32),
        marginTop: theme.spacing(2),
      },
      root: {
        position: "relative",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(5, 1),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(3, 1),
        },
      },
      title: {
        margin: theme.spacing(0, 0, 0, 5),
      },
      percent: {
        color: theme.palette.text.secondary,
        width: 38,
        textAlign: "center",
      },
      icon: {
        height: 61,
        width: 61,
      },
    })
);
