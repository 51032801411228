import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames = "root" | "title" | "message" | "button";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        margin: theme.spacing(3, 0),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      title: {
        marginTop: theme.spacing(5),
        fontWeight: 700,
      },
      message: {
        marginTop: theme.spacing(3),
        fontSize: theme.typography.pxToRem(20),
        fontWeight: 600,
      },
      button: {
        marginTop: theme.spacing(4),
      },
    })
);
