import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";

import InformationBlockItem from "../InformationBlockItem";
import Props from "./Props";
import { useStyles } from "./styles";

const InformationBlock: FC<Props> = (props) => {
  const { items, title } = props;
  const classes = useStyles();

  return (
    <div>
      <Typography variant={"h3"} component={"span"} className={classes.title}>
        {title}
      </Typography>
      <Grid container spacing={2} className={classes.itemsWrapper}>
        {items.map(({ label, value }, index) => (
          <InformationBlockItem key={label} label={label} value={value} />
        ))}
      </Grid>
    </div>
  );
};

export default InformationBlock;
