import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { ChangeEvent, FC, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import PhoneNumberInput from "../../../../common/components/PhoneNumberInput";
import TextMessage from "../../../../common/components/TextMessage";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  clearForm,
  setAndValidate,
  validateForm,
} from "../../../../store/forms/actions";
import { FormFieldKey } from "../../../../store/forms/types";
import { AppState } from "../../../../store/rootReducer";
import { isUnauthorized } from "../../../../utils/userUtils";
import { assignVenue } from "../../api";
import { AssignVenueRequest } from "../../models";
import { schema } from "./form";
import { Props } from "./Props";
import { useStyles } from "./styles";

const AssignVenueDialog: FC<Props> = (props: Props) => {
  const { venueId, open, setOpen, history } = props;
  const { assignVenue: assignVenueForm } = useSelector(
    (state: AppState) => state.forms
  );
  const dispatch = useDispatch();
  const timeoutId = useRef<number>();
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isWaitlistAdmin, setIsWaitlistAdmin] = useState(true);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: fieldName, value } = event.target;
    let fieldValue = value;

    setErrorMsg("");

    if (isPhoneNumberField(fieldName)) {
      fieldValue = trimPhoneNumber(event.target.value);
    }

    dispatch(
      setAndValidate(
        "assignVenue",
        fieldName as FormFieldKey,
        fieldValue,
        schema
      )
    );
  };

  const isPhoneNumberField = (name: string): boolean => {
    return name === "phone";
  };

  const trimPhoneNumber = (value: string): string => {
    const newFormat = value.replace(/\D/g, "");
    return `+${newFormat}`;
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(clearForm("assignVenue"));
    setErrorMsg("");
    setSuccessMsg("");
    clearTimeout(timeoutId.current);
  };

  const handleAssignVenue = () => {
    if (!dispatch(validateForm("assignVenue", schema))) {
      return;
    }

    setLoading(true);

    assignVenue(
      { ...assignVenueForm.values, isWaitlistAdmin } as AssignVenueRequest,
      venueId
    )
      .then(() => {
        setErrorMsg("");
        setSuccessMsg("Success, this dialog will close soon");
        setTimeout(() => {
          handleClose();
        }, 1500);
      })
      .catch((error) => {
        if (!error) {
          return;
        }

        if (isUnauthorized(error.status)) {
          history.push(Route.Login);
        }

        const msg = error.data.details
          ? error.data.details[0].message
          : error.data;
        setErrorMsg(msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="alert-dialog-title">
        {dictionary.venues.assignVenueForm.title}
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            fullWidth
            label={dictionary.venues.assignVenueForm.firstNameFieldLabel}
            name="firstName"
            required
            error={!!assignVenueForm.errors.firstName}
            helperText={assignVenueForm.errors.firstName}
            onChange={handleChange}
            style={{ marginTop: "1rem" }}
            type="text"
            value={assignVenueForm.values.firstName || ""}
            variant="outlined"
          />
          <TextField
            fullWidth
            label={dictionary.venues.assignVenueForm.lastNameFieldLabel}
            name="lastName"
            required
            error={!!assignVenueForm.errors.lastName}
            helperText={assignVenueForm.errors.lastName}
            onChange={handleChange}
            style={{ marginTop: "1rem" }}
            type="text"
            value={assignVenueForm.values.lastName || ""}
            variant="outlined"
          />
          <TextField
            fullWidth
            label={dictionary.venues.assignVenueForm.companyFieldLabel}
            name="company"
            required
            error={!!assignVenueForm.errors.company}
            helperText={assignVenueForm.errors.company}
            onChange={handleChange}
            style={{ marginTop: "1rem" }}
            type="text"
            value={assignVenueForm.values.company || ""}
            variant="outlined"
          />
          <TextField
            fullWidth
            label={dictionary.venues.assignVenueForm.titleFieldLabel}
            name="title"
            required
            placeholder={
              dictionary.venues.assignVenueForm.titleFieldPlaceholder
            }
            error={!!assignVenueForm.errors.title}
            helperText={assignVenueForm.errors.title}
            onChange={handleChange}
            style={{ marginTop: "1rem" }}
            type="text"
            value={assignVenueForm.values.title || ""}
            variant="outlined"
          />
          <TextField
            fullWidth
            label={dictionary.venues.assignVenueForm.emailFieldLabel}
            name="email"
            required
            error={!!assignVenueForm.errors.email}
            helperText={assignVenueForm.errors.email}
            onChange={handleChange}
            style={{ marginTop: "1rem" }}
            type="text"
            value={assignVenueForm.values.email || ""}
            variant="outlined"
          />
          <div style={{ marginTop: "1rem" }}>
            <PhoneNumberInput
              value={assignVenueForm.values.phone || ""}
              onChange={handleChange}
              name="phone"
              error={assignVenueForm.errors.phone}
              label={dictionary.contactUs.phoneNumberFieldLabel}
            />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={isWaitlistAdmin}
                onChange={(event) => setIsWaitlistAdmin(event.target.checked)}
                name="isWaitlistAdmin"
                color="primary"
              />
            }
            label={dictionary.venues.claimVenueDialog.includeWaitlist}
          />
          <TextMessage type="error" className={classes.message}>
            {errorMsg}
          </TextMessage>
          <TextMessage type="success">{successMsg}</TextMessage>
        </form>
        {loading && <LoadingIndicator withMask />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={handleClose}>
          {dictionary.venues.assignVenueForm.cancelButton}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={!assignVenueForm.isValid}
          onClick={handleAssignVenue}>
          {dictionary.venues.assignVenueForm.assignButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(AssignVenueDialog);
