import { UnclaimedVenues } from "../models";

export const GET_UNCLAIMED_VENUES_PENDING = "GET_UNCLAIMED_VENUES.PENDING";
export const GET_UNCLAIMED_VENUES_REJECTED = "GET_UNCLAIMED_VENUES.REJECTED";
export const GET_UNCLAIMED_VENUES_FULFILLED = "GET_UNCLAIMED_VENUES.FULFILLED";

export interface GetUnclaimedVenuesPendingAction {
  type: typeof GET_UNCLAIMED_VENUES_PENDING;
}

export interface GetUnclaimedVenuesSuccessAction {
  type: typeof GET_UNCLAIMED_VENUES_FULFILLED;
  unclaimedVenues: UnclaimedVenues[];
  unclaimedVenuesCount: number;
}

export interface GetUnclaimedVenuesFailAction {
  type: typeof GET_UNCLAIMED_VENUES_REJECTED;
  error: string;
}

export type UnclaimedVenuesActionTypes =
  | GetUnclaimedVenuesFailAction
  | GetUnclaimedVenuesSuccessAction
  | GetUnclaimedVenuesPendingAction;
