import React, { FC, useContext } from "react";

import AgeGroupSelect from "../../../../common/components/AgeGroupSelect";
import GenderSelect from "../../../../common/components/GenderSelect";
import { VenueReportingContext } from "../../../../common/components/VenueReportingContextProvider";
import { useStyles } from "./styles";

const PromotionAdditionalFilters: FC = () => {
  const { gender, setGender, ageGroupId, setAgeGroupId } = useContext(
    VenueReportingContext
  );
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AgeGroupSelect
        onChangeAgeGroupId={setAgeGroupId}
        ageGroupId={ageGroupId}
      />
      <GenderSelect gender={gender} onChangeGender={setGender} />
    </div>
  );
};

export default PromotionAdditionalFilters;
