import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "content"
  | "title"
  | "imageWrapper"
  | "image";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      title: {
        padding: 16,
        fontWeight: 600,
      },
      content: {
        padding: theme.spacing(4),
      },
      imageWrapper: {
        height: "100%",
        width: "100%",
        overflow: "auto",
      },
      image: {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
    })
);
