import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";

export type ClassNames =
  | "content"
  | "contentWrapper"
  | "root"
  | "pageTitle"
  | "pageDescription"
  | "header"
  | "contactLink"
  | "text404"
  | "toHomeButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      content: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(2),
      },
      contentWrapper: {
        [theme.breakpoints.up("md")]: {
          paddingBottom: 20,
        },
      },
      root: {
        marginTop: -72,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundImage: "url('/images/backgrounds/not-found-404-bg.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        [theme.breakpoints.up("md")]: {
          backgroundSize: "contain",
        },
        [theme.breakpoints.down("md")]: {
          backgroundSize: "cover",
          margin: theme.spacing(-10, -1, -1),
          backgroundImage:
            "url('images/backgrounds/not-found-404-mobile-bg.png')",
        },
      },
      header: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(4, 6, 1),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(2),
        },
      },
      contactLink: {
        color: theme.palette.text.secondary,
        marginLeft: 12,
      },
      pageTitle: {
        fontSize: theme.typography.pxToRem(42),
        lineHeight: theme.typography.pxToRem(50),
        fontWeight: "bold",
        marginTop: 10,
        [theme.breakpoints.down("md")]: {
          fontSize: theme.typography.pxToRem(32),
          lineHeight: theme.typography.pxToRem(40),
        },
      },
      pageDescription: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(25),
        marginTop: 17,
        textAlign: "center",
      },
      text404: {
        fontSize: theme.typography.pxToRem(100),
        lineHeight: theme.typography.pxToRem(120),
        fontWeight: "bold",
        [theme.breakpoints.down("md")]: {
          fontSize: theme.typography.pxToRem(70),
          lineHeight: theme.typography.pxToRem(90),
        },
      },
      toHomeButton: {
        minWidth: 228,
        marginTop: 58,
      },
    })
);
