import { http } from "../../../../config/api";

export const GET_VENUE_REVIEWS_STATISTICS = (
  venueId: number,
  monthsQty: number
) => `reviews/${venueId}/${monthsQty}/statistics`;

export const getVenueReviewsPeriod = async (
  venueId: number,
  monthsQty: number
): Promise<VenueReviewsStatistics> =>
  http
    .get(GET_VENUE_REVIEWS_STATISTICS(venueId, monthsQty))
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });

export interface VenueReviewsStatisticsItem {
  value: number;
  difference: number;
}
export interface VenueReviewsStatistics {
  avgFood: VenueReviewsStatisticsItem;
  avgNoise: VenueReviewsStatisticsItem;
  avgService: VenueReviewsStatisticsItem;
  criticReviews: VenueReviewsStatisticsItem;
  totalReviews: VenueReviewsStatisticsItem;
  userReviews: VenueReviewsStatisticsItem;
}
