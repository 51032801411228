import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";

import { AlertContext } from "../../../../../../../../../../../common/components/Alert/AlertContextProvider";
import OcietyTooltip from "../../../../../../../../../../../common/components/OcietyTooltip";
import PackageInformationPopup from "../../../../../../../../../../../common/components/PackageInformationPopup";
import { useIsDiscountAdmin } from "../../../../../../../../../../../common/hooks/useIsDiscountAdmin";
import { useVenueHasPackage } from "../../../../../../../../../../../common/hooks/useVenueHasPacakage";
import { ReactComponent as InfoTooltip } from "../../../../../../../../../../../common/svg/icons/info-tooltip.svg";
import { CroppedImageSize } from "../../../../../../../../../../../common/types/enums.type";
import dictionary from "../../../../../../../../../../../i18n/en_US/dictionary";
import { CurrentVenueState } from "../../../../../../../../../../../store/currentVenue/reducer";
import { PackageUID } from "../../../../../../../../../../../store/currentVenue/types";
import { AppState } from "../../../../../../../../../../../store/rootReducer";
import imageUtils from "../../../../../../../../../../../utils/imageUtils";
import VenueMediaCarousel from "../../../../../../../../../../VenueSettings/components/VenueMediaContent/components/VenueMediaCarousel";
import PhotoUpload from "../../../../../../../../../../VenueSettings/components/VenueMediaContent/components/VenueMediaUploader/components/PhotoUpload";
import { PromotionFormDialogContext } from "../../../../../../PromotionFormDialogContextProvider";
import { uploadPromotionPhoto, uploadPromotionVideo } from "./api";
import Props from "./Props";
import { useStyles } from "./styles";

const FileUploaderPromotion: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    error,
    onVideoUpload,
    onPhotoUpload,
    onPhotoDelete,
    photosWithIds,
    videosWithIds,
    onVideoDelete,
    onMainPhotoChange,
  } = props;
  const { showAlert } = useContext(AlertContext);
  const { promotionId } = useContext(PromotionFormDialogContext);
  const [isOpen, setIsOpen] = useState(false);

  const discountAdmin = useIsDiscountAdmin();
  const hasPackage = useVenueHasPackage(PackageUID.PROFILE_UPD);

  let additionalClass;
  const { currentVenue } = useSelector<AppState, CurrentVenueState>(
    (appState) => appState.currentVenue
  );

  if (error) {
    additionalClass = classes.error;
  }

  const venueId: number = currentVenue ? currentVenue.id : 0;

  const handleUploadPhoto = useCallback(
    (
      photo: File | Blob,
      progressUpdate: (value: number) => void,
      successCallback: () => void
    ) => {
      uploadPromotionPhoto(photo, venueId, promotionId, progressUpdate)
        .then((response) => response.data)
        .then(
          async (venuePhoto: { discountPhotoId: number; location: string }) => {
            onPhotoUpload({
              id: venuePhoto.discountPhotoId,
              url: venuePhoto.location,
            });
            if (!hasPackage && !discountAdmin) {
              setIsOpen(true);
            }

            await imageUtils.uploadCroppedImages(photo, venuePhoto.location, [
              CroppedImageSize.s300x300,
            ]);

            showAlert(
              dictionary.venues.mediaContent.successPhotoUpload,
              "success"
            );
            successCallback();
          }
        )
        .catch((error) => {
          showAlert(
            error.response
              ? error.response.data
              : dictionary.venues.mediaContent.errorPhotoUpload,
            "error"
          );
        });
    },
    [discountAdmin, venueId, promotionId, onPhotoUpload, showAlert, hasPackage]
  );

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  return (
    <div className={clsx(classes.root, additionalClass)}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography variant={"h2"} className={classes.title}>
          {dictionary.deals.createDealForm.uploadDealMedia}
        </Typography>
        <Typography variant={"h2"} className={classes.optionalLabel}>
          {dictionary.deals.createDealForm.optionalLabel}
        </Typography>
      </div>
      <Typography className={classes.label} variant={"subtitle2"}>
        {dictionary.deals.createDealForm.dealWithoutUploadingHint}
      </Typography>
      <div className={classes.uploadBtns}>
        <span>
          <PhotoUpload onUploadPhoto={handleUploadPhoto} isLock={!hasPackage} />
        </span>
      </div>
      {photosWithIds.length || videosWithIds.length ? (
        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.uploaderContainer}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.uploadedFilesTitleWrapper}>
              <Typography variant="h3" className={classes.uploadedFilesTitle}>
                {dictionary.venues.mediaContent.uploadedFiles}
              </Typography>
              <OcietyTooltip
                placement="right"
                title={
                  hasPackage
                    ? dictionary.venues.mediaContent.maxPhotoQuantity
                    : dictionary.venues.mediaContent.photoUploadHint
                }>
                <InfoTooltip className={classes.infoIcon} />
              </OcietyTooltip>
            </div>
            <VenueMediaCarousel
              promotionPhotos={photosWithIds}
              promotionVideos={videosWithIds}
              onDeletePhoto={onPhotoDelete}
              onDeleteVideo={onVideoDelete}
              onMainPhotoChange={onMainPhotoChange}
            />
          </Grid>
          <PackageInformationPopup
            open={isOpen}
            onClose={handleClosePopup}
            content={dictionary.venues.mediaContent.informationPopupContent}
            title={dictionary.venues.mediaContent.informationPopup}
          />
        </Grid>
      ) : null}
    </div>
  );
};
export default FileUploaderPromotion;
