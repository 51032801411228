import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import ButtonBlack from "../ButtonBlack";
import Props from "./Props";
import { useStyles } from "./styles";

const ListEmpty: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { imageSource, title, description, buttonOnClick } = props;

  return (
    <Box component="div" className={classes.root}>
      <img src={imageSource} alt="" className={classes.image} />
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="subtitle2" className={classes.description}>
        {description}
      </Typography>
      {buttonOnClick && (
        <ButtonBlack className={classes.button} onClick={buttonOnClick}>
          {dictionary.general.addNew}
        </ButtonBlack>
      )}
    </Box>
  );
};

export default ListEmpty;
