import { VenueMediaTag } from "../../../../../../../../common/models";
import { http } from "../../../../../../../../config/api";
const UPLOAD_VENUE_VIDEO = (venueId: string) => `/venue/${venueId}/videos`;
export const uploadVideo = (
  video: any,
  tag: VenueMediaTag,
  venueId: string,
  progressUpdate: (progressPercent: number) => void
) => {
  const formData = new FormData();
  formData.append("video", video);
  formData.append("tag", tag);

  return http.post(UPLOAD_VENUE_VIDEO(venueId), formData, {
    onUploadProgress: (progressEvent: ProgressEvent) => {
      progressUpdate(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
    },
  });
};
