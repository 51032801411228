import { http } from "../../config/api";
import { CreateCouponCode } from "./models";

const endpointsCoupons = {
  listAndCount: () => `/discount-coupons/`,
  itemById: (id: number) => `/discount-coupons/${id}`,
  add: () => `/discount-coupons/`,
  remove: (id: number) => `/discount-coupons/${id}`,
  update: () => `/discount-coupons/`,
};

const getCouponsListAndCount = (
  limit?: number,
  skip?: number,
  search?: string
) =>
  http
    .get(endpointsCoupons.listAndCount(), { params: { limit, skip, search } })
    .then((response) => response.data);

const addCouponCode = (data: CreateCouponCode) =>
  http.post(endpointsCoupons.add(), data).then((response) => ({
    ...response.data,
  }));

const removeCouponCode = (id: number): Promise<CreateCouponCode> =>
  http.delete(endpointsCoupons.remove(id)).then((response) => ({
    ...response.data,
  }));

const getCouponCodeById = (id: number) =>
  http.get(endpointsCoupons.itemById(id)).then((response) => response.data);

const updateCouponCode = (data: CreateCouponCode) =>
  http.put(endpointsCoupons.update(), data).then((response) => ({
    ...response.data,
  }));

export default {
  coupons: {
    listAndCount: getCouponsListAndCount,
    add: addCouponCode,
    remove: removeCouponCode,
    itemById: getCouponCodeById,
    update: updateCouponCode,
  },
};
