import { Dialog, useMediaQuery } from "@material-ui/core";
import React, { FC, useState } from "react";

import { OcietyTheme } from "../../../../../common/models";
import IPromotionDietaryRestriction from "../../../types/interfaces/IPromotionDietaryRestriction.interface";
import { useStyles } from "../../styles";
import PromotionFormDialogActions from "./components/PromotionFormDialogActions";
import PromotionFormDialogContent from "./components/PromotionFormDialogContent";
import PromotionFormDialogTitle from "./components/PromotionFormDialogTitle";
import PromotionFormDialogContextProvider from "./PromotionFormDialogContextProvider";
import Props from "./Props";

const PromotionFormDialog: FC<Props> = (props: Props) => {
  const { open, closeDialog, promotionId, refresh } = props;
  const classes = useStyles();
  const [dietaryRestrictions, setDietaryRestrictions] = useState<
    IPromotionDietaryRestriction[] | undefined
  >([]);
  const [ageGroupId, setAgeGroupId] = useState<number[]>([]);

  const isMobile = useMediaQuery((theme: OcietyTheme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <PromotionFormDialogContextProvider>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        onClose={closeDialog}
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen={isMobile}
        maxWidth={isMobile ? false : "sm"}
        classes={{
          paperWidthSm: classes.dialogPaperSM,
        }}>
        <PromotionFormDialogTitle
          editMode={!!promotionId}
          closeDialog={closeDialog}
          propsPromotionId={promotionId}
          setAgeGroupId={setAgeGroupId}
          setDietaryRestrictions={setDietaryRestrictions}
        />
        <PromotionFormDialogContent
          setDietaryRestrictions={setDietaryRestrictions}
          setAgeGroupId={setAgeGroupId}
          dietaryRestrictions={dietaryRestrictions}
          ageGroupId={ageGroupId}
          promotionId={promotionId}
        />
        <PromotionFormDialogActions
          dietaryRestrictions={dietaryRestrictions}
          ageGroupId={ageGroupId}
          setAgeGroupId={setAgeGroupId}
          setDietaryRestrictions={setDietaryRestrictions}
          refresh={refresh}
          closeDialog={closeDialog}
          propsPromotionId={promotionId}
        />
      </Dialog>
    </PromotionFormDialogContextProvider>
  );
};

export default PromotionFormDialog;
