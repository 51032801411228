import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type VenueClassNames = "root" | "error" | "title" | "box";

export const useStyles: () => ClassNameMap<VenueClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      // root: {
      //   display: "flex",
      //   flexDirection: "column",
      //   flex: 1,
      //   padding: 30,
      // },
      root: {
        width: "100%",
        maxWidth: 500,
        backgroundColor: theme.palette.background.paper,
      },
      error: {
        color: "red",
      },
      title: {
        margin: theme.spacing(4, 0, 2),
      },
      box: {
        backgroundColor: theme.palette.background.paper,
      },
    })
);
