export const GET_VENUE_PROMOTION_RECOMMENDATIONS_POSITION =
  "reporting/promotion-recommendations-position-by-month";

export interface PromotionRecommendationsPositionData {
  month: string;
  position: number;
}

export interface GetVenuePromotionRecommendationsPositionResponse {
  stats: PromotionRecommendationsPositionData[];
}
