import { SelectClassKey } from "@material-ui/core";
import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../common/models";

export type ClassNames = "root" | "filterSelect";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      filterSelect: {
        textAlign: "left",
        marginLeft: "auto",
        marginRight: theme.spacing(3),
      },
    })
);
export const useSelectStyles: () => Partial<ClassNameMap<SelectClassKey>> =
  makeStyles(() =>
    createStyles({
      root: {
        padding: 14,
        width: 139,
      },
    })
  );
