import { AppBar, Grid, Toolbar, Typography } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  GET_USER_DATA_FOR_HEADER,
  LOGOUT_USER,
  UserHeaderData,
} from "../../../common/components/AccountDropdown/api";
import OcietyNavLink from "../../../common/components/OcietyNavLink";
import { useRestApi } from "../../../common/hooks/useRestApi";
import { ReactComponent as LogoutIcon } from "../../../common/svg/icons/logout.svg";
import { Route } from "../../../config/router";
import dictionary from "../../../i18n/en_US/dictionary";
import { purgePersistor } from "../../../store";
import { logout } from "../../../store/auth/actions";
import { AppState } from "../../../store/rootReducer";
import { setUser } from "../../../store/user/actions";
import { UserState } from "../../../store/user/reducer";
import { useStyles } from "./styles";

const PromotionsTopbar: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { firstName, lastName } = useSelector<AppState, UserState>(
    (appState) => appState.user
  );
  const [_, getUserData] = useRestApi<UserHeaderData>(
    GET_USER_DATA_FOR_HEADER,
    "GET"
  );
  const [, userLogout] = useRestApi(LOGOUT_USER, "POST");

  useEffect(() => {
    getUserData()
      .then((data) => {
        if (data) {
          dispatch(setUser(data));
        }
      })
      .catch((e) => console.error(e));
  }, [dispatch, getUserData]);

  const handleLogout = () => {
    userLogout().then(() => {
      purgePersistor();
      dispatch(logout());
    });
  };

  return (
    <AppBar className={classes.root} color="inherit" position="relative">
      <Toolbar>
        <Grid item lg={6} md={6} sm={6} xs={6} className={classes.rowItemLeft}>
          <Typography variant="h6" component="h6" className={classes.title}>
            {dictionary.managerDeals.greeting(firstName)}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6} className={classes.rowItemRight}>
          <OcietyNavLink
            onClick={handleLogout}
            to={Route.ManagerDeals}
            icon={<LogoutIcon />}
          />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(PromotionsTopbar);
