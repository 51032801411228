import { FormControl, InputLabel } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Route } from "../../../config/router";
import dictionary from "../../../i18n/en_US/dictionary";
import {
  getOwnVenues,
  updateCurrentVenue,
} from "../../../store/currentVenue/actions";
import { CurrentVenueState } from "../../../store/currentVenue/reducer";
import { OwnVenue } from "../../../store/currentVenue/types";
import { AppState } from "../../../store/rootReducer";
import { ReactComponent as WarningIcon } from "../../svg/icons/warning-circle.svg";
import OcietyNavLink from "../OcietyNavLink";
import SelectIcon from "../SelectIcon";
import Props from "./Props";
import { useStyles } from "./styles";

const VenueSelect: FC<Props> = (props: Props) => {
  const { classes: customClasses, className } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentVenue, ownVenues } = useSelector<AppState, CurrentVenueState>(
    (state) => state.currentVenue
  );

  useEffect(() => {
    dispatch(getOwnVenues());
  }, [dispatch]);

  if (!ownVenues || ownVenues.length < 2) {
    return (
      <div>
        <h1 className={classes.oneVenueTitle}>
          {dictionary.venues.ownVenues.yourVenueLabel}
          <strong className={classes.oneVenueName}>
            {ownVenues && ownVenues[0] ? ownVenues[0].name : ""}
          </strong>
        </h1>
      </div>
    );
  }

  const changeCurrentVenue = (e: any) => {
    dispatch(updateCurrentVenue(e.target.value));
  };

  return (
    <div className={clsx(classes.root, customClasses?.root)}>
      <FormControl variant="outlined" className={className}>
        <InputLabel
          id="demo-simple-select-outlined-label"
          className={classes.selectLabel}>
          {dictionary.venues.ownVenues.venueSelectPlaceholder}
        </InputLabel>
        <Select
          IconComponent={(iconProps) => (
            <SelectIcon {...iconProps} iconName={"caretDownRed"} />
          )}
          classes={{ select: classes.select }}
          className={clsx(classes.selectWrapper, customClasses?.selectWrapper)}
          id="current_venue_select"
          value={currentVenue?.id || ownVenues[0].id}
          label={dictionary.venues.ownVenues.venueSelectPlaceholder}
          onChange={changeCurrentVenue}>
          <MenuItem value="" disabled>
            <em>{dictionary.venues.ownVenues.venueSelectPlaceholder}</em>
          </MenuItem>
          {ownVenues.map((venue: OwnVenue) => (
            <MenuItem key={venue.id} value={venue.id}>
              {venue.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {currentVenue !== undefined && !currentVenue.isActive && (
        <div
          className={clsx(
            classes.notActiveMessageRoot,
            customClasses?.notActiveMessageRoot
          )}>
          <WarningIcon className={classes.warningIcon} color={"red"} />
          <OcietyNavLink
            className={classes.notActiveMessageTextLink}
            to={Route.VenueSettings}
            linkTextClassName={classes.notActiveMessageText}>
            {dictionary.currentVenue.notActiveMessage}
          </OcietyNavLink>
        </div>
      )}
    </div>
  );
};

export default VenueSelect;
