import { Venue } from "../../../common/models";

export const GET_VENUES_PENDING = "GET_VENUES.PENDING";
export const GET_VENUES_REJECTED = "GET_VENUES.REJECTED";
export const GET_VENUES_FULFILLED = "GET_VENUES.FULFILLED";

export interface GetAllVenuesPendingAction {
  type: typeof GET_VENUES_PENDING;
}

export interface GetAllVenuesSuccessAction {
  type: typeof GET_VENUES_FULFILLED;
  venues: Venue[];
  venuesCount: number;
}

export interface GetAllVenuesFailAction {
  type: typeof GET_VENUES_REJECTED;
  error: string;
}

export type VenuesActionTypes =
  | GetAllVenuesPendingAction
  | GetAllVenuesSuccessAction
  | GetAllVenuesFailAction;
