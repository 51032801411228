export enum PackageCategory {
  ANALYTICS = "ANALYTICS",
  PROFILE_UPGRADES = "PROFILE_UPGRADES",
}

export enum PackageUID {
  BASIC = "BASIC",
  CUSTOMER = "CUSTOMER",
  PROMOTIONS = "PROMOTIONS",
  RECOMMENDATIONS = "RECOMMENDATIONS",
  PROFILE_UPD = "PROFILE_UPD",
  FULL_ACCESS = "FULL_ACCESS",
  VENUE_BOOST_100 = "VENUE_BOOST_100",
  VENUE_BOOST_250 = "VENUE_BOOST_250",
  VENUE_BOOST_1000 = "VENUE_BOOST_1000",
}

export enum PackageGroup {
  VENUE_BOOST = "VENUE_BOOST",
}

export interface Package {
  id: number;
  name: string;
  description: string;
  benefits: string[];
  cancelable: boolean;
  category: PackageCategory;
  packageUid: PackageUID;
  discountPercent: number;
  price: number;
  mainInGroup: boolean;
  group: PackageGroup | null;
  renewable: boolean;
  usagesLimit: number | null;
  currentUsagesLimit: number | null;
}
