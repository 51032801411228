import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

type ClassNames = "root";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {
      width: 260,
      height: 260,
      padding: 20,
      margin: "auto",
      backgroundSize: "100%",
      backgroundImage: "url('/images/backgrounds/qr-background.svg')",
      backgroundRepeat: "no-repeat",
    },
  })
);
