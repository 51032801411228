import createPalette, { Palette } from "@material-ui/core/styles/createPalette";

import { colors } from "./colors";

const palette: Palette = createPalette({
  primary: {
    main: colors.red.primary,
    light: colors.red.light,
  },
  secondary: {
    dark: colors.grey.primary,
    main: colors.grey.primary,
    light: colors.grey.light,
  },
  success: {
    main: colors.green.secondary,
  },
  info: {
    main: colors.blue.secondary,
  },
  warning: {
    main: colors.orange.main,
  },
  error: {
    main: colors.red.primary,
  },
  text: {
    primary: colors.grey.primary,
    secondary: colors.grey.secondary,
  },
  background: {
    default: colors.grey.lighten,
    paper: colors.white.primary,
  },
  divider: colors.grey.divider,
});

export default palette;
