import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../i18n/en_US/dictionary";
import { OcietyTheme } from "../../../models";
import ButtonBlack from "../../ButtonBlack";
import ButtonCancel from "../../ButtonCancel";
import ButtonDelete from "../../ButtonDelete";
import ButtonPrimary from "../../ButtonPrimary";
import OcietyDialogTitle from "../OcietyDIalogTitle";
import Props, { ConfirmModalType } from "./Props";
import { useStyles } from "./styles";

const ConfirmDialog: FC<Props> = (props: Props) => {
  const {
    open,
    onClose,
    onConfirm,
    type,
    yesTypeAdditionalText,
    title,
    subtitle,
    message,
  } = props;

  const isMobile = useMediaQuery((theme: OcietyTheme) =>
    theme.breakpoints.down("xs")
  );

  const classes = useStyles();
  const additionalText = yesTypeAdditionalText ? yesTypeAdditionalText : "";

  const renderConfirmButton = () => {
    switch (type) {
      case ConfirmModalType.DELETE: {
        return (
          <ButtonDelete
            className={classes.mobileButton}
            onClick={() => {
              onConfirm();
              onClose();
            }}
          />
        );
      }
      case ConfirmModalType.YES_DELETE: {
        return (
          <ButtonPrimary
            className={classes.mobileButton}
            onClick={() => {
              onConfirm();
              onClose();
            }}>
            {dictionary.confirmDialog.yesDeleteButton(additionalText)}
          </ButtonPrimary>
        );
      }
      case ConfirmModalType.CONFIRM: {
        return (
          <ButtonBlack
            className={classes.mobileButton}
            onClick={() => {
              onConfirm();
              onClose();
            }}>
            {dictionary.confirmDialog.confirmButton}
          </ButtonBlack>
        );
      }
    }
  };

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      classes={{
        paperWidthSm: classes.root,
      }}>
      <OcietyDialogTitle id="confirmation-dialog-title" onClose={onClose}>
        {title || dictionary.confirmDialog.title}
      </OcietyDialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <Typography variant={"subtitle1"} className={classes.subtitle}>
          {subtitle || null}
        </Typography>
        <Typography className={classes.message}>{message}</Typography>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <ButtonCancel onClick={onClose} className={classes.mobileButton}>
          {dictionary.confirmDialog.cancelButton}
        </ButtonCancel>
        {renderConfirmButton()}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
