import { UserHeaderData } from "../../common/components/AccountDropdown/api";
import {
  SET_USER,
  SET_USER_AVATAR,
  SetUserActionType,
  SetUserAvatarActionType,
} from "./types";

export const setUser = (user: Partial<UserHeaderData>): SetUserActionType => ({
  type: SET_USER,
  ...user,
});

export const setUserAvatar = (avatar: string): SetUserAvatarActionType => ({
  type: SET_USER_AVATAR,
  avatar,
});
