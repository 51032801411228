import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

type ClassNames =
  | "root"
  | "item"
  | "icon"
  | "counterIcon"
  | "iconActive"
  | "link"
  | "linkActive"
  | "linkText"
  | "linkTextActive";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        height: "100%",
      },
      item: {
        display: "flex",
        paddingTop: 0,
        paddingBottom: 0,
      },
      icon: {},
      iconActive: {
        display: "none",
      },
      counterIcon: {
        borderRadius: "50%",
        height: 22,
        width: 22,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.red.primary,
      },
      link: {
        padding: theme.spacing(17 / 8, 20 / 8),
        position: "relative",
        borderRadius: 4,
        "&.active": {
          backgroundColor: theme.palette.primary.light,
          textDecoration: "none",
        },
        "&:hover": {
          textDecoration: "none",
        },
        "&:hover $linkText, &.active $linkText": {
          color: theme.palette.primary.main,
        },
        "&:hover $icon, &.active $icon": {
          display: "none",
        },
        "&:hover $iconActive, &.active $iconActive": {
          display: "flex",
        },
      },
      linkText: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        whiteSpace: "nowrap",
        marginLeft: theme.spacing(30 / 8),
        fontWeight: "bold",
        fontSize: theme.typography.pxToRem(16),
      },
      linkTextActive: {
        color: colors.red.primary,
      },
      linkActive: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        "& > $iconActive": {
          display: "flex",
        },
        "& > $icon": {
          display: "none",
        },
      },
    })
);
