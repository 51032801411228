import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useSelector } from "react-redux";

import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { CartState } from "../../../../store/cart/reducer";
import { AppState } from "../../../../store/rootReducer";
import PackageCard from "../PackageCard";
import Props from "./Props";
import { useStyles } from "./styles";

const PackageCardSingle: FC<Props> = (props: Props) => {
  const {
    package: packageItem,
    active,
    enablePackage,
    disablePackage,
    toggleCart,
  } = props;
  const { benefits, cancelable, name, price, id, renewable } = packageItem;
  const classes = useStyles();
  const venueId = useCurrentVenueId();
  const cartState = useSelector<AppState, CartState>((state) => state.cart);

  const onEnable = () => {
    enablePackage(id);
  };

  const onDisable = () => {
    disablePackage(id);
  };

  const renderContent = () => (
    <ul>
      {benefits.map((benefit) => (
        <li key={benefit}>
          <Typography className={classes.benefitItem}>{benefit}</Typography>
        </li>
      ))}
    </ul>
  );

  return (
    <PackageCard
      name={name}
      active={active}
      renewable={renewable}
      inCart={!!cartState[venueId]?.includes(id)}
      toggleCart={toggleCart}
      cancelable={cancelable}
      onDisable={onDisable}
      price={price}
      onEnable={onEnable}>
      {renderContent()}
    </PackageCard>
  );
};

export default PackageCardSingle;
