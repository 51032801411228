import { FormControlLabel, Radio } from "@material-ui/core";
import clsx from "clsx";
import { FC } from "react";
import React from "react";

import BlockWrapper from "../../../views/Promotions/AdminVenue/dialogs/PromotionFormDialog/components/PromotionFormDialogContent/components/BasicInfoPromotionStep/components/BlockWrapper";
import Props from "./Props";
import { useStyles } from "./styles";

const OcietyBlockWrapperForRadioButton: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { isActive, label, value, children, disabled } = props;
  const checkedIcon = () => <div className={classes.checkedIcon} />;
  const uncheckedIcon = () => <div className={classes.uncheckedIcon} />;

  return (
    <BlockWrapper isActive={isActive} style={props.wrapperStyle}>
      <FormControlLabel
        value={value}
        disabled={disabled}
        control={<Radio icon={uncheckedIcon()} checkedIcon={checkedIcon()} />}
        classes={{
          root: clsx(classes.radioWrapper, props.rootClassName),
          label: classes.radioLabel,
        }}
        label={label}
      />
      {children}
    </BlockWrapper>
  );
};
export default OcietyBlockWrapperForRadioButton;
