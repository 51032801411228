import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "label"
  | "improvementsLabel"
  | "subLabel"
  | "subLabelDot";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        backgroundColor: colors.grey.primary,
        borderRadius: 4,
        boxShadow: "0px 6px 10px rgba(70, 72, 84, 0.15)",
        padding: theme.spacing(1, 2),
      },
      label: {
        color: colors.white.primary,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: "bold",
        fontFamily: "Nunito Sans",
      },
      improvementsLabel: {
        fontSize: theme.typography.pxToRem(16),
        paddingTop: 8,
        paddingBottom: 16,
      },
      subLabel: {
        color: colors.grey.medium,
        paddingBottom: 12,
      },
      subLabelDot: {
        color: colors.grey.secondary,
        fontSize: theme.typography.pxToRem(20),
      },
    })
);
