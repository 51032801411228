import {
  CURRENT_VENUE_GET_OWN_VENUES_FULFILLED,
  CURRENT_VENUE_GET_OWN_VENUES_PENDING,
  CURRENT_VENUE_GET_OWN_VENUES_REJECTED,
  CURRENT_VENUE_SET_CURRENT_VENUE,
  CurrentVenueActionTypes,
  OwnVenue,
} from "./types";

export interface CurrentVenueState {
  currentVenue?: OwnVenue;
  ownVenues?: OwnVenue[];
  getOwnVenuesError?: string;
  getOwnVenuesLoading: boolean;
}

const defaultState: CurrentVenueState = {
  getOwnVenuesLoading: false,
};

export default (
  state: CurrentVenueState = defaultState,
  action: CurrentVenueActionTypes
): CurrentVenueState => {
  switch (action.type) {
    case CURRENT_VENUE_SET_CURRENT_VENUE:
      return {
        ...state,
        currentVenue: action.venue,
      };
    case CURRENT_VENUE_GET_OWN_VENUES_FULFILLED:
      const newState = {
        ...state,
        ownVenues: action.venues,
        getOwnVenuesError: undefined,
        getOwnVenuesLoading: false,
      };

      const currentVenue = state.currentVenue;

      if (!currentVenue) {
        newState.currentVenue = action.venues[0];
      } else {
        newState.currentVenue = action.venues.filter(
          (venue) => venue.id === currentVenue.id
        )[0];
      }

      return newState;
    case CURRENT_VENUE_GET_OWN_VENUES_PENDING:
      return {
        ...state,
        getOwnVenuesError: undefined,
        getOwnVenuesLoading: true,
      };
    case CURRENT_VENUE_GET_OWN_VENUES_REJECTED:
      return {
        ...state,
        getOwnVenuesError: action.error,
        getOwnVenuesLoading: false,
      };
    default:
      return state;
  }
};
