import {
  chartAdditionalColor,
  chartPrimaryColor,
  chartSecondaryColor,
} from "../theme/colors";

export function getBarFillColor<T extends Array<Record<string, any>>>(
  chartData: T,
  index: number,
  dataKey: string
) {
  const valuesArray = chartData.map(
    (item: Record<string, number>) => item[dataKey]
  );
  const middleBreakpoint = Math.max(...valuesArray) / 3;
  const largeBreakpoint = (Math.max(...valuesArray) / 3) * 2;
  const value = valuesArray[index];

  return value < middleBreakpoint
    ? chartSecondaryColor
    : value < largeBreakpoint
    ? chartAdditionalColor
    : chartPrimaryColor;
}
