import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames = "root" | "dialogContent";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      dialogContent: {
        minHeight: 0,
        [theme.breakpoints.up("md")]: {
          paddingTop: theme.spacing(6),
          minWidth: 900,
        },
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(2),
          minWidth: 500,
        },
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(2),
          minWidth: "auto",
          width: "100%",
        },
      },
    })
);
