import { ForgotPasswordFormSchema } from "../../../../store/forms/types";

export const schema: ForgotPasswordFormSchema = {
  email: {
    email: true,
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
};
