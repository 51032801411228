import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Edit, VpnKey } from "@material-ui/icons";
import React, { FC } from "react";
import { NavLink } from "react-router-dom";

import IRole from "../../models/IRole";
import Props from "./Props";
import { useStyles } from "./styles";

const RolesList: FC<Props> = (props: Props) => {
  const { roles, onEditOpen } = props;
  const classes = useStyles();

  if (!roles.length) {
    return <div>Not found</div>;
  }

  return (
    <div className={classes.root}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell align="right">Name </TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((row: IRole) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.description}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="edit"
                    onClick={() => onEditOpen(row)}
                    className={classes.icon}>
                    <Edit />
                  </IconButton>

                  <NavLink to={"/rbac/role/" + row.id}>
                    <IconButton aria-label="edit" className={classes.icon}>
                      <VpnKey />
                    </IconButton>
                  </NavLink>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default RolesList;
