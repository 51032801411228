import { ChartDataItem } from "./models";

export const chartDataMock: ChartDataItem[] = [
  {
    month: "Jan",
    views: 1200,
  },
  {
    month: "Feb",
    views: 874,
  },
  {
    month: "Mar",
    views: 993,
  },
  {
    month: "Apr",
    views: 600,
  },
  {
    month: "May",
    views: 567,
  },
  {
    month: "Jun",
    views: 1045,
  },
];
