import { Typography } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import React, { FC } from "react";

import ButtonBlack from "../../../../../../../../../common/components/ButtonBlack";
import ButtonPrimary from "../../../../../../../../../common/components/ButtonPrimary";
import { Venue } from "../../../../../../../../../common/models";
import { ReactComponent as ChevronIcon } from "../../../../../../../../../common/svg/icons/chevron.svg";
import dictionary from "../../../../../../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../../../../../../utils/dateTimeUtils";
import PromotionImage, {
  PROMOTION_IMAGES,
} from "../../../../../../../components/PromotionImage";
import SelectHappyHour from "../../../../../../../components/SelectHappyHour";
import { HappyHour } from "../../../../../../../types/interfaces/HappyHour.inteface";
import { PartialDiscount } from "../../../../../../../types/interfaces/PartialDiscount.interface";
import Props from "./Props";
import { useStyles } from "./styles";

function RowDeal({
  row,
  updateStatus,
}: {
  row: PartialDiscount;
  updateStatus: (id: number, status: string) => void;
}) {
  const classes = useStyles();

  return (
    <List component="div" disablePadding>
      <ListItem className={classes.nestedListItem}>
        <ListItemText
          primary={dictionary.deals.happyHours.table.photo}
          className={classes.nestedListItemTitle}
        />
        <PromotionImage
          categoryName={row.mainCategory?.name as keyof typeof PROMOTION_IMAGES}
        />
      </ListItem>
      <ListItem className={classes.nestedListItem}>
        <ListItemText
          primary={dictionary.deals.happyHours.table.requestDate}
          className={classes.nestedListItemTitle}
        />
        <ListItemText
          primary={getFormattedDate(row.createdAt)}
          className={classes.nestedListItemDescription}
        />
      </ListItem>
      <ListItem className={classes.nestedListItem}>
        <ListItemText
          primary={dictionary.deals.happyHours.table.status}
          className={classes.nestedListItemTitle}
        />
        <SelectHappyHour
          status={row.status}
          id={row.id}
          onChange={updateStatus}
        />
      </ListItem>
    </List>
  );
}
function Row({
  row,
  onActiveDaysDialogOpen,
  updateStatus,
  removeHappyHour,
  editHappyHour,
}: {
  row: HappyHour;
  onActiveDaysDialogOpen: (isOpen: boolean, id: number) => void;
  updateStatus: (id: number, status: string) => void;
  removeHappyHour: (id: number) => void;
  editHappyHour: (id: number) => void;
}) {
  const classes = useStyles();

  const handleClick = () => {
    setOpen(!open);
  };

  const [open, setOpen] = React.useState(false);

  const openActiveDaysDialog = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onActiveDaysDialogOpen(true, row.id);
  };

  const getVenueFullAddress = ({ name, address, location, postcode }: Venue) =>
    `${name}, ${address}, ${location ? location.city : ""} ${postcode || ""}`;

  const handleRemoveHappyHour = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    event.stopPropagation();
    removeHappyHour(row.id);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={
            <Typography variant="body1" className={classes.listItemTitle}>
              {dictionary.deals.happyHours.table.venue}
            </Typography>
          }
        />
        <div>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                className={classes.listItemDescription}>
                {row.venue.name}
              </Typography>
            }
          />
          <ListItemText
            primary={
              <Typography variant="body1" className={classes.listItemContent}>
                {getVenueFullAddress(row.venue)}
              </Typography>
            }
          />
        </div>
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={
            <Typography variant="body1" className={classes.listItemTitle}>
              {dictionary.deals.happyHours.table.requestDate}
            </Typography>
          }
        />
        <ListItemText primary={getFormattedDate(row.createdAt)} />
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={
            <Typography variant="body1" className={classes.listItemTitle}>
              {dictionary.deals.happyHours.table.activeDaysHours}
            </Typography>
          }
        />
        <ListItemText
          className={classes.activeDaysAndHoursContainer}
          onClick={openActiveDaysDialog}
          primary={
            <Typography variant="h3" className={classes.listItemUnderline}>
              {dictionary.deals.happyHours.activeDaysAndHours}
            </Typography>
          }
        />
      </ListItem>
      <ListItem className={classes.listItemActions}>
        <ButtonPrimary
          style={{ marginRight: 17 }}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleRemoveHappyHour(event, row.id)
          }>
          {dictionary.general.delete}
        </ButtonPrimary>
        <ButtonBlack onClick={() => editHappyHour(row.id)}>
          {dictionary.deals.happyHours.viewDetails}
        </ButtonBlack>
      </ListItem>

      <ListItem
        button
        onClick={handleClick}
        className={classes.listItemDropdownActions}>
        <ListItemText
          primary={
            open ? (
              <Typography
                variant="body1"
                className={clsx(classes.listItemBold, classes.colorRed)}>
                {dictionary.deals.happyHours.hideItems}
              </Typography>
            ) : (
              <Typography variant="body1" className={classes.listItemBold}>
                {dictionary.deals.happyHours.showItems}
              </Typography>
            )
          }
          className={classes.listItemDropdownActionsText}
        />
        {open ? (
          <ChevronIcon className={classes.chevronIconOpened} />
        ) : (
          <ChevronIcon className={classes.chevronIconNotOpened} />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {row?.deals.map((item: PartialDiscount) => (
          <RowDeal key={item.id} row={item} updateStatus={updateStatus} />
        ))}
      </Collapse>
    </List>
  );
}

const ListNested: FC<Props> = (props: Props) => {
  const {
    onActiveDaysDialogOpen,
    list,
    updateStatus,
    removeHappyHour,
    editHappyHour,
  } = props;

  return (
    <>
      {list?.map((item: HappyHour) => (
        <Row
          key={item.id}
          row={item}
          onActiveDaysDialogOpen={onActiveDaysDialogOpen}
          updateStatus={updateStatus}
          removeHappyHour={removeHappyHour}
          editHappyHour={editHappyHour}
        />
      ))}
    </>
  );
};

export default ListNested;
