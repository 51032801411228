export enum ConfirmModalType {
  DELETE = "DELETE",
  YES_DELETE = "YES_DELETE",
  CONFIRM = "CONFIRM",
}

export default interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  type?: ConfirmModalType;
  yesTypeAdditionalText?: string;
  title?: string;
  subtitle?: string;
  message: string;
}
