import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";

import Footer from "../../common/components/Footer";
import Topbar from "../../common/components/Topbar";
import { useIsDiscountAdmin } from "../../common/hooks/useIsDiscountAdmin";
import { OcietyTheme } from "../../common/models";
import Sidebar from "./components/Sidebar";
import Props from "./Props";
import { useStyles } from "./styles";

const Main: FC<Props> = (props: Props) => {
  const { children, className } = props;

  const classes = useStyles();
  const theme: OcietyTheme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const discountAdmin = useIsDiscountAdmin();

  const [openSidebar, setOpenSidebar] = useState(isDesktop);

  useEffect(() => {
    setOpenSidebar(isDesktop);
  }, [isDesktop]);

  const toggleSidebar = () => {
    setOpenSidebar((prevState) => !prevState);
  };

  return (
    <div className={clsx(classes.root, className)}>
      {!discountAdmin && (
        <Sidebar
          toggleSidebar={toggleSidebar}
          open={openSidebar}
          variant={isDesktop ? "permanent" : "temporary"}
        />
      )}
      <div
        className={
          isDesktop
            ? discountAdmin
              ? classes.managerPromotions
              : openSidebar
              ? classes.sidebar
              : classes.noSidebar
            : classes.mobile
        }>
        <div className={classes.commonWrapper}>
          <Topbar toggleSidebar={toggleSidebar} />
          <div className={classes.container}>
            <main className={classes.content}>{children}</main>
            {!discountAdmin && <Footer className={classes.footer} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
