import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../../../../theme/colors";

export type ClassNames = "urlFieldWrapper" | "helperText";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    urlFieldWrapper: {
      marginTop: 40,
      marginBottom: 40,
    },
    helperText: {
      color: colors.green.dark,
      fontSize: 14,
      fontWeight: 600,
      marginTop: 16,
    },
  })
);
