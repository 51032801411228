import { Divider } from "@material-ui/core";
import React, { FC, useContext } from "react";

import OcietyDialogTitle from "../../../../../../../../../../../common/components/dialogs/OcietyDIalogTitle";
import dictionary from "../../../../../../../../../../../i18n/en_US/dictionary";
import { DialogContext } from "../../DialogContextProvider";
import Props from "./Props";

const Title: FC<Props> = (props: Props) => {
  const { closeDialog } = props;

  const { resetForm } = useContext(DialogContext);

  const closeAndReset = () => {
    closeDialog();
    resetForm();
  };

  return (
    <div>
      <OcietyDialogTitle id="alert-dialog-title" onClose={closeAndReset}>
        {dictionary.deals.happyHours.createRequestHappyHour}
      </OcietyDialogTitle>
      <Divider />
    </div>
  );
};
export default Title;
