import { Button, Grid } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import React, { FC } from "react";

import ButtonSuccess from "../../../../../../common/components/ButtonSuccess";
import OcietyIcon from "../../../../../../common/components/OcietyIcon";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const MFAItem: FC<Props> = (props: Props) => {
  const {
    title,
    description,
    info,
    infoHelp,
    isActive,
    selected,
    onEnableClick,
    onDisableClick,
    onSelectClick,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <div className={classes.wrapper}>
            {isActive ? (
              <OcietyIcon
                name={"okCircleGreen"}
                width="30"
                height="30"
                viewBox="0 0 30 30"
              />
            ) : (
              <OcietyIcon
                name={"xCircleRed"}
                width="30"
                height="30"
                viewBox="0 0 30 30"
              />
            )}

            <p className={classes.title}>{title}</p>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.wrapperActions}>
            {isActive ? (
              <Button
                onClick={onDisableClick}
                className={classes.btn}
                variant="contained"
                color="primary">
                {dictionary.venueAdmin.profile.mfa.disableBtn}
              </Button>
            ) : (
              <ButtonSuccess onClick={onEnableClick}>
                {dictionary.venueAdmin.profile.mfa.enableBtn}
              </ButtonSuccess>
            )}

            {isActive && (
              <>
                <Radio
                  checked={selected}
                  style={{ marginLeft: 10 }}
                  onClick={onSelectClick}
                />
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          {!isActive && description && (
            <p className={classes.descriptionText}>{description}</p>
          )}
          {isActive && info && <div className={classes.infoText}>{info}</div>}
          {isActive && infoHelp && (
            <div className={classes.infoHelpText}>{infoHelp}</div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default MFAItem;
