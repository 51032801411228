import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";
import { HEADER_HEIGHT } from "./index";

export type TopBarClassNames =
  | "actionsWrapper"
  | "root"
  | "menuBurger"
  | "menuLinks"
  | "menuLink"
  | "menuLinkText"
  | "freeTrial"
  | "freeTrialLabel";

export const useStyles: () => ClassNameMap<TopBarClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      actionsWrapper: {
        display: "flex",
        marginLeft: "auto",
        marginRight: theme.spacing(2),
        "& > *": {
          marginLeft: theme.spacing(2),
        },
      },
      root: {
        borderRadius: "0 !important",
        display: "flex",
        justifyContent: "center",
        height: HEADER_HEIGHT,
        width: "auto",
        boxShadow: "30px 0px 30px rgba(59, 72, 118, 0.08) !important",
        padding: theme.spacing(0),
        backgroundImage: theme.palette.primary.main,
      },
      menuLinks: {
        marginLeft: "auto",
      },
      menuLink: {
        color: colors.white.primary,
      },
      menuLinkText: {
        fontSize: theme.font.size.l,
      },
      menuBurger: {
        marginRight: theme.spacing(2),
      },
      freeTrial: {
        position: "absolute",
        right: "50%",
        top: 0,
        backgroundColor: colors.red.primary,
        padding: "5px 12px 7px",
        display: "flex",
        flexDirection: "row",
        gap: 8,
        alignItems: "center",
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.12)",
      },
      freeTrialLabel: {
        color: colors.white.primary,
        fontWeight: 600,
      },
    })
);
