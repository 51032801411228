import { ChartDataItem } from "./models";

export const chartDataMock: ChartDataItem[] = [
  {
    month: "Jan",
    Iphone: 40,
    IphoneWithPercent: "40%",
    Android: 60,
    AndroidWithPercent: "60%",
  },
  {
    month: "Feb",
    Iphone: 30,
    IphoneWithPercent: "30%",
    Android: 70,
    AndroidWithPercent: "70%",
  },
  {
    month: "Mar",
    Iphone: 68,
    IphoneWithPercent: "68%",
    Android: 32,
    AndroidWithPercent: "32%",
  },
  {
    month: "Apr",
    Iphone: 61,
    IphoneWithPercent: "61%",
    Android: 39,
    AndroidWithPercent: "39%",
  },
  {
    month: "May",
    Iphone: 18,
    IphoneWithPercent: "18%",
    Android: 82,
    AndroidWithPercent: "82%",
  },
  {
    month: "Jun",
    Iphone: 23,
    IphoneWithPercent: "23%",
    Android: 67,
    AndroidWithPercent: "67%",
  },
];
