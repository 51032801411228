import { Typography } from "@material-ui/core";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import dictionary from "../../../../i18n/en_US/dictionary";
import { cartClear } from "../../../../store/cart/actions";
import { CartState } from "../../../../store/cart/reducer";
import { AppState } from "../../../../store/rootReducer";
import PackageAlert from "../../../Plan/components/PackageAlert";
import { Package } from "../../../Plan/models";
import { GET_PACKAGES_BY_IDS_ARRAY } from "../MiniCartButton/api";
import PaymentConfirmationPopup from "../PaymentConfirmationPopup";
import Props from "./Props";

const MiniCart: FC<Props> = (props: Props) => {
  const venueId = useCurrentVenueId();
  const dispatch = useDispatch();
  const { isOpen, onClose } = props;
  const [packageAlert, setPackageAlert] = useState("");
  const cartItems = useSelector<AppState, CartState>(
    (appState) => appState.cart
  );
  const currentCartItems = useMemo(
    () => cartItems[venueId] || [],
    [cartItems, venueId]
  );
  const [{ data: packagesInCart }, getPackagesInCart] = useRestApi<Package[]>(
    GET_PACKAGES_BY_IDS_ARRAY(currentCartItems),
    "GET"
  );

  useEffect(() => {
    getPackagesInCart().catch((e) => console.error(e));
  }, [getPackagesInCart]);

  const handlePurchaseSucceed = () => {
    dispatch(cartClear());
  };

  const handleHidePackageAlert = () => {
    setPackageAlert("");
  };

  const handleShowPackageAlert = () => {
    setPackageAlert(dictionary.plan.fullAccessPlanConflict);
  };

  return (
    <div>
      <PaymentConfirmationPopup
        onPurchaseSucceed={handlePurchaseSucceed}
        onPurchaseFailed={handleShowPackageAlert}
        open={isOpen}
        onClose={onClose}
        packages={packagesInCart || []}
      />
      <PackageAlert open={!!packageAlert} onClose={handleHidePackageAlert}>
        <Typography align={"center"}>{packageAlert}</Typography>
      </PackageAlert>
    </div>
  );
};

export default MiniCart;
