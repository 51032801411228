import React, { FC, useContext, useEffect } from "react";

import { VenueReportingContext } from "../../../common/components/VenueReportingContextProvider";
import { AnalyticsPeriod } from "../../../common/models";
import VenueViews from "../../Reporting/components/ReportingGeneralTab/components/VenueViews";

interface Props {
  period: AnalyticsPeriod;
}

const VenueViewsContainer: FC<Props> = ({ period }: Props) => {
  const { setPeriod } = useContext(VenueReportingContext);
  useEffect(() => {
    setPeriod(period);
  }, [period, setPeriod]);

  return <VenueViews />;
};

export default VenueViewsContainer;
