import { parsePhoneNumberFromString, PhoneNumber } from "libphonenumber-js";

import { VALID_COUNTRY_CODES } from "../config";

export const isPhoneNumberAllowed = async (phoneNumber: string) => {
  phoneNumber = phoneNumber.startsWith("+") ? phoneNumber : "+" + phoneNumber;
  const validCountryCodes = VALID_COUNTRY_CODES;

  const checkedPhone: PhoneNumber | undefined =
    parsePhoneNumberFromString(phoneNumber);

  return checkedPhone && checkedPhone.isPossible()
    ? checkedPhone.country
      ? validCountryCodes.split(",").includes(checkedPhone.country)
      : false
    : false;
};

export const formatMobileNumber = (phoneNumber: string) => {
  const replaceAll = (src: string, search: string, replace: string) => {
    return src.split(search).join(replace);
  };

  phoneNumber = replaceAll(phoneNumber.trim(), "-", "");

  if (phoneNumber.length > 3 && phoneNumber.length <= 6) {
    phoneNumber = phoneNumber.slice(0, 3) + "-" + phoneNumber.slice(3);
  } else if (phoneNumber.length > 6) {
    phoneNumber =
      phoneNumber.slice(0, 3) +
      "-" +
      phoneNumber.slice(3, 6) +
      "-" +
      phoneNumber.slice(6);
  }

  return phoneNumber;
};

export const formatMobileNumberWithoutSymbols = (phoneNumber?: string) => {
  return `+${phoneNumber?.replace(/\D/g, "")}`;
};

export const checkCodeIsValid = (code: string, length = 6): boolean => {
  if (code) {
    const match = code.match(/\d/g);
    if (!match) {
      return false;
    }
    return match.length === length;
  }

  return false;
};
