import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "content"
  | "title"
  | "actionsToolbarConnected"
  | "connectedLabel";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        minWidth: 246,
        "&:not(:first-of-type)": {
          marginLeft: 20,
        },
      },
      content: {
        marginTop: theme.spacing(4),
      },
      title: {
        marginBottom: theme.spacing(5),
      },
      actionsToolbarConnected: {
        display: "flex",
        alignItems: "center",
      },
      connectedLabel: {
        marginLeft: theme.spacing(3),
      },
    })
);
