import { Avatar, Typography } from "@material-ui/core";
import React, { FC } from "react";

import CounterIcon from "../../../../../../common/components/CounterIcon";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { formatDateForMessages } from "../../../../../../utils/dateTimeUtils";
import Props from "./Props";
import { useStyles } from "./styles";

const DialogItemTab: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { data } = props;
  const { name, messages, avatar } = data;
  const lastChatMessage = messages.length
    ? messages[messages.length - 1]
    : undefined;
  const unreadMessagesQty = messages.filter(
    (message) => !message.isYours && !message.isReadByYou
  ).length;

  return (
    <div className={classes.root}>
      <div className={classes.avatar}>
        <Avatar
          alt="Avatar"
          src={avatar || "./images/avatars/messages-profile-default.jpg"}
        />
      </div>
      <div className={classes.textWrapper}>
        <Typography
          variant="h5"
          color="textPrimary"
          className={classes.nameWrapper}>
          {name}
        </Typography>
        {lastChatMessage && (
          <>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.deliveredTime}>
              {formatDateForMessages(lastChatMessage.timeSent)}
            </Typography>
            <Typography variant="body1" className={classes.latestMessageText}>
              {lastChatMessage.isYours ? dictionary.messages.youPrefix : ""}
              {lastChatMessage.text}
            </Typography>
          </>
        )}
        {unreadMessagesQty > 0 && <CounterIcon items={unreadMessagesQty} />}
      </div>
    </div>
  );
};

export default DialogItemTab;
