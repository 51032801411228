import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames = "root" | "select" | "selectWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginLeft: "auto",
        minWidth: 200,
      },
      select: {
        paddingTop: 14,
        paddingBottom: 14,
      },
      selectWrapper: {},
    })
);
