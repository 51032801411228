import { Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import React, {
  FC,
  FocusEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";

import dictionary from "../../../../../../../../../../../i18n/en_US/dictionary";
import { PromotionFormDialogContext } from "../../../../../../PromotionFormDialogContextProvider";
import { PromotionKind } from "../../../../../PromotionFormDialogActions/PromotionKind.enum";
import { useFormHandlers } from "../../hooks/useFormHandlers";
import { usePriceUserWillPay } from "../../hooks/usePriceUserWillPay";
import { useStyles } from "../../styles";

interface Props {
  isEntireOrder: boolean;
  isSingleItem: boolean;
}

export const DealPriceInput: FC<Props> = ({ isEntireOrder, isSingleItem }) => {
  const classes = useStyles();
  const { mainForm } = useContext(PromotionFormDialogContext);
  const { form, setSchemaRules } = mainForm;
  const errorHasFocus = useRef<boolean>(false);
  const focusOnError = useRef<HTMLInputElement>(null);
  const {
    clearValueFieldOnClickIfZero,
    handleChangeFormField,
    onBlurPriceField,
  } = useFormHandlers();
  const {
    priceUserWillPay,
    onChangePriceUserWillPay,
    onFocusPriceUserWillPay,
    onBlurPriceUserWillPay: onBlur,
  } = usePriceUserWillPay();

  useEffect(() => {
    setSchemaRules({
      limitPrice:
        form.values.kind === PromotionKind.GIVEAWAY
          ? {}
          : {
              type: "number+",
              required: true,
              isIntegerValueBigger: {
                fieldNameInForm: "rebatedPrice",
                fieldInputLabel:
                  dictionary.deals.createDealForm.priceUserWillPay,
              },
              fieldName: dictionary.deals.createDealForm.dealValue,
            },
      rebatedPrice:
        form.values.kind === PromotionKind.GIVEAWAY
          ? {}
          : {
              type: "number+",
              required: true,
              fieldName: dictionary.deals.createDealForm.priceUserWillPay,
              maxNumberValue: 200,
            },
    });
  }, [form.values.kind, mainForm, setSchemaRules]);

  useEffect(() => {
    if (form.values.kind === PromotionKind.GIVEAWAY) {
      mainForm.setAndValidate("limitPercent", "100");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.kind]);

  const onBlurPriceUserWillPay = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onBlur();
      onBlurPriceField("rebatedPrice")(e);
    },
    [onBlurPriceField, onBlur]
  );

  let percentToDisplay = parseFloat(form.values.limitPercent).toFixed(0);
  if (percentToDisplay === "NaN") {
    percentToDisplay = "";
  }

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={dictionary.deals.createDealForm.dealValue}
          InputLabelProps={{ style: { pointerEvents: "auto" } }}
          name="limitPrice"
          error={!!form.errors.limitPrice}
          helperText={form.errors.limitPrice}
          className={classes.promotionAmountMarginTop}
          onChange={handleChangeFormField}
          onClick={clearValueFieldOnClickIfZero}
          onBlur={onBlurPriceField("limitPrice")}
          type="text"
          value={form.values.limitPrice}
          variant="outlined"
          InputProps={{
            startAdornment: isEntireOrder ? (
              <InputAdornment position="start">
                <Typography className={classes.usdSign}>$</Typography>
              </InputAdornment>
            ) : null,
          }}
          inputRef={
            errorHasFocus.current && form.errors.limitPrice
              ? focusOnError
              : null
          }
        />
      </Grid>
      {!isEntireOrder && (
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <TextField
            fullWidth
            label={dictionary.deals.createDealForm.percent}
            name="limitPercent"
            disabled={form.values.kind === PromotionKind.GIVEAWAY}
            error={!!form.errors.limitPercent}
            helperText={form.errors.limitPercent}
            className={classes.promotionAmountMarginTop}
            onChange={handleChangeFormField}
            onClick={(e: MouseEvent) => {
              clearValueFieldOnClickIfZero(e);
              if (form.errors.limitPercent !== "") {
                (e.target as HTMLInputElement).value = "";
              }
            }}
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              if (e.target.value === "") {
                e.target.value = "0";
                mainForm.setAndValidate("limitPercent", "0");
              }
            }}
            type="text"
            value={
              form.values.kind === PromotionKind.GIVEAWAY
                ? 100
                : percentToDisplay
            }
            variant="outlined"
            inputRef={
              errorHasFocus.current && form.errors.limitPercent
                ? focusOnError
                : null
            }
          />
        </Grid>
      )}
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          fullWidth
          label={dictionary.deals.createDealForm.priceUserWillPayWithTaxes}
          name="rebatedPrice"
          className={classes.promotionAmountMarginTop}
          error={!!form.errors.rebatedPrice}
          helperText={form.errors.rebatedPrice}
          onChange={onChangePriceUserWillPay}
          onClick={clearValueFieldOnClickIfZero}
          onFocus={onFocusPriceUserWillPay}
          onBlur={onBlurPriceUserWillPay}
          type="text"
          disabled={form.values.kind === PromotionKind.GIVEAWAY}
          value={
            form.values.kind === PromotionKind.GIVEAWAY ? 0 : priceUserWillPay
          }
          variant="outlined"
          inputRef={
            errorHasFocus.current && form.errors.limitPercent
              ? focusOnError
              : null
          }
        />
      </Grid>
    </>
  );
};
