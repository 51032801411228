import React, { FC } from "react";

import PageTitle from "../../common/components/PageTitle";
import dictionary from "../../i18n/en_US/dictionary";
import PlanTabs from "./components/PlanTabs";

const Plan: FC = () => {
  return (
    <div>
      <PageTitle>{dictionary.plan.pageTitle}</PageTitle>
      <PlanTabs />
    </div>
  );
};

export default Plan;
