import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "rootEmpty"
  | "rootRow"
  | "rowItem"
  | "rowItemFirst"
  | "rowItemLast"
  | "rowItem10"
  | "rowItem15"
  | "rowItem25"
  | "gridContainer"
  | "gridContainerHeader"
  | "rowItemCenter"
  | "headerRowItemText"
  | "iconWrapper"
  | "emptyStateTitle"
  | "emptyStateMsg"
  | "text"
  | "textAddress"
  | "textBold"
  | "textCommentDescription"
  | "actionsWrapper"
  | "dateTextColor"
  | "acceptButton"
  | "approveButton"
  | "activePhotosContainer"
  | "activeUserLabel"
  | "selectedContainer"
  | "photoRequestLabel"
  | "selectCategoryLabel"
  | "selectedCategoryBackground"
  | "selectedCategoryIconColor"
  | "indeterminateCheckColor";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      gridContainer: {
        [theme.breakpoints.down("lg")]: {
          minWidth: 900,
        },
      },
      gridContainerHeader: {
        padding: 0,
      },
      root: {
        marginBottom: theme.spacing(3),
        backgroundColor: "white",
        padding: "12px 24px 12px 24px",
        maxWidth: "100%",
        boxSizing: "border-box",
        overflowX: "auto",
        [theme.breakpoints.down("sm")]: {
          maxWidth: 500,
        },
        [theme.breakpoints.up("sm")]: {
          maxWidth: "100%",
        },
      },
      rootRow: {
        padding: "8px 0px 8px 0px",
      },
      rootEmpty: {
        flex: 1,
        flexDirection: "column",
        padding: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      emptyStateTitle: {
        marginBottom: theme.spacing(1),
        fontWeight: "bold",
        textAlign: "center",
      },
      emptyStateMsg: {
        textAlign: "center",
      },
      headerRowItemText: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
      },
      iconWrapper: {
        display: "flex",
        alignItems: "center",
        marginLeft: 6,
      },
      rowItem: {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        flexWrap: "wrap",
        padding: theme.spacing(1, 1),
      },
      rowItemFirst: {
        paddingLeft: theme.spacing(5),
        maxWidth: "5%",
      },
      rowItemCenter: {
        display: "flex",
        justifyContent: "center",
      },
      rowItem10: {
        flexGrow: 0,
        maxWidth: "10%",
        flexBasis: "10%",
      },
      rowItem15: {
        flexGrow: 0,
        maxWidth: "15%",
        flexBasis: "15%",
      },
      rowItem25: {
        flexGrow: 0,
        maxWidth: "25%",
        flexBasis: "25%",
      },
      rowItemLast: {
        padding: theme.spacing(1, 5, 1, 1),
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down("md")]: {
          justifyContent: "flex-start",
        },
      },
      text: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
      },
      textAddress: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 400,
        color: colors.grey.additional,
        marginTop: 5,
      },
      textBold: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 700,
      },
      textCommentDescription: {
        maxWidth: "80%",
      },
      actionsWrapper: {
        display: "flex",
        flexDirection: "row",
      },
      acceptButton: {
        minWidth: 72,
        fontSize: 15,
        minHeight: 38,
        maxHeight: 38,
        "&.Mui-disabled": {
          opacity: 0.2,
          color: colors.white.primary,
          backgroundColor: colors.red.primary,
        },
      },
      approveButton: {
        marginRight: 16,
        "&.Mui-disabled": {
          opacity: 0.2,
          color: colors.white.primary,
          backgroundColor: colors.black.primary,
        },
      },
      activePhotosContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(3),
      },
      selectedContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: theme.spacing(3),
      },
      activeUserLabel: {
        fontWeight: 600,
        color: colors.grey.secondary,
      },
      dateTextColor: {
        color: colors.grey.additional,
      },
      photoRequestLabel: {
        fontWeight: "bold",
      },
      selectCategoryLabel: {
        fontSize: 15,
        fontWeight: "bold",
        lineHeight: "20px",
        paddingTop: 9,
        paddingBottom: 9,
      },
      selectedCategoryBackground: {
        backgroundColor: `${colors.grey.primary} !important`,
        color: colors.white.primary,
      },
      selectedCategoryIconColor: {
        color: colors.white.primary,
      },
      indeterminateCheckColor: {
        color: colors.red.primary,
      },
    })
);
