import { DialogActions, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useContext, useEffect, useState } from "react";

import { AlertContext } from "../../../../../../../../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../../../../../../../../common/components/ButtonBlack";
import ButtonCancel from "../../../../../../../../../../../common/components/ButtonCancel";
import { useCurrentVenueId } from "../../../../../../../../../../../common/hooks/useCurrentVenueId";
import dictionary from "../../../../../../../../../../../i18n/en_US/dictionary";
import api from "../../../../../../../../../api";
import DtoHappyHourRequest from "../../../../../../../../../dtos/HappyHourRequest.dto";
import { DialogContext } from "../../DialogContextProvider";
import Props from "./Props";
import { useStyles } from "./styles";

const Actions: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const {
    mainForm,
    resetForm,
    filesQueue,
    isSaveDisabled,
    setIsSaveDisabled,
    mainForm: {
      form: { isValid: mainFormIsValid },
    },
  } = useContext(DialogContext);
  const { closeDialog } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const venueId = useCurrentVenueId();

  const closeAndReset = () => {
    closeDialog();
    resetForm();
  };

  const sendCreateRequest = (): void => {
    setLoading(true);
    const { menuUrl, description } = mainForm.form.values;
    const files = filesQueue.filter((fileQueue) => fileQueue?.generatedName);
    const builtHappyHourRequest: DtoHappyHourRequest =
      DtoHappyHourRequest.build({
        menuUrl,
        description,
        files,
        venueId,
      });

    api.happyHours
      .createRequest(builtHappyHourRequest)
      .then(() => {
        closeDialog(true);
        resetForm();
      })
      .catch((error) => {
        switch (error.response.data) {
          case dictionary.deals.happyHours.errors.venueDoesntExist: {
            showAlert(
              dictionary.deals.happyHours.errors.venueDoesntExist,
              "error"
            );
            break;
          }
          case dictionary.deals.happyHours.errors.atLeastOneDeal: {
            showAlert(
              dictionary.deals.happyHours.errors.atLeastOneDeal,
              "error"
            );
            break;
          }
          case dictionary.deals.happyHours.errors.atLeastOneFile: {
            showAlert(
              dictionary.deals.happyHours.errors.atLeastOneFile,
              "error"
            );
            break;
          }
          case dictionary.deals.happyHours.errors.venueAlreadyUsed: {
            showAlert(
              dictionary.deals.happyHours.errors.venueAlreadyUsed,
              "error"
            );
            break;
          }
          case dictionary.deals.happyHours.errors.venueIsNotClaimed: {
            showAlert(
              dictionary.deals.happyHours.errors.venueIsNotClaimed,
              "error"
            );
            break;
          }
          default: {
            showAlert(
              dictionary.deals.createDealForm.msgErrorCreation,
              "error"
            );
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const handlePressPrimaryButton = () => {
    if (filesQueue.length <= 0 && !mainForm.form.values.menuUrl) {
      return showAlert(
        <div>
          <Typography style={{ color: "red" }}>
            There Should be at least one file or menu url!
          </Typography>
        </div>,
        "error"
      );
    }
    if (
      mainForm.validateForm() &&
      (filesQueue.length > 0 || mainForm.form.values.menuUrl)
    ) {
      sendCreateRequest();
    } else {
      showAlert(
        <div>
          {Object.values(mainForm.form.errors).map((error, key) => (
            <Typography key={key} style={{ color: "red" }}>
              {error}
            </Typography>
          ))}
        </div>,
        "error"
      );

      setIsSaveDisabled(true);
    }
  };

  useEffect(() => {
    if (mainFormIsValid) {
      setIsSaveDisabled(false);
    }
  }, [mainFormIsValid, setIsSaveDisabled]);

  return (
    <DialogActions
      classes={{
        root: clsx(classes.dialogActions),
      }}>
      <div className={classes.actionFlexItem}>
        <ButtonCancel onClick={closeAndReset}>
          {dictionary.general.cancel}
        </ButtonCancel>
        <ButtonBlack
          disabled={loading || isSaveDisabled}
          onClick={handlePressPrimaryButton}
          className={classes.dialogPrimaryButtonAction}>
          Send Request
        </ButtonBlack>
      </div>
    </DialogActions>
  );
};

export default Actions;
