import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "textField"
  | "titleWrapper"
  | "main"
  | "saveButton"
  | "uploadBtn";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        flexGrow: 1,
        marginTop: 50,
        marginBottom: theme.spacing(6),
      },
      textField: {},
      titleWrapper: {
        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(3.8),
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: theme.spacing(1.8),
        },
      },
      main: {
        // backgroundColor: theme.palette.background.default
        backgroundColor: "#fff",
      },
      uploadBtn: {
        textTransform: "none",
        width: 154,
        height: 44,
      },
      saveButton: {
        marginTop: theme.spacing(4),
      },
    })
);
