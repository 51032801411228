import { VenueRequestType } from "../../models";

export interface VenueRequest {
  id: number;
  venueId: number;
  venueName: string;
  date: Date;
  type: VenueRequestType;
}

export const GET_VENUES_REQUESTS = (type: VenueRequestType) =>
  // 3 - it is results qty limit.
  // Hardcoded, because no need to change it, but API allows to do it
  `/venue/requests/${type}/3`;
