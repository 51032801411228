import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";
import { colors } from "../../theme/colors";

export type VenuesClassNames =
  | "root"
  | "contentWrapper"
  | "pageNotion"
  | "trademarkContainer"
  | "topSection"
  | "trademark"
  | "trademarkWrapper"
  | "searchAndCreateWrapper"
  | "searchField"
  | "createNewVenueButton";

export const useStyles: () => ClassNameMap<VenuesClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        padding: theme.spacing(5),
        display: "flex",
        flexDirection: "column",
        flex: 1,
      },
      contentWrapper: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(5),
        position: "relative",
        flex: 1,
        display: "flex",
        flexDirection: "column",
      },
      trademark: {
        height: 70,
        width: 180,
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          height: "auto",
        },
      },
      topSection: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      trademarkContainer: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
          marginTop: theme.spacing(1),
        },
      },
      trademarkWrapper: {
        border: `solid 1px ${colors.grey.medium}`,
        borderRadius: theme.spacing(1 / 2),
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
      },
      pageNotion: {
        color: colors.grey.additional,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        marginBottom: theme.spacing(2),
      },
      searchAndCreateWrapper: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      searchField: {
        width: "50%",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      createNewVenueButton: {
        marginTop: 8,
        marginLeft: 8,
      },
    })
);
