import { Form } from "../../store/forms/types";
import { CoreConfigData } from "./models";

export interface CoreSettingsFormSchema {
  [key: string]: Record<string, any>;
}

export const createSchema = (
  configs: CoreConfigData[]
): CoreSettingsFormSchema => {
  const scheme: Record<string, any> = {};

  configs.forEach((config: CoreConfigData) => {
    scheme[config.path] = config.validation;
  });

  return scheme;
};

export interface CoreSettingsForm extends Form {
  errors: {
    [key: string]: string;
  };
  values: {
    [key: string]: string;
  };
}
