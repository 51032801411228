import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";
export type ClassNames =
  | "root"
  | "headerTitle"
  | "dialogContent"
  | "dialogActions"
  | "helperTxt"
  | "phoneNumberWrapper"
  | "btn"
  | "phoneNumberField";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      headerTitle: {
        textAlign: "center",
        marginTop: 20,
      },
      dialogContent: {
        padding: 60,
        minWidth: 400,
        borderBottom: 0,
      },
      dialogActions: { padding: theme.spacing(3) },
      phoneNumberWrapper: {
        marginTop: 45,
        minWidth: 370,
      },
      helperTxt: {
        color: colors.grey.helpText,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
      },
      btn: {
        textTransform: "none",
      },
      phoneNumberField: {
        marginTop: theme.spacing(3),
      },
    })
);
