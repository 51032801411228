import { Button, ButtonProps } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { useStyles } from "./styles";

const ButtonBlack: FC<ButtonProps> = (props) => {
  const { children, disabled, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.root, className)}
      disabled={disabled}
      {...rest}>
      {children}
    </Button>
  );
};

export default ButtonBlack;
