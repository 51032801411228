import {
  CardActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { usePagination } from "../../../../common/hooks";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { VenueAddRequest } from "../../../../common/models";
import { ReactComponent as ViewIcon } from "../../../../common/svg/icons/view.svg";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { useStyles } from "./styles";

const VenuesPendingTable: FC = () => {
  const [{ data }, getPendingVenuesList] = useRestApi<{
    list: VenueAddRequest[];
  }>("/venue/venues-pending", "GET");
  const history = useHistory();
  const classes = useStyles();
  const venues = data?.list || [];
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pagination, dispatchPagination, paginationTypes] =
    usePagination(false);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    currentPage: number
  ) => {
    dispatchPagination({ type: paginationTypes.SET_PAGE, currentPage });
  };

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    getPendingVenuesList().catch((e) => console.error(e));
  }, [getPendingVenuesList]);

  const handlePressViewIcon = (requestId: number) => {
    history.push(Route.VenuePending.replace(":id", requestId.toString()));
  };

  const getItemAddress = ({
    city,
    country,
    address,
    state,
  }: VenueAddRequest) => {
    return address + ", " + city + ", " + state + ", " + country;
  };

  return (
    <div>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={"body2"} className={classes.heading}>
                  {dictionary.venuesPending.table.headingName}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={"body2"} className={classes.heading}>
                  {dictionary.venuesPending.table.headingAddress}
                </Typography>
              </TableCell>
              <TableCell align={"center"}>
                <Typography variant={"body2"} className={classes.heading}>
                  {dictionary.venuesPending.table.headingView}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tbody}>
            {venues
              .slice(
                pagination.currentPage * rowsPerPage,
                pagination.currentPage * rowsPerPage + rowsPerPage
              )
              .map((item, index) => (
                <TableRow hover key={index}>
                  <TableCell className={clsx(classes.td, classes.td45)}>
                    <Typography className={classes.venueName} variant="body1">
                      {item.venueName}
                    </Typography>
                  </TableCell>
                  <TableCell className={clsx(classes.td, classes.td50)}>
                    <div>
                      <Typography className={classes.address} variant="body1">
                        {getItemAddress(item)}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    className={clsx(classes.td, classes.td5)}
                    align={"center"}>
                    <IconButton
                      aria-label="add"
                      onClick={() => handlePressViewIcon(item.id)}>
                      <ViewIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <CardActions>
        <TablePagination
          component="div"
          count={venues.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={pagination.currentPage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </CardActions>
    </div>
  );
};

export default VenuesPendingTable;
