import { DialogClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const MuiDialog: Partial<Record<DialogClassKey, CSSProperties>> = {
  paperWidthSm: {
    maxWidth: 900,
  },
};

export default MuiDialog;
