import IVenueAdminProfile from "../models/IVenueAdminProfile";
import {
  GET_VENUE_ADMIN_PROFILE_FULFILLED,
  GET_VENUE_ADMIN_PROFILE_PENDING,
  GET_VENUE_ADMIN_PROFILE_REJECTED,
  SAVE_VENUE_ADMIN_PROFILE_FULFILLED,
  SAVE_VENUE_ADMIN_PROFILE_PENDING,
  SAVE_VENUE_ADMIN_PROFILE_REJECTED,
  VenueAdminProfileActionTypes,
} from "./types";

interface IVenueAdminProfileState {
  venueAdminProfile?: IVenueAdminProfile;
  error?: string;
  loading: boolean;
  saved: boolean;
}

const defaultState: IVenueAdminProfileState = {
  venueAdminProfile: undefined,
  error: undefined,
  loading: false,
  saved: false,
};

export default (
  state: IVenueAdminProfileState = defaultState,
  action: VenueAdminProfileActionTypes
): IVenueAdminProfileState => {
  switch (action.type) {
    case GET_VENUE_ADMIN_PROFILE_PENDING:
      return {
        ...state,
        loading: true,
        saved: false,
      };
    case GET_VENUE_ADMIN_PROFILE_FULFILLED:
      return {
        ...state,
        venueAdminProfile: action.venueAdminProfile,
        loading: false,
        saved: false,
      };
    case GET_VENUE_ADMIN_PROFILE_REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
        saved: false,
      };
    case SAVE_VENUE_ADMIN_PROFILE_PENDING:
      return {
        ...state,
        loading: true,
        saved: false,
      };
    case SAVE_VENUE_ADMIN_PROFILE_FULFILLED:
      return {
        ...state,

        loading: false,
        saved: true,
      };
    case SAVE_VENUE_ADMIN_PROFILE_REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
        saved: false,
      };
    default:
      return state;
  }
};
