import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../common/models";

export type ClassNames =
  | "cardsWrapper"
  | "createRequest"
  | "createRequestTablet"
  | "renderButton"
  | "renderButtonIcon"
  | "renderNoHappyHour"
  | "renderNoHappyHourDesktop"
  | "renderNoHappyHourImage"
  | "renderDayAndHourListItem"
  | "renderDayAndHourListItemText"
  | "renderFileIconFile"
  | "renderFile"
  | "renderFileOriginalName"
  | "renderFileSizeBytes"
  | "renderHappyHourPaper"
  | "renderHappyHourTextActiveDayAndHours"
  | "renderHappyHourList"
  | "renderHappyHourTextYourFiles"
  | "renderHappyHourGrid"
  | "renderNoHappyHourTextHappyHours"
  | "renderNoHappyHourTextHappyHoursDescription"
  | "renderFileContent"
  | "hideOnTablet"
  | "hideOnDesktop"
  | "tabletPaddingTop0"
  | "renderNoHappyHourImageContainer";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      renderHappyHourPaper: {
        height: "100%",
        maxWidth: "100%",
        padding: 30,
      },
      createRequest: {
        alignSelf: "self-end",
        [theme.breakpoints.down("sm")]: {
          float: "right",
        },
      },
      cardsWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",

        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      renderButton: { marginTop: 24 },
      renderButtonIcon: { width: 14, height: 16, marginRight: 11 },
      renderNoHappyHour: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
      },
      renderNoHappyHourDesktop: { padding: "48px 56px" },
      renderNoHappyHourImage: { maxWidth: 400, position: "absolute", top: 0 },
      renderDayAndHourListItem: {
        background: "#F6F7F8",
        borderRadius: 4,
        marginBottom: 8,
      },
      renderDayAndHourListItemText: { flex: 3 },
      renderFileIconFile: { width: 23, height: 28 },
      renderFile: {
        background: "#FFFFFF",
        border: "1px solid #E5E7EA",
        borderRadius: 7,
        padding: 16,
        display: "flex",
        alignItems: "center",
        marginTop: 16,
        "&:hover": {
          cursor: "pointer",
        },
      },
      renderFileOriginalName: {
        fontWeight: 700,
        fontSize: 14,
        color: "#464854",
      },
      renderFileSizeBytes: { fontWeight: 600, fontSize: 12, color: "#747982" },
      renderHappyHourTextActiveDayAndHours: {
        fontWeight: 700,
        fontSize: 14,
        color: "#37393F",
      },
      renderHappyHourList: { padding: 0, marginTop: 16 },
      renderHappyHourTextYourFiles: {
        fontWeight: 700,
        fontSize: 14,
        color: "#37393F",
        marginTop: 24,
      },
      renderHappyHourGrid: { display: "flex", flexDirection: "column" },
      renderFileContent: { marginLeft: 16 },
      renderNoHappyHourTextHappyHours: {
        fontWeight: 700,
        fontSize: 24,
        color: "#000000",
      },
      renderNoHappyHourTextHappyHoursDescription: {
        fontWeight: 600,
        fontSize: 16,
        color: "#747982",
        marginTop: 8,
      },
      hideOnDesktop: {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      hideOnTablet: {
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      },
      createRequestTablet: {
        marginBottom: 16,
      },
      tabletPaddingTop0: {
        [theme.breakpoints.down("sm")]: {
          paddingTop: "0px !important",
        },
      },
      renderNoHappyHourImageContainer: { minWidth: 400, maxWidth: 400 },
    })
);
