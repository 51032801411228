import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { getRouteWithSlash, Route as AppRoute } from "../../../config/router";
import { AppState } from "../../../store/rootReducer";
import Props from "./Props";

const PrivateRoute: FC<Props> = (routeProps: Props) => {
  const { layout, component, ...rest } = routeProps;
  const auth = useSelector((state: AppState) => state.auth);

  const Layout = layout;
  const Component: any = component; // TODO: replace "any"

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.hasToken) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: getRouteWithSlash(AppRoute.Login),
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default PrivateRoute;
