import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import React, { FC, useEffect } from "react";

import ButtonBlack from "../../../../common/components/ButtonBlack";
import ButtonCancel from "../../../../common/components/ButtonCancel";
import OcietyDialogTitle from "../../../../common/components/dialogs/OcietyDIalogTitle";
import StateAutocompleteSearch from "../../../../common/components/forms/StateAutocompleteSearch";
import MultipleSelect from "../../../../common/components/MultipleSelect";
import Option from "../../../../common/components/MultipleSelect/Option";
import OcietyBlockWrapperForRadioButton from "../../../../common/components/OcietyBlockWrapperForRadioButton";
import OptionalFieldLabel from "../../../../common/components/OptionalFieldLabel";
import PhoneNumberInput from "../../../../common/components/PhoneNumberInput";
import WorkingDaysPicker from "../../../../common/components/WorkingDaysPicker";
import { VenueType } from "../../../../common/models";
import dictionary from "../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../utils/dateTimeUtils";
import { SubTypes } from "../../models";
import Props from "./Props";
import { useStyles } from "./styles";
import { useAddNewVenueDialog } from "./useAddNewVenueDialog";

const AddNewVenueDialog: FC<Props> = (props: Props) => {
  const { onCloseVenueDialog, isVenueDialogOpen, editVenueId } = props;
  const classes = useStyles();
  const {
    venueData,
    loadingUpdatingVenueData,
    loadingAddingNewVenue,
    addNewVenueForm,
    types,
    barTypesSelected,
    restaurantTypesSelected,
    uploadEditVenueInfo,
    getAndSetVenuesTypes,
    handleCreateVenue,
    resetForm,
    handleChangeTypes,
    handleChangePrice,
    handleChangeState,
    handleChangePhoneNumber,
    handleChangeFormField,
  } = useAddNewVenueDialog({ onCloseVenueDialog, editVenueId });

  useEffect(() => {
    uploadEditVenueInfo();
  }, [uploadEditVenueInfo]);

  useEffect(() => {
    getAndSetVenuesTypes();
  }, [getAndSetVenuesTypes]);

  return (
    <Dialog
      open={isVenueDialogOpen}
      onClose={onCloseVenueDialog}
      maxWidth={"lg"}
      disableBackdropClick
      aria-labelledby="add-new-venue-dialog">
      <OcietyDialogTitle
        id={"add-new-venue-dialog-title"}
        data-testid={"add-new-venue-dialog-title"}
        onClose={onCloseVenueDialog}>
        {editVenueId
          ? dictionary.dataEntryVenues.editNewVenueDialogTitle
          : dictionary.dataEntryVenues.addNewVenueDialogTitle}
      </OcietyDialogTitle>
      <Divider />
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={
                    dictionary.dataEntryVenues.addNewVenueDialogFormFields.name
                  }
                  name="name"
                  error={!!addNewVenueForm.errors.name}
                  helperText={addNewVenueForm.errors.name}
                  onChange={handleChangeFormField}
                  type="text"
                  value={addNewVenueForm.values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={9} classes={{ root: classes.gridLeftSm6 }}>
                <TextField
                  fullWidth
                  label={
                    dictionary.dataEntryVenues.addNewVenueDialogFormFields
                      .address
                  }
                  name="address"
                  error={!!addNewVenueForm.errors.address}
                  helperText={addNewVenueForm.errors.address}
                  onChange={handleChangeFormField}
                  type="text"
                  value={addNewVenueForm.values.address}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                classes={{ root: classes.gridRightSm6 }}>
                <TextField
                  fullWidth
                  label={
                    dictionary.dataEntryVenues.addNewVenueDialogFormFields
                      .postcode
                  }
                  name="postcode"
                  error={!!addNewVenueForm.errors.postcode}
                  helperText={addNewVenueForm.errors.postcode}
                  onChange={handleChangeFormField}
                  value={addNewVenueForm.values.postcode}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                spacing={2}
                classes={{ root: classes.gridLeftSm6 }}>
                <TextField
                  fullWidth
                  label={
                    dictionary.dataEntryVenues.addNewVenueDialogFormFields.city
                  }
                  name="city"
                  error={!!addNewVenueForm.errors.city}
                  helperText={addNewVenueForm.errors.city}
                  onChange={handleChangeFormField}
                  type="text"
                  value={addNewVenueForm.values.city}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                classes={{ root: classes.gridRightSm6 }}>
                <StateAutocompleteSearch
                  state={addNewVenueForm.values.state}
                  country={"USA"}
                  error={addNewVenueForm.errors.state}
                  onChange={handleChangeState}
                  label={
                    dictionary.dataEntryVenues.addNewVenueDialogFormFields.state
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} classes={{ root: classes.gridLeftSm6 }}>
                <TextField
                  fullWidth
                  label={
                    <OptionalFieldLabel
                      label={
                        dictionary.dataEntryVenues.addNewVenueDialogFormFields
                          .email
                      }
                    />
                  }
                  name="email"
                  error={!!addNewVenueForm.errors.email}
                  helperText={addNewVenueForm.errors.email}
                  onChange={handleChangeFormField}
                  type="text"
                  value={addNewVenueForm.values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                classes={{ root: classes.gridRightSm6 }}>
                <PhoneNumberInput
                  value={addNewVenueForm.values.phone}
                  onChange={handleChangePhoneNumber}
                  required={false}
                  error={addNewVenueForm.errors.phone}
                  label={
                    <OptionalFieldLabel
                      label={
                        dictionary.dataEntryVenues.addNewVenueDialogFormFields
                          .phoneNumber
                      }
                    />
                  }
                  name="phone"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={
                    <OptionalFieldLabel
                      label={
                        dictionary.dataEntryVenues.addNewVenueDialogFormFields
                          .website
                      }
                    />
                  }
                  name="website"
                  error={!!addNewVenueForm.errors.website}
                  helperText={addNewVenueForm.errors.website}
                  onChange={handleChangeFormField}
                  type="text"
                  value={addNewVenueForm.values.website}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"h3"}>
                  {
                    dictionary.dataEntryVenues.addNewVenueDialogFormFields
                      .priceRange
                  }
                </Typography>
                <RadioGroup
                  aria-label="priceRange"
                  name="price"
                  value={addNewVenueForm.values.price || 1}
                  classes={{ root: classes.rangePriceWrapper }}
                  onChange={handleChangePrice}>
                  <OcietyBlockWrapperForRadioButton
                    isActive={addNewVenueForm.values.price === 1}
                    label={"$"}
                    value={1}
                  />
                  <OcietyBlockWrapperForRadioButton
                    isActive={addNewVenueForm.values.price === 2}
                    label={"$$"}
                    value={2}
                  />
                  <OcietyBlockWrapperForRadioButton
                    isActive={addNewVenueForm.values.price === 3}
                    label={"$$$"}
                    value={3}
                  />
                  <OcietyBlockWrapperForRadioButton
                    isActive={addNewVenueForm.values.price === 4}
                    label={"$$$$"}
                    value={4}
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6} classes={{ root: classes.gridLeftSm6 }}>
                <TextField
                  fullWidth
                  label={
                    dictionary.dataEntryVenues.addNewVenueDialogFormFields
                      .longitude
                  }
                  name="longitude"
                  error={!!addNewVenueForm.errors.longitude}
                  helperText={addNewVenueForm.errors.longitude}
                  onChange={handleChangeFormField}
                  value={addNewVenueForm.values.longitude}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                classes={{ root: classes.gridRightSm6 }}>
                <TextField
                  fullWidth
                  label={
                    dictionary.dataEntryVenues.addNewVenueDialogFormFields
                      .latitude
                  }
                  name="latitude"
                  error={!!addNewVenueForm.errors.latitude}
                  helperText={addNewVenueForm.errors.latitude}
                  onChange={handleChangeFormField}
                  value={addNewVenueForm.values.latitude}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <MultipleSelect
                  error={addNewVenueForm.errors.subtypes}
                  onSelected={(value) => handleChangeTypes(value, SubTypes.bar)}
                  options={types.bar.map(
                    (type: VenueType) =>
                      new Option(String(type.id), type.subtype)
                  )}
                  selected={barTypesSelected}
                  type="bar"
                  helperText={addNewVenueForm.errors.subtypes}
                  label={
                    dictionary.dataEntryVenues.addNewVenueDialogFormFields
                      .barTypes
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <MultipleSelect
                  error={addNewVenueForm.errors.subtypes}
                  onSelected={(value) =>
                    handleChangeTypes(value, SubTypes.restaurant)
                  }
                  options={types.restaurant.map(
                    (type: VenueType) =>
                      new Option(String(type.id), type.subtype)
                  )}
                  selected={restaurantTypesSelected}
                  type="restaurant"
                  helperText={addNewVenueForm.errors.subtypes}
                  label={
                    dictionary.dataEntryVenues.addNewVenueDialogFormFields
                      .restaurantTypes
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <WorkingDaysPicker />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {venueData && venueData.history && (
          <>
            <Typography>
              {dictionary.dataEntryVenues.venueId(editVenueId)}
            </Typography>
            <Typography>
              {dictionary.dataEntryVenues.createdDate(
                getFormattedDate(new Date(venueData.history.venueUpdateDate))
              )}
            </Typography>
            <Typography>
              {dictionary.dataEntryVenues.modifiedBy(
                venueData.history.adminFirstName,
                venueData.history.adminLastName
              )}
            </Typography>
          </>
        )}
        <ButtonCancel onClick={resetForm}>
          {dictionary.dataEntryVenues.actionButtons.cancel}
        </ButtonCancel>
        <ButtonBlack
          className={classes.createButton}
          disabled={loadingAddingNewVenue || loadingUpdatingVenueData}
          onClick={handleCreateVenue}>
          {editVenueId
            ? dictionary.dataEntryVenues.actionButtons.edit
            : dictionary.dataEntryVenues.actionButtons.create}
        </ButtonBlack>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewVenueDialog;
