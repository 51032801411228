import { Dispatch } from "redux";

import { AppState } from "../../../store/rootReducer";
import { getVenueAdminProfile, saveVenueAdminProfile } from "../api";
import IVenueAdminProfile from "../models/IVenueAdminProfile";
import {
  GET_VENUE_ADMIN_PROFILE_FULFILLED,
  GET_VENUE_ADMIN_PROFILE_PENDING,
  GET_VENUE_ADMIN_PROFILE_REJECTED,
  GetVenueAdminProfileFailAction,
  GetVenueAdminProfilePendingAction,
  GetVenueAdminProfileSuccessAction,
  SAVE_VENUE_ADMIN_PROFILE_FULFILLED,
  SAVE_VENUE_ADMIN_PROFILE_PENDING,
  SAVE_VENUE_ADMIN_PROFILE_REJECTED,
  SaveVenueAdminProfileFailAction,
  SaveVenueAdminProfilePendingAction,
  SaveVenueAdminProfileSuccessAction,
  VenueAdminProfileActionTypes,
} from "./types";

const getVenueAdminProfilePendingAction =
  (): GetVenueAdminProfilePendingAction => ({
    type: GET_VENUE_ADMIN_PROFILE_PENDING,
  });

const getVenueAdminProfileSuccessAction = (
  venueAdminProfile: IVenueAdminProfile
): GetVenueAdminProfileSuccessAction => ({
  type: GET_VENUE_ADMIN_PROFILE_FULFILLED,
  venueAdminProfile,
});

const getVenueAdminProfileFailAction = (
  error: string
): GetVenueAdminProfileFailAction => ({
  type: GET_VENUE_ADMIN_PROFILE_REJECTED,
  error,
});

export const getProfile =
  () => async (dispatch: Dispatch<VenueAdminProfileActionTypes>) => {
    dispatch(getVenueAdminProfilePendingAction());

    try {
      const profile: IVenueAdminProfile = await getVenueAdminProfile();
      dispatch(getVenueAdminProfileSuccessAction(profile));
    } catch (e) {
      dispatch(getVenueAdminProfileFailAction(e));
    }
  };

const saveVenueAdminProfilePendingAction =
  (): SaveVenueAdminProfilePendingAction => ({
    type: SAVE_VENUE_ADMIN_PROFILE_PENDING,
  });

const saveVenueAdminProfileSuccessAction =
  (): SaveVenueAdminProfileSuccessAction => ({
    type: SAVE_VENUE_ADMIN_PROFILE_FULFILLED,
  });

const saveVenueAdminProfileFailAction = (
  error: string
): SaveVenueAdminProfileFailAction => ({
  type: SAVE_VENUE_ADMIN_PROFILE_REJECTED,
  error,
});

export const saveProfile =
  (profile: IVenueAdminProfile) =>
  async (
    dispatch: Dispatch<VenueAdminProfileActionTypes>,
    getState: () => AppState
  ) => {
    dispatch(saveVenueAdminProfilePendingAction());

    try {
      await saveVenueAdminProfile(profile);
      dispatch(saveVenueAdminProfileSuccessAction());
    } catch (error) {
      const msg = error.data.details
        ? error.data.details[0].message
        : error.data;
      dispatch(saveVenueAdminProfileFailAction(msg));
    }
  };
