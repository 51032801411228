import moment from "moment-timezone";
import React, { FC, useContext, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartContainer from "../../../../../../common/components/ChartContainer";
import ChartTooltip from "../../../../../../common/components/ChartTooltip";
// eslint-disable-next-line max-len
import { VenueReportingContext } from "../../../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../../../common/hooks/useRequestByPeriod";
import { useVenueHasPackage } from "../../../../../../common/hooks/useVenueHasPacakage";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { PromotionsReporting } from "../../../../../../permissions/Reporting/PromotionsReporting";
import { PackageUID } from "../../../../../../store/currentVenue/types";
import {
  chartAdditionalColor,
  chartPrimaryColor,
  colors,
} from "../../../../../../theme/colors";
import { getBarFillColor } from "../../../../../../utils/chartUtils";
import { havePermission } from "../../../../../../utils/userUtils";
import {
  BarDefaultProps,
  XAxisDefaultProps,
  YAxisDefaultProps,
} from "../../../../defaultProps";
import {
  GET_VENUE_PROMOTION_USAGE_POPULAR_HOURS,
  GetPromotionUsagePopularHoursResponse,
  PromotionUsagePopularHoursData,
} from "./api";
import { chartDataMock } from "./mock";

const PromotionUsagePopularHours: FC = () => {
  const [chartData, setChartData] = useState(chartDataMock);
  const { filters, handlePackageName } = useContext(VenueReportingContext);
  const haveAccess = havePermission(
    PromotionsReporting.PROMOTION_POPULAR_HOURS
  );
  const hasPackage = useVenueHasPackage(PackageUID.PROMOTIONS);
  const { response, loading } =
    useRequestByPeriod<GetPromotionUsagePopularHoursResponse>(
      GET_VENUE_PROMOTION_USAGE_POPULAR_HOURS,
      filters,
      hasPackage
    );

  const formatAndSetStats = (
    stats: PromotionUsagePopularHoursData[],
    timezone: string
  ) => {
    setChartData(
      stats.map(({ hour: hourResponse, usages }) => {
        const hourUTC = moment.utc().startOf("day");
        hourUTC.hours(hourResponse).minutes(0);

        const hourVenueTimezone = moment(hourUTC); // OR  var m2 = m1.clone();   (both do the same thing)
        const hour = hourVenueTimezone.tz(timezone).hour();
        return {
          hour: hour <= 12 ? `${hour} am` : `${hour - 12} pm`,
          usages,
        };
      })
    );
  };

  useEffect(() => {
    if (response) {
      formatAndSetStats(response.stats, response.timezone);
    }
    if (!hasPackage) {
      setChartData(chartDataMock);
    }
  }, [response, hasPackage]);

  const isChartEmpty = !chartData.some((data) => data.usages !== 0);

  return (
    <ChartContainer
      loading={loading}
      locked={!hasPackage}
      isChartEmpty={isChartEmpty}
      title={dictionary.reporting.dealUsagePopularHoursTitle}
      chartType={"bar-with-y-axis"}
      packageName={handlePackageName(PackageUID.PROMOTIONS)}>
      <BarChart data={chartData}>
        <CartesianGrid
          strokeDasharray="3 3"
          strokeOpacity={0.6}
          color={colors.grey.light}
          vertical={false}
        />
        <Bar {...BarDefaultProps} dataKey={"usages"} fill={chartPrimaryColor}>
          {chartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={getBarFillColor(chartData, index, "usages")}
            />
          ))}
        </Bar>
        <XAxis {...XAxisDefaultProps} dataKey="hour" />
        <YAxis {...YAxisDefaultProps} />
        <Tooltip
          content={<ChartTooltip />}
          cursor={{
            stroke: chartAdditionalColor,
            strokeWidth: 2,
            opacity: 0.3,
          }}
        />
      </BarChart>
    </ChartContainer>
  );
};

export default PromotionUsagePopularHours;
