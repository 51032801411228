import { useEffect } from "react";
import { useSelector } from "react-redux";

import { AuthState } from "../../store/auth/reducer";
import { AppState } from "../../store/rootReducer";
import { RoleNameEnum } from "../../views/VenueStaff/main/RoleTypes";
import client from "../../websocket/WebsocketClient";
import { usePrevious } from "../hooks";
import { useCurrentVenueId } from "./useCurrentVenueId";

export function useWebsocket() {
  const venueId = useCurrentVenueId();
  const prevVenueId = usePrevious(venueId);
  const { hasToken } = useSelector<AppState, AuthState>((state) => state.auth);
  const { roles } = useSelector<AppState, AuthState>((state) => state.auth);

  useEffect(() => {
    if (
      hasToken &&
      !roles.includes(RoleNameEnum.superAdmin) &&
      !roles.includes(RoleNameEnum.discount)
    ) {
      if (prevVenueId !== venueId) {
        client.disconnect();
      }

      if (!client.isConnected) {
        client.connect(venueId);
      }
    } else {
      client.disconnect();
    }
  }, [hasToken, prevVenueId, venueId, roles]);
}
