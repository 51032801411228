import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Radio,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React, { FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import ActionsDropdown from "../../../../../../common/components/ActionsDropdown";
import { ActionItemProps } from "../../../../../../common/components/ActionsDropdown/Props";
import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import ButtonCancel from "../../../../../../common/components/ButtonCancel";
import ButtonPrimary from "../../../../../../common/components/ButtonPrimary";
import ConfirmDialog from "../../../../../../common/components/dialogs/ConfirmDialog";
import { ConfirmModalType } from "../../../../../../common/components/dialogs/ConfirmDialog/Props";
import OcietyDialogTitle from "../../../../../../common/components/dialogs/OcietyDIalogTitle";
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import OcietyIcon from "../../../../../../common/components/OcietyIcon";
import OcietySplitButton from "../../../../../../common/components/OcietySplitButton";
import { Color } from "../../../../../../common/components/OcietySplitButton/enums";
import IOptionBtn from "../../../../../../common/components/OcietySplitButton/IOptionBtn";
import { useRestApi } from "../../../../../../common/hooks/useRestApi";
import { Route } from "../../../../../../config/router";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { UPDATE_VENUE_MAIN_PROMOTION } from "../../../../../Venues/api";
import { PromotionKind } from "../../../../AdminVenue/dialogs/PromotionFormDialog/components/PromotionFormDialogActions/PromotionKind.enum";
import { DiscountStatus } from "../../../../types/DiscountStatus";
import PromotionImage from "../../../PromotionImage";
import PromotionActiveDaysAndHours from "../PromotionActiveDaysAndHours";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionsGridRow: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    promotion,
    onChangeStatus,
    onEditClick,
    onDeleteClick,
    onDuplicateClick,
    isSuperAdminGrid,
    refresh,
  } = props;
  const history = useHistory();
  const { showAlert } = useContext(AlertContext);
  const [isActiveHoursOpen, setIsActiveHoursOpen] = useState(false);
  const [isOpenedCancelConfirmation, setIsOpenedCancelConfirmation] =
    useState(false);

  const [{ loading }, updateMainPromotion] = useRestApi<any>(
    UPDATE_VENUE_MAIN_PROMOTION(promotion.venueId, promotion.id),
    "PATCH"
  );
  const actions: ActionItemProps[] = [
    {
      iconName: "listAction",
      label: "Deal codes",
      onClick: () => {
        history.push(Route.DealsUsages.replace(":id", promotion.id.toString()));
      },
    },
    {
      iconName: "duplicateAction",
      label: "Duplicate",
      onClick: () => {
        onDuplicateClick(promotion.id);
      },
    },
  ];

  if (promotion.status !== DiscountStatus.EXPIRED) {
    actions.unshift({
      iconName: "editAction",
      label: "Edit",
      onClick: () => onEditClick(promotion.id),
    });
  }

  if (
    promotion.views === "0" &&
    promotion.usages === "0" &&
    promotion.status !== DiscountStatus.EXPIRED
  ) {
    actions.push({
      iconName: "removeAction",
      label: "Remove",
      onClick: () => onDeleteClick(promotion.id),
    });
  }

  const options: IOptionBtn[] = [
    {
      label: "Pending",
      value: DiscountStatus.PENDING,
      color: Color.YELLOW,
    },
    {
      label: "Enabled",
      value: DiscountStatus.ENABLED,
      color: Color.GREEN,
    },
    {
      label: "Paused",
      value: DiscountStatus.PAUSE,
      color: Color.BLACK,
    },
    {
      label: "Expired",
      value: DiscountStatus.EXPIRED,
      color: Color.BLUE,
    },
    {
      label: "Cancel for Today",
      value: DiscountStatus.CANCELLED,
      color: Color.RED,
    },
  ];

  const getOptions = () => {
    let filteredOptions: IOptionBtn[] = options;
    if (!promotion.isPlanetPromo) {
      filteredOptions = filteredOptions.filter(
        (o) => o.value !== DiscountStatus.CANCELLED
      );
    }
    if (promotion.status === DiscountStatus.EXPIRED) {
      filteredOptions = filteredOptions.filter(
        (o) => o.value === DiscountStatus.EXPIRED
      );
    } else
      filteredOptions = filteredOptions.filter(
        (o) => o.value !== DiscountStatus.EXPIRED
      );

    return filteredOptions;
  };

  const handleStatusChange = async (status: DiscountStatus): Promise<void> => {
    await onChangeStatus(promotion.id, status, promotion.venueId);
  };

  const onChangeValue = async (status: DiscountStatus): Promise<void> => {
    if (status === DiscountStatus.CANCELLED) {
      return openCancelConfirmation();
    }
    await handleStatusChange(status);
  };

  const printDate = (date: Date): string => {
    return moment(date).format("MM/DD/yyyy");
  };

  const printTime = (time: string) => moment(time, "LT").format("hh:mm A");

  const onMainPromotionChange = async () => {
    try {
      if (await updateMainPromotion()) {
        refresh();
      }
    } catch (e) {
      showAlert(dictionary.deals.changeMainPromotonError, "error");
    }
  };

  const activeHours = () => {
    const todayDate = moment().format("dddd");
    const activeTodayHours = promotion.daysHours.find(
      (activeDay) => activeDay.day === todayDate
    );
    return activeTodayHours?.isActive
      ? `${printTime(activeTodayHours.start)}-${printTime(
          activeTodayHours.end
        )}`
      : "";
  };

  const processVoucherName = (name: string) => {
    const priceWithDollarSignRegex = /[+-]?([0-9]*[.])?[0-9]+/g;
    return name
      ? name.replace(priceWithDollarSignRegex, (price) =>
          Number(price)?.toFixed(2)
        )
      : name;
  };

  const handleOpenActiveHoursModal = () => {
    setIsActiveHoursOpen((prev) => !prev);
  };

  const openCancelConfirmation = () => {
    setIsOpenedCancelConfirmation(true);
  };

  const closeCancelConfirmation = () => {
    setIsOpenedCancelConfirmation(false);
  };

  const handleConfirmCancellation = async () => {
    await handleStatusChange(DiscountStatus.CANCELLED);
    closeCancelConfirmation();
  };

  return (
    <Grid
      container
      xs={12}
      component={Paper}
      className={classes.root}
      wrap={"nowrap"}>
      {loading && <LoadingIndicator withMask />}
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemFirst,
          classes.rowItem1
        )}>
        <PromotionImage
          categoryName={promotion.mainCategoryName}
          kind={promotion.kind}
          image={promotion.img}
        />
      </Grid>
      <Grid item className={clsx(classes.rowItemName, classes.rowItem2)}>
        <Typography
          variant={"body2"}
          className={clsx(classes.rowItemText, classes.nameText)}>
          {promotion.kind === PromotionKind.VOUCHER
            ? processVoucherName(promotion.name)
            : promotion.name}
        </Typography>
      </Grid>
      {!isSuperAdminGrid && (
        <Grid
          item
          className={clsx(
            classes.rowItem,
            classes.rowItemPrimary,
            classes.rowItemCenter,
            classes.rowItem1
          )}>
          <Typography variant={"body2"} className={classes.text}>
            <FormControlLabel
              disabled={promotion.status !== DiscountStatus.ENABLED}
              checked={promotion.isMainPromotion}
              onChange={onMainPromotionChange}
              value={promotion.isMainPromotion}
              key={promotion.id}
              className={classes.primaryText}
              control={<Radio />}
              label=""
            />
          </Typography>
        </Grid>
      )}
      {isSuperAdminGrid && (
        <Grid item className={clsx(classes.rowItemName, classes.rowItem1)}>
          <Typography variant={"body2"} className={classes.rowItemName}>
            {promotion.venueName}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem1
        )}>
        <Typography variant={"body2"} className={classes.text}>
          {promotion.views}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem1
        )}>
        <Typography variant={"body2"} className={classes.text}>
          {promotion.usages}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem1
        )}>
        <Typography variant={"body2"} className={classes.text}>
          {promotion.todayUsages}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem1
        )}>
        <Typography variant={"body2"} className={classes.text}>
          {promotion.todayPurchases}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem2
        )}>
        <div className={classes.activeHoursWrapper}>
          <Typography variant={"body2"} className={classes.text}>
            {activeHours()}
          </Typography>
          <Link
            variant="body2"
            component="button"
            underline="always"
            onClick={handleOpenActiveHoursModal}
            className={classes.link}>
            {dictionary.deals.happyHours.activeDaysAndHours}
          </Link>
        </div>
        <PromotionActiveDaysAndHours
          open={isActiveHoursOpen}
          onClose={handleOpenActiveHoursModal}
          activeDaysAndHours={promotion.daysHours}
          printTime={printTime}
        />
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem1
        )}>
        <Typography variant={"body2"} className={classes.text}>
          {printDate(promotion.start)}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem2
        )}>
        <Typography variant={"body2"} className={classes.text}>
          {printDate(promotion.end)}
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemStatus,
          classes.rowItemCenter,
          classes.rowItem2
        )}>
        <Typography variant={"body2"}>
          <OcietySplitButton
            options={getOptions()}
            onChangeValue={onChangeValue}
            value={promotion.status}
            isExpired={new Date(promotion.end) < new Date()}
          />
        </Typography>
      </Grid>
      <Grid
        item
        className={clsx(
          classes.rowItem,
          classes.rowItemCenter,
          classes.rowItem1
        )}>
        {isSuperAdminGrid && (
          <OcietyIcon
            onClick={() => alert("Not implemented")}
            name={"mailBlue"}
            width="22"
            height="22"
            viewBox="0 0 22 22"
          />
        )}
        {!isSuperAdminGrid && <ActionsDropdown items={actions} />}
      </Grid>

      <ConfirmDialog
        open={isOpenedCancelConfirmation}
        onClose={closeCancelConfirmation}
        onConfirm={() => handleStatusChange(DiscountStatus.CANCELLED)}
        subtitle={"Are you sure you would like to duplicate this deal? "}
        type={ConfirmModalType.CONFIRM}
        message={" \n  "}
      />

      <Dialog
        open={isOpenedCancelConfirmation}
        classes={{
          paperWidthSm: classes.rootModal,
        }}>
        <OcietyDialogTitle
          id="confirmation-dialog-title"
          onClose={closeCancelConfirmation}>
          {dictionary.deals.happyHours.cancelForToday}
        </OcietyDialogTitle>
        <Divider />
        <DialogContent className={classes.content}>
          <img
            src="/images/backgrounds/caution-img.png"
            alt="caution-img"
            className={classes.modalImg}
          />
          <Typography variant={"h1"} className={classes.cancelModalTitle}>
            {dictionary.deals.happyHours.cancelModalSubtitle}
          </Typography>
          <Typography variant={"subtitle1"}>
            {dictionary.deals.happyHours.cancelModalMessage}
          </Typography>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <ButtonCancel
            onClick={closeCancelConfirmation}
            className={classes.mobileButton}>
            {dictionary.confirmDialog.dismissButton}
          </ButtonCancel>
          <ButtonPrimary
            className={classes.mobileButton}
            onClick={handleConfirmCancellation}>
            {dictionary.confirmDialog.confirmButton}
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default PromotionsGridRow;
