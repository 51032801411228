import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors, ColorsLight } from "../../../theme/colors";

export type ClassNames = "breakLine";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    breakLine: {
      width: "100%",
      opacity: 0.6,
      height: 1,
      backgroundColor: colors.grey.medium,
    },
  })
);
