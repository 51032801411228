import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../../../common/models";

export type ClassNames =
  | "root"
  | "dialogContent"
  | "text1"
  | "colorRed"
  | "text2"
  | "text3"
  | "inputMenuUrl"
  | "descriptionWrapper"
  | "descriptionExample"
  | "listSubHeader"
  | "item"
  | "listItemText";
export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: { width: "auto", margin: theme.spacing(1, 0) },
      dialogContent: {
        padding: theme.spacing(2),
        minHeight: 0,
        [theme.breakpoints.up("lg")]: {
          minWidth: 900,
        },
        [theme.breakpoints.up("md")]: {
          minWidth: 648,
        },
        [theme.breakpoints.down("sm")]: {
          minWidth: 500,
        },
        [theme.breakpoints.down("xs")]: {
          minWidth: "auto",
          width: "100%",
          height: "100%",
        },
      },
      listSubHeader: {
        fontWeight: 700,
        fontSize: 12,
        color: "#45484E",
        padding: 0,
        margin: 0,
        lineHeight: "10px",
        marginBottom: 8,
      },
      item: {
        padding: 0,
        marginLeft: 15,
        "&:before": {
          color: "#D9D9D9",
          width: 16,
          content: "'\\2022'",
          display: "inline-block",
          fontWeight: "bold",
          marginLeft: -14,
          fontSize: 16,
          position: "absolute",
          top: 5,
        },
      },
      listItemText: {
        fontWeight: 400,
        fontSize: 12,
        color: "#747982",
      },
      descriptionWrapper: {
        display: "flex",
        marginTop: 16,
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
        },
      },
      descriptionExample: {
        paddingLeft: 24,
        [theme.breakpoints.down("xs")]: {
          padding: 0,
          marginTop: 16,
        },
      },

      text1: { fontWeight: 700, fontSize: 16, color: "#37393F" },
      colorRed: { color: "red" },
      text2: { fontWeight: 400, fontSize: 12, color: "#747982" },
      text3: { fontWeight: 600, fontSize: 12, color: "#747982" },
      inputMenuUrl: { marginTop: 16 },
    })
);
