import React, { FC, useEffect } from "react";

import { useAsyncState } from "../../../../common/hooks/useAsyncState";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { GET_VENUE_RECOMMENDATIONS_TOTAL, ScoreTotal } from "../../models";
import DashboardWidgetSmall from "../DashboardWidgetSmall";
import Props from "./Props";

const TotalRecommendations: FC<Props> = (props) => {
  const { venueId, period } = props;
  const [state, setAsyncState] = useAsyncState<ScoreTotal>(
    GET_VENUE_RECOMMENDATIONS_TOTAL(venueId, period)
  );

  /*useEffect(() => {
    setAsyncState().then();
  }, [setAsyncState]);*/ //temporary commented as recommendations aren't implemented yet

  return (
    <DashboardWidgetSmall
      icon={"widgetRecommendations"}
      loading={state.loading}
      description={dictionary.dashboard.widgetRecommendationsDescription}
      link={Route.Recommendations}
      score={state.response ? state.response.score : 0}
    />
  );
};

export default TotalRecommendations;
