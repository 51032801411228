import { DiscountStatus } from "../types/DiscountStatus";
import { CreateHappyHour } from "../types/interfaces/CreateHappyHour.interface";
import { CreatePartialDiscount } from "../types/interfaces/CreatePartialDiscount.interface";
import { DiscountDaysHours } from "../types/interfaces/DiscountDaysHours.interface";
import { HappyHourFile } from "../types/interfaces/HappyHourFile.interface";

export default class DtoHappyHour implements CreateHappyHour {
  public static build = ({
    daysHours,
    menuUrl,
    venueId,
    deals,
    files,
  }: CreateHappyHour) => {
    const builderClassHappyHour = new DtoHappyHour();
    builderClassHappyHour.daysHours = daysHours;
    builderClassHappyHour.menuUrl = menuUrl;
    builderClassHappyHour.venueId = venueId;
    builderClassHappyHour.deals = deals;
    builderClassHappyHour.files = files;
    return builderClassHappyHour;
  };

  public static buildUpdate = ({
    id,
    daysHours,
    menuUrl,
    venueId,
    deals,
    files,
  }: CreateHappyHour & { id: number }) => {
    const builderClassHappyHour = new DtoHappyHour();
    builderClassHappyHour.id = id;
    builderClassHappyHour.daysHours = daysHours;
    builderClassHappyHour.menuUrl = menuUrl;
    builderClassHappyHour.venueId = venueId;
    builderClassHappyHour.deals = deals;
    builderClassHappyHour.files = files;
    return builderClassHappyHour;
  };

  public id = 0;
  public daysHours: DiscountDaysHours[] = [];
  public status: DiscountStatus = DiscountStatus.PENDING;
  public menuUrl = "";
  public venueId = 0;
  public deals: CreatePartialDiscount[] = [];
  public files: Array<Partial<HappyHourFile>> = [];
}
