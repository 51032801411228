import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../common/models";

export type ClassNames =
  | "root"
  | "wrapper"
  | "promotionsIcon"
  | "title"
  | "periodSelect"
  | "titleWrapper"
  | "gridContainer";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        maxWidth: 1000,
        margin: "auto",
      },
      wrapper: {
        height: "100%",
        maxWidth: "100%",
        padding: 30,
      },
      promotionsIcon: {
        width: 70,
        height: 70,
      },
      title: {
        marginTop: -6,
        marginRight: theme.spacing(2),
      },
      periodSelect: {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          margin: theme.spacing(2, 0, 1),
          border: "1px solid black",
        },
        "&&": {
          marginLeft: 20,
        },
        "&& .MuiSelect-select": {
          padding: "13px 20px",
        },
      },
      titleWrapper: {
        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(3.8),
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: theme.spacing(1.8),
        },
        [theme.breakpoints.down("sm")]: {
          flexWrap: "wrap",
        },
      },
      gridContainer: { maxWidth: "none" },
    })
);
