import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { ColorsLight } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "select" | "selectWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        minWidth: 148,
        backgroundColor: ColorsLight._FFFFFF,
        border: "solid 1px #E5E7EF",
      },
      selectWrapper: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        boxSizing: "border-box",
        backgroundColor: "transparent",
      },
      select: {
        padding: "9px 36px 9px 12px !important",
      },
    })
);
