export enum TwoFactoryAuthTypeEnum {
  DISABLE = "DISABLE",
  CALL = "CALL",
  SMS = "SMS",
  EMAIL = "EMAIL",
  TOTP = "TOTP",
}

export default interface IMfaData {
  mfaTwoFactoryAuthTypeDefault: TwoFactoryAuthTypeEnum;
  mfaSmsEnable: boolean;
  mfaSmsPhone: string;
  mfaCallEnable: boolean;
  mfaCallPhone: string;
  mfaMailEnable: boolean;
  mfaMailEmail: string;
  mfaTotpEnable: boolean;
  mfaTotpNote: string;
}
