import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type VenueClassNames =
  | "root"
  | "wrapper"
  | "title"
  | "descriptionText"
  | "infoText"
  | "infoHelpText"
  | "wrapperActions"
  | "btn"
  | "radioWrapper";

export const useStyles: () => ClassNameMap<VenueClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      wrapper: {
        display: "flex",
        alignItems: "center",
      },
      title: {
        fontSize: theme.typography.pxToRem(16),
        display: "inline-block",
        marginLeft: 20,
        fontWeight: "bold",
        fontFamily: "Nunito Sans",
        color: "#273950",
      },
      descriptionText: {
        fontFamily: "Nunito Sans",
        fontSize: theme.typography.pxToRem(12),
        color: "#909EAF",
      },
      infoText: {
        fontFamily: "Nunito Sans",
        fontSize: theme.typography.pxToRem(18),
        fontWeight: "bold",
        color: "#BAC4D1",
        display: "inline-block",
      },
      infoHelpText: {
        fontFamily: "Nunito Sans",
        fontSize: theme.typography.pxToRem(14),
        color: "#BAC4D1",
        marginLeft: theme.spacing(2),
        display: "inline-block",
      },
      wrapperActions: {
        display: "flex",
        alignItems: "center",
      },
      btn: {
        textTransform: "none",
        fontWeight: "bold",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
      },
      radioWrapper: {
        paddingLeft: 30,
      },
    })
);
