import { Venue, VenuePendingChanges } from "../../common/models";
import { http } from "../../config/api";
import { ISaveVenueRequest } from "./models/ISaveVenueRequest";

export const UPDATE_WORK_DAYS = "venue/update-work-days";
export const UPDATE_HOLIDAYS = "venue/update-holidays";
const VENUE = (venueId: number): string => `venue/${venueId}`;
const UPDATE_VENUE = "venue/update";

export const getVenueById = async (venueId: number): Promise<Venue> =>
  http
    .get(VENUE(venueId))
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const saveVenue = async (
  venue: ISaveVenueRequest
): Promise<{ pendingChanges?: VenuePendingChanges }> =>
  http
    .put(UPDATE_VENUE, venue)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });
