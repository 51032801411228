import { Box, List, ListItem, Typography } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import React, { CSSProperties, FC } from "react";

import { ReactComponent as IconClock } from "../../../../common/svg/icons/clock.svg";
import { DiscountDaysHours } from "../../types/interfaces/DiscountDaysHours.interface";
import Props from "./Props";
import { useStyles } from "./styles";

const ListDaysAndHours: FC<Props> = ({ daysAndHours }: Props) => {
  const classes = useStyles();

  const RenderTimeComponent = ({
    title,
    description,
    style,
    isActive,
  }: {
    title: string;
    description: string;
    style?: CSSProperties;
    isActive: boolean;
  }) => (
    <Box
      className={classes.renderTimeComponent}
      style={{
        ...style,
        opacity: isActive ? 1 : 0,
      }}>
      <IconClock className={classes.iconClock} />
      <div>
        <Typography variant="body2" className={classes.text1}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.text2}>
          {description}
        </Typography>
      </div>
    </Box>
  );

  const RenderDayAndHour = ({
    dayAndHour: { id, day, isActive, start, end },
  }: {
    dayAndHour: DiscountDaysHours;
  }) => (
    <ListItem className={classes.item}>
      <ListItemText
        primary={
          <Typography variant="h3" className={classes.text3}>
            {day}
          </Typography>
        }
      />
      <div className={classes.renderTimeComponentContainer}>
        <RenderTimeComponent
          title={"Start"}
          description={start}
          isActive={isActive}
          style={{ marginRight: 15 }}
        />
        <RenderTimeComponent
          title={"End"}
          description={end}
          isActive={isActive}
        />
      </div>
    </ListItem>
  );

  return (
    <List className={classes.list}>
      {daysAndHours.map((dayAndHour) => (
        <RenderDayAndHour key={dayAndHour.id} dayAndHour={dayAndHour} />
      ))}
    </List>
  );
};
export default ListDaysAndHours;
