import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { SIDEBAR_WIDTH } from "./index";

export type ClassNames =
  | "drawer"
  | "drawerOpen"
  | "drawerClose"
  | "root"
  | "logoWrapper"
  | "venueSelect"
  | "modal"
  | "sidebarTop"
  | "nav"
  | "venueSelectRoot"
  | "venueSelectWrapper"
  | "venueSelectNotActiveMessageRoot";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      drawer: {
        position: "relative",
        padding: theme.spacing(1.25),
        borderRight: "none",
        overflowX: "hidden",
      },
      drawerOpen: {
        width: SIDEBAR_WIDTH,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(82 / 8),
        },
      },
      root: {
        backgroundColor: theme.palette.background.default,
        height: "100vh",
      },
      logoWrapper: {
        marginLeft: theme.spacing(5),
      },
      modal: {
        backgroundColor: "rgba(0,0,0,.4)",
      },
      sidebarTop: {
        marginTop: theme.spacing(1.5),
        padding: theme.spacing(0, 1),
      },
      venueSelect: {
        width: "100%",
        marginTop: theme.spacing(2),
      },
      nav: {
        height: "auto",
        [theme.breakpoints.up("sm")]: {
          marginTop: theme.spacing(5),
        },
      },
      venueSelectRoot: {
        flexDirection: "column",
      },
      venueSelectNotActiveMessageRoot: {
        [theme.breakpoints.down("lg")]: {
          marginTop: theme.spacing(2),
          maxWidth: "100%",
          marginLeft: 0,
        },
      },
      venueSelectWrapper: {
        minWidth: "100%",
      },
    })
);
