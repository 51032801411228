import { http } from "../../../../config/api";
import IMfaData, { TwoFactoryAuthTypeEnum } from "./models/IMfaData";

const MFA_SMS_CODE_REQUEST = "profile/mfa";
const MFA_SET_DEFAULT = "profile/mfa/set-default";

export const getMfaProfile = async (): Promise<IMfaData> =>
  http
    .get(MFA_SMS_CODE_REQUEST)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });

export const setMfaDefault = async (
  type: TwoFactoryAuthTypeEnum
): Promise<any> =>
  http
    .post(MFA_SET_DEFAULT, { type })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
