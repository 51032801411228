import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "success" | "error";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      success: {
        color: theme.palette.success.main,
      },
      error: {
        color: theme.palette.error.main,
      },
    })
);
