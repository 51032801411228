import { DialogContentClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const MuiDialogContent: Partial<Record<DialogContentClassKey, CSSProperties>> =
  {
    root: {
      paddingLeft: 90,
      paddingRight: 90,
    },
    dividers: {
      borderBottom: 0,
      paddingLeft: 90,
      paddingRight: 90,
    },
  };

export default MuiDialogContent;
