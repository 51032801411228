import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "content"
  | "contentTextWrapper"
  | "title"
  | "rootEmpty"
  | "promotionName"
  | "emptyPromotionText"
  | "icon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        overflow: "hidden",
        position: "relative",
        marginTop: theme.spacing(3),
        minHeight: 169,
        padding: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background:
          // eslint-disable-next-line max-len
          "url(/images/backgrounds/most-popular-promotion-bg.png) no-repeat center",
        backgroundColor: theme.palette.primary.main,
        borderRadius: 6,
        [theme.breakpoints.up("xl")]: {
          backgroundSize: "cover",
        },
        [theme.breakpoints.down("md")]: {
          backgroundSize: "cover",
        },
        [theme.breakpoints.down("sm")]: {
          backgroundSize: "auto",
        },
        "&:before, &:after": {
          content: "''",
          display: "block",
          borderRadius: "50%",
          backgroundColor: theme.palette.background.default,
          position: "absolute",
          left: 56,
          height: 16,
          width: 16,
        },
        "&:before": {
          top: -8,
        },
        "&:after": {
          bottom: -8,
        },
      },
      rootEmpty: {
        background: colors.white.primary,
        "& $title": {
          color: theme.palette.text.primary,
        },
      },
      emptyPromotionText: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 600,
      },
      title: {
        color: colors.white.primary,
      },
      promotionName: {
        color: colors.white.primary,
        fontSize: theme.typography.pxToRem(32),
        lineHeight: 1.25,
        marginTop: theme.spacing(2),
        fontWeight: "bold",
      },
      content: {
        minWidth: "78%",
      },
      contentTextWrapper: {
        marginRight: theme.spacing(2),
      },
      icon: {
        width: 40,
        height: 32,
        position: "absolute",
        top: theme.spacing(3.5),
        right: theme.spacing(3.5),
      },
    })
);
