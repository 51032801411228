import { TableRow } from "@material-ui/core";
import React, { FC } from "react";

import Avatar from "../../../../../../../common/components/Avatar";
import ButtonBlack from "../../../../../../../common/components/ButtonBlack";
import OcietyTooltip from "../../../../../../../common/components/OcietyTooltip";
import TD from "../../../../../../../common/components/TD";
import useReplyToReview from "../../../../../../../common/hooks/useReplyToReview";
import { useGlobalStyles } from "../../../../../../../common/styles";
import { ReactComponent as EnvelopeIcon } from "../../../../../../../common/svg/icons/envelope.svg";
import { ReactComponent as StarSharpFilledIcon } from "../../../../../../../common/svg/icons/start-sharp-filled.svg";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../../../../utils/dateTimeUtils";
import Props from "./Props";
import { useStyles } from "./styles";

const UserReviewsTableRow: FC<Props> = (props: Props) => {
  const {
    review: { userAvatar, food, possibleResponse, username, createdAt, userId },
  } = props;
  const classes = useStyles();
  const handleClickReply = useReplyToReview({ userId, userAvatar, username });
  const globalClasses = useGlobalStyles();

  return (
    <TableRow hover>
      <TD>
        <div className={globalClasses.flexRowStartCenter}>
          <Avatar imagePath={userAvatar} size={48} />
          {username}
        </div>
      </TD>
      <TD>
        <div className={classes.tableDataCellInner}>
          {getFormattedDate(createdAt)}
        </div>
      </TD>
      <TD>
        <div className={classes.tableDataCellInner}>
          <span className={classes.marginRight10}>
            {dictionary.reviews.score(food || 0)}
          </span>
          <StarSharpFilledIcon />
        </div>
      </TD>

      <TD>
        <div className={classes.actionsRow}>
          {username !== "deleted_account" && (
            <>
              <div>
                <ButtonBlack
                  disabled={!possibleResponse}
                  onClick={handleClickReply}>
                  {possibleResponse && (
                    <EnvelopeIcon className={classes.messageIcon} />
                  )}
                  {dictionary.reviews.allReviewsReply}
                </ButtonBlack>
              </div>

              {!possibleResponse && (
                <OcietyTooltip
                  title={dictionary.reviews.allReviewsReplyDisabled}
                />
              )}
            </>
          )}
        </div>
      </TD>
    </TableRow>
  );
};

export default UserReviewsTableRow;
