import { TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent, FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import ButtonSecondary from "../../../../common/components/ButtonSecondary";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  clearMFAToken,
  loginClearError,
  verifyMFACode,
} from "../../../../store/auth/actions";
import {
  clearForm,
  setAndValidate,
  setFormError,
  validateForm,
} from "../../../../store/forms/actions";
import { FormFieldKey } from "../../../../store/forms/types";
import { AppState } from "../../../../store/rootReducer";
import { schema } from "./form";
import Props from "./Props";

const MFA: FC<Props> = (props: Props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { forms, auth }: AppState = useSelector((state: AppState) => state);
  const form = forms.mfa;
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;
    dispatch(setAndValidate("mfa", key as FormFieldKey, value, schema));
  };

  const handleVerifyMFACode = () => {
    const mfaCode = form.values.code;
    const mfaToken = auth.mfaToken;

    if (!mfaCode || !mfaToken) {
      dispatch(validateForm("mfa", schema));
      return;
    }

    dispatch(
      verifyMFACode(
        {
          code: mfaCode,
          token: mfaToken,
          rememberMe: auth.rememberMe,
        },
        (error: string) => {
          dispatch(setFormError("mfa", "code", error));
        }
      )
    );
  };

  const cancelMFA = () => {
    dispatch(loginClearError());
    dispatch(clearForm("mfa"));
    dispatch(clearMFAToken());
  };

  return (
    <>
      <Typography className={classes.mfaDescription} variant="h5">
        {dictionary.auth.enterMfaToSignIn}
      </Typography>
      <TextField
        className={classes.textField}
        error={!!form.errors.code}
        fullWidth
        helperText={form.errors.code}
        label={dictionary.auth.mfaCodeFieldLabel}
        name="code"
        onChange={handleChange}
        type="number"
        value={form.values.code || ""}
        variant="outlined"
      />
      <div className={classes.actionsWrapper}>
        <ButtonPrimary
          className={classes.signInButton}
          disabled={!form.isValid}
          fullWidth
          onClick={handleVerifyMFACode}>
          {dictionary.auth.sendMFACodeButtonText}
        </ButtonPrimary>
        <ButtonSecondary onClick={cancelMFA} fullWidth>
          {dictionary.auth.cancelMfa}
        </ButtonSecondary>
      </div>
    </>
  );
};

export default MFA;
