import { FormControlLabel, Radio, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const PrimaryPhotoRadio: FC<Props> = (props: Props) => {
  const { isMainPhoto, handleMainPhotoChange } = props;

  const classes = useStyles();

  const checkedIcon = () => <div className={classes.checkedIcon} />;
  const uncheckedIcon = () => <div className={classes.uncheckedIcon} />;

  return (
    <FormControlLabel
      className={classes.changeMainPhoto}
      checked={isMainPhoto}
      onChange={handleMainPhotoChange}
      value={isMainPhoto}
      control={
        <Radio
          checkedIcon={checkedIcon()}
          icon={uncheckedIcon()}
          className={
            isMainPhoto
              ? classes.radio
              : clsx(classes.radio, classes.radioNotSelected)
          }
        />
      }
      label={
        <Typography className={classes.text} variant={"subtitle2"}>
          {!isMainPhoto
            ? dictionary.venues.venueForm.setPrimary
            : dictionary.venues.venueForm.primary}
        </Typography>
      }
    />
  );
};

export default PrimaryPhotoRadio;
