import { Paper } from "@material-ui/core";
import React, { FC } from "react";

import PageTitle from "../../common/components/PageTitle";
import dictionary from "../../i18n/en_US/dictionary";
import CouponsTable from "./components/CouponsTable";
import PromoCodesContextProvider from "./providers";
import { useStyles } from "./styles";

const CouponManagement: FC = () => {
  const classes = useStyles();

  return (
    <PromoCodesContextProvider>
      <div className={classes.root}>
        <PageTitle>{dictionary.couponManagement.pageTitle}</PageTitle>
        <Paper className={classes.content}>
          <CouponsTable />
        </Paper>
      </div>
    </PromoCodesContextProvider>
  );
};

export default CouponManagement;
