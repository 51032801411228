import { Box, CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const LoadingIndicator: FC<Props> = (props: Props) => {
  const { wrapperClassName, withMask, ...rest } = props;

  const classes = useStyles();

  return (
    <Box
      className={clsx(
        classes.root,
        withMask && classes.withMask,
        wrapperClassName
      )}>
      <CircularProgress {...rest} />
    </Box>
  );
};

export default LoadingIndicator;
