import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type ClassNames = "root" | "menuItemWrapper" | "showAllButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {
      border: "0px solid !important",
      boxShadow: "0 0px 0px 0 rgb(0 0 0 / 0.05) !important",
      padding: "0 !important",
    },
    menuItemWrapper: {
      display: "flex",
      flexDirection: "row",
      gap: 12,
    },
    showAllButton: {
      display: "flex",
      justifyContent: "center",
    },
  })
);
