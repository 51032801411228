import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type ClassNames = "root" | "default" | "green" | "red";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
      },
      default: {},
      green: {
        color: "#67C19F",
      },
      red: {
        color: "#EB5252",
      },
    })
);
