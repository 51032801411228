import { Grid } from "@material-ui/core";
import React, { FC } from "react";

import PageTitle from "../../common/components/PageTitle";
import dictionary from "../../i18n/en_US/dictionary";
import ContactUsForm from "./components/ContactUsForm";
import ContactUsSidebar from "./components/ContactUsSidebar";
import { useStyles } from "./styles";

const ContactUs: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PageTitle wrapperClassName={classes.titleWrapper}>
        {dictionary.contactUs.pageTitle}
      </PageTitle>
      <Grid container spacing={3} style={{ maxWidth: "none" }}>
        <Grid item lg={3} md={4} sm={12}>
          <ContactUsSidebar />
        </Grid>
        <Grid item lg={9} md={8} sm={12}>
          <ContactUsForm />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactUs;
