import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../i18n/en_US/dictionary";
import InformationBlock from "./components/InformationBlock";
import Props from "./Props";
import { useStyles } from "./styles";

const VenuePendingUserData: FC<Props> = (props: Props) => {
  const {
    comment,
    userCompany,
    userEmail,
    userFirstName,
    userLastName,
    userPhoneNumber,
    userTitle,
  } = props;

  const classes = useStyles();

  const constructItems = () => {
    return [
      {
        label: dictionary.venuePending.adminInformation.firstNameLabel,
        value: userFirstName,
      },
      {
        label: dictionary.venuePending.adminInformation.companyLabel,
        value: userCompany,
      },
      {
        label: dictionary.venuePending.adminInformation.lastNameLabel,
        value: userLastName,
      },
      {
        label: dictionary.venuePending.adminInformation.phoneNumberLabel,
        value: userPhoneNumber,
      },
      {
        label: dictionary.venuePending.adminInformation.titleLabel,
        value: userTitle,
      },
      {
        label: dictionary.venuePending.adminInformation.emailLabel,
        value: userEmail,
      },
    ];
  };

  return (
    <div className={classes.root}>
      <InformationBlock
        title={dictionary.venuePending.adminInformation.blockTitle}
        items={constructItems()}
      />

      {!!comment && (
        <div className={classes.commentWrapper}>
          <Typography variant={"body2"} className={classes.blockLabel}>
            {dictionary.venuePending.adminInformation.commentLabel}
          </Typography>
          <div className={classes.comment}>
            <Typography className={classes.blockValue}>{comment}</Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default VenuePendingUserData;
