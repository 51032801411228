import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "title"
  | "helperText"
  | "actionsToolbar"
  | "deleteButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        position: "relative",
      },
      title: {
        fontSize: theme.spacing(2),
        lineHeight: theme.typography.pxToRem(40),
        fontWeight: 700,
      },
      helperText: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        marginBottom: theme.spacing(2),
      },
      actionsToolbar: {
        marginTop: theme.spacing(3),
        display: "flex",
        justifyContent: "flex-end",
      },
      deleteButton: {
        marginRight: theme.spacing(1),
      },
    })
);
