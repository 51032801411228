import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const CounterIcon: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { items, className } = props;

  return (
    <>
      {items ? (
        <div className={className ? className : classes.counter}>
          <Typography className={classes.counterText} component={"span"}>
            {items}
          </Typography>
        </div>
      ) : null}
    </>
  );
};

export default CounterIcon;
