import { colors } from "../colors";

export default {
  track: {
    backgroundColor: "#D9DEE7",
  },
  switchBase: {
    color: "#A5B2C5",
    "&$checked": {
      color: colors.green.secondary,
      "& + $track": {
        backgroundColor: colors.green.primary,
        opacity: 0.38,
      },
    },
    "&$focusVisible $thumb": {
      color: colors.green.primary,
    },
  },
};
