import { http } from "../../../../../../../../../../../config/api";

export const UPLOAD_PROMOTION_PHOTO = (venueId: number, promotionId: number) =>
  `/promotions/${venueId}/photo/${promotionId}`;

export const DELETE_PROMOTION_PHOTO = (
  venueId: number,
  promotionPhotoId: number
) => `/promotions/${venueId}/photo/${promotionPhotoId}`;

export const DELETE_PROMOTION_VIDEO = (
  venueId: number,
  promotionVideoId: number
) => `/promotions/${venueId}/video/${promotionVideoId}`;

const UPLOAD_PROMOTION_VIDEO = (venueId: string) =>
  `/promotions/${venueId}/video`;

export const uploadPromotionPhoto = (
  photo: File | Blob,
  venueId: number,
  promotionId: number,
  progressUpdate: (progressPercent: number) => void
) => {
  const formData = new FormData();
  if ("name" in photo) {
    formData.append("photo", photo, photo?.name);
  } else {
    formData.append("photo", photo);
  }

  return http.post(UPLOAD_PROMOTION_PHOTO(venueId, promotionId), formData, {
    onUploadProgress: (progressEvent: ProgressEvent) => {
      progressUpdate(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
    },
  });
};

export const uploadPromotionVideo = (
  photo: any,
  venueId: string,
  progressUpdate: (progressPercent: number) => void
) => {
  const formData = new FormData();
  formData.append("video", photo);

  return http.post(UPLOAD_PROMOTION_VIDEO(venueId), formData, {
    onUploadProgress: (progressEvent: ProgressEvent) => {
      progressUpdate(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
    },
  });
};

export const handleDeletePromotionVideo = (
  venueId: number,
  videoId: number
) => {
  return http
    .delete(DELETE_PROMOTION_VIDEO(venueId, videoId))
    .catch((error) => {
      throw error.response;
    });
};

export const handleDeletePromotionPhoto = (
  venueId: number,
  photoId: number
) => {
  return http
    .delete(DELETE_PROMOTION_PHOTO(venueId, photoId))
    .catch((error) => {
      throw error.response;
    });
};
