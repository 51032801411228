import React, { FC } from "react";
import { Helmet } from "react-helmet";

import Props from "./Props";

const DynamicMetaTags: FC<Props> = (props: Props) => {
  const { title, ...rest } = props;
  return <Helmet title={title} htmlAttributes={{ lang: "en" }} {...rest} />;
};

export default DynamicMetaTags;
