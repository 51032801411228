import validator from "validator";

import { Form } from "../common/models";

interface LengthRule {
  max?: number;
  min?: number;
}

export type FieldType =
  | "email"
  | "isUrl"
  | "password"
  | "number"
  | "number+"
  | "percentNumber"
  | "phone"
  | "latitude"
  | "longitude"
  | "notLetters"
  | "atLeastOne"
  | "endDate";

type FormSchema = Record<string, ValidationRules>;

export interface ValidationRules {
  type?: FieldType | FieldType[];
  length?: LengthRule;
  maxNumberValue?: number;
  required?: boolean;
  equal?: string;
  noEqual?: string;
  isNotEqual?: string;
  fieldName?: string;
  email?: boolean;
  isUrl?: boolean;
  isIntegerValueBigger?: IsIntegerValueBiggerType;
}

export interface IsIntegerValueBiggerType {
  fieldNameInForm: string;
  fieldInputLabel: string;
}

export function validate<S extends FormSchema, F extends Form>(
  key: keyof S,
  value: string,
  schema: S,
  form: F
): string {
  let error = "";
  const validationRules: ValidationRules = schema[key];

  const handleError = (errorString: string) => {
    const editedKey = key
      .toString()
      .replace(/([A-Z])/g, " $1")
      .trim();
    const fieldName =
      validationRules.fieldName ||
      // @ts-ignore
      editedKey[0].toUpperCase() + editedKey.slice(1, editedKey.length);

    error = fieldName + " " + errorString;
  };
  if (!value) {
    if (validationRules?.required) {
      handleError("is required");
    }

    return error;
  }

  // @ts-ignore
  for (const ruleKey: keyof ValidationRules in validationRules) {
    if (validationRules.hasOwnProperty(ruleKey)) {
      const rules = validationRules[ruleKey as keyof ValidationRules];
      switch (ruleKey) {
        case "type": {
          validateType(value, rules as FieldType | FieldType[], handleError);
          break;
        }
        case "length": {
          validateLength(value, rules as LengthRule, handleError);
          break;
        }
        case "maxNumberValue": {
          validateMaxValue(value, rules as LengthRule, handleError);
          break;
        }
        case "equal": {
          const fieldToCompare = rules as string;

          validateEqual(
            value,
            fieldToCompare,
            form.values[fieldToCompare],
            handleError
          );
          break;
        }
        case "noEqual": {
          const fieldToCompare = rules as string;

          validateEqualCurrentAndNew(
            value,
            fieldToCompare,
            form.values[fieldToCompare],
            handleError
          );
          break;
        }
        case "isNotEqual": {
          const fieldToCompare = rules as string;

          validateIsNotEqual(value, fieldToCompare, handleError);
          break;
        }
        case "isIntegerValueBigger": {
          const fieldToCompare = rules as IsIntegerValueBiggerType;

          validateBiggerIntegerFieldThanAnother(
            value,
            fieldToCompare.fieldInputLabel,
            form.values[fieldToCompare.fieldNameInForm],
            handleError
          );
          break;
        }
        default: {
          // nothing
        }
      }
    }
  }

  return error;
}

function validateType(
  value: string,
  type: FieldType | FieldType[],
  handleError: (error: string) => void
): void {
  switch (type) {
    case "email": {
      if (!validator.isEmail(value)) {
        handleError("is not valid");
      }
      break;
    }
    case "isUrl": {
      if (!validator.isURL(value)) {
        handleError("is not url");
      }
      break;
    }
    case "password": {
      if (
        !validator.matches(
          value,
          new RegExp(
            // eslint-disable-next-line max-len
            "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
          )
        )
      ) {
        handleError(
          "must contain at least one number and one" +
            " uppercase and lowercase letter, and at least 8" +
            " or more characters"
        );
      }
      break;
    }
    case "number": {
      if (!validator.isNumeric(value.toString())) {
        handleError("is not valid number");
      }
      break;
    }
    case "number+": {
      if (!validator.isNumeric(value.toString()) || Number(value) <= 0) {
        handleError("is not positive number value");
      }
      break;
    }
    case "atLeastOne": {
      if (!validator.isNumeric(value.toString()) || Number(value) <= 0) {
        handleError("is required");
      }
      break;
    }
    case "percentNumber": {
      if (
        !validator.isNumeric(value.toString()) ||
        Number(value) <= 0 ||
        Number(value) > 100
      ) {
        handleError("is not a valid percent number");
      }
      break;
    }
    case "phone": {
      if (!validator.isMobilePhone(value)) {
        handleError("is not valid");
      }
      break;
    }
    case "latitude": {
      validateLatitude(value, handleError);
      break;
    }
    case "longitude": {
      validateLongitude(value, handleError);
      break;
    }
    case "notLetters": {
      validateNoLetters(value, handleError);
      break;
    }
    case "endDate": {
      validateEndDate(value, handleError);
      break;
    }
  }
}

function validateLength(
  value: string,
  rules: LengthRule,
  handleError: (error: string) => void
) {
  const valueLength = value.length;
  const ruleMax = rules.max;
  const ruleMin = rules.min;

  if (ruleMax && ruleMax < valueLength) {
    handleError(`can't contain more than ${ruleMax} symbols`);
  }

  if (ruleMin && ruleMin > valueLength) {
    handleError(`can't contain less than ${ruleMin} symbols`);
  }
}

function validateMaxValue(
  value: string,
  rules: LengthRule,
  handleError: (error: string) => void
) {
  const fieldValue = Number(value);
  const ruleMax = rules;

  if (ruleMax && ruleMax < fieldValue) {
    handleError(`can't be more than ${ruleMax}`);
  }
}

function validateIsNotEqual(
  value: string,
  comparisonFieldName: string,
  handleError: (error: string) => void
) {
  if (value === comparisonFieldName) {
    handleError(`should not be ${comparisonFieldName}`);
  }
}

function validateEqual(
  value: string,
  comparisonFieldName: string,
  comparison: string,
  handleError: (error: string) => void
) {
  if (!validator.equals(value, comparison)) {
    handleError(
      `must be the same as ${formatUserFriendlyFieldName(comparisonFieldName)}`
    );
  }
}

function validateEqualCurrentAndNew(
  value: string,
  comparisonFieldName: string,
  comparison: string,
  handleError: (error: string) => void
) {
  if (validator.equals(value, comparison)) {
    handleError(
      `must be different from ${formatUserFriendlyFieldName(
        comparisonFieldName
      )}`
    );
  }
}

export function validateBiggerIntegerFieldThanAnother(
  value: string,
  comparisonFieldName: string,
  comparisonValue: string,
  handleError: (error: string) => void
) {
  if (Number(value) <= Number(comparisonValue)) {
    handleError(
      `must be bigger than ${formatUserFriendlyFieldName(comparisonFieldName)}`
    );
  }
}

export function validateWebsite(
  website: string,
  handleError: (error: string) => void
) {
  if (website && !validator.isURL(website)) {
    handleError("is not valid");
  }
}

export function validateEndDate(
  endDate: string,
  handleError: (error: string) => void
) {
  if (endDate && validator.isBefore(endDate)) {
    handleError("is not valid");
  }
}

export function validateLatitude(
  latitude: string,
  handleError: (error: string) => void
) {
  if (!validator.isLatLong(latitude + ",-73.985130")) {
    handleError("is not valid coordinate");
  }
}

export function validateLongitude(
  longitude: string,
  handleError: (error: string) => void
) {
  if (!validator.isLatLong("40.758896," + longitude)) {
    handleError("is not valid coordinate");
  }
}

export function validateNoLetters(
  postcode: string,
  handleError: (error: string) => void
) {
  const numberPattern = new RegExp("^[^a-zA-Z]+$");
  if (!numberPattern.test(postcode) && postcode !== "") {
    handleError("must contain only numbers");
  }
}

export const formatUserFriendlyFieldName = (fieldName: string) => {
  const editedKey = fieldName
    .toString()
    .replace(/([A-Z])/g, " $1")
    .trim();

  return editedKey[0].toUpperCase() + editedKey.slice(1, editedKey.length);
};
