export enum PromotionType {
  FOOD = "FOOD",
  DRINK = "DRINK",
  ORDER = "ORDER",
}

export enum PromotionLimitType {
  SINGLE = "SINGLE",
  GROUP = "GROUP",
}
