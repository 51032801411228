import { Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import OcietyIcon from "../OcietyIcon";
import Props from "./Props";
import { useStyles } from "./styles";

type PasswordRequirementName =
  | "letter"
  | "number"
  | "special"
  | "capital"
  | "minimum";

interface PasswordRequirement {
  name: PasswordRequirementName;
  label: string;
}

const requirements: PasswordRequirement[] = [
  {
    name: "letter",
    label: dictionary.forgotPassword.withLetters,
  },
  {
    name: "number",
    label: dictionary.forgotPassword.withNumbers,
  },
  {
    name: "special",
    label: dictionary.forgotPassword.withSpecial,
  },
  {
    name: "capital",
    label: dictionary.forgotPassword.withCapital,
  },
  {
    name: "minimum",
    label: dictionary.forgotPassword.withMinimum(8),
  },
];

type State = Record<PasswordRequirementName, boolean>;

const defaultState = {
  letter: false,
  number: false,
  special: false,
  capital: false,
  minimum: false,
};

const PasswordRequirementsPopin: FC<Props> = (props: Props) => {
  const {
    password,
    handlePasswordRequirementsError,
    inputId,
    setIsPasswordFieldFocused,
    isPasswordFieldFocused,
  } = props;
  const [state, setState] = useState<State>(defaultState);
  const classes = useStyles();
  const isFirstRender = useRef(true);

  const checkPasswordRequirements = useCallback(() => {
    const newState = password
      ? {
          // only Latin letters
          letter: !!password.match(/[a-zA-Z]/g) && !password.match(/[а-яА-Я]/g),
          number: !!password.match(/[0-9]/g),
          special: !!password.match(/\W|_/g),
          capital: !!password.match(/[A-Z]/g),
          minimum: password.length >= 8,
        }
      : { ...defaultState };

    if (Object.values(newState).some((value) => !value)) {
      handlePasswordRequirementsError();
    }

    setState(newState);
  }, [handlePasswordRequirementsError, password]);

  useEffect(() => {
    // To avoid errors on first field render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    checkPasswordRequirements();
  }, [checkPasswordRequirements]);

  const handleDocumentClick = useCallback(
    (e: any) => {
      if (!e.target.closest(inputId) && isPasswordFieldFocused) {
        setIsPasswordFieldFocused(false);
      }
    },
    [inputId, isPasswordFieldFocused, setIsPasswordFieldFocused]
  );

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [handleDocumentClick]);

  const renderRequirement = ({ label, name }: PasswordRequirement) => (
    <Typography
      className={clsx(
        classes.requirement,
        state[name] && classes.requirementDone
      )}>
      <OcietyIcon
        className={classes.icon}
        viewBox={"0 0 10 10"}
        name={state[name] ? "checkMark" : "xRed"}
      />
      {label}
    </Typography>
  );

  if (!isPasswordFieldFocused) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <Typography className={classes.title}>
        {dictionary.forgotPassword.passwordRequirementsTitle}
      </Typography>
      <div className={classes.requirementsWrapper}>
        {requirements.map(renderRequirement)}
      </div>
    </Paper>
  );
};

export default PasswordRequirementsPopin;
