import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const TextMessage: FC<Props> = (props: Props) => {
  const { children, className, type } = props;

  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.root, classes[type], className)}
      variant="h6">
      {children}
    </Typography>
  );
};

export default TextMessage;
