import React, { FC } from "react";

import VenueReportingContextProvider from "../../common/components/VenueReportingContextProvider";
import ReportingTabs from "./components/ReportingTabs";
import ReportingTopBar from "./components/ReportingTopBar";

const Reporting: FC = () => {
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  return (
    <VenueReportingContextProvider>
      <div>
        <ReportingTopBar currentTabIndex={currentTabIndex} />
        <ReportingTabs
          onChangeTab={(tabHash: string, index: number) => {
            setCurrentTabIndex(index);
          }}
        />
      </div>
    </VenueReportingContextProvider>
  );
};

export default Reporting;
