import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";

import {
  AgeGroup,
  GET_AGE_GROUP_OPTIONS,
} from "../../../../../../../../../common/components/AgeGroupSelect/api";
import Option from "../../../../../../../../../common/components/MultipleSelect/Option";
import OcietyBlockWrapperForRadioButton from "../../../../../../../../../common/components/OcietyBlockWrapperForRadioButton";
import OcietyCheckbox from "../../../../../../../../../common/components/OcietyCheckbox";
import OcietySwitch from "../../../../../../../../../common/components/OcietySwitch";
import OcietyTooltip from "../../../../../../../../../common/components/OcietyTooltip";
import { useRestApi } from "../../../../../../../../../common/hooks/useRestApi";
import { ReactComponent as InfoIcon } from "../../../../../../../../../common/svg/icons/info.svg";
import { ReactComponent as IconOctagramGreen } from "../../../../../../../../../common/svg/icons/octagram-green.svg";
import dictionary from "../../../../../../../../../i18n/en_US/dictionary";
import { colors } from "../../../../../../../../../theme/colors";
import { PromotionAdvancedFormField } from "../../../../forms/advanced";
import { PromotionFormDialogContext } from "../../../../PromotionFormDialogContextProvider";
import { PromotionKind } from "../../../PromotionFormDialogActions/PromotionKind.enum";
import Props from "./Props";
import { useStyles } from "./styles";

const AdvancedInfoPromotionStep: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { ageGroupId, setAgeGroupId } = props;
  const { advancedForm, mainForm } = useContext(PromotionFormDialogContext);
  const { form, setAndValidate, setSchemaRules, setFormValue } = advancedForm;
  const [{ data: ageGroupsData }, getAgeGroups] = useRestApi<Array<AgeGroup>>(
    GET_AGE_GROUP_OPTIONS,
    "GET"
  );
  const isDeal =
    mainForm.form.values.kind === PromotionKind.DEAL ||
    mainForm.form.values.kind === PromotionKind.GIVEAWAY;

  const stepInput = useRef<HTMLInputElement>(null);

  const ageGroupOptions = ageGroupsData
    ? ageGroupsData
        .filter((option) =>
          mainForm.form.values.categoryId === "29"
            ? option.name !== "18-20"
            : option
        )
        .map(
          (ageGroup: AgeGroup) => new Option(String(ageGroup.id), ageGroup.name)
        )
    : [];

  useEffect(() => {
    getAgeGroups().catch((e) => console.error(e));
  }, [getAgeGroups]);

  useEffect(() => {
    stepInput.current &&
      stepInput.current?.scrollIntoView({ block: "nearest" });
  }, [setFormValue, setSchemaRules]);

  useEffect(() => {
    if (form.values.isQuantityUnlimited === "1") {
      setSchemaRules({ quantity: {} });
      if (form.values.quantity) {
        setAndValidate("quantity", "1"); // set default value
      }
    } else {
      setSchemaRules({
        quantity: {
          type: "number+",
          required: true,
        },
      });
    }
  }, [
    setSchemaRules,
    setAndValidate,
    form.values.isQuantityUnlimited,
    form.values.quantity,
  ]);

  const isQuantityUnlimited = useCallback(() => {
    return form.values.isQuantityUnlimited === "1";
  }, [form.values.isQuantityUnlimited]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const fieldName = event.target.name as PromotionAdvancedFormField;
    let fieldValue = event.target.value;

    if (
      [
        "notifyHowManyLeftCount",
        "quantity",
        "maximumUsagesPerCustomer",
        "maximumUsagesPerDay",
      ].includes(fieldName)
    ) {
      fieldValue = fieldValue.replace(/\D/g, "");
    }
    setAndValidate(fieldName, fieldValue);
  };

  const isDisableNotifyLeft = () => {
    return form.values.notifyHowManyLeft === "0";
  };
  const handleSwitchChange = (changed: boolean) => {
    setAndValidate("notifyHowManyLeft", changed ? "1" : "0");
  };

  const handleChangeLimit = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAndValidate(
        "isQuantityUnlimited",
        (event.target as HTMLInputElement).value || "0"
      );
    },
    [setAndValidate]
  );

  const clearValueFieldOnClick = (e: any) => {
    if (e.target.value === "0") e.target.value = "";
  };

  const handleChangeAgeGroup = (event: React.ChangeEvent<any>) => {
    const checkedAgeGroup = Number(event.target.value);
    let ageGroups;
    if (ageGroupId.includes(checkedAgeGroup)) {
      ageGroups = ageGroupId.filter((age) => age !== checkedAgeGroup);
      setAgeGroupId(ageGroups);
    } else {
      ageGroups = [...ageGroupId, checkedAgeGroup];
      setAgeGroupId(ageGroups);
    }

    setFormValue("ageGroupId", ageGroups?.map((item) => item).join());
  };

  const handleChangeAllAgeGroups = () => {
    if (ageGroupId.length === ageGroupOptions.length) {
      setAgeGroupId([]);
      setFormValue("ageGroupId", "");
    } else {
      setAgeGroupId(ageGroupOptions?.map((item) => Number(item.value)));
      setFormValue(
        "ageGroupId",
        ageGroupOptions?.map((item) => item.value).join()
      );
    }
  };

  useEffect(() => {
    if (Number(mainForm.form.values.isPlanetPromo)) {
      setSchemaRules({
        maximumUsagesPerDay: {
          type: "number+",
          required: true,
        },
      });
    } else if (Number(advancedForm.form.values.maximumUsagesPerDay) !== 0) {
      setSchemaRules({
        maximumUsagesPerDay: {
          type: "number",
        },
      });
      advancedForm.setAndValidate("maximumUsagesPerDay", "0");
    }
  }, [advancedForm, mainForm.form.values.isPlanetPromo, setSchemaRules]);

  return (
    <>
      <div ref={stepInput} />
      <Grid container spacing={4} className={classes.root}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h3">
            {dictionary.deals.quantityLimitationTitle}
          </Typography>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={form.values.isQuantityUnlimited}
            onChange={handleChangeLimit}
            className={classes.marginTopField}
            defaultValue={"0"}>
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <OcietyBlockWrapperForRadioButton
                  isActive={!isQuantityUnlimited()}
                  label={dictionary.deals.createDealForm.limitedLabel}
                  value={"0"}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <OcietyBlockWrapperForRadioButton
                  isActive={isQuantityUnlimited()}
                  label={dictionary.deals.createDealForm.unlimitedLabel}
                  value={"1"}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography
            variant="h3"
            style={{
              color: isQuantityUnlimited()
                ? colors.grey.secondary
                : colors.grey.primary,
            }}>
            {dictionary.deals.maximumUsagesAcrossAllTitle}
          </Typography>
          <FormControl fullWidth>
            <TextField
              id="filled-quantity"
              disabled={isQuantityUnlimited()}
              label={dictionary.deals.typeQuantityLabel}
              variant="outlined"
              name={"quantity"}
              className={classes.marginTopField}
              onChange={handleChange}
              onClick={clearValueFieldOnClick}
              helperText={form.errors.quantity}
              error={!!form.errors.quantity}
              value={form.values.quantity}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography variant="h3">
            {dictionary.deals.maximumUsagesPerCustomerTitle}
          </Typography>
          <FormControl fullWidth>
            <TextField
              id="filled-maximumUsagesPerCustomer"
              variant="outlined"
              className={classes.marginTopField}
              label={dictionary.deals.typeQuantityLabel}
              name={"maximumUsagesPerCustomer"}
              onChange={handleChange}
              onClick={clearValueFieldOnClick}
              helperText={form.errors.maximumUsagesPerCustomer}
              error={!!form.errors.maximumUsagesPerCustomer}
              value={form.values.maximumUsagesPerCustomer}
            />
          </FormControl>
        </Grid>

        {isDeal && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.notificationLabelWrapper}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <Typography
                  variant="h3"
                  className={classes.notificationLabel}
                  style={{
                    color: colors.grey.primary,
                  }}>
                  {dictionary.deals.maximumUsagesPerDayTitle}
                </Typography>

                <Typography
                  variant="h3"
                  style={{
                    color: colors.grey.primary,
                    display: "flex",
                  }}>
                  ({dictionary.deals.onlyForPlanetPromo})
                </Typography>

                {mainForm.form.values.isPlanetPromo === "0" && (
                  <OcietyTooltip
                    title={dictionary.deals.goBackToThePreviousStep}
                    placement="bottom-start">
                    <InfoIcon className={classes.infoIcon} />
                  </OcietyTooltip>
                )}
              </div>
            </div>

            <FormControl fullWidth>
              <TextField
                disabled={mainForm.form.values.isPlanetPromo === "0"}
                id="filled-maximumUsagesPerDay"
                name="maximumUsagesPerDay"
                variant="outlined"
                label={dictionary.deals.typeQuantityLabel}
                onChange={handleChange}
                onClick={clearValueFieldOnClick}
                className={classes.marginTopField}
                helperText={form.errors.maximumUsagesPerDay}
                error={!!form.errors.maximumUsagesPerDay}
                value={form.values.maximumUsagesPerDay}
                InputProps={{
                  startAdornment: (
                    <IconOctagramGreen
                      style={{ width: 24, height: 24, marginRight: 4 }}
                    />
                  ),
                }}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className={classes.notificationLabelWrapper}>
            <Typography
              variant="h3"
              className={classes.notificationLabel}
              style={{
                color: isQuantityUnlimited()
                  ? colors.grey.secondary
                  : colors.grey.primary,
              }}>
              {dictionary.deals.notificationTitle}
            </Typography>
            <OcietySwitch
              disabled={isQuantityUnlimited()}
              value={form.values.notifyHowManyLeft === "1"}
              checked={form.values.notifyHowManyLeft === "1"}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => {
                handleSwitchChange(checked);
              }}
            />
          </div>

          <FormControl fullWidth style={{ paddingTop: 9 }}>
            <TextField
              disabled={isQuantityUnlimited() || isDisableNotifyLeft()}
              id="filled-number"
              name="notifyHowManyLeftCount"
              variant="outlined"
              label={dictionary.deals.typeQuantityLabel}
              onChange={handleChange}
              onClick={clearValueFieldOnClick}
              className={classes.marginTopField}
              helperText={form.errors.notifyHowManyLeftCount}
              error={!!form.errors.notifyHowManyLeftCount}
              value={form.values.notifyHowManyLeftCount}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </FormControl>
        </Grid>
        <Grid item lg={10} md={10} sm={12} xs={12}>
          <div
            className={clsx(
              classes.notificationLabelWrapper,
              classes.targetAudienceTitle
            )}>
            <Typography variant={"h3"}>
              {dictionary.deals.createDealForm.selectAgeGroup}
            </Typography>
            <OcietyTooltip
              title={dictionary.deals.createDealForm.selectAgeGroupInfo}
              placement="bottom-start">
              <InfoIcon className={classes.infoIcon} />
            </OcietyTooltip>
          </div>
          <FormControlLabel
            label={dictionary.deals.createDealForm.allAgeGroupLabel}
            classes={{ label: classes.ageGroupLabels }}
            control={
              <OcietyCheckbox
                defaultChecked
                checked={ageGroupId.length === ageGroupOptions.length}
                onChange={handleChangeAllAgeGroups}
              />
            }
          />
          <FormGroup className={classes.formControlAge}>
            {ageGroupOptions.map((ageGroup) => (
              <FormControlLabel
                key={ageGroup.value}
                onChange={handleChangeAgeGroup}
                control={
                  <OcietyCheckbox
                    checked={ageGroupId.includes(Number(ageGroup.value))}
                  />
                }
                label={ageGroup.label}
                value={ageGroup.value}
                classes={{ label: classes.ageGroupLabels }}
                className={classes.formControlAgeLabel}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default AdvancedInfoPromotionStep;
