import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type ClassNames =
  | "root"
  | "rowItem"
  | "rowItemFirst"
  | "rowItemLast"
  | "headerRowItemText";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(3),
      },
      headerRowItemText: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
      },
      rowItem: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 1),
      },
      rowItemFirst: {
        paddingLeft: theme.spacing(5),
      },
      rowItemLast: {
        padding: theme.spacing(1, 5, 1, 1),
        display: "flex",
        justifyContent: "flex-end",
      },
    })
);
