import { http } from "../../config/api";
import { TransactionGetParams } from "./models";

const endpointsTransactions = {
  listAndCountAll: () => `/transaction-history/all`,
  getFiltersOptions: () => "/transaction-history/filter-options",
};

const getAllTransactionsListAndCount = ({
  limit,
  skip,
  userEmail,
  referralCode,
  promoCodeName,
  discountName,
  venueName,
  createdAt,
  fiatAmount,
  couponAmount,
  creditsAmount,
  fee,
  discountRebatedPrice,
  type,
  kind,
  order,
  sortedColumn,
}: TransactionGetParams) =>
  http
    .get(endpointsTransactions.listAndCountAll(), {
      params: {
        limit,
        skip,
        userEmail,
        referralCode,
        promoCodeName,
        discountName,
        venueName,
        createdAt,
        fiatAmount,
        couponAmount,
        creditsAmount,
        fee,
        discountRebatedPrice,
        type,
        kind,
        order,
        sortedColumn,
      },
    })
    .then((response) => response.data);

const getFiltersOption = () =>
  http
    .get(endpointsTransactions.getFiltersOptions())
    .then((response) => response.data);

export default {
  transactions: {
    listAndCountAll: getAllTransactionsListAndCount,
    mapAllFilters: getFiltersOption,
  },
};
