import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames = "root" | "count" | "activeLabel";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: "bold",
      },
      count: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(20),
        color: colors.dark.additional,
      },
      activeLabel: {
        color: colors.purple.additional,
      },
    })
);
