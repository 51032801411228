import { DialogContent } from "@material-ui/core";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { AgeGroup } from "../../../../../../../common/components/AgeGroupSelect/api";
import { AlertContext } from "../../../../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../../../../common/components/LoadingIndicator";
import StepsIndicator from "../../../../../../../common/components/StepsIndicator";
import { useCurrentVenueId } from "../../../../../../../common/hooks/useCurrentVenueId";
import { useIsDiscountAdmin } from "../../../../../../../common/hooks/useIsDiscountAdmin";
import { useRestApi } from "../../../../../../../common/hooks/useRestApi";
import { FileUrlWithId } from "../../../../../../../common/models";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { setFormValue as reduxSetFormValue } from "../../../../../../../store/forms/actions";
import { GET_MANAGER_PROMOTION } from "../../../../../../ManagerPromotions/Promotions/api";
import IPromotionCategory from "../../../../../types/interfaces/IPromotionCategory.interface";
import IPromotionDietaryRestriction from "../../../../../types/interfaces/IPromotionDietaryRestriction.interface";
import { PromotionLimitType, PromotionType } from "../../../../enums";
import { IPromotion } from "../../models/IPromotion";
import { PromotionFormDialogContext } from "../../PromotionFormDialogContextProvider";
import { PromotionKind } from "../PromotionFormDialogActions/PromotionKind.enum";
import AdvancedInfoPromotionStep from "./components/AdvancedInfoPromotionStep";
import BasicInfoPromotionStep from "./components/BasicInfoPromotionStep";
import { GET_IS_ALLOWED_FUll_ORDER_PROMOTION_TYPE } from "./components/BasicInfoPromotionStep/api";
import MainInfoPromotionStep from "./components/MainInfoPromotionStep";
import {
  getPromotionsCategories,
  getPromotionsSubCategories,
  PROMOTION_DIETARY_RESTRICTIONS,
} from "./components/MainInfoPromotionStep/api";
import { SelectDealKindStep } from "./components/SelectDealKindStep";
import Props from "./Props";
import { useStyles } from "./styles";
import {
  daysHoursToWorkingDays,
  transformDineroAmountToDecimalString,
} from "./utils";

const PromotionFormDialogContent: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    promotionId: propsPromotionId,
    setDietaryRestrictions,
    dietaryRestrictions,
    ageGroupId,
    setAgeGroupId,
  } = props;
  const {
    currentStep,
    basicForm,
    mainForm,
    advancedForm,
    setPromotionId,
    loading,
    setLoading,
    setDaysHours,
    setCurrentStep,
  } = useContext(PromotionFormDialogContext);
  const { showAlert } = useContext(AlertContext);
  const discountAdmin = useIsDiscountAdmin();
  const venueId = useCurrentVenueId();
  const dispatch = useDispatch();

  useEffect(() => {
    if (propsPromotionId) {
      setCurrentStep(2);
    }
  }, [propsPromotionId, setCurrentStep]);

  const [promotionDietaryRestrictions, setPromotionDietaryRestrictions] =
    useState<Array<IPromotionDietaryRestriction>>([]);
  const [promotionCategories, setPromotionCategories] = useState<
    Array<IPromotionCategory>
  >([]);
  const [promotionSubCategories, setPromotionSubCategories] = useState<
    Array<IPromotionCategory>
  >([]);

  const [, getPromotionData] = useRestApi<IPromotion>(
    "promotions/" + (propsPromotionId ? propsPromotionId : 0),
    "GET"
  );
  const [, getManagerPromotionData] = useRestApi<IPromotion>(
    GET_MANAGER_PROMOTION(propsPromotionId ? propsPromotionId : 0),
    "GET"
  );
  const [{ data: isAllowedFullOrderResponse }, getIsAllowedFullOrderType] =
    useRestApi<{
      allowed: boolean;
    }>(GET_IS_ALLOWED_FUll_ORDER_PROMOTION_TYPE(venueId), "GET");
  const [, getPromotionsDietaryRestrictions] = useRestApi<
    any,
    IPromotionDietaryRestriction[]
  >(PROMOTION_DIETARY_RESTRICTIONS, "GET");

  const { setFormValues: basicSetFormValues } = basicForm;
  const { setFormValues: mainSetFormValues, setFormValue: mainSetFormValue } =
    mainForm;
  const { setFormValues: advancedFormSetFormValues } = advancedForm;
  const currentPromotionType = mainForm.form.values.type as PromotionType;
  const { setFormValue } = mainForm;

  const getCategoryValue = () => {
    if (mainForm.form.values.categoryId) {
      return mainForm.form.values.categoryId;
    }
  };

  const currentCategoryId = getCategoryValue();

  useEffect(() => {
    getPromotionsDietaryRestrictions()
      .then((restrictions) => {
        if (restrictions.length) {
          setPromotionDietaryRestrictions(restrictions);
        }
      })
      .catch(() => {
        console.log("ERROR");
      });
  }, [getPromotionsDietaryRestrictions]);

  useEffect(() => {
    getIsAllowedFullOrderType().catch((e) => console.error(e));
  }, [getIsAllowedFullOrderType]);

  useEffect(() => {
    setPromotionSubCategories([]);
    getPromotionsCategories(currentPromotionType)
      .then((categories) => {
        if (categories.length) {
          setPromotionCategories(categories);
        }
      })
      .catch(() => {
        console.log("ERROR");
      });
  }, [setPromotionSubCategories, currentPromotionType, setFormValue]);

  useEffect(() => {
    setPromotionSubCategories([]);
    if (
      currentCategoryId &&
      mainForm.form.values.kind !== PromotionKind.VOUCHER
    ) {
      getPromotionsSubCategories(Number(currentCategoryId))
        .then((categories: IPromotionCategory[]) => {
          if (categories.length) {
            setPromotionSubCategories(categories);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [currentCategoryId, currentPromotionType, mainForm.form.values.kind]);

  useEffect(() => {
    if (propsPromotionId) {
      setLoading(true);
      setPromotionId(propsPromotionId);
      const getPromotion = discountAdmin
        ? getManagerPromotionData()
        : getPromotionData();
      getPromotion
        .then((promotion: IPromotion | undefined) => {
          if (promotion) {
            if (
              promotion.userToDiscounts &&
              promotion.userToDiscounts.length > 0
            ) {
              showAlert(
                dictionary.deals.createDealForm.msgErrorPurchasedDeal,
                "error"
              );
            }
            let categoryId = promotion.categoryId
              ? String(promotion.categoryId)
              : "";
            if (categoryId === "" && promotion.kind === PromotionKind.VOUCHER) {
              categoryId = String(promotion.mainCategoryId);
            }
            const sellingPrice = transformDineroAmountToDecimalString(
              promotion.sellingPrice
            );
            const rebatedPrice = transformDineroAmountToDecimalString(
              promotion.rebatedPrice
            );
            mainSetFormValues({
              name: promotion.name,
              type: (promotion.type as PromotionType) || PromotionType.FOOD,
              categoryId,
              subCategoryId: promotion.subCategoryId
                ? String(promotion.subCategoryId)
                : "",
              otherSubcategoryName: promotion.otherSubcategoryName
                ? String(promotion.otherSubcategoryName)
                : "",
              start: promotion.start
                ? moment.utc(promotion.start).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD"),
              end: promotion.end
                ? moment.utc(promotion.end).format("YYYY-MM-DD")
                : moment().add(1, "days").format("YYYY-MM-DD"),
              description: promotion.description || "",
              limitType: promotion.itemsLimit || PromotionLimitType.SINGLE,
              limitPercent: promotion.percent ? String(promotion.percent) : "0",
              limitMaxAmount: promotion.maxAmount
                ? String(promotion.maxAmount)
                : "0",
              limitPrice: promotion.sellingPrice ? sellingPrice : "0",
              limitItemsName: promotion.itemsName
                ? String(promotion.itemsName)
                : "",
              rebatedPrice: promotion.rebatedPrice ? rebatedPrice : "",
              dietaryRestrictions: promotion.dietaryRestrictions
                ? promotion.dietaryRestrictions.map((item) => item.id).join()
                : "",
              restrictions: promotion.restrictions,
              kind: promotion.kind ? promotion.kind : PromotionKind.DEAL,
              isPlanetPromo: promotion.isPlanetPromo ? "1" : "0",
            });
            setDietaryRestrictions(promotion.dietaryRestrictions);
            advancedFormSetFormValues({
              quantity: promotion.discountQuantity
                ? String(promotion.discountQuantity)
                : "0",
              notifyHowManyLeft: promotion.notifyHowManyLeft ? "1" : "0",
              notifyHowManyLeftCount: promotion.notifyHowManyLeftCount
                ? String(promotion.notifyHowManyLeftCount)
                : "0",
              maximumUsagesPerCustomer: promotion.maximumUsagesPerCustomer
                ? String(promotion.maximumUsagesPerCustomer)
                : "1",
              maximumUsagesPerDay: promotion.maximumUsagesPerDay
                ? String(promotion.maximumUsagesPerDay)
                : "0",
              restrictMinimumAge: promotion.restrictMinimumAge
                ? String(promotion.restrictMinimumAge)
                : "0",
              promotionCode: promotion.promoCode
                ? String(promotion.promoCode)
                : "",
              isQuantityUnlimited: promotion.isQuantityUnlimited ? "1" : "0",
              ageGroupId: promotion.ageGroup
                ? promotion.ageGroup.map((item: AgeGroup) => item.id).join()
                : "",
            });
            setAgeGroupId(
              promotion.ageGroup
                ? promotion.ageGroup.map((item: AgeGroup) => item.id)
                : []
            );
            if (promotion.daysHours) {
              const workingDays = daysHoursToWorkingDays(promotion.daysHours);
              dispatch(
                reduxSetFormValue("workingDays", "workingDays", workingDays)
              );
            }

            setDaysHours(promotion.daysHours);
            basicSetFormValues({
              id: String(promotion.id) || "",
              promotionPhotos: JSON.stringify(
                promotion.photos.map(
                  ({ id, fileUrl, isMainPhoto }): FileUrlWithId => ({
                    id,
                    url: fileUrl,
                    isMainPhoto,
                  })
                )
              ),
              promotionVideos: JSON.stringify(
                promotion.videos.map(
                  ({ id, fileUrl }): FileUrlWithId => ({ id, url: fileUrl })
                )
              ),
              mainPhotoId: promotion.mainPhoto
                ? JSON.stringify(promotion.mainPhoto.id)
                : "",
            });
          }
        })
        .catch((e) => {
          console.log("e -->>> ", e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    dispatch,
    setPromotionId,
    discountAdmin,
    mainSetFormValue,
    setDaysHours,
    setAgeGroupId,
    propsPromotionId,
    setLoading,
    getPromotionData,
    getManagerPromotionData,
    basicSetFormValues,
    mainSetFormValues,
    advancedFormSetFormValues,
    setDietaryRestrictions,
    showAlert,
  ]);

  return (
    <DialogContent className={classes.dialogContent}>
      {currentStep === 1 ? <SelectDealKindStep /> : null}
      {currentStep !== 1 ? (
        <>
          {loading && <LoadingIndicator withMask />}
          <StepsIndicator
            currentStep={currentStep - 1}
            stepsNames={["", "", ""]}
          />
          {currentStep === 2 ? (
            <MainInfoPromotionStep
              dietaryRestrictions={dietaryRestrictions}
              setDietaryRestrictions={setDietaryRestrictions}
              isAllowedFullOrderResponse={isAllowedFullOrderResponse?.allowed}
              promotionDietaryRestrictions={promotionDietaryRestrictions}
              promotionCategories={promotionCategories}
              promotionSubCategories={promotionSubCategories}
            />
          ) : currentStep === 3 ? (
            <AdvancedInfoPromotionStep
              ageGroupId={ageGroupId}
              setAgeGroupId={setAgeGroupId}
            />
          ) : (
            <>
              <BasicInfoPromotionStep
                promotionDietaryRestrictions={promotionDietaryRestrictions}
                promotionCategories={promotionCategories}
                promotionSubCategories={promotionSubCategories}
              />
            </>
          )}
        </>
      ) : null}
    </DialogContent>
  );
};
export default PromotionFormDialogContent;
