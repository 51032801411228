import { Grid, Paper } from "@material-ui/core";
import React, { FC } from "react";

import { useStyles } from "../../styles";
import VenueCommonDataForm from "./components/VenueCommonDataForm";
import Props from "./Props";

const VenueCommonData: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { holidays, setHolidays } = props;

  return (
    <Paper className={classes.section}>
      <Grid container spacing={3}>
        <Grid item lg={12} xl={8}>
          <VenueCommonDataForm holidays={holidays} setHolidays={setHolidays} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default VenueCommonData;
