import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core/SvgIcon/SvgIcon";
import Assignment from "@material-ui/icons/Assignment";
import LocationIcon from "@material-ui/icons/LocationSearching";
import SettingsIcon from "@material-ui/icons/Settings";

import { PRIVACY_POLICY_URL } from "../../../../config";

export interface Page {
  title: string;
  href: string;
  icon: OverridableComponent<SvgIconTypeMap>;
}

const pages: Page[] = [
  {
    title: "My Venues",
    href: "/my-venues",
    icon: LocationIcon,
  },
  {
    title: "All Venues",
    href: "/businesses",
    icon: LocationIcon,
  },
  {
    title: "TermsAndConditions",
    href: "/terms-and-conditions",
    icon: Assignment,
  },
  {
    title: "Privacy Policy",
    href: PRIVACY_POLICY_URL,
    icon: Assignment,
  },
  {
    title: "Settings",
    href: "/settings",
    icon: SettingsIcon,
  },
];

export default pages;
