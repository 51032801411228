import { requiredTextValidationRulesDefault } from "../../../../../config/validation";
export interface IVenueAdminMainFormSchema {
  firstName: Record<string, unknown>;
  email: Record<string, unknown>;
  phone: Record<string, unknown>;
  birthday: Record<string, unknown>;
  timezone: Record<string, unknown>;
  country: Record<string, unknown>;
  city: Record<string, unknown>;
}

export const VenueAdminMainFormSchema: IVenueAdminMainFormSchema = {
  firstName: {
    ...requiredTextValidationRulesDefault,
    length: {
      maximum: 64,
      minimum: 2,
    },
  },
  email: {
    ...requiredTextValidationRulesDefault,
    length: {
      maximum: 128,
      minimum: 6,
    },
  },
  phone: {
    ...requiredTextValidationRulesDefault,
    length: {
      maximum: 15,
      minimum: 10,
    },
  },
  birthday: { ...requiredTextValidationRulesDefault },
  timezone: { ...requiredTextValidationRulesDefault },
  country: { ...requiredTextValidationRulesDefault },
  city: { ...requiredTextValidationRulesDefault },
};
