import dictionary from "../../../../i18n/en_US/dictionary";

export type VenueBoostPrices = 9 | 19 | 29;
export enum VenueBoostSize {
  BASIC = 100,
  STANDARD = 250,
  PRO = 1000,
}

export function getVenueBoostPriceLabel(
  price: VenueBoostPrices,
  usagesLimit: number | null
) {
  let size: VenueBoostSize;

  switch (price) {
    case 9: {
      size = usagesLimit || VenueBoostSize.BASIC;
      break;
    }
    case 19: {
      size = usagesLimit || VenueBoostSize.STANDARD;
      break;
    }
    case 29: {
      size = usagesLimit || VenueBoostSize.PRO;
      break;
    }
  }

  return dictionary.plan.venueBoostOptionLabel(price, size);
}
