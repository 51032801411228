import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../../../i18n/en_US/dictionary";
import { useStyles } from "./styles";

const DialogListEmpty: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        src="/images/backgrounds/chat-list-empty.png"
        alt="chat-list-empty"
      />
      <Typography className={classes.title} component={"span"} variant={"h1"}>
        {dictionary.messages.emptyTitle}
      </Typography>
      <Typography variant={"body2"}>
        {dictionary.messages.emptyMessage}
      </Typography>
    </div>
  );
};

export default DialogListEmpty;
