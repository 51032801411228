import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../common/models";

export type ClassNames =
  | "root"
  | "formControl"
  | "actionsToolbar"
  | "appModeLabel"
  | "actionsToolbarConnected"
  | "connectedLabel";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        position: "relative",
      },
      formControl: {
        marginTop: 30,
      },
      appModeLabel: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        fontWeight: "bold",
      },
      actionsToolbar: {
        marginTop: theme.spacing(2.5),
        display: "flex",
        flexDirection: "column",
        width: "auto",
      },
      actionsToolbarConnected: {
        display: "flex",
        alignItems: "center",
      },
      connectedLabel: {
        fontWeight: "bold",
        marginLeft: theme.spacing(3),
      },
    })
);
