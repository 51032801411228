import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "dropdownContent"
  | "itemLabel"
  | "iconRoot"
  | "itemRoot";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        position: "relative",
      },
      dropdownContent: {
        zIndex: 10,
        borderRadius: 4,
        backgroundColor: colors.grey.primary,
        position: "absolute",
        left: 0,
        top: "100%",
        minWidth: 138,
      },
      itemLabel: {
        color: colors.white.primary,
      },
      iconRoot: {
        minWidth: 30,
      },
      itemRoot: {
        padding: theme.spacing(0, 1.25, 0, 0.5),
      },
    })
);
