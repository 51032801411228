import { Badge, Grid, Icon, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SignalCellularAltRoundedIcon from "@material-ui/icons/SignalCellularAltRounded";
import clsx from "clsx";
import moment from "moment";
import React, { FC, useCallback, useContext, useMemo } from "react";

import { FileUrlWithId } from "../../../../../../../../../../../common/models";
import { ReactComponent as ChevronIcon } from "../../../../../../../../../../../common/svg/icons/chevron.svg";
import { ReactComponent as IconOctagramGreen } from "../../../../../../../../../../../common/svg/icons/octagram-green.svg";
import dictionary from "../../../../../../../../../../../i18n/en_US/dictionary";
import PromotionImage from "../../../../../../../../../components/PromotionImage";
import { PROMOTION_IMAGES } from "../../../../../../../../../components/PromotionImage/index";
import IPromotionDietaryRestriction from "../../../../../../../../../types/interfaces/IPromotionDietaryRestriction.interface";
import { IDayHours } from "../../../../../../models/IDayHours";
import { PromotionFormDialogContext } from "../../../../../../PromotionFormDialogContextProvider";
import { PromotionKind } from "../../../../../PromotionFormDialogActions/PromotionKind.enum";
import { DEAL_MOBILE_PREVIEW } from "./dealMobilePreview";
import ArrowLeftSvg from "./icons/arrow-left.svg";
import HeartSvg from "./icons/heart.svg";
import iPhoneFrameSvg from "./icons/iPhoneFrame.svg";
import MoreSvg from "./icons/more.svg";
import QuestionMarkSvg from "./icons/question-mark.svg";
import ScrollHintSvg from "./icons/scroll.svg";
import tableclothSvg from "./icons/tablecloth.svg";

const useStyles = makeStyles(() => ({
  previewContainer: {
    backgroundImage: `url(${tableclothSvg})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
    padding: 16,
    paddingTop: 24,
    paddingBottom: 24,
  },
  iPhone: {
    borderRadius: 48,
    backgroundColor: "#fff",
    backgroundImage: `url(${iPhoneFrameSvg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "375px",
    height: "755px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    margin: "auto",
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 32,
    fontFamily: "Nunito Sans",
  },
  contentWrapper: {
    height: "100%",
    width: "100%",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    paddingBottom: 35,
  },
  timeWifiStatus: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  imageIcon: {
    height: "100%",
  },
  iconRoot: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerControls: {
    marginTop: 16,
    display: "flex",
    fontSize: 11,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heartIcon: {
    width: 24,
    height: 24,
  },
  moreIcon: {
    width: 24,
    height: 24,
  },
  arrowLeftIcon: {
    width: 16,
    height: 16,
  },
  heartAndMoreIcons: {
    display: "flex",
    flexDirection: "row",
    width: 54,
    justifyContent: "space-between",
  },
  dealImagePlaceholderWrap: {
    marginTop: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    overflow: "hidden",
  },
  dealImagePlaceholder: {
    borderRadius: "5px 5px 0px 0px",
    maxWidth: "323px",
  },
  dealPriceWrap: {
    color: "#fff",
  },
  priceBadge: {
    background: "linear-gradient(185.35deg, #E10028 2.28%, #E15255 95.72%)",
    boxShadow: "0px 2px 13px 2px #FF003D66",
    borderRadius: 4,
    padding: "4px 8px",
    marginLeft: 18,
    transform: "translateY(-18px)",
  },
  dealTitle: {
    fontSize: 36,
    fontWeight: 700,
    marginLeft: 12,
  },
  dayHoursWrap: {
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  dayHourDiv: {
    display: "flex",
    flexDirection: "column",
    background: "#F2F4F7",
    margin: 8,
    padding: 8,
    marginTop: 18,
    minWidth: 100,
    fontSize: 13,
  },
  todayWeekDayBadge: {
    background: "#3B424E",
    color: "#fff",
    transform: "translate(37px, -22px)",
    fontSize: "12px",
    borderRadius: "4px",
    padding: "4px",
    height: "23px",
    width: "max-content",
  },
  todayWeekDayContent: {
    transform: "translateY(-22px)",
  },
  dealSubtitle: { color: "#9BA2AE", marginLeft: 12 },
  timeText: {
    color: "#24282E",
    fontWeight: 700,
  },
  expiresOnWrap: {
    marginTop: 4,
    marginLeft: 12,
    fontSize: 13,
  },
  expOnDateText: {
    fontWeight: 700,
  },
  dietaryRestrictionsWrap: {
    marginLeft: 12,
    marginRight: 12,
    marginTop: 32,
    display: "flex",
    flexDirection: "column",
  },
  dietaryRestrictionsHeader: {
    display: "flex",
    flexDirection: "row",
  },
  questionMarkIconWrap: {
    marginLeft: 8,
  },
  questionMarkIcon: {
    width: 16,
    height: 16,
  },
  dietaryRestrBadges: {
    display: "flex",
    flexDirection: "row",
  },
  dietaryRestrBadge: {
    background: "#F2F4F7",
    marginTop: 8,
    marginRight: 8,
    padding: 8,
    fontWeight: 700,
    fontSize: 12,
  },
  descriptionWrap: {
    marginTop: 32,
    marginLeft: 12,
    marginRight: 12,
  },
  buttonBuyDeal: {
    background: "linear-gradient(45deg, #FD5052 0%, #DD1534 100%)",
    padding: "10.208px 13.6107px",
    borderRadius: 3.40267,
    textAlign: "center",
    margin: "0 14px",
  },
  descrRestrTextWrap: {
    fontSize: 13,
  },
  borderLine: {
    height: 3.5,
    marginTop: 17,
    marginBottom: 17,
    background:
      "linear-gradient(90.01deg, #F8F8F8 -2.21%, rgba(248, 248, 248, 0) 107.79%)",
  },
  iconOctagramGreenWrapper: {
    minHeight: 20,
    minWidth: 20,
    marginRight: 10,
    alignSelf: "baseline",
  },
  chevronIconWrapper: {
    alignSelf: "center",
    transform: "rotate(-90deg)",
    marginRight: -10,
    marginLeft: 15,
  },
  planetPromoBlock: {
    display: "flex",
    backgroundColor: "#F5FBEE",
    borderRadius: 5,
    padding: 14,
  },
  scrollHintWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontWeight: 700,
    transform: "translateY(-80px)",
  },
  scrollHintIcon: {},
  scrollIconText: {
    color: "#F44E4E",
  },
  descrRestrMore: {
    marginRight: "14px",
    fontWeight: 700,
  },
  descrRestrMoreWrap: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  descrRestrText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: "38px",
  },
  mileVenueNameSeparator: {
    color: "rgb(208 211 217)",
  },
  originalPriceText: {
    fontWeight: 700,
    textDecoration: "line-through",
    color: "#FFFFFF80",
    marginLeft: 4,
  },
}));

const weekdayIndexMap: { [day: string]: number } = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

function sortDayHoursFromToday(dayHours: IDayHours[]): IDayHours[] {
  // Get the index of today's weekday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const todayIndex = moment().weekday();

  // Sort the weekdays array based on the distance from today's index
  dayHours.sort((a, b) => {
    const aIndex = (weekdayIndexMap[a.day] - todayIndex + 7) % 7;
    const bIndex = (weekdayIndexMap[b.day] - todayIndex + 7) % 7;
    return aIndex - bIndex;
  });

  return dayHours;
}

function formatTimeString(time: string): string {
  const formattedTime = moment(time, "HH:mm:ss").format("hh:mm A");
  return formattedTime;
}

function formatDate(date: string): string {
  const formattedDate = moment(date, "YYYY-MM-DD").format("MMM Do");
  return formattedDate;
}

interface WeekDayHoursProps {
  dayHours: IDayHours;
  isToday: boolean;
}

export const WeekDayHours: FC<WeekDayHoursProps> = (props) => {
  const { dayHours, isToday } = props;
  const classes = useStyles();

  return (
    <div key={dayHours.id} className={classes.dayHourDiv}>
      {isToday && (
        <div className={classes.todayWeekDayBadge}>
          <span>Today</span>
        </div>
      )}
      <div className={isToday ? classes.todayWeekDayContent : ""}>
        <p>{dayHours.day.slice(0, 3)}</p>
        {dayHours.isActive ? (
          <>
            <p className={classes.timeText}>
              {dayHours.start ? formatTimeString(dayHours.start) : ""} -{" "}
            </p>
            <p className={classes.timeText}>
              {dayHours.end ? formatTimeString(dayHours.end) : ""}
            </p>
          </>
        ) : (
          <p className={classes.timeText}>-</p>
        )}
      </div>
    </div>
  );
};

const generateDealName = ({
  kind,
  sellingPrice,
  originalPrice,
  category,
  subCategory,
  offPercent,
  title,
  productName,
}: {
  kind: PromotionKind;
  sellingPrice: string;
  originalPrice?: string;
  category: string;
  subCategory?: string;
  offPercent?: string;
  title?: string;
  productName?: string;
}) => {
  if (kind === PromotionKind.DEAL || kind === PromotionKind.GIVEAWAY) {
    return `${offPercent}% off ${productName}`;
  }
  if (kind === PromotionKind.SPECIAL) {
    return title;
  }
  if (kind === PromotionKind.VOUCHER) {
    return `Pay $${Number(sellingPrice)?.toFixed(2)}, Get $${Number(
      originalPrice
    )?.toFixed(2)} off ${category}`;
  }
};

interface Props {
  promotionDietaryRestrictions: IPromotionDietaryRestriction[];
  endDate: string;
  selectedDietaryRestrictions: string;
  description: string;
  restrictions: string;
  daysHours: IDayHours[];
  photosWithIds: FileUrlWithId[];
  currentVenueName: string;
  kind: PromotionKind;
  sellingPrice: string;
  originalPrice: string;
  category: string;
  subCategory?: string;
  offPercent?: string;
  title?: string;
  mainCategoryName?: string;
  productName?: string;
}

export const IPhoneMobilePreview: FC<Props> = ({
  promotionDietaryRestrictions,
  endDate,
  selectedDietaryRestrictions,
  description,
  restrictions,
  daysHours,
  photosWithIds,
  currentVenueName,
  category,
  subCategory,
  kind,
  sellingPrice,
  originalPrice,
  offPercent,
  title,
  mainCategoryName,
  productName,
}) => {
  const classes = useStyles();

  const { mainForm } = useContext(PromotionFormDialogContext);
  const isPlanetPromo = useMemo(
    () => mainForm.form.values.isPlanetPromo === "1",
    [mainForm.form.values.isPlanetPromo]
  );

  const PlanetPromo = useCallback(
    () => (
      <>
        <div className={classes.borderLine} />
        <div className={classes.planetPromoBlock}>
          <div className={classes.iconOctagramGreenWrapper}>
            <IconOctagramGreen
              style={{ width: 20, height: 20, marginRight: 12 }}
            />
          </div>
          <div>
            <Typography
              style={{
                color: "#669F2A",
                fontSize: 12,
                lineHeight: 17,
                fontWeight: 700,
              }}>
              {dictionary.deals.createDealForm.mobilePreview.planetPromo}
            </Typography>
            <Typography
              style={{ color: "#669F2A", fontSize: 12, lineHeight: 17 }}>
              {dictionary.deals.createDealForm.mobilePreview.planetPromoDetails}
            </Typography>
          </div>
          <div className={classes.chevronIconWrapper}>
            <ChevronIcon style={{ width: 20, height: 20, color: "#669F2A" }} />
          </div>
        </div>
        <div className={classes.borderLine} />
      </>
    ),
    [
      classes.borderLine,
      classes.chevronIconWrapper,
      classes.iconOctagramGreenWrapper,
      classes.planetPromoBlock,
    ]
  );

  const daysHoursWithMissingDays = useMemo(() => {
    return Object.keys(weekdayIndexMap).map((day) => {
      const dayHours = daysHours.find((dh) => dh.day === day);
      return dayHours || { day };
    });
  }, [daysHours]);

  const sortedDayHours = useMemo(() => {
    return sortDayHoursFromToday(daysHoursWithMissingDays as IDayHours[]);
  }, [daysHoursWithMissingDays]);

  const dealImg = photosWithIds.find((p) => p.isMainPhoto)?.url || "";

  return (
    <Grid container justify="center" className={classes.previewContainer}>
      <Grid item xs={12} md={8}>
        <div className={classes.iPhone}>
          <div className={classes.timeWifiStatus}>
            <Typography style={{ marginLeft: 16, fontWeight: 700 }}>
              09:41
            </Typography>
            <SignalCellularAltRoundedIcon style={{ marginRight: 20 }} />
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.headerControls}>
              <Icon classes={{ root: classes.iconRoot }}>
                <img
                  className={clsx(classes.imageIcon, classes.arrowLeftIcon)}
                  src={ArrowLeftSvg}
                  alt="right arrow"
                />
              </Icon>
              <div>
                <Typography style={{ fontWeight: 700 }}>
                  2.2 mi{" "}
                  <span className={classes.mileVenueNameSeparator}>|</span>{" "}
                  {currentVenueName}
                </Typography>
              </div>
              <div className={classes.heartAndMoreIcons}>
                <Icon classes={{ root: classes.iconRoot }}>
                  <img
                    className={clsx(classes.imageIcon, classes.heartIcon)}
                    src={HeartSvg}
                    alt="heart icon"
                  />
                </Icon>
                <Icon classes={{ root: classes.iconRoot }}>
                  <img
                    className={clsx(classes.imageIcon, classes.moreIcon)}
                    src={MoreSvg}
                    alt="more icon"
                  />
                </Icon>
              </div>
            </div>
            <div className={classes.dealImagePlaceholderWrap}>
              {dealImg !== "" ? (
                <img
                  className={classes.dealImagePlaceholder}
                  src={dealImg}
                  alt="deal placeholder"
                />
              ) : (
                <PromotionImage
                  categoryName={
                    mainCategoryName as keyof typeof PROMOTION_IMAGES
                  }
                  kind={kind}
                  image={""}
                  height={200}
                  width={450}
                  promoImages={DEAL_MOBILE_PREVIEW}
                />
              )}
            </div>
            <div className={classes.dealPriceWrap}>
              <Badge className={classes.priceBadge}>
                {kind !== PromotionKind.VOUCHER && (
                  <>
                    <Typography color="inherit" style={{ fontWeight: 700 }}>
                      {`$${Number(sellingPrice)?.toFixed(2)}`}
                    </Typography>
                    {originalPrice !== "" && originalPrice !== "0" && (
                      <Typography
                        color="inherit"
                        className={classes.originalPriceText}>
                        {`$${Number(originalPrice)?.toFixed(2)}`}
                      </Typography>
                    )}
                  </>
                )}
                {kind === PromotionKind.VOUCHER && (
                  <Typography color="inherit" style={{ fontWeight: 700 }}>
                    {`Pay $${Number(sellingPrice)?.toFixed(2)}, Get $${Number(
                      originalPrice
                    )?.toFixed(2)}`}
                  </Typography>
                )}
              </Badge>
            </div>
            <Typography className={classes.dealTitle} variant="h2">
              {generateDealName({
                kind,
                sellingPrice,
                originalPrice,
                category,
                subCategory,
                offPercent,
                title,
                productName,
              })}
            </Typography>
            <p className={classes.dealSubtitle}>
              <>
                {kind === PromotionKind.VOUCHER ? (
                  <>{subCategory}</>
                ) : (
                  <>
                    {category}
                    {subCategory !== "" ? ` | ${subCategory}` : ""}
                  </>
                )}
              </>
            </p>
            <div className={classes.dayHoursWrap}>
              {sortedDayHours.map((day) => (
                <WeekDayHours
                  key={day.id}
                  dayHours={day}
                  isToday={day.day === moment().format("dddd")}
                />
              ))}
            </div>
            <div className={classes.expiresOnWrap}>
              <span>
                {dictionary.deals.createDealForm.mobilePreview.expiresOn}
              </span>{" "}
              <span className={classes.expOnDateText}>
                {formatDate(endDate)}
              </span>
            </div>
            {selectedDietaryRestrictions === "" ? (
              <></>
            ) : (
              <div className={classes.dietaryRestrictionsWrap}>
                <div className={classes.dietaryRestrictionsHeader}>
                  <Typography variant="h3">
                    {
                      dictionary.deals.createDealForm.mobilePreview
                        .dietaryRestrictions
                    }
                  </Typography>
                  <div className={classes.questionMarkIconWrap}>
                    <Icon classes={{ root: classes.iconRoot }}>
                      <img
                        className={clsx(
                          classes.imageIcon,
                          classes.questionMarkIcon
                        )}
                        src={QuestionMarkSvg}
                        alt="question mark icon"
                      />
                    </Icon>
                  </div>
                </div>
                {typeof selectedDietaryRestrictions === "string" ? (
                  <div className={classes.dietaryRestrBadges}>
                    {selectedDietaryRestrictions.split(",").map((id) => {
                      const restriction = promotionDietaryRestrictions.find(
                        (dr) => dr.id === parseInt(id)
                      );
                      if (!restriction) {
                        return null;
                      }
                      return (
                        <Badge
                          key={restriction.id}
                          className={classes.dietaryRestrBadge}>
                          <p>{restriction.name}</p>
                        </Badge>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            )}
            {description === "" ? null : (
              <div className={classes.descriptionWrap}>
                <h4>
                  {dictionary.deals.createDealForm.mobilePreview.description}
                </h4>
                <div className={classes.descrRestrTextWrap}>
                  <p className={classes.descrRestrText}>{description}</p>
                  <div className={classes.descrRestrMoreWrap}>
                    <p className={classes.descrRestrMore}>
                      {dictionary.deals.createDealForm.mobilePreview.more}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {restrictions === "" ? null : (
              <div className={classes.descriptionWrap}>
                <h4>
                  {dictionary.deals.createDealForm.mobilePreview.exceptions}
                </h4>
                <div className={classes.descrRestrTextWrap}>
                  <p className={classes.descrRestrText}>{restrictions}</p>
                  <div className={classes.descrRestrMoreWrap}>
                    <p className={classes.descrRestrMore}>
                      {dictionary.deals.createDealForm.mobilePreview.more}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {isPlanetPromo && <PlanetPromo />}

            <div className={classes.buttonBuyDeal}>
              <Typography
                style={{
                  fontSize: 14,
                  color: "#fff",
                  fontWeight: 700,
                  lineHeight: 20,
                }}>
                {kind === PromotionKind.GIVEAWAY
                  ? dictionary.deals.createDealForm.mobilePreview.claim
                  : dictionary.deals.createDealForm.mobilePreview.buyDeal}
              </Typography>
            </div>
          </div>

          <div className={classes.scrollHintWrap}>
            <Icon classes={{ root: classes.iconRoot }}>
              <img
                className={clsx(classes.imageIcon, classes.scrollHintIcon)}
                src={ScrollHintSvg}
                alt="scroll hint"
              />
            </Icon>
            <p className={classes.scrollIconText}>
              {dictionary.deals.createDealForm.mobilePreview.scroll}
            </p>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
