import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";
import { colors } from "../../../../../../../../theme/colors";

export type ClassNames = "root" | "headerTitle" | "emailWrapper" | "helperTxt";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      headerTitle: {
        textAlign: "center",
        marginTop: 20,
      },
      emailWrapper: {
        marginTop: 45,
      },
      helperTxt: {
        color: colors.grey.helpText,
        marginTop: 30,
        marginBottom: 30,
      },
    })
);
