import { AnalyticsPeriod } from "../../common/models";
import { WekDaysShort } from "../../utils/dateTimeUtils";

export interface ScoreTotal {
  score: number;
}

export type PopularDaysData = {
  [key in WekDaysShort]: number;
};

export interface MostPopularPromotionData {
  id: number;
  name: string;
}

export interface MostPopularPromotionResponse {
  promotion: MostPopularPromotionData | null;
}

export interface PopularDaysResponseData {
  summary: PopularDaysData;
}

export const GET_VENUE_PROMOTIONS_USED_TOTAL = (
  venueId: number | string,
  period: AnalyticsPeriod
) => `promotions/${venueId}/used-total/${period}`;

export const GET_VENUE_PROMOTIONS_ACTIVE_TOTAL = (venueId: number | string) =>
  `promotions/${venueId}/active-total`;

export const GET_VENUE_RECOMMENDATIONS_TOTAL = (
  venueId: number | string,
  period: AnalyticsPeriod
) => `recommendations/${venueId}/total/${period}`;

export const GET_VENUE_REVIEWS_TOTAL = (
  venueId: number | string,
  period: AnalyticsPeriod
) => `reviews/${venueId}/total/${period}`;

export const GET_VENUE_MOST_ACTIVE_DAYS = (
  venueId: number | string,
  period: AnalyticsPeriod
) => `promotions/${venueId}/popular-days/${period}`;

export const GET_VENUE_PROMOTIONS_USAGE_SATISFACTION = (
  venueId: number | string,
  period: AnalyticsPeriod
) => `promotions/${venueId}/users-satisfaction/${period}`;

export const GET_VENUE_PROMOTIONS_MOST_POPULAR_PROMOTION = (
  venueId: number | string,
  period: AnalyticsPeriod
) => `promotions/${venueId}/most-popular/${period}`;
