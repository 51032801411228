import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { ChangeEvent, FC, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../../common/components/LoadingIndicator";
import TextMessage from "../../common/components/TextMessage";
import { useGlobalStyles } from "../../common/styles";
import dictionary from "../../i18n/en_US/dictionary";
import { setAndValidate, validateForm } from "../../store/forms/actions";
import { FormFieldKey } from "../../store/forms/types";
import { AppState } from "../../store/rootReducer";
import { getAllConfigs, resetToDefaults, saveConfig } from "./ducks/actions";
import { createSchema } from "./form";
import { Config, ConfigSection } from "./models";
import { useStyles } from "./styles";

const CoreSettings: FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { flexRowBetweenCenter, flexColumn, flexRowEndCenter } =
    useGlobalStyles();
  const dispatch = useDispatch();
  const { config, forms } = useSelector((state: AppState) => state);
  const { configSections, configsArray, loading, error, success } = config;
  const form = forms.coreSettings;
  const schema = createSchema(configsArray);

  useEffect(() => {
    dispatch(getAllConfigs());
  }, [dispatch]);

  const handleResetDefaults = () => {
    dispatch(resetToDefaults());
    dispatch(saveConfig());
  };

  const handleSaveConfig = () => {
    if (!dispatch(validateForm("coreSettings", schema))) {
      return;
    }

    dispatch(saveConfig());
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;
    dispatch(
      setAndValidate("coreSettings", key as FormFieldKey, value, schema)
    );
  };

  const renderAccordionContent = (sectionConfigs: Config[]): ReactNode[] => {
    return sectionConfigs.map((sectionConfig: Config) => (
      <div key={sectionConfig.label}>
        <Box className={flexRowBetweenCenter}>
          <Box className={flexColumn}>
            <TextField
              error={!!form.errors[sectionConfig.path]}
              helperText={form.errors[sectionConfig.path]}
              fullWidth
              onChange={handleChange}
              className={classes.textField}
              label={sectionConfig.label}
              name={sectionConfig.path}
              type={sectionConfig.inputType}
              required
              value={form.values[sectionConfig.path] || ""}
              variant="outlined"
            />
            <Typography>{sectionConfig.description}</Typography>
          </Box>
        </Box>
        <Divider className={classes.divider} />
      </div>
    ));
  };

  const renderAccordion = (): ReactNode[] => {
    return configSections.map((configSection: ConfigSection) => (
      <Accordion key={configSection.title}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography className={classes.heading}>
            {configSection.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.sectionContent}>
          {renderAccordionContent(configSection.content)}
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <div className={clsx(flexColumn, classes.root)}>
      <div className={classes.configSection}>
        <div className={classes.accordionWrapper}>
          {renderAccordion()}
          {loading && <LoadingIndicator withMask />}
        </div>
        {!!error && (
          <TextMessage className={classes.message} type="error">
            {error}
          </TextMessage>
        )}
        {!!success && (
          <TextMessage className={classes.message} type="success">
            {success}
          </TextMessage>
        )}
        <div className={clsx(flexRowEndCenter, classes.configActions)}>
          <Button
            className={classes.cancelButton}
            color="secondary"
            size="large"
            type="reset"
            variant="text"
            onClick={handleResetDefaults}>
            {dictionary.settings.resetChangesButton}
          </Button>
          <Button
            color="primary"
            size="large"
            disabled={!form.isValid}
            type="submit"
            variant="contained"
            onClick={handleSaveConfig}>
            {dictionary.settings.saveButton}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CoreSettings);
