import { useEffect, useRef } from "react";

function useBottomScrollListener(onBottomReached: () => void, offset = 0) {
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleScroll = (event: Event) => {
      const element = event.target as HTMLElement;
      if (
        element.scrollHeight - element.scrollTop <=
        element.clientHeight + offset
      ) {
        onBottomReached();
      }
    };

    let currentElement: HTMLElement | null = null;
    const timeout = setTimeout(() => {
      currentElement = ref.current;
      if (currentElement) {
        currentElement.addEventListener("scroll", handleScroll);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
      if (currentElement) {
        currentElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [onBottomReached, offset]);

  return ref;
}

export default useBottomScrollListener;
