import { DialogActions, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useContext, useState } from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../../../common/components/ButtonBlack";
import ButtonCancel from "../../../../../../common/components/ButtonCancel";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import api from "../../../../api";
import DtoCouponCode from "../../../../dtos/CouponCode.dto";
import { CreateCouponCode } from "../../../../models";
import { PromoCodesContext } from "../../../../providers";
import { DialogContext } from "../../DialogContextProvider";
import Props from "./Props";
import { useStyles } from "./styles";

const Actions: FC<Props> = (props: Props) => {
  const { updateListCoupon } = props;
  const { showAlert } = useContext(AlertContext);
  const { resetAndCloseForm, mainForm } = useContext(DialogContext);
  const { promoCodeId, isPreview } = useContext(PromoCodesContext);

  const [loading, setLoading] = useState<boolean>(false);

  const classes = useStyles();

  const sendCreateRequest = (): void => {
    const builtCouponCode: CreateCouponCode = DtoCouponCode.build(
      mainForm.form.values
    );

    setLoading(true);

    api.coupons
      .add(builtCouponCode)
      .then(async () => {
        await updateListCoupon();
        await resetAndCloseForm();
        showAlert(
          dictionary.couponManagement.createdCouponSuccessMessage,
          "success"
        );
      })
      .catch((e) => {
        if (e.response.data === "couponNameAlreadyExist") {
          return showAlert(
            dictionary.couponManagement.duplicateCouponErrorMessage,
            "error"
          );
        }
        if (e.response.data === "negativeValues") {
          return showAlert(
            dictionary.couponManagement.negativeValuesErrorMessage,
            "error"
          );
        }
        showAlert(
          dictionary.couponManagement.createdCouponErrorMessage,
          "error"
        );
      })
      .finally(() => setLoading(false));
  };

  const sendUpdateRequest = (couponId: number): void => {
    const updateBuiltCouponCode: CreateCouponCode = DtoCouponCode.buildUpdate({
      id: String(couponId),
      ...mainForm.form.values,
    });

    setLoading(true);

    api.coupons
      .update(updateBuiltCouponCode)
      .then(async () => {
        await updateListCoupon();
        await resetAndCloseForm();
        showAlert(
          dictionary.couponManagement.updatedCouponSuccessMessage,
          "success"
        );
      })
      .catch(() => {
        showAlert(
          dictionary.couponManagement.updatedCouponErrorMessage,
          "error"
        );
      })
      .finally(() => setLoading(false));
  };

  const handleSaveAction = () => {
    if (mainForm.validateForm()) {
      if (promoCodeId) sendUpdateRequest(promoCodeId);
      else sendCreateRequest();
    } else {
      showAlert(
        <div>
          {Object.values(mainForm.form.errors).map((error, key) => (
            <Typography key={key} style={{ color: "red" }}>
              {error}
            </Typography>
          ))}
        </div>,
        "error"
      );
    }
  };

  if (isPreview) return null;

  return (
    <DialogActions
      classes={{
        root: clsx(classes.dialogActions),
      }}>
      <div className={classes.actionFlexItem}>
        <ButtonCancel onClick={resetAndCloseForm}>
          {dictionary.general.cancel}
        </ButtonCancel>
        <ButtonBlack
          onClick={handleSaveAction}
          disabled={loading}
          className={classes.dialogPrimaryButtonAction}>
          {dictionary.general.save}
        </ButtonBlack>
      </div>
    </DialogActions>
  );
};

export default Actions;
