import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "content"
  | "noDataEmptyChartText"
  | "noDataEmptyChartTextFullWidth"
  | "noDataEmptyChartTitle";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      content: {
        display: "flex",
        flex: 1,
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      noDataEmptyChartText: {
        marginTop: 10,
        color: colors.grey.secondary,
        width: "40%",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        [theme.breakpoints.down("md")]: {
          width: "50%",
        },
      },
      noDataEmptyChartTitle: {
        marginTop: 10,
        textAlign: "center",
      },
      noDataEmptyChartTextFullWidth: {
        width: "100%",
      },
    })
);
