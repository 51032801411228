import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import ButtonSuccess from "../../../../common/components/ButtonSuccess";
import dictionary from "../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const MiniCartSuccessPurchase: FC<Props> = (props) => {
  const { onClose } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        src="/images/backgrounds/purchase-succeed.png"
        alt="purchase-succeed"
      />
      <Typography className={classes.title} variant={"h2"}>
        {dictionary.plan.purchaseSucceedTitle}
      </Typography>
      <Typography className={classes.message} variant={"body2"}>
        {dictionary.plan.purchaseSucceedMessage}
      </Typography>
      <ButtonSuccess className={classes.button} onClick={onClose}>
        {dictionary.plan.purchaseSucceedButtonPrimary}
      </ButtonSuccess>
    </div>
  );
};

export default MiniCartSuccessPurchase;
