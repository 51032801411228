import {
  CardActions,
  Chip,
  PropTypes,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import * as _ from "lodash";
import moment from "moment";
import React, { ChangeEvent, FC } from "react";

import { PromotionUsedStatus } from "../../models/enums";
import IPromotionUsages from "../../models/IPromotionUsages";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionsUsagesTable: FC<Props> = (props: Props) => {
  const {
    className,
    usedPromotions,
    totalCount,
    defaultRowsPerPage,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
  } = props;
  const classes = useStyles();

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    currentPage: number
  ) => {
    setPage(currentPage);
  };

  const printDateTime = (date: Date): string => {
    return moment(date).format("MM/DD/yyyy HH:mm:ss");
  };

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const printPOS = (posTag?: string) => {
    if (!posTag) {
      return "-";
    }
    return posTag.replace("pos", "");
  };
  const printStatus = (status: PromotionUsedStatus) => {
    const statusText = _.startCase(_.toLower(PromotionUsedStatus[status]));

    let color: PropTypes.Color = "default";

    if (status === PromotionUsedStatus.READY) {
      color = "primary";
    }

    if (status === PromotionUsedStatus.NEW) {
      color = "secondary";
    }

    return <Chip label={statusText} color={color} />;
  };

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.content}>
        <Table>
          <TableHead className={classes.thead}>
            <TableRow>
              <TableCell className={classes.th}>
                <Typography variant={"body2"} className={classes.heading}>
                  {"Promotion code"}
                </Typography>
              </TableCell>
              <TableCell className={classes.th}>
                <Typography variant={"body2"} className={classes.heading}>
                  {"Used date time"}
                </Typography>
              </TableCell>
              <TableCell className={classes.th}>
                <Typography variant={"body2"} className={classes.heading}>
                  {"Username"}
                </Typography>
              </TableCell>
              <TableCell className={classes.th}>
                <Typography variant={"body2"} className={classes.heading}>
                  {"POS system"}
                </Typography>
              </TableCell>
              <TableCell className={classes.th}>
                <Typography variant={"body2"} className={classes.heading}>
                  {"Status"}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tbody}>
            {usedPromotions.map((usedPromotion: IPromotionUsages) => (
              <TableRow
                className={classes.tableRow}
                hover
                key={usedPromotion.id}>
                <TableCell className={classes.td}>
                  <div className={classes.nameContainer}>
                    <Typography className={classes.venueName} variant="body1">
                      {usedPromotion.promoCode}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className={classes.td}>
                  <div className={classes.nameContainer}>
                    <Typography className={classes.venueName} variant="body1">
                      {printDateTime(usedPromotion.createdAt)}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className={classes.td}>
                  <div className={classes.nameContainer}>
                    <Typography className={classes.venueName} variant="body1">
                      {usedPromotion.userName}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className={classes.td}>
                  <div className={classes.nameContainer}>
                    <Typography className={classes.venueName} variant="body1">
                      {printPOS(usedPromotion.posTag)}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className={classes.td}>
                  <div className={classes.nameContainer}>
                    <Typography className={classes.venueName} variant="body1">
                      {printStatus(usedPromotion.status)}
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={defaultRowsPerPage}
          />
        </CardActions>
      </div>
    </div>
  );
};
export default PromotionsUsagesTable;
