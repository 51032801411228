import { Button, Typography } from "@material-ui/core";
import React, { FC, useCallback, useContext, useEffect } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import { useRestApi } from "../../hooks/useRestApi";
import { AdminNotification } from "../../models";
import { AlertContext } from "../Alert/AlertContextProvider";
import IconDropdown from "../IconDropdown";
import { DELETE_ADMIN_NOTIFICATIONS, GET_ADMIN_NOTIFICATIONS } from "./api";
import NotificationsDropdownItem from "./components/NotificationsDropdownItem";
import { useStyles } from "./styles";

const NotificationsDropdown: FC = () => {
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const [{ data: notificationsData }, getNotifications] = useRestApi<
    AdminNotification[]
  >(GET_ADMIN_NOTIFICATIONS, "GET");
  const [, deleteNotifications] = useRestApi<AdminNotification[]>(
    DELETE_ADMIN_NOTIFICATIONS,
    "DELETE"
  );
  const notifications = Array.isArray(notificationsData)
    ? notificationsData
    : [];
  const isEmpty = !notifications.length;
  const hasNewNotification = notifications.some(({ isNew }) => isNew);

  const fetchNotifications = useCallback(() => {
    getNotifications().catch((e) => console.error(e));
  }, [getNotifications]);

  useEffect(() => {
    fetchNotifications();

    // fetch notifications every minute
    // TODO redo using websockets
    const cron = setInterval(fetchNotifications, 1000 * 60);

    return () => {
      clearInterval(cron);
    };
  }, [fetchNotifications]);

  const renderNotifications = () => {
    return notifications.map((notification) => (
      <NotificationsDropdownItem
        key={notification.id}
        notification={notification}
      />
    ));
  };

  const clearNotifications = () => {
    deleteNotifications().catch(() => {
      showAlert(dictionary.notifications.clearError, "error");
    });
  };

  const renderEmptyNotifications = () => (
    <div className={classes.emptyContainer}>
      <Typography className={classes.emptyText}>
        {dictionary.notifications.emptyText}
      </Typography>
    </div>
  );

  return (
    <IconDropdown
      icon={"ring"}
      indicator={hasNewNotification}
      onOpen={fetchNotifications}
      onClose={fetchNotifications}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography className={classes.title}>
            {dictionary.notifications.dropdownTitle}
          </Typography>
          {!isEmpty && (
            <Button
              className={classes.clearButton}
              variant={"text"}
              onClick={clearNotifications}>
              {dictionary.notifications.clearButton}
            </Button>
          )}
        </div>
        <div className={classes.notificationsContainer}>
          {!isEmpty ? renderNotifications() : renderEmptyNotifications()}
        </div>
      </div>
    </IconDropdown>
  );
};

export default NotificationsDropdown;
