import { Tab, Tabs, useTheme } from "@material-ui/core";
import React, { FC, useContext, useEffect, useMemo } from "react";
import SwipeableViews from "react-swipeable-views";

import TabPanel from "../../../../../common/components/TabPanel";
import { VenueReportingContext } from "../../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../../common/hooks/useRequestByPeriod";
import { useVenueHasPackage } from "../../../../../common/hooks/useVenueHasPacakage";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { PackageUID } from "../../../../../store/currentVenue/types";
import {
  GET_PROMOTION_USAGES_VIEWS_BY_MONTH,
  GetPromotionUsagesViewsByMonthResponse,
} from "../../charts/PromotionUsagesViewsChart/api";
import TabHappyHours from "./components/TabHappyHours";
import TabPromotions from "./components/TabPromotions";
import { useStyles } from "./styles";

const TabsPromotions: FC<{
  currentTab: number;
  changeTab: (tab: number) => void;
}> = ({ currentTab, changeTab }) => {
  const theme = useTheme();

  const classes = useStyles();
  const tabProps = (index: number) => ({
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  });

  const handleChange = (
    event: React.ChangeEvent<Record<any, any>>,
    newValue: number
  ) => {
    changeTab(newValue);
  };

  const handleChangeIndex = (index: number) => {
    changeTab(index);
  };

  const hasPackage = useVenueHasPackage(PackageUID.PROMOTIONS);

  const { filters, setStats } = useContext(VenueReportingContext);

  const requestFilters = useMemo(() => {
    return { ...filters, ...{ isHappyHour: currentTab === 1 } };
  }, [currentTab, filters]);

  const { response } =
    useRequestByPeriod<GetPromotionUsagesViewsByMonthResponse>(
      GET_PROMOTION_USAGES_VIEWS_BY_MONTH,
      requestFilters,
      hasPackage // havePermission(ReportingPromotions.VIEWS_USAGES)
    );

  useEffect(() => {
    if (response) setStats(response.stats);
  }, [response, setStats]);

  return (
    <div>
      <div className={classes.tabsWrapper}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="user management tab">
          <Tab
            classes={{
              root: classes.tabRoot,
            }}
            label={dictionary.deals.tabs.deals}
            {...tabProps(0)}
          />
          <Tab
            classes={{
              root: classes.tabRoot,
              labelIcon: classes.tabIcon,
            }}
            //TODO update on mui 5
            // icon={hasPackage ? <></> : <IconPackLock />}
            label={dictionary.deals.tabs.happyHours}
            {...tabProps(1)}
          />
        </Tabs>
      </div>
      <SwipeableViews
        className={classes.swipeableContainer}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={currentTab}
        onChangeIndex={handleChangeIndex}>
        <TabPanel
          className={classes.tabPanel}
          hideNotActive
          value={currentTab.toString()}
          index={"0"}
          dir={theme.direction}>
          <TabPromotions />
        </TabPanel>
        <TabPanel
          className={classes.tabPanel}
          hideNotActive
          value={currentTab.toString()}
          index={"1"}
          dir={theme.direction}>
          <TabHappyHours />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export default TabsPromotions;
