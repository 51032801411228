import { useContext, useState } from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import dictionary from "../../../../i18n/en_US/dictionary";
import { DELETE_UNCLAIMED_VENUE } from "../../api";
import { AddNewVenueRequest } from "../../models";
import useDataEntryBusinesses from "../../useDataEntryBusinesses";

interface Props {
  editVenueId: number;
  setEditVenueId: (venueId: number) => void;
  handleOpenVenueMode: () => void;
}

export function useVenuesTable(props: Props) {
  const { editVenueId, setEditVenueId, handleOpenVenueMode } = props;
  const { fetchUnclaimedData } = useDataEntryBusinesses();
  const { showAlert } = useContext(AlertContext);

  const [, deleteUnclaimedVenueData] = useRestApi<any, AddNewVenueRequest>(
    DELETE_UNCLAIMED_VENUE(editVenueId),
    "DELETE"
  );

  const [confirmedDeleteVenue, setConfirmedDeleteVenue] = useState(false);

  const handleClickEditMode = (venueId: number) => {
    setEditVenueId(venueId);
    handleOpenVenueMode();
  };

  const handleOpenConfirmDeleteVenuePopup = (venueId: number) => {
    setConfirmedDeleteVenue(true);
    setEditVenueId(venueId);
  };

  const handleDeleteUnclaimedVenue = () => {
    deleteUnclaimedVenueData()
      .then(() => {
        showAlert(
          dictionary.dataEntryVenues.requestMessages.successDeletedVenueMessage,
          "success"
        );
        fetchUnclaimedData();
      })
      .catch(() => {
        showAlert(
          dictionary.dataEntryVenues.requestMessages.errorDeleteVenueMessage,
          "error"
        );
      });
  };

  return {
    confirmedDeleteVenue,
    setConfirmedDeleteVenue,
    handleDeleteUnclaimedVenue,
    handleOpenConfirmDeleteVenuePopup,
    handleClickEditMode,
  };
}
