import React, { Dispatch, FC, SetStateAction, useState } from "react";

import { useForm, UseFormTypes } from "../../../../../../../../../common/hooks";
import { weekDaysFullName } from "../../../../../../../../../utils/dateTimeUtils";
import { DealCardState } from "../../../../../../../types/enums/DealCardState.enum";
import { CreatePartialDiscount } from "../../../../../../../types/interfaces/CreatePartialDiscount.interface";
import { DiscountDaysHours } from "../../../../../../../types/interfaces/DiscountDaysHours.interface";
import { PartialDiscount } from "../../../../../../../types/interfaces/PartialDiscount.interface";
import {
  HappyHourAdvancedForm,
  HappyHourAdvancedFormField,
  HappyHourAdvancedFormSchema,
  HappyHourAdvancedFormSchemaType,
} from "./forms/advanced";
import {
  HappyHourMainForm,
  HappyHourMainFormField,
  HappyHourMainFormSchema,
  HappyHourMainFormSchemaType,
} from "./forms/main";

interface Props {
  children?: React.ReactElement;
}

interface HappyHourFormDialogContextInterface {
  mainForm: UseFormTypes<HappyHourMainFormSchemaType, HappyHourMainForm>;
  advancedForm: UseFormTypes<
    HappyHourAdvancedFormSchemaType,
    HappyHourAdvancedForm
  >;
  currentStep: number;

  setCurrentStep: Dispatch<SetStateAction<number>>;
  daysHours: DiscountDaysHours[];
  setDaysHours: Dispatch<SetStateAction<DiscountDaysHours[]>>;
  resetForm: () => void;
  clearAdvancedForm: () => void;
  deals: CreatePartialDiscount[];
  setDeals: Dispatch<SetStateAction<any[]>>;
  activeCardState: DealCardState;
  setActiveCardState: Dispatch<SetStateAction<DealCardState>>;
  happyHourId: number;
  setHappyHourId: Dispatch<SetStateAction<number>>;
  updateDealItem: PartialDiscount | undefined;
  isSaveDisabled: boolean;
  setIsSaveDisabled: Dispatch<SetStateAction<boolean>>;
  setUpdateDealItem: Dispatch<SetStateAction<any>>;
  filesQueue: any[];
  setFilesQueue: Dispatch<SetStateAction<any[]>>;
  resetInActiveCards: (value: any) => any;
  increaseCustomFormLengthProperty: (
    value: number,
    key: HappyHourMainFormField
  ) => void;
  decreaseCustomFormLengthProperty: (
    value: number,
    key: HappyHourMainFormField
  ) => void;
}
export const daysHoursDefault: DiscountDaysHours[] = weekDaysFullName.map(
  (day: string) => {
    return {
      id: day.slice(0, 3),
      day,
      isActive: true,
      start: "00:00:00",
      end: "23:59:59",
    };
  }
);

export const DialogContext =
  React.createContext<HappyHourFormDialogContextInterface>({
    mainForm: {
      form: HappyHourMainForm,
      setAndValidate: () => undefined,
      setFormValue: () => undefined,
      setFormValues: () => undefined,
      setSchemaRules: () => undefined,
      clearErrors: () => undefined,
      validateForm: () => true,
    },
    advancedForm: {
      form: HappyHourAdvancedForm,
      setAndValidate: () => undefined,
      setFormValue: () => undefined,
      setFormValues: () => undefined,
      setSchemaRules: () => undefined,
      clearErrors: () => undefined,
      validateForm: () => true,
    },

    currentStep: 1,

    daysHours: [],

    setCurrentStep: () => undefined,

    setDaysHours: () => undefined,
    resetForm: () => undefined,
    clearAdvancedForm: () => undefined,
    deals: [],
    setDeals: () => undefined,
    activeCardState: DealCardState.percent,
    setActiveCardState: () => undefined,
    happyHourId: 0,
    setHappyHourId: () => undefined,
    updateDealItem: undefined,
    setUpdateDealItem: () => undefined,
    filesQueue: [],
    setFilesQueue: () => undefined,
    resetInActiveCards: () => undefined,
    increaseCustomFormLengthProperty: () => undefined,
    decreaseCustomFormLengthProperty: () => undefined,
    isSaveDisabled: false,
    setIsSaveDisabled: () => undefined,
  });

const DialogContextProvider: FC<Props> = ({ children }) => {
  const mainForm = useForm(HappyHourMainFormSchema, HappyHourMainForm);
  const advancedForm = useForm(
    HappyHourAdvancedFormSchema,
    HappyHourAdvancedForm
  );

  const [activeCardState, setActiveCardState] = useState<DealCardState>(
    DealCardState.percent
  );

  const [happyHourId, setHappyHourId] = useState(0);
  const [updateDealItem, setUpdateDealItem] = useState<
    PartialDiscount | undefined
  >(undefined);
  const [currentStep, setCurrentStep] = useState(1);
  const [daysHours, setDaysHours] =
    useState<DiscountDaysHours[]>(daysHoursDefault);
  const [deals, setDeals] = useState<any[]>([]);
  const [filesQueue, setFilesQueue] = useState<any[]>([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const resetForm = () => {
    mainForm.clearErrors(
      Object.keys(HappyHourMainForm.values) as HappyHourMainFormField[]
    );

    advancedForm.clearErrors(
      Object.keys(HappyHourAdvancedForm.values) as HappyHourAdvancedFormField[]
    );
    mainForm.setFormValues(HappyHourMainForm.values);
    advancedForm.setFormValues(HappyHourAdvancedForm.values);

    setHappyHourId(0);
    setCurrentStep(1);
    setFilesQueue([]);
    setDeals([]);
    setUpdateDealItem(undefined);
  };

  const clearAdvancedForm = () => {
    advancedForm.setFormValues(HappyHourAdvancedForm.values);
  };

  const increaseCustomFormLengthProperty = (
    value: number,
    key: HappyHourMainFormField
  ) => {
    const valueNew = Number(mainForm.form.values[key]) + value;
    mainForm.setAndValidate(key, String(valueNew));
  };
  const decreaseCustomFormLengthProperty = (
    value: number,
    key: HappyHourMainFormField
  ) => {
    const valueNew = Number(mainForm.form.values[key]) - value;
    mainForm.setAndValidate(key, String(valueNew >= 0 ? valueNew : 0));
  };

  const resetInActiveCards = (formData: any) => {
    const formValues = { ...formData };
    if (activeCardState != DealCardState.percent) formValues.percent = 0;
    if (activeCardState != DealCardState.sellingPrice)
      formValues.sellingPrice = 0;
    if (activeCardState != DealCardState.rebatedPrice)
      formValues.rebatedPrice = 0;
    if (activeCardState != DealCardState.customTitle)
      formValues.customTitle = "";
    return formValues;
  };

  return (
    <DialogContext.Provider
      value={{
        mainForm,
        advancedForm,
        currentStep,
        setCurrentStep,
        daysHours,
        setDaysHours,
        resetForm,
        clearAdvancedForm,
        deals,
        setDeals,
        activeCardState,
        setActiveCardState,
        happyHourId,
        setHappyHourId,
        filesQueue,
        setFilesQueue,
        updateDealItem,
        setUpdateDealItem,
        resetInActiveCards,
        increaseCustomFormLengthProperty,
        decreaseCustomFormLengthProperty,
        isSaveDisabled,
        setIsSaveDisabled,
      }}>
      {children}
    </DialogContext.Provider>
  );
};
export default DialogContextProvider;
