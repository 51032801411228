export enum NotificationEnum {
  EMAIL = "EMAIL",
  WEB_APP = "WEB_APP",
  MONTHLY_NEWSLETTER = "MONTHLY_NEWSLETTER",
  NEW_REVIEWS = "NEW_REVIEWS",
  USED_PROMOTIONS = "USED_PROMOTIONS",
  NEW_MESSAGE_FROM_USER = "NEW_MESSAGE_FROM_USER",
  NEW_MESSAGE_FROM_OCIETY = "NEW_MESSAGE_FROM_OCIETY",
  PROMOTION_EXPIRING = "PROMOTION_EXPIRING",
  PROMOTIONS_ALMOST_USED = "PROMOTIONS_ALMOST_USED",
}
