import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "miniCartContentWrapper"
  | "indicator"
  | "button";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        position: "relative",
      },
      button: {
        position: "relative",
      },
      indicator: {
        background: "#6979F8",
        border: "1.5px solid #F7F8FC",
        borderRadius: "50%",
        top: 10,
        right: 15,
        position: "absolute",
        height: 10,
        width: 10,
      },
      miniCartContentWrapper: {
        minWidth: 253,
        padding: theme.spacing(20 / 8),
        position: "absolute",
        top: "100%",
        marginTop: 10,
        right: -12,
        "&:before": {
          content: "''",
          display: "block",
          width: 15,
          height: 15,
          transform: "rotate(45deg)",
          position: "absolute",
          background: theme.palette.background.paper,
          top: -7,
          right: 30,
        },
        [theme.breakpoints.down("xs")]: {
          width: "calc(100vw - 47px)",
          right: -67,
          "&:before": {
            right: 85,
          },
        },
      },
    })
);
