import React, { Dispatch, FC, SetStateAction, useState } from "react";

import { useForm, UseFormTypes } from "../../../../../../../../../common/hooks";
import {
  HappyHourMainForm,
  HappyHourMainFormSchema,
  HappyHourMainFormSchemaType,
} from "./forms/main";

interface Props {
  children?: React.ReactElement;
}

interface HappyHourFormDialogContextInterface {
  mainForm: UseFormTypes<HappyHourMainFormSchemaType, HappyHourMainForm>;
  resetForm: () => void;
  filesQueue: any[];
  setFilesQueue: Dispatch<SetStateAction<any[]>>;
  isSaveDisabled: boolean;
  setIsSaveDisabled: Dispatch<SetStateAction<boolean>>;
}

export const DialogContext =
  React.createContext<HappyHourFormDialogContextInterface>({
    mainForm: {
      form: HappyHourMainForm,
      setAndValidate: () => undefined,
      setFormValue: () => undefined,
      setFormValues: () => undefined,
      setSchemaRules: () => undefined,
      clearErrors: () => undefined,
      validateForm: () => true,
    },
    filesQueue: [],
    setFilesQueue: () => undefined,
    resetForm: () => undefined,
    isSaveDisabled: false,
    setIsSaveDisabled: () => undefined,
  });

const DialogContextProvider: FC<Props> = ({ children }) => {
  const mainForm = useForm(HappyHourMainFormSchema, HappyHourMainForm);
  const [filesQueue, setFilesQueue] = useState<any[]>([]);

  const resetForm = () => {
    mainForm.setFormValues(HappyHourMainForm.values);
    setFilesQueue([]);
  };

  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  return (
    <DialogContext.Provider
      value={{
        mainForm,
        resetForm,
        filesQueue,
        setFilesQueue,
        isSaveDisabled,
        setIsSaveDisabled,
      }}>
      {children}
    </DialogContext.Provider>
  );
};
export default DialogContextProvider;
