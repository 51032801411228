import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames =
  | "root"
  | "dialogContent"
  | "fieldLabel"
  | "inputAdornedValueField"
  | "inputAdornedCodeField"
  | "inputAdornedCodeFieldText";
export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      dialogContent: {
        padding: theme.spacing(4),
        minHeight: 0,
        [theme.breakpoints.up("lg")]: {
          minWidth: 900,
        },
        [theme.breakpoints.up("md")]: {
          minWidth: 648,
        },
        [theme.breakpoints.down("sm")]: {
          minWidth: 500,
        },
        [theme.breakpoints.down("xs")]: {
          minWidth: "auto",
          width: "100%",
          height: "100%",
        },
      },
      fieldLabel: {
        marginBottom: 6,
        color: "#3B424E",
        fontWeight: 600,
      },
      inputAdornedValueField: {
        borderLeft: "solid 1px #D0D5DD",
        marginLeft: 5,
        paddingLeft: 10,
      },
      inputAdornedCodeFieldText: {
        fontSize: "14px !important",
        lineHeight: "22px !important",
        fontWeight: 400,
        color: "#9BA2AE",
      },
      inputAdornedCodeField: {
        marginLeft: -5,
        paddingLeft: 0,
      },
    })
);
