import moment from "moment";

import { BasicForm } from "../../../../common/models";
import { ValidationRules } from "../../../../utils/validation";
import { PromoCodesType } from "../../models";

export type PromoCodesFormErrorsField =
  | "type"
  | "couponCode"
  | "value"
  | "start"
  | "end"
  | "minimumAmount"
  | "usageLimit";

export type PromoCodesFormValues = {
  [key in PromoCodesFormErrorsField]: string;
};

export type PromoCodesFormErrors = {
  [key in PromoCodesFormErrorsField]: string;
};

export const PromoCodesForm: BasicForm<PromoCodesFormErrorsField> = {
  values: {
    type: PromoCodesType.STANDARD,
    couponCode: "",
    value: "",
    start: moment().format("YYYY-MM-DD"),
    end: moment().add(1, "days").format("YYYY-MM-DD"),
    minimumAmount: "",
    usageLimit: "",
  },
  errors: {
    type: "",
    couponCode: "",
    value: "",
    start: "",
    end: "",
    minimumAmount: "",
    usageLimit: "",
  },
  touched: [],
  isValid: true,
};

export type PromoCodesFormSchemaType = Record<
  PromoCodesFormErrorsField,
  ValidationRules
>;

export const PromoCodesFormSchema: PromoCodesFormSchemaType = {
  type: {
    required: true,
  },
  couponCode: {
    required: true,
    length: {
      max: 15,
    },
  },
  value: {
    required: true,
    type: "number+",
  },
  start: {
    required: true,
  },
  end: {
    required: true,
  },
  minimumAmount: {
    required: true,
    type: "number+",
  },
  usageLimit: {
    required: true,
    type: "number+",
  },
};
