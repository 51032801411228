export const APP_NAME = "Ociety";

export const VALID_COUNTRY_CODES = process.env.VALID_PHONE_COUNTRIES || "US,AT";

export const HELP_EMAIL = "help@ociety.com";

export const CURRENCY_SYMBOL = "$";

export const isPackagesDisabled =
  process.env.REACT_APP_PACKAGES_DISABLED === "true";

export const RBACK_ENABLED = false;

export const TERMS_AND_CONDITIONS_URL = "https://www.ociety.com/terms-of-use/";
export const PRIVACY_POLICY_URL = "https://www.ociety.com/privacy-policy/";
export const TERMS_OF_SALE = "https://www.ociety.com/terms-of-sale/";
