import React, { FC, useContext, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartContainer from "../../../../../common/components/ChartContainer";
import ChartTooltip from "../../../../../common/components/ChartTooltip";
import { VenueReportingContext } from "../../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../../common/hooks/useRequestByPeriod";
import { useVenueHasPackage } from "../../../../../common/hooks/useVenueHasPacakage";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { PackageUID } from "../../../../../store/currentVenue/types";
import {
  chartAdditionalColor,
  chartSecondaryColor,
  colors,
} from "../../../../../theme/colors";
import { getBarFillColor } from "../../../../../utils/chartUtils";
import {
  LegendDefaultProps,
  XAxisDefaultProps,
  YAxisDefaultProps,
} from "../../../../Reporting/defaultProps";
import {
  GET_MOST_POPULAR_PROMOTIONS,
  GetMostPopularPromotionsResponse,
} from "./api";
import { chartDataMock } from "./mock";
import { ChartDataItem } from "./models";

const MostPopularPromotionsChart: FC = () => {
  const { filters, handlePackageName } = useContext(VenueReportingContext);
  const [chartData, setChartData] = useState(chartDataMock);
  const hasPackage = useVenueHasPackage(PackageUID.PROMOTIONS);

  const { response, loading } =
    useRequestByPeriod<GetMostPopularPromotionsResponse>(
      GET_MOST_POPULAR_PROMOTIONS,
      filters,
      hasPackage // havePermission(ReportingPromotions.MOST_POPULAR)
    );

  const prepareChartData = (stats: ChartDataItem[]) => {
    if (stats.length < 4) {
      let addEmpty = 4 - stats.length;
      while (addEmpty > 0) {
        stats.push({ name: "  ", usages: 0 });
        addEmpty--;
      }
    }

    setChartData(
      stats.map((chartData) => ({
        ...chartData,
      }))
    );
  };

  useEffect(() => {
    if (response) {
      prepareChartData(response.stats);
    }
    if (!hasPackage) {
      setChartData(chartDataMock);
    }
  }, [response, hasPackage]);

  const isChartEmpty = !chartData.some((data) => data.usages !== 0);

  return (
    <ChartContainer
      loading={loading}
      isChartEmpty={isChartEmpty}
      title={dictionary.reporting.dealMostPopularTitle}
      chartType={"bar-with-y-axis"}
      locked={!hasPackage}
      packageName={handlePackageName(PackageUID.PROMOTIONS)}>
      <BarChart layout={"vertical"} barCategoryGap={3} data={chartData}>
        <CartesianGrid
          strokeDasharray="5 5"
          strokeOpacity={0.6}
          color={colors.grey.light}
          horizontal={false}
        />
        <XAxis {...XAxisDefaultProps} allowDecimals={false} type="number" />
        <YAxis
          {...YAxisDefaultProps}
          type="category"
          dataKey="name"
          width={140}
        />
        <Bar
          radius={[0, 2, 2, 0]}
          dataKey={"usages"}
          fill={chartSecondaryColor}
          name={dictionary.reporting.dealNumberOfTimesUsed}>
          {chartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={getBarFillColor(chartData, index, "usages")}
            />
          ))}
        </Bar>
        <Tooltip
          content={<ChartTooltip />}
          cursor={{
            stroke: chartAdditionalColor,
            strokeWidth: 2,
            opacity: 0.3,
          }}
        />
        <Legend {...LegendDefaultProps} />
      </BarChart>
    </ChartContainer>
  );
};

export default MostPopularPromotionsChart;
