import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "text";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        position: "relative",
        border: "none",
        "&:after": {
          content: "''",
          display: "block",
          position: "absolute",
          left: 0,
          bottom: 0,
          width: "100%",
          height: theme.spacing(1),
          background: theme.palette.background.default,
          boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)",
        },
        "&:first-of-type, &:last-of-type": {
          borderRadius: 4,
        },
      },
      text: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        paddingLeft: theme.spacing(2),
      },
    })
);
