import { createStyles } from "@material-ui/core";
import { ClassNameMap, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type UploadPhotoClassNames =
  | "imageCircleCloudUpload"
  | "imageCircleCloudUploadContainer"
  | "imageSelectorContainer"
  | "inputFileSelect"
  | "textClickToUpload"
  | "textContainer"
  | "textFileUploadConstrains";

export const useStyles: () => ClassNameMap<UploadPhotoClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      imageCircleCloudUpload: { height: 40 },
      imageCircleCloudUploadContainer: { marginRight: 16 },
      imageSelectorContainer: {
        border: "1px dashed #CBCFD5",
        borderRadius: 4,
        padding: 40,
        marginTop: 16,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      inputFileSelect: { display: "none" },
      textClickToUpload: { color: "#FE5454" },
      textContainer: { fontWeight: 700, fontSize: 14 },
      textFileUploadConstrains: {
        fontWeight: 600,
        fontSize: 12,
        color: "#747982",
      },
    })
);
