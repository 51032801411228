import moment from "moment";

export const hoursToMoment = (hoursString: string) => {
  if (!hoursString) {
    throw Error("No hours string provided");
  }

  const [hours, minutes, seconds] = hoursString.split(":");

  if (!hours || !minutes || !seconds) {
    throw Error("Invalid format");
  }

  // @ts-ignore
  return moment({ hours, minutes, seconds });
};

export const formatMobileNumberWithCountry = (phoneNumber: string) => {
  const replaceAll = (src: string, search: string, replace: string) => {
    return src.split(search).join(replace);
  };

  phoneNumber = replaceAll(phoneNumber.trim(), "-", "");

  if (phoneNumber.length > 3 && phoneNumber.length <= 6)
    phoneNumber = phoneNumber.slice(0, 3) + "-" + phoneNumber.slice(3);
  else if (phoneNumber.length > 10)
    phoneNumber =
      phoneNumber.slice(0, 1) +
      "-" +
      phoneNumber.slice(1, 4) +
      "-" +
      phoneNumber.slice(4, 7) +
      "-" +
      phoneNumber.slice(7);

  return phoneNumber;
};
