import React, { Dispatch, FC, SetStateAction, useState } from "react";

import { PromoCodeResponseItem } from "../models";

interface Props {
  children?: React.ReactElement;
}

interface PromoCodesContext {
  formDialogOpen: boolean;
  setFormDialogOpen: Dispatch<SetStateAction<boolean>>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  promoCodesList: PromoCodeResponseItem[];
  setPromoCodesList: Dispatch<SetStateAction<PromoCodeResponseItem[]>>;
  promoCodesTabCount: number;
  setPromoCodesTabCount: Dispatch<SetStateAction<number>>;
  promoCodeId: number;
  setPromoCodeId: Dispatch<SetStateAction<number>>;
  isPreview: boolean;
  setIsPreview: Dispatch<SetStateAction<boolean>>;
}

export const PromoCodesContext = React.createContext<PromoCodesContext>({
  formDialogOpen: false,
  setFormDialogOpen: () => undefined,
  searchValue: "",
  setSearchValue: () => undefined,
  promoCodesList: [],
  setPromoCodesList: () => undefined,
  promoCodesTabCount: 0,
  setPromoCodesTabCount: () => undefined,
  promoCodeId: 0,
  setPromoCodeId: () => undefined,
  isPreview: false,
  setIsPreview: () => undefined,
});

const PromoCodesContextProvider: FC<Props> = ({ children }) => {
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [promoCodesList, setPromoCodesList] = useState<PromoCodeResponseItem[]>(
    []
  );
  const [promoCodesTabCount, setPromoCodesTabCount] = useState<number>(0);
  const [promoCodeId, setPromoCodeId] = useState<number>(0);
  const [isPreview, setIsPreview] = useState<boolean>(false);

  return (
    <PromoCodesContext.Provider
      value={{
        formDialogOpen,
        setFormDialogOpen,
        searchValue,
        setSearchValue,
        promoCodesList,
        setPromoCodesList,
        promoCodesTabCount,
        setPromoCodesTabCount,
        promoCodeId,
        setPromoCodeId,
        isPreview,
        setIsPreview,
      }}>
      {children}
    </PromoCodesContext.Provider>
  );
};

export default PromoCodesContextProvider;
