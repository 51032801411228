export const GET_VENUE_PROMOTION_UNIQUE_VIEWS =
  "reporting/promotion-unique-views-by-month";

export interface PromotionUniqueViewsData {
  month: string;
  new: number;
  general: number;
}

export interface GetVenuePromotionUniqueViewsResponse {
  stats: PromotionUniqueViewsData[];
}
