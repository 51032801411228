import dictionary from "../../i18n/en_US/dictionary";
import HttpStatusCodes from "../httpStatusCodes";
import { AuthError } from "../models";

export const handleLoginError = (e: any) => {
  let error: string = dictionary.auth.authError;

  if (e && e.status === HttpStatusCodes.BAD_REQUEST) {
    switch (e.data) {
      case AuthError.ACCOUNT_BLOCKED: {
        error = dictionary.auth.blockedError;
        break;
      }
      case AuthError.ACCOUNT_BEFORE_BLOCKING: {
        error = dictionary.auth.lastAttemptBeforeBlockError;
        break;
      }
      case AuthError.ACCOUNT_INACTIVE: {
        error = dictionary.auth.inactiveError;
        break;
      }
      case AuthError.ACCOUNT_DELETED: {
        error = dictionary.auth.deletedError;
        break;
      }
      case AuthError.INVALID_CREDENTIALS: {
        error = dictionary.auth.credentialsError;
        break;
      }
    }
  }

  return error;
};
