import { DialogContent } from "@material-ui/core";
import { DialogProps } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import React, { FC } from "react";

import ButtonSuccess from "../../../../common/components/ButtonSuccess";
import OcietyDialogTitle from "../../../../common/components/dialogs/OcietyDIalogTitle";
import dictionary from "../../../../i18n/en_US/dictionary";

const PackageAlert: FC<DialogProps> = (props) => {
  const { open, onClose, children, ...rest } = props;

  const handleCloseAlert = () => {
    if (onClose) {
      // @ts-ignore
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      {...rest}>
      <OcietyDialogTitle id="mfa-call-dialog-title" onClose={handleCloseAlert}>
        {dictionary.plan.packagesConflict}
      </OcietyDialogTitle>
      <DialogContent dividers style={{ maxWidth: 500 }}>
        {children}
      </DialogContent>
      <DialogActions>
        <ButtonSuccess style={{ margin: "auto" }} onClick={handleCloseAlert}>
          {dictionary.plan.packageAlertButton}
        </ButtonSuccess>
      </DialogActions>
    </Dialog>
  );
};

export default PackageAlert;
