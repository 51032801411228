import { createMuiTheme, ThemeOptions } from "@material-ui/core";

import overrides from "./overrides";
import palette from "./palette";
import props from "./props";
import typography from "./typography";

interface OcietyThemeOptions extends ThemeOptions {
  font?: {
    size: {
      xxs: number;
      xs: number;
      s: number;
      m: number;
      l: number;
      xl: number;
      xxl: number;
    };
  };
}

// @ts-ignore
const borderColorPrimary: string = palette.secondary?.light;
const borderWidthPrimary = "1px";
const borderTypePrimary = "solid";
export const borderPrimary = `${borderWidthPrimary} ${borderTypePrimary} ${borderColorPrimary}`;

const themeOptions: OcietyThemeOptions = {
  palette,
  typography,
  overrides,
  props,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  font: {
    size: {
      xxs: 10,
      xs: 12,
      s: 14,
      m: 16,
      l: 18,
      xl: 20,
      xxl: 22,
    },
  },
};

export default createMuiTheme(themeOptions);
