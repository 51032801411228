import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "title"
  | "requirementsWrapper"
  | "requirement"
  | "icon"
  | "requirementDone";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette.background.default,
        position: "absolute",
        width: 260,
        marginLeft: 10,
        zIndex: 10,
        padding: theme.spacing(4),
        "&:before": {
          content: "''",
          display: "block",
          width: 15,
          height: 15,
          transform: "rotate(45deg)",
          position: "absolute",
          background: theme.palette.background.default,
        },
        [theme.breakpoints.up("sm")]: {
          top: 0,
          left: "100%",
          "&:before": {
            left: -7,
            top: 20,
          },
        },
        [theme.breakpoints.down("sm")]: {
          top: "100%",
          right: 0,
          marginTop: 10,
          "&:before": {
            right: 10,
            top: -7,
          },
        },
      },
      title: {
        fontWeight: 600,
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(16),
      },
      requirementsWrapper: {
        marginTop: theme.spacing(3),
      },
      requirement: {
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
        color: colors.red.primary,
        fontSize: theme.typography.pxToRem(12),
        marginBottom: 4,
      },
      requirementDone: {
        color: colors.green.primary,
      },
      icon: {
        marginTop: -2,
        height: 10,
        width: 10,
        marginRight: theme.spacing(2),
      },
    })
);
