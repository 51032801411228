import { Paper } from "@material-ui/core";
import React, { FC } from "react";

import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import StatisticsBlock from "../StatisticsBlock";
import Props from "./Props";
import { useStyles } from "./styles";

const StatisticsBlockShort: FC<Props> = (props: Props) => {
  const { description, score, loading, difference } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {loading && <LoadingIndicator withMask />}

      <div>
        <StatisticsBlock
          description={description}
          score={score}
          difference={difference}
        />
      </div>
    </Paper>
  );
};

export default StatisticsBlockShort;
