// eslint-disable-next-line max-len
import { requiredTextValidationRulesDefault } from "../../../../config/validation";
import { TwoFactoryAuthTypeEnum } from "../../../../store/auth/types";
import { Form } from "../../../../store/forms/types";

export interface ClaimVenueForm extends Form {
  errors: {
    comment?: string;
    company?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    title?: string;
  };
  values: {
    comment?: string;
    company?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    title?: string;
  };
}

export interface ClaimVenueVerifyForm extends Form {
  errors: {
    code?: string;
    securityKey?: string;
    type?: string;
  };
  values: {
    code?: string;
    securityKey?: string;
    type?: TwoFactoryAuthTypeEnum;
  };
}

export interface ClaimVenueFormSchema {
  firstName: {
    presence: {
      allowEmpty: boolean;
      message: string;
    };
    length: {
      maximum: number;
    };
  };
  lastName: {
    presence: {
      allowEmpty: boolean;
      message: string;
    };
    length: {
      maximum: number;
    };
  };
  company: {
    presence: {
      allowEmpty: boolean;
      message: string;
    };
    length: {
      maximum: number;
    };
  };
  title: {
    presence: {
      allowEmpty: boolean;
      message: string;
    };
    length: {
      maximum: number;
    };
  };
  comment: {
    length: {
      maximum: number;
    };
  };
  email: {
    presence: {
      allowEmpty: boolean;
      message: string;
    };
    email: true;
    length: {
      maximum: number;
    };
  };
  phoneNumber: {
    presence: {
      allowEmpty: boolean;
      message: string;
    };
    length: {
      maximum: number;
      minimum: number;
    };
  };
}

export interface ClaimVenueVerifyFormSchema {
  code: {
    presence: {
      allowEmpty: boolean;
      message: string;
    };
    length: {
      maximum: number;
    };
  };
  securityKey: {
    presence: {
      allowEmpty: boolean;
      message: string;
    };
    length: {
      maximum: number;
    };
  };
  type: any;
}

export const claimVenueVerifyFormSchema: ClaimVenueVerifyFormSchema = {
  code: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 6,
    },
  },
  securityKey: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 16,
    },
  },
  type: {},
};

export const claimVenueFormSchema: ClaimVenueFormSchema = {
  firstName: requiredTextValidationRulesDefault,
  lastName: requiredTextValidationRulesDefault,
  company: requiredTextValidationRulesDefault,
  title: requiredTextValidationRulesDefault,
  comment: {
    length: {
      maximum: 1000,
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 128,
    },
  },
  phoneNumber: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
      minimum: 6,
    },
  },
};

export enum ClaimFlowSteps {
  CLAIM_VENUE_FORM = "CLAIM_VENUE_FORM",
  CONFIRMATION_METHOD = "CONFIRMATION_METHOD",
  VERIFICATION_CODE = "VERIFICATION_CODE",
  ATTACHMENT_FILE_NO_CODE = "ATTACHMENT_FILE_NO_CODE",
  ATTACHMENT_FILE_NO_MATCH = "ATTACHMENT_FILE_NO_MATCH",
  SUCCESS_NEED_APPROVE = "SUCCESS_NEED_APPROVE",
  SUCCESS_CLAIM = "SUCCESS_CLAIM",
}
