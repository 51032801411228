import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@mui/material/Skeleton";
import clsx from "clsx";
import { isNegative } from "dinero.js";
import React, { FC, useContext } from "react";

import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { getTimeAndDate } from "../../../../../../../../utils/dateTimeUtils";
import {
  toDineroObjFormat,
  transformDineroAmountToDecimalString,
} from "../../../../../../../Promotions/AdminVenue/dialogs/PromotionFormDialog/components/PromotionFormDialogContent/utils";
import { TransactionItem } from "../../../../../../models";
import { TransactionsContext } from "../../../../../../providers";
import { useStyles } from "../../styles";
import Props from "./Props";

const Row: FC<Props> = ({ row }: Props) => {
  const { tableLoading } = useContext(TransactionsContext);
  const {
    createdAt,
    type,
    kind,
    userEmail,
    creditsAmount,
    couponAmount,
    fiatAmount,
    fee,
    discountRebatedPrice,
    discountName,
    promoCodeName,
    usedPromoCode,
    usedDate,
    venueName,
    validUntil,
    referralCode,
  } = row;
  const classes = useStyles();
  const { headCellsState } = useContext(TransactionsContext);

  const isNegativeValue = (value: number) =>
    isNegative(toDineroObjFormat(value));

  const isSelectedToPresent = (columnName: keyof TransactionItem) => {
    return headCellsState.find((cell) => cell.id === columnName)?.isSelected;
  };

  const renderSkeleton = (cellDate: string) => {
    return tableLoading ? (
      <>
        <Skeleton variant="text" sx={{ fontSize: 14 }} />
        <Skeleton variant="text" sx={{ fontSize: 14 }} />
      </>
    ) : (
      cellDate
    );
  };

  const returnPricesStyle = (value: number | null) => {
    if (value === null) return classes.mainTextColour;

    if (isNegativeValue(value)) return classes.negativeAmount;

    return classes.positiveAmount;
  };

  return (
    <>
      <TableRow className={classes.tableRow}>
        {isSelectedToPresent("createdAt") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {renderSkeleton(getTimeAndDate(createdAt))}
          </TableCell>
        )}
        {isSelectedToPresent("userEmail") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {renderSkeleton(userEmail || "-")}
          </TableCell>
        )}
        {isSelectedToPresent("type") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {renderSkeleton(
              dictionary.moneyForm.transactionCheckboxes.type[type]
            )}
          </TableCell>
        )}
        {isSelectedToPresent("fiatAmount") && (
          <TableCell
            className={clsx(
              classes.rowTableCell,
              classes.cellText,
              returnPricesStyle(fiatAmount)
            )}>
            {renderSkeleton(
              fiatAmount !== null
                ? `${isNegativeValue(fiatAmount) ? "-" : "+"}$` +
                    transformDineroAmountToDecimalString(Math.abs(fiatAmount))
                : "-"
            )}
          </TableCell>
        )}
        {isSelectedToPresent("creditsAmount") && (
          <TableCell
            className={clsx(
              classes.rowTableCell,
              classes.cellText,
              returnPricesStyle(creditsAmount)
            )}>
            {renderSkeleton(
              creditsAmount !== null
                ? `${isNegativeValue(creditsAmount) ? "-" : "+"}$` +
                    transformDineroAmountToDecimalString(
                      Math.abs(creditsAmount)
                    )
                : "-"
            )}
          </TableCell>
        )}
        {isSelectedToPresent("venueName") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {renderSkeleton(venueName || "-")}
          </TableCell>
        )}
        {isSelectedToPresent("discountRebatedPrice") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {renderSkeleton(
              discountRebatedPrice !== null
                ? "$" +
                    transformDineroAmountToDecimalString(discountRebatedPrice)
                : "-"
            )}
          </TableCell>
        )}
        {isSelectedToPresent("couponAmount") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {renderSkeleton(
              couponAmount !== null
                ? "$" + transformDineroAmountToDecimalString(couponAmount)
                : "-"
            )}
          </TableCell>
        )}
        {isSelectedToPresent("referralCode") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {renderSkeleton(referralCode || "-")}
          </TableCell>
        )}
        {isSelectedToPresent("fee") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {renderSkeleton(
              fee !== null
                ? `${isNegativeValue(fee) ? "-" : "+"}$` +
                    transformDineroAmountToDecimalString(fee)
                : "-"
            )}
          </TableCell>
        )}
        {isSelectedToPresent("promoCodeName") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {renderSkeleton(promoCodeName || "-")}
          </TableCell>
        )}
        {isSelectedToPresent("discountName") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {renderSkeleton(discountName || "-")}
          </TableCell>
        )}
        {isSelectedToPresent("validUntil") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {validUntil ? renderSkeleton(getTimeAndDate(validUntil)) : "-"}
          </TableCell>
        )}
        {isSelectedToPresent("kind") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {renderSkeleton(
              dictionary.moneyForm.transactionCheckboxes.kind[kind] || "-"
            )}
          </TableCell>
        )}
        {isSelectedToPresent("usedDate") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {usedDate ? renderSkeleton(getTimeAndDate(usedDate)) : "-"}
          </TableCell>
        )}
        {isSelectedToPresent("usedPromoCode") && (
          <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
            {renderSkeleton(usedPromoCode || "-")}
          </TableCell>
        )}
      </TableRow>
      <TableRow className={classes.rowDropdownWrapper}>
        <TableCell
          className={clsx(classes.tableCell, classes.rowCellDropdownWrapper)}
          colSpan={6}
        />
      </TableRow>
    </>
  );
};

export default Row;
