import { TablePagination, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import React, { FC } from "react";

import ActionsDropdown from "../../../../../../../../../common/components/ActionsDropdown";
import ButtonBlack from "../../../../../../../../../common/components/ButtonBlack";
import { Venue } from "../../../../../../../../../common/models";
import { ReactComponent as ChevronIcon } from "../../../../../../../../../common/svg/icons/chevron.svg";
import dictionary from "../../../../../../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../../../../../../utils/dateTimeUtils";
import PromotionImage, {
  PROMOTION_IMAGES,
} from "../../../../../../../components/PromotionImage";
import SelectHappyHour from "../../../../../../../components/SelectHappyHour";
import { HappyHour } from "../../../../../../../types/interfaces/HappyHour.inteface";
import { PartialDiscount } from "../../../../../../../types/interfaces/PartialDiscount.interface";
import Props from "./Props";
import { useStyles } from "./styles";

function Row({
  row,
  onActiveDaysDialogOpen,
  updateStatus,
  removeHappyHour,
  editHappyHour,
}: {
  row: HappyHour;
  onActiveDaysDialogOpen: (open: boolean, id: number) => void;
  updateStatus: (id: number, status: string) => void;
  removeHappyHour: (id: number) => void;
  editHappyHour: (id: number) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const openActiveDaysDialog = (
    event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onActiveDaysDialogOpen(true, row.id);
  };

  const openViewDetails = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    editHappyHour(row.id);
  };

  const getVenueFullAddress = ({ name, address, location, postcode }: Venue) =>
    `${name}, ${address}, ${location ? location.city : ""} ${postcode || ""}`;

  const handleRemoveHappyHour = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    event.stopPropagation();
    removeHappyHour(row.id);
  };

  return (
    <>
      <TableRow
        className={clsx(classes.tableRow, classes.rowTableRowContainer)}
        onClick={() => setOpen(!open)}
        style={{
          boxShadow: open ? "4px 0px 0px 0px red inset" : "none",
          borderBottomLeftRadius: open ? 0 : 4,
        }}>
        <TableCell className={classes.rowTableCell}>
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <ChevronIcon className={classes.chevronIconOpened} />
            ) : (
              <ChevronIcon className={classes.chevronIconNotOpened} />
            )}
          </IconButton>
        </TableCell>
        <TableCell className={classes.rowTableCell}>
          <Typography
            component="div"
            gutterBottom
            variant="body1"
            className={classes.rowTextVenueName}>
            {row.venue.name}
          </Typography>
          <Typography
            component="div"
            gutterBottom
            variant="caption"
            className={classes.rowTextVenueAddress}>
            {getVenueFullAddress(row.venue)}
          </Typography>
        </TableCell>
        <TableCell
          className={clsx(classes.rowTableCell, classes.rowCellCreatedAt)}>
          {getFormattedDate(row.createdAt)}
        </TableCell>
        <TableCell
          onClick={openActiveDaysDialog}
          className={clsx(
            classes.rowTableCell,
            classes.rowCellActiveDaysAndHours
          )}>
          {dictionary.deals.happyHours.activeDaysAndHours}
        </TableCell>
        <TableCell className={classes.rowTableCell}>
          <ButtonBlack onClick={openViewDetails}>
            {dictionary.deals.happyHours.details}
          </ButtonBlack>
        </TableCell>
        <TableCell>
          <ActionsDropdown
            iconName={"threeDotsVertical"}
            items={[
              {
                onClick: (event: React.ChangeEvent<HTMLInputElement>) =>
                  handleRemoveHappyHour(event, row.id),
                iconName: "removeAction",
                label: "Delete",
              },
            ]}
          />
        </TableCell>
      </TableRow>
      <TableRow
        className={classes.rowDropdownWrapper}
        style={{
          boxShadow: open ? "4px 0px 0px 0px red inset" : "none",
          borderTopLeftRadius: open ? 0 : 4,
        }}>
        <TableCell
          className={clsx(classes.tableCell, classes.rowCellDropdownWrapper)}
          colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={clsx(
                        classes.tableCell,
                        classes.tableHeadCellCollapsed
                      )}
                    />
                    <TableCell
                      className={clsx(
                        classes.tableCell,
                        classes.tableHeadCellCollapsed,
                        classes.rowCellPhoto
                      )}>
                      {dictionary.deals.happyHours.table.photo}
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.tableCell,
                        classes.tableHeadCellCollapsed,
                        classes.rowCellDealName
                      )}>
                      {dictionary.deals.happyHours.table.dealName}
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.tableCell,
                        classes.tableHeadCellCollapsed,
                        classes.rowCellStatus
                      )}>
                      {dictionary.deals.happyHours.table.status}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.deals?.map((rowDeal: PartialDiscount) => (
                    <TableRow
                      key={rowDeal.id}
                      className={classes.tableRowCollapsed}>
                      <TableCell
                        className={(classes.tableCell, classes.rowCellDeal)}
                      />
                      <TableCell
                        className={clsx(
                          classes.tableCellCollapsed,
                          classes.tableCell
                        )}>
                        <PromotionImage
                          height={64}
                          width={64}
                          categoryName={
                            rowDeal.mainCategory
                              ?.name as keyof typeof PROMOTION_IMAGES
                          }
                        />
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.tableCellCollapsed,
                          classes.tableCell,
                          classes.rowCellDealTitle
                        )}>
                        {rowDeal.name}
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.tableCellCollapsed,
                          classes.tableCell
                        )}>
                        <SelectHappyHour
                          status={rowDeal.status}
                          id={rowDeal.id}
                          onChange={updateStatus}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell} />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {open && (
        <TableRow className={classes.rowCellEmptyContainer}>
          <TableCell
            colSpan={6}
            className={clsx(classes.tableCell, classes.rowCellEmpty)}
          />
        </TableRow>
      )}
    </>
  );
}

const TableCollapsible: FC<Props> = (props: Props) => {
  const {
    onActiveDaysDialogOpen,
    list,
    updateStatus,
    removeHappyHour,
    count,
    rowsPerPage,
    page,
    handlePageChange,
    handleRowsPerPageChange,
    editHappyHour,
  } = props;

  const classes = useStyles();

  return (
    <>
      <TableContainer className={classes.tableCollapsibleContainer}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell style={{ paddingLeft: 36 }} />
              <TableCell className={classes.tableHeadCell}>
                {dictionary.deals.happyHours.table.venue}
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {dictionary.deals.happyHours.table.requestDate}
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {dictionary.deals.happyHours.table.activeDaysHours}
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {dictionary.deals.happyHours.table.actions}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableHeadBody}>
            {list?.map((item: HappyHour) => (
              <Row
                key={item.id}
                row={item}
                onActiveDaysDialogOpen={onActiveDaysDialogOpen}
                updateStatus={updateStatus}
                removeHappyHour={removeHappyHour}
                editHappyHour={editHappyHour}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        onChangePage={(_, page) => handlePageChange(page)}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleRowsPerPageChange}
        rowsPerPageOptions={[10, 20, 30]}
      />
    </>
  );
};

export default TableCollapsible;
