import { Typography } from "@material-ui/core";
import React, { FC, useMemo } from "react";

import ButtonBlack from "../../../../common/components/ButtonBlack";
import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import dictionary from "../../../../i18n/en_US/dictionary";
import SelectHappyHour from "../SelectHappyHour";
import ImageLineGraySalad from "./../../../../common/images/components/TabHappyHours/line-gray-salad.png";
import Props from "./Props";
import { useStyles } from "./styles";

const CardHappyHours: FC<Props> = (props: Props) => {
  const { deal, onEdit, onDelete, onSelect, index } = props;
  const classes = useStyles();

  const dealCardDescription = useMemo(() => {
    let description = "";
    if (deal?.mainCategoryName && deal?.subCategoryName) {
      description = `${deal?.mainCategoryName}, ${deal?.subCategoryName}`;
    } else if (deal.mainCategory && deal.subCategory) {
      description = `${deal?.mainCategory.name}, ${deal?.subCategory.name}`;
    }
    return description;
  }, [
    deal.mainCategory,
    deal?.mainCategoryName,
    deal.subCategory,
    deal?.subCategoryName,
  ]);

  const RenderActions = () => (
    <div className={classes.renderActions}>
      {onEdit && (
        <ButtonBlack onClick={() => onEdit(index)}>
          {dictionary.general.edit}
        </ButtonBlack>
      )}
      {onDelete && (
        <ButtonPrimary
          className={classes.renderActionsButtonPrimary}
          onClick={() => onDelete(index)}>
          {dictionary.general.delete}
        </ButtonPrimary>
      )}
    </div>
  );

  const RenderSelect = ({ onChange }: any) => (
    <SelectHappyHour status={deal.status} id={deal.id} onChange={onChange} />
  );

  const RenderActionsContainer = ({ children }: any) => (
    <div className={classes.renderActionsContainer}>
      <div className={classes.renderActionsContainerContent}>
        <img
          src={ImageLineGraySalad}
          alt=""
          className={classes.renderActionsContainerImage}
        />
        <Typography variant="h5" className={classes.text1}>
          {deal?.name}
        </Typography>
        <Typography className={classes.text2}>{dealCardDescription}</Typography>
        <Typography className={classes.text3}>{deal?.description}</Typography>
      </div>
      {children}
    </div>
  );

  const RenderSelectContainer = ({ children }: any) => (
    <div className={classes.renderSelectContainer}>
      <img
        src={ImageLineGraySalad}
        alt=""
        className={classes.renderSelectContainerImage}
      />
      <div className={classes.renderSelectContainerContentWrapper}>
        <div className={classes.renderSelectContainerContent}>
          <Typography variant="h5" className={classes.text1}>
            {deal?.name}
          </Typography>
          <Typography className={classes.text2}>
            {dealCardDescription}
          </Typography>
          <Typography className={classes.text3}>{deal?.description}</Typography>
        </div>
        {children}
      </div>
    </div>
  );

  return (
    <>
      {(onEdit || onDelete) && (
        <RenderActionsContainer>
          <RenderActions />
        </RenderActionsContainer>
      )}

      {onSelect && (
        <RenderSelectContainer>
          <RenderSelect onChange={onSelect} />
        </RenderSelectContainer>
      )}
    </>
  );
};
export default CardHappyHours;
