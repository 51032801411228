import MomentUtils from "@date-io/moment";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as _ from "lodash";
import moment from "moment";
import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import SimpleSelect from "../../../../common/components/Draft/SimpleSelect";
import OptionItem from "../../../../common/components/SingleSelect/OptionItem";
import dictionary from "../../../../i18n/en_US/dictionary";
import { Profile } from "../../../../permissions/permission";
import {
  setAndValidate,
  setForm,
  validateForm,
} from "../../../../store/forms/actions";
import { FormFieldKey } from "../../../../store/forms/types";
import { AppState } from "../../../../store/rootReducer";
import { havePermission } from "../../../../utils/userUtils";
import { getLocations, getTZ } from "./api";
import AvatarUpload from "./components/AvatarUpload";
import { VenueAdminMainFormSchema as schema } from "./form/VenueAdminMainFormSchema";
import VenueAdminProfileFormHelper from "./form/VenueAdminProfileFormHelper";
import ILocation from "./models/ILocation";
import Props from "./Props";
const VenueAdminMainForm: FC<Props> = (props: Props) => {
  const { profile, onSave } = props;
  const { forms } = useSelector((state: AppState) => state);
  const form = forms.venueAdminProfile;
  const dispatch = useDispatch();
  const { showAlert } = useContext(AlertContext);
  const initState: Array<{ key: string; value: string }> = [];
  const [timezones, setTimezones] = useState(initState);
  const intitState: ILocation[] = [];
  const [locations, setLocations] = useState(intitState);
  let selectedLocation: ILocation | undefined;
  const stateFormRef = useRef();

  // @ts-ignore
  stateFormRef.current = form;

  useEffect(() => {
    dispatch(
      setForm(
        "venueAdminProfile",
        VenueAdminProfileFormHelper.createVenueAdminProfileFormFromValue(
          profile
        )
      )
    );
  }, [dispatch, profile]);

  useEffect(() => {
    getTZ()
      .then((tzs) => {
        if (tzs) {
          const tz = [];
          // tslint:disable-next-line:forin
          for (const tzAbbr in tzs) {
            // @ts-ignore
            tz.push({ key: tzAbbr, value: tzs[tzAbbr].text });
          }

          setTimezones(tz);
        }
      })
      .catch((e) => {
        console.log("e -->>> ", e);
      });

    getLocations()
      .then((locs: ILocation[]) => {
        setLocations(locs);
      })
      .catch((error) => {
        console.error("error");
      });
  }, []);

  const handleSave = _.debounce(() => {
    if (!havePermission(Profile.EDIT)) {
      showAlert("User profile data is prohibited", "error");
    }

    if (!dispatch(validateForm("venueAdminProfile", schema))) {
      return;
    }

    // @ts-ignore
    const form: VenueAdminMainForm = stateFormRef.current as VenueAdminMainForm;

    onSave(VenueAdminProfileFormHelper.mapProfileFromForm(form));
  }, 500);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;

    // TODO reformat phone field
    // if (event.target.name === "phone") {
    //   value = formatMobileNumber(value as string);
    // }

    dispatch(
      setAndValidate(
        "venueAdminProfile",
        event.target.name as FormFieldKey,
        value,
        schema
      )
    );
  };

  const handleChangeFieldValue = (
    fieldName: string,
    value: string | number
  ) => {
    dispatch(
      setAndValidate(
        "venueAdminProfile",
        fieldName as FormFieldKey,
        value,
        schema
      )
    );

    handleSave();
  };

  const countries: string[] = [];
  const cites: ILocation[] = [];

  if (locations.length) {
    for (const loc of locations) {
      if (!countries.includes(loc.country)) {
        countries.push(loc.country);
      }
    }

    if (form.values.city) {
      selectedLocation = locations
        .filter((loc) => String(loc.id) === form.values.city)
        .pop();

      if (selectedLocation && !form.values.country) {
        dispatch(
          setAndValidate(
            "venueAdminProfile",
            "country",
            selectedLocation.country,
            schema
          )
        );
      }
    }

    if (form.values.country || selectedLocation) {
      for (const loc of locations) {
        if (
          loc.country === form.values.country ||
          (selectedLocation && selectedLocation.country === loc.country)
        ) {
          cites.push(loc);
        }
      }
    }
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <AvatarUpload
            avatarLink={
              props.profile.avatarLink ? props.profile.avatarLink : undefined
            }
          />
          <TextField
            fullWidth
            required
            label={dictionary.venueAdmin.profile.mainForm.firstName}
            name="firstName"
            error={!!form.errors.firstName}
            helperText={form.errors.firstName}
            onChange={handleChange}
            onBlur={(e) => {
              handleSave();
            }}
            style={{ marginTop: "1rem" }}
            type="text"
            value={form.values.firstName}
            variant="outlined"
          />
          <TextField
            fullWidth
            required
            label={dictionary.venueAdmin.profile.mainForm.email}
            name="email"
            error={!!form.errors.email}
            helperText={form.errors.email}
            onChange={handleChange}
            onBlur={(e) => {
              handleSave();
            }}
            style={{ marginTop: "1rem" }}
            type="text"
            value={form.values.email}
            variant="outlined"
          />
          <TextField
            fullWidth
            required
            label={dictionary.venueAdmin.profile.mainForm.phone}
            name="phone"
            error={!!form.errors.phone}
            helperText={form.errors.phone}
            onChange={handleChange}
            onBlur={(e) => {
              handleSave();
            }}
            style={{ marginTop: "1rem" }}
            type="text"
            value={form.values.phone}
            variant="outlined"
          />

          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Box style={{ marginTop: 20 }}>
              <DatePicker
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <AccessAlarmIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label={dictionary.venueAdmin.profile.mainForm.birthday}
                format="MM/DD/yyyy"
                required
                error={!!form.errors.birthday}
                helperText={form.errors.birthday}
                inputVariant="outlined"
                maxDate={
                  new Date(+new Date() - 10 * 12 * 31 * 24 * 3600 * 1000)
                }
                onChange={(date) => {
                  handleChangeFieldValue(
                    "birthday",
                    moment(date).format("YYYY-MM-DD")
                  );
                }}
                value={moment(form.values.birthday)}
              />
            </Box>
          </MuiPickersUtilsProvider>

          <SimpleSelect
            placeholder={dictionary.venueAdmin.profile.mainForm.timezone}
            onChange={(value) => {
              handleChangeFieldValue("timezone", value);
            }}
            options={timezones.map((tz) => new OptionItem(tz.key, tz.value))}
            value={form.values.timezone}
            error={form.errors.timezone}
          />
          <SimpleSelect
            placeholder={dictionary.venueAdmin.profile.mainForm.country}
            error={form.errors.country}
            onChange={(value) => {
              handleChangeFieldValue("country", value);
            }}
            options={countries.map(
              (country) => new OptionItem(country, country)
            )}
            value={form.values.country}
          />
          <SimpleSelect
            placeholder={dictionary.venueAdmin.profile.mainForm.city}
            error={form.errors.city}
            onChange={(value) => {
              handleChangeFieldValue("city", value);
            }}
            options={cites.map(
              (location) => new OptionItem(String(location.id), location.city)
            )}
            value={String(form.values.city)}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default VenueAdminMainForm;
