import {
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import OcietyDatePicker from "../../../../../../common/components/forms/OcietyDatePicker";
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { transformDineroAmountToDecimalString } from "../../../../../Promotions/AdminVenue/dialogs/PromotionFormDialog/components/PromotionFormDialogContent/utils";
import api from "../../../../api";
import { CouponCodeResponse, PromoCodesType } from "../../../../models";
import { PromoCodesContext } from "../../../../providers";
import { DialogContext } from "../../DialogContextProvider";
import { PromoCodesFormErrorsField } from "../../form";
import { useStyles } from "./styles";

const Content: FC = () => {
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const { mainForm } = useContext(DialogContext);
  const { form, setAndValidate } = mainForm;
  const { promoCodeId, isPreview } = useContext(PromoCodesContext);

  const [loading, setLoading] = useState<boolean>(false);

  const { type, couponCode, value, start, end, minimumAmount, usageLimit } =
    form.values;
  const {
    couponCode: errorCode,
    value: errorValue,
    minimumAmount: errorMinAmount,
    usageLimit: errorUsageLimit,
  } = form.errors;

  const handleChangeFormNameField = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setAndValidate(
        event.target.name as PromoCodesFormErrorsField,
        event.target.value
      );
    },
    [setAndValidate]
  );

  const handleChangeFormType = useCallback(
    (e: any) => {
      setAndValidate("type", e.target.value);
    },
    [setAndValidate]
  );

  const handleStartDate = useCallback(
    (date: MaterialUiPickersDate) => {
      setAndValidate("start", moment(date).format("YYYY-MM-DD"));
    },
    [setAndValidate]
  );

  const handleEndDate = useCallback(
    (date: MaterialUiPickersDate) => {
      setAndValidate("end", moment(date).format("YYYY-MM-DD"));
    },
    [setAndValidate]
  );

  const fetchPromoCodeData = useCallback(() => {
    setLoading(true);
    api.coupons
      .itemById(promoCodeId)
      .then((data: CouponCodeResponse) => {
        if (data) {
          const valueManoey = transformDineroAmountToDecimalString(data.value);
          const minAmountMoney = transformDineroAmountToDecimalString(
            data.minimumAmount
          );

          mainForm.setFormValues({
            type: data.type,
            couponCode: data.couponCode,
            value: valueManoey,
            minimumAmount: minAmountMoney,
            usageLimit: data.usageLimit,
            start: data.start,
            end: data.end,
          });
        }
      })
      .catch(() => {
        showAlert(dictionary.deals.createDealForm.errorFetchData, "error");
      })
      .finally(() => setLoading(false));
  }, [mainForm, promoCodeId, showAlert]);

  useEffect(() => {
    if (promoCodeId) {
      fetchPromoCodeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCodeId]);

  return (
    <DialogContent className={classes.dialogContent}>
      <Grid container spacing={4} className={classes.root}>
        {loading && <LoadingIndicator withMask />}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="body1" className={classes.fieldLabel}>
            {dictionary.couponManagement.formTitles.type}
          </Typography>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            disabled={isPreview}>
            <Select
              id="select-promo-code-type"
              value={type}
              defaultValue={PromoCodesType.STANDARD}
              onChange={handleChangeFormType}>
              <MenuItem value={PromoCodesType.STANDARD}>
                {dictionary.couponManagement.couponTypeLabel.standard}
              </MenuItem>
              <MenuItem value={PromoCodesType.FIRST_TIME}>
                {dictionary.couponManagement.couponTypeLabel.firstTime}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="body1" className={classes.fieldLabel}>
            {dictionary.couponManagement.formTitles.couponCode}
          </Typography>
          <TextField
            fullWidth
            disabled={isPreview}
            name={"couponCode"}
            error={!!errorCode}
            helperText={errorCode}
            onChange={handleChangeFormNameField}
            type="text"
            value={couponCode}
            variant="outlined"
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="body1" className={classes.fieldLabel}>
            {dictionary.couponManagement.formTitles.value}
          </Typography>
          <TextField
            fullWidth
            name={"value"}
            disabled={isPreview}
            error={!!errorValue}
            helperText={errorValue}
            onChange={handleChangeFormNameField}
            type="number"
            value={value}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>$</Typography>
                </InputAdornment>
              ),
              classes: { inputAdornedStart: classes.inputAdornedValueField },
            }}
          />
        </Grid>
        <Grid container spacing={2} item lg={12} md={12} sm={12} xs={12}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography variant="body1" className={classes.fieldLabel}>
              {dictionary.couponManagement.formTitles.startDate}
            </Typography>
            <OcietyDatePicker
              label={""}
              minDate={new Date()}
              value={start}
              onChange={handleStartDate}
              icon={"datePickerPromoCodes"}
              disabled={isPreview}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography variant="body1" className={classes.fieldLabel}>
              {dictionary.couponManagement.formTitles.endDate}
            </Typography>
            <OcietyDatePicker
              label={""}
              minDate={moment(form.values.start).add(1, "days").toDate()}
              value={end}
              onChange={handleEndDate}
              icon={"datePickerPromoCodes"}
              disabled={isPreview}
            />
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="body1" className={classes.fieldLabel}>
            {dictionary.couponManagement.formTitles.minPurchaseAmount}
          </Typography>
          <TextField
            fullWidth
            name={"minimumAmount"}
            error={!!errorMinAmount}
            helperText={errorMinAmount}
            onChange={handleChangeFormNameField}
            type="number"
            value={minimumAmount}
            variant="outlined"
            disabled={isPreview}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="body1" className={classes.fieldLabel}>
            {dictionary.couponManagement.formTitles.usageLimit}
          </Typography>
          <TextField
            fullWidth
            name={"usageLimit"}
            error={!!errorUsageLimit}
            helperText={errorUsageLimit}
            onChange={handleChangeFormNameField}
            type="number"
            value={usageLimit}
            variant="outlined"
            disabled={isPreview}
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
};
export default Content;
