export interface ScoreTotal {
  score: number;
}
export enum VenueRequestType {
  ALL = "ALL",
  CLAIM = "CLAIM",
  CHANGE = "CHANGE",
  ADD = "ADD",
}

export interface VenuesChangesSelectFilterOption {
  value: VenueRequestType;
  label: string;
}
