import { http } from "../../../config/api";
import IAdmin from "./models/IAdmin";

const RBAC_ADMINS = `rbac/admins`;

export const getAdmins = async (): Promise<IAdmin[]> =>
  http
    .get(RBAC_ADMINS)
    .then((response) => [...response.data])
    .catch((error) => {
      throw error.response;
    });
