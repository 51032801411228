import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { buttonUseStyles } from "./buttonStyles";
import Props from "./Props";
import { useStyles } from "./styles";

const ArrowButton: FC<Props> = (props: Props) => {
  const { children, className, ...rest } = props;
  const buttonClasses = buttonUseStyles();
  const classes = useStyles();
  return (
    <IconButton
      classes={buttonClasses}
      className={clsx(classes.root, buttonClasses, className)}
      {...rest}>
      {children}
    </IconButton>
  );
};
export default ArrowButton;
