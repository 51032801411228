import { http } from "../../../../config/api";
import ILiveUserData from "./models/ILiveUserData";

const REPORTING_LIVE_STATS = (venueId: number) =>
  `reporting/live-stats/${venueId}`;

export const getReportingLiveStats = async (
  venueId: number
): Promise<ILiveUserData[]> =>
  http
    .get(REPORTING_LIVE_STATS(venueId))
    .then((response) => [...response.data])
    .catch((error) => {
      throw error.response;
    });
