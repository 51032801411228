import { SvgIcon } from "@material-ui/core";
import React, { FC } from "react";

import { icons } from "./iconsMapping";
import Props from "./Props";

/**
 * Deprecated use svg imports instead
 *
 * @deprecated
 * @param name
 * @param rest
 * @constructor
 */
const OcietyIcon: FC<Props> = ({ name, ...rest }: Props) => {
  return <SvgIcon {...rest}>{icons[name]}</SvgIcon>;
};

export default OcietyIcon;
