import { requiredTextValidationRulesDefault } from "../../../../../config/validation";
import IProfileFormSchema from "./IProfileFormSchema";

export const ProfileFormSchema: IProfileFormSchema = {
  firstName: {
    ...requiredTextValidationRulesDefault,
    length: {
      maximum: 64,
      minimum: 2,
    },
  },
  lastName: {
    ...requiredTextValidationRulesDefault,
    length: {
      maximum: 64,
      minimum: 2,
    },
  },
  email: {
    ...requiredTextValidationRulesDefault,
    email: true,
    length: {
      minimum: 3,
    },
  },
  phone: {
    ...requiredTextValidationRulesDefault,
    length: {
      maximum: 12,
      minimum: 11,
    },
  },
};
