import { BasicForm, BasicSchema } from "../../../../common/models";

export type Field = "comment";

export const defaultForm: BasicForm<Field> = {
  errors: {
    comment: "",
  },
  values: {
    comment: "",
  },
  isValid: true,
  touched: [],
};

export const schema: BasicSchema<Field> = {
  comment: {
    length: {
      min: 10,
      max: 1000,
    },
  },
};
