import { ChangeEvent, useState } from "react";

export function usePagination() {
  const [search, setSearch] = useState<string>();
  const [page, setPage] = useState(0);
  const [searchPage, setSearchPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const skip = !search ? page * rowsPerPage : searchPage * rowsPerPage;

  const handleSearch = (searchPhrase: string) => {
    setSearch(searchPhrase);

    if (!searchPhrase) {
      setSearchPage(0);
    }
  };

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return {
    search,
    page,
    rowsPerPage,
    searchPage,
    skip,
    setPage,
    setSearchPage,
    handleSearch,
    handleRowsPerPageChange,
  };
}

export default usePagination;
