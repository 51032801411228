import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import OcietyIcon from "../OcietyIcon";
import Props from "./Props";
import { useStyles } from "./styles";

const StepsIndicator: FC<Props> = (props: Props) => {
  const { currentStep, stepsNames } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {stepsNames.map((stepName, index) => (
        <div key={index} className={classes.stepContainer}>
          <div
            className={clsx(
              classes.stepWrapper,
              currentStep >= index + 1 && classes.stepWrapperActive,
              stepsNames.length - 1 === index && classes.lastStep
            )}
          />
          <div className={classes.textWrapper}>
            <OcietyIcon
              name={
                currentStep >= index + 1
                  ? "redCheckMarkCircle"
                  : "grayCheckMarkCircle"
              }
              width="28"
              height="28"
              viewBox="0 0 28 28"
            />
            <div className={classes.stepTitleWrapper}>
              <Typography
                component={"span"}
                variant={"subtitle2"}
                className={classes.stepNumber}>
                {dictionary.stepsIndicator.step(index + 1)}
              </Typography>
              <Typography component={"span"} className={classes.stepName}>
                {stepsNames[index]}
              </Typography>
            </div>
          </div>
          <div />
        </div>
      ))}
    </div>
  );
};
export default StepsIndicator;
