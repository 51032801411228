import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxLogger from "redux-logger";
import { persistStore } from "redux-persist";
import reduxPromiseMiddleware from "redux-promise-middleware";
import thunk from "redux-thunk";

import rootReducer from "./rootReducer";

const isProduction: boolean = process.env.NODE_ENV === "production";
const middleware = [thunk, reduxPromiseMiddleware];

if (!isProduction) {
  middleware.push(reduxLogger);
}

export const store = createStore(
  rootReducer,
  isProduction
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware))
);
export const persistor = persistStore(store);

export const purgePersistor = () => {
  persistor.purge();
};
