import { capitalize } from "@material-ui/core";

import HttpStatusCodes from "../common/httpStatusCodes";
import { MobileAppUserType } from "../common/models";
import { Permission } from "../permissions/permission";
import { store } from "../store";

/**
 * @deprecated
 *
 * Do not use this! May be unexpected issues!
 * Use useIsSuperAdmin hook instead (src/common/hooks/useIsSuperAdmin.ts)
 */
export const isAdmin = () => {
  const { roles } = store.getState().auth;
  return roles && roles.includes("ADMIN");
};

export const isLoggedIn = () => Boolean(store.getState().auth.hasToken);

export const havePermission = (permission: Permission) => {
  const { permissions } = store.getState().auth;

  return permissions && permissions.includes(permission);
};

export const isUnauthorized = (status: number) => {
  return status === HttpStatusCodes.UNAUTHORIZED;
};

export const isForbidden = (status: number) => {
  return status === HttpStatusCodes.FORBIDDEN;
};

export const formatUserTypeToString = (userType: MobileAppUserType) =>
  capitalize(userType.toLowerCase());
