import { ReportingPromotions } from "./Promotions/ReportingPromotions";
import { RBAC } from "./rbac";
import { GeneralReporting } from "./Reporting/GeneralReporting";
import { PromotionsReporting } from "./Reporting/PromotionsReporting";
import { ReportingRecommendations } from "./Reporting/ReportingRecommendations";

export enum Profile {
  VIEW = "PROFILE.VIEW",
  EDIT = "PROFILE.EDIT",
  CHANGE_PASSWORD = "PROFILE.CHANGE_PASSWORD",
}

export type Permission =
  | Profile
  | RBAC
  | PromotionsReporting
  | ReportingRecommendations
  | GeneralReporting
  | ReportingPromotions;

// Test
