import * as Sentry from "@sentry/react";
import React, { FC, useCallback, useContext, useEffect, useRef } from "react";
import { matchPath, Redirect, Switch, useHistory } from "react-router-dom";

import { AlertContext } from "./common/components/Alert/AlertContextProvider";
import PrivateRoute from "./common/components/PrivateRoute";
import RouteWithLayout from "./common/components/RouteWithLayout";
import { useDataEntryAdmin } from "./common/hooks/useDataEntryAdmin";
import { useIsDiscountAdmin } from "./common/hooks/useIsDiscountAdmin";
import { useIsManager } from "./common/hooks/useIsManager";
import { useIsSuperAdmin } from "./common/hooks/useIsSuperAdmin";
import { useMessaging } from "./common/hooks/useMessaging";
import { useWebsocket } from "./common/hooks/useWebsocket";
import { isPackagesDisabled, RBACK_ENABLED } from "./config";
import { subscribeResponse } from "./config/api";
import { getRouteWithSlash, Route } from "./config/router";
import MainLayout from "./layouts/Main";
import MinimalLayout from "./layouts/Minimal";
import BillingInformation from "./views/BillingInformation";
import ContactUs from "./views/ContactUs";
import CoreSettings from "./views/CoreSettings";
import CouponManagement from "./views/CouponManagement";
import CriticRequest from "./views/CriticRequest";
import Dashboard from "./views/Dashboard";
import DashboardSuperAdmin from "./views/DashboardSuperAdmin";
import DataEntryBusinesses from "./views/DataEntryBusinesses";
import ForgotPassword from "./views/ForgotPassword";
import Login from "./views/Login";
import ManagerPromotions from "./views/ManagerPromotions/Promotions";
import Messages from "./views/Messages";
import MoneyFlow from "./views/MoneyFlow";
import NotFound from "./views/NotFound";
import Plan from "./views/Plan";
import Profile from "./views/Profile";
import OcietyAdminPromotions from "./views/Promotions/AdminSuper";
import VenueAdminPromotions from "./views/Promotions/AdminVenue";
import PromotionsUsages from "./views/PromotionsUsages";
import RBACAdmins from "./views/RBAC/admins";
import RBAC from "./views/RBAC/main";
import RBACRolePermissions from "./views/RBAC/rolePermissions";
import RBACRoles from "./views/RBAC/roles";
import ReportedPhotos from "./views/ReportedPhotos";
import Reporting from "./views/Reporting";
import Reviews from "./views/Reviews";
import TermsAndConditions from "./views/StaticPages/TermsAndConditions";
import Subscriptions from "./views/Subscriptions";
import UserManagement from "./views/UserManagement";
import UserPhotos from "./views/UserPhotos";
import VenueAdminProfile from "./views/VenueAdminProfile";
import VenueClaim from "./views/VenueClaim";
import VenueClaimRequests from "./views/VenueClaimRequests";
import VenuePending from "./views/VenuePending";
import Venues from "./views/Venues";
import VenuesChangesPending from "./views/VenuesChangesPending";
import VenueSettings from "./views/VenueSettings";
import VenuesPending from "./views/VenuesPending";
import VenueStaff from "./views/VenueStaff/main";

const Routes: FC = () => {
  useWebsocket();
  useMessaging();

  const { showAlert } = useContext(AlertContext);
  const isSubscribed = useRef(false);
  const superAdmin = useIsSuperAdmin();
  const discountAdmin = useIsDiscountAdmin();
  const manager = useIsManager();
  const dataEntryAdmin = useDataEntryAdmin();
  const history = useHistory();

  function sendHello() {
    if (
      window.$crisp.is("chat:opened") === true &&
      !window.$crisp.is("session:ongoing")
    ) {
      window.$crisp.push([
        "do",
        "message:send",
        ["text", "Hey there! Let Ociety Team know if you need any help :)"],
      ]);
    }
  }

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_READY_TRIGGER = function () {
      window.$crisp.push(["on", "chat:opened", sendHello]);
    };
    window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_CHAT_WEBSITE_ID;
    (function () {
      const d = document;
      const s: any = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.id = "crisp";
      s.async = 1;
      if (!discountAdmin) {
        d.getElementsByTagName("head")[0].appendChild(s);
      } else {
        const linkElements = d.getElementsByTagName("link");
        for (let i = linkElements.length - 1; i >= 0; --i) {
          if (linkElements[i].href.includes("crisp")) {
            linkElements[i].remove();
          }
        }
        const chartElement = d.getElementById("crisp-chatbox");
        if (chartElement) {
          chartElement?.parentNode?.removeChild(chartElement);
        }
      }
    })();
  }, [discountAdmin]);

  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID) {
      (function (w, d, s, l, i) {
        // @ts-ignore
        w[l] = window.dataLayer || [];
        // @ts-ignore
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        // @ts-ignore
        j.async = true;
        // @ts-ignore
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        // @ts-ignore
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", process.env.REACT_APP_GTM_ID);
    }
  }, []);

  const showErrorMessage = useCallback(
    (message: string) => {
      showAlert(message, "error");
    },
    [showAlert]
  );

  // Moved from useEffect, because need to subscribe before app's first render
  if (!isSubscribed.current) {
    subscribeResponse((message: string) => {
      showErrorMessage(message);
    });

    isSubscribed.current = true;
  }

  const routes: { path: string }[] = Object.values(Route).map((value) => ({
    path: getRouteWithSlash(value),
  }));
  Sentry.init({
    dsn: "https://a278c6a7baa01758fcebcb44a4c75fa6@o4505516896681984.ingest.sentry.io/4505879024631813",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          history,
          routes,
          matchPath
        ),
      }),
    ],
    tracesSampleRate:
      process.env.REACT_APP_SENTRY_ENVIRONMENT === "production" ? 0.05 : 1.0,
  });

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to={
          discountAdmin
            ? getRouteWithSlash(Route.ManagerDeals)
            : dataEntryAdmin
            ? getRouteWithSlash(Route.DataEntryBusinesses)
            : getRouteWithSlash(Route.Dashboard)
        }
      />
      {(superAdmin || manager) && (
        <PrivateRoute
          component={OcietyAdminPromotions}
          exact
          layout={MainLayout}
          path="/deals"
        />
      )}
      {!superAdmin && !manager && !dataEntryAdmin && (
        <PrivateRoute
          component={VenueAdminPromotions}
          exact
          layout={MainLayout}
          path="/deals"
        />
      )}
      {!isPackagesDisabled && !superAdmin && !manager && (
        <PrivateRoute
          component={BillingInformation}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.BillingInformation)}
        />
      )}
      {!discountAdmin && !dataEntryAdmin && (
        <PrivateRoute
          component={PromotionsUsages}
          exact
          layout={MainLayout}
          path="/deals/:id/usages"
        />
      )}
      {dataEntryAdmin && (
        <PrivateRoute
          layout={MinimalLayout}
          component={DataEntryBusinesses}
          path={getRouteWithSlash(Route.DataEntryBusinesses)}
        />
      )}
      <RouteWithLayout
        component={Venues}
        exact
        layout={MinimalLayout}
        path="/businesses/:uuid?"
      />
      <RouteWithLayout
        component={ContactUs}
        exact
        layout={MinimalLayout}
        path="/contact-us"
      />
      <RouteWithLayout
        component={TermsAndConditions}
        exact
        layout={MinimalLayout}
        path="/terms-and-conditions"
      />
      <RouteWithLayout
        component={() => {
          window.location.href = "https://help.ociety.com/";
          return null;
        }}
        exact
        layout={MinimalLayout}
        path="/help"
      />
      {!discountAdmin && !dataEntryAdmin && (
        <PrivateRoute
          component={Profile}
          exact
          layout={MainLayout}
          path="/profile"
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          // component={VenueAdminPromotions}
          component={OcietyAdminPromotions}
          exact
          layout={MainLayout}
          path="/deals"
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={VenuesPending}
          exact
          layout={MainLayout}
          path="/venues-pending"
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={VenuePending}
          exact
          layout={MainLayout}
          path="/venue-pending/:id"
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={VenuesChangesPending}
          exact
          layout={MainLayout}
          path="/venues-changes-pending"
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={UserPhotos}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.UserPhotos)}
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={ReportedPhotos}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.ReportedMedia)}
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={VenueClaimRequests}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.VenuesClaimRequests)}
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={VenueClaim}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.VenueClaim)}
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={Subscriptions}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.Subscriptions)}
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={UserManagement}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.UserManagement)}
        />
      )}
      {superAdmin && (
        <PrivateRoute
          component={CouponManagement}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.CouponManagement)}
        />
      )}
      {superAdmin && (
        <PrivateRoute
          component={MoneyFlow}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.MoneyFlow)}
        />
      )}
      {(superAdmin || manager) && (
        <PrivateRoute
          component={CriticRequest}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.CriticRequest)}
        />
      )}
      <PrivateRoute
        component={VenueAdminProfile}
        exact
        layout={MainLayout}
        path="/profile-old"
      />
      {(superAdmin || manager) && RBACK_ENABLED && (
        <PrivateRoute component={RBAC} exact layout={MainLayout} path="/rbac" />
      )}
      {(superAdmin || manager) && RBACK_ENABLED && (
        <PrivateRoute
          component={RBACRoles}
          exact
          layout={MainLayout}
          path="/rbac/roles"
        />
      )}
      {!discountAdmin && !dataEntryAdmin && (
        <PrivateRoute
          component={VenueStaff}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.VenueStaff)}
        />
      )}
      {!discountAdmin && !dataEntryAdmin && (
        <PrivateRoute
          component={superAdmin || manager ? DashboardSuperAdmin : Dashboard}
          exact
          layout={MainLayout}
          path="/dashboard"
        />
      )}
      {!superAdmin && !discountAdmin && !manager && !dataEntryAdmin && (
        <PrivateRoute
          component={Messages}
          exact
          layout={MainLayout}
          path="/messages"
        />
      )}
      {!superAdmin && !discountAdmin && !manager && !dataEntryAdmin && (
        <PrivateRoute
          component={Reporting}
          exact
          layout={MainLayout}
          path="/reporting"
        />
      )}
      {/*{!superAdmin && !discountAdmin && !manager &&(
        <PrivateRoute
          component={Recommendations}
          exact
          layout={MainLayout}
          path="/recommendations"
        />
      )}*/}
      {!superAdmin && !discountAdmin && !manager && !dataEntryAdmin && (
        <PrivateRoute
          component={Reviews}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.Reviews)}
        />
      )}
      {!discountAdmin && !dataEntryAdmin && (
        <PrivateRoute
          component={VenueSettings}
          exact
          layout={MainLayout}
          path={
            superAdmin || manager
              ? getRouteWithSlash(Route.VenueSettingsAdmin)
              : getRouteWithSlash(Route.VenueSettings)
          }
        />
      )}
      {!superAdmin && !discountAdmin && !manager && !dataEntryAdmin && (
        <PrivateRoute component={Plan} exact layout={MainLayout} path="/plan" />
      )}
      {superAdmin &&
        !discountAdmin &&
        RBACK_ENABLED &&
        manager &&
        !dataEntryAdmin && (
          <PrivateRoute
            component={RBACAdmins}
            exact
            layout={MainLayout}
            path="/rbac/admins"
          />
        )}
      {superAdmin &&
        !discountAdmin &&
        manager &&
        RBACK_ENABLED &&
        !dataEntryAdmin && (
          <PrivateRoute
            component={RBACRolePermissions}
            exact
            layout={MainLayout}
            path="/rbac/role/:number"
          />
        )}
      {discountAdmin && (
        <PrivateRoute
          component={ManagerPromotions}
          exact
          layout={MainLayout}
          path={getRouteWithSlash(Route.ManagerDeals)}
        />
      )}
      <RouteWithLayout
        className={"no-header"}
        component={Login}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        className={"no-header"}
        component={ForgotPassword}
        exact
        layout={MinimalLayout}
        path="/forgot-password"
      />
      {superAdmin && !discountAdmin && !manager && (
        <PrivateRoute
          component={CoreSettings}
          exact
          layout={MainLayout}
          path="/settings"
        />
      )}
      <RouteWithLayout
        component={NotFound}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      {history.location.pathname === "/venues" ? (
        <Redirect to="/businesses" />
      ) : (
        <Redirect to="/not-found" />
      )}
    </Switch>
  );
};

export default Routes;
