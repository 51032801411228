import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "root"
  | "contentContainer"
  | "container"
  | "name"
  | "currency"
  | "priceContainer"
  | "price"
  | "benefitItem"
  | "period";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
      },
      priceContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(6),
      },
      name: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(22),
        textAlign: "center",
      },
      benefitItem: {
        padding: "5px 0",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(17),
      },
      currency: {
        fontSize: theme.typography.pxToRem(34),
        fontWeight: 900,
        marginRight: 7,
        marginTop: -20,
      },
      price: {
        fontWeight: 800,
        fontSize: theme.typography.pxToRem(70),
        color: colors.green.additional,
      },
      period: {
        marginTop: 17,
        fontSize: theme.typography.pxToRem(22),
        fontWeight: 600,
        marginLeft: 7,
      },
      container: {
        padding: theme.spacing(5),
      },
      contentContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundColor: "rgba(247, 248, 249, 0.51)",
        paddingLeft: theme.spacing(7),
      },
    })
);
