import moment from "moment";

export type DataMappedByShortWeekDays = {
  [key in WekDaysShort]: any;
};

export type WekDaysShort =
  | "sun"
  | "mon"
  | "tue"
  | "wed"
  | "thu"
  | "fri"
  | "sat";

export const shortWeekDays: WekDaysShort[] = [
  "sun",
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
];

export const weekDaysFullName = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export enum weekDaysMapping {
  sun = "Sunday",
  mon = "Monday",
  tue = "Tuesday",
  wed = "Wednesday",
  thu = "Thursday",
  fri = "Friday",
  sat = "Saturday",
}

export const getShortWeekDay = (
  date: Date,
  toLowercase = false
): WekDaysShort | string => {
  const dayName = moment(date).format("ddd");

  return toLowercase ? dayName.toLowerCase() : dayName;
};

export const sortDataByWeekDays = (data: DataMappedByShortWeekDays) => {
  const mappedData = Object.entries(data);

  return mappedData.sort((a, b) => {
    const prevDayShortName = a[0] as WekDaysShort;
    const nextDayShortName = b[0] as WekDaysShort;

    return (
      shortWeekDays.indexOf(prevDayShortName) -
      shortWeekDays.indexOf(nextDayShortName)
    );
  });
};

// time format is hh:mm:ss
export const getMomentFromTimeString = (time: string) => {
  return moment(
    new Date().toString().replace(/([0-9]+:[0-9]{2})(:[0-9]{2})/, time)
  );
};

export const getFormattedDate = (date: Date | string) =>
  moment(date).format("MM/DD/yyyy");

export const formatDateToDayMonthYear = (date: Date) => {
  return moment(date).format("D MMM YYYY");
};

export const formatDateForMessages = (date: any) => {
  const dateMoment = moment(date);
  const isToday = moment(new Date()).isSame(dateMoment, "day");

  return isToday ? dateMoment.format("hh:mm A") : dateMoment.format("MMM DD");
};

export const formatHoursToMeridiemWithoutPostMeridiem = (date: any) =>
  moment(date, "HH:mm:ss").format("hh:mm");

export const formatHoursToPostMeridiem = (date: any) =>
  moment(date, "HH:mm:ss").format("A");

export const formatToNextBillingDate = (date: any) =>
  moment(new Date(date)).format("MMM DD, YYYY");

export const getUniqueTimeId = (symbolsQty = 0) => {
  const timestampString = new Date().getTime().toString();
  const length = timestampString.length;

  return parseInt(timestampString.slice(length - symbolsQty, length));
};

export const getTimeAndDate = (date: Date | string) =>
  moment(date).format("MM/DD/yyyy, hh:mm:ss a");

export const formatToDateAndTimeWithTimeZoneAndUTC = (
  date: Date | string | number
) => moment(date).format("DD MMM YYYY hh:mm:ss A (UTCZ)");
