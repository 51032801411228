import { Tab, Tabs } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { Review } from "../../../../common/models";
import dictionary from "../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../store/rootReducer";
import CriticReviews from "./CriticReviews";
import {
  CRITIC_REVIEW_LIMIT,
  getVenueCriticReviews,
  VenueCriticReviewsResponse,
} from "./CriticReviews/api";
import { useStyles } from "./styles";
import UserReviews from "./UserReviews";

enum ReviewTabsEnum {
  userReviews,
  criticReviews,
}
const ReviewsTabs: FC = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(ReviewTabsEnum.userReviews);
  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };
  const currentVenueId = useCurrentVenueId();
  const [activeReview, setActiveReview] = useState<Review | null>(null);
  const user = useSelector((state: AppState) => state.user);

  const [reviewsData, setReviewsData] = useState<VenueCriticReviewsResponse>({
    reviews: [],
    count: 0,
  });

  useEffect(() => {
    getVenueCriticReviews(currentVenueId, CRITIC_REVIEW_LIMIT).then(
      (response) => {
        const [activeReview] = response.reviews;
        setActiveReview(activeReview);
        setReviewsData(response);
      }
    );
  }, [currentVenueId]);

  function tabsProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTab}
        className={classes.tabsWrapper}>
        <Tab
          label={dictionary.reviews.tabs.userReviews}
          classes={{
            root: classes.tab,
          }}
          {...tabsProps(0)}
        />
        {reviewsData.reviews.length > 0 && activeReview && (
          <Tab
            label={dictionary.reviews.tabs.criticReviews}
            classes={{
              root: classes.tab,
            }}
            {...tabsProps(1)}
          />
        )}
      </Tabs>
      {(tab === ReviewTabsEnum.userReviews && <UserReviews />) ||
        (reviewsData.reviews.length > 0 && activeReview && (
          <CriticReviews
            reviewsData={reviewsData}
            activeReview={activeReview}
            user={user}
          />
        ))}
    </div>
  );
};

export default ReviewsTabs;
