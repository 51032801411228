import React, { FC, useContext, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartContainer from "../../../../../../common/components/ChartContainer";
import ChartTooltip from "../../../../../../common/components/ChartTooltip";
import { VenueReportingContext } from "../../../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../../../common/hooks/useRequestByPeriod";
import { useVenueHasPackage } from "../../../../../../common/hooks/useVenueHasPacakage";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { PackageUID } from "../../../../../../store/currentVenue/types";
import {
  chartAdditionalColor,
  chartPrimaryColor,
  chartSecondaryColor,
  colors,
} from "../../../../../../theme/colors";
import {
  BarDefaultProps,
  LegendDefaultProps,
  XAxisDefaultProps,
  YAxisDefaultProps,
} from "../../../../defaultProps";
import {
  GET_MOST_POPULAR_NEARBY_VENUES,
  GetPopularNearbyVenuesResponse,
  PopularNearbyVenuesData,
} from "./api";
import { chartDataMock, mockVenuesNames } from "./mock";

const MostPopularVenuesNearby: FC = () => {
  const [chartData, setChartData] = useState(chartDataMock);
  const { filters, handlePackageName } = useContext(VenueReportingContext);
  const hasPackage = useVenueHasPackage(PackageUID.RECOMMENDATIONS);
  const { response, loading } =
    useRequestByPeriod<GetPopularNearbyVenuesResponse>(
      GET_MOST_POPULAR_NEARBY_VENUES,
      filters,
      hasPackage
    );
  const { firstVenueName, secondVenueName, thirdVenueName } =
    hasPackage && response ? response : mockVenuesNames;

  const parseChartData = (stats: PopularNearbyVenuesData[]) => {
    setChartData(
      stats.map((viewsData) => ({
        ...viewsData,
        month: viewsData.month.slice(0, 3),
      }))
    );
  };

  useEffect(() => {
    if (response) {
      parseChartData(response.stats);
    }

    if (!hasPackage) {
      setChartData(chartDataMock);
    }
  }, [response, hasPackage]);

  const isChartEmpty = !chartData.some(
    (data) => (data.first || data.second || data.third) !== 0
  );

  return (
    <ChartContainer
      loading={loading}
      locked={!hasPackage}
      isChartEmpty={isChartEmpty}
      title={dictionary.reporting.recommendationsPopularNearbyVenuesTitle}
      chartType={"bar-with-y-axis-and-legend-bottom"}
      packageName={handlePackageName(PackageUID.RECOMMENDATIONS)}>
      <BarChart data={chartData}>
        <CartesianGrid
          strokeDasharray="3 3"
          strokeOpacity={0.6}
          color={colors.grey.light}
          vertical={false}
        />
        <Legend {...LegendDefaultProps} />
        <Bar
          {...BarDefaultProps}
          name={firstVenueName}
          dataKey={"first"}
          fill={chartSecondaryColor}
          unit={dictionary.reporting.mostPopularNearbyVenuesUnit(
            firstVenueName
          )}
        />
        <Bar
          {...BarDefaultProps}
          dataKey={"second"}
          name={secondVenueName}
          fill={chartPrimaryColor}
          unit={dictionary.reporting.mostPopularNearbyVenuesUnit(
            secondVenueName
          )}
        />
        <Bar
          {...BarDefaultProps}
          dataKey={"third"}
          name={thirdVenueName}
          fill={chartAdditionalColor}
          unit={dictionary.reporting.mostPopularNearbyVenuesUnit(
            thirdVenueName
          )}
        />
        <YAxis {...YAxisDefaultProps} />
        <XAxis {...XAxisDefaultProps} dataKey="month" />
        <Tooltip
          content={<ChartTooltip />}
          cursor={{
            stroke: chartAdditionalColor,
            strokeWidth: 2,
            opacity: 0.3,
          }}
        />
      </BarChart>
    </ChartContainer>
  );
};

export default MostPopularVenuesNearby;
