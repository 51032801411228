import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "buttonCreate" | "buttonCreateIcon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      buttonCreate: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(5),
      },
      buttonCreateIcon: {
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(1),
      },
    })
);
