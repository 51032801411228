import { http } from "../../../../config/api";
import { AddressLocation } from "../../../models";

export const GET_ALL_STATES = (country?: string) =>
  `venue/location/states?country=${country || "USA"}`;

export const getAllStates = (country?: string): Promise<AddressLocation[]> =>
  http
    .get(GET_ALL_STATES(country))
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
