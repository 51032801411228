import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "uncheckedIcon"
  | "checkedIcon"
  | "radioWrapper"
  | "radioLabel";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      uncheckedIcon: {
        width: 20,
        height: 20,
        borderRadius: "50%",
        border: `1px solid ${colors.grey.disabled}`,
      },
      checkedIcon: {
        width: 20,
        height: 20,
        borderRadius: "50%",
        boxShadow: "0px 1px 3px rgba(254, 84, 84, 0.3)",
        border: `5px solid ${colors.red.primary}`,
      },
      radioWrapper: {
        margin: 0,
        padding: 16,
        width: "100%",
      },
      radioLabel: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: theme.typography.pxToRem(24),
      },
    })
);
