import { InputAdornment, TextField } from "@material-ui/core";
import React, { FC } from "react";

import OcietyIcon from "../../../../../common/components/OcietyIcon";
import dictionary from "../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const ManagerSearch: FC<Props> = (props: Props) => {
  const { handleSearch, searchValue, setSearchValue } = props;
  const classes = useStyles();

  const searchManagers = (searchString: string) => {
    setSearchValue(searchString);
    handleSearch(searchString);
  };

  return (
    <TextField
      required
      fullWidth
      name="currentPass"
      className={classes.search}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <OcietyIcon
              name={"greySearchButton"}
              width={16}
              height={16}
              viewBox="0 0 16 16"
            />
          </InputAdornment>
        ),
      }}
      onChange={(event: any) => {
        searchManagers(event.target.value);
      }}
      placeholder={dictionary.venueStaff.search}
      value={searchValue}
      type="text"
      variant="outlined"
    />
  );
};

export default ManagerSearch;
