import moment from "moment";

import { Form } from "../../../../../../common/models";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { ValidationRules } from "../../../../../../utils/validation";
import { PromotionLimitType, PromotionType } from "../../../enums";

export type PromotionMainFormField =
  | "isPlanetPromo"
  | "type"
  | "categoryId"
  | "subCategoryId"
  | "otherSubcategoryName"
  | "start"
  | "end"
  | "description"
  | "limitType"
  | "limitPercent"
  | "limitMaxAmount"
  | "limitItemsName"
  | "limitPrice"
  | "rebatedPrice"
  | "dietaryRestrictions"
  | "kind"
  | "restrictions"
  | "name";

export type PromotionMainFormValues = {
  [key in PromotionMainFormField]: string;
};

export type PromotionMainFormErrors = {
  [key in PromotionMainFormField]: string;
};

export interface IPromotionMainForm extends Form {
  values: PromotionMainFormValues;
  errors: PromotionMainFormErrors;
  touched: PromotionMainFormField[];
}

export const PromotionMainForm: IPromotionMainForm = {
  values: {
    name: "",
    type: PromotionType.FOOD,
    categoryId: "",
    subCategoryId: "",
    otherSubcategoryName: "",
    start: moment().format("YYYY-MM-DD"),
    end: moment().add(1, "days").format("YYYY-MM-DD"),
    description: "",
    limitType: PromotionLimitType.SINGLE,
    limitPercent: "0",
    limitMaxAmount: "0",
    limitPrice: "0",
    rebatedPrice: "0",
    limitItemsName: "",
    dietaryRestrictions: "",
    kind: "DEAL",
    restrictions: "",
    isPlanetPromo: "0",
  },
  errors: {
    type: "",
    categoryId: "",
    subCategoryId: "",
    otherSubcategoryName: "",
    start: "",
    end: "",
    description: "",
    limitType: "",
    limitPercent: "",
    limitMaxAmount: "",
    limitPrice: "",
    limitItemsName: "",
    dietaryRestrictions: "",
    rebatedPrice: "",
    kind: "",
    restrictions: "",
    name: "",
    isPlanetPromo: "",
  },
  touched: [],
  isValid: true,
};

export type PromotionMainFormSchemaType = Record<
  PromotionMainFormField,
  ValidationRules
>;

export const limitItemsNameBaseRules: ValidationRules = {
  fieldName: dictionary.deals.createDealForm.itemsName,
  required: true,
  length: {
    min: 2,
    max: 512,
  },
};

export const percentBasesRules: ValidationRules = {
  fieldName: dictionary.deals.createDealForm.percent,
  type: "percentNumber",
  required: true,
};

export const rebatedPriceBasesRules: ValidationRules = {
  type: "number+",
  required: true,
  maxNumberValue: 200,
};

export const otherSubcategoryBasesRules: ValidationRules = {
  fieldName: dictionary.deals.createDealForm.provideSubcategoryNameIfOther,
  length: {
    min: 2,
    max: 20,
  },
  required: false,
};

export const PromotionMainFormSchema: PromotionMainFormSchemaType = {
  name: {},
  type: {
    required: true,
  },
  categoryId: {
    type: "number",
  },
  subCategoryId: {
    type: "number",
  },
  otherSubcategoryName: {
    ...otherSubcategoryBasesRules,
  },
  start: {},
  end: { type: "endDate" },
  description: {
    required: false,
  },
  restrictions: {
    required: false,
  },
  limitType: {},
  limitPercent: {
    ...percentBasesRules,
  },
  limitMaxAmount: {
    fieldName: dictionary.deals.createDealForm.maxAmountFieldName,
    type: "number",
  },
  rebatedPrice: rebatedPriceBasesRules,
  limitPrice: {},
  limitItemsName: limitItemsNameBaseRules,
  dietaryRestrictions: {},
  kind: {},
  isPlanetPromo: {
    required: false,
    type: "number",
  },
};
