import { TextField, Typography } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import clsx from "clsx";
import React, { FC, useEffect, useRef, useState } from "react";

import LoadingIndicator from "../../../../../common/components/LoadingIndicator";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { TwoFactoryAuthTypeEnum } from "../../../../../store/auth/types";
import { useStyles } from "../styles";
import Props from "./Props";

const VerificationCode: FC<Props> = (props: Props) => {
  const {
    claimVenueForm,
    claimVenueVerifyForm,
    loading,
    errorMsg,
    handleResendCode,
    isAttachmentOption,
    resendType,
    handleOpenAttachmentModal,
    handleChangeClaimVerifyForm,
  } = props;
  const classes = useStyles();
  const [counter, setCounter] = useState(30);
  const [shouldTimerStart, setShouldTimerStart] = useState(true);

  const intervalRef = useRef<any>();

  const showTimer = async () => {
    await handleResendCode();
  };

  const decreaseCounter = () => setCounter((counter) => counter - 1);

  useEffect(() => {
    if (shouldTimerStart) {
      intervalRef.current = setInterval(decreaseCounter, 1000);

      if (counter == -1) {
        clearInterval(intervalRef.current);
        setCounter(30);
        setShouldTimerStart(false);
      }
    }

    return () => clearInterval(intervalRef.current);
  }, [shouldTimerStart, counter, setShouldTimerStart]);

  return (
    <>
      <DialogContent dividers className={classes.root}>
        <form>
          <Typography
            variant="subtitle1"
            className={classes.verificationSubtitle}>
            {claimVenueVerifyForm.values.type === TwoFactoryAuthTypeEnum.EMAIL
              ? dictionary.venues.claimVenueDialog.successSentCodeEmail(
                  claimVenueForm.values.email
                )
              : dictionary.venues.claimVenueDialog.successSentCodePhone(
                  claimVenueForm.values.phoneNumber
                )}
            <a href="mailto:help@ociety.com">help@ociety.com</a>
          </Typography>
          <TextField
            required
            error={!!claimVenueVerifyForm.errors.code || !!errorMsg}
            fullWidth
            helperText={claimVenueVerifyForm.errors.code || errorMsg}
            label={
              dictionary.venues.claimVenueDialog.verificationCodeFieldLabel
            }
            name="code"
            onChange={handleChangeClaimVerifyForm}
            type="text"
            value={claimVenueVerifyForm.values.code || ""}
            variant="outlined"
          />
          <div className={classes.resendWrapper}>
            {shouldTimerStart ? (
              <>
                <Typography variant="body1" className={classes.helperResendTxt}>
                  {dictionary.venues.claimVenueDialog.didNotGetCode}
                </Typography>
                <Typography className={classes.timer}>
                  {dictionary.venues.claimVenueDialog.codeTimer(counter)}
                </Typography>
              </>
            ) : (
              <Typography
                onClick={showTimer}
                className={clsx(classes.timer, classes.cursor)}>
                {resendType === TwoFactoryAuthTypeEnum.EMAIL
                  ? dictionary.venues.claimVenueDialog.resendLabel
                  : dictionary.venues.claimVenueDialog.recallLabel}
              </Typography>
            )}
          </div>
          {isAttachmentOption && (
            <div className={classes.resendWrapper}>
              <Typography className={classes.helperResendTxt}>
                {dictionary.venues.claimVenueDialog.didNotGet}
                <span
                  onClick={handleOpenAttachmentModal}
                  className={clsx(classes.timer, classes.cursor)}>
                  {dictionary.venues.claimVenueDialog.clickHereLabel}
                </span>
                {dictionary.venues.claimVenueDialog.sendDocumentLabel}
              </Typography>
            </div>
          )}
        </form>
        {loading && <LoadingIndicator withMask />}
      </DialogContent>
    </>
  );
};

export default VerificationCode;
