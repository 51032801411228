import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import ButtonPrimary from "../../common/components/ButtonPrimary";
import { useGlobalStyles } from "../../common/styles";
import { Route } from "../../config/router";
import dictionary from "../../i18n/en_US/dictionary";
import { useStyles } from "./styles";

const NotFound: FC = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const history = useHistory();

  const onHomeClick = () => {
    history.push(Route.Home);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div
          className={clsx(
            globalClasses.flexColumnCenterCenter,
            classes.contentWrapper
          )}>
          <Typography className={classes.text404}>404</Typography>
          <Typography className={classes.pageTitle}>
            {dictionary.notFound.title}
          </Typography>
          <Typography className={classes.pageDescription}>
            {dictionary.notFound.description}
          </Typography>
          <ButtonPrimary className={classes.toHomeButton} onClick={onHomeClick}>
            {dictionary.notFound.toHomeButton}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
