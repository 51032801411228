import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "icon"
  | "iconHorizontal"
  | "iconVertical"
  | "iconArrow"
  | "description"
  | "descriptionVertical"
  | "link"
  | "linkDisabled"
  | "linkContent";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        height: "100%",
        minHeight: 170,
        position: "relative",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(5, "10%"),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(3, 4),
        },
      },
      description: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(18),
        wordBreak: "break-word",
      },
      descriptionVertical: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(18),
        wordBreak: "break-word",
        marginBottom: theme.spacing(1.5),
      },
      icon: {
        width: 62,
        height: 62,
      },
      iconHorizontal: {
        marginRight: theme.spacing(3),
      },
      iconVertical: {
        marginBottom: theme.spacing(3),
      },
      iconArrow: {
        position: "absolute",
        transition: theme.transitions.create("right", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up("md")]: {
          top: theme.spacing(3.5),
          right: theme.spacing(3.5),
        },
        [theme.breakpoints.down("md")]: {
          top: theme.spacing(2.5),
          right: theme.spacing(2.5),
        },
      },
      linkDisabled: {
        pointerEvents: "none",
      },
      link: {
        display: "block",
        height: "100%",
        "&:hover, &:active": {
          textDecoration: "none",
        },
        "&:not(.disableLink):hover $iconArrow": {
          [theme.breakpoints.up("md")]: {
            right: theme.spacing(4.5),
          },
        },
      },
      linkContent: {
        height: "100%",
      },
    })
);
