import { TablePagination } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { FC, useContext, useState } from "react";

import RangeAmountSlider from "../../../../../../common/components/RangeAmountSlider";
import { ReactComponent as SortingActive } from "../../../../../../common/svg/icons/column-sorting-active-icon.svg";
import { ReactComponent as SortingDefault } from "../../../../../../common/svg/icons/column-sorting-default-icon.svg";
import { ReactComponent as SearchIcon } from "../../../../../../common/svg/icons/search-icon.svg";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { transformDineroAmountToDecimalString } from "../../../../../Promotions/AdminVenue/dialogs/PromotionFormDialog/components/PromotionFormDialogContent/utils";
import VenuesSearchEmpty from "../../../../../Venues/components/VenuesSearchEmpty";
import {
  HeadCell,
  SearchByAmountColumn,
  SearchByNameColumn,
  TransactionItem,
} from "../../../../models";
import { TransactionsContext } from "../../../../providers";
import AutoCompleteTransactionsSearch from "../AutoCompleteSearch";
import FilterCheckboxPopup from "../FilterCheckboxPopup";
import Row from "./components/Row";
import Props from "./Props";
import { useStyles } from "./styles";

export const filterCheckboxFields = ["kind", "type"];
export const searchFields = [
  "userEmail",
  "venueName",
  "discountName",
  "referralCode",
  "promoCodeName",
];
export const amountRangeFields = [
  "fiatAmount",
  "couponAmount",
  "creditsAmount",
  "fee",
  "discountRebatedPrice",
];

const MoneyFullTable: FC<Props> = (props: Props) => {
  const {
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    handlePageChange,
    count,
  } = props;
  const classes = useStyles();
  const {
    transactionsList,
    order,
    setOrderBy,
    setOrder,
    orderBy,
    headCellsState,
    filterOptions,
    searchFilters,
    setTableLoading,
    setSearchFilters,
  } = useContext(TransactionsContext);
  const [isOpenedFieldsSearch, setIsOpenedFieldsSearch] = useState<string[]>(
    []
  );

  const handleOpenFieldSearch = (field: keyof TransactionItem) => {
    setIsOpenedFieldsSearch((prevState) => {
      prevState.push(field);
      return prevState;
    });
  };

  const handleCloseFieldSearch = (field: keyof TransactionItem) => {
    setIsOpenedFieldsSearch((prevState) =>
      prevState.filter((column) => column !== field)
    );
  };

  const createSortHandler =
    (property: keyof TransactionItem) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TransactionItem
  ) => {
    const isAsc = orderBy === property && order === "ASC";
    setOrder(isAsc ? "DESC" : "ASC");
    setOrderBy(property);
  };

  const searchIcon = (column: HeadCell) => {
    if (
      searchFields.includes(column.id) &&
      filterOptions[column.id as keyof SearchByNameColumn]
    ) {
      return (
        <SearchIcon
          onClick={() => handleOpenFieldSearch(column.id)}
          className={classes.searchIcon}
        />
      );
    }

    return null;
  };

  const handleChangeAmountRangeFilter =
    (column: keyof SearchByAmountColumn) => (range: number[]) => {
      setTableLoading(true);
      setSearchFilters((prevFilters) => {
        return {
          ...prevFilters,
          [column]: range,
        };
      });
      setTableLoading(false);
    };

  const checkBoxSearchIcon = (column: HeadCell) => {
    if (filterCheckboxFields.includes(column.id)) {
      const options = Object.entries(
        dictionary.moneyForm.transactionCheckboxes[column.id as "type" | "kind"]
      ).map(([id, label]) => ({ id, label }));

      return (
        <FilterCheckboxPopup
          column={column.id}
          options={options}
          searchFilters={searchFilters}
        />
      );
    }

    if (amountRangeFields.includes(column.id)) {
      const minRange =
        column.id === "fee"
          ? Number(filterOptions[column.id as keyof SearchByAmountColumn][0])
          : Number(
              transformDineroAmountToDecimalString(
                filterOptions[column.id as keyof SearchByAmountColumn][0]
              )
            ) || 0;
      const maxRange =
        column.id === "fee"
          ? Number(filterOptions[column.id as keyof SearchByAmountColumn][1])
          : Number(
              transformDineroAmountToDecimalString(
                filterOptions[column.id as keyof SearchByAmountColumn][1]
              )
            ) || 0;

      return (
        <RangeAmountSlider
          minRange={minRange}
          maxRange={maxRange}
          handleSetRange={(range) =>
            handleChangeAmountRangeFilter(
              column.id as keyof SearchByAmountColumn
            )(range)
          }
        />
      );
    }
    return null;
  };

  const searchFieldsInput = (column: HeadCell) => {
    if (
      searchFields.includes(column.id) &&
      filterOptions[column.id as keyof SearchByNameColumn]
    ) {
      return (
        <AutoCompleteTransactionsSearch
          options={filterOptions[column.id as keyof SearchByNameColumn]}
          column={column}
          handleClose={() => handleCloseFieldSearch(column.id)}
        />
      );
    }

    return null;
  };

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {headCellsState.map((headCell) => {
                if (!headCell.isSelected) return null;

                return (
                  <TableCell
                    key={headCell.id}
                    className={`${classes.tableHeadCell} ${classes.stickyHeader}`}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {isOpenedFieldsSearch.includes(headCell.id) ? (
                        searchFieldsInput(headCell)
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={createSortHandler(
                            headCell.id as keyof TransactionItem
                          )}>
                          {headCell.label}
                          {orderBy === headCell.id && order === "DESC" ? (
                            <SortingActive />
                          ) : (
                            <SortingDefault />
                          )}
                          {searchIcon(headCell)}
                        </div>
                      )}
                      {checkBoxSearchIcon(headCell)}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableHeadBody}>
            {transactionsList.map((row) => {
              return <Row key={row.id} row={row as any} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!transactionsList.length && <VenuesSearchEmpty />}
      <TablePagination
        component="div"
        count={count}
        onChangePage={(_, page) => handlePageChange(page)}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleRowsPerPageChange}
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
      />
    </>
  );
};

export default MoneyFullTable;
