import MomentUtils from "@date-io/moment";
import {
  Button,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import React, { ChangeEvent, FC, useEffect, useRef } from "react";

import { useForm } from "../../../../../../../../common/hooks";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { EditHolidayField, editHolidayForm, schema } from "./form";
import Props from "./Props";
import { useStyles } from "./styles";

const HolidaysEdit: FC<Props> = (props) => {
  const { currentHoliday, setCurrentHoliday } = props;
  const { startTime, name } = currentHoliday;
  const { form, setAndValidate, setFormValues } = useForm(
    schema,
    editHolidayForm
  );
  const classes = useStyles();
  const didMount = useRef(false);

  const handleChangeDate = (date: MaterialUiPickersDate) => {
    if (date) {
      setAndValidate("date", date.format("MM/DD/yyyy"));
    }
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setAndValidate(e.target.name as EditHolidayField, e.target.value);
  };

  useEffect(() => {
    if (form.isValid) {
      setCurrentHoliday((prevHoliday) => {
        return (
          prevHoliday && {
            ...prevHoliday,
            startTime: form.values.date,
            endTime: form.values.date,
            name: form.values.name,
          }
        );
      });
    }
  }, [form.values, form.isValid, setCurrentHoliday]);

  const selectTodayDate = () => {
    handleChangeDate(moment(new Date()));
  };

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      setFormValues({
        name,
        date: startTime,
      });
    }
  }, [setFormValues, name, startTime]);

  return (
    <div className={classes.root}>
      <Typography variant={"body1"} className={classes.selectDateTitle}>
        {dictionary.venues.venueForm.holidaysSelectDateTitle}
      </Typography>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Paper className={classes.calendarWrapper}>
          <DatePicker
            disableToolbar
            margin={"normal"}
            variant={"static"}
            format="MM/DD/yyyy"
            minDate={moment(new Date())}
            value={moment(form.values.date)}
            onChange={handleChangeDate}
          />
          <Button
            onClick={selectTodayDate}
            variant={"text"}
            color={"primary"}
            className={classes.todayButton}>
            {dictionary.venues.venueForm.holidaysSetTodayDateButton}
          </Button>
        </Paper>
      </MuiPickersUtilsProvider>
      <FormControl fullWidth>
        <TextField
          fullWidth
          required
          label={dictionary.venues.venueForm.holidaysNameFieldLabel}
          name="name"
          error={!!form.errors.name}
          helperText={form.errors.name}
          onChange={handleChangeName}
          type="text"
          value={form.values.name}
          variant="outlined"
        />
      </FormControl>
    </div>
  );
};

export default HolidaysEdit;
