import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../common/models";

export type ClassNames = "root" | "btn" | "icon";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginLeft: 30,
        marginRight: 30,
      },
      btn: {
        margin: 30,
      },
      icon: {
        padding: theme.spacing(1),
      },
    })
);
