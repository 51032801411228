import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "content"
  | "actions"
  | "subtitle"
  | "message"
  | "actionButtonContainer"
  | "mobileButton";

const PADDING = 40;

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        minWidth: 544,
        [theme.breakpoints.down("xs")]: {
          minWidth: 0,
        },
      },
      content: {
        padding: `24px ${PADDING}px 4px 32px`,
        maxWidth: 750,
      },
      actions: {
        padding: 32,
        maxWidth: 544,
        [theme.breakpoints.down("sm")]: {
          padding: "10px 20px",
        },
      },
      subtitle: {
        marginBottom: 10,
        color: colors.grey.additional,
      },
      message: {
        fontSize: 18,
        fontWeight: 600,
        color: colors.grey.additional,
        textAlign: "center",
        marginBottom: 24,
      },
      actionButtonContainer: {
        justifyContent: "flex-end",
        display: "flex",
      },
      mobileButton: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          height: "100%",
        },
      },
    })
);
