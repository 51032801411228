import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type ClassNames = "root" | "disabled";

export const buttonUseStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#F0F0F1",
      borderRadius: 0,
      width: 52,
      height: 45,
    },
    disabled: {
      backgroundColor: "#F9F9FA !important" as any,
    },
  })
);
