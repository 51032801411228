import { ChatDataItem, Message } from "../../views/Messages/models";
import {
  ADD_NEW_MESSAGE,
  AddNewMessageActionType,
  SET_ACTIVE_CHAT,
  SET_CHAT_DATA,
  SET_LIKED_MESSAGE,
  SetActiveChat,
  SetChatDataActionType,
  SetLikedMessageType,
} from "./types";

export const setChatData = (
  chatData: ChatDataItem[]
): SetChatDataActionType => ({
  type: SET_CHAT_DATA,
  chatData,
});

export const addNewMessage = (
  newMessage: Message,
  activeChatWindow?: number
): AddNewMessageActionType => ({
  type: ADD_NEW_MESSAGE,
  newMessage,
  activeChatWindow,
});

export const setLikedMessage = (message: Message): SetLikedMessageType => ({
  type: SET_LIKED_MESSAGE,
  message,
});

export const setActiveChat = (chatIndex: number): SetActiveChat => ({
  type: SET_ACTIVE_CHAT,
  activeChat: chatIndex,
});
