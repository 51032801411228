import { Theme } from "@material-ui/core";
import { Component, FC, SyntheticEvent } from "react";

import { Route } from "../config/router";
import { ValidationRules } from "../utils/validation";

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  NON_BINARY = "NON_BINARY",
  UNKNOWN = "UNKNOWN",
  OTHER = "OTHER",
}

export enum MobileAppUserType {
  CRITIC = "CRITIC",
  USER = "USER",
}

export interface AddressLocation {
  id: number;
  city: string;
  country: string;
  state: string;
}

export interface GetChartDataByPeriodRequest {
  venueId: number;
  dateFrom: string;
  dateTo: string;
  age?: string;
  gender?: Gender;
}

export enum AdminNotificationType {
  PROMOTION = "PROMOTION",
  RECOMMENDATION = "RECOMMENDATION",
  VENUE_REVIEW = "VENUE_REVIEW",
  VENUE_POS_CONNECT = "VENUE_POS_CONNECT",
  VENUE_POS_DISCONNECT = "VENUE_POS_DISCONNECT",
  VENUE_MENU_DELETE = "VENUE_MENU_DELETE",
  STRIPE = "STRIPE",
}

export interface AdminNotification {
  id: number;
  image?: string;
  title: string;
  date: string;
  message: string;
  isNew: boolean;
  type: AdminNotificationType;
  venueName: string;
  venueId: number;
}

export interface Review {
  id: number;
  userId: number;
  username: string;
  createdAt: Date;
  updatedAt: Date;
  userAvatar?: string;
  userType: MobileAppUserType;
  food?: number;
  service?: number;
  possibleResponse: boolean;
  likeUserIds: number[];
  comment: string;
  commentResponse: string;
  commentResponseDate: Date;
}

export interface ReviewsResponse {
  reviews: Review[];
  isBar: boolean;
}

export interface AuthCredentials {
  username: string;
  password: string;
  rememberMe: boolean;
}

export interface ForgotPassword {
  username: string;
  method?: string;
}

export enum AnalyticsPeriod {
  LastMonth = 1,
  LastThreeMoths = 3,
  LastSixMoths = 6,
  LastYear = 12,
  AllTime = 99,
}

export interface AnalyticsPeriodSelectOption {
  value: AnalyticsPeriod;
  label: string;
}

/**
 * @deprecated Use BasicForm interface instead
 */
export interface Form {
  values: Record<string, string>;
  errors: Record<string, string>;
  touched: string[];
  isValid: boolean;
}

export interface BasicForm<T extends string> {
  values: Record<T, string>;
  errors: Record<T, string>;
  touched: T[];
  isValid: boolean;
}

export type BasicSchema<T extends string> = Record<T, ValidationRules>;

export type VenuePOSTag = "posClover" | "posSquare" | "posVend";

export interface FileUrlWithId {
  id: number;
  url: string;
  active?: boolean;
  isMainPhoto?: boolean;
}

export interface User {
  id: number;
  firstName: string | null;
  lastName: string | null;
  avatarLink: string | null;
  title: string | null;
  email: string | null;
  company: string | null;
  phone: string | null;
}

export enum AuthError {
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  ACCOUNT_BLOCKED = "ACCOUNT_BLOCKED",
  ACCOUNT_BEFORE_BLOCKING = "ACCOUNT_BEFORE_BLOCKING",
  ACCOUNT_DELETED = "ACCOUNT_DELETED",
  ACCOUNT_INACTIVE = "ACCOUNT_INACTIVE",
}

export interface ChangePassword {
  password: string;
  code: string;
  token: string;
}
export interface VerifyMFACodeRequest {
  code: string;
  token: string;
  rememberMe: boolean;
}

export interface NavLink {
  label: string | Component | FC;
  path?: Route;
  order?: number;
  onClick?: (e: SyntheticEvent) => void;
  href?: string;
}

export interface OcietyTheme extends Theme {
  font: {
    size: {
      xxs: number;
      xs: number;
      s: number;
      m: number;
      l: number;
      xl: number;
      xxl: number;
    };
  };
}

export type VenueMediaTag = "interior" | "exterior";

export interface VenuePhoto {
  id: number;
  tag?: VenueMediaTag;
  venue?: Venue;
  url: string;
  active: boolean;
  isMainPhoto?: boolean;
}

export interface AmbienceItemVotes {
  name?: string;
  id?: number;
  total: number;
}

export interface AmbienceRating {
  atmosphere: AmbienceItemVotes[];
  crowd: AmbienceItemVotes[];
  loudness: AmbienceItemVotes[];
}

export interface Rating {
  ambience?: AmbienceRating;
  avgCombined?: number;
  avgFood?: number;
  avgService?: number;
  totalFood?: number;
  totalService?: number;
}
export interface WorkingHour {
  id: string;
  from: string;
  to: string;
}

export interface Holiday {
  id: string;
  startTime: string;
  endTime: string;
  name: string;
}

export interface WorkingDay {
  id: string;
  day: string;
  isOpen: boolean;
  hours: WorkingHour[];
}

export interface VenueVideo {
  id: number;
  name?: string;
  url: string;
  tag?: VenueMediaTag;
  active: boolean;
}

export type PaymentMethodType = "cc" | "paypal";

export interface BasicPaymentMethod {
  type: PaymentMethodType;
  nextBillAmount?: string;
  nextBillingDate?: string;
  imageUrl: string;
}

export interface CreditCartPaymentMethod extends BasicPaymentMethod {
  maskedNumber: string;
  cardType: string;
  expirationDate: string;
}

export interface PayPalPaymentMethod extends BasicPaymentMethod {
  payPalAccount: string;
}

export type PaymentMethod = CreditCartPaymentMethod | PayPalPaymentMethod;

export interface VenuePendingChanges {
  name?: string;
  address?: string;
  postcode?: string;
  address2?: string;
  postcode2?: string;
  city?: string;
  country?: string;
  state?: string;
  id: number;
  website?: string;
}

export interface Venue {
  id: number;
  address: string;
  postcode?: string;
  address2?: string;
  postcode2?: string;
  name: string;
  longitude: number;
  latitude: number;
  location: {
    id: number;
    city: string;
    country: string;
    state: string;
  };
  type: {
    restaurant?: VenueType[];
    bar?: VenueType[];
  };
  description?: string | null;
  photos: VenuePhoto[];
  rating: Rating;
  workingDays: WorkingDay[];
  holidays: Holiday[];
  website: string;
  price: number;
  videos: VenueVideo[];
  pendingChanges?: VenuePendingChanges;
  mainPhotoId?: number;
  tags: string[];
}

export interface VenueType {
  id: number;
  subtype: string;
}

export interface VenueAddRequest {
  id: number;
  venueName: string;
  city: string;
  country: string;
  state: string;
  address: string;
}

export interface VenueAddRequestDetails {
  id: number;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userCompany: string;
  userTitle: string;
  userPhoneNumber: string;
  comment: string | null;
  venueName: string;
  venueCity: string;
  venueCountry: string;
  venueAddress: string;
  venuePostcode: string;
  venueEmail: string;
  venueState: string;
  venuePhone: string;
  venueWebsite: string;
  barTypesIds: string | null;
  restaurantTypesIds: string | null;
  attachments: string[];
}

export interface VenueTypes {
  bar: VenueType[];
  restaurant: VenueType[];
}

export interface SuccessResponse {
  success: boolean;
}

export interface UserPhoto {
  id: number;
  url: string;
}

export interface VenueAdmin {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  city: string;
  country: string;
  description: string;
  username: string;
  profileImage: string;
  images?: UserPhoto[];
  phone?: string;
  title?: string;
  company?: string;
}

export interface BasicPromotionCategory {
  id: number;
  name: string;
}

export interface POSCredentials {
  id: number;
  appId: string;
  appSecret: string;
  merchant: string;
  token: string;
  isSandbox: boolean;
  tag: VenuePOSTag;
}

export interface VenuePOS {
  label: string;
  value: VenuePOSTag;
  posObj: POSCredentials;
}

export enum POS_KEY {
  CLOVER,
  SQUARE_UP,
  VEND,
}

export interface VenueMenu {
  id: number;
  filePath: string;
  fileName: string;
  size: number;
  websiteLink: string;
}

export type GoBack = "go-back";
