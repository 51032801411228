import { Paper, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useRef, useState } from "react";

import Avatar from "../../../../../../../common/components/Avatar";
import BreakLine from "../../../../../../../common/components/BreakLine";
import ButtonBlack from "../../../../../../../common/components/ButtonBlack";
import { useGlobalStyles } from "../../../../../../../common/styles";
import { ReactComponent as ExclamationIcon } from "../../../../../../../common/svg/icons/exclamation.svg";
import { ReactComponent as HeartFilledIcon } from "../../../../../../../common/svg/icons/heart-filled.svg";
import { ReactComponent as StarHeptagonFilledIcon } from "../../../../../../../common/svg/icons/star-heptagon-filled.svg";
import { ReactComponent as TrashIcon } from "../../../../../../../common/svg/icons/trash.svg";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../../../../utils/dateTimeUtils";
import Props from "./Props";
import { useStyles } from "./styles";

const CardReviewReply = ({
  updateAdminResponse,
}: {
  updateAdminResponse: (text: string) => void;
}) => {
  const classes = useStyles();
  const [text, setText] = useState<string>("");
  return (
    <div className={classes.cardReviewReplyWrapper}>
      <TextField
        className={classes.reviewReplyInput}
        required
        fullWidth
        multiline={true}
        rows={4}
        label={dictionary.reviews.typeYourResponseHereLabel}
        placeholder={dictionary.reviews.typeYourResponseHereLabel}
        type="text"
        variant="outlined"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <ButtonBlack
        disabled={text.length === 0}
        className={classes.button}
        onClick={async () => await updateAdminResponse(text)}>
        {dictionary.reviews.send}
      </ButtonBlack>
    </div>
  );
};

const CriticReviewCard: FC<Props> = ({
  activeReview,
  review,
  updateReview,
  user,
  sticky,
}: Props) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const {
    likeUserIds,
    comment,
    commentResponse,
    createdAt,
    userAvatar,
    username,
    food,
    commentResponseDate,
  } = review || activeReview;
  // @ts-ignore
  const updateAdminResponse = async (text: string) => updateReview(text);

  const CardReviewForbidden = () => {
    return (
      <div className={classes.cardReviewForbidden}>
        <ExclamationIcon className={classes.exclamationIcon} />
        <Typography className={classes.cardReviewForbiddenText}>
          {dictionary.reviews.criticLimitAccessLeaveReply}
        </Typography>
      </div>
    );
  };

  const Card = () => {
    return (
      <Paper
        className={clsx(classes.cardWrapper, { [classes.sticky]: sticky })}
        style={{
          border:
            review?.id === activeReview?.id ? "solid 1px #747982" : "none",
          cursor: review && activeReview ? "pointer" : "default",
        }}>
        <div className={classes.cardWrapperInner}>
          <Avatar imagePath={userAvatar} size={56} />
          <div className={classes.cardTitle}>
            <Typography className={classes.cardTitleUsername}>
              {username}
            </Typography>
            <div className={classes.cardStarsWrapper}>
              {food && food > 0 && (
                <div className={globalClasses.flexRow_Center}>
                  <StarHeptagonFilledIcon
                    className={classes.starHeptagonFilledIcon}
                  />
                  <Typography className={classes.starHeptagonFilledText}>
                    {food}
                  </Typography>
                </div>
              )}

              {likeUserIds?.length > 0 && (
                <div className={globalClasses.flexRow_Center}>
                  <HeartFilledIcon className={classes.heartFilledIcon} />

                  <Typography className={classes.heartFilledText}>
                    {likeUserIds.length}
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <Typography className={classes.cardTitleDate}>
            {getFormattedDate(createdAt)}
          </Typography>
        </div>

        <Typography className={classes.cardReviewText}>{comment}</Typography>

        {!review && activeReview && (
          <>
            {activeReview.commentResponse ? (
              <CardTextResponse />
            ) : activeReview.possibleResponse ? (
              <CardReviewReply updateAdminResponse={updateAdminResponse} />
            ) : (
              <CardReviewForbidden />
            )}
          </>
        )}
        {review && review.commentResponse && <CardTextResponse />}
      </Paper>
    );
  };

  const CardTextResponse = () => {
    return (
      <div className={classes.cardTextResponseWrapper}>
        <BreakLine />
        <div className={classes.cardTextResponseTitleWrapper}>
          <Typography className={classes.cardTextResponseUsername}>
            {dictionary.reviews.adminName(user.firstName)}
          </Typography>
          <Typography className={classes.cardTextResponseDate}>
            {getFormattedDate(commentResponseDate)}
          </Typography>
        </div>
        <div className={classes.cardTextResponseTextWrapper}>
          <Typography className={classes.cardTextResponseText}>
            {commentResponse}
          </Typography>
          {activeReview && !review && (
            <div
              onClick={() => updateReview("")}
              className={classes.trashIconWrapper}>
              <TrashIcon className={classes.trashIcon} />
            </div>
          )}
        </div>
      </div>
    );
  };

  return <Card />;
};

export default CriticReviewCard;
