import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames = "root";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        width: 31,
        height: 18,
        padding: 0,
        display: "flex",
        "&:active": {
          "& .MuiSwitch-thumb": {
            width: 15,
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
          },
        },
        "& .MuiSwitch-switchBase": {
          padding: 3,
          color: colors.white.primary,
          "&.Mui-checked": {
            transform: "translateX(14px)",
            color: colors.white.primary,
            "& + .MuiSwitch-track": {
              opacity: 1,
              backgroundColor: colors.red.primary,
            },
          },
        },
        "& .MuiSwitch-thumb": {
          boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
          width: 12,
          height: 12,
          borderRadius: 6,
          transition: theme.transitions.create(["width"], {
            duration: 200,
          }),
        },
        "& .MuiSwitch-track": {
          borderRadius: 18 / 2,
          opacity: 1,
          backgroundColor: colors.grey.disabled,
          boxSizing: "border-box",
        },
      },
    })
);
