import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "actionsContainer" | "saveButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        position: "relative",
        display: "flex",
        minHeight: 310,
        flexDirection: "column",
        justifyContent: "space-between",
      },
      actionsContainer: {
        display: "flex",
        margin: theme.spacing(3, 0),
        justifyContent: "flex-end",
      },
      saveButton: {
        marginLeft: theme.spacing(2),
      },
    })
);
