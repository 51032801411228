import { Button, Paper, TextField, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { FC, useContext, useState } from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { useStyles } from "../AddSmsMethodDialog/styles";
import { requestTotp, saveTotp } from "./api";
import ITotpData from "./models/ITotpData";
import Props from "./Props";
import { TotpSteps } from "./TotpSteps";

const AddTotpMethodDialog: FC<Props> = (props: Props) => {
  const { isOpen, setOpen, onSuccessEnabled } = props;
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const [step, setStep] = useState(TotpSteps.PASSWORD_STEP);
  const [loading, setLoading] = useState(false);
  const [totp, setTotp] = useState<ITotpData | null>(null);

  const [password, setPassword] = useState("");
  const [methodDescription, setMethodDescription] = useState("");
  const [otpCode, setOtpCode] = useState("");

  const handleClose = () => {
    setOpen(false);

    if (step === TotpSteps.SUCCESS_STEP && onSuccessEnabled) {
      onSuccessEnabled();
    }
  };

  const codeIsValid = (code: string): boolean => {
    if (code) {
      const match = code.match(/\d/g);
      if (!match) {
        return false;
      }
      return match.length === 6;
    }

    return false;
  };

  const descIsValid = (description: string) => {
    return !!description;
  };

  const handlePasswordChange = async (event: any) => {
    setPassword(event.target.value);
  };
  const handleRequestTotp = async () => {
    if (!password) {
      return;
    }

    try {
      setLoading(true);

      const totpdata = await requestTotp(password);

      if (!totpdata) {
        showAlert(
          dictionary.venueAdmin.profile.mfa.dialogs.totp.errorResponseMsg,
          "error"
        );
      }

      setTotp(totpdata);
      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.totp.successRequestQRcode,
        "success"
      );
      setStep(TotpSteps.QR_STEP);
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(e.data, "error");
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTotp = async () => {
    if (!otpCode || !methodDescription || !totp) {
      return;
    }

    try {
      setLoading(true);

      const result = await saveTotp(totp.secret, methodDescription, otpCode);

      if (!result) {
        showAlert(
          dictionary.venueAdmin.profile.mfa.dialogs.totp.errorResponseMsg,
          "error"
        );
      }

      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.totp.mfaSuccessEnable,
        "success"
      );
      setStep(TotpSteps.SUCCESS_STEP);
    } catch (e) {
      if (!e) {
        return;
      }

      if (e.data) {
        showAlert(e.data, "error");
      }
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  const handleActionButtonClick = async () => {
    if (step === TotpSteps.PASSWORD_STEP) {
      await handleRequestTotp();
    }

    if (step === TotpSteps.QR_STEP) {
      await handleSaveTotp();
    }
  };

  const renderPasswordStep = () => {
    return (
      <TextField
        type="password"
        label={dictionary.venueAdmin.profile.mfa.dialogs.totp.passwordLabel}
        onChange={handlePasswordChange}
        value={password}
      />
    );
  };

  const handleChangeDescription = (event: any) => {
    setMethodDescription(event.target.value);
  };
  const handleChangeOtpCode = (event: any) => {
    setOtpCode(event.target.value);
  };

  const renderQrCodeStep = () => {
    if (totp) {
      return (
        <div>
          <Paper elevation={3}>
            <div>
              <img src={totp.imageUrl} alt="" />
            </div>

            <div style={{ padding: 30 }}>
              <strong>QR code or secret key: </strong>
              <code>
                <pre>{totp.secret}</pre>
              </code>
            </div>
          </Paper>

          <form>
            <TextField
              label={dictionary.venueAdmin.profile.mfa.dialogs.totp.descLabel}
              onChange={handleChangeDescription}
              value={methodDescription}
              error={!descIsValid(methodDescription)}
            />
            <TextField
              label={dictionary.venueAdmin.profile.mfa.dialogs.totp.otpLabel}
              onChange={handleChangeOtpCode}
              value={otpCode}
              error={!codeIsValid(otpCode)}
            />
          </form>
        </div>
      );
    }

    return <LoadingIndicator withMask />;
  };

  const renderSuccessStep = () => {
    return (
      <div>
        <Typography
          variant="body2"
          component="span"
          style={{ marginBottom: 30 }}>
          {dictionary.venueAdmin.profile.mfa.dialogs.totp.mfaSuccessEnable}
        </Typography>
      </div>
    );
  };

  const renderSteps = () => {
    if (step === TotpSteps.PASSWORD_STEP) {
      return renderPasswordStep();
    }

    if (step === TotpSteps.QR_STEP) {
      return renderQrCodeStep();
    }

    if (step === TotpSteps.SUCCESS_STEP) {
      return renderSuccessStep();
    }
  };

  const isAllowedContinueBtn = () => {
    if (password && step === TotpSteps.PASSWORD_STEP) {
      return true;
    }

    if (step === TotpSteps.QR_STEP && methodDescription && otpCode) {
      return true;
    }

    return false;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="alert-dialog-title">
        {dictionary.venueAdmin.profile.mfa.dialogs.totp.title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {renderSteps()}
        {loading && <LoadingIndicator withMask />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={() => handleClose()}>
          {dictionary.venueAdmin.profile.mfa.dialogs.totp.closeButton}
        </Button>
        {step !== TotpSteps.SUCCESS_STEP && (
          <Button
            color="primary"
            variant="outlined"
            disabled={!isAllowedContinueBtn()}
            onClick={handleActionButtonClick}>
            {dictionary.venueAdmin.profile.mfa.dialogs.totp.continueButton}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default AddTotpMethodDialog;
