import { List, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React, { FC } from "react";
import { useSelector } from "react-redux";

import CounterIcon from "../../../../../../common/components/CounterIcon";
import OcietyIcon from "../../../../../../common/components/OcietyIcon";
// eslint-disable-next-line max-len
import { OcietyIconName } from "../../../../../../common/components/OcietyIcon/iconsMapping";
import OcietyNavLink from "../../../../../../common/components/OcietyNavLink";
import { useCurrentVenueId } from "../../../../../../common/hooks/useCurrentVenueId";
import { useIsManager } from "../../../../../../common/hooks/useIsManager";
import { useIsSuperAdmin } from "../../../../../../common/hooks/useIsSuperAdmin";
import { OcietyTheme } from "../../../../../../common/models";
import { isPackagesDisabled } from "../../../../../../config";
import { Route } from "../../../../../../config/router";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../../../store/rootReducer";
import { getNewMessagesQty } from "../../../../../../utils/messagingUtils";
import { sortByOrder } from "../../../../../../utils/objectArrayUtils";
import Props from "./Props";
import { useStyles } from "./styles";

interface LinkItem {
  route: Route;
  withEntityId?: boolean;
  order?: number;
  label: string;
  icon: OcietyIconName;
  iconActive: OcietyIconName;
}

const superAdminPages: Route[] = [
  Route.Dashboard,
  Route.Deals,
  Route.Venues,
  Route.UserPhotos,
  Route.ReportedMedia,
  Route.VenuesPending,
  Route.VenuesClaimRequests,
  Route.VenuesChangesPending,
  Route.UserManagement,
];

let linksMapping: LinkItem[] = [
  {
    route: Route.Dashboard,
    order: 10,
    withEntityId: true,
    iconActive: "dashboardActive",
    icon: "dashboard",
    label: dictionary.sidebar.dashboardLink,
  },
  {
    route: Route.Messages,
    order: 20,
    iconActive: "messagesActive",
    icon: "messages",
    label: dictionary.sidebar.messagesLink,
  },
  {
    route: Route.Deals,
    order: 30,
    withEntityId: true,
    iconActive: "promotionActive",
    icon: "promotion",
    label: dictionary.sidebar.dealsLink,
  },
  {
    route: Route.Reporting,
    order: 40,
    withEntityId: true,
    iconActive: "circleDiagramActive",
    icon: "circleDiagram",
    label: dictionary.sidebar.reportingLink,
  },
  // {
  //   route: Route.Recommendations,
  //   order: 50,
  //   withEntityId: true,
  //   iconActive: "thumbUpActive",
  //   icon: "thumbUp",
  //   label: dictionary.sidebar.recommendationsLink,
  // },
  {
    route: Route.Reviews,
    order: 60,
    withEntityId: true,
    iconActive: "starActive",
    icon: "star",
    label: dictionary.sidebar.reviewsLink,
  },
  {
    route: Route.Plan,
    order: 70,
    iconActive: "priceLabelActive",
    icon: "priceLabel",
    label: dictionary.sidebar.planLink,
  },
];

const SidebarNav: FC<Props> = (props: Props) => {
  const { className, toggleSidebar, isSidebarOpen, ...rest } = props;
  const theme: OcietyTheme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const venueId = useCurrentVenueId();
  const superAdmin = useIsSuperAdmin();
  const isManager = useIsManager();
  const newMessagesQty = useSelector<AppState, number>((state) =>
    getNewMessagesQty(state.messaging.chatData)
  );

  if (superAdmin || isManager) {
    linksMapping = linksMapping.filter((link) =>
      superAdminPages.includes(link.route)
    );

    if (!linksMapping.some((link) => link.route === Route.Venues)) {
      linksMapping.push({
        route: Route.Venues,
        order: 80,
        withEntityId: true,
        iconActive: "venuesActive",
        icon: "venuesGrey",
        label: dictionary.sidebar.venuesLink,
      });
    }

    if (!linksMapping.some((link) => link.route === Route.VenuesPending)) {
      linksMapping.push({
        route: Route.VenuesPending,
        order: 90,
        withEntityId: true,
        iconActive: "venuesNewPendingActive",
        icon: "venuesNewPending",
        label: dictionary.sidebar.venuesCreationRequests,
      });

      if (!linksMapping.some((link) => link.route === Route.UserPhotos)) {
        linksMapping.push({
          route: Route.UserPhotos,
          order: 100,
          withEntityId: true,
          iconActive: "userPhotosActive",
          icon: "userPhotos",
          label: dictionary.sidebar.userPhotosLink,
        });
      }

      if (!linksMapping.some((link) => link.route === Route.ReportedMedia)) {
        linksMapping.push({
          route: Route.ReportedMedia,
          order: 110,
          withEntityId: true,
          iconActive: "reportedPhotosActive",
          icon: "reportedPhotos",
          label: dictionary.sidebar.reportedMediaLink,
        });
      }

      if (
        !linksMapping.some((link) => link.route === Route.VenuesClaimRequests)
      ) {
        linksMapping.push({
          route: Route.VenuesClaimRequests,
          order: 120,
          withEntityId: true,
          iconActive: "venuesClaimActive",
          icon: "venuesClaim",
          label: dictionary.sidebar.venuesClaimRequests,
        });
      }
    }

    if (
      !linksMapping.some((link) => link.route === Route.VenuesChangesPending)
    ) {
      linksMapping.push({
        route: Route.VenuesChangesPending,
        order: 130,
        withEntityId: true,
        iconActive: "settingsActive",
        icon: "settings",
        label: dictionary.sidebar.venuesChangesLink,
      });
    }

    if (!linksMapping.some((link) => link.route === Route.UserManagement)) {
      linksMapping.push({
        route: Route.UserManagement,
        order: 140,
        withEntityId: true,
        iconActive: "userActive",
        icon: "user",
        label: dictionary.sidebar.userManagementLink,
      });
    }

    if (!linksMapping.some((link) => link.route === Route.CouponManagement)) {
      linksMapping.push({
        route: Route.CouponManagement,
        order: 150,
        withEntityId: true,
        iconActive: "couponActive",
        icon: "coupon",
        label: dictionary.sidebar.couponManagementLink,
      });
    }

    if (!linksMapping.some((link) => link.route === Route.MoneyFlow)) {
      linksMapping.push({
        route: Route.MoneyFlow,
        order: 160,
        withEntityId: true,
        iconActive: "moneyFlowActive",
        icon: "moneyFlow",
        label: dictionary.sidebar.moneyFlowLink,
      });
    }
  } else {
    linksMapping = linksMapping.filter(
      (link) =>
        link.route !== Route.Venues ||
        // @ts-ignore
        link.route !== Route.VenuesPending ||
        link.route !== Route.VenuesChangesPending ||
        link.route !== Route.VenuesClaimRequests
    );

    if (!linksMapping.some((link) => link.route === Route.VenueSettings)) {
      linksMapping.push({
        route: Route.VenueSettings,
        order: 80,
        withEntityId: true,
        iconActive: "settingsActive",
        icon: "settings",
        label: dictionary.sidebar.venueSettingsLink,
      });
    }

    if (!isPackagesDisabled) {
      if (
        !linksMapping.some((link) => link.route === Route.BillingInformation)
      ) {
        linksMapping.push({
          route: Route.BillingInformation,
          order: 90,
          iconActive: "billingInformationActive",
          icon: "billingInformation",
          label: dictionary.sidebar.billingInformationLink,
        });
      }
    }
  }

  const handleLinkClick = () => {
    if (isSidebarOpen && isMobile) {
      toggleSidebar();
    }
  };

  const renderLink = ({
    route,
    withEntityId,
    label,
    icon,
    iconActive,
  }: LinkItem) => (
    <OcietyNavLink
      to={withEntityId && venueId ? [route, String(venueId)] : route}
      key={route}
      className={classes.link}
      linkTextClassName={clsx(classes.linkText)}
      onClick={handleLinkClick}
      icon={
        <>
          <OcietyIcon
            className={classes.icon}
            name={
              route === Route.Messages && newMessagesQty > 0
                ? "unreadMessages"
                : icon
            }
          />
          <OcietyIcon
            className={classes.iconActive}
            name={
              route === Route.Messages && newMessagesQty > 0
                ? "activeUnreadMessages"
                : iconActive
            }
          />
        </>
      }>
      {label}
      {route === Route.Messages ? (
        <CounterIcon items={newMessagesQty} className={classes.counterIcon} />
      ) : null}
    </OcietyNavLink>
  );

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {linksMapping.sort(sortByOrder).map(renderLink)}
    </List>
  );
};

export default SidebarNav;
