import {
  CART_CLEAR,
  CART_ITEM_ADD,
  CART_ITEM_REMOVE,
  CartActionTypes,
} from "./types";

export type CartState = Record<string, number[] | undefined>;

const defaultState: CartState = {};

export default (
  state: CartState = defaultState,
  action: CartActionTypes
): CartState => {
  switch (action.type) {
    case CART_CLEAR:
      return {};
    case CART_ITEM_ADD:
      const prevAdd = state[action.venueId] || [];

      return {
        ...state,
        [action.venueId]: [...prevAdd, action.packageId],
      };
    case CART_ITEM_REMOVE:
      const prevRemove = state[action.venueId] || [];

      return {
        ...state,
        [action.venueId]: prevRemove.filter(
          (packageId) => packageId !== action.packageId
        ),
      };
    default:
      return state;
  }
};
