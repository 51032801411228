import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../common/models";

export type ClassNames =
  | "tableRow"
  | "chevronIconOpened"
  | "chevronIconNotOpened"
  | "rowTextVenueName"
  | "rowTextVenueAddress"
  | "rowCellCreatedAt"
  | "rowCellActiveDaysAndHours"
  | "rowDropdownWrapper"
  | "rowCellDropdownWrapper"
  | "tableCell"
  | "tableHeadCellCollapsed"
  | "rowTableRowContainer"
  | "rowCellPhoto"
  | "rowCellDealName"
  | "rowCellStatus"
  | "tableRowCollapsed"
  | "rowCellDeal"
  | "tableCellCollapsed"
  | "rowCellDealTitle"
  | "rowCellEmptyContainer"
  | "rowCellEmpty"
  | "tableCollapsibleContainer"
  | "tableHeadCell"
  | "tableHeadBody"
  | "rowTableCell";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      tableCell: {
        border: "none",
      },
      tableCellCollapsed: {
        borderBottom: "solid 1px #E9E9E9",
        padding: "12px 0",
      },

      tableHeadCellCollapsed: {
        padding: "16px 0 2px 0",
      },

      tableRow: {
        "& > *": {
          borderBottom: "unset",
        },
        "&:hover": {
          boxShadow: "4px 0px 0px 0px red inset !important",
          cursor: "pointer",

          "& td:first-child svg": { color: "red !important" },
        },
      },

      tableRowCollapsed: {
        "&:last-child > td": {
          borderBottom: "none",
        },
      },
      chevronIconOpened: {
        width: 16,
        height: 9,
        color: "#FF6464",
        transform: "rotate(180deg)",
      },
      chevronIconNotOpened: { width: 16, height: 9, color: "#45484E" },
      rowTextVenueName: { fontWeight: 600, fontSize: 14, color: "#37393F" },
      rowTextVenueAddress: { fontWeight: 400, fontSize: 12, color: "#595D64" },
      rowCellCreatedAt: { fontWeight: 600, fontSize: 14, color: "#37393F" },
      rowCellActiveDaysAndHours: {
        fontWeight: 700,
        fontSize: 14,
        color: "#37393F",
        textDecorationLine: "underline",
      },
      rowDropdownWrapper: { backgroundColor: "#F8F8F8", borderRadius: 4 },
      rowCellDropdownWrapper: { padding: "0 0 16px 0" },
      rowCellPhoto: { fontWeight: 600, fontSize: 14, color: "#595D64" },
      rowCellDealName: { fontWeight: 600, fontSize: 14, color: "#595D64" },
      rowCellStatus: { fontWeight: 600, fontSize: 14, color: "#595D64" },
      rowCellDeal: { padding: 12 },
      rowCellDealTitle: {
        fontWeight: 600,
        fontSize: 14,
        color: "#37393F",
      },
      rowCellEmptyContainer: {
        height: 16,
        backgroundColor: "rgb(248, 248, 248)",
      },
      rowCellEmpty: { padding: 0 },
      tableCollapsibleContainer: { overflow: "visible" },
      tableHeadCell: { fontWeight: 600, fontSize: 14, color: "#595D64" },
      tableHeadBody: { boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)" },
      rowTableRowContainer: { borderRadius: 4 },
      rowTableCell: { padding: "24px 16px" },
    })
);
