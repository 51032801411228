import { http } from "../../../../config/api";
import ChangePasswordRequest from "./models/ChangePasswordRequest";
const CHANGE_PASSWORD = "profile/change-password";

export const changePassword = async (
  changepasswordRequest: ChangePasswordRequest
) =>
  http
    .post(CHANGE_PASSWORD, changepasswordRequest)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });
