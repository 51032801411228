import { toMoneyFormat } from "../../Promotions/AdminVenue/dialogs/PromotionFormDialog/components/PromotionFormDialogContent/utils";
import { PromoCodesFormErrorsField } from "../components/DialogAddNewCoupon/form";
import { CreateCouponCode, PromoCodesType } from "../models";

export default class DtoCouponCode implements CreateCouponCode {
  public static build = ({
    type,
    couponCode,
    value,
    start,
    end,
    minimumAmount,
    usageLimit,
  }: Record<PromoCodesFormErrorsField, string>) => {
    const builderClassCouponCode = new DtoCouponCode();
    builderClassCouponCode.type = type as PromoCodesType;
    builderClassCouponCode.couponCode = couponCode;
    builderClassCouponCode.value = toMoneyFormat(value).amount;
    builderClassCouponCode.start = start;
    builderClassCouponCode.end = end;
    builderClassCouponCode.minimumAmount = toMoneyFormat(minimumAmount).amount;
    builderClassCouponCode.usageLimit = Number(usageLimit);
    return builderClassCouponCode;
  };

  public static buildUpdate = ({
    id,
    type,
    couponCode,
    value,
    start,
    end,
    minimumAmount,
    usageLimit,
  }: Record<PromoCodesFormErrorsField | "id", string>) => {
    const builderClassCouponCode = new DtoCouponCode();
    builderClassCouponCode.id = Number(id);
    builderClassCouponCode.type = type as PromoCodesType;
    builderClassCouponCode.couponCode = couponCode;
    builderClassCouponCode.value = toMoneyFormat(value).amount;
    builderClassCouponCode.start = start;
    builderClassCouponCode.end = end;
    builderClassCouponCode.minimumAmount = toMoneyFormat(minimumAmount).amount;
    builderClassCouponCode.usageLimit = Number(usageLimit);
    return builderClassCouponCode;
  };

  public id = 0;
  public type: PromoCodesType = PromoCodesType.STANDARD;
  public couponCode = "";
  public value = 0;
  public start = "";
  public end = "";
  public minimumAmount = 0;
  public usageLimit = 0;
}
