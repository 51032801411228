import { Button, Tab, Tabs, useTheme } from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SwipeableViews from "react-swipeable-views";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../../../common/components/ButtonBlack";
import ConfirmDialog from "../../../../../../common/components/dialogs/ConfirmDialog";
import { ConfirmModalType } from "../../../../../../common/components/dialogs/ConfirmDialog/Props";
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import TabPanel from "../../../../../../common/components/TabPanel";
import { useCurrentVenueId } from "../../../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../../../common/hooks/useRestApi";
import HttpStatusCodes from "../../../../../../common/httpStatusCodes";
import { VenueMenu } from "../../../../../../common/models";
import { CroppedImageSize } from "../../../../../../common/types/enums.type";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import imageUtils from "../../../../../../utils/imageUtils";
import { MenuFileInfo, VENUE_MENU } from "../../api";
import MenuFileTab from "../MenuFileTab";
import MenuURLTab from "../MenuURLTab";
import { useStyles } from "./styles";

const tabHashes = ["#menu-file", "#menu-url"];

const MenuTabs: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const venueId = useCurrentVenueId();
  const { showAlert } = useContext(AlertContext);
  const menuInputRef = useRef<HTMLInputElement>(null);

  const { hash } = window.location;

  const initialTabIndex = hash
    ? tabHashes.indexOf(hash) !== -1
      ? tabHashes.indexOf(hash)
      : 0
    : 0;

  const [currentTab, setCurrentTab] = React.useState(initialTabIndex);
  const [menuLink, setMenuLink] = useState<string | null>("");
  const [isUploadedLink, setIsUploadedLink] = useState(false);
  const [menuFileInfo, setMenuFileInfo] = useState<MenuFileInfo | null>(null);

  const [, deleteMenu] = useRestApi(VENUE_MENU(venueId), "DELETE");
  const [{ loading }, getVenueMenu] = useRestApi<VenueMenu>(
    VENUE_MENU(venueId),
    "GET",
    undefined,
    true
  );
  const [{ error: errorUploading }, updateMenu] = useRestApi<any, FormData>(
    VENUE_MENU(venueId),
    "POST"
  );

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const fetchMenu = useCallback(() => {
    getVenueMenu()
      .then((response) => {
        if (response) {
          const { websiteLink, fileName, size, filePath } = response;

          if (filePath) {
            setMenuFileInfo({ fileName, size });
            setLinkEmpty();
          }

          if (websiteLink) {
            setMenuLink(websiteLink);
            setIsUploadedLink(true);
            setFileEmpty();
          }
        }
      })
      .catch((e) => {
        if (e.response.status === HttpStatusCodes.NOT_FOUND) {
          setFileEmpty();
          setLinkEmpty();
        }
      });
  }, [getVenueMenu]);

  const hasMenuFile = Boolean(menuFileInfo);
  const hasMenuURL = isUploadedLink;

  useEffect(() => {
    fetchMenu();
  }, [fetchMenu]);

  useEffect(() => {
    const fileTab = 0;
    const linkTab = 1;
    const tabIndex = hasMenuFile
      ? fileTab
      : hasMenuURL
      ? linkTab
      : initialTabIndex;

    setCurrentTab(tabIndex);
  }, [initialTabIndex, hasMenuFile, hasMenuURL]);

  const handleChangeTab = (index: number) => {
    setCurrentTab(index);
    window.location.hash = tabHashes[index];
  };

  const handleChangeCurrentTab = (
    event: React.ChangeEvent<Record<any, any>>,
    newValue: number
  ) => {
    handleChangeTab(newValue);
  };

  const handleChangeIndex = (index: number) => {
    handleChangeTab(index);
  };

  const handleMenuChangeClick = () => {
    if (currentTab === 0) {
      handleLoadMenuFile();
    } else {
      handleLoadMenuLink();
    }
  };

  const handleLoadMenuFile = () => menuInputRef?.current?.click();

  const handleLoadMenuLink = () => {
    if (menuLink) {
      const formData = new FormData();
      formData.append("websiteLink", menuLink);

      uploadMenu(formData).then(() => {
        setIsUploadedLink(true);
        setFileEmpty();
      });
    }
  };

  const handleChangeMenuFile = async (e: ChangeEvent<HTMLInputElement>) => {
    let file: File | null = e.target.files && e.target.files[0];
    if (file) {
      const fileInfo = { fileName: file.name, size: file.size };
      if (file.type.match(/image/g)) {
        const croppedImage = await imageUtils.cropImage(
          file,
          CroppedImageSize.s1280x1280
        );
        file = croppedImage ? (croppedImage as File) : file;
        file = new File([file], file.name, { type: file.type });
      }

      const formData = new FormData();
      formData.append("menu", file, file.name);

      uploadMenu(formData).then(() => {
        setMenuFileInfo(fileInfo);
        setLinkEmpty();
      });
    }
  };

  const handleChangeMenuLink = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMenuLink(value);
  };

  const handleMenuRemove = () => {
    deleteMenu()
      .then(() => {
        if (currentTab === 0) {
          setFileEmpty();
        } else {
          setLinkEmpty();
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const setFileEmpty = () => {
    if (menuInputRef.current?.value) {
      menuInputRef.current.value = "";
    }
    setMenuFileInfo(null);
  };

  const setLinkEmpty = () => {
    setMenuLink("");
    setIsUploadedLink(false);
  };

  const uploadMenu = async (data: FormData) =>
    await updateMenu(data)
      .then(() => {
        showAlert(
          dictionary.venues.venueForm.menuSaveSuccess(currentTab === 0),
          "success"
        );
      })
      .catch((e) => {
        showAlert(e.response.data, "error");
      });

  const uploadButtonLabel = useMemo(() => {
    if (currentTab === 0) {
      return hasMenuFile
        ? dictionary.venues.menuChangeFile
        : dictionary.venues.menuUploadFile;
    }

    if (currentTab === 1) {
      return dictionary.venues.menuUrlSave;
    }
  }, [currentTab, hasMenuFile]);

  const disabledUploadButton = currentTab === 1 && menuLink === "";

  const removeButtonLabel =
    currentTab === 0
      ? dictionary.venues.removeFile
      : dictionary.venues.removeURL;

  const shouldRenderRemoveButton = useMemo(() => {
    if (hasMenuFile) {
      return currentTab === 0;
    }

    if (hasMenuURL) {
      return currentTab === 1;
    }
  }, [hasMenuURL, hasMenuFile, currentTab]);

  return (
    <div>
      <div className={classes.tabsWrapper}>
        <Tabs
          value={currentTab}
          onChange={handleChangeCurrentTab}
          indicatorColor="primary"
          textColor="primary"
          aria-label="menu tabs">
          <Tab
            classes={{
              root: classes.tabRoot,
            }}
            disabled={hasMenuURL}
            label={dictionary.venues.venueForm.menuTabs.file}
          />
          <Tab
            classes={{
              root: classes.tabRoot,
            }}
            disabled={hasMenuFile}
            label={dictionary.venues.venueForm.menuTabs.url}
          />
        </Tabs>
      </div>
      <SwipeableViews
        className={classes.swipeableContainer}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={currentTab}
        onChangeIndex={handleChangeIndex}>
        <TabPanel
          hideNotActive
          value={currentTab.toString()}
          index={"0"}
          dir={theme.direction}>
          <MenuFileTab
            handleMenuChange={handleChangeMenuFile}
            menuFile={menuFileInfo}
            menuInputRef={menuInputRef}
          />
        </TabPanel>
        <TabPanel
          hideNotActive
          value={currentTab.toString()}
          index={"1"}
          dir={theme.direction}>
          <MenuURLTab
            handleChangeMenuLink={handleChangeMenuLink}
            menuLink={menuLink}
            error={errorUploading}
            isUploadedLink={isUploadedLink}
          />
        </TabPanel>
      </SwipeableViews>

      <ButtonBlack
        disabled={disabledUploadButton}
        className={classes.saveButton}
        onClick={handleMenuChangeClick}>
        {uploadButtonLabel}
      </ButtonBlack>
      {shouldRenderRemoveButton && (
        <Button
          onClick={() => setIsConfirmDialogOpen(true)}
          className={classes.removeMenuButton}>
          {removeButtonLabel}
        </Button>
      )}
      {isConfirmDialogOpen && (
        <ConfirmDialog
          open={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          onConfirm={handleMenuRemove}
          type={ConfirmModalType.DELETE}
          message={dictionary.venues.venueForm.deleteMenuConfirmationMessage}
        />
      )}
      {loading && <LoadingIndicator withMask />}
    </div>
  );
};

export default MenuTabs;
