import { http } from "../../config/api";
import DtoHappyHourRequest from "./dtos/HappyHourRequest.dto";
import { CreateHappyHour } from "./types/interfaces/CreateHappyHour.interface";
import { HappyHour } from "./types/interfaces/HappyHour.inteface";
import { ResponseHappyHourFile } from "./types/interfaces/ResponseHappyHourFile.interface";

const endpointsHappyHour = {
  listAndCount: () => `/happy-hours/`,
  itemById: (id: number) => `/happy-hours/${id}`,
  listByVenue: (venueId: number) => `/happy-hours/venues/${venueId}/list`,
  itemByVenue: (venueId: number) => `/happy-hours/venues/${venueId}/item`,
  add: () => `/happy-hours/`,
  update: () => `/happy-hours/`,
  remove: (id: number) => `/happy-hours/${id}`,
  createRequest: () => `/happy-hours/requests/`,
  updateDealStatus: (id: number) => `/happy-hours/deals/${id}`,
  uploadHappyHourFiles: () => `/happy-hours/files/`,
  getHappyHourFilesByFilesNames: () => `/happy-hours/files/names/`,
};

const happyHourListAndCount = (
  limit?: number,
  skip?: number,
  search?: string
) =>
  http
    .get(endpointsHappyHour.listAndCount(), { params: { limit, skip, search } })
    .then((response) => response.data);

const happyHourListByVenue = (venueId: number) =>
  http
    .get(endpointsHappyHour.listByVenue(venueId))
    .then((response) => response.data);

const happyHourItemById = (id: number) =>
  http.get(endpointsHappyHour.itemById(id)).then((response) => response.data);

const happyHourItemByVenue = (venueId: number) =>
  http
    .get(endpointsHappyHour.itemByVenue(venueId))
    .then((response) => response.data);

const happyHourAdd = (data: CreateHappyHour) =>
  http.post(endpointsHappyHour.add(), data).then((response) => ({
    ...response.data,
  }));

const happyHourGetFilesByFilesNames = (filesNames: string[]) =>
  http
    .post(endpointsHappyHour.getHappyHourFilesByFilesNames(), { filesNames })
    .then((response) => response.data);

const happyHourUpdate = (data: CreateHappyHour) =>
  http.put(endpointsHappyHour.update(), data).then((response) => ({
    ...response.data,
  }));

const happyHourRemove = (id: number): Promise<HappyHour> =>
  http.delete(endpointsHappyHour.remove(id)).then((response) => ({
    ...response.data,
  }));

const happyHourCreateRequest = (data: DtoHappyHourRequest) =>
  http.post(endpointsHappyHour.createRequest(), data).then((response) => ({
    ...response.data,
  }));

const happyHourUpdateDealStatus = (id: number, status: string) =>
  http
    .patch(endpointsHappyHour.updateDealStatus(id), { status })
    .then((response) => ({
      ...response.data,
    }));

const happyHourUploadFile = (
  file: File | Blob,
  progressUpdate: (progressPercent: number) => void
): Promise<ResponseHappyHourFile> => {
  const formData = new FormData();
  if ("name" in file) {
    formData.append("file", file, file.name);
  } else {
    formData.append("file", file);
  }

  return http
    .post(endpointsHappyHour.uploadHappyHourFiles(), formData, {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        progressUpdate(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    })
    .then((response) => ({
      ...response.data,
    }));
};

export default {
  happyHours: {
    listAndCount: happyHourListAndCount,
    listByVenue: happyHourListByVenue,
    itemByVenue: happyHourItemByVenue,
    itemById: happyHourItemById,
    add: happyHourAdd,
    update: happyHourUpdate,
    remove: happyHourRemove,
    createRequest: happyHourCreateRequest,
    updateDealStatus: happyHourUpdateDealStatus,
    uploadFile: happyHourUploadFile,
    getFilesByFilesNames: happyHourGetFilesByFilesNames,
    endpoints: endpointsHappyHour,
  },
};
