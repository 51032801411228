import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import ButtonBlack from "../../../../common/components/ButtonBlack";
import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import ConfirmDialog from "../../../../common/components/dialogs/ConfirmDialog";
import { ConfirmModalType } from "../../../../common/components/dialogs/ConfirmDialog/Props";
import dictionary from "../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";
import { useVenuesTable } from "./useVenuesTable";

const VenuesTable: FC<Props> = (props: Props) => {
  const { venueList, setEditVenueId, handleOpenVenueMode, editVenueId } = props;
  const classes = useStyles();
  const {
    confirmedDeleteVenue,
    handleDeleteUnclaimedVenue,
    handleOpenConfirmDeleteVenuePopup,
    handleClickEditMode,
    setConfirmedDeleteVenue,
  } = useVenuesTable({ editVenueId, setEditVenueId, handleOpenVenueMode });

  return (
    <Table>
      <TableHead className={classes.thead}>
        <TableRow>
          <TableCell className={classes.th}>
            <Typography variant={"body1"} className={classes.heading}>
              {dictionary.dataEntryVenues.tableHeaders.venuesName}
            </Typography>
          </TableCell>
          <TableCell className={classes.th}>
            <Typography variant={"body1"} className={classes.heading}>
              {dictionary.dataEntryVenues.tableHeaders.venuesId}
            </Typography>
          </TableCell>
          <TableCell className={classes.th}>
            <Typography variant={"body1"} className={classes.heading}>
              {dictionary.dataEntryVenues.tableHeaders.venuesAddress}
            </Typography>
          </TableCell>
          <TableCell className={classes.th} />
        </TableRow>
      </TableHead>
      <TableBody className={classes.tbody}>
        {venueList.map((venue) => (
          <TableRow className={classes.tableRow} hover key={venue.id}>
            <TableCell className={clsx(classes.td, classes.td35)}>
              <Typography className={classes.venueName} variant="body1">
                {venue.name}
              </Typography>
            </TableCell>
            <TableCell className={clsx(classes.td, classes.td15)}>
              <Typography variant={"body1"}>{venue.id}</Typography>
            </TableCell>
            <TableCell className={clsx(classes.td, classes.td35)}>
              <Typography variant={"body1"}>{venue.address}</Typography>
            </TableCell>
            <TableCell className={clsx(classes.td, classes.td15)}>
              <div className={classes.actionsButtonWrapper}>
                <ButtonBlack
                  className={classes.editButton}
                  onClick={() => handleClickEditMode(venue.id)}>
                  {dictionary.dataEntryVenues.actionButtons.edit}
                </ButtonBlack>
                <ButtonPrimary
                  className={classes.deleteButton}
                  onClick={() => handleOpenConfirmDeleteVenuePopup(venue.id)}>
                  {dictionary.dataEntryVenues.actionButtons.delete}
                </ButtonPrimary>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <ConfirmDialog
        onConfirm={handleDeleteUnclaimedVenue}
        open={confirmedDeleteVenue}
        onClose={() => setConfirmedDeleteVenue(false)}
        subtitle={dictionary.dataEntryVenues.confirmDeleteVenueMessage}
        type={ConfirmModalType.YES_DELETE}
        message={" \n  "}
        yesTypeAdditionalText={"Delete"}
      />
    </Table>
  );
};

export default VenuesTable;
