import { TextField, Typography } from "@material-ui/core";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { changePasswordRequest } from "../../../../common/api";
// eslint-disable-next-line max-len
import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import ButtonSecondary from "../../../../common/components/ButtonSecondary";
// eslint-disable-next-line max-len
import PasswordRequirementsPopin from "../../../../common/components/PasswordRequirementsPopin";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { forgotPasswordReset } from "../../../../store/forgotPassword/actions";
import {
  clearForm,
  setAndValidate,
  setFormError,
  setFormValue,
  validateForm,
} from "../../../../store/forms/actions";
// eslint-disable-next-line max-len
import { ChangePasswordForm as ChangePasswordFormInterface } from "../../../../store/forms/types";
import { AppState } from "../../../../store/rootReducer";
import { createSchema } from "./form";
import Props from "./Props";

const ChangePasswordForm: FC<Props> = (props: Props) => {
  const { classes, token, method, codeProp } = props;
  const history = useHistory();
  const { showAlert } = useContext(AlertContext);
  const dispatch = useDispatch();
  const form = useSelector<AppState, ChangePasswordFormInterface>(
    (state) => state.forms.changePassword
  );
  const schema = createSchema(form.values.password);
  const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);

  const handleCancel = () => {
    dispatch(forgotPasswordReset());
  };

  useEffect(() => {
    if (codeProp) {
      dispatch(setFormValue("changePassword", "code", codeProp));
    }
  }, [codeProp, dispatch]);

  const handlePasswordRequirementsError = () => {
    if (!form.errors.password) {
      dispatch(
        setFormError(
          "changePassword",
          "password",
          dictionary.forgotPassword.doesNotMeetRequirements
        )
      );
    }
  };

  const handleRequest = async () => {
    try {
      await changePasswordRequest({
        token,
        password: form.values.password as string,
        code: form.values.code as string,
      });

      dispatch(forgotPasswordReset());
      history.push(Route.Login);
      dispatch(clearForm("changePassword"));
      dispatch(clearForm("forgotPassword"));
      showAlert(dictionary.forgotPassword.forgotPasswordSuccess, "success");
    } catch (e) {
      showAlert(dictionary.forgotPassword.forgotPasswordError, "error");
    }
  };

  const validateAndSubmit = async () => {
    if (!dispatch(validateForm("changePassword", schema))) {
      return;
    }

    await handleRequest();
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    dispatch(setAndValidate("changePassword", name, value, schema));
  };

  return (
    <>
      <div>
        <Typography className={classes.formLabel}>
          {dictionary.forgotPassword.enterAndConfirm}
          {method && (
            <>
              <br />
              <br />
              {dictionary.forgotPassword.sentSecurityCode(method.target)}
            </>
          )}
        </Typography>
        <div className={classes.inputField}>
          <TextField
            // className={classes.inputField}
            error={!!form.errors.password}
            fullWidth
            onFocus={() => setIsPasswordFieldFocused(true)}
            required
            InputProps={{
              id: "password",
            }}
            helperText={form.errors.password}
            label={dictionary.forgotPassword.password}
            name="password"
            value={form.values.password}
            onChange={handleChange}
            type="password"
            variant="outlined"
          />
          <PasswordRequirementsPopin
            inputId={"#password"}
            isPasswordFieldFocused={isPasswordFieldFocused}
            setIsPasswordFieldFocused={setIsPasswordFieldFocused}
            password={form.values.password}
            handlePasswordRequirementsError={handlePasswordRequirementsError}
          />
        </div>
        <div>
          <div className={classes.inputField}>
            <TextField
              // className={classes.inputField}
              error={!!form.errors.confirmNewPassword}
              fullWidth
              required
              helperText={form.errors.confirmNewPassword}
              label={dictionary.forgotPassword.confirmNewPassword}
              name="confirmNewPassword"
              onChange={handleChange}
              type="password"
              value={form.values.confirmNewPassword}
              variant="outlined"
            />
          </div>
          {!codeProp && (
            <div className={classes.inputField}>
              <TextField
                // className={classes.inputField}
                error={!!form.errors.code}
                fullWidth
                required
                helperText={form.errors.code}
                label={dictionary.forgotPassword.code}
                name="code"
                onChange={handleChange}
                type="text"
                value={form.values.code}
                variant="outlined"
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.actionsWrapper}>
        <ButtonPrimary
          className={classes.primaryBtn}
          disabled={!form.isValid}
          fullWidth
          onClick={validateAndSubmit}>
          {dictionary.forgotPassword.change}
        </ButtonPrimary>
        {!codeProp && (
          <ButtonSecondary
            className={classes.btn}
            fullWidth
            onClick={handleCancel}>
            {dictionary.forgotPassword.cancel}
          </ButtonSecondary>
        )}
      </div>
    </>
  );
};

export default ChangePasswordForm;
