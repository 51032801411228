import { Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useCallback } from "react";

import PageTitle from "../../../common/components/PageTitle";
import VenueReportingContextProvider from "../../../common/components/VenueReportingContextProvider";
import { useGlobalStyles } from "../../../common/styles";
import dictionary from "../../../i18n/en_US/dictionary";
import MostPopularPromotionsChart from "./charts/MostPopularPromotionsChart";
import PromotionUsagesViewsChart from "./charts/PromotionUsagesViewsChart";
import TabsPromotions from "./components/Tabs";
import VenueAdminFilters from "./components/VenueAdminFilters";
import { useStyles } from "./styles";

// indexes (hashes order) is important
const tabHashes = ["#deals", "#happy-hours"];

const VenueAdminPromotions: FC = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const { hash } = window.location;

  const initialTabIndex = hash
    ? tabHashes.indexOf(hash) !== -1
      ? tabHashes.indexOf(hash)
      : 0
    : 0;

  const [currentTab, setCurrentTab] = React.useState(initialTabIndex);

  const changeTab = (index: number) => {
    setCurrentTab(index);
    window.location.hash = tabHashes[index];
  };

  const GraphicsDeals = useCallback(
    () => (
      <>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <PromotionUsagesViewsChart title={"Deals Views"} />
        </Grid>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <MostPopularPromotionsChart />
        </Grid>
      </>
    ),
    []
  );

  const GraphicsHappyHours = useCallback(
    () => (
      <Grid item xs={12} lg={12} md={12} xl={12}>
        <PromotionUsagesViewsChart title={"Happy Hours Views"} />
      </Grid>
    ),
    []
  );

  return (
    <VenueReportingContextProvider>
      <div>
        <div
          className={clsx(globalClasses.flexRow_Center, classes.titleWrapper)}>
          <PageTitle>{dictionary.deals.titlePage}</PageTitle>
          <VenueAdminFilters className={classes.periodSelect} />
        </div>
        <Grid container spacing={3} className={classes.gridContainer}>
          {currentTab === 0 ? <GraphicsDeals /> : <GraphicsHappyHours />}

          <Grid item xs={12} lg={12} md={12} xl={12}>
            <TabsPromotions currentTab={currentTab} changeTab={changeTab} />
          </Grid>
        </Grid>
      </div>
    </VenueReportingContextProvider>
  );
};

export default VenueAdminPromotions;
