import { colors } from "../colors";

export default {
  containedPrimary: {
    borderRadius: 3,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: colors.red.primary,
      boxShadow: "0px 2px 10px 0px rgba(235, 82, 82, 0.44)",
    },
  },
  outlined: {
    paddingTop: 7,
    paddingBototm: 7,
    label: {
      fontWeight: "bold",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
};
