import React, { ChangeEvent, useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { VenueType } from "../../../../common/models";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  clearForm,
  setAndValidate,
  setFormError,
  setFormValue,
  validateForm,
} from "../../../../store/forms/actions";
import { FormFieldKey } from "../../../../store/forms/types";
import { AppState } from "../../../../store/rootReducer";
import { formatMobileNumberWithoutSymbols } from "../../../../utils/phoneNumberUtils";
import { getVenueTypes } from "../../../VenueSettings/components/VenueCommonData/api";
import {
  ADD_NEW_VENUE,
  GET_UNCLAIMED_VENUE,
  UPDATE_NEW_VENUE,
} from "../../api";
import {
  AddNewVenueRequest,
  GetUnclaimedVenueResponse,
  SubTypes,
  WorkingTimeDataTeamForm,
} from "../../models";
import { addNewVenueFormSchema } from "./form";

interface Props {
  onCloseVenueDialog: () => void;
  editVenueId: number;
}

export function useAddNewVenueDialog(props: Props) {
  const { onCloseVenueDialog, editVenueId } = props;
  const dispatch = useDispatch();
  const { showAlert } = useContext(AlertContext);
  const { forms } = useSelector((state: AppState) => state);
  const { addNewVenue: addNewVenueForm, workingDays: workingDaysForm } = forms;

  const [venueData, setVenueData] = useState<GetUnclaimedVenueResponse | null>(
    null
  );
  const defaultTypesState: { bar: VenueType[]; restaurant: VenueType[] } = {
    bar: [],
    restaurant: [],
  };
  const [types, setTypes] = useState(defaultTypesState);
  const [barTypesSelected, setBarTypesSelected] = useState<string[]>([]);
  const [restaurantTypesSelected, setRestaurantTypesSelected] = useState<
    string[]
  >([]);

  const [{ loading: loadingAddingNewVenue }, addNewVenue] = useRestApi<
    any,
    AddNewVenueRequest
  >(ADD_NEW_VENUE, "POST");
  const [, getUnclaimedVenueData] = useRestApi<GetUnclaimedVenueResponse>(
    GET_UNCLAIMED_VENUE(editVenueId),
    "GET"
  );
  const [{ loading: loadingUpdatingVenueData }, updateUnclaimedVenueData] =
    useRestApi<any, AddNewVenueRequest>(UPDATE_NEW_VENUE(editVenueId), "PUT");

  const uploadEditVenueInfo = useCallback(() => {
    if (editVenueId) {
      const editVenue = getUnclaimedVenueData();
      editVenue.then((response) => {
        if (response) {
          const responseRestaurantType =
            response.type.restaurant?.map((type) => type.id.toString()) || [];
          const responseBarType =
            response.type.bar?.map((type) => type.id.toString()) || [];
          setVenueData(response);

          setRestaurantTypesSelected(responseRestaurantType);
          setBarTypesSelected(responseBarType);
          dispatch(
            setFormValue("addNewVenue", "subtypes", [
              ...responseBarType,
              ...responseRestaurantType,
            ])
          );
          dispatch(setFormValue("addNewVenue", "name", response.name));
          dispatch(setFormValue("addNewVenue", "address", response.address));
          dispatch(setFormValue("addNewVenue", "city", response.location.city));
          dispatch(
            setFormValue("addNewVenue", "state", response.location.state)
          );
          dispatch(setFormValue("addNewVenue", "email", response.email));
          dispatch(setFormValue("addNewVenue", "phone", response.phone));
          dispatch(setFormValue("addNewVenue", "website", response.website));
          dispatch(setFormValue("addNewVenue", "price", response.price));
          dispatch(
            setFormValue("addNewVenue", "longitude", response.longitude)
          );
          dispatch(setFormValue("addNewVenue", "latitude", response.latitude));
          dispatch(setFormValue("addNewVenue", "postcode", response.postcode));
          dispatch(
            setFormValue("workingDays", "workingDays", response.workingDays)
          );
        }
      });
    } else {
      setRestaurantTypesSelected([]);
      setBarTypesSelected([]);
    }
  }, [dispatch, editVenueId, getUnclaimedVenueData]);

  const getAndSetVenuesTypes = useCallback(() => {
    getVenueTypes().then((resp) => {
      if (resp && resp.bar && resp.restaurant) {
        setTypes(resp);
      }
    });
  }, []);

  const handleChangeFormField = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;
    const onlyNumFields = ["phoneNumber", "postcode", "longitude", "latitude"];
    const numberPattern = new RegExp("^[^a-zA-Z]+$");

    if (onlyNumFields.includes(key)) {
      if (numberPattern.test(value) || value === "") {
        dispatch(
          setAndValidate(
            "addNewVenue",
            key as FormFieldKey,
            value,
            addNewVenueFormSchema
          )
        );
      } else return;
    } else
      dispatch(
        setAndValidate(
          "addNewVenue",
          key as FormFieldKey,
          value,
          addNewVenueFormSchema
        )
      );
  };

  const handleChangePhoneNumber = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    const formattedNumber = formatMobileNumberWithoutSymbols(value);

    dispatch(
      setAndValidate(
        "addNewVenue",
        "phone",
        formattedNumber || "",
        addNewVenueFormSchema
      )
    );
  };

  const handleChangeState = (state?: string) => {
    dispatch(
      setAndValidate("addNewVenue", "state", state || "", addNewVenueFormSchema)
    );
  };

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setAndValidate(
        "addNewVenue",
        "price",
        Number((event.target as HTMLInputElement).value) || 1
      )
    );
  };

  const handleChangeTypes = (value: string[], typeKind: SubTypes) => {
    const numberValue = value.map((v) => Number(v));
    let valueField;

    if (typeKind === SubTypes.bar) {
      selectedTypesBar(numberValue);
      valueField = [...restaurantTypesSelected, ...numberValue];
    } else {
      valueField = [...barTypesSelected, ...numberValue];
      selectedTypesRestaurant(numberValue);
    }

    valueField = valueField.map((v) => Number(v));
    dispatch(
      setAndValidate(
        "addNewVenue",
        "subtypes",
        valueField,
        addNewVenueFormSchema
      )
    );
  };

  const selectedTypesBar = (allTypes: number[]) => {
    const barTypes = types[SubTypes.bar]
      .filter((subtype) => allTypes.indexOf(subtype.id) !== -1)
      .map((typeEl) => typeEl.id.toString());
    setBarTypesSelected(barTypes);
  };

  const selectedTypesRestaurant = (allTypes: number[]) => {
    const restaurantTypes = types[SubTypes.restaurant]
      .filter((subtype) => allTypes.indexOf(subtype.id) !== -1)
      .map((typeEl) => typeEl.id.toString());

    setRestaurantTypesSelected(restaurantTypes);
  };

  const resetForm = () => {
    dispatch(clearForm("addNewVenue"));
    dispatch(clearForm("workingDays"));
    setVenueData(null);
    setBarTypesSelected([]);
    setRestaurantTypesSelected([]);
    onCloseVenueDialog();
  };

  const handleCreateVenue = () => {
    if (!dispatch(validateForm("addNewVenue", addNewVenueFormSchema))) {
      return;
    }

    const workingTime: WorkingTimeDataTeamForm[] =
      workingDaysForm.values.workingDays?.map((day) => ({
        day: day.day,
        isOpen: day.isOpen,
        hours: day.hours.map((hr) => ({ from: hr.from, to: hr.to })),
      })) || [];

    const requestData: AddNewVenueRequest = {
      ...addNewVenueForm.values,
      longitude: Number(addNewVenueForm.values.longitude),
      latitude: Number(addNewVenueForm.values.latitude),
      website: addNewVenueForm.values.website || undefined,
      phone: addNewVenueForm.values.phone || undefined,
      email: addNewVenueForm.values.email || undefined,
      workTime: workingTime,
    };

    const mainAction = editVenueId ? updateUnclaimedVenueData : addNewVenue;

    mainAction(requestData)
      .then((res) => {
        if (res) {
          showAlert(
            editVenueId
              ? dictionary.dataEntryVenues.requestMessages
                  .successUpdatedVenueMessage
              : dictionary.dataEntryVenues.requestMessages
                  .successAddedVenueMessage,
            "success"
          );
          resetForm();
        }
      })
      .catch((e) => {
        const error = e.response.data.details[0].message;
        const fieldPath = e.response.data.details[0].path[0];

        dispatch(setFormError("addNewVenue", fieldPath, error));
        showAlert(
          error || editVenueId
            ? dictionary.dataEntryVenues.requestMessages.errorUpdateVenueMessage
            : dictionary.dataEntryVenues.requestMessages.errorAddVenueMessage,
          "error"
        );
      });
  };

  return {
    venueData,
    uploadEditVenueInfo,
    getAndSetVenuesTypes,
    loadingUpdatingVenueData,
    loadingAddingNewVenue,
    addNewVenueForm,
    types,
    barTypesSelected,
    restaurantTypesSelected,
    handleCreateVenue,
    resetForm,
    selectedTypesRestaurant,
    selectedTypesBar,
    handleChangeTypes,
    handleChangePrice,
    handleChangeState,
    handleChangePhoneNumber,
    handleChangeFormField,
    setTypes,
  };
}
