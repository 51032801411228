import React, { FC, useEffect } from "react";

import { useRestApi } from "../../../../common/hooks/useRestApi";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import DashboardWidgetSmall from "../../../Dashboard/components/DashboardWidgetSmall";
import { ScoreTotal } from "../../../Dashboard/models";
import { GET_VENUES_TOTAL } from "./api";

const TotalVenues: FC = () => {
  const [{ data, loading }, getVenuesQty] = useRestApi<ScoreTotal>(
    GET_VENUES_TOTAL,
    "GET"
  );

  useEffect(() => {
    getVenuesQty().catch((e) => console.error(e));
  }, [getVenuesQty]);

  return (
    <DashboardWidgetSmall
      orientation={"vertical"}
      loading={loading}
      icon={"venues"}
      description={dictionary.dashboard.totalVenues}
      link={Route.Venues}
      score={data?.score || 0}
    />
  );
};

export default TotalVenues;
