import { http } from "../../../../../config/api";
import IUpdateRoleRequest from "./models/IUpdateRoleRequest";

const RBAC_ROLE = (roleId: number): string => `rbac/role/${roleId} `;

export const updateRole = async (
  roleId: number,
  updateRoleRequest: IUpdateRoleRequest
) =>
  http
    .put(RBAC_ROLE(roleId), updateRoleRequest)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });
