import { Divider } from "@material-ui/core";
import React, { FC, useContext } from "react";

import OcietyDialogTitle from "../../../../../../common/components/dialogs/OcietyDIalogTitle";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { PromoCodesContext } from "../../../../providers";
import { DialogContext } from "../../DialogContextProvider";

const Title: FC = () => {
  const { resetAndCloseForm } = useContext(DialogContext);
  const { promoCodeId, isPreview } = useContext(PromoCodesContext);
  const getTitle = (): string => {
    if (promoCodeId && isPreview) {
      return dictionary.couponManagement.couponPreview;
    }

    if (promoCodeId) {
      return dictionary.couponManagement.editCoupon;
    }

    return dictionary.couponManagement.addNewCode;
  };

  return (
    <div>
      <OcietyDialogTitle id="promo-dialog-title" onClose={resetAndCloseForm}>
        {getTitle()}
      </OcietyDialogTitle>
      <Divider />
    </div>
  );
};
export default Title;
