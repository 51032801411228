import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";

export type ClassNames =
  | "root"
  | "content"
  | "changePaymentMethodDialog"
  | "nextPayment"
  | "paymentWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      content: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(2),
        },
      },
      changePaymentMethodDialog: {
        paddingTop: 50,
        [theme.breakpoints.up("md")]: {
          minWidth: 700,
        },
      },
      paymentWrapper: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      nextPayment: {
        [theme.breakpoints.up("md")]: {
          marginLeft: theme.spacing(4),
        },
        [theme.breakpoints.down("sm")]: {
          marginTop: theme.spacing(4),
        },
      },
    })
);
