import { ChartDataItem } from "./models";

export const chartDataMock: ChartDataItem[] = [
  {
    month: "Jan",
    new: 1200,
    general: 668,
  },
  {
    month: "Feb",
    new: 1200,
    general: 668,
  },
  {
    month: "Mar",
    new: 777,
    general: 668,
  },
  {
    month: "Apr",
    new: 999,
    general: 500,
  },
  {
    month: "May",
    new: 567,
    general: 900,
  },
  {
    month: "Jun",
    new: 1111,
    general: 668,
  },
];
