import { Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import ButtonBlack from "../../../../common/components/ButtonBlack";
import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import ButtonSuccess from "../../../../common/components/ButtonSuccess";
import { CURRENCY_SYMBOL, isPackagesDisabled } from "../../../../config";
import dictionary from "../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const PackageCard: FC<Props> = (props: Props) => {
  const {
    active,
    onEnable,
    onDisable,
    toggleCart,
    children,
    inCart,
    price,
    renewable,
    name,
    cancelable,
  } = props;
  const classes = useStyles();

  const renderButton = () => {
    if (isPackagesDisabled && name !== "Venue Boost") {
      return null;
    }

    if (!cancelable) {
      return (
        <ButtonBlack fullWidth disabled>
          {dictionary.plan.defaultPackage}
        </ButtonBlack>
      );
    }

    if (inCart) {
      return (
        <ButtonSuccess fullWidth onClick={toggleCart}>
          {dictionary.plan.inCart}
        </ButtonSuccess>
      );
    }

    return active ? (
      <ButtonPrimary fullWidth onClick={onDisable}>
        {dictionary.plan.cancelPackage}
      </ButtonPrimary>
    ) : (
      <ButtonSuccess fullWidth onClick={onEnable}>
        {dictionary.plan.addPackageButton}
      </ButtonSuccess>
    );
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.name}>{name}</Typography>
        {!isPackagesDisabled && (
          <div className={classes.priceContainer}>
            <Typography
              component={"span"}
              variant={"body2"}
              className={classes.currency}>
              {CURRENCY_SYMBOL}
            </Typography>
            <Typography component={"span"} className={classes.price}>
              {price}
            </Typography>
            {renewable && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.period}>
                {dictionary.plan.periodLabel}
              </Typography>
            )}
          </div>
        )}
      </div>
      <div className={clsx(classes.container, classes.contentContainer)}>
        {children}
      </div>
      <div className={classes.container}>
        <div>{renderButton()}</div>
      </div>
    </Paper>
  );
};

export default PackageCard;
