import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../common/models";

export type ClassNames = "root" | "btn" | "icon" | "table";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      btn: {
        margin: 30,
      },
      icon: {
        padding: theme.spacing(1),
      },
      table: {
        margin: 100,
      },
    })
);
