export const VENUE_CREATION_REQUEST_APPROVE = "venue/add-venue-approve";
export const VENUE_CREATION_REQUEST_REJECT = (entityId: number) =>
  `venue/add-venue-reject/${entityId}`;

export interface ApproveVenueRequest {
  requestId: number;
  venueName: string;
  venueAddress: string;
  venuePostcode: string;
  venueCity: string;
  venueState: string;
  venueCountry: string;
  venueEmail: string;
  venuePhone: string;
  venueLongitude: string;
  venueLatitude: string;
  venueBarTypesIds?: string;
  venueRestaurantTypesIds?: string;
  venueWebsite?: string;
}

export interface RejectVenueRequest {
  comment: string;
}
