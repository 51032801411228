import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "arrowButton"
  | "root"
  | "content"
  | "icon"
  | "nameContainer"
  | "avatar"
  | "actions"
  | "address"
  | "tableRow"
  | "title"
  | "textField"
  | "heading"
  | "td"
  | "td40"
  | "td20"
  | "emptyText"
  | "td5"
  | "tbody"
  | "sectionHeader"
  | "venueName";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        padding: theme.spacing(5),
        minHeight: 405,
        position: "relative",
      },
      tbody: {
        boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)",
      },
      emptyText: {
        textAlign: "center",
        marginTop: 150,
      },
      td40: {
        width: "40%",
        paddingLeft: theme.spacing(4),
      },
      td20: {
        width: "20%",
      },
      td5: {
        width: "5%",
      },
      td: {
        position: "relative",
        border: "none",
        "&:after": {
          content: "''",
          display: "block",
          position: "absolute",
          left: 0,
          bottom: 0,
          width: "100%",
          height: theme.spacing(1),
          background: theme.palette.background.default,
          boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)",
        },
        "&:first-of-type, &:last-of-type": {
          borderRadius: 4,
        },
      },
      content: {
        padding: 0,
        marginBottom: theme.spacing(4),
      },
      nameContainer: {
        display: "flex",
        alignItems: "center",
      },
      avatar: {
        height: 60,
        width: 60,
      },
      actions: {
        justifyContent: "flex-end",
      },
      tableRow: {
        padding: theme.spacing(1, 1, 2),
      },
      icon: {
        padding: theme.spacing(1),
      },
      title: {
        marginTop: theme.spacing(3),
      },
      textField: {
        marginTop: theme.spacing(2),
      },
      venueName: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
      },
      address: {
        color: colors.grey.additional,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
      },
      heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
      },
      arrowButton: {
        minWidth: 58,
        height: 38,
      },
      sectionHeader: {
        display: "flex",
        marginBottom: theme.spacing(3.6),
        alignItems: "center",
      },
    })
);
