import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert as BaseAlert, AlertTitle, Color } from "@material-ui/lab";
import clsx from "clsx";
import React, { FC, useContext, useEffect, useRef } from "react";

import OcietyIcon from "../OcietyIcon";
import { AlertContext } from "./AlertContextProvider";
import Props from "./Props";
import { useStyles } from "./styles";

const ALERT_LIFETIME = 5000;

const Alert: FC<Props> = (props) => {
  const { hideAlert } = useContext(AlertContext);
  const { children, severity, title, alertId, className, time, ...rest } =
    props;
  const isFirstRender = useRef(true);

  const classes = useStyles();

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      setTimeout(
        () => {
          hideAlert(alertId);
        },
        time ? time : ALERT_LIFETIME
      );
    }
  }, [time, isFirstRender, hideAlert, alertId]);

  const getIconsMapping = (): Partial<Record<Color, React.ReactNode>> => {
    const iconsNames: Partial<Color[]> = [
      "error",
      "info",
      "success",
      "warning",
    ];
    const iconsMapping: Partial<Record<Color, React.ReactNode>> = {};

    iconsNames.forEach((name) => {
      if (name) {
        iconsMapping[name] = (
          <OcietyIcon name={name} width="26" height="26" viewBox="0 0 26 26" />
        );
      }
    });

    return iconsMapping;
  };

  const getDefaultTitle = () => {
    const defaultTitle = severity || "success";

    return (
      defaultTitle.slice(0, 1).toUpperCase() +
      defaultTitle.slice(1, defaultTitle.length)
    );
  };

  // @ts-ignore
  return (
    <BaseAlert
      {...rest}
      severity={severity}
      className={clsx(
        classes.alertWrapper,
        severity && classes[severity],
        className
      )}
      classes={{ icon: classes.icon, action: classes.actionIcon }}
      iconMapping={getIconsMapping()}
      action={
        // @ts-ignore
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => hideAlert(alertId)}>
          <CloseIcon fontSize="default" color="action" />
        </IconButton>
      }>
      <AlertTitle className={classes.title}>
        {title || getDefaultTitle()}
      </AlertTitle>
      <div className={classes.message}>{children}</div>
    </BaseAlert>
  );
};

export default Alert;
