import { TableRowClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

import { colors } from "../colors";

const MuiTableRow: Partial<Record<TableRowClassKey, CSSProperties>> = {
  root: {
    "&$selected": {
      backgroundColor: colors.white.primary,
    },
    "&$hover": {
      "&:hover": {
        backgroundColor: colors.white.primary,
      },
    },
  },
};

export default MuiTableRow;
