import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../common/models";

export type ClassNames = "root" | "content" | "tableContainer";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      content: {
        position: "relative",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(3, 3),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(3, 2),
        },
      },
      tableContainer: {
        paddingLeft: 20,
        paddingRight: 20,
        marginLeft: -20,
        marginRight: -20,
        overflowX: "auto",
      },
    })
);
