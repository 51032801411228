import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { FC, useContext, useState } from "react";

import { AlertContext } from "../../../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../../../common/components/LoadingIndicator";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { useStyles } from "../AddSmsMethodDialog/styles";
import { requestCodeEmail, sendEmailCode } from "./api";
import CodeStep from "./components/CodeStep";
import EmailStep from "./components/EmailStep";
import SuccessStep from "./components/SuccessStep";
import { MailSteps } from "./MailSteps";
import Props from "./Props";

const AddMailMethodDialog: FC<Props> = (props: Props) => {
  const { isOpen, setOpen, onSuccessEnabled } = props;
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const [step, setStep] = useState(MailSteps.EMAIL_STEP);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const handleClose = () => {
    setOpen(false);

    if (step === MailSteps.SUCCESS_STEP && onSuccessEnabled) {
      onSuccessEnabled();
    }
  };

  const handleRequestCodeEmail = async () => {
    if (!email) {
      return;
    }

    try {
      setLoading(true);

      const result = await requestCodeEmail(email);

      if (!result) {
        showAlert(
          dictionary.venueAdmin.profile.mfa.dialogs.email.errorResponseMsg,
          "error"
        );
      }

      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.email.successSendCodeMessage,
        "success"
      );
      setStep(MailSteps.CODE_STEP);
    } catch (e) {
      if (!e) {
        return;
      }

      handleClose();
      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.email.errorSendCodeMessage,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEmailMethodSave = async () => {
    if (!email || !code) {
      return;
    }

    try {
      setLoading(true);

      const result = await sendEmailCode(email, code);

      if (!result) {
        showAlert(
          dictionary.venueAdmin.profile.mfa.dialogs.email.errorResponseMsg,
          "error"
        );
      }

      showAlert(
        dictionary.venueAdmin.profile.mfa.dialogs.email.mfaSuccessEnable,
        "success"
      );

      setStep(MailSteps.SUCCESS_STEP);
    } catch (e) {
      if (!e) {
        return;
      }

      handleClose();
      showAlert("Some error", "error");
    } finally {
      setLoading(false);
    }
  };
  const handleBtnClick = async () => {
    if (step === MailSteps.EMAIL_STEP) {
      await handleRequestCodeEmail();
    }

    if (step === MailSteps.CODE_STEP) {
      await handleEmailMethodSave();
    }
  };

  const isAllowedContinueBtn = () => {
    if (step === MailSteps.EMAIL_STEP) {
      if (email) {
        return true;
      }
    }

    if (step === MailSteps.CODE_STEP) {
      if (email && code) {
        return true;
      }
    }

    return false;
  };
  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="alert-dialog-title">
        {dictionary.venueAdmin.profile.mfa.dialogs.email.title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {step === MailSteps.EMAIL_STEP && <EmailStep onSetEmail={setEmail} />}
        {step === MailSteps.CODE_STEP && <CodeStep onSetCode={setCode} />}
        {step === MailSteps.SUCCESS_STEP && <SuccessStep />}
        {loading && <LoadingIndicator withMask />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={() => handleClose()}>
          {dictionary.venueAdmin.profile.mfa.dialogs.email.closeButton}
        </Button>
        {step !== MailSteps.SUCCESS_STEP && (
          <Button
            color="primary"
            variant="outlined"
            disabled={!isAllowedContinueBtn()}
            onClick={handleBtnClick}>
            {dictionary.venueAdmin.profile.mfa.dialogs.email.continueButton}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default AddMailMethodDialog;
