import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type ClassNames =
  | "tbody"
  | "td30"
  | "td10"
  | "td"
  | "heading"
  | "viewRequestButton"
  | "tdTypography";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      tbody: {
        boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)",
      },
      td30: {
        width: "30%",
      },
      td10: {
        width: "10%",
      },
      td: {
        position: "relative",
        border: "none",
        "&:after": {
          content: "''",
          display: "block",
          position: "absolute",
          left: 0,
          bottom: 0,
          width: "100%",
          height: theme.spacing(1),
          background: theme.palette.background.default,
          boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)",
        },
        "&:first-of-type, &:last-of-type": {
          borderRadius: 4,
        },
      },

      heading: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        color: "#596880",
      },
      viewRequestButton: {
        minWidth: 140,
      },
      tdTypography: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
      },
    })
);
