import { http } from "../../../config/api";

const DELETE_PROMOTION = (venueId: number, promotionId: number) =>
  `promotions/${venueId}/discounts/${promotionId}`;

const DUPLICATE_PROMOTION = "/promotions/duplicate";

export const deletePromotion = async (
  venueId: number,
  promotionId: number
): Promise<any> => {
  return http.delete(DELETE_PROMOTION(venueId, promotionId));
};

export const duplicatePromotion = async (promotionId: number): Promise<any> => {
  return http.post(DUPLICATE_PROMOTION, { promotionId });
};
