import React, { FC, useMemo } from "react";

import dictionary from "../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../utils/dateTimeUtils";
import InformationBlock from "./components/InformationBlock";
import Props from "./Props";
import { useStyles } from "./styles";

const VenueClaimPendingUserData: FC<Props> = (props: Props) => {
  const classes = useStyles();

  const items = useMemo(
    () => [
      {
        label: dictionary.venueClaim.adminInformation.firstNameLabel,
        value: props.userFirstName,
      },
      {
        label: dictionary.venueClaim.adminInformation.companyLabel,
        value: props.userCompany,
      },
      {
        label: dictionary.venueClaim.adminInformation.lastNameLabel,
        value: props.userLastName,
      },
      {
        label: dictionary.venueClaim.adminInformation.phoneNumberLabel,
        value: props.userPhoneNumber,
      },
      {
        label: dictionary.venueClaim.adminInformation.titleLabel,
        value: props.userTitle,
      },
      {
        label: dictionary.venueClaim.adminInformation.emailLabel,
        value: props.userEmail,
      },
      {
        label: dictionary.venuePending.adminInformation.commentLabel,
        value: props.comment || " - ",
      },
      {
        label: dictionary.venueClaim.adminInformation.addressLabel,
        value: props.venueAddress,
      },
      {
        label: dictionary.venueClaim.adminInformation.creationDate,
        value: getFormattedDate(props.createdAt),
      },
    ],
    [
      props.comment,
      props.userCompany,
      props.userEmail,
      props.userFirstName,
      props.userLastName,
      props.userPhoneNumber,
      props.userTitle,
      props.venueAddress,
      props.createdAt,
    ]
  );

  return (
    <div className={classes.root}>
      <InformationBlock
        title={dictionary.venuePending.adminInformation.blockTitle}
        items={items}
      />
    </div>
  );
};

export default VenueClaimPendingUserData;
