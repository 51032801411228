import { AnalyticsPeriod, Review } from "../../../../../../../common/models";
import { http } from "../../../../../../../config/api";

export const GET_VENUE_REVIEWS = (venueId: number, period: AnalyticsPeriod) =>
  `/reviews/${venueId}/${period}`;

export const getVenueReviews = async (
  venueId: number,
  period: AnalyticsPeriod,
  limit?: number,
  skip?: number
): Promise<VenueReviewsResponse> =>
  http
    .get(GET_VENUE_REVIEWS(venueId, period), { params: { limit, skip } })
    .then((response) => ({ ...response.data }))
    .catch((error) => {
      throw error.response;
    });

export default interface VenueReviewsResponse {
  reviews: Review[];
  count: number;
}
