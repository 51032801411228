import { USD } from "@dinero.js/currencies";
import {
  allocate,
  Currency,
  Dinero,
  dinero,
  DineroSnapshot,
  toDecimal,
  toSnapshot,
  toUnits,
} from "dinero.js";
import moment from "moment";

import { DiscountDaysHours } from "../../../../../types/interfaces/DiscountDaysHours.interface";
import { IDayHours } from "../../models/IDayHours";

export enum Days {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export const toWorkingDays = (hours?: any[]) => {
  if (!hours || !hours.length) {
    return [];
  }

  const workingWeek: any[] = moment.weekdays(true).map((day) => ({
    id: "",
    day,
    isOpen: false,
    hours: [],
  }));

  hours.forEach((item) => {
    const day1 = workingWeek.find((day) => day.day === Days[item.day]);
    if (day1) {
      day1.id = item.id;
      day1.isOpen = item.isOpen;
      day1.hours = day1.hours
        .concat({
          id: item.id,
          from: item.startTime,
          to: item.endTime,
        })
        .sort((a: any, b: any) =>
          moment(a.from, "HH:mm:ss") > moment(b.from, "HH:mm:ss") ? 1 : -1
        );
    }
  });
  return workingWeek;
};

export const toWorkingHoursFromPromotionDaysHours = ({
  start,
  end,
  isActive,
  day,
  id,
}: DiscountDaysHours) => ({
  startTime: start,
  endTime: end,
  isOpen: isActive,
  id,
  day: Days[day as unknown as number],
  venue: undefined,
  venueId: null,
});

export const daysHoursToWorkingDays = (daysHours: IDayHours[]) =>
  toWorkingDays(daysHours.map(toWorkingHoursFromPromotionDaysHours));

export const formatMoneyToFront = (dineroObj: Dinero<number>): string =>
  toUnits(dineroObj, ({ value }) =>
    value
      .filter((amount) => amount > 0)
      .map((amount) => `${amount}`)
      .join(".")
  );

export const dineroObjDefault = dinero({
  amount: 0,
  currency: USD,
});

export const formatMoneyToCalculation = (value: string | number): number =>
  Math.round(Number(value) * 100);

export const toDineroObjFormat = (
  value: number | string,
  currency?: Currency<number>
): Dinero<number> => {
  try {
    const amount = formatMoneyToCalculation(value);
    return dinero({
      amount,
      currency: currency || USD,
    });
  } catch (e) {
    console.log(e);
    return dineroObjDefault;
  }
};

export const toDineroObjFormatSimple = (
  value: number,
  currency?: Currency<number>
): Dinero<number> => {
  try {
    return dinero({
      amount: value,
      currency: currency || USD,
    });
  } catch (e) {
    console.log(e);
    return dineroObjDefault;
  }
};

export const toMoneyFormat = (
  value: number | string,
  currency?: Currency<number>
): DineroSnapshot<number> => {
  const price = toDineroObjFormat(value, currency);
  return toSnapshot(price);
};

export function userWillPayCalculationFromPercentage(
  price: string | number,
  percent: number
): Dinero<number> {
  if (percent > 100) {
    percent = 100;
  }
  try {
    const dineroFormat = toDineroObjFormat(Number(price));

    const [, chunk] = allocate(dineroFormat, [percent, 100 - percent]);
    return chunk;
  } catch (e) {
    console.log(e);
    return dineroObjDefault;
  }
}

export function percentageCalculationFromUserWillPay(
  price: string | number,
  userWillPayPrice: string | number
): Dinero<number> {
  const priceNumber = Number(price);
  const userWillPayPriceNumber = Number(userWillPayPrice);

  let priceValue = priceNumber;
  if (priceNumber == 0 || priceNumber < userWillPayPriceNumber) {
    priceValue = userWillPayPriceNumber;
  }

  try {
    const percentDineroFormat = toDineroObjFormat(100);

    const [chunk] = allocate(percentDineroFormat, [
      priceValue - userWillPayPriceNumber,
      userWillPayPriceNumber,
    ]);

    return chunk;
  } catch (e) {
    console.log(e);
    return dineroObjDefault;
  }
}

export function transformDineroAmountToDecimalString(value: number): string {
  const valueDinero = toDineroObjFormatSimple(value);

  return toDecimal(valueDinero);
}
