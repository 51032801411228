import { useHistory } from "react-router";

import { getRouteWithSlash, Route } from "../../config/router";

export interface ReplyToUserReviewState {
  userId: number;
  userAvatar?: string;
  username: string;
}

export default function useReplyToReview(
  replyToReviewState: ReplyToUserReviewState
) {
  const history = useHistory();

  return () => {
    history.push(getRouteWithSlash(Route.Messages), replyToReviewState);
  };
}
