import { purple } from "@material-ui/core/colors";
import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
export type ClassNames = "root" | "switchBase";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        flexGrow: 1,
        marginTop: 30,
      },
      switchBase: {
        color: purple[300],
        "&$checked": {
          color: purple[500],
        },
        "&$checked + $track": {
          backgroundColor: purple[500],
        },
      },
    })
);
