import {
  CardActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ButtonBlack from "../../../../../../../../common/components/ButtonBlack";
import { usePagination } from "../../../../../../../../common/hooks";
import { useRestApi } from "../../../../../../../../common/hooks/useRestApi";
import { Route } from "../../../../../../../../config/router";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../../../../../utils/dateTimeUtils";
import { UserManagementCriticRequest } from "../../../../../../models";
import { UserManagementContext } from "../../../../../../providers";
import { useStyles } from "./styles";

const CriticRequestsTable: FC = () => {
  const [{ data }, getCriticRequests] = useRestApi<{
    list: UserManagementCriticRequest[];
  }>("/user-management/critic-requests", "GET");
  const history = useHistory();
  const classes = useStyles();
  const criticRequests = data?.list || [];
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pagination, dispatchPagination, paginationTypes] =
    usePagination(false);

  const { setCriticRequestsTabCount } = useContext(UserManagementContext);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    currentPage: number
  ) => {
    dispatchPagination({ type: paginationTypes.SET_PAGE, currentPage });
  };

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    getCriticRequests().catch((e) => console.error(e));
  }, [getCriticRequests]);

  const handlePressViewIcon = (requestId: number) => {
    history.push(Route.CriticRequest.replace(":id", requestId.toString()));
  };

  useEffect(() => {
    setCriticRequestsTabCount(criticRequests.length);
  }, [criticRequests.length, setCriticRequestsTabCount]);

  return (
    <div>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={"body2"} className={classes.heading}>
                  {dictionary.userManagement.table.head.user}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={"body2"} className={classes.heading}>
                  {dictionary.userManagement.table.head.email}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={"body2"} className={classes.heading}>
                  {dictionary.userManagement.table.head.date}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={"body2"} className={classes.heading}>
                  {dictionary.userManagement.table.head.action}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tbody}>
            {criticRequests
              .slice(
                pagination.currentPage * rowsPerPage,
                pagination.currentPage * rowsPerPage + rowsPerPage
              )
              .map((item, index) => (
                <TableRow hover key={index}>
                  <TableCell className={clsx(classes.td, classes.td30)}>
                    <Typography
                      className={classes.tdTypography}
                      variant="body1">
                      {item.fullName}
                    </Typography>
                  </TableCell>
                  <TableCell className={clsx(classes.td, classes.td30)}>
                    <Typography
                      className={classes.tdTypography}
                      variant="body1">
                      {item.email}
                    </Typography>
                  </TableCell>
                  <TableCell className={clsx(classes.td, classes.td30)}>
                    <Typography
                      className={classes.tdTypography}
                      variant="body1">
                      {getFormattedDate(item.updatedAt)}
                    </Typography>
                  </TableCell>
                  <TableCell className={clsx(classes.td, classes.td10)}>
                    <ButtonBlack
                      onClick={() => handlePressViewIcon(item.id)}
                      className={classes.viewRequestButton}>
                      {dictionary.userManagement.table.viewRequest}
                    </ButtonBlack>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <CardActions>
        <TablePagination
          component="div"
          count={criticRequests.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={pagination.currentPage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </CardActions>
    </div>
  );
};

export default CriticRequestsTable;
