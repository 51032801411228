import { Typography } from "@material-ui/core";
import Popover from "@mui/material/Popover";
import {
  endOfISOWeek,
  endOfMonth,
  startOfISOWeek,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import React, { FC, useState } from "react";
import { ClassNames, DateRange, DayPicker } from "react-day-picker";
import styles from "react-day-picker/dist/style.css";

import dictionary from "../../../i18n/en_US/dictionary";
import { ReactComponent as CalendarIcon } from "../../svg/icons/date-range-icon.svg";
import FilterDateButton from "../FilterButton";
import Props from "./Props";
import { useStyles } from "./styles";

export interface ShortcutsItem {
  label: string;
  getValue: () => DateRange;
}

const todayMonth = new Date();

const shortcutsItems: ShortcutsItem[] = [
  {
    label: "This Week",
    getValue: () => {
      return { from: startOfISOWeek(todayMonth), to: todayMonth };
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const prevWeek = subDays(todayMonth, 7);
      return { from: startOfISOWeek(prevWeek), to: endOfISOWeek(prevWeek) };
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = todayMonth;
      const prevWeek = subDays(today, 7);
      return { from: prevWeek, to: today };
    },
  },
  {
    label: "Previuos Month",
    getValue: () => {
      const previousMonth = subMonths(todayMonth, 1);
      const startOfPreviousMonth = startOfMonth(previousMonth);
      const endOfPreviousMonth = endOfMonth(previousMonth);
      return { from: startOfPreviousMonth, to: endOfPreviousMonth };
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = todayMonth;
      return { from: startOfMonth(today), to: endOfMonth(today) };
    },
  },
  {
    label: "Reset",
    getValue: () => {
      return { from: undefined, to: undefined };
    },
  },
];

const DateRangePicker: FC<Props> = ({ setDateRange }: Props) => {
  const classes = useStyles();
  const [range, setRange] = useState<DateRange | undefined>();
  const [month, setMonth] = useState<Date>(todayMonth);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const classNames: ClassNames = {
    ...styles,
    caption: classes.font,
    head_row: classes.font,
    cell: classes.font,
  };

  const handleChangeRangeWithShortcuts = (range?: DateRange) => {
    if (range?.from) {
      setMonth(range?.from);
    }
    handleChangeRange(range);
  };

  const handleChangeRange = (range?: DateRange) => {
    setRange(range);
    setDateRange(range);
  };

  return (
    <>
      <FilterDateButton
        mainIcon={<CalendarIcon />}
        label={dictionary.moneyForm.dateRange}
        handleButtonClick={handleButtonClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ root: classes.rootDateRangePopup }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div className={classes.root}>
          <div style={{ marginTop: 32 }}>
            {shortcutsItems.map((item) => (
              <Typography
                variant={"h5"}
                className={classes.shortcutsItem}
                key={item.label}
                onClick={() => handleChangeRangeWithShortcuts(item.getValue())}>
                {item.label}
              </Typography>
            ))}
          </div>
          <div>
            <Typography className={classes.selectRange}>
              {dictionary.moneyForm.selectDateRange}
            </Typography>
            <Typography variant={"h3"}>
              {dictionary.moneyForm.dateTitle(range)}
            </Typography>

            <DayPicker
              id="test"
              mode="range"
              defaultMonth={todayMonth}
              selected={range}
              onSelect={handleChangeRange}
              classNames={classNames}
              numberOfMonths={2}
              month={month}
              onMonthChange={setMonth}
              modifiersClassNames={{
                selected: classes.selected,
                today: classes.today,
              }}
            />
          </div>
        </div>
      </Popover>
    </>
  );
};

export default DateRangePicker;
