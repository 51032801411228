import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "avatar";

export const DEFAULT_AVATAR_SIZE = 70;

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      avatar: {
        height: "auto",
        minWidth: "100%",
        minHeight: "100%",
      },
      root: {
        height: DEFAULT_AVATAR_SIZE,
        width: DEFAULT_AVATAR_SIZE,
        minWidth: DEFAULT_AVATAR_SIZE,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "white",
      },
    })
);
