import { Box, Grid, Paper } from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMediaQuery } from "react-responsive";

import { AlertContext } from "../../../../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../../../../common/components/ButtonBlack";
import ListEmpty from "../../../../../../../common/components/ListEmpty";
import LoadingIndicator from "../../../../../../../common/components/LoadingIndicator";
import HttpStatusCodes from "../../../../../../../common/httpStatusCodes";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import VenuesSearchEmpty from "../../../../../../Venues/components/VenuesSearchEmpty";
import api from "../../../../../api";
import SearchPromotion from "../../../../../components/SearchPromotion";
import { PromotionsContext } from "../../../../../providers";
import { HappyHour } from "../../../../../types/interfaces/HappyHour.inteface";
import { PartialDiscount } from "../../../../../types/interfaces/PartialDiscount.interface";
import ImageDiscountStickerRed from "./../../../../../../../common/images/components/DialogCreateHappyHours/discount-sticker-red.png";
import ListNested from "./components/ListNested";
import TableCollapsible from "./components/TableCollapsible";
import DialogActiveDaysAndHours from "./dialogs/DialogActiveDaysAndHours";
import DialogCreateHappyHours from "./dialogs/DialogCreateHappyHours";
import Props from "./Props";
import { useStyles } from "./styles";

const TabHappyHours: FC<Props> = (props: Props) => {
  const isTablet = useMediaQuery({ query: "(max-width: 960px)" });
  const classes = useStyles();
  const {
    happyHoursTabCount,
    onClickItemId,
    setOnClickItemId,
    isActiveDaysDialogOpen,
    setActiveDaysDialogOpen,
    happyHoursList,
    setHappyHoursList,
    setHappyHoursTabCount,
  } = useContext(PromotionsContext);

  const [page, setPage] = useState(0);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const { showAlert } = useContext(AlertContext);
  const [happyHourId, setHappyHourId] = useState<number>(0);

  const [happyHoursCount, setHappyHoursCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const onChangeSearchValue = (search: string) => {
    setPage(1);
    setSearchValue(search);
  };
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const skip = (page > 0 ? page - 1 : 0) * rowsPerPage;

  useEffect(() => {
    setLoading(true);
    api.happyHours
      .listAndCount(rowsPerPage, skip, searchValue)
      .then((data: { list: HappyHour[]; count: number }) => {
        if (data) {
          setHappyHoursList(data.list);
          setHappyHoursCount(data.count);
        }
      })
      .catch((e) => {
        if (e.status !== HttpStatusCodes.SERVER_ERROR) {
          showAlert(dictionary.deals.createDealForm.errorFetchData, "error");
        }
      })
      .finally(() => setLoading(false));
  }, [showAlert, searchValue, skip, rowsPerPage, setHappyHoursList]);

  const updateDealStatus = async (id: number, status: string) => {
    const updatedDeal = await api.happyHours
      .updateDealStatus(id, status)
      .catch((error) => {
        switch (error.response.data) {
          case dictionary.deals.happyHours.errors.venueDoesntExist: {
            showAlert(
              dictionary.deals.happyHours.errors.venueDoesntExist,
              "error"
            );
            break;
          }
          case dictionary.deals.happyHours.errors.atLeastOneDeal: {
            showAlert(
              dictionary.deals.happyHours.errors.atLeastOneDeal,
              "error"
            );
            break;
          }
          case dictionary.deals.happyHours.errors.atLeastOneFile: {
            showAlert(
              dictionary.deals.happyHours.errors.atLeastOneFile,
              "error"
            );
            break;
          }
          case dictionary.deals.happyHours.errors.venueAlreadyUsed: {
            showAlert(
              dictionary.deals.happyHours.errors.venueAlreadyUsed,
              "error"
            );
            break;
          }
          case dictionary.deals.happyHours.errors.venueIsNotClaimed: {
            showAlert(
              dictionary.deals.happyHours.errors.venueIsNotClaimed,
              "error"
            );
            break;
          }
          default: {
            showAlert(
              dictionary.deals.createDealForm.msgErrorCreation,
              "error"
            );
          }
        }
      });

    setHappyHoursList((happyHoursList) =>
      happyHoursList.map((itemHappyHour) => {
        itemHappyHour.deals = itemHappyHour.deals.map((deal: PartialDiscount) =>
          deal.id === updatedDeal.id ? updatedDeal : deal
        );
        return itemHappyHour;
      })
    );
  };

  const editHappyHour = async (id: number) => {
    setHappyHourId(id);
    setFormDialogOpen(true);
  };

  const removeHappyHour = async (id: number) => {
    await api.happyHours.remove(id).catch((error) => {
      if (error.response.data === "Happy Hour does not exist") {
        showAlert("Happy Hour does not exist", "error");
      } else showAlert(dictionary.errors.undefinedError, "error");
    });

    setHappyHoursList((happyHoursList) =>
      happyHoursList.filter((itemHappyHour) => itemHappyHour.id !== id)
    );

    setHappyHoursTabCount((prevNumber: number) => prevNumber - 1);
  };

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const RenderEmptyList = () => (
    <div className={classes.root}>
      <Paper className={classes.wrapper}>
        {loading && <LoadingIndicator withMask />}
        <ListEmpty
          imageSource={ImageDiscountStickerRed}
          title={dictionary.deals.happyHours.title}
          description={dictionary.deals.happyHours.emptyList.description}
          buttonOnClick={() => setFormDialogOpen(true)}
        />
      </Paper>
    </div>
  );

  const activeDaysAndHours = useMemo(
    (): HappyHour | undefined =>
      happyHoursList.find((item: HappyHour) => item.id === onClickItemId),
    [happyHoursList, onClickItemId]
  );

  const onCloseDialogCreateHappyHours = () => {
    setFormDialogOpen(false);
    setHappyHourId(0);
  };

  return (
    <>
      {happyHoursTabCount ? (
        <div className={classes.root}>
          <Paper className={classes.wrapper}>
            <Grid container spacing={10} alignItems="center">
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <SearchPromotion onChange={onChangeSearchValue} />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  <ButtonBlack onClick={() => setFormDialogOpen(true)}>
                    {dictionary.general.addNew}
                  </ButtonBlack>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={10} style={{ position: "relative" }}>
              {loading && <LoadingIndicator withMask />}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {searchValue && !happyHoursList?.length ? (
                  <VenuesSearchEmpty />
                ) : isTablet ? (
                  <ListNested
                    removeHappyHour={removeHappyHour}
                    updateStatus={updateDealStatus}
                    list={happyHoursList}
                    onActiveDaysDialogOpen={(isOpen: boolean, id: number) => {
                      setActiveDaysDialogOpen(isOpen);
                      setOnClickItemId(id);
                    }}
                    count={happyHoursCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    editHappyHour={editHappyHour}
                  />
                ) : (
                  <TableCollapsible
                    removeHappyHour={removeHappyHour}
                    updateStatus={updateDealStatus}
                    editHappyHour={editHappyHour}
                    list={happyHoursList}
                    onActiveDaysDialogOpen={(isOpen: boolean, id: number) => {
                      setActiveDaysDialogOpen(isOpen);
                      setOnClickItemId(id);
                    }}
                    count={happyHoursCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handlePageChange={setPage}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </div>
      ) : (
        <RenderEmptyList />
      )}
      <DialogCreateHappyHours
        happyHourId={happyHourId}
        closeDialog={onCloseDialogCreateHappyHours}
        open={formDialogOpen}
      />
      {activeDaysAndHours && (
        <DialogActiveDaysAndHours
          isOpen={isActiveDaysDialogOpen}
          onClose={() => setActiveDaysDialogOpen(false)}
          activeDaysAndHours={activeDaysAndHours.daysHours}
        />
      )}
    </>
  );
};

export default TabHappyHours;
