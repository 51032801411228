import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../models";

export type ClassNames = "root" | "selectWrapper" | "select" | "selectLabel";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      selectWrapper: {
        // color: theme.palette.primary.main,
        // fontWeight: "bold",
        // fontSize: theme.typography.pxToRem(16),
        // boxSizing: "border-box",
        // backgroundColor: theme.palette.primary.light,
        // "& > fieldset": {
        //     border: "none",
        // },
        // [theme.breakpoints.up("sm")]: {
        //     minWidth: 292,
        // },
      },
      select: {
        // paddingTop: 15.5,
        // paddingBottom: 15.5,
      },
      selectLabel: {
        ///  visibility: "hidden",
      },
    })
);
