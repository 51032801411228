import { InputLabelClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

import { colors } from "../colors";

const selectStyles = {
  fontWeight: 600,
  color: "#585D6E",
  "&$focused, &$shrink": {
    color: colors.grey.secondary,
  },
};

const MuiInputLabel: Partial<Record<InputLabelClassKey, CSSProperties>> = {
  outlined: { ...selectStyles },
  filled: { ...selectStyles },
  shrink: { ...selectStyles },
};

export default MuiInputLabel;
