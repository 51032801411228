import { TablePagination } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import React, { FC, useContext } from "react";

import ActionsDropdown from "../../../../../../common/components/ActionsDropdown";
import ButtonBlack from "../../../../../../common/components/ButtonBlack";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { getFormattedDate } from "../../../../../../utils/dateTimeUtils";
import { transformDineroAmountToDecimalString } from "../../../../../Promotions/AdminVenue/dialogs/PromotionFormDialog/components/PromotionFormDialogContent/utils";
import { PromoCodeResponseItem } from "../../../../models";
import { PromoCodesContext } from "../../../../providers";
import Props from "./Props";
import { useStyles } from "./styles";

function Row({
  row,
  editPromoCode,
  removeCouponCode,
  setPreview,
}: {
  row: PromoCodeResponseItem;
  editPromoCode: (id: number) => void;
  removeCouponCode: (id: number) => void;
  setPreview: (id: number) => void;
}) {
  const classes = useStyles();

  const handleRemoveCouponCode = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.stopPropagation();
    await removeCouponCode(row.id);
  };

  const handleEditPromoCode = (event: any) => {
    event.stopPropagation();
    editPromoCode(row.id);
  };
  const handlePreviewPromoCode = async () => {
    setPreview(row.id);
  };
  const value = transformDineroAmountToDecimalString(row.value);

  return (
    <>
      <TableRow className={classes.tableRow} onClick={handlePreviewPromoCode}>
        <TableCell
          className={clsx(classes.rowTableCell, classes.cellText)}
          style={{ paddingLeft: 36 }}>
          {row.couponCode}
        </TableCell>
        <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
          {"$" + value}
        </TableCell>
        <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
          {getFormattedDate(row.start)}
        </TableCell>
        <TableCell className={clsx(classes.rowTableCell, classes.cellText)}>
          {getFormattedDate(row.end)}
        </TableCell>
        <TableCell
          className={clsx(
            classes.rowTableCell,
            classes.alignCenter,
            classes.cellText
          )}>
          {row.usages}
        </TableCell>
        <TableCell className={classes.rowTableCell}>
          <ButtonBlack onClick={handleEditPromoCode}>
            {dictionary.couponManagement.tableTitles.edit}
          </ButtonBlack>
        </TableCell>
        <TableCell>
          <ActionsDropdown
            iconName={"threeDotsVertical"}
            items={[
              {
                onClick: (event: React.ChangeEvent<HTMLInputElement>) =>
                  handleRemoveCouponCode(event),
                iconName: "removeAction",
                label: "Delete",
              },
            ]}
          />
        </TableCell>
      </TableRow>
      <TableRow className={classes.rowDropdownWrapper}>
        <TableCell
          className={clsx(classes.tableCell, classes.rowCellDropdownWrapper)}
          colSpan={6}
        />
      </TableRow>
    </>
  );
}

const FullTable: FC<Props> = (props: Props) => {
  const {
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    handlePageChange,
    count,
    removeCouponCode,
  } = props;
  const { promoCodesList, setPromoCodeId, setFormDialogOpen, setIsPreview } =
    useContext(PromoCodesContext);
  const classes = useStyles();

  const handleEditPromoCode = (id: number) => {
    setPromoCodeId(id);
    setFormDialogOpen(true);
  };

  const handlePreviewPromoCode = (id: number) => {
    setIsPreview(true);
    setPromoCodeId(id);
    setFormDialogOpen(true);
  };

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableHeadCell}
                style={{ paddingLeft: 28 }}>
                {dictionary.couponManagement.tableTitles.couponCode}
              </TableCell>
              <TableCell
                className={classes.tableHeadCell}
                style={{ paddingLeft: 12 }}>
                {dictionary.couponManagement.tableTitles.value}
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {dictionary.couponManagement.tableTitles.startDate}
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {dictionary.couponManagement.tableTitles.endDate}
              </TableCell>
              <TableCell
                className={clsx(classes.tableHeadCell, classes.alignCenter)}>
                {dictionary.couponManagement.tableTitles.usages}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableHeadBody}>
            {promoCodesList?.map((item: PromoCodeResponseItem) => (
              <Row
                key={item.id}
                row={item}
                editPromoCode={handleEditPromoCode}
                removeCouponCode={removeCouponCode}
                setPreview={handlePreviewPromoCode}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        onChangePage={(_, page) => handlePageChange(page)}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleRowsPerPageChange}
        rowsPerPageOptions={[10, 20, 30]}
      />
    </>
  );
};

export default FullTable;
