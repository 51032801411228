import { http } from "../../config/api";
import IVenueAdminProfile from "./models/IVenueAdminProfile";
const ADMIN_PROFILE = "profile";

export const getVenueAdminProfile = async (): Promise<IVenueAdminProfile> =>
  http
    .get(ADMIN_PROFILE)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const saveVenueAdminProfile = async (profile: IVenueAdminProfile) =>
  http
    .put(ADMIN_PROFILE, profile)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });
