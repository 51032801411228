import React, { FC, useRef, useState } from "react";

import ButtonBlack from "../../../../../../../../common/components/ButtonBlack";
import LinearProgressWithLabel from "../../../../../../../../common/components/LinearProgressWithLabel";
import OcietyIcon from "../../../../../../../../common/components/OcietyIcon";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const VideoUpload: FC<Props> = (props: Props) => {
  const { isLock } = props;
  const classes = useStyles();
  const videoInput = useRef<HTMLInputElement>(null);
  const [progressUpload, setProgressUpload] = useState(0);

  const openVideoSelector = () => {
    if (videoInput.current) {
      videoInput.current.click();
    }
    setProgressUpload(0);
  };

  const progressUpdate = (progressValue: number) => {
    if (progressValue > 0) {
      setProgressUpload(progressValue);
    }
  };

  const handleFileChange = (event: any) => {
    props.onUploadVideo(event.target.files[0], progressUpdate, () => {
      // Clear input after successfully uploading
      if (videoInput.current) {
        videoInput.current.value = "";
      }

      setTimeout(() => {
        setProgressUpload(0);
      }, 1000);
    });
  };

  return (
    <div className={classes.root}>
      <input
        ref={videoInput}
        style={{ display: "none" }}
        accept="video/*"
        onChange={handleFileChange}
        id="video-selector-input"
        type="file"
      />
      <ButtonBlack
        disabled={isLock}
        variant="contained"
        className={classes.btn}
        fullWidth
        onClick={openVideoSelector}>
        {isLock && <OcietyIcon name="lock" />}
        {dictionary.venues.mediaContent.uploadVideo}
        <LinearProgressWithLabel
          value={progressUpload}
          hidePercents
          className={classes.loadingIndicator}
        />
      </ButtonBlack>
    </div>
  );
};
export default VideoUpload;
