import { FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";

import { useAsyncState } from "../../../../../../../../../common/hooks/useAsyncState";
import { BasicPromotionCategory } from "../../../../../../../../../common/models";
import dictionary from "../../../../../../../../../i18n/en_US/dictionary";
import { DiscountKind } from "../../../../../../../types/enums/DiscountKind.enum";
import { GET_PROMOTION_FILTERED_CATEGORIES } from "./api";
import CategoriesResponse from "./models/CategoriesResponse";
import Props from "./Props";
import { useSelectStyles, useStyles } from "./styles";

const PromotionKindToName = {
  [String(DiscountKind.DEAL)]: "Ociety Deals",
  [String(DiscountKind.SPECIAL)]: "Specials",
  [String(DiscountKind.VOUCHER)]: "Ociety Vouchers",
  [String(DiscountKind.PLANET_PROMO)]: "Planet Promo",
};

const AllowedDiscountKinds: DiscountKind[] = [
  DiscountKind.DEAL,
  DiscountKind.SPECIAL,
  DiscountKind.VOUCHER,
  DiscountKind.PLANET_PROMO,
];

interface DealPromotionCategory {
  id: DiscountKind;
  name: string;
}

const PromotionCategoriesSelect: FC<Props> = (props: Props) => {
  const {
    selectedCategoryId,
    setSelectedCategoryId,
    selectedDealKind,
    setSelectedDealKind,
    setPage,
  } = props;
  const classes = useStyles();
  const selectClasses = useSelectStyles();
  const [{ response }, setCategories] = useAsyncState<CategoriesResponse>(
    GET_PROMOTION_FILTERED_CATEGORIES
  );
  const [dealCategoriesAndKinds, setDealCategoriesAndKinds] =
    useState<(BasicPromotionCategory | DealPromotionCategory)[]>();

  useEffect(() => {
    setCategories().then();
  }, [setCategories]);

  const handleChangeCategoryFilter = (e: any) => {
    const valueAsDiscountKind = e.target.value as DiscountKind;
    setPage && setPage(1);
    if (AllowedDiscountKinds.includes(valueAsDiscountKind)) {
      setSelectedCategoryId(undefined);
      setSelectedDealKind(valueAsDiscountKind);
      return;
    }
    setSelectedDealKind(undefined);
    setSelectedCategoryId(Number(e.target.value));
  };

  useEffect(() => {
    if (!response) {
      return;
    }
    const allCategoryOptionIdx = response.categories.length - 1;
    setSelectedCategoryId(response.categories[allCategoryOptionIdx].id);
    const allCategoriesOption = response.categories[allCategoryOptionIdx];
    const totalBillOption = response.categories.find(
      (category) => category.id === 5 || category.name === "Total Bill"
    );
    const responseCategoriesWithoutAllAndTotalBill = response.categories.filter(
      (category) =>
        (category.id !== totalBillOption?.id ||
          category.name !== totalBillOption?.name) &&
        category.id !== allCategoriesOption.id
    );
    const dealsAndCategories = [
      ...responseCategoriesWithoutAllAndTotalBill,
      ...(Object.keys(PromotionKindToName).map((key) => ({
        id: key as DiscountKind,
        name: String(PromotionKindToName[key]),
      })) || []),
    ];
    dealsAndCategories.push(allCategoriesOption);
    setDealCategoriesAndKinds(dealsAndCategories);
  }, [response, setSelectedCategoryId]);

  if (
    !response?.categories ||
    (selectedCategoryId === undefined && selectedDealKind === undefined)
  ) {
    return null;
  }

  return (
    <FormControl
      variant="outlined"
      className={clsx(classes.filterSelect, "select-no-legend")}>
      <Select
        classes={selectClasses}
        id="popular_promotions_category"
        value={
          selectedCategoryId !== undefined
            ? selectedCategoryId
            : selectedDealKind
        }
        label={dictionary.reporting.dealCategorySelectLabel}
        onChange={handleChangeCategoryFilter}>
        <MenuItem value="" disabled>
          <em>{dictionary.reporting.dealCategorySelectLabel}</em>
        </MenuItem>
        {dealCategoriesAndKinds?.map((dealCategory) => (
          <MenuItem key={dealCategory.id} value={dealCategory.id}>
            {dealCategory.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default PromotionCategoriesSelect;
