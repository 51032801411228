import { Grid } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";

import { VenueReportingContext } from "../../../../common/components/VenueReportingContextProvider";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import dictionary from "../../../../i18n/en_US/dictionary";
import { getVenueReviewsPeriod } from "./api";
import AverageReviews from "./components/AverageReviews";
import TotalCriticsReviews from "./components/TotalCriticsReviews";
import TotalUsersReviews from "./components/TotalUsersReviews";
import { venueReviewsStatisticsMock } from "./mock";

const ReviewsStatistics: FC = () => {
  const { period } = useContext(VenueReportingContext);
  const venueId = useCurrentVenueId();
  const [statistics, setStatistics] = useState(venueReviewsStatisticsMock);

  useEffect(() => {
    getVenueReviewsPeriod(venueId, period).then((response) =>
      setStatistics(response)
    );
  }, [venueId, period]);

  return (
    <Grid container spacing={3} style={{ maxWidth: "none" }}>
      <Grid item xl={2} lg={2} sm={6} xs={6}>
        <TotalUsersReviews
          value={statistics.totalReviews.value}
          difference={statistics.totalReviews.difference}
          description={dictionary.reviews.statistics.totalReviews}
        />
      </Grid>
      <Grid item xl={2} lg={2} sm={6} xs={6}>
        <TotalCriticsReviews
          value={statistics.criticReviews.value}
          difference={statistics.criticReviews.difference}
          description={dictionary.reviews.statistics.criticReviews}
        />
      </Grid>
      <Grid item xl={8} lg={8} sm={12} xs={12}>
        <AverageReviews statistics={statistics} />
      </Grid>
    </Grid>
  );
};

export default ReviewsStatistics;
