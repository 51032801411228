import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../common/models";
import { colors } from "../../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "text"
  | "nameText"
  | "rowItem"
  | "rowItemPrimary"
  | "rowItemStatus"
  | "rowItemLeft"
  | "rowItemRight"
  | "rowItemFirst"
  | "rowItemLast"
  | "rowItemCenter"
  | "rowItemSmallWidth"
  | "rowItemText"
  | "activeHoursWrapper"
  | "link"
  | "primaryText"
  | "rowItemActiveHours"
  | "buttonRight"
  | "buttonLeft"
  | "actionButton"
  | "duplicateButton"
  | "duplicateText"
  | "divider"
  | "buttonsContainer"
  | "editButton";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: 15,
        marginLeft: 15,
        marginRight: 15,
        paddingTop: 15,
        paddingBottom: 15,
        backgroundColor: "white",
        maxWidth: "100%",
        boxSizing: "border-box",
        overflowX: "auto",
        [theme.breakpoints.down("sm")]: {
          maxWidth: 400,
        },
        [theme.breakpoints.up("sm")]: {
          maxWidth: "100%",
        },
      },
      buttonLeft: {
        margin: theme.spacing(0, 0.5, 2, 3),
      },
      buttonRight: {
        margin: theme.spacing(0, 3, 2, 0.5),
      },
      actionButton: {
        width: "100%",
      },
      text: {
        color: colors.grey.text,
      },
      nameText: {
        color: colors.dark.main,
        overflowWrap: "anywhere",
      },
      rowItemText: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
      },
      rowItem: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        paddingTop: theme.spacing(1),
      },
      rowItemLeft: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1, 1, 1, 3),
      },
      rowItemRight: {
        display: "flex",
        justifyContent: "flex-end",
        padding: theme.spacing(1, 3, 1, 1),
      },
      rowItemStatus: {
        display: "flex",
        justifyContent: "flex-end",
        padding: theme.spacing(1, 2, 1, 1),
      },
      rowItemPrimary: {
        paddingLeft: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
          justifyContent: "flex-start",
          paddingLeft: theme.spacing(1),
        },
        maxWidth: "7%",
      },
      rowItemFirst: {
        paddingLeft: theme.spacing(3),
        maxWidth: "14%",
      },
      rowItemLast: {
        padding: theme.spacing(1, 5, 1, 1),
        display: "flex",
        justifyContent: "flex-end",
      },
      rowItemCenter: {
        display: "flex",
        justifyContent: "center",
      },
      rowItemSmallWidth: {
        maxWidth: "4%",
      },
      activeHoursWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        paddingBottom: 15,
      },
      rowItemActiveHours: {
        maxWidth: "15%",
      },
      link: {
        color: colors.blue.dark,
      },
      primaryText: {
        margin: theme.spacing(0, 0, 0, 0),
      },
      duplicateButton: {
        borderColor: colors.grey.secondary,
        textTransform: "none",
        margin: theme.spacing(0, 3, 1, 3),
      },
      duplicateText: {
        margin: theme.spacing(0.5, 1),
        fontWeight: 600,
      },
      divider: {
        margin: theme.spacing(0, 2.5, 1.5, 2.5),
      },
      buttonsContainer: {
        paddingTop: theme.spacing(2),
      },
      editButton: {
        borderColor: colors.grey.secondary,
      },
    })
);
