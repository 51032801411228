import IChangePasswordRequest from "./IChangePasswordRequest";

export default class ChangePasswordRequest implements IChangePasswordRequest {
  public readonly currentPassword: string;
  public readonly newPassword: string;

  constructor(currentPassword: string, newPassword: string) {
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
  }
}
