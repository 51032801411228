import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type ClassNames =
  | "root"
  | "shortcutsItem"
  | "today"
  | "selected"
  | "font"
  | "selectRange"
  | "rootDateRangePopup";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      padding: 20,
      alignItems: "center",
      cursor: "pointer",
    },
    shortcutsItem: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      borderRadius: 40,
      padding: 12,
      textAlign: "center",
      margin: 8,
    },
    today: {
      fontWeight: 700,
    },
    selected: {
      backgroundColor: "rgb(247 4 4 / 47%)",
    },
    font: {
      fontFamily:
        'Nunito Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    selectRange: {
      textTransform: "uppercase",
      color: "rgba(0, 0, 0, 0.6)",
    },
    rootDateRangePopup: {
      "&& .MuiPaper-root": {
        borderRadius: 20,
      },
    },
  })
);
