import { Tab, Tabs, useTheme } from "@material-ui/core";
import React, { FC, useContext, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";

import { AlertContext } from "../../../../../common/components/Alert/AlertContextProvider";
import TabPanel from "../../../../../common/components/TabPanel";
import {
  getInitialTabIndex,
  getTabProps,
} from "../../../../../common/components/TabPanel/utils";
import HttpStatusCodes from "../../../../../common/httpStatusCodes";
import dictionary from "../../../../../i18n/en_US/dictionary";
import api from "../../../api";
import { PromotionsContext } from "../../../providers";
import { HappyHour } from "../../../types/interfaces/HappyHour.inteface";
import TabHappyHours from "./components/TabHappyHours";
import TabPromotions from "./components/TabPromotions";
import { useStyles } from "./styles";

// indexes (hashes order) is important
const tabHashes = ["#deals", "#happy-hours"];

const TabsPromotions: FC = () => {
  const theme = useTheme();
  const initialTabIndex = getInitialTabIndex(
    window.location.hash,
    tabHashes,
    1
  );

  const [currentTab, setCurrentTab] = React.useState(initialTabIndex);
  const classes = useStyles();
  const { happyHoursTabCount, setHappyHoursTabCount } =
    useContext(PromotionsContext);
  const { showAlert } = useContext(AlertContext);

  const changeTab = (index: number) => {
    setCurrentTab(index);
    window.location.hash = tabHashes[index];
  };

  const handleChange = (
    event: React.ChangeEvent<Record<any, any>>,
    newValue: number
  ) => {
    changeTab(newValue);
  };

  const handleChangeIndex = (index: number) => {
    changeTab(index);
  };

  useEffect(() => {
    api.happyHours
      .listAndCount()
      .then((data: { list: HappyHour[]; count: number }) => {
        if (data) {
          setHappyHoursTabCount(data.count);
        }
      })
      .catch((e) => {
        if (e.status !== HttpStatusCodes.SERVER_ERROR) {
          showAlert(dictionary.deals.createDealForm.errorFetchData, "error");
        }
      });
  }, [setHappyHoursTabCount, showAlert]);

  return (
    <div>
      <div className={classes.tabsWrapper}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="user management tab">
          <Tab
            classes={{
              root: classes.tabRoot,
            }}
            label={dictionary.deals.tabs.deals}
            {...getTabProps(0)}
          />
          <Tab
            classes={{
              root: classes.tabRoot,
            }}
            label={
              dictionary.deals.tabs.happyHours + ` (${happyHoursTabCount})`
            }
            {...getTabProps(1)}
          />
        </Tabs>
      </div>
      <SwipeableViews
        className={classes.swipeableContainer}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={currentTab}
        onChangeIndex={handleChangeIndex}>
        <TabPanel
          hideNotActive
          value={currentTab.toString()}
          index={"0"}
          dir={theme.direction}>
          <TabPromotions />
        </TabPanel>
        <TabPanel
          hideNotActive
          value={currentTab.toString()}
          index={"1"}
          dir={theme.direction}>
          <TabHappyHours />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export default TabsPromotions;
