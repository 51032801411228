import {
  CardActions,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import TBody from "../../../../common/components/TBody";
import { usePagination } from "../../../../common/hooks";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { ReactComponent as ViewIcon } from "../../../../common/svg/icons/view.svg";
import { getRouteWithSlash, Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import { formatDateToDayMonthYear } from "../../../../utils/dateTimeUtils";
import { VENUE_CLAIMS_URL, VenueClaim, VenueClaimRequestStatus } from "./api";
import { Props } from "./Props";
import { useStyles } from "./styles";

const VenueClaimRequestsTable: FC<Props> = ({ status }) => {
  const [{ data }, getVenuesClaimPendingList] = useRestApi<VenueClaim[]>(
    VENUE_CLAIMS_URL,
    "GET",
    { params: { status } }
  );
  const history = useHistory();
  const classes = useStyles();
  const venues = data || [];
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pagination, dispatchPagination, paginationTypes] =
    usePagination(false);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    currentPage: number
  ) => {
    dispatchPagination({ type: paginationTypes.SET_PAGE, currentPage });
  };

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    getVenuesClaimPendingList().catch((e) => console.error(e));
  }, [getVenuesClaimPendingList]);

  const handlePressViewIcon = (requestId: number) => {
    history.push(
      getRouteWithSlash(Route.VenueClaim).replace(":id", requestId.toString())
    );
  };

  return (
    <div>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={"body2"} className={classes.heading}>
                  {dictionary.venueClaim.table.headingVenueName}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={"body2"} className={classes.heading}>
                  {dictionary.venueClaim.table.headingAddress}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={"body2"} className={classes.heading}>
                  {dictionary.venueClaim.table.headingUser}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={"body2"} className={classes.heading}>
                  {dictionary.venueClaim.table.headingDate}
                </Typography>
              </TableCell>
              {status !== VenueClaimRequestStatus.PENDING && (
                <TableCell>
                  <Typography variant={"body2"} className={classes.heading}>
                    {status === VenueClaimRequestStatus.APPROVED
                      ? dictionary.venueClaim.table.dateApproved
                      : dictionary.venueClaim.table.dateRejected}
                  </Typography>
                </TableCell>
              )}
              <TableCell align={"center"}>
                <Typography
                  variant={"body2"}
                  className={clsx(classes.heading, classes.headingView)}>
                  {dictionary.venuesChangesPending.table.headingView}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TBody>
            {venues
              .slice(
                pagination.currentPage * rowsPerPage,
                pagination.currentPage * rowsPerPage + rowsPerPage
              )
              .map((item, index) => (
                <TableRow hover key={index}>
                  <TableCell className={clsx(classes.td, classes.td30)}>
                    <Typography className={classes.venueName} variant="body1">
                      {item.venueName}
                    </Typography>
                  </TableCell>
                  <TableCell className={clsx(classes.td, classes.td30)}>
                    <Typography className={classes.venueName} variant="body1">
                      {item.venueAddress}
                    </Typography>
                  </TableCell>
                  <TableCell className={clsx(classes.td, classes.td30)}>
                    <Typography className={classes.venueName} variant="body1">
                      {`${item.firstName} ${item.lastName}`}
                    </Typography>
                  </TableCell>
                  <TableCell className={clsx(classes.td, classes.td30)}>
                    <Typography className={classes.venueName} variant="body1">
                      {formatDateToDayMonthYear(item.date)}
                    </Typography>
                  </TableCell>
                  {status !== VenueClaimRequestStatus.PENDING && (
                    <TableCell className={clsx(classes.td, classes.td30)}>
                      <Typography className={classes.venueName} variant="body1">
                        {formatDateToDayMonthYear(item.statusUpdatedAt)}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell
                    className={clsx(classes.td, classes.td5)}
                    align={"center"}>
                    <IconButton
                      aria-label="add"
                      onClick={() => handlePressViewIcon(item.id)}>
                      <ViewIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TBody>
        </Table>
      </div>
      <CardActions>
        <TablePagination
          component="div"
          count={venues.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={pagination.currentPage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </CardActions>
    </div>
  );
};

export default VenueClaimRequestsTable;
