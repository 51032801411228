import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "likeButton"
  | "companionMessageItemWrapper"
  | "companionTextMessage"
  | "yourMessageItemWrapper"
  | "yourTextMessage"
  | "textMessage"
  | "messageAndDateWrapper"
  | "yourMessageAndDateWrapper"
  | "companionMessageAndDateWrapper";

const MESSAGE_BORDER_RADIUS = 5;

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        width: "100%",
        overflow: "auto",
      },
      companionMessageItemWrapper: {
        margin: theme.spacing(0, 0, 0, 2),
        alignItems: "flex-end",
        width: "96%",
      },
      textMessage: {
        display: "block",
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
        overflowWrap: "anywhere",
        maxWidth: 500,
      },
      companionTextMessage: {
        backgroundColor: colors.grey.lighten,
        float: "left",
        borderTopLeftRadius: MESSAGE_BORDER_RADIUS,
        borderBottomRightRadius: MESSAGE_BORDER_RADIUS,
      },
      messageAndDateWrapper: {
        display: "flex",
        flexDirection: "column",
        marginRight: theme.spacing(2),
      },
      companionMessageAndDateWrapper: {
        alignItems: "flex-start",
      },
      yourMessageItemWrapper: {
        margin: theme.spacing(0, 0, 0, 2),
        alignItems: "flex-end",
        width: "96%",
        flexDirection: "row-reverse",
      },
      yourTextMessage: {
        float: "right",
        backgroundColor: colors.red.primary,
        color: colors.white.primary,
        borderTopRightRadius: MESSAGE_BORDER_RADIUS,
        borderBottomLeftRadius: MESSAGE_BORDER_RADIUS,
      },
      yourMessageAndDateWrapper: {
        alignItems: "flex-end",
      },
      likeButton: {
        marginLeft: theme.spacing(2),
        marginTop: 3,
      },
    })
);
