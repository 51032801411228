import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "inputMessage"
  | "sendBtn"
  | "sendBtnWrapper"
  | "inputWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        width: "100%",
      },
      inputMessage: {
        width: "100%",
        padding: theme.spacing(2),
        borderTop: `2px solid ${colors.grey.divider}`,
        borderRadius: 0,
      },
      sendBtnWrapper: {
        position: "relative",
      },
      sendBtn: {
        position: "absolute",
        right: 20,
        top: 20,
        width: 40,
        height: 40,
        minWidth: 0,
      },
      inputWrapper: {
        maxHeight: 48,
        width: "90%",
        overflow: "auto",
        minHeight: 48,
      },
    })
);
