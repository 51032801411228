import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../common/models";

export type ClassNames =
  | "root"
  | "wrapper"
  | "promotionsIcon"
  | "title"
  | "periodSelect"
  | "titleWrapper"
  | "gridContainer"
  | "dialogPaperSM"
  | "dialogActions"
  | "dialogActionsWithTotalPrice"
  | "dialogPrimaryButtonAction"
  | "actionFlexItem"
  | "actionFlexItemTotalPrice"
  | "weight700"
  | "radioWrapper"
  | "padding16"
  | "textAlignLeft"
  | "nextButtonRightAlignWrapper"
  | "marginRight16"
  | "disabledRadioText"
  | "disabledRadioHintWrap"
  | "disabledRadioInfoIcon"
  | "disabledRadioInfoMainMenuLink"
  | "disabledRadioInfoMainMenuText"
  | "inlineBlock";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        maxWidth: 1000,
        margin: "auto",
      },
      wrapper: {
        height: "100%",
        maxWidth: "100%",
        padding: 30,
      },
      promotionsIcon: {
        width: 70,
        height: 70,
      },
      title: {
        marginTop: -6,
        marginRight: theme.spacing(2),
      },
      periodSelect: {
        "&&": {
          marginLeft: 20,
        },
        "&& .MuiSelect-select": {
          padding: "13px 20px",
        },
      },
      titleWrapper: {
        flexWrap: "wrap",
        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(3.8),
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: theme.spacing(1.8),
        },
        [theme.breakpoints.down("sm")]: {
          flexWrap: "wrap",
        },
      },
      gridContainer: { maxWidth: "none" },
      dialogActions: {
        padding: "0px 32px 32px",
      },
      dialogActionsWithTotalPrice: {
        justifyContent: "space-between",
      },
      dialogPaperSM: {
        maxWidth: 648,
      },
      dialogPrimaryButtonAction: {
        width: "fit-content",
      },
      actionFlexItem: {
        display: "flex",
        flexDirection: "row",
        gap: 16,
        height: 38,
      },
      actionFlexItemTotalPrice: {
        gap: 8,
      },
      weight700: { fontWeight: 700 },
      radioWrapper: {
        textAlign: "left",
        margin: 8,
      },
      padding16: {
        padding: 16,
      },
      textAlignLeft: {
        textAlign: "left",
      },
      nextButtonRightAlignWrapper: {
        display: "flex",
        flexDirection: "row-reverse",
        width: "100%",
      },
      marginRight16: {
        marginRight: 16,
      },
      disabledRadioText: {
        color: "#9BA2AE",
      },
      disabledRadioHintWrap: {
        display: "flex",
        alignItems: "center",
        marginTop: 12,
      },
      disabledRadioInfoIcon: {
        width: 16,
        height: 16,
        color: "#000",
        marginRight: 8,
      },
      disabledRadioInfoMainMenuLink: {
        marginRight: 4,
      },
      disabledRadioInfoMainMenuText: {
        display: "inline-block",
        color: "#000",
        textDecoration: "underline",
      },
      inlineBlock: {
        display: "inline-block",
      },
    })
);
