import { VenueClaimRequestStatus } from "../VenueClaimRequests/components/VenueClaimRequestsTable/api";

export const GET_CLAIM_REQUEST = (requestId: number) =>
  `venue/venue-claim-pending/${requestId}`;

export interface VenueClaimRequestData {
  comment?: string;
  company: string;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  phoneNumber: string;
  title: string;
  venueName: string;
  venueId: number;
  venueAddress: string;
  createdAt: Date;
  isWaitlistAdmin?: boolean;
  documentPath?: DocumentPath;
  status: VenueClaimRequestStatus;
  statusUpdatedAt: Date;
}

export interface DocumentPath {
  active: boolean;
  id: number;
  isMainPhoto: boolean;
  tag: string;
  url: string;
}
