import React, { FC, useContext, useState } from "react";

import { AlertContext } from "../../../../../common/components/Alert/AlertContextProvider";
import { useCurrentVenueId } from "../../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../../common/hooks/useRestApi";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { RoleTypeEnum } from "../../../main/RoleTypes";
import { UPDATE_MANAGER } from "../../api";
import { IUpdateManagerRequest } from "../../models/ManagerRequest";
import ManagerDialog from "../ManagerDialog";
import { CreateManagerForm } from "../ManagerDialog/form";
import Props from "./Props";

const EditManagerDialog: FC<Props> = (props: Props) => {
  const { isOpen, toggleOpen, onSuccess, manager } = props;

  const [role, setRole] = useState<any>([]);
  const { showAlert } = useContext(AlertContext);

  const venueId = useCurrentVenueId();

  const handleClose = () => {
    toggleOpen(undefined);
  };

  const [_, handleEdit] = useRestApi<any, IUpdateManagerRequest>(
    UPDATE_MANAGER(manager.id),
    "PUT"
  );

  const handleEditManager = async (form: CreateManagerForm) => {
    try {
      if (form) {
        handleEdit({
          ...form.values,
          isWaitlistAdmin: role.includes(RoleTypeEnum.waitlist),
          isDiscountAdmin: role.includes(RoleTypeEnum.discount),
          venueId,
        } as IUpdateManagerRequest)
          .then(() => {
            showAlert(dictionary.venueStaff.messages.updatedSuccess, "success");
          })
          .then(() => {
            handleClose();
            toggleOpen(undefined);
            onSuccess && onSuccess();
          })
          .catch((error) => {
            if (!error) {
              return;
            }

            if (error.response.data === "Insult word") {
              showAlert(
                dictionary.notifications.insultingMessageError,
                "error"
              );
            } else
              showAlert(dictionary.venueStaff.messages.updatedError, "error");
          });
      }
    } catch (e) {
      if (!e) {
        return;
      }
    }
  };

  return (
    <>
      {isOpen && (
        <ManagerDialog
          handleClose={handleClose}
          handleSave={handleEditManager}
          role={role}
          setRole={setRole}
          manager={manager}
        />
      )}
    </>
  );
};

export default EditManagerDialog;
