import { Divider, Grid } from "@material-ui/core";
import React, { FC, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { AlertContext } from "../../common/components/Alert/AlertContextProvider";
import PageTitle from "../../common/components/PageTitle";
import { useIsManager } from "../../common/hooks/useIsManager";
import { useIsSuperAdmin } from "../../common/hooks/useIsSuperAdmin";
import { isPackagesDisabled } from "../../config";
import dictionary from "../../i18n/en_US/dictionary";
import { AppState } from "../../store/rootReducer";
import { setUser } from "../../store/user/actions";
import Props from "../VenueAdminProfile/Props";
import ChangePassword from "./components/ChangePassword";
import DeleteProfile from "./components/DeleteProfile";
import ManagePayments from "./components/ManagePayments";
import MFA from "./components/MFA";
import Notifications from "./components/Notifications";
import Photo from "./components/Photo";
import ProfileForm from "./components/ProfileForm";
import { getProfile, saveProfile } from "./ducks/actions";
import IVenueAdminProfile from "./models/IVenueAdminProfile";
import { useStyles } from "./styles";

const Profile: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showAlert } = useContext(AlertContext);
  const { profile, saved } = useSelector((state: AppState) => state.profile);
  const superAdmin = useIsSuperAdmin();
  const manager = useIsManager();

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (saved) {
      showAlert(dictionary.profile.profileSaved, "success");
    }
  }, [saved, showAlert]);

  const handleSave = (profile: IVenueAdminProfile) => {
    dispatch(
      saveProfile(
        profile,
        (msg?: string) => {
          showAlert(msg || dictionary.profile.profileSavingError, "error");
        },
        () => {
          dispatch(
            setUser({
              firstName: profile.firstName,
              lastName: profile.lastName,
            })
          );
        }
      )
    );
  };

  return (
    <div>
      <PageTitle wrapperClassName={classes.titleWrapper}>
        {dictionary.profile.pageTitle}
      </PageTitle>
      <div className={classes.profileWrapper}>
        <Grid container spacing={3} style={{ maxWidth: "none" }}>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <Photo
              photoUrl={profile?.avatarLink ? profile.avatarLink : undefined}
            />
            {profile && <ProfileForm profile={profile} onSave={handleSave} />}
            <Divider />
            <ChangePassword />
            <Divider />
            <Notifications />
            <Divider />
            <MFA />
            <Divider />
            {!isPackagesDisabled && !superAdmin && !manager && (
              <ManagePayments />
            )}
            <Divider />
            <DeleteProfile />
            <Divider />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default withRouter(Profile);
