import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { AlertContext } from "../../../common/components/Alert/AlertContextProvider";
import ButtonPrimary from "../../../common/components/ButtonPrimary";
import { useCurrentVenueId } from "../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../common/hooks/useRestApi";
import dictionary from "../../../i18n/en_US/dictionary";
import { RoleTypeEnum } from "../main/RoleTypes";
import { GET_MANAGERS } from "./api";
import ManagerSearch from "./components/ManagerSearch";
import VenueStaffManagersList from "./components/ManagersList";
import IManager from "./models/IManager";
import Props from "./Props";
import { useStyles } from "./styles";

const VenueStaffManagers: FC<Props> = () => {
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const [managers, setManagers] = useState<IManager[]>([]);
  const [isOpenCreateUserModal, setIsOpenUserCreateModal] = useState(false);
  const [currentManager, setCurrentManager] = useState<IManager | undefined>(
    undefined
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchedManagers, setSearchedManagers] = useState<
    IManager[] | undefined
  >();
  const [tab, setTab] = useState(0);
  const [filter, setFilter] = useState("");

  const venueId = useCurrentVenueId();

  const [, getManagers] = useRestApi<IManager[], { type: string }>(
    GET_MANAGERS(venueId),
    "POST"
  );

  function tabsProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
    setSearchValue("");
    setSearchedManagers(undefined);
    if (newValue === 0) {
      setFilter("");
    } else if (newValue === 1) {
      setFilter(RoleTypeEnum.waitlist);
    } else if (newValue === 2) {
      setFilter(RoleTypeEnum.discount);
    }
  };

  const handleSearch = (searchString: string) => {
    const search = searchString.toLowerCase();
    if (search !== "") {
      setSearchedManagers(
        managers.filter((manager) => {
          if (
            manager.firstName.startsWith(search) ||
            manager.email.startsWith(search)
          ) {
            return manager;
          } else {
            return false;
          }
        })
      );
    } else {
      handleChangeTab({}, tab);
    }
  };

  const toggleUserCreateModal = (status: boolean) => {
    if (status) {
      setCurrentManager(undefined);
      setIsOpenUserCreateModal(status);
    } else {
      setCurrentManager(undefined);
      setIsOpenUserCreateModal(false);
    }
  };

  const loadManagers = useCallback(() => {
    getManagers({ type: filter })
      .then((managers: IManager[] | undefined) => {
        if (managers) {
          setManagers(managers);
        }
      })
      .catch(() => {
        showAlert(dictionary.venueStaff.messages.gettingError, "error");
      });
  }, [getManagers, showAlert, filter]);

  // @ts-ignore
  useEffect(() => {
    loadManagers();
  }, [loadManagers]);

  return (
    <Paper className={classes.wrapper}>
      <Grid container direction="row">
        <ManagerSearch
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          handleSearch={(search) => handleSearch(search)}
        />
        <ButtonPrimary
          className={classes.btn}
          variant="contained"
          color="primary"
          onClick={() => toggleUserCreateModal(true)}>
          {dictionary.venueStaff.managers.addManager}
        </ButtonPrimary>
      </Grid>

      <div className={classes.tabsWrapper}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}>
          <Tab
            label={dictionary.venueStaff.tabs.all}
            classes={{
              root: classes.tab,
            }}
            {...tabsProps(0)}
          />
          <Tab
            label={dictionary.venueStaff.tabs.waitlistManagers}
            classes={{
              root: classes.tab,
            }}
            {...tabsProps(1)}
          />
          <Tab
            label={dictionary.venueStaff.tabs.discountManagers}
            classes={{
              root: classes.tab,
            }}
            {...tabsProps(2)}
          />
        </Tabs>
      </div>

      <div className={classes.wrapper}>
        <VenueStaffManagersList
          managers={searchedManagers || managers}
          onSuccess={() => loadManagers()}
          isOpenCreateUserModal={isOpenCreateUserModal}
          toggleUserCreateModal={(status) => toggleUserCreateModal(status)}
          currentManager={currentManager}
          setCurrentManager={setCurrentManager}
        />
      </div>
    </Paper>
  );
};
export default withRouter(VenueStaffManagers);
