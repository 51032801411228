import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "line"
  | "content"
  | "emptyChartContent"
  | "noDataEmptyChartText"
  | "noDataEmptyChartTextFullWidth"
  | "noDataEmptyChartTitle"
  | "withYAxis"
  | "pie"
  | "bar"
  | "withLegendBottom"
  | "basic"
  | "lockedChart";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        position: "relative",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(5, 3),
          height: 350,
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(3, 2),
          height: 320,
        },
      },
      content: {
        display: "flex",
        flex: 1,
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
      },
      emptyChartContent: {
        alignItems: "center",
      },
      noDataEmptyChartText: {
        marginTop: 10,
        color: colors.grey.secondary,
        width: "40%",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        [theme.breakpoints.down("md")]: {
          width: "50%",
        },
      },
      noDataEmptyChartTitle: {
        marginTop: 10,
        textAlign: "center",
      },
      noDataEmptyChartTextFullWidth: {
        width: "100%",
      },
      basic: {
        [theme.breakpoints.up("md")]: {
          maxHeight: 200,
        },
        [theme.breakpoints.down("md")]: {
          maxHeight: 180,
        },
      },
      withYAxis: {
        marginLeft: -13,
      },
      withLegendBottom: {
        [theme.breakpoints.up("md")]: {
          maxHeight: 238,
        },
        [theme.breakpoints.down("md")]: {
          maxHeight: 218,
        },
      },
      pie: {},
      bar: {},
      line: {},
      lockedChart: {
        "&:hover": {
          display: "none",
        },
      },
    })
);
