import { TextField, Typography } from "@material-ui/core";
import React, { FC } from "react";

import OcietyQRCode from "../../../../../../../../common/components/OcietyQRCode";
import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const QRStep: FC<Props> = (props: Props) => {
  const {
    secretKey,
    imgSrc,
    onSetDescription,
    onSetOPTCode,
    errorCode,
    errorDescription,
  } = props;
  const classes = useStyles();

  const onChangeDescription = (description: string) => {
    onSetDescription(description.trim());
  };

  const onChangeCode = (code: string) => {
    onSetOPTCode(code.trim());
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div className={classes.qrCodeWrapper}>
        <OcietyQRCode imgSrc={imgSrc} />
      </div>
      <Typography variant="body1" className={classes.warnText}>
        {dictionary.profile.mfa.scanQRCodeWarning}
      </Typography>
      <Typography variant="body1" className={classes.helpText}>
        {dictionary.profile.mfa.scanQRCodeNote}
      </Typography>
      <Typography variant="body1" className={classes.secretKey}>
        {secretKey}
      </Typography>
      <TextField
        fullWidth
        id="filled-otp-desciption"
        label={dictionary.profile.mfa.descriptionFieldLabel}
        name="description"
        onChange={(ev) => {
          onChangeDescription(ev.target.value);
        }}
        style={{ marginTop: "1rem" }}
        type="text"
        variant="outlined"
        helperText={errorDescription}
        error={!!errorDescription}
      />
      <TextField
        fullWidth
        id="filled-phone-code"
        label={dictionary.profile.mfa.otpFieldLabel}
        name="code"
        onChange={(ev) => {
          onChangeCode(ev.target.value);
        }}
        style={{ marginTop: "1rem" }}
        type="text"
        variant="outlined"
        helperText={errorCode}
        error={!!errorCode}
      />
    </div>
  );
};
export default QRStep;
