import { ChartDataItem } from "./models";

export const chartDataMock: ChartDataItem[] = [
  {
    month: "Sep",
    views: 1100,
    usages: 668,
  },
  {
    month: "Oct",
    views: 1200,
    usages: 68,
  },
  {
    month: "Nov",
    views: 1200,
    usages: 668,
  },
  {
    month: "Dec",
    views: 1200,
    usages: 668,
  },
  {
    month: "Jum",
    views: 1200,
    usages: 668,
  },
  {
    month: "Feb",
    views: 1200,
    usages: 668,
  },
];
