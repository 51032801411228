import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { ColorsGray } from "../../../../theme/colors";

export type ClassNames = "infoText";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    infoText: {
      color: ColorsGray._747982,
    },
  })
);
