import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useSelector } from "react-redux";

import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { ReactComponent as PackLockIcon } from "../../../../common/svg/icons/pack-lock.svg";
import { CURRENCY_SYMBOL, isPackagesDisabled } from "../../../../config";
import dictionary from "../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../store/rootReducer";
import Props from "./Props";
import { useStyles } from "./styles";

const FullAccessPackageCard: FC<Props> = (props: Props) => {
  const {
    package: pack,
    enablePackage,
    disablePackage,
    active,
    toggleCart,
  } = props;
  const venueId = useCurrentVenueId();
  const { cart } = useSelector<AppState, AppState>((state) => state);
  const classes = useStyles();

  if (!pack) {
    return null;
  }

  const onUpdate = () => {
    if (active) {
      disablePackage(pack.id);
    } else {
      enablePackage(pack.id);
    }
  };

  const { price, name, description, id } = pack;

  if (isPackagesDisabled) {
    return null;
  }

  return (
    <div className={classes.root}>
      {pack.discountPercent > 0 && (
        <div className={classes.discountContainer}>
          <Typography className={classes.discount} component={"span"}>
            {dictionary.plan.fullAccessDiscountLabel(pack.discountPercent)}
          </Typography>
        </div>
      )}
      <div className={classes.contentContainer}>
        <div className={classes.mainContent}>
          <div className={classes.nameContainer}>
            {!active && <PackLockIcon className={classes.lockIcon} />}
            <Typography className={classes.packageName}>{name}</Typography>
          </div>
          <div>
            <div className={classes.priceContainer}>
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.currency}>
                {CURRENCY_SYMBOL}
              </Typography>
              <Typography component={"span"} className={classes.price}>
                {price}
              </Typography>
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.period}>
                {dictionary.plan.periodLabel}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.descriptionContainer}>
          <Typography className={classes.description}>{description}</Typography>
        </div>
      </div>
      <div className={classes.actionsToolbar}>
        {cart[venueId]?.includes(id) ? (
          <ButtonPrimary className={classes.unlockButton} onClick={toggleCart}>
            {dictionary.plan.inCart}
          </ButtonPrimary>
        ) : (
          <ButtonPrimary className={classes.unlockButton} onClick={onUpdate}>
            {active
              ? dictionary.plan.lockFullAccess
              : dictionary.plan.unlockFullAccess}
          </ButtonPrimary>
        )}
      </div>
    </div>
  );
};

export default FullAccessPackageCard;
