import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type LinksClassNames = "root" | "link";

export const useStyles: () => ClassNameMap<LinksClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
      },
      link: {
        marginRight: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        color: "#FFF",
      },
    })
);
