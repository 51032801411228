import { DialogActions } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AlertContext } from "../../../../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../../../../common/components/ButtonBlack";
import ButtonCancel from "../../../../../../../common/components/ButtonCancel";
import ConfirmDialog from "../../../../../../../common/components/dialogs/ConfirmDialog";
import { ConfirmModalType } from "../../../../../../../common/components/dialogs/ConfirmDialog/Props";
import { useIsDiscountAdmin } from "../../../../../../../common/hooks/useIsDiscountAdmin";
import { FileUrlWithId } from "../../../../../../../common/models";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { CurrentVenueState } from "../../../../../../../store/currentVenue/reducer";
import { AppState } from "../../../../../../../store/rootReducer";
import {
  createManagerPromotion,
  updateManagerPromotion,
} from "../../../../../../ManagerPromotions/Promotions/api";
import { WorkingDaysForm } from "../../../../../../VenueSettings/components/VenueCommonData/form/WorkingDaysForm";
import { DiscountStatus } from "../../../../../types/DiscountStatus";
import { PromotionLimitType, PromotionType } from "../../../../enums";
import { useStyles } from "../../../../styles";
import { createPromotion, updatePromotion } from "../../api";
import CreatePromotionFormRequest from "../../models/CreatePromotionFormRequest";
import { IDayHours } from "../../models/IDayHours";
import UpdatePromotionFormRequest from "../../models/UpdatePromotionFormRequest";
import { PromotionFormDialogContext } from "../../PromotionFormDialogContextProvider";
import { toMoneyFormat } from "../PromotionFormDialogContent/utils";
import { PromotionKind } from "./PromotionKind.enum";
import Props from "./Props";

const PromotionFormDialogActions: FC<Props> = (props: Props) => {
  const {
    closeDialog,
    refresh,
    dietaryRestrictions,
    ageGroupId,
    setDietaryRestrictions,
    setAgeGroupId,
    propsPromotionId,
  } = props;
  const { showAlert } = useContext(AlertContext);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const { currentVenue } = useSelector<AppState, CurrentVenueState>(
    (appState) => appState.currentVenue
  );
  const workingDaysForm = useSelector<AppState, WorkingDaysForm>(
    (state) => state.forms.workingDays
  );

  const venueId: number = currentVenue ? currentVenue.id : 0;
  const classes = useStyles();
  const discountAdmin = useIsDiscountAdmin();

  const {
    currentStep,
    isNextDisabled,
    setCurrentStep,
    basicForm,
    mainForm,
    advancedForm,
    promotionId,
    daysHours,
    setDaysHours,
    setPromotionId,
    resetForm,
    setNumberOfMediaInEditMode,
  } = useContext(PromotionFormDialogContext);

  useEffect(() => {
    if (workingDaysForm.values.workingDays) {
      const reformattedWorkingDays: IDayHours[] =
        workingDaysForm.values.workingDays.map((day) => {
          return {
            id: day.id,
            day: day.day,
            isActive: day.isOpen,
            start: day.hours[0].from,
            end: day.hours[0].to,
          };
        });

      setDaysHours(reformattedWorkingDays);
    }
  }, [setDaysHours, workingDaysForm.values.workingDays]);

  const getSecondaryButtonLabel = () => {
    if (currentStep === 2 && promotionId) {
      return dictionary.deals.createDealForm.cancelButtonLabel;
    }

    return currentStep === 1
      ? dictionary.deals.createDealForm.cancelButtonLabel
      : dictionary.deals.createDealForm.backButtonLabel;
  };

  const getPrimaryButtonLabel = () => {
    if (currentStep === 4) {
      if (propsPromotionId) {
        return "Save";
      } else return "Create & Publish";
    }

    return "Next";
  };

  const closeAndReset = () => {
    setAgeGroupId([]);
    setDietaryRestrictions([]);
    setNumberOfMediaInEditMode([]);
    closeDialog();
    resetForm();
  };

  const handlePressSecondaryButton = () => {
    if (currentStep === 1 || (currentStep === 2 && promotionId)) {
      return setIsConfirmDialogOpen(true);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  // filesWithIdsJSON - it is JSON with array of FileUrlWithId objects
  const getPromotionFilesIds = (filesWithIdsJSON: string) => {
    return filesWithIdsJSON.length
      ? JSON.parse(filesWithIdsJSON).map(
          (fileUrlWithId: FileUrlWithId) => fileUrlWithId.id
        )
      : [];
  };

  const showRebatedPriceError = () => {
    setCurrentStep(2);
    showAlert(dictionary.deals.createDealForm.msgErrorRebatedPrice, "error");
  };

  const sendUpdateRequest = (status?: DiscountStatus): void => {
    const request = new UpdatePromotionFormRequest();
    const advancedFormValues = advancedForm.form.values;
    request.id = promotionId;
    request.type = mainForm.form.values.type as PromotionType;
    request.description = mainForm.form.values.description;
    request.restrictions = mainForm.form.values.restrictions;
    request.start = mainForm.form.values.start;
    request.end = mainForm.form.values.end;
    request.videosIds = getPromotionFilesIds(
      basicForm.form.values.promotionVideos
    );
    request.photosIds = getPromotionFilesIds(
      basicForm.form.values.promotionPhotos
    );
    request.categoryId = mainForm.form.values.categoryId;
    request.subCategoryId = mainForm.form.values.subCategoryId;
    request.otherSubcategoryName = mainForm.form.values.otherSubcategoryName;
    request.daysHours = daysHours;
    request.limitType = mainForm.form.values.limitType as PromotionLimitType;
    request.limitMaxAmount = Number(mainForm.form.values.limitMaxAmount);
    request.limitPercent = Number(mainForm.form.values.limitPercent);
    request.limitPrice = toMoneyFormat(Number(mainForm.form.values.limitPrice));
    request.rebatedPrice = toMoneyFormat(
      Number(mainForm.form.values.rebatedPrice)
    );
    request.limitItemsName = mainForm.form.values.limitItemsName;
    request.dietaryRestrictions = mainForm.form.values.dietaryRestrictions;
    request.quantity = Number(advancedFormValues.quantity);
    request.notifyHowManyLeft = advancedFormValues.notifyHowManyLeft === "1";
    request.notifyHowManyLeftCount = Number(
      advancedFormValues.notifyHowManyLeftCount
    );
    request.maximumUsagesPerCustomer = Number(
      advancedFormValues.maximumUsagesPerCustomer
    );
    request.maximumUsagesPerDay = Number(
      advancedFormValues.maximumUsagesPerDay
    );
    request.restrictMinimumAge = advancedFormValues.restrictMinimumAge;
    request.promotionCode = advancedFormValues.promotionCode;
    request.isQuantityUnlimited =
      advancedFormValues.isQuantityUnlimited === "1";
    request.isPlanetPromo = mainForm.form.values.isPlanetPromo === "1";
    request.ageGroupId = advancedFormValues.ageGroupId;
    request.mainPhotoId = Number(basicForm.form.values.mainPhotoId);
    request.name =
      (mainForm.form.values.kind as PromotionKind) === PromotionKind.SPECIAL
        ? mainForm.form.values.name
        : "";
    request.kind = mainForm.form.values.kind as PromotionKind;
    if (status) {
      request.status = status;
    }

    const update = discountAdmin
      ? updateManagerPromotion(
          request,
          promotionId,
          venueId,
          dietaryRestrictions,
          ageGroupId
        )
      : updatePromotion(
          request,
          promotionId,
          venueId,
          dietaryRestrictions,
          ageGroupId
        );

    update
      .then(() => {
        showAlert(
          propsPromotionId
            ? dictionary.deals.createDealForm.msgSuccessUpdated
            : dictionary.deals.createDealForm.msgSuccessCreated,
          "success",
          "Success!"
        );

        closeAndReset();
        if (refresh) {
          refresh();
        }
      })
      .catch((error) => {
        const UpdateDealsErrors: { [k: string]: string } = {
          "Insult word": dictionary.notifications.insultingMessageError,
          dealAlreadyPurchased:
            dictionary.deals.createDealForm.msgErrorPurchasedDeal,
        };

        if (error?.data.includes("Rebated price is too big")) {
          return showRebatedPriceError();
        }

        if (error.data) {
          showAlert(UpdateDealsErrors[error.data], "error");
        } else
          showAlert(dictionary.deals.createDealForm.msgErrorUpdated, "error");
      });
  };

  const sendRequest = () => {
    const request = new CreatePromotionFormRequest();
    const advancedFormValues = advancedForm.form.values;
    request.description = mainForm.form.values.description;
    request.start = mainForm.form.values.start;
    request.end = mainForm.form.values.end;
    request.type = mainForm.form.values.type as PromotionType;
    request.photosIds = getPromotionFilesIds(
      basicForm.form.values.promotionPhotos
    );
    request.videosIds = getPromotionFilesIds(
      basicForm.form.values.promotionVideos
    );
    request.promotionCategoryId = mainForm.form.values.categoryId;
    request.promotionSubCategoryId = mainForm.form.values.subCategoryId;
    request.otherSubcategoryName = mainForm.form.values.otherSubcategoryName;
    request.daysHours = daysHours;
    request.limitType = mainForm.form.values.limitType as PromotionLimitType;
    request.limitMaxAmount = Number(mainForm.form.values.limitMaxAmount);
    request.limitPercent = Number(mainForm.form.values.limitPercent);
    request.limitPrice = toMoneyFormat(Number(mainForm.form.values.limitPrice));
    request.rebatedPrice = toMoneyFormat(
      Number(mainForm.form.values.rebatedPrice)
    );
    request.limitItemsName = mainForm.form.values.limitItemsName;
    request.dietaryRestrictions = mainForm.form.values.dietaryRestrictions;
    request.quantity = Number(advancedFormValues.quantity);
    request.notifyHowManyLeft = advancedFormValues.notifyHowManyLeft === "1";
    request.notifyHowManyLeftCount = Number(
      advancedFormValues.notifyHowManyLeftCount
    );
    request.maximumUsagesPerCustomer = Number(
      advancedFormValues.maximumUsagesPerCustomer
    );
    request.maximumUsagesPerDay = Number(
      advancedFormValues.maximumUsagesPerDay
    );
    request.ageGroupId = advancedFormValues.ageGroupId;
    request.promotionCode = advancedFormValues.promotionCode;
    request.isQuantityUnlimited =
      advancedFormValues.isQuantityUnlimited === "1";
    request.isPlanetPromo = mainForm.form.values.isPlanetPromo === "1";
    request.restrictMinimumAge = advancedFormValues.restrictMinimumAge;
    request.mainPhotoId = Number(basicForm.form.values.mainPhotoId);
    request.kind = mainForm.form.values.kind as PromotionKind;
    request.restrictions = mainForm.form.values.restrictions;
    request.name =
      (mainForm.form.values.kind as PromotionKind) === PromotionKind.SPECIAL
        ? mainForm.form.values.name
        : "";

    const create = discountAdmin
      ? createManagerPromotion(
          request,
          venueId,
          dietaryRestrictions,
          ageGroupId
        )
      : createPromotion(request, venueId, dietaryRestrictions, ageGroupId);
    create
      .then((response) => {
        setPromotionId(response.data);
      })
      .catch((error) => {
        if (error?.data.includes("Rebated price is too big")) {
          return showRebatedPriceError();
        }
        if (error?.response?.data === "Insult word") {
          showAlert(dictionary.notifications.insultingMessageError, "error");
        } else
          showAlert(dictionary.deals.createDealForm.msgErrorCreation, "error");
      });
  };

  const handlePressPrimaryButton = () => {
    if (currentStep === 3 && advancedForm.validateForm()) {
      setCurrentStep(currentStep + 1);
      if (promotionId) {
        setCurrentStep(currentStep + 1);
      } else {
        sendRequest();
      }
    }

    if (currentStep === 4 && basicForm.validateForm()) {
      if (propsPromotionId) {
        sendUpdateRequest();
      } else {
        sendUpdateRequest(DiscountStatus.ENABLED);
      }
    }

    if (currentStep === 2 && mainForm.validateForm()) {
      setCurrentStep(currentStep + 1);
    }

    if (currentStep === 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <DialogActions
      classes={{
        root: clsx(classes.dialogActions),
      }}>
      <div className={classes.actionFlexItem}>
        <ButtonCancel
          className={classes.dialogPrimaryButtonAction}
          onClick={handlePressSecondaryButton}>
          {getSecondaryButtonLabel()}
        </ButtonCancel>
        <ButtonBlack
          onClick={handlePressPrimaryButton}
          disabled={isNextDisabled}
          className={classes.dialogPrimaryButtonAction}>
          {getPrimaryButtonLabel()}
        </ButtonBlack>
      </div>
      <ConfirmDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={closeAndReset}
        subtitle={
          propsPromotionId
            ? dictionary.deals.createDealForm.popupWarningOnCloseEditModeMessage
            : dictionary.deals.createDealForm.popupWarningOnClose
        }
        title={
          propsPromotionId
            ? dictionary.deals.createDealForm.popupWarningOnCloseEditModeTitle
            : undefined
        }
        type={ConfirmModalType.YES_DELETE}
        message={" \n  "}
        yesTypeAdditionalText={propsPromotionId ? "Leave" : "Delete"}
      />
    </DialogActions>
  );
};

export default PromotionFormDialogActions;
