import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { ColorsGray } from "../../../../../../theme/colors";

export type ClassNames =
  | "title"
  | "description"
  | "icon"
  | "arrowTopLeftIcon"
  | "arrowBottomRightIcon"
  | "difference";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      title: {
        color: ColorsGray._37393F,
        fontSize: theme.typography.pxToRem(14),
        wordBreak: "break-word",
        fontWeight: 400,
        lineHeight: 24,
      },
      description: {
        marginTop: 8,
        display: "flex",
        alignItems: "center",
      },
      icon: {
        marginRight: theme.spacing(3),
        height: 28,
        display: "flex",
        justifyContent: "space-between",
        padding: "2px 10px",
        alignItems: "center",
        borderRadius: 4,
      },
      arrowTopLeftIcon: { width: 12, height: 12 },
      arrowBottomRightIcon: { width: 12, height: 12 },
      difference: { fontWeight: 600, fontSize: 16 },
    })
);
