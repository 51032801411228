import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames = "root" | "itemsWrapper" | "title";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      itemsWrapper: {
        marginTop: theme.spacing(1),
      },
      title: {
        fontSize: theme.spacing(2),
        lineHeight: theme.typography.pxToRem(40),
        fontWeight: 700,
      },
    })
);
