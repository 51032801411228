import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";

export type ClassNames =
  | "pdfFileIcon"
  | "menuFileMetadata"
  | "textGap"
  | "uploadedMenuWrapper"
  | "emptyMenuWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      pdfFileIcon: {
        marginRight: theme.spacing(3),
      },
      menuFileMetadata: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      emptyMenuWrapper: {
        marginTop: 40,
        marginBottom: 40,
      },
      textGap: {
        marginBottom: 6,
      },
      uploadedMenuWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 40,
        marginBottom: 40,
      },
    })
);
