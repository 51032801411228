import { Grid, RadioGroup, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { startCase, toLower } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect } from "react";

import OcietyDatePicker from "../../../../../../../../../../common/components/forms/OcietyDatePicker";
import OcietyBlockWrapperForRadioButton from "../../../../../../../../../../common/components/OcietyBlockWrapperForRadioButton";
import { useRestApi } from "../../../../../../../../../../common/hooks/useRestApi";
import dictionary from "../../../../../../../../../../i18n/en_US/dictionary";
import IPromotionCategory from "../../../../../../../../types/interfaces/IPromotionCategory.interface";
import { PromotionType } from "../../../../../../../enums";
import { otherSubcategoryBasesRules } from "../../../../../forms/main";
import { PromotionFormDialogContext } from "../../../../../PromotionFormDialogContextProvider";
import { PromotionKind } from "../../../../PromotionFormDialogActions/PromotionKind.enum";
import { DB_ID_ENTIRE_MENU_VOUCHER_APPLIES_TO_CATEGORY } from "../constants";
import { useFormHandlers } from "../hooks/useFormHandlers";
import { useStyles } from "../styles";
import { useDefaultCategoryForVoucher } from "./useDefaultCategoryForVoucher";

enum PromotionLayout {
  specialDealNameInputField,
  descriptionRestrictionsSection,
  appliesToWithCategoriesSection,
  startEndDatePickersSection,
  voucherAppliesToWithCategorySection,
}

export const layouts = {
  deal: [
    PromotionLayout.appliesToWithCategoriesSection,
    PromotionLayout.descriptionRestrictionsSection,
    PromotionLayout.startEndDatePickersSection,
  ],
  special: [
    PromotionLayout.appliesToWithCategoriesSection,
    PromotionLayout.specialDealNameInputField,
    PromotionLayout.descriptionRestrictionsSection,
    PromotionLayout.startEndDatePickersSection,
  ],
  voucher: [
    PromotionLayout.voucherAppliesToWithCategorySection,
    PromotionLayout.descriptionRestrictionsSection,
    PromotionLayout.startEndDatePickersSection,
  ],
};

interface Props {
  promotionCategories: IPromotionCategory[];
  promotionSubCategories: IPromotionCategory[];
}

export const useRenderDealsPanels = (props: Props) => {
  const { promotionCategories, promotionSubCategories } = props;
  const classes = useStyles();
  const { mainForm } = useContext(PromotionFormDialogContext);
  const { form, setAndValidate, setSchemaRules } = mainForm;
  const currentPromotionType = form.values.type as PromotionType;
  const endValue = form.values.end;

  useDefaultCategoryForVoucher();

  const [
    { data: generalVoucherPromoCategories },
    getGeneralPromotionCategoryData,
  ] = useRestApi<{ categories: IPromotionCategory[] }>(
    "promotions/sub-categories/2",
    "GET"
  );

  useEffect(() => {
    getGeneralPromotionCategoryData();
  }, [getGeneralPromotionCategoryData]);

  const getCategoryValue = () => {
    if (form.values.categoryId) {
      return form.values.categoryId;
    }
  };

  const currentCategoryId = getCategoryValue();

  const { handleChangeFormNameField } = useFormHandlers();

  const handleSetAndValidateCategories = useCallback(
    (value = ""): void => {
      setAndValidate("categoryId", value);
      setAndValidate("subCategoryId", "");
    },
    [setAndValidate]
  );

  const handleChangeCategory = useCallback(
    (_: React.ChangeEvent<any>, option: IPromotionCategory | null) => {
      if (option) {
        handleSetAndValidateCategories(String(option.id));
      }
    },
    [handleSetAndValidateCategories]
  );

  const onCategoryDelete = useCallback(
    (_: React.ChangeEvent<any>, value: string, event: string) => {
      if (event === "clear" && value === "") {
        handleSetAndValidateCategories();
      }
    },
    [handleSetAndValidateCategories]
  );

  const handleSetAndValidateSubCategory = useCallback(
    (value = ""): void => {
      setAndValidate("subCategoryId", value);
      setAndValidate("otherSubcategoryName", "");
      setSchemaRules({
        otherSubcategoryName: {
          ...otherSubcategoryBasesRules,
          required: false,
        },
      });
    },
    [setAndValidate, setSchemaRules]
  );

  const handleChangeSubCategory = useCallback(
    (_: React.ChangeEvent<any>, option: IPromotionCategory | null) => {
      if (option) {
        handleSetAndValidateSubCategory(String(option.id));
      }
    },
    [handleSetAndValidateSubCategory]
  );

  const onSubCategoryDelete = useCallback(
    (_: React.ChangeEvent<any>, value: string, event: string) => {
      if (event === "clear" && value === "") {
        handleSetAndValidateSubCategory();
      }
    },
    [handleSetAndValidateSubCategory]
  );

  const handleStartDate = useCallback(
    (date: MaterialUiPickersDate) => {
      setAndValidate("start", moment(date).format("YYYY-MM-DD"));
    },
    [setAndValidate]
  );

  const handleEndDate = useCallback(
    (date: MaterialUiPickersDate) => {
      setAndValidate("end", moment(date).format("YYYY-MM-DD"));
    },
    [setAndValidate]
  );

  const getCategoryLabel = useCallback(() => {
    if (currentPromotionType === PromotionType.DRINK) {
      return dictionary.deals.drinkCategoriesLabel;
    }

    if (currentPromotionType === PromotionType.FOOD) {
      return dictionary.deals.foodCategoriesLabel;
    }

    return dictionary.deals.categoriesLabel;
  }, [currentPromotionType]);

  const handleChangeTypeRadio = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAndValidate("type", (event.target as HTMLInputElement).value || "");
      mainForm.setFormValue("categoryId", "");
      mainForm.setFormValue("subCategoryId", "");
    },
    [setAndValidate, mainForm]
  );

  const categoryAutoCompleteValue =
    promotionCategories.find(
      (category) => category.id.toString() === form.values.categoryId
    ) || null;

  const subCategoryAutoCompleteValue =
    promotionSubCategories.find(
      (category) => category.id.toString() === form.values.subCategoryId
    ) || null;

  useEffect(() => {
    if (mainForm.form.values.kind !== PromotionKind.VOUCHER) {
      return;
    }

    const voucherCategoryName =
      generalVoucherPromoCategories?.categories.find(
        (category) => String(category.id) === mainForm.form.values.categoryId
      )?.name || "";

    if (
      mainForm.form.values.categoryId ===
      DB_ID_ENTIRE_MENU_VOUCHER_APPLIES_TO_CATEGORY
    ) {
      setAndValidate("type", PromotionType.ORDER);
    } else if (voucherCategoryName?.toLowerCase() === "drinks") {
      setAndValidate("type", PromotionType.DRINK);
    } else {
      setAndValidate("type", PromotionType.FOOD);
    }
  }, [
    setAndValidate,
    mainForm.form.values.kind,
    mainForm.form.values.categoryId,
    generalVoucherPromoCategories,
  ]);

  const isOtherSubCategory = useCallback(() => {
    const otherId = promotionSubCategories.find((sub) => sub.name === "Other");

    return String(otherId?.id) === form.values.subCategoryId;
  }, [form.values.subCategoryId, promotionSubCategories]);

  useEffect(() => {
    if (isOtherSubCategory()) {
      setSchemaRules({
        otherSubcategoryName: {
          ...otherSubcategoryBasesRules,
          required: true,
        },
      });
    }
  }, [isOtherSubCategory, setSchemaRules]);

  const renderPanels = useCallback(
    (layout: PromotionLayout) => {
      switch (layout) {
        case PromotionLayout.voucherAppliesToWithCategorySection:
          return (
            <>
              <Grid item xs={12}>
                <Typography className={classes.inputLabelWithTooltip}>
                  {dictionary.deals.createDealForm.appliesTo}
                </Typography>
                <Autocomplete
                  id="promotion-category"
                  onChange={handleChangeCategory}
                  value={generalVoucherPromoCategories?.categories.find(
                    (category) =>
                      category.id.toString() === form.values.categoryId
                  )}
                  defaultValue={
                    generalVoucherPromoCategories?.categories.find(
                      (category) =>
                        category.id.toString() ===
                        DB_ID_ENTIRE_MENU_VOUCHER_APPLIES_TO_CATEGORY
                    ) || null
                  }
                  onInputChange={onCategoryDelete}
                  getOptionLabel={(option) => startCase(toLower(option.name))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!form.errors.categoryId}
                      helperText={form.errors.categoryId}
                      value={form.values.categoryId}
                      name="categoryId"
                      type="text"
                      variant="outlined"
                    />
                  )}
                  options={generalVoucherPromoCategories?.categories || []}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.inputLabelWithTooltip}>
                  {dictionary.deals.createDealForm.category}
                </Typography>
                <Autocomplete
                  id="promotion-sub-category"
                  fullWidth
                  onChange={handleChangeSubCategory}
                  value={
                    promotionCategories.find(
                      (category) =>
                        category.id.toString() === form.values.subCategoryId
                    ) || null
                  }
                  onInputChange={onSubCategoryDelete}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!form.errors.subCategoryId}
                      helperText={form.errors.subCategoryId}
                      value={form.values.subCategoryId}
                      name="subCategoryId"
                      type="text"
                      variant="outlined"
                    />
                  )}
                  options={promotionCategories}
                />
              </Grid>
            </>
          );
        case PromotionLayout.specialDealNameInputField:
          return (
            <Grid item xs={12}>
              <Typography className={classes.inputLabelWithTooltip}>
                {dictionary.deals.createDealForm.specialTitle}
              </Typography>
              <TextField
                fullWidth
                InputLabelProps={{ style: { pointerEvents: "auto" } }}
                name="name"
                error={!!form.errors.name}
                helperText={form.errors.name}
                onChange={handleChangeFormNameField}
                type="text"
                value={form.values.name}
                variant="outlined"
              />
            </Grid>
          );
        case PromotionLayout.startEndDatePickersSection:
          return (
            <>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <OcietyDatePicker
                  label={dictionary.deals.createDealForm.startDate}
                  minDate={new Date()}
                  value={form.values.start}
                  onChange={handleStartDate}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <OcietyDatePicker
                  label={dictionary.deals.createDealForm.expDate}
                  minDate={moment(form.values.start).add(1, "days").toDate()}
                  value={endValue}
                  onChange={handleEndDate}
                  error={!!form.errors.end}
                  helperText={form.errors.end}
                />
              </Grid>
            </>
          );
        case PromotionLayout.descriptionRestrictionsSection:
          return (
            <>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.inputWrapperWithTooltip}>
                  <Typography className={classes.inputLabelWithTooltip}>
                    {dictionary.deals.createDealForm.descriptionTitle}
                  </Typography>
                </div>
                <TextField
                  fullWidth
                  InputLabelProps={{ style: { pointerEvents: "auto" } }}
                  name="description"
                  error={!!form.errors.description}
                  helperText={form.errors.description}
                  onChange={handleChangeFormNameField}
                  type="text"
                  value={form.values.description}
                  variant="outlined"
                  multiline={true}
                  rows={3}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.inputLabelWithTooltip}>
                  {dictionary.deals.createDealForm.restrictionsTitle}
                </Typography>
                <TextField
                  fullWidth
                  InputLabelProps={{ style: { pointerEvents: "auto" } }}
                  name="restrictions"
                  error={!!form.errors.restrictions}
                  helperText={form.errors.restrictions}
                  onChange={handleChangeFormNameField}
                  type="text"
                  value={form.values.restrictions}
                  variant="outlined"
                  multiline={true}
                  rows={3}
                />
              </Grid>
            </>
          );
        case PromotionLayout.appliesToWithCategoriesSection:
          return (
            <>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h3">
                  {dictionary.deals.createDealForm.dealTypeLabel}
                </Typography>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  className={classes.marginTop10}
                  value={form.values.type}
                  onChange={handleChangeTypeRadio}
                  defaultValue={PromotionType.FOOD}>
                  <Grid container spacing={1}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <OcietyBlockWrapperForRadioButton
                        isActive={form.values.type === PromotionType.FOOD}
                        label={
                          dictionary.deals.createDealForm.foodDealTypeLabel
                        }
                        value={PromotionType.FOOD}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <OcietyBlockWrapperForRadioButton
                        isActive={form.values.type === PromotionType.DRINK}
                        label={
                          dictionary.deals.createDealForm.drinkDealTypeLabel
                        }
                        value={PromotionType.DRINK}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Grid>
              {currentPromotionType !== PromotionType.ORDER && (
                <>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Autocomplete
                      id="promotion-category"
                      fullWidth
                      onChange={handleChangeCategory}
                      value={categoryAutoCompleteValue}
                      onInputChange={onCategoryDelete}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!form.errors.categoryId}
                          helperText={form.errors.categoryId}
                          label={getCategoryLabel()}
                          value={form.values.categoryId}
                          name="categoryId"
                          type="text"
                          variant="outlined"
                        />
                      )}
                      options={promotionCategories}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Autocomplete
                      id="promotion-sub-category"
                      fullWidth
                      value={subCategoryAutoCompleteValue}
                      onChange={handleChangeSubCategory}
                      onInputChange={onSubCategoryDelete}
                      disabled={!currentCategoryId}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!form.errors.subCategoryId}
                          helperText={form.errors.subCategoryId}
                          label={dictionary.deals.subCategoriesLabel}
                          value={form.values.subCategoryId}
                          name="subCategoryId"
                          type="text"
                          variant="outlined"
                        />
                      )}
                      options={promotionSubCategories}
                    />
                  </Grid>
                </>
              )}
              {isOtherSubCategory() && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label={
                      dictionary.deals.createDealForm
                        .provideSubcategoryNameIfOther
                    }
                    name="otherSubcategoryName"
                    error={!!form.errors.otherSubcategoryName}
                    helperText={form.errors.otherSubcategoryName}
                    onChange={handleChangeFormNameField}
                    type="text"
                    value={form.values.otherSubcategoryName}
                    variant="outlined"
                    rows={1}
                  />
                </Grid>
              )}
            </>
          );
        default:
          return;
      }
    },
    [
      categoryAutoCompleteValue,
      classes.inputLabelWithTooltip,
      classes.inputWrapperWithTooltip,
      classes.marginTop10,
      currentCategoryId,
      currentPromotionType,
      endValue,
      form.errors.categoryId,
      form.errors.description,
      form.errors.name,
      form.errors.otherSubcategoryName,
      form.errors.restrictions,
      form.errors.subCategoryId,
      form.errors.end,
      form.values.categoryId,
      form.values.description,
      form.values.name,
      form.values.otherSubcategoryName,
      form.values.restrictions,
      form.values.start,
      form.values.subCategoryId,
      form.values.type,
      generalVoucherPromoCategories?.categories,
      getCategoryLabel,
      handleChangeCategory,
      handleChangeFormNameField,
      handleChangeSubCategory,
      handleChangeTypeRadio,
      handleEndDate,
      handleStartDate,
      isOtherSubCategory,
      onCategoryDelete,
      onSubCategoryDelete,
      promotionCategories,
      promotionSubCategories,
      subCategoryAutoCompleteValue,
    ]
  );

  return { renderPanels };
};
