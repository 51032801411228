import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../../../common/models";

export type ClassNames = "root" | "dialogContent";
export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      dialogContent: {
        padding: theme.spacing(2),
        minHeight: 0,
        [theme.breakpoints.up("lg")]: {
          minWidth: 900,
        },
        [theme.breakpoints.up("md")]: {
          minWidth: 648,
        },
        [theme.breakpoints.down("sm")]: {
          minWidth: 500,
        },
        [theme.breakpoints.down("xs")]: {
          minWidth: "auto",
          width: "100%",
          height: "100%",
        },
      },
    })
);
