import { Box, Grid, Paper } from "@material-ui/core";
import React, { useContext } from "react";
import { DateRange } from "react-day-picker";

import DateRangePicker from "../../../../common/components/DateRange";
import { TransactionsContext } from "../../providers";
import MoneyFullTable from "./components/FullTable";
import TableColumnManager from "./components/TableColumnManager";
import { useStyles } from "./styles";
import { useMoneyForm } from "./useMoneyForm";

const TransactionTable = () => {
  const classes = useStyles();
  const {
    rowsPerPage,
    page,
    transactionsCount,
    setPage,
    handleRowsPerPageChange,
  } = useMoneyForm();
  const { setSearchFilters } = useContext(TransactionsContext);
  const handleChangeDateFilter = (range: DateRange) => {
    setSearchFilters((prevFilters) => {
      return {
        ...prevFilters,
        createdAt: range,
      };
    });
  };

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.wrapper}>
          <Grid container spacing={10} alignItems="center">
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box
                display="flex"
                justifyContent="flex-start"
                style={{ gap: 12 }}>
                <TableColumnManager />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box display="flex" justifyContent="flex-end" style={{ gap: 12 }}>
                <DateRangePicker setDateRange={handleChangeDateFilter} />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={10} style={{ position: "relative" }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <MoneyFullTable
                count={transactionsCount}
                rowsPerPage={rowsPerPage}
                page={page}
                handlePageChange={setPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default TransactionTable;
