import { Box, Grid, Paper } from "@material-ui/core";
import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";

import ButtonBlack from "../../../../common/components/ButtonBlack";
import ListEmpty from "../../../../common/components/ListEmpty";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import ImageDiscountStickerRed from "../../../../common/images/components/DialogCreateHappyHours/discount-sticker-red.png";
import dictionary from "../../../../i18n/en_US/dictionary";
import SearchPromotion from "../../../Promotions/components/SearchPromotion";
import VenuesSearchEmpty from "../../../Venues/components/VenuesSearchEmpty";
import { PromoCodesContext } from "../../providers";
import DialogCreateNewCoupon from "../DialogAddNewCoupon";
import AdaptiveList from "./components/AdaptiveList";
import FullTable from "./components/FullTable";
import { useStyles } from "./styles";
import { useCouponTable } from "./useCouponTable";

const CouponsTable = () => {
  const classes = useStyles();
  const isTablet = useMediaQuery({ query: "(max-width: 960px)" });
  const { promoCodesTabCount, setFormDialogOpen, searchValue, promoCodesList } =
    useContext(PromoCodesContext);
  const {
    page,
    rowsPerPage,
    setPage,
    handleRowsPerPageChange,
    loading,
    onChangeSearchValue,
    fetchCoupons,
    removeCouponCode,
  } = useCouponTable();

  const RenderEmptyList = () => (
    <div className={classes.root}>
      <Paper className={classes.wrapper}>
        {loading && <LoadingIndicator withMask />}
        <ListEmpty
          imageSource={ImageDiscountStickerRed}
          title={dictionary.couponManagement.couponEmptyTitle}
          description={dictionary.couponManagement.couponEmptyMessage}
          buttonOnClick={() => setFormDialogOpen(true)}
        />
      </Paper>
    </div>
  );

  return (
    <>
      {promoCodesTabCount || (!promoCodesTabCount && searchValue) ? (
        <div className={classes.root}>
          <Paper className={classes.wrapper}>
            <Grid container spacing={10} alignItems="center">
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <SearchPromotion
                  onChange={onChangeSearchValue}
                  placeholder={dictionary.couponManagement.searchPlaceholder}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  <ButtonBlack onClick={() => setFormDialogOpen(true)}>
                    {dictionary.couponManagement.addNewCode}
                  </ButtonBlack>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={10} style={{ position: "relative" }}>
              {loading && <LoadingIndicator withMask />}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {searchValue && !promoCodesList?.length ? (
                  <VenuesSearchEmpty />
                ) : isTablet ? (
                  <AdaptiveList removeCouponCode={removeCouponCode} />
                ) : (
                  <FullTable
                    count={promoCodesTabCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handlePageChange={setPage}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    removeCouponCode={removeCouponCode}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </div>
      ) : (
        <RenderEmptyList />
      )}
      <DialogCreateNewCoupon updateListCoupon={fetchCoupons} />
    </>
  );
};

export default CouponsTable;
