import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../../theme/colors";
import { OcietyTheme } from "../../../models";

export type ClassNames =
  | "root"
  | "label"
  | "loadingIndicator"
  | "description"
  | "labelWrapper"
  | "dropzoneLabel";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        width: "100%",
        height: 120,
        background: "rgba(237, 239, 246, 0.35)",
        padding: theme.spacing(2),
        borderRadius: 4,
        border: "2px dashed rgba(186, 191, 207, 0.36)",
      },
      label: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
      },
      description: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 400,
        color: colors.grey.secondary,
      },
      labelWrapper: {
        marginLeft: 20,
      },
      loadingIndicator: {
        position: "absolute",
        left: 0,
        bottom: 0,
        right: 0,
      },
      dropzoneLabel: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      },
    })
);
