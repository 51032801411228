import { ChipClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

import { colors } from "../colors";

const MuiChip: Partial<Record<ChipClassKey, CSSProperties>> = {
  root: {
    borderRadius: 4,
    margin: 5,
    backgroundColor: colors.red.primary,
    color: colors.white.primary,
    fontWeight: 700,
    fontSize: 14,
  },
};

export default MuiChip;
