import {
  Venue,
  VenuePendingChanges,
  VenuePhoto,
  VenueVideo,
} from "../../../common/models";

export const GET_VENUE_PENDING = "GET_VENUE.PENDING";
export const GET_VENUE_REJECTED = "GET_VENUE.REJECTED";
export const GET_VENUE_FULFILLED = "GET_VENUE.FULFILLED";

export const SAVE_VENUE_PENDING = "SAVE_VENUE.PENDING";
export const SAVE_VENUE_REJECTED = "SAVE_VENUE.REJECTED";
export const SAVE_VENUE_FULFILLED = "SAVE_VENUE.FULFILLED";

export const SET_VENUE_PHOTO = "SET_VENUE_PHOTO";
export const DELETE_VENUE_PHOTO = "DELETE_VENUE_PHOTO";
export const SET_VENUE_VIDEO = "SET_VENUE_VIDEO";
export const DELETE_VENUE_VIDEO = "DELETE_VENUE_VIDEO";

export const CHANGE_MAIN_PHOTO = "CHANGE_MAIN_PHOTO";

export interface GetVenuePendingAction {
  type: typeof GET_VENUE_PENDING;
}

export interface GetVenueSuccessAction {
  type: typeof GET_VENUE_FULFILLED;
  venue: Venue;
}

export interface GetVenueFailAction {
  type: typeof GET_VENUE_REJECTED;
  error: string;
}

export interface SaveVenuePendingAction {
  type: typeof SAVE_VENUE_PENDING;
}

export interface SaveVenueSuccessAction {
  type: typeof SAVE_VENUE_FULFILLED;
  pendingChanges?: VenuePendingChanges;
}

export interface SaveVenueFailAction {
  type: typeof SAVE_VENUE_REJECTED;
  error: string;
}

export interface SetVenuePhotoAction {
  type: typeof SET_VENUE_PHOTO;
  photo: VenuePhoto;
}

export interface DeleteVenuePhotoAction {
  type: typeof DELETE_VENUE_PHOTO;
  photoId: number;
}

export interface SetVenueVideoAction {
  type: typeof SET_VENUE_VIDEO;
  video: VenueVideo;
}

export interface DeleteVenueVideoAction {
  type: typeof DELETE_VENUE_VIDEO;
  videoId: number;
}

export interface ChangeMainPhotoAction {
  type: typeof CHANGE_MAIN_PHOTO;
  photoId: number;
}

export type VenueActionTypes =
  | GetVenuePendingAction
  | GetVenueSuccessAction
  | GetVenueFailAction
  | SaveVenuePendingAction
  | SaveVenueSuccessAction
  | SaveVenueFailAction
  | SetVenuePhotoAction
  | SetVenueVideoAction
  | DeleteVenuePhotoAction
  | DeleteVenueVideoAction
  | ChangeMainPhotoAction;
