import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";
import { useDispatch } from "react-redux";

import { useRestApi } from "../../../../../../common/hooks/useRestApi";
import { ReactComponent as LikeActiveIcon } from "../../../../../../common/svg/icons/like-active.svg";
import { ReactComponent as LikeIcon } from "../../../../../../common/svg/icons/like.svg";
import { setLikedMessage } from "../../../../../../store/messaging/actions";
import { formatDateForMessages } from "../../../../../../utils/dateTimeUtils";
import { CHAT_MESSAGES_LIKE } from "../../../../api";
import Props from "./Props";
import { useStyles } from "./styles";

const MessagesListItem: FC<Props> = (props: Props) => {
  const {
    message: { isYours, avatar, timeSent, text, liked, id },
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [, likeMessage] = useRestApi(
    CHAT_MESSAGES_LIKE(id),
    "PATCH",
    undefined,
    true
  );

  const handleLikeMessage = () => {
    likeMessage()
      .then(() => {
        dispatch(setLikedMessage(props.message));
      })
      .catch((e) => console.error(e));
  };

  return (
    <ListItem
      alignItems="center"
      className={
        isYours
          ? classes.yourMessageItemWrapper
          : classes.companionMessageItemWrapper
      }>
      <ListItemAvatar>
        <Avatar
          alt={avatar || "Avatar picture"}
          src={
            avatar ||
            (isYours
              ? "./images/avatars/messages-profile-default-you.png"
              : "./images/avatars/messages-profile-default.jpg")
          }
        />
      </ListItemAvatar>
      <ListItemText
        secondary={
          <Typography variant="body1" color="textPrimary">
            {formatDateForMessages(timeSent)}
          </Typography>
        }
        className={clsx(
          classes.messageAndDateWrapper,
          isYours
            ? classes.yourMessageAndDateWrapper
            : classes.companionMessageAndDateWrapper
        )}>
        <Typography
          className={clsx(
            classes.textMessage,
            isYours ? classes.yourTextMessage : classes.companionTextMessage
          )}>
          {text}
        </Typography>
        {!isYours && (
          <IconButton
            className={classes.likeButton}
            onClick={handleLikeMessage}>
            {liked ? <LikeActiveIcon /> : <LikeIcon />}
          </IconButton>
        )}
      </ListItemText>
    </ListItem>
  );
};

export default MessagesListItem;
