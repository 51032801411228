import { TableBody } from "@material-ui/core";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const TBody: FC<Props> = (props: Props) => {
  const { children } = props;
  const classes = useStyles();

  return <TableBody className={classes.root}>{children}</TableBody>;
};

export default TBody;
