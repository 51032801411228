import clsx from "clsx";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const TabPanel: FC<Props> = (props: Props) => {
  const classes = useStyles();

  const { children, value, index, className, hideNotActive } = props;

  if (hideNotActive && value !== index) {
    return null;
  }

  return (
    <div
      className={clsx(classes.root, className)}
      role="tabpanel"
      hidden={hideNotActive && value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}>
      {children}
    </div>
  );
};

export default TabPanel;
