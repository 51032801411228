import { Paper } from "@material-ui/core";
import React, { FC } from "react";

import PageTitle from "../../common/components/PageTitle";
import dictionary from "../../i18n/en_US/dictionary";
import ReportedMediaTable from "./components/ReportedMediaTable";
import { useStyles } from "./styles";

const ReportedMedia: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PageTitle>{dictionary.reportedMedia.pageTitle}</PageTitle>
      <Paper className={classes.content}>
        <ReportedMediaTable />
      </Paper>
    </div>
  );
};

export default ReportedMedia;
