import { http } from "../../../config/api";
import IRole from "./models/IRole";

const RBAC_ROLES = `rbac/roles`;

export const getRoles = async (): Promise<IRole[]> =>
  http
    .get(RBAC_ROLES)
    .then((response) => [...response.data])
    .catch((error) => {
      throw error.response;
    });
