import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AuthState } from "../../store/auth/reducer";
import { AppState } from "../../store/rootReducer";
import { RoleNameEnum } from "../../views/VenueStaff/main/RoleTypes";

export function useIsDiscountAdmin() {
  const { roles } = useSelector<AppState, AuthState>((state) => state.auth);
  const [isDiscountAdmin, setIsDiscountAdmin] = useState(
    roles.includes(RoleNameEnum.discount)
  );

  useEffect(() => {
    setIsDiscountAdmin(roles.includes(RoleNameEnum.discount));
  }, [roles]);

  return isDiscountAdmin;
}
