import { SelectClassKey } from "@material-ui/core";
import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";

export type ClassNames =
  | "root"
  | "title"
  | "barLabel"
  | "filterWrapper"
  | "filterRadio"
  | "filterContainer"
  | "filterLabel"
  | "tableCell"
  | "tableHead"
  | "tableCellHead"
  | "table"
  | "filterSelect"
  | "tableEmptyContainer";

const tableCellPadding = 12 / 8;

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        position: "relative",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(5, 3),
          height: 350,
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(3, 2),
          height: 320,
        },
      },
      barLabel: {
        fontWeight: "bold",
        fontFamily: "Nunito Sans",
        fill: "#BAC4D1",
        fontSize: theme.typography.pxToRem(12),
        marginTop: 9,
      },
      title: {
        margin: 0,
      },
      filterContainer: {
        width: "100%",
        marginTop: theme.spacing(3),
        marginBottom: -theme.spacing(2),
      },
      filterWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
      },
      filterLabel: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(13),
        marginLeft: theme.spacing(1),
        color: theme.palette.text.secondary,
        "&.checked": {
          color: theme.palette.primary.main,
        },
      },
      filterRadio: {
        display: "none",
      },
      table: {
        margin: theme.spacing(3, -tableCellPadding),
      },
      tableCell: {
        borderBottom: 0,
        color: "#73859B",
        fontSize: theme.typography.pxToRem(15),
        padding: theme.spacing(1, tableCellPadding),
        "&:not(:last-of-type)": {
          width: "100%",
        },
      },
      tableCellHead: {
        padding: theme.spacing(tableCellPadding, tableCellPadding, 0),
        borderBottom: 0,
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.text.secondary,
      },
      tableHead: {
        backgroundColor: "transparent",
      },
      filterSelect: {
        marginLeft: "auto",
        marginRight: theme.spacing(3),
      },
      tableEmptyContainer: {
        marginTop: theme.spacing(2),
      },
    })
);

export const useSelectStyles: () => Partial<ClassNameMap<SelectClassKey>> =
  makeStyles(() =>
    createStyles({
      root: {
        padding: 14,
        width: 139,
      },
    })
  );
