import dropin, { Dropin } from "braintree-web-drop-in";
import React, { FC, useEffect, useState } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import { useCurrentVenueId } from "../../hooks/useCurrentVenueId";
import { useRestApi } from "../../hooks/useRestApi";
import ButtonCancel from "../ButtonCancel";
import ButtonSuccess from "../ButtonSuccess";
import LoadingIndicator from "../LoadingIndicator";
import {
  BRAINTREE_GET_CLIENT_ACCESS_TOKEN,
  BRAINTREE_SAVE_DEFAULT_PAYMENT_METHOD,
  GetClientTokenResponse,
  SaveDefaultPaymentMethodResponse,
} from "./api";
import Props from "./Props";
import { useStyles } from "./styles";

const BraintreeDropin: FC<Props> = (props: Props) => {
  const { onPaymentSave, onCancel } = props;
  const venueId = useCurrentVenueId();
  const classes = useStyles();
  const [dropinInstance, setDropinInstance] = useState<Dropin | null>(null);
  const [{ loading: getClientTokenLoading }, getClientToken] =
    useRestApi<GetClientTokenResponse>(
      BRAINTREE_GET_CLIENT_ACCESS_TOKEN,
      "GET"
    );
  const [
    { loading: saveDefaultPaymentMethodLoading },
    saveDefaultPaymentMethod,
  ] = useRestApi<any, SaveDefaultPaymentMethodResponse>(
    BRAINTREE_SAVE_DEFAULT_PAYMENT_METHOD,
    "POST"
  );
  const loading = saveDefaultPaymentMethodLoading || getClientTokenLoading;

  useEffect(() => {
    getClientToken()
      .then((data) => {
        dropin
          .create({
            authorization:
              data?.clientToken ||
              (process.env.REACT_APP_BRAINTREE_CLIENT_TOKEN as string),
            container: "#dropin_container",
            paypal: {
              flow: "vault",
            },
          })
          .then(setDropinInstance)
          .catch((e) => console.error(e));
      })
      .catch((e) => {
        console.error(e);
      });
  }, [getClientToken]);

  const handleSavePaymentMethod = () => {
    if (dropinInstance) {
      dropinInstance
        .requestPaymentMethod()
        .then(({ nonce }) => {
          saveDefaultPaymentMethod({
            paymentMethodNonce: nonce,
            venueId,
          })
            .then(onPaymentSave)
            .catch((e: any) => {
              console.log("'error' -->>> ", e);
            });
        })
        .catch((e) => console.error(e));
    }
  };

  return (
    <div className={classes.root}>
      <div id="dropin_container" />
      <div className={classes.actionsContainer}>
        <ButtonCancel onClick={onCancel}>
          {dictionary.plan.cancelAction}
        </ButtonCancel>
        <ButtonSuccess
          className={classes.saveButton}
          onClick={handleSavePaymentMethod}>
          {dictionary.plan.saveDefaultPaymentMethodButton}
        </ButtonSuccess>
      </div>
      {loading && <LoadingIndicator withMask />}
    </div>
  );
};

export default BraintreeDropin;
