import { ChangePasswordFormSchema } from "../../../../store/forms/types";

export const createSchema = (
  passwordValue?: string
): ChangePasswordFormSchema => ({
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  confirmNewPassword: {
    equality: {
      attribute: "password",
      comparator: (confirmValue: string) => confirmValue === passwordValue,
    },
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  code: {
    presence: { allowEmpty: false, message: "is required" },
    format: {
      pattern: /[0-9]*/g,
    },
    length: {
      maximum: 6,
      minimum: 6,
    },
  },
});
