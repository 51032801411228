import { Dispatch } from "react";

import { getAllUnclaimedVenues as gav } from "../api";
import { UnclaimedVenues } from "../models";
import {
  GET_UNCLAIMED_VENUES_FULFILLED,
  GET_UNCLAIMED_VENUES_PENDING,
  GET_UNCLAIMED_VENUES_REJECTED,
  GetUnclaimedVenuesFailAction,
  GetUnclaimedVenuesPendingAction,
  GetUnclaimedVenuesSuccessAction,
  UnclaimedVenuesActionTypes,
} from "./types";

export const getUnclaimedVenuesPendingAction =
  (): GetUnclaimedVenuesPendingAction => ({
    type: GET_UNCLAIMED_VENUES_PENDING,
  });

export const getUnclaimedVenuesSuccessAction = (
  unclaimedVenues: UnclaimedVenues[],
  unclaimedVenuesCount: number
): GetUnclaimedVenuesSuccessAction => ({
  type: GET_UNCLAIMED_VENUES_FULFILLED,
  unclaimedVenues,
  unclaimedVenuesCount,
});

export const getUnclaimedVenuesFailAction = (
  error: string
): GetUnclaimedVenuesFailAction => ({
  type: GET_UNCLAIMED_VENUES_REJECTED,
  error,
});

export const getAllUnclaimedVenues =
  (skip?: number, limit?: number, search?: string) =>
  async (dispatch: Dispatch<UnclaimedVenuesActionTypes>) => {
    dispatch(getUnclaimedVenuesPendingAction());
    try {
      const { unclaimedVenues, unclaimedVenuesCount } = await gav(
        skip,
        limit,
        search
      );

      dispatch(
        getUnclaimedVenuesSuccessAction(unclaimedVenues, unclaimedVenuesCount)
      );
    } catch (e) {
      dispatch(getUnclaimedVenuesFailAction(e));
    }
  };
