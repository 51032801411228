import { DialogContent } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";

import { PromotionType } from "../../../../../../../../../AdminVenue/enums";
import IPromotionCategory from "../../../../../../../../../types/interfaces/IPromotionCategory.interface";
import { DialogContext } from "../../DialogContextProvider";
import Main from "./components/Main";
import {
  getPromotionsCategories,
  getPromotionsSubCategories,
} from "./components/Main/api";
import Secondary from "./components/Secondary";
import Props from "./Props";
import { useStyles } from "./styles";

const Content: FC<Props> = ({ happyHourId }: Props) => {
  const classes = useStyles();

  const { currentStep, advancedForm } = useContext(DialogContext);

  const [promotionCategories, setPromotionCategories] = useState<
    Array<IPromotionCategory>
  >([]);
  const [promotionSubCategories, setPromotionSubCategories] = useState<
    Array<IPromotionCategory>
  >([]);

  const getPromotions = async (): Promise<void> => {
    const promotions =
      (await Promise.all([
        getPromotionsCategories(PromotionType.FOOD),
        getPromotionsCategories(PromotionType.DRINK),
      ]).catch(() => console.log("ERROR"))) || [];

    setPromotionCategories(promotions.flat());
  };

  useEffect(() => {
    setPromotionSubCategories([]);
    getPromotions();
  }, [setPromotionSubCategories]);

  const getCategoryValue = () => {
    if (advancedForm.form.values.mainCategoryId) {
      return advancedForm.form.values.mainCategoryId;
    }
  };

  const currentCategoryId = getCategoryValue();

  useEffect(() => {
    setPromotionSubCategories([]);
    if (currentCategoryId) {
      getPromotionsSubCategories(Number(currentCategoryId))
        .then((categories: IPromotionCategory[]) => {
          if (categories.length) {
            setPromotionSubCategories(categories);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [currentCategoryId]);

  return (
    <DialogContent className={classes.dialogContent}>
      {currentStep === 1 ? (
        <Main happyHourId={happyHourId} />
      ) : (
        <Secondary
          promotionCategories={promotionCategories}
          promotionSubCategories={promotionSubCategories}
        />
      )}
    </DialogContent>
  );
};
export default Content;
