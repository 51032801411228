import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../../../../common/models";

export type ClassNames =
  | "root"
  | "wrapper"
  | "promotionsIcon"
  | "title"
  | "periodSelect"
  | "titleWrapper"
  | "gridContainer"
  | "dialogPaperSM"
  | "dialogActions"
  | "dialogPrimaryButtonAction"
  | "actionFlexItem"
  | "actionFlexItemTotalPrice";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        maxWidth: 1000,
        margin: "auto",
      },
      wrapper: {
        height: "100%",
        maxWidth: "100%",
        padding: 30,
      },
      promotionsIcon: {
        width: 70,
        height: 70,
      },
      title: {
        marginTop: -6,
        marginRight: theme.spacing(2),
      },
      periodSelect: {
        "&&": {
          marginLeft: 20,
        },
        "&& .MuiSelect-select": {
          padding: "13px 20px",
        },
      },
      titleWrapper: {
        flexWrap: "wrap",
        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(3.8),
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: theme.spacing(1.8),
        },
        [theme.breakpoints.down("sm")]: {
          flexWrap: "wrap",
        },
      },
      gridContainer: { maxWidth: "none" },
      dialogActions: {
        padding: "0px 32px 32px",
      },
      dialogPaperSM: {
        maxWidth: 648,
      },
      dialogPrimaryButtonAction: {
        minWidth: 116,
      },
      actionFlexItem: {
        display: "flex",
        flexDirection: "row",
        gap: 16,
      },
      actionFlexItemTotalPrice: {
        gap: 8,
      },
    })
);
