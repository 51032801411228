import {
  Button,
  Chip,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React, { FC, useState } from "react";

import { ReactComponent as DeleteIcon } from "../../../../../../../common/svg/icons/delete-2.svg";
import { ReactComponent as DuplicateIcon } from "../../../../../../../common/svg/icons/duplicate.svg";
import { ReactComponent as EditIcon } from "../../../../../../../common/svg/icons/edit.svg";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { colors } from "../../../../../../../theme/colors";
import PromotionActiveDaysAndHours from "../../../../../../Promotions/components/PromotionsGrid/components/PromotionActiveDaysAndHours";
import { DiscountStatus } from "../../../../../../Promotions/types/DiscountStatus";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionsGridRow: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { promotion, onEditClick, onDeleteClick, onDuplicateClick } = props;
  const [isActiveHoursOpen, setIsActiveHoursOpen] = useState(false);

  const options = [
    {
      label: dictionary.managerDeals.statusOptions.pending,
      value: DiscountStatus.PENDING,
      color: colors.yellow.primary,
    },
    {
      label: dictionary.managerDeals.statusOptions.approved,
      value: DiscountStatus.ENABLED,
      color: colors.green.secondary,
    },
    {
      label: dictionary.managerDeals.statusOptions.paused,
      value: DiscountStatus.PAUSE,
      color: colors.dark.main,
    },
    {
      label: dictionary.managerDeals.statusOptions.expired,
      value: DiscountStatus.EXPIRED,
      color: colors.grey.additional,
    },
    {
      label: dictionary.managerDeals.statusOptions.cancelled,
      value: DiscountStatus.CANCELLED,
      color: colors.red.primary,
    },
  ];

  const getOption = (status: DiscountStatus) => {
    const option = options.find((option) => option.value === status);
    return option || options[0];
  };

  const printDate = (date: Date): string => {
    return moment(date).format("MM/DD/yyyy");
  };

  const printTime = (time: string) => {
    const hour = time.slice(0, 2);
    const isDay = parseInt(hour) > 12;
    return time.slice(0, 5) + dictionary.deals.formatTime(isDay);
  };

  const activeHours = () => {
    const todayDate = moment().format("dddd");
    const activeTodayHours = promotion.daysHours.find(
      (activeDay: any) => activeDay.day === todayDate
    );
    return activeTodayHours?.isActive
      ? `${printTime(activeTodayHours.start)} - ${printTime(
          activeTodayHours.end
        )}`
      : "";
  };

  const handleOpenActiveHoursModal = () => {
    setIsActiveHoursOpen((prev) => !prev);
  };

  return (
    <Grid container lg={5} xs={12} component={Paper} className={classes.root}>
      <Grid lg={8} md={6} sm={6} xs={8} className={classes.rowItemLeft}>
        <Typography
          variant={"body2"}
          className={clsx(classes.rowItemText, classes.nameText)}>
          {promotion.name}
        </Typography>
      </Grid>
      <Grid item lg={4} md={6} sm={6} xs={4} className={classes.rowItemStatus}>
        <Chip
          label={getOption(promotion.status).label}
          style={{ backgroundColor: getOption(promotion.status).color }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={6}
        className={clsx(classes.rowItem, classes.rowItemLeft)}>
        <Typography variant={"body2"} className={classes.text}>
          {dictionary.managerDeals.headers.activeHours}
        </Typography>
      </Grid>
      <Grid item xs={12} className={clsx(classes.rowItemLeft)}>
        <div className={classes.activeHoursWrapper}>
          <Typography variant={"body2"} className={classes.nameText}>
            {activeHours()}
          </Typography>
          <Link
            variant="body2"
            component="button"
            underline="always"
            onClick={handleOpenActiveHoursModal}
            className={classes.link}>
            {dictionary.managerDeals.headers.daysAndHours}
          </Link>
        </div>
        <PromotionActiveDaysAndHours
          open={isActiveHoursOpen}
          onClose={handleOpenActiveHoursModal}
          activeDaysAndHours={promotion.daysHours}
          printTime={printTime}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>

      <Grid item xs={6} className={clsx(classes.rowItem, classes.rowItemLeft)}>
        <Typography variant={"body2"} className={classes.text}>
          {dictionary.managerDeals.headers.startDate}
        </Typography>
      </Grid>
      <Grid item xs={6} className={clsx(classes.rowItem, classes.rowItemRight)}>
        <Typography variant={"body2"} className={classes.text}>
          {dictionary.managerDeals.headers.expirationDate}
        </Typography>
      </Grid>

      <Grid item xs={6} className={clsx(classes.rowItem, classes.rowItemLeft)}>
        <Typography variant={"body2"} className={classes.nameText}>
          {printDate(promotion.start)}
        </Typography>
      </Grid>
      <Grid item xs={6} className={clsx(classes.rowItem, classes.rowItemRight)}>
        <Typography variant={"body2"} className={classes.nameText}>
          {printDate(promotion.end)}
        </Typography>
      </Grid>

      {promotion.status === DiscountStatus.PENDING && promotion.editable && (
        <>
          <Grid
            item
            xs={6}
            className={clsx(classes.rowItemCenter, classes.buttonsContainer)}>
            <Button
              className={clsx(classes.buttonLeft, classes.actionButton)}
              variant="outlined"
              color="primary"
              onClick={() => onDeleteClick(promotion.id)}>
              <DeleteIcon />
            </Button>
          </Grid>
          <Grid
            xs={6}
            className={clsx(classes.rowItemCenter, classes.buttonsContainer)}>
            <Button
              className={clsx(
                classes.buttonRight,
                classes.actionButton,
                classes.editButton
              )}
              variant="outlined"
              onClick={() => onEditClick(promotion.id)}>
              <EditIcon />
            </Button>
          </Grid>
        </>
      )}
      {promotion.status === DiscountStatus.EXPIRED && (
        <Grid
          item
          xs={12}
          className={clsx(classes.rowItemCenter, classes.buttonsContainer)}>
          <Button
            className={clsx(classes.actionButton, classes.duplicateButton)}
            variant="outlined"
            onClick={() => onDuplicateClick(promotion.id)}>
            <DuplicateIcon />
            <Typography
              variant={"body2"}
              className={clsx(classes.text, classes.duplicateText)}>
              {dictionary.managerDeals.duplicateBtn}
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default PromotionsGridRow;
