import { Review } from "../../../../../common/models";
import { http } from "../../../../../config/api";

export const CRITIC_REVIEW_LIMIT = 3;
const UPDATE_VENUE_CRITIC_REVIEW = (reviewId: number) =>
  `/reviews/${reviewId}/comment-response`;

const GET_VENUE_CRITIC_REVIEWS = (venueId: number) =>
  `/reviews/${venueId}/critic`;

export const getVenueCriticReviews = async (
  venueId: number,
  limit: number,
  skip?: number
): Promise<VenueCriticReviewsResponse> =>
  http
    .get(GET_VENUE_CRITIC_REVIEWS(venueId), { params: { limit, skip } })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });

export const putVenueCriticReview = async (
  reviewId: number,
  venueId: number,
  commentResponse: string,
  commentResponseDate: Date
): Promise<Review> =>
  http
    .patch(UPDATE_VENUE_CRITIC_REVIEW(reviewId), {
      venueId,
      commentResponse,
      commentResponseDate,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });

export interface VenueCriticReviewsResponse {
  reviews: Review[];
  count: number;
}
