import { PaperProps } from "@material-ui/core/Paper";

const MuiPaper: PaperProps = {
  style: {
    borderRadius: 6,
    boxShadow: "0px 0px 30px rgba(59, 72, 118, 0.08)",
  },
};

export default MuiPaper;
