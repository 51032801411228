import { Overrides } from "@material-ui/core/styles/overrides";

import MuiAccordion from "./MuiAccordion";
import MuiAccordionSummary from "./MuiAccordionSummary";
import MuiAvatar from "./MuiAvatar";
import MuiButton from "./MuiButton";
import MuiCheckbox from "./MuiCheckbox";
import MuiChip from "./MuiChip";
import MuiDialog from "./MuiDialog";
import MuiDialogActions from "./MuiDialogActions";
import MuiDialogContent from "./MuiDialogContent";
import MuiFormLabel from "./MuiFormLabel";
import MuiInputLabel from "./MuiInputLabel";
import MuiLinearProgress from "./MuiLinearProgress";
import MuiMenu from "./MuiMenu";
import MuiMenuItem from "./MuiMenuItem";
import MuiPaper from "./MuiPaper";
import MuiRadio from "./MuiRadio";
import MuiSelect from "./MuiSelect";
import MuiSwitch from "./MuiSwitch";
import MuiTab from "./MuiTab";
import MuiTableCell from "./MuiTableCell";
import MuiTablePagination from "./MuiTablePagination";
import MuiTableRow from "./MuiTableRow";
import MuiTabs from "./MuiTabs";
import MuiTextField from "./MuiTextField";
import MuiTypography from "./MuiTypography";

const overrides: Overrides = {
  MuiAccordion,
  MuiAccordionSummary,
  MuiAvatar,
  MuiButton,
  MuiCheckbox,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiPaper,
  MuiTab,
  MuiTableCell,
  MuiTableRow,
  MuiTabs,
  MuiTypography,
  MuiRadio,
  MuiFormLabel,
  MuiSelect,
  MuiLinearProgress,
  MuiMenu,
  MuiMenuItem,
  MuiTablePagination,
  MuiTextField,
  MuiInputLabel,
  MuiChip,
  MuiSwitch,
};

export default overrides;
