import { Form } from "../../../../../common/models";
import { ValidationRules } from "../../../../../utils/validation";

export type CreateVenueAdminInfoFormField =
  | "comment"
  | "company"
  | "email"
  | "firstName"
  | "lastName"
  | "phoneNumber"
  | "title";

export type CreateVenueAdminInfoFormValues = {
  [key in CreateVenueAdminInfoFormField]: string;
};

export type CreateVenueAdminInfoFormErrors = {
  [key in CreateVenueAdminInfoFormField]: string;
};

export interface CreateVenueAdminInfoForm extends Form {
  values: CreateVenueAdminInfoFormValues;
  errors: CreateVenueAdminInfoFormErrors;
  touched: CreateVenueAdminInfoFormField[];
}

export type CreateVenueAdminInfoFormSchema = Record<
  CreateVenueAdminInfoFormField,
  ValidationRules
>;

export const createVenueAdminInfoForm: CreateVenueAdminInfoForm = {
  values: {
    comment: "",
    company: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    title: "",
  },
  errors: {
    comment: "",
    company: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    title: "",
  },
  touched: [],
  isValid: true,
};

export const createVenueAdminInfoFormSchema: CreateVenueAdminInfoFormSchema = {
  company: {
    required: true,
    length: {
      max: 256,
    },
  },
  comment: {
    length: {
      max: 1000,
      min: 10,
    },
  },
  email: {
    required: true,
    type: "email",
    length: {
      max: 256,
    },
  },
  firstName: {
    required: true,
    length: {
      max: 256,
    },
  },
  lastName: {
    required: true,
    length: {
      max: 256,
    },
  },
  phoneNumber: {
    required: true,
    type: "phone",
    length: {
      max: 256,
    },
  },
  title: {
    required: true,
    length: {
      max: 256,
    },
  },
};
