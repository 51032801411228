import { Tab, Tabs, useTheme } from "@material-ui/core";
import React, { FC } from "react";
import SwipeableViews from "react-swipeable-views";

import TabPanel from "../../../../common/components/TabPanel";
import dictionary from "../../../../i18n/en_US/dictionary";
import ReportingGeneralTab from "../ReportingGeneralTab";
import ReportingLiveStatsTab from "../ReportingLiveStatsTab";
import ReportingPromotionTab from "../ReportingPromotionTab";
import ReportingRecommendationsTab from "../ReportingRecommendationsTab";
import Props from "./Props";
import { useStyles } from "./styles";

// indexes (hashes order) is important
const tabHashes = ["#general", "#live-stats", "#deal", "#recommendations"];

const ReportingTabs: FC<Props> = (props: Props) => {
  const { onChangeTab } = props;
  const theme = useTheme();
  const { hash } = window.location;
  const initialTabIndex = hash
    ? tabHashes.indexOf(hash) !== -1
      ? tabHashes.indexOf(hash)
      : 0
    : 0;

  const [currentTab, setCurrentTab] = React.useState(initialTabIndex);
  const classes = useStyles();
  const tabProps = (index: number) => ({
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  });

  const changeTab = (index: number) => {
    setCurrentTab(index);
    window.location.hash = tabHashes[index];
    onChangeTab(tabHashes[index], index);
  };

  const handleChange = (
    event: React.ChangeEvent<Record<any, any>>,
    newValue: number
  ) => {
    changeTab(newValue);
  };

  const handleChangeIndex = (index: number) => {
    changeTab(index);
  };

  return (
    <div>
      <div className={classes.tabsWrapper}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="reporting tab">
          <Tab
            classes={{
              root: classes.tabRoot,
            }}
            label={dictionary.reporting.tabLabelGeneral}
            {...tabProps(0)}
          />
          <Tab
            classes={{
              root: classes.tabRoot,
            }}
            label={dictionary.reporting.tabLabelLiveStats}
            {...tabProps(1)}
          />
          <Tab
            classes={{
              root: classes.tabRoot,
            }}
            label={dictionary.reporting.tabLabelDeal}
            {...tabProps(2)}
          />
          <Tab
            classes={{
              root: classes.tabRoot,
            }}
            label={dictionary.reporting.tabLabelRecommendations}
            {...tabProps(2)}
          />
        </Tabs>
      </div>
      <SwipeableViews
        className={classes.swipeableContainer}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={currentTab}
        onChangeIndex={handleChangeIndex}>
        <TabPanel
          hideNotActive
          value={currentTab.toString()}
          index={"0"}
          dir={theme.direction}>
          <ReportingGeneralTab />
        </TabPanel>
        <TabPanel
          hideNotActive
          value={currentTab.toString()}
          index={"1"}
          dir={theme.direction}>
          <ReportingLiveStatsTab />
        </TabPanel>
        <TabPanel
          hideNotActive
          value={currentTab.toString()}
          index={"2"}
          dir={theme.direction}>
          <ReportingPromotionTab />
        </TabPanel>
        <TabPanel
          hideNotActive
          value={currentTab.toString()}
          index={"3"}
          dir={theme.direction}>
          <ReportingRecommendationsTab />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export default ReportingTabs;
