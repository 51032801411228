import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";

export type ClassNames =
  | "root"
  | "heading"
  | "sectionContent"
  | "divider"
  | "textField"
  | "configSection"
  | "configActions"
  | "cancelButton"
  | "accordionWrapper"
  | "message";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        flex: 1,
        [theme.breakpoints.up("md")]: {
          margin: theme.spacing(5, "auto"),
          padding: theme.spacing(0, 2),
        },
        padding: theme.spacing(0, 1),
        margin: theme.spacing(1, "auto"),
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
      sectionContent: {
        display: "flex",
        flexDirection: "column",
      },
      divider: {
        margin: theme.spacing(2, 0),
      },
      textField: {
        marginBottom: theme.spacing(1),
      },
      configSection: {
        [theme.breakpoints.up("md")]: {
          width: "50vw",
        },
        width: "100%",
        margin: theme.spacing(0, "auto"),
      },
      configActions: {
        marginTop: theme.spacing(2),
      },
      cancelButton: {
        marginRight: theme.spacing(2),
      },
      accordionWrapper: {
        position: "relative",
      },
      message: {
        marginTop: theme.spacing(2),
      },
    })
);
