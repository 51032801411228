import React, { FC, useContext, useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartContainer from "../../../../../../common/components/ChartContainer";
import ChartTooltip from "../../../../../../common/components/ChartTooltip";
import { VenueReportingContext } from "../../../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../../../common/hooks/useRequestByPeriod";
import { useVenueHasPackage } from "../../../../../../common/hooks/useVenueHasPacakage";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { PackageUID } from "../../../../../../store/currentVenue/types";
import { chartAdditionalColor, colors } from "../../../../../../theme/colors";
import {
  LegendDefaultProps,
  LineDefaultProps,
  XAxisDefaultProps,
  YAxisDefaultProps,
} from "../../../../defaultProps";
import {
  GET_VENUE_MEDIA_CONTENT_VIEWS,
  GetVenueMediaContentViewsResponse,
  MediaContentViewsData,
} from "./api";
import { chartDataMock } from "./mock";

const VenueMediaContentViews: FC = () => {
  const [chartData, setChartData] = useState(chartDataMock);
  const { filters, handlePackageName } = useContext(VenueReportingContext);
  const hasPackage = useVenueHasPackage(PackageUID.CUSTOMER);
  const { response, loading } =
    useRequestByPeriod<GetVenueMediaContentViewsResponse>(
      GET_VENUE_MEDIA_CONTENT_VIEWS,
      filters,
      hasPackage
    );

  const prepareChartData = (stats: MediaContentViewsData[]) => {
    setChartData(
      stats.map((viewsData) => ({
        ...viewsData,
        month: viewsData.month.slice(0, 3),
      }))
    );
  };

  useEffect(() => {
    if (response) {
      prepareChartData(response.stats);
    }
    if (!hasPackage) {
      setChartData(chartDataMock);
    }
  }, [response, hasPackage]);

  const isChartEmpty = !chartData.some(
    (data) => (data.videos || data.images) !== 0
  );

  return (
    <ChartContainer
      locked={!hasPackage}
      loading={loading}
      isChartEmpty={isChartEmpty}
      title={dictionary.reporting.venueMediaContentViewsTitle}
      chartType={"line-with-y-axis-and-legend-bottom"}
      packageName={handlePackageName(PackageUID.CUSTOMER)}>
      <LineChart data={chartData}>
        <CartesianGrid
          strokeDasharray="3 3"
          strokeOpacity={0.6}
          color={colors.grey.light}
          vertical={false}
        />
        <Line
          {...LineDefaultProps}
          dataKey={"videos"}
          dot={false}
          type="monotone"
          stroke={chartAdditionalColor}
          unit={dictionary.reporting.venueMediaContentViewsVideosUnit}
        />
        <Line
          {...LineDefaultProps}
          dot={false}
          dataKey={"images"}
          type="monotone"
          unit={dictionary.reporting.venueMediaContentViewsImagesUnit}
        />
        <XAxis {...XAxisDefaultProps} dataKey="month" />
        <YAxis {...YAxisDefaultProps} />
        <Tooltip content={<ChartTooltip />} />
        <Legend {...LegendDefaultProps} />
      </LineChart>
    </ChartContainer>
  );
};

export default VenueMediaContentViews;
