import { http } from "../../../config/api";

const RBAC_PERMISSIONS = `rbac/permissions`;
const RBAC_ROLE = (roleId: number): string => `rbac/role/${roleId}`;
const RBAC_ROLE_PERMISSIONS = (roleId: number): string =>
  `rbac/role/${roleId}/permissions`;

export const getRole = async (id: number): Promise<any> =>
  http
    .get(RBAC_ROLE(id))
    .then((response) => {
      return { ...response.data };
    })
    .catch((error) => {
      throw error.response;
    });

export const getAllPermissions = async (): Promise<any> =>
  http
    .get(RBAC_PERMISSIONS)
    .then((response) => [...response.data])
    .catch((error) => {
      throw error.response;
    });

export const savePermissionRole = async (
  roleId: number,
  permission: Permissions,
  status: boolean
): Promise<any> => {
  http
    .put(RBAC_ROLE_PERMISSIONS(roleId), {
      permission,
      status,
    })
    .then((response) => {
      return { ...response.data };
    })
    .catch((error) => {
      throw error.response;
    });
};
