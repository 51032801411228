import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";
import { colors } from "../../../../theme/colors";

export type ClassNames =
  | "paymentContainer"
  | "dialogContent"
  | "packagesContainer"
  | "itemsTitleContainer"
  | "totalContainer"
  | "totalLabel"
  | "totalAmount"
  | "clearCartButton"
  | "packageToPurchaseTitle"
  | "reviewPackageBtnEmptyCart";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      paymentContainer: {
        position: "relative",
        minHeight: 150,
      },
      dialogContent: {
        minWidth: 500,
        position: "relative",
      },
      itemsTitleContainer: {
        marginTop: theme.spacing(2),
      },
      packagesContainer: {
        maxHeight: 525,
        padding: theme.spacing(3, 3, 0),
        margin: theme.spacing(0, -3),
        overflowY: "auto",
      },
      packageToPurchaseTitle: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(16),
        marginBottom: 10,
      },
      clearCartButton: {
        marginRight: "auto",
      },
      totalContainer: {
        display: "flex",
        justifyContent: "flex-end",
        margin: theme.spacing(3, 0),
      },
      totalLabel: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(16),
        paddingRight: 13,
      },
      totalAmount: {
        color: colors.red.primary,
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(16),
      },
      reviewPackageBtnEmptyCart: {
        justifyContent: "center",
      },
    })
);
