export const GET_VENUE_UNIQUE_VIEWS = "reporting/unique-views-by-month";

export interface UniqueViewsData {
  month: string;
  viewsCount: number;
}

export interface GetVenueUniqueViewsResponse {
  stats: UniqueViewsData[];
}
