import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "renderActions"
  | "renderActionsButtonPrimary"
  | "renderActionsContainer"
  | "renderActionsContainerContent"
  | "renderActionsContainerImage"
  | "renderSelectContainer"
  | "renderSelectContainerContent"
  | "renderSelectContainerContentWrapper"
  | "renderSelectContainerImage"
  | "text1"
  | "text2"
  | "text3";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      renderSelectContainer: {
        marginTop: 16,
        background: "#FFFFFF",
        border: "1px solid #E5E7EA",
        borderRadius: 7,
        padding: 8,
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginRight: 17,

        [theme.breakpoints.down("sm")]: {
          marginRight: 0,
        },
      },
      renderActions: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
      renderActionsButtonPrimary: { marginTop: 10 },
      renderActionsContainer: {
        marginTop: 16,
        background: "#FFFFFF",
        border: "1px solid #E5E7EA",
        borderRadius: 7,
        padding: "8px 32px 8px 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      renderActionsContainerContent: { marginRight: 32, width: "100%" },
      renderActionsContainerImage: {
        marginLeft: "-8px",
        height: 40,
        width: "100%",
      },
      renderSelectContainerContent: { marginBottom: 17 },
      renderSelectContainerContentWrapper: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        padding: 8,
      },
      renderSelectContainerImage: { height: 40, width: "100%" },
      text1: { fontWeight: 700, fontSize: 12, color: "#1F1F1F", marginTop: 8 },
      text2: { fontWeight: 600, fontSize: "8px !important", color: "#929499" },
      text3: {
        fontWeight: 400,
        fontSize: "9px  !important",
        color: "#37393F",
        marginTop: 10,
      },
    })
);
