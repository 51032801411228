import { Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, SyntheticEvent } from "react";

import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import OcietyIcon from "../../../../common/components/OcietyIcon";
import OcietyNavLink from "../../../../common/components/OcietyNavLink";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { useGlobalStyles } from "../../../../common/styles";
import { Route } from "../../../../config/router";
import Props from "./Props";
import { useStyles } from "./styles";

const DashboardWidgetSmall: FC<Props> = (props: Props) => {
  const {
    icon,
    description,
    link,
    score,
    loading,
    linkHash,
    orientation = "horizontal",
  } = props;
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const venueId = useCurrentVenueId();
  const isHorizontal = orientation === "horizontal";
  const to = [link, venueId.toString()];

  const disableLink = link === Route.Recommendations;

  if (linkHash) {
    to.push(linkHash);
  }

  const handleOnClick = (e: SyntheticEvent) => {
    if (disableLink) {
      e.preventDefault();
    }
  };

  return (
    <OcietyNavLink
      to={to}
      onClick={handleOnClick}
      className={clsx(
        disableLink && "disableLink" && classes.linkDisabled,
        classes.link
      )}
      linkTextClassName={classes.linkContent}>
      <Paper
        className={clsx(
          isHorizontal
            ? globalClasses.flexRow_Center
            : globalClasses.flexColumnCenterCenter,
          classes.root
        )}>
        <OcietyIcon name={"arrowRight"} classes={{ root: classes.iconArrow }} />
        {loading && <LoadingIndicator withMask />}
        <OcietyIcon
          name={icon}
          viewBox={"0 0 62 62"}
          classes={{
            root: clsx(
              classes.icon,
              isHorizontal ? classes.iconHorizontal : classes.iconVertical
            ),
          }}
        />
        <div
          className={
            isHorizontal
              ? globalClasses.flexColumnCenterCenter
              : globalClasses.flexColumnCenterCenterReverse
          }>
          <Typography variant={"h1"}>{score}</Typography>
          <Typography
            className={
              isHorizontal ? classes.description : classes.descriptionVertical
            }>
            {description}
          </Typography>
        </div>
      </Paper>
    </OcietyNavLink>
  );
};

export default DashboardWidgetSmall;
