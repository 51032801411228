import { Box, LinearProgress, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const LinearProgressWithLabel: FC<Props> = (props: Props) => {
  const { className, hidePercents, ...restProps } = props;
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      className={clsx(classes.root, className)}>
      <Box width="100%" mr={hidePercents ? 0 : 1}>
        <LinearProgress
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
          variant="determinate"
          {...restProps}
          className={classes.linearProgress}
        />
      </Box>
      {!hidePercents && (
        <Box minWidth={35}>
          <Typography
            variant="body1"
            color="textSecondary"
            className={classes.textValue}>{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};
export default LinearProgressWithLabel;
