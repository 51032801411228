import { Grid, Link, Paper, SvgIcon, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { has } from "lodash";
import React, { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonBlack from "../../../../common/components/ButtonBlack";
import ButtonDelete from "../../../../common/components/ButtonDelete";
import ButtonSuccess from "../../../../common/components/ButtonSuccess";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { VenuePhoto, VenueVideo } from "../../../../common/models";
import { ReactComponent as ConnectedIcon } from "../../../../common/svg/icons/connected.svg";
import { ReactComponent as StripeIcon } from "../../../../common/svg/icons/stripe.svg";
import dictionary from "../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../store/rootReducer";
import { getVenue, setVenuePhoto, setVenueVideo } from "../../ducks/actions";
import { useStyles as useBaseStyles } from "../../styles";
import { useStyles } from "../VenueMenu/styles";

const PaymentContent: FC = () => {
  const { venue, currentVenue } = useSelector<AppState, AppState>(
    (state) => state
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const baseClasses = useBaseStyles();

  const venueId = useCurrentVenueId();

  // const [{ loading: saveMediaLoading, data: url }, registerPayment] =
  //   useRestApi("/venue/registerpayment/" + venueId, "POST", {}, true);
  const [
    { loading: paymentStatusLoading, data: status },
    getPaymentAccountStatus,
  ] = useRestApi("/venue/paymentstatus/" + venueId, "GET", {}, true);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    getPaymentAccountStatus();
  }, [getPaymentAccountStatus]);

  // useEffect(() => {
  //   if (url) {
  //     debugger;
  //     if (url) window.location.href = url.toString();
  //   }
  // }, [url]);

  // const redirect = useCallback(() => {
  //   if (url) window.location.href = url.toString();
  // }, [url]);
  const handleButtonClick = async () => {
    // await registerPayment();
    // setTimeout(() => {
    //   redirect();
    // }, 500);
  };

  const LinkRedirect = ({ children }: any) => (
    <a
      href={
        `${process.env.REACT_APP_BASE_URL}/admin/venue/registerpayment/` +
        venueId
      }>
      {children}
    </a>
  );
  return (
    <Paper className={baseClasses.section}>
      <div>
        {(venue.loading || paymentStatusLoading) && (
          <LoadingIndicator withMask />
        )}
        <Link href="#stripe">
          <Typography variant={"h2"} style={{ marginBottom: 32 }}>
            {dictionary.venues.venueForm.paymentTitle}
          </Typography>
        </Link>

        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justify="space-between"
          spacing={5}
          id="stripe">
          <Grid item xs={1} sm={1}>
            <StripeIcon />
          </Grid>
          <Grid item xs={11} sm={7}>
            {!status ? (
              <div>
                <Typography variant={"h2"} className={baseClasses.sectionTitle}>
                  {"Connect to Stripe"}
                </Typography>
                <Typography variant={"body1"} className={classes.infoText}>
                  {
                    "Easily connect your bank account to Stripe to receive payments from customers for redeemed deals"
                  }
                </Typography>

                {currentVenue.currentVenue?.hasStripeAccount && (
                  <Alert severity="warning">
                    <div>
                      <Typography
                        variant={"h4"}
                        className={baseClasses.sectionTitle}>
                        {"Additional Documents Required"}
                      </Typography>
                      <Typography
                        variant={"body2"}
                        className={classes.infoText}>
                        {
                          "Please visit your Stripe dashboard for more information"
                        }
                      </Typography>
                    </div>
                  </Alert>
                )}
              </div>
            ) : (
              <div>
                <Typography
                  variant={"h2"}
                  className={baseClasses.sectionTitle}
                  style={{ display: "flex" }}>
                  {"Stripe Connected & Ready   "}
                  <ConnectedIcon style={{ paddingLeft: 5 }} />
                </Typography>

                <Typography variant={"body1"} className={classes.infoText}>
                  {"Your Stripe account is already connected and ready to accept payments from customers.\n" +
                    "Follow the link to see all financials associated with deal redemptions.\n" +
                    "All payouts will be made at the end of the first full month. Minimum of $50 balance."}
                </Typography>
              </div>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              justifyContent: "end",
            }}>
            {/* <div className={classes.saveButton}> */}
            {status ? (
              <LinkRedirect>
                <ButtonBlack onClick={handleButtonClick}>
                  {dictionary.venues.venueForm.openDashboard}
                </ButtonBlack>
              </LinkRedirect>
            ) : currentVenue.currentVenue?.hasStripeAccount ? (
              <LinkRedirect>
                <ButtonBlack onClick={handleButtonClick}>
                  {dictionary.venues.venueForm.proceedPayment}
                </ButtonBlack>
              </LinkRedirect>
            ) : (
              <LinkRedirect>
                <ButtonBlack onClick={handleButtonClick}>
                  {dictionary.venues.venueForm.registerPayment}
                </ButtonBlack>
              </LinkRedirect>
            )}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default PaymentContent;
