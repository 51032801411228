import { Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import PageTitle from "../../../common/components/PageTitle";
import { useGlobalStyles } from "../../../common/styles";
import dictionary from "../../../i18n/en_US/dictionary";
import PromotionsContextProvider from "../providers";
import TabsPromotions from "./components/Tabs";
import { useStyles } from "./styles";

const OcietyAdminPromotions: FC = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  return (
    <PromotionsContextProvider>
      <div>
        <div
          className={clsx(globalClasses.flexRow_Center, classes.titleWrapper)}>
          <PageTitle>{dictionary.deals.titlePage}</PageTitle>
        </div>

        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <TabsPromotions />
          </Grid>
        </Grid>
      </div>
    </PromotionsContextProvider>
  );
};
export default OcietyAdminPromotions;
