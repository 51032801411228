export enum ForgotPasswordMethodEnum {
  SMS = "SMS",
  EMAIL = "EMAIL",
  CALL = "CALL",
}
export interface ForgotPasswordMethod {
  method: string;
  target: string;
}

export interface ForgotPasswordResponse {
  success: boolean;
  method: ForgotPasswordMethod;
  methods: ForgotPasswordMethod[];
  isNeedAdditionlAction?: boolean;
  token?: string;
}

export interface ChangePasswordResponse {
  success: boolean;
  error?: string;
}

export const FORGOT_PASSWORD_PENDING = "FORGOT_PASSWORD.PENDING";
export const FORGOT_PASSWORD_FULFILLED = "FORGOT_PASSWORD.FULFILLED";
export const FORGOT_PASSWORD_REJECTED = "FORGOT_PASSWORD.REJECTED";
export const FORGOT_PASSWORD_CLEAR_ERROR = "FORGOT_PASSWORD.ClEAR_ERROR";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD.RESET";

export interface ForgotPasswordPendingAction {
  type: typeof FORGOT_PASSWORD_PENDING;
}

export interface ForgotPasswordSuccessAction {
  type: typeof FORGOT_PASSWORD_FULFILLED;
  success: boolean;
  method: ForgotPasswordMethod;
  methods: ForgotPasswordMethod[];
  email: string;
  token: undefined | string;
}

export interface ForgotPasswordFailAction {
  type: typeof FORGOT_PASSWORD_REJECTED;
  error: string;
}

export interface ForgotPasswordClearErrorAction {
  type: typeof FORGOT_PASSWORD_CLEAR_ERROR;
}

export interface ForgotPasswordResetAction {
  type: typeof FORGOT_PASSWORD_RESET;
}

export interface ForgotPasswordPendingAction {
  type: typeof FORGOT_PASSWORD_PENDING;
}

export interface ForgotPasswordSuccessAction {
  type: typeof FORGOT_PASSWORD_FULFILLED;
  success: boolean;
  method: ForgotPasswordMethod;
  methods: ForgotPasswordMethod[];
  email: string;
  token: undefined | string;
}

export interface ForgotPasswordFailAction {
  type: typeof FORGOT_PASSWORD_REJECTED;
  error: string;
}

export interface ForgotPasswordClearErrorAction {
  type: typeof FORGOT_PASSWORD_CLEAR_ERROR;
}

export interface ForgotPasswordResetAction {
  type: typeof FORGOT_PASSWORD_RESET;
}

export type ForgotPasswordActionTypes =
  | ForgotPasswordPendingAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordFailAction
  | ForgotPasswordClearErrorAction
  | ForgotPasswordResetAction;
