import { Box, Typography } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import clsx from "clsx";
import React, { FC } from "react";
import { useHistory } from "react-router";

import { getRouteWithSlash, Route } from "../../../config/router";
import dictionary from "../../../i18n/en_US/dictionary";
import ButtonBlack from "../ButtonBlack";
import OcietyIcon from "../OcietyIcon";
import OcietyTooltip from "../OcietyTooltip";
import Props from "./Props";
import { useStyles } from "./styles";

const LockedChartMask: FC<Props> = (props: Props) => {
  const { wrapperClassName, withMask, packageName, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();

  const handleGoToPackages = () => {
    history.push(getRouteWithSlash(Route.Plan));
  };

  return (
    <Box
      className={clsx(
        classes.root,
        withMask && classes.withMask,
        wrapperClassName
      )}>
      <OcietyTooltip
        title={dictionary.plan.toAccessPurchasePackageTooltip(packageName)}
        placement="top">
        <div>
          <ButtonBlack className={classes.btn} onClick={handleGoToPackages}>
            <OcietyIcon name={"lock"} className={classes.lockIcon} />
            <LockOpenIcon className={classes.unlockIcon} />
            <Typography variant="body2" className={classes.unlockBtnText}>
              {dictionary.reporting.unlockReportingButton}
            </Typography>
          </ButtonBlack>
        </div>
      </OcietyTooltip>
    </Box>
  );
};

export default LockedChartMask;
