import MomentUtils from "@date-io/moment";
import {
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import clsx from "clsx";
import React, { FC } from "react";

import OcietyDialogTitle from "../../../../../../common/components/dialogs/OcietyDIalogTitle";
import { OcietyTheme } from "../../../../../../common/models";
import { ReactComponent as ClockIcon } from "../../../../../../common/svg/icons/clock.svg";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { getMomentFromTimeString } from "../../../../../../utils/dateTimeUtils";
import { DiscountDaysHours } from "../../../../types/interfaces/DiscountDaysHours.interface";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionActiveDaysAndHours: FC<Props> = (props: Props) => {
  const { open, onClose, activeDaysAndHours, printTime, ...rest } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: OcietyTheme) =>
    theme.breakpoints.down("sm")
  );

  const handleClosePopup = () => {
    if (onClose) {
      // @ts-ignore
      onClose();
    }
  };

  const timeRender = (dayHour: DiscountDaysHours, isStart?: boolean) => {
    return (
      <Paper
        className={
          dayHour.isActive ? classes.timePickerWrapper : classes.unActiveHours
        }>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ClockIcon className={classes.clockIcon} />
          <TimePicker
            className={classes.timePicker}
            label={
              isStart
                ? dictionary.deals.createDealForm.startLabel
                : dictionary.deals.createDealForm.endLabel
            }
            InputLabelProps={{
              className: classes.timeLabel,
            }}
            InputProps={{
              disableUnderline: true,
            }}
            value={getMomentFromTimeString(
              isStart ? dayHour.start : dayHour.end
            )}
            onChange={(newTime) => {
              newTime || undefined;
            }}
            readOnly
          />
        </MuiPickersUtilsProvider>
      </Paper>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      {...rest}>
      <OcietyDialogTitle id="mfa-call-dialog-title" onClose={handleClosePopup}>
        {dictionary.deals.activeHours}
      </OcietyDialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <div className={classes.hoursContainer}>
          <Grid container justify="space-between" className={classes.root}>
            {activeDaysAndHours.map((dayHour) => (
              <Grid
                item
                container
                xs={12}
                key={dayHour.id}
                alignItems="center"
                className={clsx(
                  classes.dayNameWrapper,
                  dayHour.day === "Saturday" && classes.lastDay
                )}>
                <Grid item lg={4} md={4} sm={2} xs={2}>
                  {dayHour.isActive ? (
                    <Typography className={classes.dayName}>
                      {isMobile ? dayHour.day.substring(0, 3) : dayHour.day}
                    </Typography>
                  ) : (
                    <Typography variant="body2">{dayHour.day}</Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={5}
                  xs={5}
                  className={classes.hoursCenter}>
                  {timeRender(dayHour, true)}
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={5}
                  xs={5}
                  className={classes.hoursCenter}>
                  {timeRender(dayHour)}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PromotionActiveDaysAndHours;
