import {
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React, { FC, useContext } from "react";

import OcietyCheckbox from "../../../../../../common/components/OcietyCheckbox";
import { ReactComponent as FilterCheckboxIcon } from "../../../../../../common/svg/icons/filter-checkbox-icon.svg";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import {
  FiltersWithoutDateRange,
  TransactionsAllFilterParams,
} from "../../../../models";
import { TransactionsContext } from "../../../../providers";
import Props from "./Props";
import { useStyles } from "./styles";

const FilterCheckboxPopup: FC<Props> = ({ options, column }: Props) => {
  const classes = useStyles();
  const { setSearchFilters, searchFilters, setTableLoading } =
    useContext(TransactionsContext);
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;

  const handleChange = (event: any) => {
    setTableLoading(true);
    setSearchFilters((prevFilters) => {
      const current = { ...prevFilters };
      const columnFilter =
        current[column as keyof FiltersWithoutDateRange] || [];
      const index = columnFilter.indexOf(event.target.value);

      if (index !== -1) {
        columnFilter.splice(index, 1);
      } else {
        columnFilter.push(event.target.value);
      }

      //@ts-ignore
      current[column as keyof TransactionsAllFilterParams] = columnFilter;

      return current;
    });
    setTableLoading(false);
  };

  return (
    <>
      <div onClick={handleClick} className={classes.searchIcon}>
        <FilterCheckboxIcon aria-describedby={id} type="button" />
      </div>
      <Dialog id={id} open={open} onClose={handleClick}>
        <DialogTitle>
          <Typography variant={"h3"}>
            {dictionary.moneyForm.chooseFilerCheckbox(column)}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: 8 }}>
          {options.map((option) => (
            <div key={option.id} className={classes.menuItem}>
              <FormControlLabel
                label={option.label}
                control={
                  <OcietyCheckbox
                    value={option.id}
                    checked={Boolean(
                      searchFilters[
                        column as keyof FiltersWithoutDateRange
                      ]?.find((i) => i === option.id)
                    )}
                    onChange={handleChange}
                  />
                }
              />
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FilterCheckboxPopup;
