import {
  FORGOT_PASSWORD_CLEAR_ERROR,
  FORGOT_PASSWORD_FULFILLED,
  FORGOT_PASSWORD_PENDING,
  FORGOT_PASSWORD_REJECTED,
  FORGOT_PASSWORD_RESET,
  ForgotPasswordActionTypes,
  ForgotPasswordMethod,
} from "./types";

interface ForgotPasswordState {
  loading: boolean;
  error?: string;
  selectMethod: boolean;
  success: boolean;
  method: ForgotPasswordMethod | null;
  methods: ForgotPasswordMethod[];
  email: string;
  token: string | undefined;
}

const defaultState: ForgotPasswordState = {
  loading: false,
  success: false,
  method: null,
  selectMethod: false,
  methods: [],
  email: "",
  token: undefined,
};

export default (
  state: ForgotPasswordState = defaultState,
  action: ForgotPasswordActionTypes
): ForgotPasswordState => {
  switch (action.type) {
    case FORGOT_PASSWORD_PENDING:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case FORGOT_PASSWORD_FULFILLED:
      const { method, success, methods, email, token } = action;

      return {
        ...state,
        loading: false,
        success,
        method,
        methods,
        email,
        token,
      };
    case FORGOT_PASSWORD_REJECTED:
      return {
        ...state,
        error: action.error,
      };
    case FORGOT_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case FORGOT_PASSWORD_RESET:
      return defaultState;

    default:
      return state;
  }
};
