import { Grid } from "@material-ui/core";
import React, { FC } from "react";

import { IPromotion } from "../../../../Promotions/types/IPromotion";
import PromotionsGridRow from "./components/PromotionsGridRow";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionsGrid: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    promotions,
    onEditPromotion,
    onDeletePromotion,
    onDuplicatePromotion,
  } = props;

  const renderRows = () =>
    promotions?.map((promotion: IPromotion) => (
      <PromotionsGridRow
        promotion={promotion}
        key={promotion.id}
        onDuplicateClick={onDuplicatePromotion}
        onDeleteClick={onDeletePromotion}
        onEditClick={onEditPromotion}
      />
    ));

  return (
    <div className={classes.root}>
      <Grid className={classes.gridContainer} container xs={12}>
        {renderRows()}
        <Grid item lg={9} />
      </Grid>
    </div>
  );
};

export default PromotionsGrid;
