import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { loginasadmin as logIn } from "../../../store/auth/actions";
import { useRestApi } from "../../hooks/useRestApi";
import { GET_ADMINS } from "./api";
import { useStyles } from "./styles";

let timer: any;

const LoginAsVenueAdmin: FC = () => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [input, setInput] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const [{ data, loading }, getAdmins]: any = useRestApi<any>(
    GET_ADMINS,
    "POST"
  );

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      getAdmins({
        email: input,
      }).catch((e: any) => console.error(e));
    }, 500);
  }, [getAdmins, input]);

  const handleAutocompleteChange = async (e: any, admin: any) => {
    if (admin) {
      await dispatch(
        logIn({
          username: admin.email.trim(),
          password: "password",
          rememberMe: false,
        })
      );
      setTimeout(() => (window.location.pathname = "/"), 1);
      console.log(admin);
    }
  };

  return (
    <Autocomplete
      id="admins-autocomplete"
      loading={loading}
      fullWidth
      options={data || []}
      getOptionLabel={(admin: any) =>
        admin.email + " | " + admin.company + " | " + admin.title
      }
      onChange={handleAutocompleteChange}
      onInputChange={(e, v) => {
        setInput(v);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Login Under Any Venue Admin By Email " />
      )}
    />
  );
};

export default LoginAsVenueAdmin;
