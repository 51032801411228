import { Box, FormControl, Typography } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import OcietyRadioGroup from "../../../../common/components/OcietyRadioGroup";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { ReactComponent as InfoTooltipIcon } from "../../../../common/svg/icons/info-tooltip.svg";
import dictionary from "../../../../i18n/en_US/dictionary";
import { CartState } from "../../../../store/cart/reducer";
import { AppState } from "../../../../store/rootReducer";
import { PackageGroup } from "../../models";
import PackageCard from "../PackageCard";
import {
  getVenueBoostPriceLabel,
  VenueBoostPrices,
} from "./getVenueBoostPriceLabel";
import Props from "./Props";
import { useStyles } from "./styles";

const PackageCardGroup: FC<Props> = (props: Props) => {
  const {
    packagesGroup,
    activePackageId,
    enablePackage,
    disablePackage,
    currentUsagesLimit,
    toggleCart,
  } = props;
  const classes = useStyles();
  const venueId = useCurrentVenueId();
  const cartState = useSelector<AppState, CartState>((state) => state.cart);
  const mainPackage = packagesGroup.find((pack) => pack.mainInGroup);
  const [selectedPackageId, setSelectedPackageId] = useState(
    activePackageId || mainPackage?.id
  );

  useEffect(() => {
    setSelectedPackageId(activePackageId || mainPackage?.id);
  }, [activePackageId, mainPackage?.id, venueId]);

  if (!mainPackage) {
    return null;
  }

  const { description, price, cancelable, name, group, renewable } =
    mainPackage;
  const active = !!activePackageId;
  const inCart = !!cartState[venueId]?.some((entry) =>
    packagesGroup.map((pack) => pack.id).includes(entry)
  );

  const enableSelectedPackage = () => {
    if (selectedPackageId) {
      enablePackage(selectedPackageId);
    }
  };

  const disableSelectedPackage = () => {
    if (selectedPackageId) {
      disablePackage(selectedPackageId);
    }
  };

  const handleChangeOption = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSelectedPackageId(Number(value));
  };

  const renderPriceLabel = (
    packagePrice: number,
    usagesLimit: number | null
  ) => {
    switch (group) {
      case PackageGroup.VENUE_BOOST: {
        return getVenueBoostPriceLabel(
          packagePrice as VenueBoostPrices,
          usagesLimit
        );
      }
      default: {
        return getVenueBoostPriceLabel(
          packagePrice as VenueBoostPrices,
          usagesLimit
        );
      }
    }
  };

  const renderOptions = () => {
    return (
      <FormControl component="fieldset">
        <OcietyRadioGroup
          disabled={active || inCart}
          options={packagesGroup
            // Cheapest at the top
            .sort((a, b) => a.price - b.price)
            .map(({ id, price, usagesLimit }) => ({
              label: renderPriceLabel(price, usagesLimit),
              value: id.toString(),
            }))}
          value={selectedPackageId?.toString()}
          onChange={handleChangeOption}
        />
        <RadioGroup
          aria-label="venue-boost-package"
          name="venue_boost_package"
          value={selectedPackageId}
          onChange={handleChangeOption}>
          {}
        </RadioGroup>
      </FormControl>
    );
  };

  const renderContent = () => (
    <>
      {renderOptions()}
      {currentUsagesLimit ? (
        <Box className={classes.venueBoostBottomContainer}>
          <Typography className={classes.clicksQtyMessage}>
            {dictionary.plan.clicksLeft(currentUsagesLimit)}
          </Typography>
          <Box className={classes.venueBoostNotifyContainer}>
            <InfoTooltipIcon />
            <Typography className={classes.venueBoostNotifyMessage}>
              {dictionary.plan.venueBoostWillNotify}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Typography className={classes.description}>{description}</Typography>
      )}
    </>
  );

  return (
    <PackageCard
      name={name}
      active={active}
      renewable={renewable}
      inCart={inCart}
      toggleCart={toggleCart}
      cancelable={cancelable}
      onDisable={disableSelectedPackage}
      price={price}
      onEnable={enableSelectedPackage}>
      {renderContent()}
    </PackageCard>
  );
};

export default PackageCardGroup;
