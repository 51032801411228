// eslint-disable-next-line max-len
import { requiredTextValidationRulesDefault } from "../../../../config/validation";
import { Form } from "../../../../store/forms/types";

export interface AssignVenueFormSchema {
  email: Record<string, unknown>;
  firstName: Record<string, unknown>;
  lastName: Record<string, unknown>;
  phone: Record<string, unknown>;
  title: Record<string, unknown>;
  company: Record<string, unknown>;
}

export const schema: AssignVenueFormSchema = {
  email: {
    ...requiredTextValidationRulesDefault,
    email: true,
    length: {
      maximum: 128,
    },
  },
  phone: {
    ...requiredTextValidationRulesDefault,
    length: {
      maximum: 128,
      minimum: 6,
    },
  },
  firstName: requiredTextValidationRulesDefault,
  lastName: requiredTextValidationRulesDefault,
  title: requiredTextValidationRulesDefault,
  company: requiredTextValidationRulesDefault,
};

export interface AssignVenueForm extends Form {
  errors: {
    phone?: string;
    email?: string;
    title?: string;
    company?: string;
    firstName?: string;
    lastName?: string;
  };
  values: {
    phone?: string;
    email?: string;
    title?: string;
    company?: string;
    firstName?: string;
    lastName?: string;
  };
}
