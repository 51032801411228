import { Paper, Typography } from "@material-ui/core";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import ButtonSuccess from "../../../../common/components/ButtonSuccess";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import dictionary from "../../../../i18n/en_US/dictionary";
import { useStyles } from "../../styles";
import {
  BenefitsItem,
  GET_ALL_BENEFITS,
  GET_VENUE_BENEFITS,
  GetBenefitsResponse,
  GetVenueBenefitsResponse,
  SAVE_VENUE_BENEFITS,
  SaveVenueBenefitsRequest,
  SaveVenueBenefitsResponse,
  VenueId,
} from "./api";
import VenueBenefitsAccordion from "./components/VenueBenefitsAccordion";
import { AccordionSection, VenueBenefitsSections } from "./sectionsMapping";

const VenueBenefits: FC = () => {
  const venueId = useCurrentVenueId();
  const { showAlert } = useContext(AlertContext);
  const [
    { data: allBenefits, loading: gettingBenefitsLoading },
    getAllBenefits,
  ] = useRestApi<GetBenefitsResponse[]>(GET_ALL_BENEFITS, "GET");
  const [{ data: benefits, loading: gettingLoading }, getVenueBenefits] =
    useRestApi<GetVenueBenefitsResponse>(GET_VENUE_BENEFITS(venueId), "GET");
  const [{ loading: savingLoading }, saveBenefits] = useRestApi<
    SaveVenueBenefitsResponse,
    SaveVenueBenefitsRequest
  >(SAVE_VENUE_BENEFITS(venueId), "PUT");
  const classes = useStyles();
  const [venueBenefits, setVenueBenefits] = useState<VenueId[]>([]);

  useEffect(() => {
    getVenueBenefits().catch((e) => {
      console.log("e -->>> ", e);
    });
  }, [getVenueBenefits]);

  useEffect(() => {
    getAllBenefits().catch((e) => {
      console.log("e -->>> ", e);
    });
  }, [getAllBenefits]);

  useEffect(() => {
    if (benefits) {
      const venueBenefitsIds = benefits.map((item) => item.id);
      setVenueBenefits(venueBenefitsIds);
    }
  }, [benefits]);

  const parseResponseIntoSections = (sections: VenueBenefitsSections) => {
    if (!allBenefits) {
      return;
    }

    allBenefits.map((benefit) => {
      switch (benefit.group) {
        case "General":
          sections.basic.content.push(benefit);
          break;
        case "Meals":
          sections.meals.content.push(benefit);
          break;
        case "Additional":
          sections.additional.content.push(benefit);
          break;
        default:
          sections.basic.content.push(benefit);
      }
    });

    return sections;
  };

  const prepareSections = (): AccordionSection[] => {
    if (!allBenefits) {
      return [];
    }

    const sections: VenueBenefitsSections = {
      basic: {
        title: dictionary.venues.venueForm.benefitsBasicSectionTitle,
        content: [],
      },
      meals: {
        title: dictionary.venues.venueForm.benefitsMealsSectionTitle,
        content: [],
      },
      additional: {
        title: dictionary.venues.venueForm.benefitsAdditionalSectionTitle,
        content: [],
      },
    };

    parseResponseIntoSections(sections);

    return Object.values(sections);
  };

  const handleChangeBenefit = useCallback(
    (benefit: BenefitsItem) => {
      const currentIndex = venueBenefits.indexOf(benefit.id);
      const newBenefits = [...venueBenefits];

      if (currentIndex === -1) {
        newBenefits.push(benefit.id);
      } else {
        newBenefits.splice(currentIndex, 1);
      }

      setVenueBenefits(newBenefits);
    },
    [venueBenefits]
  );

  const handlePressSaveVenueBenefits = async () => {
    if (!venueBenefits) {
      return;
    }

    try {
      const res = await saveBenefits(venueBenefits);

      if (res?.success) {
        showAlert(dictionary.venues.venueForm.benefitsSaveSuccess, "success");
      }
    } catch (e) {
      showAlert(dictionary.venues.venueForm.benefitsSaveSuccess, "error");
    }
  };

  return (
    <Paper className={classes.section}>
      <Typography variant={"h2"} className={classes.sectionTitle}>
        {dictionary.venues.venueForm.benefitsTitle}
      </Typography>
      <VenueBenefitsAccordion
        sections={prepareSections()}
        loading={gettingLoading || savingLoading || gettingBenefitsLoading}
        onChangeBenefit={handleChangeBenefit}
        benefitsState={venueBenefits}
      />
      <ButtonSuccess
        onClick={handlePressSaveVenueBenefits}
        className={classes.saveButton}>
        {dictionary.venues.venueForm.btnSave}
      </ButtonSuccess>
    </Paper>
  );
};

export default VenueBenefits;
