import { Venue } from "../../../common/models";
import {
  CHANGE_MAIN_PHOTO,
  DELETE_VENUE_PHOTO,
  DELETE_VENUE_VIDEO,
  GET_VENUE_FULFILLED,
  GET_VENUE_PENDING,
  GET_VENUE_REJECTED,
  SAVE_VENUE_FULFILLED,
  SAVE_VENUE_PENDING,
  SAVE_VENUE_REJECTED,
  SET_VENUE_PHOTO,
  SET_VENUE_VIDEO,
  VenueActionTypes,
} from "./types";

interface VenueState {
  venue?: Venue;
  error?: string;
  loading: boolean;
}

const defaultState: VenueState = {
  venue: undefined,
  error: undefined,
  loading: false,
};

export default (
  state: VenueState = defaultState,
  action: VenueActionTypes
): VenueState => {
  switch (action.type) {
    case GET_VENUE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case SAVE_VENUE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case SAVE_VENUE_FULFILLED:
      const newState = {
        ...state,
        loading: false,
      };

      if (newState.venue) {
        newState.venue.pendingChanges = action.pendingChanges;
      }

      return newState;
    case SAVE_VENUE_REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_VENUE_FULFILLED:
      return {
        ...state,
        venue: action.venue,
        loading: false,
      };
    case GET_VENUE_REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SET_VENUE_PHOTO:
      if (state.venue) {
        return {
          ...state,
          venue: {
            ...state.venue,
            photos: [...state.venue.photos, action.photo],
          },
        };
      } else {
        return state;
      }
    case DELETE_VENUE_PHOTO:
      if (state.venue) {
        return {
          ...state,
          venue: {
            ...state.venue,
            photos: state.venue.photos.filter(
              (photo) => photo.id !== action.photoId
            ),
          },
        };
      } else {
        return state;
      }
    case DELETE_VENUE_VIDEO:
      if (state.venue) {
        return {
          ...state,
          venue: {
            ...state.venue,
            videos: state.venue.videos.filter(
              (video) => video.id !== action.videoId
            ),
          },
        };
      } else {
        return state;
      }
    case SET_VENUE_VIDEO:
      if (state.venue) {
        return {
          ...state,
          venue: {
            ...state.venue,
            videos: [...state.venue.videos, action.video],
          },
        };
      } else {
        return state;
      }
    case CHANGE_MAIN_PHOTO:
      if (state.venue) {
        return {
          ...state,
          venue: {
            ...state.venue,
            photos: state.venue.photos.map((item) => {
              const photo = item;
              item.isMainPhoto = photo.id === action.photoId;
              return photo;
            }),
            mainPhotoId: action.photoId,
          },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};
