import { Paper, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonSuccess from "../../../../common/components/ButtonSuccess";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { useCurrentVenueId } from "../../../../common/hooks/useCurrentVenueId";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { VenuePhoto, VenueVideo } from "../../../../common/models";
import dictionary from "../../../../i18n/en_US/dictionary";
import { AppState } from "../../../../store/rootReducer";
import {
  SAVE_VENUE_PENDING_MEDIA,
  SaveVenuePendingMediaRequest,
} from "../../../VenuesChangesPending/components/VenuesChangesPendingTable/api";
import { getVenue, setVenuePhoto, setVenueVideo } from "../../ducks/actions";
import { useStyles } from "../../styles";
import VenueMediaCarousel from "./components/VenueMediaCarousel";
import VenueMediaUploader from "./components/VenueMediaUploader";

const VenueMediaContent: FC = () => {
  const { venue } = useSelector<AppState, AppState>((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  const onUploadVideo = (video: VenueVideo) => {
    dispatch(setVenueVideo(video));
  };
  const venueId = useCurrentVenueId();

  const [{ loading: saveMediaLoading }, savePendingMedia] = useRestApi<
    any,
    SaveVenuePendingMediaRequest
  >(SAVE_VENUE_PENDING_MEDIA(venueId), "POST");

  const hasPendingMedia = () => {
    const photos = venue.venue?.photos.find(
      (item: any) => item.active === false
    );
    const videos = venue.venue?.videos.find(
      (item: any) => item.active === false
    );

    return photos || videos;
  };

  const onUploadPhoto = (photo: VenuePhoto) => {
    const venueId = venue.venue?.id;
    const venuePhotosCount = venue.venue?.photos.length;
    dispatch(setVenuePhoto(photo));
    if (
      venueId &&
      typeof venuePhotosCount === "number" &&
      venuePhotosCount === 0
    ) {
      dispatch(getVenue(venueId));
    }
  };

  const handleSavePendingMedia = async () => {
    await savePendingMedia({ mediaType: "media" });
    dispatch(getVenue(venueId));
  };

  const getVideos = () => {
    const sortedPhotos = venue.venue?.videos.sort((video) =>
      !video.active ? -1 : 1
    );
    return hasPendingMedia() ? sortedPhotos : venue.venue?.videos || [];
  };

  const getPhotos = () => {
    const sortedPhotos = venue.venue?.photos.sort((photo) =>
      !photo.active ? -1 : 1
    );
    return hasPendingMedia() ? sortedPhotos : venue.venue?.photos || [];
  };

  return (
    <Paper className={classes.section}>
      {venue.venue?.photos.length || venue.venue?.videos.length ? (
        <div>
          <Typography variant={"h2"} style={{ marginBottom: 32 }}>
            {dictionary.venues.venueForm.mediaContentTitle}
          </Typography>
          <div className={classes.tabsWrapper}>
            {(venue.loading || saveMediaLoading) && (
              <LoadingIndicator withMask />
            )}
            <div className={classes.uploaderWrapper}>
              <VenueMediaUploader
                tag={"interior"}
                isFileUploaded={
                  !!(venue.venue?.photos.length || venue.venue?.videos.length)
                }
                onUploadVenuePhoto={onUploadPhoto}
                onUploadVenueVideo={onUploadVideo}
              />
            </div>
            <div className={classes.sliderWrapper}>
              <VenueMediaCarousel
                venuePhotos={getPhotos()}
                venueVideos={getVideos()}
              />
            </div>
          </div>
          {hasPendingMedia() && (
            <div className={classes.saveButton}>
              <ButtonSuccess onClick={handleSavePendingMedia}>
                {dictionary.venues.venueForm.save}
              </ButtonSuccess>
            </div>
          )}
        </div>
      ) : (
        <div className={classes.tabsWrapper}>
          {venue.loading && <LoadingIndicator withMask />}
          <VenueMediaUploader
            tag={"interior"}
            isFileUploaded={!!venue.venue?.photos.length}
            uploaderTitle={dictionary.venues.mediaContent.uploadVenueMedia}
            onUploadVenuePhoto={onUploadPhoto}
            onUploadVenueVideo={onUploadVideo}
            label={dictionary.venues.mediaContent.maxMediaSize}
          />
        </div>
      )}
    </Paper>
  );
};

export default VenueMediaContent;
