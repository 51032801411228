import { Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import { ReactComponent as InfoTooltip } from "../../../../common/svg/icons/info-tooltip.svg";
import dictionary from "../../../../i18n/en_US/dictionary";
import { IPromotion } from "../../types/IPromotion";
import PaginationBtnActions from "./components/PaginationBtnActions";
import PaginationPage from "./components/PaginationPage";
import PromotionsGridRow from "./components/PromotionsGridRow";
import Props from "./Props";
import { useStyles } from "./styles";

const PromotionsGrid: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    promotions,
    setPage,
    page,
    count,
    onChangePromotionStatus,
    onEditPromotion,
    onDeletePromotion,
    onDuplicatePromotion,
    refresh,
    isSuperAdminGrid,
    rowsPerPage,
  } = props;

  const renderRows = () =>
    promotions?.map((promotion: IPromotion) => (
      <PromotionsGridRow
        promotion={promotion}
        key={promotion.id}
        onChangeStatus={(promotionId, status, venueId) =>
          onChangePromotionStatus(promotionId, status, venueId)
        }
        onDuplicateClick={onDuplicatePromotion}
        onDeleteClick={onDeletePromotion}
        isSuperAdminGrid={isSuperAdminGrid}
        onEditClick={onEditPromotion}
        refresh={refresh}
      />
    ));

  const rowStart = page === 1 ? 1 : (page - 1) * rowsPerPage + 1;
  const rowEnd = rowStart + promotions.length - 1;

  return (
    <div>
      <Grid container xs={12} component={Paper} className={classes.root}>
        <Grid
          className={classes.gridContainer}
          container
          xs={12}
          wrap={"nowrap"}>
          <Grid
            item
            className={clsx(
              classes.rowItem,
              classes.rowItemFirst,
              classes.rowItem1
            )}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.deals.tableHeaders.photo}
            </Typography>
          </Grid>
          <Grid item className={clsx(classes.rowItem, classes.rowItem2)}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.deals.tableHeaders.name}
            </Typography>
          </Grid>
          {!isSuperAdminGrid && (
            <Grid
              item
              className={clsx(
                classes.rowItem,
                classes.rowItemCenter,
                classes.rowItem1
              )}>
              <Typography
                variant={"body2"}
                className={classes.headerRowItemText}>
                {dictionary.deals.tableHeaders.primary}
              </Typography>
              <Tooltip
                title={dictionary.deals.primaryDealTooltip}
                placement="top-start">
                <div className={classes.iconWrapper}>
                  <InfoTooltip />
                </div>
              </Tooltip>
            </Grid>
          )}
          {isSuperAdminGrid && (
            <Grid item className={clsx(classes.rowItem, classes.rowItem1)}>
              <Typography
                variant={"body2"}
                className={classes.headerRowItemText}>
                {dictionary.deals.tableHeaders.venue}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            className={clsx(
              classes.rowItem,
              classes.rowItemCenter,
              classes.rowItem1
            )}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.deals.tableHeaders.views}
            </Typography>
          </Grid>
          <Grid
            item
            className={clsx(
              classes.rowItem,
              classes.rowItemCenter,
              classes.rowItem1
            )}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.deals.tableHeaders.redeemed}
            </Typography>
          </Grid>
          <Grid
            item
            className={clsx(
              classes.rowItem,
              classes.rowItemCenter,
              classes.rowItem1
            )}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.deals.tableHeaders.redeemedToday}
            </Typography>
            <Tooltip
              title={dictionary.deals.appliesToPlanetPromoOnly}
              placement="top-start">
              <div className={classes.iconWrapper}>
                <InfoTooltip />
              </div>
            </Tooltip>
          </Grid>
          <Grid
            item
            className={clsx(
              classes.rowItem,
              classes.rowItemCenter,
              classes.rowItem1
            )}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.deals.tableHeaders.purchasedToday}
            </Typography>
            <Tooltip
              title={dictionary.deals.appliesToPlanetPromoOnly}
              placement="top-start">
              <div className={classes.iconWrapper}>
                <InfoTooltip />
              </div>
            </Tooltip>
          </Grid>
          <Grid
            item
            className={clsx(
              classes.rowItem,
              classes.rowItemCenter,
              classes.rowItem2
            )}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.deals.tableHeaders.activeHours}
            </Typography>
            <Tooltip
              title={dictionary.deals.activeHoursTooltip}
              placement="top-start">
              <div className={classes.iconWrapper}>
                <InfoTooltip />
              </div>
            </Tooltip>
          </Grid>
          <Grid
            item
            className={clsx(
              classes.rowItem,
              classes.rowItemCenter,
              classes.rowItem1
            )}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.deals.tableHeaders.startDate}
            </Typography>
          </Grid>
          <Grid
            item
            className={clsx(
              classes.rowItem,
              classes.rowItemCenter,
              classes.rowItem2
            )}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.deals.tableHeaders.expirationDate}
            </Typography>
          </Grid>
          <Grid
            item
            className={clsx(
              classes.rowItem,
              classes.rowItemCenter,
              classes.rowItem2
            )}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {dictionary.deals.tableHeaders.status}
            </Typography>
          </Grid>
          <Grid
            item
            className={clsx(
              classes.rowItem,
              classes.rowItemCenter,
              classes.rowItem1
            )}>
            <Typography variant={"body2"} className={classes.headerRowItemText}>
              {isSuperAdminGrid ? "" : dictionary.deals.tableHeaders.actions}
            </Typography>
          </Grid>
        </Grid>
        {renderRows()}
        <Grid item lg={9} />
        <Grid item lg={3}>
          <Grid container direction="row">
            <Grid item xs={6} className={classes.textAlignRight}>
              <PaginationPage
                count={count}
                rowStart={rowStart}
                rowEnd={rowEnd}
              />
            </Grid>
            <Grid item xs={6} className={classes.textAlignRight}>
              <PaginationBtnActions
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={(event, page) => {
                  setPage(page);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PromotionsGrid;
