import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { ChangeEvent, FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import OcietyNavLink from "../../../../common/components/OcietyNavLink";
import TextMessage from "../../../../common/components/TextMessage";
// eslint-disable-next-line max-len
import { useSubmitFormOnEnter } from "../../../../common/hooks/useSubmitFormOnEnter";
import {
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
  TERMS_OF_SALE,
} from "../../../../config";
import { Route } from "../../../../config/router";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  login as logIn,
  loginClearError,
  toggleRememberMe,
} from "../../../../store/auth/actions";
import { setAndValidate, validateForm } from "../../../../store/forms/actions";
import { FormFieldKey } from "../../../../store/forms/types";
import { AppState } from "../../../../store/rootReducer";
import { schema } from "./form";
import Props from "./Props";

const LoginForm: FC<Props> = (props: Props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { forms, auth }: AppState = useSelector((state: AppState) => state);
  const form = forms.login;
  const [email, setEmail] = React.useState<string>("");

  useEffect(() => {
    // To clear errors on component mounting
    dispatch(loginClearError());

    return () => {
      // To clear errors on component unmounting
      dispatch(loginClearError());
    };
  }, [dispatch]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;
    dispatch(loginClearError());
    if (key === "email") {
      dispatch(
        setAndValidate("login", key as FormFieldKey, value.trim(), schema)
      );
      setEmail(value);
      return;
    }
    dispatch(setAndValidate("login", key as FormFieldKey, value, schema));
  };

  const handleSignIn = async () => {
    const { email, password } = form.values;

    if (!email || !password) {
      dispatch(validateForm("login", schema));
      return;
    }

    dispatch(
      logIn({
        username: email.trim(),
        password,
        rememberMe: auth.rememberMe,
      })
    );
  };

  useSubmitFormOnEnter(handleSignIn);

  return (
    <>
      {auth.error && (
        <Box className={classes.errorMessageWrapper}>
          <Box className={classes.errorMessageContent}>
            <TextMessage className={classes.errorMessage} type="error">
              {auth.error}
            </TextMessage>
          </Box>
        </Box>
      )}
      <div>
        <TextField
          className={classes.textField}
          required
          error={!!form.errors.email}
          fullWidth
          helperText={form.errors.email}
          label={dictionary.auth.emailFieldLabel}
          name="email"
          onChange={handleChange}
          type="text"
          value={email || ""}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          required
          error={!!form.errors.password}
          fullWidth
          helperText={form.errors.password}
          label={dictionary.auth.passwordFieldLabel}
          name="password"
          onChange={handleChange}
          type="password"
          value={form.values.password || ""}
          variant="outlined"
        />
      </div>
      <div className={classes.actionsWrapper}>
        <div className={classes.actionsSecondaryContainer}>
          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  checked: classes.checkboxChecked,
                }}
                color="default"
                checked={auth.rememberMe}
                onChange={() => dispatch(toggleRememberMe())}
                name="rememberMe"
              />
            }
            label={
              <Typography className={classes.checkboxLabel}>
                {dictionary.auth.rememberMe}
              </Typography>
            }
          />
          <OcietyNavLink
            className={classes.forgotPasswordLink}
            linkTextClassName={classes.forgotPasswordLinkText}
            to={Route.ForgotPassword}>
            {dictionary.auth.forgotPasswordLink}
          </OcietyNavLink>
        </div>
        <ButtonPrimary
          className={classes.signInButton}
          disabled={!form.isValid || !!auth.error}
          fullWidth
          onClick={handleSignIn}>
          {dictionary.auth.signInButtonText}
        </ButtonPrimary>
        <div className={classes.registerActionWrapper}>
          <Typography className={classes.registerLabel}>
            {dictionary.auth.registerQuestion}
          </Typography>
          <OcietyNavLink
            to={Route.Venues}
            className={classes.registerLink}
            linkTextClassName={classes.registerLinkText}>
            {dictionary.auth.registerLink}
          </OcietyNavLink>
        </div>
        <div style={{ textAlign: "center", marginTop: 24 }}>
          <Typography className={classes.bySigningOrLogging}>
            {dictionary.auth.bySigningOrLogging}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <OcietyNavLink
              href={TERMS_AND_CONDITIONS_URL}
              className={clsx(classes.registerLink, classes.marginHorizontal0)}
              linkTextClassName={classes.registerLinkTextGray}>
              {dictionary.footer.termsOfUse}
            </OcietyNavLink>
            <Typography
              className={clsx(classes.registerLabel, classes.marginRight5)}>
              ,
            </Typography>
            <OcietyNavLink
              href={PRIVACY_POLICY_URL}
              className={clsx(classes.registerLink, classes.marginHorizontal0)}
              linkTextClassName={classes.registerLinkTextGray}>
              {dictionary.footer.privacy}
            </OcietyNavLink>
            <Typography
              className={clsx(
                classes.registerLabel,
                classes.marginHorizontal5
              )}>
              and
            </Typography>
            <OcietyNavLink
              href={TERMS_OF_SALE}
              className={clsx(classes.registerLink, classes.marginHorizontal0)}
              linkTextClassName={classes.registerLinkTextGray}>
              {dictionary.footer.termsOfSale}
            </OcietyNavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
