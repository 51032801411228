import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { colors } from "../../../theme/colors";
import { OcietyTheme } from "../../models";

export type ClassNames =
  | "root"
  | "userName"
  | "caretIcon"
  | "toggler"
  | "link"
  | "linkText"
  | "logoutButton"
  | "logoutText"
  | "avatar"
  | "avatarWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      avatar: {
        height: "auto",
        maxHeight: "100%",
      },
      avatarWrapper: {
        borderRadius: 4,
        overflow: "hidden",
        height: 40,
        width: 40,
        minWidth: 40,
        [theme.breakpoints.up("sm")]: {
          marginRight: theme.spacing(20 / 8),
        },
      },
      root: {},
      userName: {
        fontWeight: "bold",
      },
      toggler: {
        [theme.breakpoints.down("sm")]: {
          marginLeft: theme.spacing(3),
        },
        [theme.breakpoints.up("sm")]: {
          marginLeft: theme.spacing(5),
        },
        cursor: "pointer",
        height: "100%",
      },
      caretIcon: {
        "& > g": {
          fill: theme.palette.text.primary,
        },
      },
      link: {
        padding: 20,
        "& svg > path": {
          fill: colors.red.primary,
        },
      },
      linkText: {
        marginLeft: 20,
        fontWeight: "bold",
      },
      logoutButton: {
        display: "flex",
        flexDirection: "row",
        padding: 20,
        "& svg > path": {
          fill: colors.red.primary,
        },
        backgroundColor: colors.white.primary,
        cursor: "pointer",
      },
      logoutText: {
        fontSize: "14px !important",
        lineHeight: "22px",
        fontWeight: 700,
        marginLeft: 20,
        "&:hover": {
          textDecoration: "underline",
        },
      },
    })
);
