import React, { FC, useContext, useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ChartContainer from "../../../../../../common/components/ChartContainer";
import ChartTooltip from "../../../../../../common/components/ChartTooltip";
import { VenueReportingContext } from "../../../../../../common/components/VenueReportingContextProvider";
import { useRequestByPeriod } from "../../../../../../common/hooks/useRequestByPeriod";
import { useVenueHasPackage } from "../../../../../../common/hooks/useVenueHasPacakage";
import dictionary from "../../../../../../i18n/en_US/dictionary";
import { PackageUID } from "../../../../../../store/currentVenue/types";
import { colors } from "../../../../../../theme/colors";
import {
  LineDefaultProps,
  XAxisDefaultProps,
  YAxisDefaultProps,
} from "../../../../defaultProps";
import {
  GET_VENUE_IMPRESSIONS_IN_SEARCH,
  GetVenueSearchImpressionsResponse,
  ImpressionsData,
} from "./api";
import { chartDataMock } from "./mock";

const VenueImpressionsInSearch: FC = () => {
  const [chartData, setChartData] = useState(chartDataMock);
  const { filters, handlePackageName } = useContext(VenueReportingContext);
  const hasPackage = useVenueHasPackage(PackageUID.CUSTOMER);

  const { response, loading } =
    useRequestByPeriod<GetVenueSearchImpressionsResponse>(
      GET_VENUE_IMPRESSIONS_IN_SEARCH,
      filters,
      hasPackage
    );

  const prepareChartData = (stats: ImpressionsData[]) => {
    setChartData(
      stats.map((impressionsData) => ({
        month: impressionsData.month.slice(0, 3),
        impressionsCount: impressionsData.impressionsCount,
      }))
    );
  };

  useEffect(() => {
    if (response) {
      prepareChartData(response.stats);
    }
    if (!hasPackage) {
      setChartData(chartDataMock);
    }
  }, [response, hasPackage]);

  const isChartEmpty = !chartData.some((data) => data.impressionsCount !== 0);

  return (
    <ChartContainer
      locked={!hasPackage}
      loading={loading}
      isChartEmpty={isChartEmpty}
      title={dictionary.reporting.venueImpressionsSearchTitle}
      chartType={"line-with-y-axis"}
      packageName={handlePackageName(PackageUID.CUSTOMER)}>
      <LineChart data={chartData}>
        <CartesianGrid
          strokeDasharray="3 3"
          strokeOpacity={0.6}
          color={colors.grey.light}
          vertical={false}
        />
        <Line
          {...LineDefaultProps}
          dataKey={"impressionsCount"}
          unit={dictionary.reporting.venueImpressionsSearchUnit}
        />
        <XAxis {...XAxisDefaultProps} dataKey="month" />
        <YAxis {...YAxisDefaultProps} />
        <Tooltip content={<ChartTooltip />} />
      </LineChart>
    </ChartContainer>
  );
};

export default VenueImpressionsInSearch;
