import { Paper } from "@material-ui/core";
import React, { FC, useContext } from "react";

import VenueReportingContextProvider, {
  VenueReportingContext,
} from "../../../../../common/components/VenueReportingContextProvider";
import UserReviewsTable from "./components/UserReviewsTable";
import { useStyles } from "./styles";

const UserReviews: FC = () => {
  const classes = useStyles();
  const { period } = useContext(VenueReportingContext);

  return (
    <>
      <VenueReportingContextProvider>
        <div className={classes.root}>
          <Paper className={classes.content}>
            <div className={classes.tableContainer}>
              <UserReviewsTable period={period} />
            </div>
          </Paper>
        </div>
      </VenueReportingContextProvider>
    </>
  );
};

export default UserReviews;
