import { Dialog, DialogTitle, IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC, useEffect, useState } from "react";

import ButtonBlack from "../../../../../../../common/components/ButtonBlack";
import ButtonCancel from "../../../../../../../common/components/ButtonCancel";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const CriticReviewResponseDialog: FC<Props> = (props: Props) => {
  const { open, onClose, title, updateReview, message } = props;
  const classes = useStyles();
  const [text, setText] = useState<string>(message);
  const updateAdminResponse = async () => {
    updateReview(text);
    onClose();
  };

  useEffect(() => {
    setText(message);
  }, [message]);

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <DialogTitle className={classes.titleWrapper} disableTypography>
        <p className={classes.title}>{title}</p>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className={classes.content} style={{}}>
        <TextField
          className={classes.input}
          fullWidth
          multiline={true}
          rows={4}
          name="comment"
          required
          label={dictionary.reviews.typeYourResponseHereLabel}
          placeholder={dictionary.reviews.typeYourResponseHereLabel}
          type="text"
          variant="outlined"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />

        <div className={classes.actions}>
          <ButtonCancel className={classes.cancel}>Cancel</ButtonCancel>
          <ButtonBlack
            disabled={text.length === 0}
            className={classes.buttonBlack}
            onClick={async () => await updateAdminResponse()}>
            {dictionary.reviews.send}
          </ButtonBlack>
        </div>
      </div>
    </Dialog>
  );
};

export default CriticReviewResponseDialog;
