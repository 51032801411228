import { http } from "../../../../../../../../../config/api";
import IPromotionCategory from "../../../../../../../types/interfaces/IPromotionCategory.interface";
import { PromotionType } from "../../../../../../enums";

const promoTypeToUrl = {
  [PromotionType.FOOD]: "promotions/categories/food",
  [PromotionType.DRINK]: "promotions/categories/drink",
  [PromotionType.ORDER]: "promotions/categories/order",
};

export const PROMOTION_DIETARY_RESTRICTIONS = "promotions/dietary-restrictions";

const PROMOTIONS_SUB_CATEGORIES_DRINK = (parentId: number) =>
  `promotions/sub-categories/${parentId}`;

export const getPromotionsCategories = async (
  type: PromotionType
): Promise<IPromotionCategory[]> => {
  return http
    .get(promoTypeToUrl[type])
    .then((response) => [...response.data.categories])
    .catch((error) => {
      throw error.response;
    });
};

export const getPromotionsSubCategories = async (
  parentId: number
): Promise<IPromotionCategory[]> => {
  return http
    .get(PROMOTIONS_SUB_CATEGORIES_DRINK(parentId))
    .then((response) => [...response.data.categories])
    .catch((error) => {
      throw error.response;
    });
};
