import { GET_DATA_WITH_PAGINATION } from "../../common/api";
import { ShowAlertActionType } from "../../common/components/Alert/AlertContextProvider";
import { http } from "../../config/api";
import dictionary from "../../i18n/en_US/dictionary";
import {
  AssignVenueRequest,
  AssignVenueResponse,
  AssignVenueToUserResponse,
  ClaimVenueConfirmRequest,
  ClaimVenueConfirmResponse,
  ClaimVenueRequest,
  ClaimVenueResponse,
  GetAllVenuesResponse,
  GetVenueAdminsResponse,
  PrefilledClaimVenueRequest,
} from "./models";
const VENUE_CLAIM = "venue/claim";
const VENUE_SAVE_PREFILLED_DATA = "venue/save-prefilled-data";
const VENUE_GET_PREFILLED_DATA = (uuid: string) =>
  `venue/get-prefilled-data/${uuid}`;
const VENUE_CLAIM_CONFIRM = "venue/claim/confirm";
export const UPLOAD_CLAIM_ATTACHMENT = (venueId: number) =>
  `/venue/${venueId}/upload-doc-claim`;
const PUBLIC_VENUES = `venue/all`;

const VENUE_ADMINS = "venue/admins";
const ASSIGN_VENUE = (venueId: number): string => `venue/${venueId}/owners`;
const ASSIGN_VENUE_TO_USER = (venueId: number, username: string): string =>
  `venue/${venueId}/owners/${username}`;

export const UPDATE_VENUE_MAIN_PROMOTION = (
  venueId: number,
  promotionId: number
): string => `venue/${venueId}/main-promotion/${promotionId}`;

export const claimVenue = async (
  claimVenueRequest: ClaimVenueRequest,
  alertCallback?: ShowAlertActionType
): Promise<ClaimVenueResponse> =>
  http
    .post(VENUE_CLAIM, claimVenueRequest)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      if (alertCallback) {
        if (error.response.data === "Insult word") {
          alertCallback(
            dictionary.notifications.insultingMessageError,
            "error"
          );
        }
      }
      throw error.response;
    });

export const getPrefilledVenueData = async (uuid: string): Promise<any> =>
  http
    .get(VENUE_GET_PREFILLED_DATA(uuid))
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const savePrefilledVenueData = async (
  prefilledDataRequest: PrefilledClaimVenueRequest,
  alertCallback?: ShowAlertActionType
): Promise<string> =>
  http
    .post(VENUE_SAVE_PREFILLED_DATA, prefilledDataRequest)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      if (alertCallback) {
        if (error.response.data === "Insult word") {
          alertCallback(
            dictionary.notifications.insultingMessageError,
            "error"
          );
        }
      }
      throw error.response;
    });

export const claimVenueConfirm = async (
  claimVenueConfirmRequest: ClaimVenueConfirmRequest
): Promise<ClaimVenueConfirmResponse> =>
  http
    .post(VENUE_CLAIM_CONFIRM, claimVenueConfirmRequest)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const claimVenueWithUploadedFile = async (
  claimVenueRequest: FormData,
  venueId: number
): Promise<ClaimVenueResponse> =>
  http
    .post(UPLOAD_CLAIM_ATTACHMENT(venueId), claimVenueRequest)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const getAllVenues = async (
  skip?: number,
  limit?: number,
  search?: string
): Promise<GetAllVenuesResponse> =>
  http
    .get(GET_DATA_WITH_PAGINATION(PUBLIC_VENUES, skip, limit, search))
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const getVenueAdmins = async (): Promise<GetVenueAdminsResponse> =>
  http
    .get(VENUE_ADMINS)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const assignVenueToUser = async (
  venueId: number,
  username: string,
  isWaitlistAdmin?: boolean
): Promise<AssignVenueToUserResponse> =>
  http
    .post(ASSIGN_VENUE_TO_USER(venueId, username), { isWaitlistAdmin })
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const assignVenue = async (
  assignVenueRequest: AssignVenueRequest,
  venueId: number
): Promise<AssignVenueResponse> =>
  http
    .post(ASSIGN_VENUE(venueId), assignVenueRequest)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });
