import { useCallback, useEffect } from "react";

import { useRestApi } from "../../../../common/hooks/useRestApi";
import dictionary from "../../../../i18n/en_US/dictionary";
import { VenueClaimRequestStatus } from "../VenueClaimRequestsTable/api";
import { claimStatuses } from "./common";

const VENUE_CLAIMS_COUNT_URL = "/venue/claims/count";

const getTabHeadingStringByStatus = (status: VenueClaimRequestStatus) => {
  switch (status) {
    case VenueClaimRequestStatus.PENDING:
      return dictionary.claims.tabs.pending;
    case VenueClaimRequestStatus.APPROVED:
      return dictionary.claims.tabs.approved;
    case VenueClaimRequestStatus.REJECTED:
      return dictionary.claims.tabs.rejected;
    default:
      return "";
  }
};
const getTabHeadingLabelWithCount = (
  status: VenueClaimRequestStatus,
  count: number
) => `${getTabHeadingStringByStatus(status)} (${count})`;

export const useTabsHeadings = () => {
  const [{ data }, getVenueClaimsCounterList] = useRestApi<{
    count: { pending: number; approved: number; rejected: number };
  }>(VENUE_CLAIMS_COUNT_URL, "GET");

  const getCountByStatus = useCallback(
    (status: VenueClaimRequestStatus) => {
      if (status === VenueClaimRequestStatus.PENDING) {
        return data?.count.pending || 0;
      }
      if (status === VenueClaimRequestStatus.APPROVED) {
        return data?.count.approved || 0;
      }
      if (status === VenueClaimRequestStatus.REJECTED) {
        return data?.count?.rejected || 0;
      }
      return 0;
    },
    [data]
  );

  useEffect(() => {
    getVenueClaimsCounterList().catch((e) => console.error(e));
  }, [getVenueClaimsCounterList]);

  return claimStatuses.map((status) =>
    getTabHeadingLabelWithCount(status, getCountByStatus(status))
  );
};
