import { Grid, Paper, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";

import { AlertContext } from "../../../../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../../../../common/components/ButtonBlack";
import DialogPopup from "../../../../../../../common/components/dialogs/DialogSimpleConfirm";
import ImageFullScreen from "../../../../../../../common/components/ImageFullScreen";
import LoadingIndicator from "../../../../../../../common/components/LoadingIndicator";
import { useCurrentVenueId } from "../../../../../../../common/hooks/useCurrentVenueId";
import { useVenueHasPackage } from "../../../../../../../common/hooks/useVenueHasPacakage";
import { ReactComponent as IconFile } from "../../../../../../../common/svg/icons/file.svg";
import { ReactComponent as IconLockUnlocked } from "../../../../../../../common/svg/icons/lock-unlocked.svg";
import { getRouteWithSlash, Route } from "../../../../../../../config/router";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { PackageUID } from "../../../../../../../store/currentVenue/types";
import {
  formatHoursToMeridiemWithoutPostMeridiem,
  formatHoursToPostMeridiem,
} from "../../../../../../../utils/dateTimeUtils";
import { utilsFile } from "../../../../../../../utils/file.util";
import api from "../../../../../api";
import CardHappyHours from "../../../../../components/CardHappyHours";
import { DiscountDaysHours } from "../../../../../types/interfaces/DiscountDaysHours.interface";
import { HappyHour } from "../../../../../types/interfaces/HappyHour.inteface";
import { HappyHourFile } from "../../../../../types/interfaces/HappyHourFile.interface";
import { PartialDiscount } from "../../../../../types/interfaces/PartialDiscount.interface";
import PhoneWithBackground from "./../../../../../../../common/images/components/TabHappyHours/phone-with-background.png";
import DialogCreateHappyHours from "./dialogs/DialogCreateHappyHours";
import Props from "./Props";
import { useStyles } from "./styles";

const TabHappyHours: FC<Props> = (props: Props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const classes = useStyles();
  const hasPackage = useVenueHasPackage(PackageUID.PROMOTIONS);
  const history = useHistory();

  const [happyHour, setHappyHour] = useState<HappyHour | undefined>(undefined);

  const [loading, setLoading] = useState(false);
  const { showAlert } = useContext(AlertContext);
  const venueId = useCurrentVenueId();
  const [formDialogOpen, setFormDialogOpen] = useState(false);

  const onInit = useCallback(async () => {
    setLoading(true);
    api.happyHours
      .itemByVenue(venueId)
      .then((data: HappyHour) => setHappyHour(data))
      .catch(() => setHappyHour(undefined))
      .finally(() => setLoading(false));
  }, [venueId]);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  useEffect(() => {
    onInit();
  }, [onInit]);

  const handleClickChangePlan = () => {
    history.push(getRouteWithSlash(Route.Plan));
  };

  const RenderButton = () =>
    hasPackage ? (
      <ButtonBlack
        onClick={() => setFormDialogOpen(true)}
        className={classes.renderButton}>
        {dictionary.deals.happyHours.createRequest}
      </ButtonBlack>
    ) : (
      <ButtonBlack
        onClick={handleClickChangePlan}
        className={classes.renderButton}>
        <IconLockUnlocked className={classes.renderButtonIcon} />
        {dictionary.deals.happyHours.goToPlans}
      </ButtonBlack>
    );

  const RenderNoHappyHour = () => (
    <Paper>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className={classes.renderNoHappyHour}>
            {loading && <LoadingIndicator withMask />}
            <div className={classes.renderNoHappyHourDesktop}>
              <Typography
                variant="h1"
                className={classes.renderNoHappyHourTextHappyHours}>
                {dictionary.deals.happyHours.title}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.renderNoHappyHourTextHappyHoursDescription}>
                {dictionary.deals.happyHours.createRequestDescription}
              </Typography>
              <RenderButton />
            </div>
            {!isMobile && (
              <div className={classes.renderNoHappyHourImageContainer}>
                <img
                  src={PhoneWithBackground}
                  alt=""
                  className={classes.renderNoHappyHourImage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );

  const updateDiscountStatus = async (id: number, status: string) => {
    await api.happyHours.updateDealStatus(id, status).catch(() => {
      showAlert(dictionary.errors.undefinedError, "error");
    });
    await onInit();
  };

  const RenderDayAndHour = ({
    dayAndHour: { day, isActive, start, end },
  }: {
    dayAndHour: DiscountDaysHours;
  }) => {
    const primary = isActive ? (
      <>
        <Typography style={{ fontSize: 13, fontWeight: "bold" }}>
          <span>{formatHoursToMeridiemWithoutPostMeridiem(start)}</span>
          <span style={{ fontSize: 10, color: "#9298A0" }}>
            {" "}
            {formatHoursToPostMeridiem(start)}
          </span>
          <span> - </span>
          <span>{formatHoursToMeridiemWithoutPostMeridiem(end)}</span>
          <span style={{ fontSize: 10, color: "#9298A0" }}>
            {" "}
            {formatHoursToPostMeridiem(end)}
          </span>
        </Typography>
      </>
    ) : (
      <Typography variant="h5">-</Typography>
    );
    return (
      <ListItem className={classes.renderDayAndHourListItem}>
        <ListItemText
          className={classes.renderDayAndHourListItemText}
          id="switch-list-label-wifi"
          primary={day}
          // classes={{
          //   primary: {
          //     fontWeight: 400,
          //     fontSize: 12,
          //     color: "#45484E",
          //   },
          // }}
        />
        <ListItemText
          className={classes.renderDayAndHourListItemText}
          id="switch-list-label-bluetooth"
          primary={primary}
          // classes={{
          //   primary: {
          //     fontWeight: 700,
          //     fontSize: 13,
          //     color: "#1F1F1F",
          //   },
          // }}
        />
      </ListItem>
    );
  };

  const RenderFile = ({
    file: { originalName, sizeBytes, fullPath },
  }: {
    file: HappyHourFile;
  }) => {
    const [isFullScreenImg, setIsFullScreenImg] = useState(false);

    const openDocument = (e: any, component: string) => {
      e.preventDefault();
      const win = window.open(component, "_blank");
      win?.focus();
    };

    const handleFileOpen = (e: any) => {
      if (originalName.match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i)) {
        setIsFullScreenImg(true);
      } else if (fullPath) {
        openDocument(e, fullPath);
      }
    };

    return (
      <>
        <div className={classes.renderFile} onClick={handleFileOpen}>
          <IconFile className={classes.renderFileIconFile} />
          <div className={classes.renderFileContent}>
            <Typography
              variant="body1"
              className={classes.renderFileOriginalName}>
              {originalName}
            </Typography>
            <Typography variant="body2" className={classes.renderFileSizeBytes}>
              {utilsFile.convertBytesToMegabytes(sizeBytes)}
            </Typography>
          </div>
        </div>
        {!!fullPath && (
          <ImageFullScreen
            src={fullPath}
            isOpen={isFullScreenImg}
            close={() => setIsFullScreenImg(false)}
          />
        )}
      </>
    );
  };

  const RenderHappyHour = () =>
    happyHour ? (
      <Paper className={classes.renderHappyHourPaper}>
        <Grid container spacing={10} style={{ position: "relative" }}>
          {loading && <LoadingIndicator withMask />}

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <ButtonBlack
              onClick={() => setFormDialogOpen(true)}
              className={clsx(
                classes.createRequest,
                classes.hideOnTablet,
                classes.createRequestTablet
              )}>
              {dictionary.deals.happyHours.createRequest}
            </ButtonBlack>
            <Typography
              variant="body1"
              className={classes.renderHappyHourTextActiveDayAndHours}>
              {dictionary.deals.happyHours.activeDaysAndHours}
            </Typography>
            <List className={classes.renderHappyHourList}>
              {happyHour.daysHours.map((dayAndHour) => (
                <RenderDayAndHour dayAndHour={dayAndHour} key={dayAndHour.id} />
              ))}
            </List>

            <Typography
              variant="body1"
              className={classes.renderHappyHourTextYourFiles}>
              {dictionary.deals.happyHours.dialog.yourFiles}
            </Typography>
            {happyHour.files.map((file: HappyHourFile) => (
              <RenderFile file={file} key={file.id} />
            ))}
          </Grid>

          <Grid
            item
            lg={8}
            md={8}
            sm={12}
            xs={12}
            className={clsx(
              classes.renderHappyHourGrid,
              classes.tabletPaddingTop0
            )}>
            <ButtonBlack
              onClick={() => setFormDialogOpen(true)}
              className={clsx(classes.createRequest, classes.hideOnDesktop)}>
              {dictionary.deals.happyHours.createRequest}
            </ButtonBlack>
            <div className={classes.cardsWrapper}>
              {happyHour?.deals?.map((deal: PartialDiscount, index: number) => (
                <div style={{ minWidth: 354, maxWidth: 354 }} key={deal.id}>
                  <CardHappyHours
                    onSelect={updateDiscountStatus}
                    deal={deal}
                    index={index}
                  />
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Paper>
    ) : (
      <></>
    );

  const handleCloseDialog = (isConfirmDialog?: boolean) => {
    setFormDialogOpen(false);
    setIsConfirmDialogOpen(!!isConfirmDialog);
  };

  return (
    <>
      {happyHour ? <RenderHappyHour /> : <RenderNoHappyHour />}

      <DialogCreateHappyHours
        refresh={() => ({})}
        happyHourId={null}
        handleCloseDialog={handleCloseDialog}
        open={formDialogOpen}
      />
      <DialogPopup
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        subtitle={dictionary.deals.happyHours.dialog.requestSentPopUpSubTitle}
        title={dictionary.deals.happyHours.dialog.requestSentPopUpTitle}
        message={" \n  "}
      />
    </>
  );
};

export default TabHappyHours;
