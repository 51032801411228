import { TableCellClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

import typography from "../typography";

const MuiTableCell: Partial<Record<TableCellClassKey, CSSProperties>> = {
  root: {
    ...typography.body1,
    padding: "16px 16px 24px",
  },
};

export default MuiTableCell;
