import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { FC, useContext, useEffect, useState } from "react";

import { AlertContext } from "../../../../../common/components/Alert/AlertContextProvider";
import LoadingIndicator from "../../../../../common/components/LoadingIndicator";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { getRolesList, saveAdminRoles } from "./api";
import IRole from "./models/IRole";
import Props from "./Props";
import { useStyles } from "./styles";

const RolesDialog: FC<Props> = (props: Props) => {
  const { onSuccessUpdate, isOpen, setOpen, admin } = props;

  const classes = useStyles();
  const handleClose = () => {
    setOpen(null);
  };
  const [roles, setRoles] = useState<IRole[]>([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState<number[]>([]);
  const { showAlert } = useContext(AlertContext);

  // const loadRoles = () => {
  //   getRolesList()
  //     .then((roles: IRole[]) => {
  //       if (roles) {
  //         setRoles(roles);
  //       }
  //     })
  //     .catch(() => {
  //       showAlert(
  //         dictionary.deals.createDealForm.createDealError,
  //         "error"
  //       );
  //     });
  // };

  useEffect(() => {
    getRolesList()
      .then((roles: IRole[]) => {
        if (roles) {
          setRoles(roles);
        }
      })
      .catch(() => {
        showAlert(dictionary.deals.createDealForm.createDealError, "error");
      });
    if (admin) {
      setChecked(
        admin.roles.map((role: { id: number; name: string }) => role.id)
      );
    }
  }, [admin, showAlert]);

  const handleSaveAdminRole = async () => {
    if (!admin) {
      return;
    }
    try {
      setLoading(true);

      await saveAdminRoles(admin.id, checked);
      if (onSuccessUpdate) {
        onSuccessUpdate();
      }

      showAlert("Saved!!!", "success");
      handleClose();
    } catch (e) {
      console.log("EEEE", e);
      if (!e) {
        return;
      }

      handleClose();
      showAlert(dictionary.deals.createDealForm.createDealError, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(event.target.name);
    const isChecked: boolean = event.target.checked;
    const position = checked.indexOf(id);

    const newChecked = [...checked];

    if (isChecked && position === -1) {
      newChecked.push(id);
    } else if (!isChecked && position !== -1) {
      newChecked.splice(position, 1);
    }
    setChecked(newChecked);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="alert-dialog-title">
        {"Choise admin roles \n" + admin?.username}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {loading && <LoadingIndicator withMask />}
        <div className={classes.wrapper}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{"Roles"}</FormLabel>
            <FormGroup>
              {roles.map((role: IRole, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={checked.indexOf(role.id) !== -1}
                        onChange={handleChange}
                        name={String(role.id)}
                      />
                    }
                    label={role.name}
                  />
                );
              })}
            </FormGroup>
            <FormHelperText>Be careful</FormHelperText>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={() => handleClose()}>
          {dictionary.deals.createDealForm.cancelButton}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleSaveAdminRole}>
          {"Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RolesDialog;
