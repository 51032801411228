import { TextField, Typography } from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const PasswordStep: FC<Props> = (props: Props) => {
  const { onSetPassword } = props;
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h1" className={classes.headerTitle}>
        {dictionary.profile.mfa.enterAccountPassword}
      </Typography>
      <div className={classes.inputPasswordWrapper}>
        <TextField
          fullWidth
          id="filled-phone-code"
          label={dictionary.profile.mfa.accountPassword}
          name="password"
          onChange={(ev) => {
            const value = ev.target.value;
            if (value.length) {
              onSetPassword(ev.target.value);
            }
          }}
          style={{ marginTop: "1rem" }}
          // value={formatMobileNumber(smsMFAPhone)}
          type="password"
          variant="outlined"
        />
      </div>
    </div>
  );
};

export default PasswordStep;
