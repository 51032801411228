import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type ClassNames =
  | "root"
  | "linearProgress"
  | "textValue"
  | "barColorPrimary"
  | "colorPrimary";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    root: { margin: "10px 0" },
    linearProgress: { height: 8, borderRadius: 4 },
    textValue: { fontWeight: 500, fontSize: 14, color: "#595D64" },
    barColorPrimary: { background: "#FE5454" },
    colorPrimary: {
      backgroundColor: "#E5E7EA",
    },
  })
);
