import {
  Gender,
  GetChartDataByPeriodRequest,
} from "../../../../../../common/models";

export const GET_VENUE_VIEWS_AGE_GENDER = "reporting/views-by-month-age-gender";

export interface ViewsAgeGenderData {
  ageRange: string;
  value: number;
}

export interface GetVenueViewsAgeGenderResponse {
  stats: ViewsAgeGenderData[];
}

export interface GetVenueViewsAgeGenderRequest
  extends GetChartDataByPeriodRequest {
  gender?: Gender;
}
