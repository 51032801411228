import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../common/models";

export type ClassNames = "root" | "btn" | "wrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginLeft: 30,
        marginRight: 30,
      },
      wrapper: {
        marginTop: 40,
        marginBottom: 40,
      },
      btn: {
        margin: 30,
      },
    })
);
