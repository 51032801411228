import Compressor from "compressorjs";
import { toNumber } from "lodash";

import { CroppedImageSize } from "../common/types/enums.type";
import { http } from "../config/api";

const UPLOAD_CROPPED_IMAGE = `file/cropped-image`;
export const uploadCroppedImage = async (
  croppedImage: File | Blob,
  originalImagePath: string,
  size: string
) => {
  if (!croppedImage) return;
  const formData = new FormData();
  formData.append("originalImagePath", originalImagePath);
  formData.append("size", size);
  formData.append("croppedImage", croppedImage);

  return http.post(UPLOAD_CROPPED_IMAGE, formData);
};

async function cropImage(
  file: File | Blob,
  croppedImageSize: CroppedImageSize
): Promise<File | Blob> {
  const [width, height] = croppedImageSize
    .replace("s", "")
    .split("x")
    .map(toNumber);
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 1,
      maxWidth: width,
      maxHeight: height,
      resize: "contain",
      success: resolve,
      error: reject,
    });
  });
}

const isFileImageCheck = (file: File | Blob): boolean =>
  !!file.type.match(/\/(gif|jpe?g|tiff?|png|webp|bmp)$/i);

async function uploadCroppedImages(
  file: File | Blob,
  originalImagePath: string,
  sizes: CroppedImageSize[]
) {
  if (!isFileImageCheck(file)) return;
  const promises = sizes.map(async (size) => {
    return uploadCroppedImage(
      await cropImage(file, size),
      originalImagePath,
      size
    );
  });
  return Promise.all(promises);
}

const getCroppedImagePath = (
  originalImagePath: string,
  size: CroppedImageSize
) => `${size}_${originalImagePath}`;

export default {
  cropImage: cropImage,
  uploadCroppedImages: uploadCroppedImages,
  getCroppedImagePath: getCroppedImagePath,
  isFileImageCheck: isFileImageCheck,
};
