import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { useForm, UseFormTypes } from "../../../../common/hooks";
import {
  CreateVenueAdminInfoForm,
  createVenueAdminInfoForm,
  CreateVenueAdminInfoFormSchema,
  createVenueAdminInfoFormSchema,
} from "./forms/admin";
import {
  CreateVenueVenueInfoForm,
  createVenueVenueInfoForm,
  CreateVenueVenueInfoFormSchema,
  createVenueVenueInfoFormSchema,
} from "./forms/venue";

interface Props {
  children?: React.ReactElement;
}

interface CreateVenueDialogContextInterface {
  adminForm: UseFormTypes<
    CreateVenueAdminInfoFormSchema,
    CreateVenueAdminInfoForm
  >;
  venueForm: UseFormTypes<
    CreateVenueVenueInfoFormSchema,
    CreateVenueVenueInfoForm
  >;
  currentStep: number;
  isNextDisabled: boolean;
  isValidateDisabled: boolean;
  setIsValidateDisabled: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setAttachments: Dispatch<SetStateAction<any>>;
  attachments: any[];
}

export const CreateVenueDialogContext =
  React.createContext<CreateVenueDialogContextInterface>({
    adminForm: {
      form: createVenueAdminInfoForm,
      setAndValidate: () => undefined,
      setFormValue: () => undefined,
      clearErrors: () => undefined,
      setFormValues: () => undefined,
      setSchemaRules: () => undefined,
      validateForm: () => true,
    },
    venueForm: {
      form: createVenueVenueInfoForm,
      setAndValidate: () => undefined,
      setFormValue: () => undefined,
      clearErrors: () => undefined,
      setFormValues: () => undefined,
      setSchemaRules: () => undefined,
      validateForm: () => true,
    },
    currentStep: 1,
    isNextDisabled: false,
    isValidateDisabled: true,
    setIsValidateDisabled: () => undefined,
    setCurrentStep: () => undefined,
    setAttachments: () => undefined,
    attachments: [],
  });

const CreateVenueDialogContextProvider: FC<Props> = ({ children }) => {
  const adminForm = useForm(
    createVenueAdminInfoFormSchema,
    createVenueAdminInfoForm
  );
  const venueForm = useForm(
    createVenueVenueInfoFormSchema,
    createVenueVenueInfoForm
  );
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isValidateDisabled, setIsValidateDisabled] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    setIsNextDisabled(
      (currentStep === 1 && !adminForm.form.isValid) ||
        (currentStep === 2 && !venueForm.form.isValid)
    );
  }, [currentStep, adminForm.form.isValid, venueForm.form.isValid]);

  return (
    <CreateVenueDialogContext.Provider
      value={{
        adminForm,
        venueForm,
        currentStep,
        isNextDisabled,
        isValidateDisabled,
        setIsValidateDisabled,
        setCurrentStep,
        setAttachments,
        attachments,
      }}>
      {children}
    </CreateVenueDialogContext.Provider>
  );
};

export default CreateVenueDialogContextProvider;
