import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../models";

export type ClassNames = "root" | "message" | "withMask";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      },
      message: {
        marginTop: theme.spacing(2),
      },
      withMask: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 10,
        position: "absolute",
        backgroundColor: "rgba(255,255,255, .6)",
      },
    })
);
