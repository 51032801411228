import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "description"
  | "venueBoostNotifyMessage"
  | "venueBoostBottomContainer"
  | "venueBoostNotifyContainer"
  | "clicksQtyMessage";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      description: {
        padding: "5px 0",
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(17),
      },
      venueBoostNotifyMessage: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
        marginLeft: theme.spacing(1),
      },
      clicksQtyMessage: {
        fontWeight: "bold",
        fontSize: theme.typography.pxToRem(22),
        textAlign: "center",
      },
      venueBoostBottomContainer: {
        marginTop: theme.spacing(2),
      },
      venueBoostNotifyContainer: {
        marginTop: theme.spacing(2),
        display: "flex",
        flexWrap: "wrap",
      },
    })
);
