import { useCallback, useContext, useState } from "react";

import { AlertContext } from "../../../../../../../common/components/Alert/AlertContextProvider";
import { useCurrentVenueId } from "../../../../../../../common/hooks/useCurrentVenueId";
import dictionary from "../../../../../../../i18n/en_US/dictionary";
import { deletePromotion, duplicatePromotion } from "../../../../api";
import { PromotionFormDialogContext } from "../../../../dialogs/PromotionFormDialog/PromotionFormDialogContextProvider";

export function useTabPromotionsHook() {
  const [refreshToken, setRefreshToken] = useState(0);

  const { setPromotionId } = useContext(PromotionFormDialogContext);

  const [editPromotionId, setEditPromotionId] = useState<number | null>(null);
  const [deletePromotionId, setDeletePromotionId] = useState<number | null>(
    null
  );
  const [duplicatePromotionId, setDuplicatePromotionId] = useState<
    number | null
  >(null);

  // Edit promotion mode
  const openEditFormDialog = useCallback(
    (promotionId: number) => {
      setPromotionId(0);
      setEditPromotionId(promotionId);
      setFormDialogOpen(true);
    },
    [setPromotionId]
  );
  const { showAlert } = useContext(AlertContext);

  const [formDialogOpen, setFormDialogOpen] = useState(false);

  // Create promotion mode
  const openFormDialog = useCallback(() => {
    setPromotionId(0);
    setEditPromotionId(null);
    setFormDialogOpen(true);
  }, [setPromotionId]);

  const openDeleteDialog = useCallback((promotionId: number) => {
    setDeletePromotionId(promotionId);
  }, []);

  const openDuplicateDialog = useCallback((promotionId: number) => {
    setDuplicatePromotionId(promotionId);
  }, []);

  const refreshGrid = useCallback(() => {
    setRefreshToken(+new Date());
  }, []);

  const closeDeleteDialog = useCallback(() => {
    setDeletePromotionId(null);
  }, []);

  const closeDuplicateDialog = useCallback(() => {
    setDuplicatePromotionId(null);
  }, []);

  const closeFormDialog = useCallback(() => {
    setFormDialogOpen(false);
  }, []);

  const confirmDeletePromotion = async () => {
    if (!deletePromotionId) {
      return;
    }

    try {
      const result = await deletePromotion(venueId, deletePromotionId);

      if (result) {
        showAlert(dictionary.deals.deleteDealSuccess, "success");
        refreshGrid();
      } else {
        showAlert(dictionary.deals.deletePromotonError, "error");
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert(dictionary.deals.deletePromotonError, "error");
    }
  };

  const confirmDuplicatePromotion = async () => {
    if (!duplicatePromotionId) {
      return;
    }

    try {
      const result = await duplicatePromotion(duplicatePromotionId);

      if (result) {
        showAlert(
          dictionary.deals.duplicateDeal.createdDealDuplicate,
          "success"
        );

        if (result.data.id) {
          openEditFormDialog(result.data.id);
        }
        refreshGrid();
      } else {
        showAlert(dictionary.deals.duplicateDeal.errorDealDuplicate, "error");
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert("Error", "error");
    }
  };

  const venueId = useCurrentVenueId();

  return {
    refreshToken,
    openEditFormDialog,
    openDeleteDialog,
    openFormDialog,
    openDuplicateDialog,
    refreshGrid,
    venueId,
    confirmDuplicatePromotion,
    closeDuplicateDialog,
    duplicatePromotionId,
    confirmDeletePromotion,
    closeDeleteDialog,
    deletePromotionId,
    formDialogOpen,
    closeFormDialog,
    editPromotionId,
  };
}
