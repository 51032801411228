import { Grid } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";

import PageTitle from "../../common/components/PageTitle";
import { useRestApi } from "../../common/hooks/useRestApi";
import { useGlobalStyles } from "../../common/styles";
import dictionary from "../../i18n/en_US/dictionary";
import { useStyles } from "../Promotions/AdminSuper/styles";
import { IPromotion } from "../Promotions/types/IPromotion";
import PromotionsUsagesList from "./components/PromotionsUsagesList";
import Props from "./Props";

const PromotionsUsages: FC<Props> = (props: Props) => {
  const promotionId = parseInt(props.match.params.id);
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [promotion, setPromotion] = useState<IPromotion | null>(null);

  const [, getPromotionData] = useRestApi<IPromotion>(
    "promotions/" + (promotionId ? promotionId : 0),
    "GET"
  );
  useEffect(() => {
    getPromotionData()
      .then((promotion: IPromotion | undefined) => {
        if (promotion) {
          setPromotion(promotion);
        }
      })
      .catch((e) => console.error("PromotionsUsages", e));
  }, [getPromotionData]);

  return (
    <div>
      <div className={clsx(globalClasses.flexRow_Center, classes.titleWrapper)}>
        <PageTitle>
          {dictionary.deals.dealsUsages.titlePage(
            promotion ? promotion.name : ""
          )}
        </PageTitle>
      </div>

      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} lg={12} md={12} xl={12}>
          <PromotionsUsagesList promotionId={promotionId} />
        </Grid>
      </Grid>
    </div>
  );
};
export default PromotionsUsages;
