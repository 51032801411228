import { Typography } from "@material-ui/core";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

// import dictionary from "../../../i18n/en_US/dictionary";
import { AlertContext } from "../../../common/components/Alert/AlertContextProvider";
import dictionary from "../../../i18n/en_US/dictionary";
import { getAdmins } from "./api";
import RBACAdminsList from "./components/AdminsList";
import RolesDialog from "./components/RolesDialog";
import IAdmin from "./models/IAdmin";
import Props from "./Props";
import { useStyles } from "./styles";

const RBACAdmins: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { showAlert } = useContext(AlertContext);
  const [admins, setAdmins] = useState<IAdmin[]>([]);
  const [openAdmin, setAdminModal] = useState<IAdmin | null>(null);

  const loadAdmins = useCallback(() => {
    getAdmins()
      .then((admins: IAdmin[]) => {
        if (admins) {
          setAdmins(admins);
        }
      })
      .catch(() => {
        showAlert(dictionary.RBAC.admins.editRoles.updaterRolesError, "error");
      });
  }, [showAlert]);

  const refreshAdmins = () => {
    alert("Refresh admins");
    getAdmins()
      .then((admins: IAdmin[]) => {
        if (admins) {
          setAdmins(admins);
        }
      })
      .catch(() => {
        showAlert(dictionary.RBAC.admins.editRoles.updaterRolesError, "error");
      });
  };

  // @ts-ignore
  useEffect(() => {
    loadAdmins();
  }, [loadAdmins]);

  return (
    <div className={classes.root}>
      <Typography variant="h3" gutterBottom>
        Manage admins
      </Typography>
      <div className={classes.wrapper}>
        <RBACAdminsList admins={admins} setAdminOpen={setAdminModal} />
      </div>

      <RolesDialog
        onSuccessUpdate={() => refreshAdmins()}
        admin={openAdmin}
        isOpen={!!openAdmin}
        setOpen={setAdminModal}
      />
    </div>
  );
};
export default withRouter(RBACAdmins);
