import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type ClassNames = "root" | "title" | "dropzoneRoot" | "attachmentsList";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      dropzoneRoot: {
        maxWidth: "100%",
        backgroundImage: "none",
        marginTop: theme.spacing(2),
        flexDirection: "column",
      },

      title: {
        marginTop: theme.spacing(1),
      },
      attachmentsList: {
        fontWeight: 700,
        marginTop: theme.spacing(1),
      },
    })
);
