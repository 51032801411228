import { Grid, Paper, Typography } from "@material-ui/core";
import React, { FC } from "react";

import InformationBlockItem from "../InformationBlockItem";
import Props from "./Props";
import { useStyles } from "./styles";

const InformationBlock: FC<Props> = (props) => {
  const { items, title } = props;
  const classes = useStyles();

  return (
    <div>
      <Typography variant={"h3"} component={"span"} className={classes.title}>
        {title}
      </Typography>
      <Paper>
        <Grid container spacing={2} className={classes.itemsWrapper}>
          {items.map(({ label, value }, index) => (
            <InformationBlockItem
              key={label}
              label={label}
              value={value}
              fullWidth={index > 5}
            />
          ))}
        </Grid>
      </Paper>
    </div>
  );
};

export default InformationBlock;
