import { Typography } from "@material-ui/core";
import React, { FC, useRef } from "react";
import { useDropzone } from "react-dropzone";

import ImageCircleCloudUpload from "../../../../common/images/components/TabHappyHours/circle-cloud-upload.png";
import { CroppedImageSize } from "../../../../common/types/enums.type";
import dictionary from "../../../../i18n/en_US/dictionary";
import imageUtils from "../../../../utils/imageUtils";
import Props from "./Props";
import { useStyles } from "./styles";

const DEFAULT_ACCEPT = ".mp4,image/*";

const FileUpload: FC<Props> = (props: Props) => {
  const { accept, onFileChange, error } = props;
  const classes = useStyles();

  const fileInput = useRef<HTMLInputElement>(null);

  const openImageSelector = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileChange = async (event: any) => {
    const { files } = event.target;

    onFileChange(
      await Promise.all(Array.from(files).map(await preprocessFile))
    );
    if (fileInput.current) {
      fileInput.current.value = ""; // Clear input after uploading
    }
  };

  const preprocessFile = async (file: any) =>
    file.type.match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i)
      ? await imageUtils.cropImage(file, CroppedImageSize.s1280x1280)
      : file;

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept === "*" ? "" : accept || DEFAULT_ACCEPT,
    onDrop: async (files: any[]) => {
      onFileChange(await Promise.all(files.map(await preprocessFile)));
    },
    multiple: true,
  });

  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <input
        multiple
        ref={fileInput}
        className={classes.inputFileSelect}
        accept={accept || DEFAULT_ACCEPT}
        onChange={handleFileChange}
        id="photo-selector-input"
        type="file"
      />
      <input ref={inputRef} {...getInputProps()} />
      <div
        {...getRootProps()}
        onClick={openImageSelector}
        style={{ border: error && "1px solid red" }}
        className={classes.imageSelectorContainer}>
        <div className={classes.imageCircleCloudUploadContainer}>
          <img
            src={ImageCircleCloudUpload}
            alt=""
            className={classes.imageCircleCloudUpload}
          />
        </div>
        <div>
          <Typography variant="h4" className={classes.textContainer}>
            <span className={classes.textClickToUpload}>
              {dictionary.deals.fileUpload.clickToUpload}
            </span>{" "}
            {dictionary.deals.fileUpload.orDragAndDrop}
          </Typography>
          <Typography
            variant="body2"
            className={classes.textFileUploadConstrains}>
            {dictionary.deals.fileUpload.fileUploadConstrains}
          </Typography>
        </div>
      </div>
    </>
  );
};
export default FileUpload;
