import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../common/models";
import { colors } from "../../theme/colors";

export type ClassNames = "root" | "titleWrapper" | "profileWrapper" | "main";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      titleWrapper: {
        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(3.8),
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: theme.spacing(1.8),
        },
      },
      profileWrapper: {
        backgroundColor: colors.white.primary,
        width: "100%",
        height: "100%",
        padding: 100,
      },
      main: {
        height: "100%",
        width: "100%",
        backgroundColor: colors.white.primary,
      },
    })
);
