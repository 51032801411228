import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames = "button" | "icon" | "iconRight";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      button: {
        backgroundColor: colors.dark.main,
        color: colors.white.primary,
        borderRadius: 4,
        borderColor: "transparent",
        textTransform: "none",
        "&:hover": {
          backgroundColor: colors.dark.main,
          boxShadow: "0 0 25px 0 rgba(0,0,0,0.25)",
        },
        "&:$disabled": {
          backgroundColor: "#C8C8C8",
        },
        fontWeight: "bold",
        "&:hover $iconRight": {
          [theme.breakpoints.up("md")]: {
            marginRight: theme.spacing(1),
            marginLeft: 0,
          },
        },
      },
      icon: {
        marginRight: 5,
        marginTop: -3,
      },
      iconRight: {
        marginLeft: theme.spacing(1),
        marginRight: 0,
        transition: theme.transitions.create(["margin-left", "margin-right"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    })
);
