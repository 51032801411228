import { GET_DATA_WITH_PAGINATION } from "../../common/api";
import { http } from "../../config/api";

export const VENUE_PHOTOS_APPROVE = "/venue/approve-photo/0";
export const VENUE_PHOTOS_DECLINE = "/venue/delete-photo/0";

const VENUE_PHOTOS_ALL = `venue/venue-photos/0`;

export const getVenuePendingPhotosAll = async (
  limit: number,
  skip?: number,
  search?: string
): Promise<any> =>
  http
    .get(GET_DATA_WITH_PAGINATION(VENUE_PHOTOS_ALL, limit, skip, search))
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });

export const declinePhotos = async (checkedAll: number[]): Promise<any> =>
  http
    .delete(VENUE_PHOTOS_DECLINE, { data: { photoIds: checkedAll } })
    .catch((error) => {
      throw error.response;
    });
