import { colors } from "../colors";

export default {
  root: {
    "&$focused": {
      color: colors.grey.primary,
    },
  },
  asterisk: {
    color: "#db3131",
    "&$error": {
      color: "#db3131",
    },
  },
};
