import { http } from "../../../../../../config/api";

const MFA_EMAIL_CODE_REQUEST = "profile/mfa/email-code-request";
const MFA_EMAIL_SET_REQUEST = "profile/mfa/email-set";

export const requestCodeEmail = async (email: string): Promise<any> =>
  http
    .post(MFA_EMAIL_CODE_REQUEST, { email })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });

export const sendEmailCode = async (
  email: string,
  code: string
): Promise<any> =>
  http
    .post(MFA_EMAIL_SET_REQUEST, { email, code })
    .then((response) => {
      return { ...response.data };
    })
    .catch((error) => {
      throw error.response;
    });
