import { Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import Slider, { Settings } from "react-slick";

import ImageFullScreen from "../../../../common/components/ImageFullScreen";
import dictionary from "../../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const VenuePendingAttachments: FC<Props> = (props: Props) => {
  const { attachments } = props;
  const [fullScreenAttachment, setFullScreenAttachment] = useState("");
  const classes = useStyles();

  const slickSettings: Settings = {
    arrows: false,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 500,
  };

  const renderAttachment = (attachmentUrl: string) => (
    <div className={classes.attachmentRoot}>
      <div className={classes.attachmentWrapper}>
        {attachmentUrl.includes(".pdf") ? (
          <>
            <a
              href={attachmentUrl}
              rel="noopener noreferrer"
              target="_blank"
              className={classes.pdfLinkToNewPage}
            />
            <embed src={attachmentUrl} height="247" width="100%" />
          </>
        ) : (
          <img
            className={classes.attachmentImage}
            onClick={() => setFullScreenAttachment(attachmentUrl)}
            key={attachmentUrl}
            src={attachmentUrl}
            alt={attachmentUrl}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <Typography variant={"body2"} className={classes.attachmentTitle}>
        {dictionary.venues.attachmentsTitle}
      </Typography>
      <Slider className={classes.slider} {...slickSettings}>
        {attachments.map(renderAttachment)}
      </Slider>
      <ImageFullScreen
        src={fullScreenAttachment}
        isOpen={Boolean(fullScreenAttachment)}
        close={() => setFullScreenAttachment("")}
      />
    </div>
  );
};

export default VenuePendingAttachments;
