import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../common/models";

export type ClassNames = "root" | "filterSelect";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        width: "auto",
        display: "flex",
        marginLeft: "auto",
        justifyContent: "flex-end",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
      filterSelect: {
        marginTop: 4,
        marginLeft: 8,
        [theme.breakpoints.down("sm")]: {
          marginTop: theme.spacing(2),
          marginLeft: 0,
        },
        "&:first-child": {
          marginLeft: 0,
        },
      },
    })
);
