import IVenueAdminProfile from "../models/IVenueAdminProfile";

export const GET_PROFILE_PENDING = "GET_PROFILE.PENDING";
export const GET_PROFILE_REJECTED = "GET_PROFILE.REJECTED";
export const GET_PROFILE_FULFILLED = "GET_PROFILE.FULFILLED";

export const SAVE_PROFILE_PENDING = "SAVE_PROFILE.PENDING";
export const SAVE_PROFILE_REJECTED = "SAVE_PROFILE.REJECTED";
export const SAVE_PROFILE_FULFILLED = "SAVE_PROFILE.FULFILLED";

export interface GetProfilePendingAction {
  type: typeof GET_PROFILE_PENDING;
}

export interface GetProfileSuccessAction {
  type: typeof GET_PROFILE_FULFILLED;
  profile: IVenueAdminProfile;
}

export interface GetProfileFailAction {
  type: typeof GET_PROFILE_REJECTED;
  error: string;
}

export interface SaveProfilePendingAction {
  type: typeof SAVE_PROFILE_PENDING;
}

export interface SaveProfileSuccessAction {
  type: typeof SAVE_PROFILE_FULFILLED;
}

export interface SaveProfileFailAction {
  type: typeof SAVE_PROFILE_REJECTED;
  error: string;
}

export type ProfileActionTypes =
  | GetProfilePendingAction
  | GetProfileSuccessAction
  | GetProfileFailAction
  | SaveProfilePendingAction
  | SaveProfileSuccessAction
  | SaveProfileFailAction;
