import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "root"
  | "formControl"
  | "actionsToolbar"
  | "deleteButton"
  | "formContainer"
  | "divider";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      formControl: {
        width: "100%",
      },
      actionsToolbar: {
        marginTop: theme.spacing(3),
        display: "flex",
        justifyContent: "flex-start",
      },
      deleteButton: {
        marginLeft: theme.spacing(3),
      },
      formContainer: {
        marginTop: theme.spacing(3),
      },
      divider: {
        margin: theme.spacing(3, 0),
      },
    })
);
