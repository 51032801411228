import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import dictionary from "../../../../../../../../i18n/en_US/dictionary";
import Props from "./Props";

const SuccessStep: FC<Props> = (props: Props) => {
  return (
    <div>
      <Typography variant="body2" component="span" style={{ marginBottom: 30 }}>
        {dictionary.venueAdmin.profile.mfa.dialogs.call.mfaSuccessEnable}
      </Typography>
    </div>
  );
};
export default SuccessStep;
