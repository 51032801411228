import { Button, Typography } from "@material-ui/core";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { AlertContext } from "../../../common/components/Alert/AlertContextProvider";
import { useStyles } from "../main/styles";
import { getRoles } from "./api";
import CreateRoleDialog from "./components/CreateRoleDialog";
import EditRoleDialog from "./components/EditRoleDialog";
import RolesList from "./components/RolesList";
import IRole from "./models/IRole";
import Props from "./Props";

const RBACRoles: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const initRoles: IRole[] = [];
  const [roles, setRoles] = useState(initRoles);
  const [isOpenCreateModal, setIsOpentCreateModal] = useState(false);
  const [editModelRole, setEditModelRole] = useState<IRole | false>(false);
  const { showAlert } = useContext(AlertContext);

  const loadRoles = useCallback(() => {
    getRoles()
      .then((roles: IRole[]) => {
        if (roles) {
          setRoles(roles);
        }
      })
      .catch(() => {
        showAlert("Error", "error");
      });
  }, [showAlert]);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  return (
    <div className={classes.root}>
      <Typography variant="h3" gutterBottom>
        RBAC roles
      </Typography>
      <Button
        className={classes.btn}
        variant="contained"
        color="primary"
        onClick={() => setIsOpentCreateModal(true)}>
        Add role
      </Button>

      <RolesList onEditOpen={(role) => setEditModelRole(role)} roles={roles} />

      <CreateRoleDialog
        onSuccessCreate={() => loadRoles()}
        isOpen={isOpenCreateModal}
        setOpen={setIsOpentCreateModal}
      />

      <EditRoleDialog
        role={editModelRole}
        onSuccessUpdate={() => loadRoles()}
        isOpen={!!editModelRole}
        setOpen={setEditModelRole}
      />
    </div>
  );
};
export default withRouter(RBACRoles);
