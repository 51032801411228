import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AuthState } from "../../store/auth/reducer";
import { AppState } from "../../store/rootReducer";
import { RoleNameEnum } from "../../views/VenueStaff/main/RoleTypes";

export function useIsManager() {
  const { roles } = useSelector<AppState, AuthState>((state) => state.auth);
  const [isManager, setIsManager] = useState(
    roles.includes(RoleNameEnum.manager)
  );

  useEffect(() => {
    setIsManager(roles.includes(RoleNameEnum.manager));
  }, [roles]);

  return isManager;
}
