import { ComponentsProps } from "@material-ui/core/styles/props";

import MuiAccordionSummary from "./MuiAccordionSummary";
import MuiChip from "./MuiChip";
import MuiPaper from "./MuiPaper";
import MuiRadio from "./MuiRadio";
import MuiSelect from "./MuiSelect";
import MuiSwitch from "./MuiSwitch";
import MuiTextField from "./MuiTextField";

const props: ComponentsProps = {
  MuiAccordionSummary,
  MuiChip,
  MuiSelect,
  MuiTextField,
  MuiRadio,
  MuiPaper,
  MuiSwitch,
};

export default props;
