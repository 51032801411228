import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

export type ClassNames = "link" | "linkText";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(() =>
  createStyles({
    link: {
      padding: 20,
    },
    linkText: {
      marginLeft: 20,
      fontWeight: "bold",
    },
  })
);
