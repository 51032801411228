import { ChatDataItem, Message } from "../../views/Messages/models";

export const SET_CHAT_DATA = "SET_CHAT_DATA";
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";
export const SET_LIKED_MESSAGE = "SET_LIKED_MESSAGE";
export const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT";

export interface SetChatDataActionType {
  type: typeof SET_CHAT_DATA;
  chatData: ChatDataItem[];
}

export interface AddNewMessageActionType {
  type: typeof ADD_NEW_MESSAGE;
  newMessage: Message;
  activeChatWindow?: number;
}

export interface SetLikedMessageType {
  type: typeof SET_LIKED_MESSAGE;
  message: Message;
}

export interface SetActiveChat {
  type: typeof SET_ACTIVE_CHAT;
  activeChat: number;
}
export type MessagingActionType =
  | SetChatDataActionType
  | AddNewMessageActionType
  | SetLikedMessageType
  | SetActiveChat;
