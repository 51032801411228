import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../common/models";
import { colors } from "../../../../../../theme/colors";

export type ClassNames =
  | "root"
  | "dialogContent"
  | "scheduleTypesWrapper"
  | "scheduleTypeLabel"
  | "scheduleTypeLabelActive";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        backgroundColor: "rgba(237, 239, 246, 0.35)",
        border: "2px solid #EBECF0",
        padding: theme.spacing(2, 5),
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(2, 2),
        },
      },
      scheduleTypesWrapper: {
        marginLeft: theme.spacing(4),
        marginBottom: theme.spacing(2),
      },
      scheduleTypeLabel: {
        fontWeight: 600,
      },
      scheduleTypeLabelActive: {
        color: colors.green.secondary,
      },
      dialogContent: {
        [theme.breakpoints.down("sm")]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
    })
);
