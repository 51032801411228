import { Grid, Hidden } from "@material-ui/core";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";

import ButtonBlack from "../../../../../common/components/ButtonBlack";
import VenueReportingContextProvider from "../../../../../common/components/VenueReportingContextProvider";
import { useCurrentVenueId } from "../../../../../common/hooks/useCurrentVenueId";
import { Review } from "../../../../../common/models";
import dictionary from "../../../../../i18n/en_US/dictionary";
import {
  CRITIC_REVIEW_LIMIT,
  getVenueCriticReviews,
  putVenueCriticReview,
  VenueCriticReviewsResponse,
} from "./api";
import CriticReviewCard from "./components/CriticReviewCard";
import CriticReviewResponseDialog from "./dialogs/CriticReviewResponseDialog";
import Props from "./props";
import { useStyles } from "./styles";

const CriticReviews: FC<Props> = ({
  reviewsData: reviewsDataParent,
  activeReview: activeReviewParent,
  user,
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const currentVenueId = useCurrentVenueId();
  const [activeReview, setActiveReview] = useState<Review>(activeReviewParent);
  const [reviewsData, setReviewsData] =
    useState<VenueCriticReviewsResponse>(reviewsDataParent);

  const handleUpdateReview = async (commentResponse: string) => {
    const newReview = await putVenueCriticReview(
      activeReview.id,
      currentVenueId,
      commentResponse,
      moment().toDate()
    );

    reviewsData.reviews.splice(
      reviewsData.reviews.findIndex((e) => e.id === newReview.id),
      1,
      newReview
    );

    setActiveReview(newReview);
    setReviewsData({
      ...reviewsData,
    });
  };

  const updateActiveCard = (review: Review) => {
    setActiveReview(review);

    setDialogOpen(true);
  };

  useEffect(() => {
    getVenueCriticReviews(currentVenueId, CRITIC_REVIEW_LIMIT).then(
      (response) => {
        const [activeReview] = response.reviews;
        setActiveReview(activeReview);
        setReviewsData(response);
      }
    );
  }, [currentVenueId]);

  const loadMore = () => {
    getVenueCriticReviews(
      currentVenueId,
      CRITIC_REVIEW_LIMIT,
      reviewsData.reviews.length
    ).then((response) => {
      const { reviews, count } = response;
      setReviewsData({
        reviews: [...reviewsData.reviews, ...reviews],
        count,
      });
    });
  };
  return (
    <>
      <VenueReportingContextProvider>
        <div className={classes.root}>
          <Grid container spacing={3} style={{ maxWidth: "none" }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {reviewsData.reviews.map((review, key) => (
                <div onClick={() => updateActiveCard(review)} key={key}>
                  <CriticReviewCard
                    review={review}
                    activeReview={activeReview}
                    user={user}
                    updateReview={handleUpdateReview}
                  />
                </div>
              ))}

              <div className={classes.buttonWrapper}>
                {reviewsData.reviews.length < reviewsData.count && (
                  <ButtonBlack className={classes.button} onClick={loadMore}>
                    {dictionary.reviews.loadMore}
                  </ButtonBlack>
                )}
              </div>
            </Grid>
            <Hidden xsDown smDown>
              <Grid item lg={6} md={6} sm={12}>
                <CriticReviewCard
                  sticky
                  activeReview={activeReview}
                  updateReview={handleUpdateReview}
                  user={user}
                />
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </VenueReportingContextProvider>
      <Hidden mdUp>
        <CriticReviewResponseDialog
          title={dictionary.reviews.response}
          open={dialogOpen}
          updateReview={handleUpdateReview}
          onClose={() => {
            setDialogOpen(false);
          }}
          message={activeReview.commentResponse || ""}
        />
      </Hidden>
    </>
  );
};

export default CriticReviews;
