import {
  CardActions,
  Grid,
  TablePagination,
  Typography,
} from "@material-ui/core";
import { AxiosRequestConfig } from "axios";
import clsx from "clsx";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../common/components/ButtonBlack";
import ButtonPrimary from "../../../../common/components/ButtonPrimary";
import ConfirmDialog from "../../../../common/components/dialogs/ConfirmDialog";
import { ConfirmModalType } from "../../../../common/components/dialogs/ConfirmDialog/Props";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import OcietyCheckbox from "../../../../common/components/OcietyCheckbox";
import OcietyTooltip from "../../../../common/components/OcietyTooltip";
import { usePagination } from "../../../../common/hooks";
import { useRestApi } from "../../../../common/hooks/useRestApi";
import { ReactComponent as InfoTooltip } from "../../../../common/svg/icons/info-tooltip.svg";
import dictionary from "../../../../i18n/en_US/dictionary";
import {
  getAllReportedMedia,
  REPORTED_MEDIA_ACCEPT,
  REPORTED_MEDIA_DECLINE,
} from "../../api";
import {
  AcceptReportRequest,
  DeclineReportRequest,
  ReportedMediaSuccessResponse,
} from "../../models";
import ReportedMediaTableRow from "./components/ReportedMediaTableRow";
import { useStyles } from "./styles";

const ReportedMediaTable: FC = () => {
  const classes = useStyles();
  const [reportedMedia, setReportedMedia] = useState<
    ReportedMediaSuccessResponse[]
  >([]);
  const [activeRequests, setActiveRequests] = useState(0);
  const { showAlert } = useContext(AlertContext);
  const [pagination, dispatchPagination, paginationTypes] =
    usePagination(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [checkedAll, setCheckedAll] = useState<number[]>([]);
  const mediaIdsAcceptRequest = [
    // @ts-ignore
    ...new Set(
      reportedMedia
        .filter((media) => checkedAll.includes(media.id))
        .map((media) => media.photoId)
    ),
  ];
  const [isDeclinedModalOpen, setIsDeclinedModalOpen] = useState(false);
  const [{ loading: loadingAcceptingReports }, acceptReport] = useRestApi<
    any,
    AcceptReportRequest,
    AxiosRequestConfig
  >(REPORTED_MEDIA_ACCEPT, "DELETE", {
    data: { photoIds: mediaIdsAcceptRequest },
  });
  const [{ loading: loadingDecliningReports }, declineReport] = useRestApi<
    any,
    DeclineReportRequest,
    AxiosRequestConfig
  >(REPORTED_MEDIA_DECLINE, "DELETE", { data: { reportIds: checkedAll } });

  const selected = checkedAll.length;
  const skip = pagination.currentPage * rowsPerPage;

  const fetchReportedMedia = useCallback(() => {
    getAllReportedMedia(rowsPerPage, skip)
      .then((response) => {
        if (response) {
          setReportedMedia(response.data);
          setActiveRequests(response.count);

          if (pagination.currentPage && !response.data.length) {
            const currentPage = pagination.currentPage - 1;
            dispatchPagination({ type: paginationTypes.SET_PAGE, currentPage });
          }
        }
      })
      .catch(() => {
        showAlert(dictionary.deals.createDealForm.errorFetchData, "error");
      });
  }, [
    rowsPerPage,
    skip,
    pagination.currentPage,
    dispatchPagination,
    paginationTypes.SET_PAGE,
    showAlert,
  ]);

  useEffect(() => {
    fetchReportedMedia();
  }, [fetchReportedMedia]);

  const handleCloseDeclineConfirmDialog = () => {
    setIsDeclinedModalOpen(false);
    setCheckedAll([]);
  };
  const handleOpenDeclineConfirmDialog = () => {
    setIsDeclinedModalOpen(true);
  };

  const handleAcceptReport = () => {
    acceptReport()
      .then(() => {
        showAlert(
          dictionary.reportedMedia.acceptSuccessPopup,
          "success",
          dictionary.app.success
        );
        fetchReportedMedia();
      })
      .catch(() =>
        showAlert(
          dictionary.reportedMedia.acceptErrorPopup,
          "error",
          dictionary.app.oopsError
        )
      )
      .finally(() => setCheckedAll([]));
  };

  const handleDeclineMediaReport = () => {
    declineReport()
      .then(() => {
        showAlert(
          dictionary.reportedMedia.declineSuccessPopup,
          "success",
          dictionary.app.success
        );
        fetchReportedMedia();
      })
      .catch(() =>
        showAlert(
          dictionary.reportedMedia.declineErrorPopup,
          "error",
          dictionary.app.oopsError
        )
      )
      .finally(() => setCheckedAll([]));
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    currentPage: number
  ) => {
    dispatchPagination({ type: paginationTypes.SET_PAGE, currentPage });
    setCheckedAll([]);
  };

  const handleRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const renderRows = () =>
    reportedMedia &&
    reportedMedia.map(
      (media) =>
        media.active && (
          <ReportedMediaTableRow
            key={media.id}
            isChecked={checkedAll.includes(media.id)}
            reportedMediaItem={media}
            handleOpenDeclineConfirmDialog={handleOpenDeclineConfirmDialog}
            setCheckedAll={setCheckedAll}
            selected={selected}
            fetchReportedMedia={fetchReportedMedia}
          />
        )
    );

  const handleCheckAll = () => {
    const mediaIds = reportedMedia.map(
      (media: ReportedMediaSuccessResponse) => media.id
    );
    selected === reportedMedia.length
      ? setCheckedAll([])
      : setCheckedAll(mediaIds);
  };

  return (
    <>
      {reportedMedia.length ? (
        <div>
          {selected > 0 ? (
            <div className={classes.selectedContainer}>
              <Typography variant={"h4"} style={{ marginRight: 24 }}>
                {dictionary.reportedMedia.selectedReportsOutOf(
                  selected,
                  activeRequests
                )}
              </Typography>
              <div className={classes.actionsWrapper}>
                <ButtonBlack
                  onClick={handleAcceptReport}
                  className={clsx(
                    classes.actionButtons,
                    classes.approveButton
                  )}>
                  {dictionary.reportedMedia.actions.accept}
                </ButtonBlack>
                <ButtonPrimary
                  onClick={handleOpenDeclineConfirmDialog}
                  color="primary"
                  className={clsx(
                    classes.actionButtons,
                    classes.declineButton
                  )}>
                  {dictionary.reportedMedia.actions.decline}
                </ButtonPrimary>
              </div>
            </div>
          ) : (
            <div className={classes.activePhotosContainer}>
              <Typography variant={"h3"} className={classes.photoRequestLabel}>
                {dictionary.reportedMedia.reportHeader}
              </Typography>
              <div className={classes.activePhotosContainer}>
                <Typography
                  variant={"body1"}
                  className={classes.activeRequestsLabel}>
                  {dictionary.reportedMedia.activeRequest(activeRequests)}
                </Typography>
              </div>
            </div>
          )}
          <Grid container xs={12} className={clsx(classes.root)}>
            <Grid
              className={clsx(
                classes.gridContainer,
                classes.gridContainerHeader
              )}
              container
              xs={12}>
              <Grid item className={clsx(classes.rowItem, classes.rowItem5)}>
                <OcietyCheckbox
                  checked={selected === reportedMedia.length}
                  indeterminate={
                    selected > 0 && selected < reportedMedia.length
                  }
                  onChange={handleCheckAll}
                  classes={{ indeterminate: classes.indeterminateCheckColor }}
                  color="primary"
                />
              </Grid>
              <Grid item className={clsx(classes.rowItem, classes.rowItem8)}>
                <Typography
                  variant={"subtitle2"}
                  className={classes.headerRowItemText}>
                  {dictionary.reportedMedia.tableColumnNames.mediaColumn}
                </Typography>
              </Grid>
              <Grid item className={clsx(classes.rowItem, classes.rowItem20)}>
                <Typography
                  variant={"subtitle2"}
                  className={classes.headerRowItemText}>
                  {dictionary.reportedMedia.tableColumnNames.venueColumn}
                </Typography>
              </Grid>
              <Grid item className={clsx(classes.rowItem, classes.rowItem10)}>
                <Typography
                  variant={"subtitle2"}
                  className={classes.headerRowItemText}>
                  {dictionary.reportedMedia.tableColumnNames.dateColumn}
                </Typography>
              </Grid>
              <Grid item className={clsx(classes.rowItem, classes.rowItem19)}>
                <Typography
                  variant={"subtitle2"}
                  className={classes.headerRowItemText}>
                  {dictionary.reportedMedia.tableColumnNames.emailColumn}
                </Typography>
              </Grid>
              <Grid item className={clsx(classes.rowItem, classes.rowItem19)}>
                <Typography
                  variant={"subtitle2"}
                  className={classes.headerRowItemText}>
                  {dictionary.reportedMedia.tableColumnNames.commentColumn}
                </Typography>
              </Grid>
              <Grid item className={clsx(classes.rowItem, classes.rowItem19)}>
                <Typography
                  variant={"subtitle2"}
                  className={classes.headerRowItemText}>
                  {dictionary.reportedMedia.tableColumnNames.actionsColumn}
                </Typography>
                <OcietyTooltip
                  title={dictionary.reportedMedia.actionHeaderTooltip}
                  placement="top-start">
                  <div className={classes.iconWrapper}>
                    <InfoTooltip />
                  </div>
                </OcietyTooltip>
              </Grid>
            </Grid>
            {renderRows()}
          </Grid>
          <ConfirmDialog
            open={isDeclinedModalOpen}
            onClose={handleCloseDeclineConfirmDialog}
            onConfirm={handleDeclineMediaReport}
            subtitle={dictionary.reportedMedia.confirmDeclineDialogMessage(
              checkedAll.length
            )}
            message={"\n"}
            type={ConfirmModalType.YES_DELETE}
            yesTypeAdditionalText={"Decline"}
          />
          <CardActions>
            <TablePagination
              component="div"
              count={activeRequests}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={pagination.currentPage}
              labelDisplayedRows={(type) => (
                <Typography
                  variant={"subtitle2"}
                  className={
                    classes.paginationLabel
                  }>{`${type.to} - ${type.count}`}</Typography>
              )}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              labelRowsPerPage={
                <Typography
                  variant={"subtitle2"}
                  className={classes.paginationLabel}>
                  Rows per page
                </Typography>
              }
            />
          </CardActions>
          {(loadingAcceptingReports || loadingDecliningReports) && (
            <LoadingIndicator withMask />
          )}
        </div>
      ) : (
        <div className={classes.rootEmpty}>
          <img
            src="images/backgrounds/empty-media-approve-bg.png"
            alt="chat-list-empty"
          />
          <Typography
            className={classes.emptyStateTitle}
            component={"span"}
            variant={"h2"}>
            {dictionary.reportedMedia.noReportedMediaTitle}
          </Typography>
          <Typography variant={"h5"} className={classes.emptyStateMsg}>
            {dictionary.reportedMedia.noReportedMediaMsg}
          </Typography>
        </div>
      )}
    </>
  );
};

export default ReportedMediaTable;
