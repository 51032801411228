import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../../../../../common/models";

export type ClassNames = "root" | "headerTitle" | "inputPasswordWrapper";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {},
      headerTitle: {
        textAlign: "center",
        marginTop: 20,
      },
      inputPasswordWrapper: {
        marginTop: theme.spacing(4),
        maxWidth: 350,
        margin: "auto",
      },
    })
);
