import {
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import OcietySwitch from "../../../../common/components/OcietySwitch";
import dictionary from "../../../../i18n/en_US/dictionary";
import { getNotifications, saveNotifications } from "./api";
import { NotificationEnum } from "./enums";
import Props from "./Props";
import { useStyles } from "./styles";

const Notifications: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState<NotificationEnum[]>([]);
  const { showAlert } = useContext(AlertContext);

  const saveCheckedNotifications = async (
    notifications: NotificationEnum[]
  ) => {
    try {
      const result = await saveNotifications(notifications);

      if (result) {
        showAlert("Error", "success");
      }
    } catch (e) {
      if (!e) {
        return;
      }

      showAlert("Error", "error");
    }
  };
  const handleToggleNotification = (notification: NotificationEnum) => {
    const currentIndex = notifications.indexOf(notification);
    const newChecked = [...notifications];

    if (currentIndex === -1) {
      newChecked.push(notification);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setNotifications(newChecked);
    saveCheckedNotifications(newChecked);
  };

  useEffect(() => {
    getNotifications()
      .then((notifications: NotificationEnum[]) => {
        if (notifications) {
          setNotifications(notifications);
        }
      })
      .catch(() => {
        showAlert("Error", "error");
      });
  }, [showAlert]);

  return (
    <div style={{ marginTop: 30, width: "80%" }}>
      <Typography variant={"h3"}>
        {dictionary.profile.notifictions.title}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <List
            subheader={<ListSubheader> </ListSubheader>}
            className={classes.root}>
            <ListItem>
              <ListItemText
                id="switch-list-label-email"
                primary={dictionary.profile.notifictions.notificationEmail}
              />
              <ListItemSecondaryAction>
                <OcietySwitch
                  edge="end"
                  onChange={(ev) =>
                    handleToggleNotification(NotificationEnum.EMAIL)
                  }
                  checked={notifications.indexOf(NotificationEnum.EMAIL) !== -1}
                  inputProps={{ "aria-labelledby": "sswitch-list-label-emai" }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                id="switch-list-label-app"
                primary="Notifications in app"
              />
              <ListItemSecondaryAction>
                <OcietySwitch
                  edge="end"
                  onChange={(ev) =>
                    handleToggleNotification(NotificationEnum.WEB_APP)
                  }
                  checked={
                    notifications.indexOf(NotificationEnum.WEB_APP) !== -1
                  }
                  inputProps={{
                    "aria-labelledby": "switch-list-label-app",
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            {/*<ListItem>*/}
            {/*  <ListItemText*/}
            {/*    id="switch-list-label-monthly-newsletter"*/}
            {/*    primary="Monthly newsletter from Ociety"*/}
            {/*  />*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <OcietySwitch*/}
            {/*      edge="end"*/}
            {/*      onChange={(ev) =>*/}
            {/*        handleToggleNotification(*/}
            {/*          NotificationEnum.MONTHLY_NEWSLETTER*/}
            {/*        )*/}
            {/*      }*/}
            {/*      checked={*/}
            {/*        notifications.indexOf(*/}
            {/*          NotificationEnum.MONTHLY_NEWSLETTER*/}
            {/*        ) !== -1*/}
            {/*      }*/}
            {/*      inputProps={{*/}
            {/*        "aria-labelledby": "switch-list-label-monthly-newsletter",*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*</ListItem>*/}
            <ListItem>
              <ListItemText
                id="switch-list-label-new-reviews"
                primary="New reviews"
              />
              <ListItemSecondaryAction>
                <OcietySwitch
                  edge="end"
                  onChange={(ev) =>
                    handleToggleNotification(NotificationEnum.NEW_REVIEWS)
                  }
                  checked={
                    notifications.indexOf(NotificationEnum.NEW_REVIEWS) !== -1
                  }
                  inputProps={{
                    "aria-labelledby": "switch-list-label-new-reviews",
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                id="switch-list-label-used-deals"
                primary="Redeemed deals"
              />
              <ListItemSecondaryAction>
                <OcietySwitch
                  edge="end"
                  onChange={(ev) =>
                    handleToggleNotification(NotificationEnum.USED_PROMOTIONS)
                  }
                  checked={
                    notifications.indexOf(NotificationEnum.USED_PROMOTIONS) !==
                    -1
                  }
                  inputProps={{
                    "aria-labelledby": "switch-list-label-used-deals",
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6}>
          <List
            subheader={<ListSubheader></ListSubheader>}
            className={classes.root}>
            {/*<ListItem>*/}
            {/*  <ListItemText*/}
            {/*    id="switch-list-label-new-message-user"*/}
            {/*    primary="New messages from Users"*/}
            {/*  />*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <OcietySwitch*/}
            {/*      edge="end"*/}
            {/*      onChange={(ev) =>*/}
            {/*        handleToggleNotification(*/}
            {/*          NotificationEnum.NEW_MESSAGE_FROM_USER*/}
            {/*        )*/}
            {/*      }*/}
            {/*      checked={*/}
            {/*        notifications.indexOf(*/}
            {/*          NotificationEnum.NEW_MESSAGE_FROM_USER*/}
            {/*        ) !== -1*/}
            {/*      }*/}
            {/*      inputProps={{*/}
            {/*        "aria-labelledby": "switch-list-label-new-message-user",*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*</ListItem>*/}
            {/*<ListItem>*/}
            {/*  <ListItemText*/}
            {/*    id="switch-list-label-new-message-ociety"*/}
            {/*    primary="New messages from Ociety"*/}
            {/*  />*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <OcietySwitch*/}
            {/*      edge="end"*/}
            {/*      onChange={(ev) =>*/}
            {/*        handleToggleNotification(*/}
            {/*          NotificationEnum.NEW_MESSAGE_FROM_OCIETY*/}
            {/*        )*/}
            {/*      }*/}
            {/*      checked={*/}
            {/*        notifications.indexOf(*/}
            {/*          NotificationEnum.NEW_MESSAGE_FROM_OCIETY*/}
            {/*        ) !== -1*/}
            {/*      }*/}
            {/*      inputProps={{*/}
            {/*        "aria-labelledby": "switch-list-label-new-message-ociety",*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*</ListItem>*/}
            <ListItem>
              <ListItemText
                id="switch-list-label-deal-expiring"
                primary="Deals expiring"
              />
              <ListItemSecondaryAction>
                <OcietySwitch
                  edge="end"
                  onChange={(ev) =>
                    handleToggleNotification(
                      NotificationEnum.PROMOTION_EXPIRING
                    )
                  }
                  checked={
                    notifications.indexOf(
                      NotificationEnum.PROMOTION_EXPIRING
                    ) !== -1
                  }
                  inputProps={{
                    "aria-labelledby": "switch-list-label-deal-expiring",
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            {/*<ListItem>*/}
            {/*  <ListItemText*/}
            {/*    id="switch-list-label-deals-almost-used"*/}
            {/*    primary="Deals almost used up"*/}
            {/*  />*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <OcietySwitch*/}
            {/*      edge="end"*/}
            {/*      onChange={(ev) =>*/}
            {/*        handleToggleNotification(*/}
            {/*          NotificationEnum.PROMOTIONS_ALMOST_USED*/}
            {/*        )*/}
            {/*      }*/}
            {/*      checked={*/}
            {/*        notifications.indexOf(*/}
            {/*          NotificationEnum.PROMOTIONS_ALMOST_USED*/}
            {/*        ) !== -1*/}
            {/*      }*/}
            {/*      inputProps={{*/}
            {/*        "aria-labelledby": "switch-list-label-deals-almost-used",*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*</ListItem>*/}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};
export default Notifications;
