import { MenuItemClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const MuiMenuItem: Partial<Record<MenuItemClassKey, CSSProperties>> = {
  root: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 20,
    paddingRight: 20,
    fontWeight: 600,
    fontSize: 16,
    "&$selected": {
      backgroundColor: "#EBECF0",
    },
  },
};

export default MuiMenuItem;
