import { http } from "../../../../config/api";
import ILocation from "./models/ILocation";

const PROFILE_TZ = `profile/tz`;
const PROFILE_LOCATIONS = `profile/locations`;

export const getTZ = async (): Promise<Record<string, any> | null> =>
  http
    .get(PROFILE_TZ)
    .then((response) => ({
      ...response.data,
    }))
    .catch((error) => {
      throw error.response;
    });

export const getLocations = async (): Promise<ILocation[]> =>
  http
    .get(PROFILE_LOCATIONS)
    .then((response) => {
      return [...response.data];
    })
    .catch((error) => {
      throw error.response;
    });
