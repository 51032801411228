export default class DtoHappyHourRequest {
  public static build = ({
    files,
    menuUrl,
    description,
    venueId,
  }: DtoHappyHourRequest) => {
    const builderClassHappyHour = new DtoHappyHourRequest();
    builderClassHappyHour.files = files;
    builderClassHappyHour.menuUrl = menuUrl;
    builderClassHappyHour.description = description;
    builderClassHappyHour.venueId = venueId;
    return builderClassHappyHour;
  };

  public venueId = 0;
  public files: any[] = [];
  public menuUrl = "";
  public description = "";
}
