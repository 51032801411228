import { Dispatch } from "react";

// eslint-disable-next-line max-len
import { ShowAlertActionType } from "../../../common/components/Alert/AlertContextProvider";
import {
  Venue,
  VenuePendingChanges,
  VenuePhoto,
  VenueVideo,
} from "../../../common/models";
import dictionary from "../../../i18n/en_US/dictionary";
import { getOwnVenues } from "../../../store/currentVenue/actions";
import { getVenueById, saveVenue as sv } from "../api";
import {
  CHANGE_MAIN_PHOTO,
  ChangeMainPhotoAction,
  DELETE_VENUE_PHOTO,
  DELETE_VENUE_VIDEO,
  DeleteVenuePhotoAction,
  DeleteVenueVideoAction,
  GET_VENUE_FULFILLED,
  GET_VENUE_PENDING,
  GET_VENUE_REJECTED,
  GetVenueFailAction,
  GetVenuePendingAction,
  GetVenueSuccessAction,
  SAVE_VENUE_FULFILLED,
  SAVE_VENUE_PENDING,
  SAVE_VENUE_REJECTED,
  SaveVenueFailAction,
  SaveVenuePendingAction,
  SaveVenueSuccessAction,
  SET_VENUE_PHOTO,
  SET_VENUE_VIDEO,
  VenueActionTypes,
} from "./types";

const getVenuePendingAction = (): GetVenuePendingAction => ({
  type: GET_VENUE_PENDING,
});

const getVenueSuccessAction = (venue: Venue): GetVenueSuccessAction => ({
  type: GET_VENUE_FULFILLED,
  venue,
});

const getVenueFailAction = (error: string): GetVenueFailAction => ({
  type: GET_VENUE_REJECTED,
  error,
});

export const setVenuePhoto =
  (photo: VenuePhoto) => (dispatch: Dispatch<VenueActionTypes>) => {
    dispatch({
      type: SET_VENUE_PHOTO,
      photo,
    });
  };

export const deleteVenuePhoto =
  (photoId: number) => (dispatch: Dispatch<DeleteVenuePhotoAction>) => {
    dispatch({
      type: DELETE_VENUE_PHOTO,
      photoId,
    });
  };

export const changeMainPhotoVenue =
  (photoId: number) => (dispatch: Dispatch<ChangeMainPhotoAction>) => {
    dispatch({
      type: CHANGE_MAIN_PHOTO,
      photoId,
    });
  };

export const setVenueVideo =
  (video: VenueVideo) => (dispatch: Dispatch<VenueActionTypes>) => {
    dispatch({
      type: SET_VENUE_VIDEO,
      video,
    });
  };

export const deleteVenueVideo =
  (videoId: number) => (dispatch: Dispatch<DeleteVenueVideoAction>) => {
    dispatch({
      type: DELETE_VENUE_VIDEO,
      videoId,
    });
  };

export const getVenue =
  (venueId: number) => async (dispatch: Dispatch<VenueActionTypes>) => {
    dispatch(getVenuePendingAction());

    try {
      const venue = await getVenueById(venueId);
      dispatch(getVenueSuccessAction(venue));
    } catch (e) {
      dispatch(getVenueFailAction(e));
    }
  };

const saveFormVenuePendingAction = (): SaveVenuePendingAction => ({
  type: SAVE_VENUE_PENDING,
});

const saveFormVenueSuccessAction = (
  pendingChanges?: VenuePendingChanges
): SaveVenueSuccessAction => ({
  type: SAVE_VENUE_FULFILLED,
  pendingChanges,
});

const saveFormVenuegFailAction = (error: string): SaveVenueFailAction => ({
  type: SAVE_VENUE_REJECTED,
  error,
});

export const saveVenue =
  (venue: any, alertCallback?: ShowAlertActionType) =>
  async (dispatch: Dispatch<VenueActionTypes | any>) => {
    dispatch(saveFormVenuePendingAction());

    try {
      const { pendingChanges } = await sv(venue);
      dispatch(saveFormVenueSuccessAction(pendingChanges));
      dispatch(getOwnVenues());

      if (alertCallback) {
        alertCallback(dictionary.venues.venueForm.saveSuccess, "success");
      }
    } catch (error) {
      const msg = error
        ? error.data.details
          ? error.data.details[0].message
          : error.data
        : "Something went wrong";
      dispatch(saveFormVenuegFailAction(msg));

      if (alertCallback) {
        if (error.data === "Insult word") {
          alertCallback(
            dictionary.notifications.insultingMessageError,
            "error"
          );
        } else alertCallback(dictionary.venues.venueForm.saveError, "error");
      }
    }
  };
