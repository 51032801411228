import { DialogActionsClassKey } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

const MuiDialogActions: Partial<Record<DialogActionsClassKey, CSSProperties>> =
  {
    root: {
      paddingLeft: 32,
      paddingRight: 32,
      paddingBottom: 32,
      paddingTop: 8,
    },
  };

export default MuiDialogActions;
