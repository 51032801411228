import {
  Chip,
  FormControl,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { ChangeEvent, FC, useEffect } from "react";

import { useForm } from "../../../../../common/hooks";
import { ReactComponent as ApplyIcon } from "../../../../../common/svg/icons/apply.svg";
import { ReactComponent as DeleteIcon } from "../../../../../common/svg/icons/delete-2.svg";
import dictionary from "../../../../../i18n/en_US/dictionary";
import { RoleType } from "../../../main/RoleTypes";
import { RoleTypeEnum } from "../../../main/RoleTypes";
import {
  createManagerForm,
  CreateManagerFormField,
  createManagerFormSchema,
} from "./form";
import Props from "./Props";
import { useStyles } from "./styles";

const ManagerDialog: FC<Props> = (props: Props) => {
  const { handleClose, handleSave, setRole, role, manager } = props;
  const classes = useStyles();

  const { form, setAndValidate, setFormValues, validateForm } = useForm(
    createManagerFormSchema,
    createManagerForm
  );

  const rolesOptions = [
    {
      title: dictionary.venueStaff.managers.roles.waitlistManager,
      type: RoleTypeEnum.waitlist,
    },
    {
      title: dictionary.venueStaff.managers.roles.discountManager,
      type: RoleTypeEnum.discount,
    },
  ];

  const getLabel = (value: string) => {
    if (value === RoleTypeEnum.waitlist) {
      return dictionary.venueStaff.managers.roles.waitlistManager;
    }
    if (value === RoleTypeEnum.discount) {
      return dictionary.venueStaff.managers.roles.discountManager;
    }
  };

  useEffect(() => {
    if (manager) {
      setFormValues({
        firstName: manager.firstName,
        email: manager.email,
        isWaitlistAdmin: manager.isWaitlistAdmin,
        isDiscountAdmin: manager.isDiscountAdmin,
      });

      const managerRoles: string[] = [];

      if (manager.isWaitlistAdmin) {
        managerRoles.push(RoleTypeEnum.waitlist);
      }

      if (manager.isDiscountAdmin) {
        managerRoles.push(RoleTypeEnum.discount);
      }

      setRole(managerRoles);
    }
  }, [setRole, manager, setFormValues]);

  const handleChangeFormTextField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;

    setAndValidate(key as CreateManagerFormField, value);
  };

  const handleSaveItem = async () => {
    if (!validateForm()) {
      return;
    }

    await handleSave(form);
  };

  const handleRemoveRole = (value: string) => {
    const currentRoles = role;
    currentRoles.splice(currentRoles.indexOf(value), 1);
    setRole(currentRoles);
  };

  return (
    <Grid container xs={12} component={Paper} className={classes.manager}>
      <Grid item xs={3}>
        <FormControl className={classes.formControl}>
          <TextField
            className={classes.textField}
            required
            error={!!form.errors.firstName}
            fullWidth
            helperText={form.errors.firstName}
            label={dictionary.venueStaff.managers.form.firstName}
            name="firstName"
            onChange={handleChangeFormTextField}
            type="text"
            value={form.values.firstName || ""}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl className={classes.formControl}>
          <TextField
            className={classes.textField}
            required
            error={!!form.errors.email}
            fullWidth
            helperText={form.errors.email}
            label={dictionary.venueStaff.managers.form.email}
            name="email"
            onChange={handleChangeFormTextField}
            type="text"
            value={form.values.email || ""}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <FormControl className={classes.formControl}>
          <Select
            className={classes.select}
            multiple
            value={role}
            onChange={(event) => setRole(event.target.value)}
            input={<Input style={{ height: "50px" }} />}
            renderValue={(role: any) => (
              <div className={classes.selectedItems}>
                {role.length > 0 &&
                  role.map((value: RoleType) => (
                    <Chip
                      key={value}
                      label={getLabel(value)}
                      onDelete={(event) => handleRemoveRole(event)}
                      deleteIcon={
                        <div
                          onMouseDown={(event) => {
                            event.stopPropagation();
                            handleRemoveRole(value);
                          }}>
                          <CloseIcon className={classes.deleteIcon} />
                        </div>
                      }
                    />
                  ))}
              </div>
            )}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}>
            {rolesOptions.map((item) => (
              <MenuItem key={item.title} value={item.type}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={1}>
        <FormControl className={classes.actionsFormControl}>
          <Grid item xs={12} className={classes.actions}>
            <Grid item xs={6}>
              <IconButton onClick={handleSaveItem} name={"confirm"}>
                <ApplyIcon />
              </IconButton>
            </Grid>

            <Grid item xs={6}>
              <IconButton onClick={handleClose} name={"cancel"}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ManagerDialog;
