import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";

const Avatar: FC<Props> = (props: Props) => {
  const { imagePath, size } = props;

  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{ height: size, width: size, minWidth: size }}>
      <img
        className={classes.avatar}
        src={imagePath || "/images/avatars/review-avatar-placeholder.jpg"}
        alt="review user avatar"
      />
    </div>
  );
};

export default Avatar;
