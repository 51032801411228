import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import Props from "./Props";
import { useStyles } from "./styles";

const EmptyChart: FC<Props> = (props: Props) => {
  const { chartType, isChartSmall } = props;

  const classes = useStyles();

  const type = chartType.split("-")[0];

  return (
    <div className={classes.content}>
      <img
        src={`/images/backgrounds/${type}-chart-empty-bg.png`}
        alt="empty chart"
      />
      <Typography variant={"h4"} className={classes.noDataEmptyChartTitle}>
        {dictionary.reporting.noDataEmptyChartTitle}
      </Typography>
      <Typography
        variant={"body1"}
        className={clsx(
          classes.noDataEmptyChartText,
          isChartSmall && classes.noDataEmptyChartTextFullWidth
        )}>
        {dictionary.reporting.noDataEmptyChartText}
      </Typography>
    </div>
  );
};

export default EmptyChart;
