import { Typography } from "@material-ui/core";
import React, { FC } from "react";

import { ReactComponent as DropdownIcon } from "../../svg/icons/dropdown-icon.svg";
import Props from "./Props";
import { useStyles } from "./styles";

const FilterButton: FC<Props> = (props: Props) => {
  const { mainIcon, label, handleButtonClick } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.root} onClick={handleButtonClick}>
        {mainIcon}
        <Typography variant={"h5"} className={classes.label}>
          {label}
        </Typography>
        <DropdownIcon />
      </div>
    </>
  );
};

export default FilterButton;
