import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../models";

export type ClassNames =
  | "root"
  | "closeButton"
  | "closeIcon"
  | "title"
  | "description";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(30 / 8),
        padding: theme.spacing(30 / 8),
        position: "relative",
        borderRadius: 6,
        background:
          "url(/images/backgrounds/upgrade-plan-bg.png) no-repeat center",
        backgroundSize: "cover",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      title: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: "bold",
      },
      description: {
        margin: theme.spacing(20 / 8, 0),
        lineHeight: theme.typography.pxToRem(27),
        fontWeight: 600,
      },
      closeButton: {
        position: "absolute",
        top: 15,
        right: 20,
      },
      closeIcon: {
        width: 12,
        height: 12,
      },
    })
);
