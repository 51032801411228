import {
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";

import { AlertContext } from "../../../../../../../../../../../common/components/Alert/AlertContextProvider";
import { CroppedImageSize } from "../../../../../../../../../../../common/types/enums.type";
import dictionary from "../../../../../../../../../../../i18n/en_US/dictionary";
import imageUtils from "../../../../../../../../../../../utils/imageUtils";
import api from "../../../../../../../../../api";
import FileUpload from "../../../../../../../../../components/FileUpload";
import FileUploadProgress from "../../../../../../../../../components/FileUploadProgress";
import { DialogContext } from "../../DialogContextProvider";
import { HappyHourMainFormField } from "../../forms/main";
import { useStyles } from "./styles";

const Content: FC = () => {
  const classes = useStyles();

  const focusOnError = useRef<HTMLDivElement>(null);
  const errorHasFocus = useRef<boolean>(false);
  const {
    mainForm,
    mainForm: { form, setAndValidate },
    filesQueue,
    setFilesQueue,
    isSaveDisabled,
    setIsSaveDisabled,
  } = useContext(DialogContext);
  const { showAlert } = useContext(AlertContext);

  useEffect(() => {
    if (!mainForm.form.isValid) {
      focusOnError?.current?.focus();
      errorHasFocus.current = true;
    }
  }, [mainForm.form.isValid]);

  const handleChangeFormNameField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAndValidate(
      event.target.name as HappyHourMainFormField,
      event.target.value
    );
  };

  const handleFileChange = (files: any | any[]) => {
    files = Array.isArray(files) ? files : [files];
    setFilesQueue((currentFiles) => [...currentFiles, ...files]);
  };

  const onFileRemove = (generatedName: string) =>
    generatedName
      ? setFilesQueue((filesQueue) =>
          filesQueue.filter(
            (fileQueue) => fileQueue.generatedName !== generatedName
          )
        )
      : undefined;

  const onFileUpload = useCallback(
    async (file: any, progressUpdate: (value: number) => void) => {
      let uploadedFile: any;
      if (file.type.match(/image/g)) {
        setIsSaveDisabled(true);
        const croppedImage = await imageUtils.cropImage(
          file,
          CroppedImageSize.s1280x1280
        );
        uploadedFile = await api.happyHours
          .uploadFile(croppedImage, progressUpdate)
          .catch((error) => {
            if (error.response.data === "File too large")
              showAlert(dictionary.notifications.fileIsTooLargeError, "error");
            else showAlert(error.response.data, "error");
          })
          .finally(() => {
            setIsSaveDisabled(false);
          });
      } else {
        setIsSaveDisabled(true);
        uploadedFile = await api.happyHours
          .uploadFile(file, progressUpdate)
          .catch((error) => {
            if (error.response.data === "File too large")
              showAlert(dictionary.notifications.fileIsTooLargeError, "error");
            else showAlert(error.response.data, "error");
          })
          .finally(() => {
            setIsSaveDisabled(false);
          });
      }

      setFilesQueue((filesQueue) =>
        filesQueue.map((fileQueue) =>
          fileQueue && file && file.name === fileQueue.name
            ? uploadedFile
            : fileQueue
        )
      );
    },
    [setFilesQueue, setIsSaveDisabled, showAlert]
  );

  return (
    <DialogContent className={classes.dialogContent}>
      <Grid container spacing={4} className={classes.root}>
        <Grid item container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h3" className={classes.text1}>
              {dictionary.deals.happyHours.menusUpload}
            </Typography>
            <Typography variant="caption" className={classes.text2}>
              {dictionary.deals.happyHours.menusUploadDescription}
            </Typography>
            <FileUpload
              onFileChange={handleFileChange}
              accept=".jpg,.jpeg,.png,.pdf"
            />
            {filesQueue.map((file: any, index: number) => (
              <FileUploadProgress
                key={index}
                file={file}
                onFileUpload={onFileUpload}
                onRemove={onFileRemove}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="body2" className={classes.text3}>
              {dictionary.deals.happyHours.dialog.menuUrl}
            </Typography>
            <TextField
              className={classes.inputMenuUrl}
              fullWidth
              label={"Menu URL"}
              name="menuUrl"
              error={!!form.errors.menuUrl}
              helperText={form.errors.menuUrl}
              onChange={handleChangeFormNameField}
              type="text"
              value={form.values.menuUrl}
              variant="outlined"
              // inputRef={
              //   errorHasFocus.current && form.errors.menuUrl
              //     ? focusOnError
              //     : null
              // }
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h3" className={classes.text1}>
              {dictionary.deals.happyHours.dialog.descriptionLabel}
            </Typography>
            <Typography variant="caption" className={classes.text2}>
              {dictionary.deals.happyHours.dialog.descriptionLabelText}
            </Typography>
            <div className={classes.descriptionWrapper}>
              <TextField
                fullWidth
                label={"Enter text here"}
                InputLabelProps={{ style: { pointerEvents: "auto" } }}
                name="description"
                error={!!form.errors.description}
                helperText={form.errors.description}
                onChange={handleChangeFormNameField}
                type="text"
                value={form.values.description}
                variant="outlined"
                multiline={true}
                rows={3}
              />

              <div className={classes.descriptionExample}>
                <List
                  subheader={
                    <ListSubheader className={classes.listSubHeader}>
                      {dictionary.deals.happyHours.dialog.list.item1}
                    </ListSubheader>
                  }>
                  <ListItem className={classes.item}>
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.listItemText}
                          variant="caption">
                          {dictionary.deals.happyHours.dialog.list.item2}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem className={classes.item}>
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.listItemText}
                          variant="caption">
                          {dictionary.deals.happyHours.dialog.list.item3}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem className={classes.item}>
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.listItemText}
                          variant="caption">
                          {dictionary.deals.happyHours.dialog.list.item4}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  );
};
export default Content;
