import { Button, ButtonGroup, Tooltip, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { AlertContext } from "../../../../common/components/Alert/AlertContextProvider";
import ButtonBlack from "../../../../common/components/ButtonBlack";
import ButtonCancel from "../../../../common/components/ButtonCancel";
import ConfirmDialog from "../../../../common/components/dialogs/ConfirmDialog";
import { ConfirmModalType } from "../../../../common/components/dialogs/ConfirmDialog/Props";
import OcietyDialogTitle from "../../../../common/components/dialogs/OcietyDIalogTitle";
import SuccessDialog from "../../../../common/components/SuccessDialog";
import { ReactComponent as LinkIcon } from "../../../../common/svg/icons/link-2.svg";
import dictionary from "../../../../i18n/en_US/dictionary";
import { TwoFactoryAuthTypeEnum } from "../../../../store/auth/types";
import {
  clearForm,
  setAndValidate,
  setFormError,
  setFormValue,
  validateForm,
} from "../../../../store/forms/actions";
import { FormFieldKey } from "../../../../store/forms/types";
import { AppState } from "../../../../store/rootReducer";
import {
  formatMobileNumberWithoutSymbols,
  isPhoneNumberAllowed,
} from "../../../../utils/phoneNumberUtils";
import {
  claimVenue,
  claimVenueConfirm,
  claimVenueWithUploadedFile,
  savePrefilledVenueData,
} from "../../api";
import { ClaimVenueConfirmResponse, ClaimVenueResponse } from "../../models";
import AttachmentStep from "./AttachmentStep";
import ClaimVenueForm from "./ClaimVenueForm";
import ConfirmationMethod from "./ConfirmationMethod";
import {
  ClaimFlowSteps,
  claimVenueFormSchema,
  claimVenueVerifyFormSchema,
} from "./form";
import Props from "./Props";
import { useStyles } from "./styles";
import VerificationCode from "./VerificationCode";

const ClaimVenueDialog: FC<Props> = (props: Props) => {
  const { open, setOpen, prefilledData } = props;
  const { forms } = useSelector((state: AppState) => state);
  const { claimVenue: claimVenueForm, claimVenueVerify: claimVenueVerifyForm } =
    forms;
  const dispatch = useDispatch();
  const history = useHistory();
  const { showAlert } = useContext(AlertContext);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isWaitlistAdmin, setIsWaitlistAdmin] = useState(true);
  const [isValidateDisabled, setIsValidateDisabled] = useState(true);
  const [closeConfirm, setCloseConfirm] = useState(false);
  const [isAttachmentOption, setIsAttachmentOption] = useState(false);
  const [attachment, setAttachment] = useState<any[]>([]);
  const [selectedMethod, setSelectedMethod] =
    React.useState<TwoFactoryAuthTypeEnum>();
  const [resendType, setResendType] = useState(TwoFactoryAuthTypeEnum.EMAIL);
  const [currentStep, setCurrentStep] = useState<ClaimFlowSteps>(
    ClaimFlowSteps.CLAIM_VENUE_FORM
  );
  const [uuid, setUuid] = useState(uuidv4());
  const [isPrefilledDataSaved, setIsPrefilledDataSaved] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const claimButtonId = `claim-venue-${
    selectedMethod?.toLocaleLowerCase() || resendType.toLowerCase()
  }`;
  const classes = useStyles();
  const formLink = `${window.location.origin}/businesses/${uuid}`;
  const venueId = useMemo(() => {
    return props.venueId || prefilledData?.venueId || 0;
  }, [prefilledData?.venueId, props.venueId]);
  const handleChangeClaimForm = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;

    if (errorMsg) {
      setErrorMsg("");
    }

    dispatch(
      setAndValidate(
        "claimVenue",
        key as FormFieldKey,
        key === "phoneNumber" &&
          formatMobileNumberWithoutSymbols(value)?.length === 12
          ? formatMobileNumberWithoutSymbols(value)
          : value,
        claimVenueFormSchema
      )
    );
    await savePrefilledVenueData(
      {
        venueId,
        ...claimVenueForm.values,
        isWaitlistAdmin,
        uuid,
      },
      showAlert
    );
  };

  useEffect(() => {
    if (prefilledData) {
      setIsPrefilledDataSaved(true);

      for (const [key, value] of Object.entries(prefilledData)) {
        dispatch(
          setAndValidate(
            "claimVenue",
            key as FormFieldKey,
            key === "phoneNumber" &&
              formatMobileNumberWithoutSymbols(value)?.length === 12
              ? formatMobileNumberWithoutSymbols(value)
              : value,
            claimVenueFormSchema
          )
        );
      }

      if (prefilledData.uuid) {
        setUuid(prefilledData.uuid);
      }
    }
  }, [dispatch, prefilledData, open]);

  const handleChangeClaimVerifyForm = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: key, value } = event.target;

    if (errorMsg) {
      setErrorMsg("");
    }

    dispatch(
      setAndValidate(
        "claimVenueVerify",
        key as FormFieldKey,
        value,
        claimVenueVerifyFormSchema
      )
    );
  };

  const handleClose = (force = false) => {
    if (force || claimVenueVerifyForm.values.securityKey) {
      dispatch(clearForm("claimVenueVerify"));
      dispatch(setFormValue("claimVenueVerify", "securityKey", undefined));
      dispatch(setFormValue("claimVenueVerify", "type", undefined));

      if (force) {
        dispatch(clearForm("claimVenue"));
        setOpen(false);
      }
    } else {
      dispatch(clearForm("claimVenue"));
      setOpen(false);
    }

    setErrorMsg("");
    setSelectedMethod(undefined);

    history.push({
      pathname: "/businesses",
    });
  };

  const handleClaimVenue = async () => {
    if (!dispatch(validateForm("claimVenue", claimVenueFormSchema))) {
      return;
    }

    if (
      !claimVenueForm.values.phoneNumber ||
      !(await isPhoneNumberAllowed(claimVenueForm.values.phoneNumber))
    ) {
      dispatch(
        setFormError(
          "claimVenue",
          "phoneNumber",
          dictionary.venues.claimVenueDialog.phoneNumberFieldValidationError
        )
      );
      return;
    }

    if (currentStep === ClaimFlowSteps.VERIFICATION_CODE) {
      setIsAttachmentOption(true);
    }

    try {
      setLoading(true);

      const res: ClaimVenueResponse = await claimVenue(
        {
          venueId,
          ...claimVenueForm.values,
          isWaitlistAdmin,
        },
        showAlert
      );

      if (res.success) {
        setCurrentStep(ClaimFlowSteps.SUCCESS_NEED_APPROVE);
      }

      if (res.securityKey) {
        dispatch(
          setFormValue("claimVenueVerify", "securityKey", res.securityKey)
        );
        dispatch(setFormValue("claimVenueVerify", "type", res.type));
        if (res.type) {
          setResendType(res.type);
        }
        setCurrentStep(ClaimFlowSteps.VERIFICATION_CODE);
      } else if (res.message) {
        setCurrentStep(ClaimFlowSteps.CONFIRMATION_METHOD);
      } else {
        setCurrentStep(ClaimFlowSteps.ATTACHMENT_FILE_NO_MATCH);
      }
      setErrorMsg("");
    } catch (e) {
      if (!e) {
        return;
      }

      let msg = dictionary.venues.claimVenueDialog.claimError;

      if (e.data) {
        msg = e.data.details ? e.data.details[0].message : e.data;
      }

      setErrorMsg(msg);
      if (e.status === 409) {
        showAlert(
          dictionary.venues.claimVenueDialog.errorPopupIfEmailIsRegisteredTitle,
          "error",
          dictionary.venues.claimVenueDialog.errorPopupIfEmailIsRegisteredText
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSavePrefilledData = async () => {
    try {
      setLoading(true);

      await savePrefilledVenueData(
        {
          venueId,
          ...claimVenueForm.values,
          isWaitlistAdmin,
          uuid,
        },
        showAlert
      );

      await navigator.clipboard.writeText(formLink);
      setShowTooltip(true);
      setIsPrefilledDataSaved(true);

      setErrorMsg("");
    } catch (e) {
      if (!e) {
        return;
      }

      let msg = dictionary.venues.claimVenueDialog.claimError;

      if (e.data) {
        msg = e.data.details ? e.data.details[0].message : e.data;
      }

      setErrorMsg(msg);
      if (e.status === 409) {
        showAlert(
          dictionary.venues.claimVenueDialog.errorPopupIfEmailIsRegisteredTitle,
          "error",
          dictionary.venues.claimVenueDialog.errorPopupIfEmailIsRegisteredText
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClaimWithAttachment = () => {
    handleClose(true);
  };

  const handleConfirmationMethod = async () => {
    if (currentStep === ClaimFlowSteps.VERIFICATION_CODE) {
      setIsAttachmentOption(true);
    }

    try {
      setLoading(true);
      const res: ClaimVenueResponse = await claimVenue(
        {
          venueId,
          ...claimVenueForm.values,
          isWaitlistAdmin,
          type: selectedMethod,
        },
        showAlert
      );

      if (res.securityKey) {
        dispatch(
          setFormValue("claimVenueVerify", "securityKey", res.securityKey)
        );
        dispatch(setFormValue("claimVenueVerify", "type", res.type));
      }
      setErrorMsg("");
    } catch (e) {
      if (!e) {
        return;
      }

      let msg = dictionary.venues.claimVenueDialog.claimError;

      if (e.data) {
        msg = e.data.details ? e.data.details[0].message : e.data;
      }

      setErrorMsg(msg);
      showAlert(msg, "error");
    } finally {
      setCurrentStep(ClaimFlowSteps.VERIFICATION_CODE);
      setLoading(false);
    }
  };

  const handleClaimWithUploadedFile = async () => {
    const formData = new FormData();

    if (claimVenueForm.values.comment) {
      formData.append("comment", claimVenueForm.values.comment);
    }
    if (claimVenueForm.values.company) {
      formData.append("company", claimVenueForm.values.company);
    }
    if (claimVenueForm.values.email) {
      formData.append("email", claimVenueForm.values.email);
    }
    if (claimVenueForm.values.firstName) {
      formData.append("firstName", claimVenueForm.values.firstName);
    }
    if (claimVenueForm.values.lastName) {
      formData.append("lastName", claimVenueForm.values.lastName);
    }

    if (claimVenueForm.values.phoneNumber) {
      formData.append("phoneNumber", claimVenueForm.values.phoneNumber);
    }
    if (claimVenueForm.values.title) {
      formData.append("title", claimVenueForm.values.title);
    }

    if (attachment?.length) {
      attachment.forEach((file: any) => {
        formData.append("file", file, file.name);
      });
    }
    if (venueId) {
      // @ts-ignore
      formData.append("venueId", venueId);
    }

    if (isWaitlistAdmin) {
      // @ts-ignore
      formData.append("isWaitlistAdmin", isWaitlistAdmin);
    }

    try {
      setLoading(true);
      const response: ClaimVenueConfirmResponse =
        await claimVenueWithUploadedFile(formData, venueId);

      if (response) {
        setCurrentStep(ClaimFlowSteps.SUCCESS_NEED_APPROVE);
      }
    } catch (e) {
      showAlert(
        dictionary.venues.claimVenueDialog.dialogAttachmentErrorMessage,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyClaim = async () => {
    if (
      !dispatch(validateForm("claimVenueVerify", claimVenueVerifyFormSchema))
    ) {
      return;
    }

    try {
      setLoading(true);

      const res: ClaimVenueConfirmResponse = await claimVenueConfirm({
        venueId,
        ...claimVenueForm.values,
        ...claimVenueVerifyForm.values,
        isWaitlistAdmin,
      });

      if (res.message) {
        if (res.success) {
          setCurrentStep(ClaimFlowSteps.SUCCESS_CLAIM);
        } else {
          setErrorMsg(res.message);
          showAlert(res.message, "error");
        }
      }
    } catch (e) {
      if (!e) {
        return;
      }

      let msg = dictionary.venues.claimVenueDialog.claimError;

      if (e.data) {
        msg = e.data.details ? e.data.details[0].message : e.data;
      }

      setErrorMsg(msg);
      showAlert(msg, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      disableBackdropClick
      aria-labelledby="form-dialog-title">
      <OcietyDialogTitle
        id="alert-dialog-title"
        onClose={() => setCloseConfirm(true)}>
        {currentStep === ClaimFlowSteps.CONFIRMATION_METHOD
          ? dictionary.venues.claimVenueDialog.dialogTitleTypeSelection
          : currentStep === ClaimFlowSteps.ATTACHMENT_FILE_NO_CODE
          ? dictionary.venues.claimVenueDialog.dialogTitleAttachmentLabel
          : currentStep === ClaimFlowSteps.ATTACHMENT_FILE_NO_MATCH
          ? dictionary.venues.claimVenueDialog.confirmOwnershipLabel
          : dictionary.venues.claimVenueDialog.dialogTitle}
      </OcietyDialogTitle>
      {currentStep === ClaimFlowSteps.CLAIM_VENUE_FORM && (
        <ClaimVenueForm
          claimVenueForm={claimVenueForm}
          handleChangeClaimForm={handleChangeClaimForm}
          handleClose={handleClose}
          isWaitlistAdmin={isWaitlistAdmin}
          setIsWaitlistAdmin={setIsWaitlistAdmin}
          loading={loading}
          setIsValidateDisabled={setIsValidateDisabled}
        />
      )}
      {currentStep === ClaimFlowSteps.CONFIRMATION_METHOD && (
        <ConfirmationMethod
          handleClose={handleClose}
          loading={loading}
          selectedValue={selectedMethod}
          setSelectedValue={setSelectedMethod}
        />
      )}
      {currentStep === ClaimFlowSteps.VERIFICATION_CODE && (
        <VerificationCode
          claimVenueForm={claimVenueForm}
          isAttachmentOption={isAttachmentOption}
          handleClose={handleClose}
          claimVenueVerifyForm={claimVenueVerifyForm}
          loading={loading}
          handleOpenAttachmentModal={() =>
            setCurrentStep(ClaimFlowSteps.ATTACHMENT_FILE_NO_CODE)
          }
          errorMsg={errorMsg}
          resendType={selectedMethod || resendType}
          handleResendCode={
            selectedMethod ? handleConfirmationMethod : handleClaimVenue
          }
          handleChangeClaimVerifyForm={handleChangeClaimVerifyForm}
        />
      )}
      {(currentStep === ClaimFlowSteps.ATTACHMENT_FILE_NO_CODE ||
        currentStep === ClaimFlowSteps.ATTACHMENT_FILE_NO_MATCH) && (
        <AttachmentStep
          loading={loading}
          attachment={attachment}
          setAttachment={setAttachment}
          currentStep={currentStep}
        />
      )}
      <DialogActions className={classes.actionsDialogButton}>
        {currentStep === ClaimFlowSteps.CLAIM_VENUE_FORM && (
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
            }}
            open={showTooltip}
            title={dictionary.venues.claimVenueDialog.linkCopiedTooltip}
            leaveDelay={1000}
            onClose={() => setShowTooltip(false)}>
            <ButtonGroup
              disableElevation
              variant="contained"
              className={classes.linkButtonGroup}>
              <Tooltip
                classes={{
                  tooltip: classes.tooltip,
                }}
                title={dictionary.venues.claimVenueDialog.linkTooltip}>
                <span>
                  <Button
                    disabled={true}
                    classes={{
                      disabled: classes.linkDisabledButton,
                    }}>
                    <Typography variant="inherit" noWrap>
                      {formLink}
                    </Typography>
                  </Button>
                </span>
              </Tooltip>
              <Button
                className={classes.linkButton}
                variant="contained"
                onClick={() => {
                  handleSavePrefilledData();
                }}>
                <LinkIcon className={classes.linkButtonIcon} />
              </Button>
            </ButtonGroup>
          </Tooltip>
        )}
        <ButtonCancel
          variant="outlined"
          onClick={() => {
            setCloseConfirm(true);
          }}>
          {dictionary.venues.claimVenueDialog.cancelButton}
        </ButtonCancel>

        {currentStep === ClaimFlowSteps.CLAIM_VENUE_FORM && (
          <ButtonBlack
            variant="contained"
            id="venue-claim-button"
            disabled={
              !claimVenueForm.isValid ||
              !!errorMsg ||
              isValidateDisabled ||
              loading
            }
            onClick={handleClaimVenue}>
            {dictionary.venues.claimVenueDialog.claimButton}
          </ButtonBlack>
        )}
        {currentStep === ClaimFlowSteps.CONFIRMATION_METHOD && (
          <ButtonBlack
            variant="contained"
            id="venue-claim-button"
            disabled={!claimVenueForm.isValid || !!errorMsg}
            onClick={handleConfirmationMethod}>
            {dictionary.venues.claimVenueDialog.selectConfirmationMethodButton}
          </ButtonBlack>
        )}
        {currentStep === ClaimFlowSteps.VERIFICATION_CODE && (
          <ButtonBlack
            variant="contained"
            id={claimButtonId}
            disabled={!claimVenueVerifyForm.isValid || !!errorMsg}
            onClick={handleVerifyClaim}>
            {dictionary.venues.claimVenueDialog.claimButton}
          </ButtonBlack>
        )}
        {(currentStep === ClaimFlowSteps.ATTACHMENT_FILE_NO_CODE ||
          currentStep === ClaimFlowSteps.ATTACHMENT_FILE_NO_MATCH) && (
          <ButtonBlack
            variant="contained"
            id={claimButtonId}
            disabled={!attachment.length}
            onClick={handleClaimWithUploadedFile}>
            {dictionary.venues.claimVenueDialog.claimButton}
          </ButtonBlack>
        )}
        <ConfirmDialog
          open={closeConfirm}
          onClose={() => {
            setCloseConfirm(false);
          }}
          onConfirm={() => handleClose(true)}
          type={ConfirmModalType.CONFIRM}
          message={
            isPrefilledDataSaved
              ? dictionary.venues.claimVenueDialog.popupWarningOnCloseDataSaved
              : dictionary.venues.claimVenueDialog.popupWarningOnClose
          }
        />
        <SuccessDialog
          open={
            currentStep === ClaimFlowSteps.SUCCESS_NEED_APPROVE ||
            currentStep === ClaimFlowSteps.SUCCESS_CLAIM
          }
          onClose={handleClaimWithAttachment}
          message={
            currentStep === ClaimFlowSteps.SUCCESS_NEED_APPROVE
              ? dictionary.venues.claimVenueDialog
                  .successForManualApproveDialogMsg
              : currentStep === ClaimFlowSteps.SUCCESS_CLAIM
              ? dictionary.venues.claimVenueDialog.successClaimingMessagePopup
              : ""
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(ClaimVenueDialog);
