import { ClassNameMap, createStyles, makeStyles } from "@material-ui/styles";

import { OcietyTheme } from "../../../../common/models";

export type ClassNames =
  | "list"
  | "item"
  | "iconClock"
  | "renderTimeComponent"
  | "renderTimeComponentContainer"
  | "text1"
  | "text2"
  | "text3";

export const useStyles: () => ClassNameMap<ClassNames> = makeStyles(
  (theme: OcietyTheme) =>
    createStyles({
      list: {
        background: "#F9FAFC",
        border: "2px solid #E5E7EF",
        borderRadius: 4,
        display: "flex",
        padding: "32px 48px",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
          padding: 16,
        },
      },
      item: {
        display: "flex",
        justifyContent: "space-between",
        padding: "12px 0",
        borderBottom: "solid 2px #E5E7EF",

        "&:last-child": {
          borderBottom: "none",
        },
      },
      iconClock: { width: 26, height: 26, marginRight: 13, color: "#9298A0" },
      renderTimeComponent: {
        background: "#FFFFFF",
        boxShadow: "0px 6px 10px rgb(70 72 84 / 5%)",
        borderRadius: 4,
        padding: "5px 15px",
        display: "flex",
        alignItems: "center",
      },
      renderTimeComponentContainer: { display: "flex" },
      text1: { fontWeight: 600, fontSize: 12, color: "#747982" },
      text2: { fontWeight: 600, fontSize: 14, color: "#37393F" },
      text3: { fontWeight: 700, fontSize: 16, color: "#37393F" },
    })
);
