import { Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

import dictionary from "../../../i18n/en_US/dictionary";
import { useGlobalStyles } from "../../styles";
import BackButton from "../BackButton";
import DynamicMetaTags from "../DynamicMetaTags";
import Props from "./Props";
import { useStyles } from "./styles";

const PageTitle: FC<Props> = (props: Props) => {
  const { children, wrapperClassName, backURL } = props;

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <>
      {typeof children === "string" && <DynamicMetaTags title={children} />}
      <div className={clsx(globalClasses.flexRow_Center, wrapperClassName)}>
        {backURL && <BackButton to={backURL} />}
        <Typography className={classes.titleText} variant={"h2"}>
          {children}
        </Typography>
      </div>
    </>
  );
};

export default PageTitle;
