export enum RoleTypeEnum {
  waitlist = "isWaitlistAdmin",
  discount = "isDiscountAdmin",
}

export enum RoleNameEnum {
  superAdmin = "ADMIN",
  admin = "VENUE_ADMIN",
  waitlist = "WAITLIST_ADMIN",
  discount = "DISCOUNT_ADMIN",
  manager = "MANAGER",
  dataEntryAdmin = "DATA_ENTRY_ADMIN",
}

export type RoleType = "isWaitlistAdmin" | "isDiscountAdmin";
